import {SensorBasicsForm} from '../AddSensor/WizardState';
import {DeviceFamily} from '../../../../common/constants';

export function GetChannelTypes(sensorBasicsFormState: SensorBasicsForm, deviceFamily: DeviceFamily) {
	const selectedChannel = sensorBasicsFormState.selectedChannel;
	const channelTypes = selectedChannel.channel_types;

	if ([DeviceFamily.LiberoGx, DeviceFamily.EcologProXG].includes(deviceFamily)) {
		return channelTypes;
	}

	return channelTypes.filter(c => c.value_index == selectedChannel.selectedValueIndex);
}
