import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {StyledLink} from '../Styles/StyledLink';
import Shared from '../../Shared/Shared';

const LinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    font-size: 13px;
    color: #e41919;
`;

const EnableHubspotChat = props => {
    const LINK_TEXT = {
        true: 'Chat with Support',
        false: 'Chat with Support',
    };
    const [storageVisibility] = useState(localStorage.getItem('hideHubspotChat') || 'false');
    const [isChatHidden, setIsChatHidden] = useState(true);
    const [linkText, setLinkText] = useState(LINK_TEXT[true]);

    useEffect(() => {
        const visibility = JSON.parse(storageVisibility.toLowerCase());
        setIsChatHidden(visibility);
        localStorage.setItem('hideHubspotChat', visibility.toString());
    }, []);

    useEffect(() => {
        const domElementLoaded = document.getElementById('hubspot-messages-iframe-container');
        if (domElementLoaded) {
            localStorage.setItem('hideHubspotChat', isChatHidden.toString());
            Shared.hideHubspotChat(isChatHidden);
        } else {
            setLinkText(LINK_TEXT[false]);
        }
    }, [isChatHidden]);

    const triggerHubspotChat = () => {
        setLinkText(LINK_TEXT[!isChatHidden]);
        setIsChatHidden(!isChatHidden);
    };

    return (
        <LinkWrapper>
            <StyledLink onClick={triggerHubspotChat} title="Do you need some assistance?">
                {linkText}
            </StyledLink>
            <div style={{paddingLeft: '5px'}}>
                <img width={30} src={process.env.PUBLIC_URL + '/img/chat-icon.png'} alt="Chat with support" />
            </div>
        </LinkWrapper>
    );
};

export default EnableHubspotChat;
