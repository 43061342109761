import React from 'react';
import styles from './styles.module.css';

type LimitAlarmsTitleProps = {
	show?: boolean;
	title: string;
	type?: 'h1' | 'h2';
};

export function SummarySectionHeading(props: LimitAlarmsTitleProps) {
	const show = props.show != false;
	let className: string;
	switch (props.type) {
		case 'h2':
			className = styles.subSectionHeading;
			break;
		default:
			className = styles.sectionHeading;
	}

	return (
		show && (
			<tr className={className}>
				<td>{props.title}</td>
				<td />
			</tr>
		)
	);
}
