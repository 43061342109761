import React, {Component} from 'react';
import {Col, ColProps, ConfigProvider, Row, Space, TreeSelect} from 'antd';
import LegacySensorService from './Sensor/SensorService';
import SensorGroupsService from '../Settings/SensorGroups/SensorGroupsService';
import SensorGroupsUtils from '../Settings/SensorGroups/SensorGroupsUtils';
import SensorComponent from './Sensor/SensorComponent';
import Groups from './Group/Groups';
import pubsub from 'pubsub-js';
import Message from '../Shared/Components/Message';
import {Access} from '../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../Infrastructure/Authorization/Access';
import {secureToken} from '../Infrastructure/Authorization/Utils';
import SerialNumberInfo from '../Shared/SerialNumberInfo';
import {RequestLogger} from '../Infrastructure/Requests/Logger';
import {MultiContext} from '../Infrastructure/Authorization/Context/MultiContext';
import {RouteComponentProps} from 'react-router-dom';
import {DashboardState} from './DashboardState';
import {OptimizeFor} from './Sensor/SensorBodyGraphic';
import {CalibrationStatusChanged, DEVICE_EVENT, SensorAlarmingEnabledChangedData, UI_EVENT} from '../Shared/Constants/Events';
import MeasurementService from '../Services/Measurements/LastMeasurementValues';
import {Sensor} from '../Common/Types/Sensor';
import {DeviationStatus} from '../Common/Types/DeviationStatus';
import {MeasurementNew} from '../Common/Types/WebSocket/MeasurementNew';
import {SignalStrengthNew} from '../Common/Types/WebSocket/SignalStrengthNew';
import InfiniteScroll from 'react-infinite-scroller';
import {ComputePageNumber} from '../../common/util';
import CalibrationService from '../Services/Calibrations/CalibrationService';
import {getAdditionallyAffectedSensors, groupChannels} from '../../common/util/SensorChannelGrouping';
import {CalibrationState} from '../Calibration/Model/CalibrationState';
import {ContentWrapper, ViewWrapper} from '../Layout';
import {ViewHeader} from '../Common';
import Shared from '../Shared/Shared';
import {AddSensorButton} from '../Buttons';
import {SensorService} from '../../common/services';

const rowWrapper = {
	margin: '0',
};

const rowChild = {
	...rowWrapper,
	width: '100%',
};

const colGroupParent = {
	padding: '1px',
	margin: '0px',
};

const rowGroupWrapper = {
	backgroundColor: 'white',
	border: '2px solid #3a3a39',
	borderRadius: '5px',
	margin: '1px',
	padding: '1px',
};

const colGroupChild = {
	padding: '2px 5px 0px 4px',
};

const colGroupChildLast = {
	padding: '3px 2px 3px 8px',
};

const colSingleParent = {
	padding: '1px',
	margin: '0px',
};

const rowSingleWrapper = {
	backgroundColor: 'white',
	border: '2px solid white ',
	margin: '1px',
	padding: '0px',
};

const colSingleChild = {
	padding: '4px',
};

const OptimizeForSpeedThreshold = 50;

class Dashboard extends Component<RouteComponentProps, DashboardState> {
	static contextType = MultiContext;

	declare context: React.ContextType<typeof MultiContext>;

	private readonly ItemsPerInfiniteScrollPage = 20;

	private calibrationService: CalibrationService;

	private subscriptions: PubSubJS.Token[] = [];

	constructor(props) {
		super(props);

		this.state = {
			checkLastRunOnly: props.location.state && props.location.state.checkLastRunOnly,
			loading: false,
			sensorGroups: [],
			filteredSensorGroups: [],
			selectedGroups: undefined,
			sensors: new Map<number, Sensor>(),
			shownSensors: new Map<number, Sensor>(),
			startCalibrationModalVisible: false,
			startCalibrationModalSensor: null,
			startCalibrationModalAdditionallyAffectedSensors: null,
			startCalibrationModalLoading: false,
		};

		this.calibrationService = new CalibrationService();

		// TODOS:
		// sensor_status_changed might have worked before
		this.handleNewSensor = this.handleNewSensor.bind(this);
		this.handleSensorGroupChange = this.handleSensorGroupChange.bind(this);
		this.handleRunStatusChanged = this.handleRunStatusChanged.bind(this);
		this.handleUpdateDashboardLiberoG = this.handleUpdateDashboardLiberoG.bind(this);
		this.handleNewMeasurement = this.handleNewMeasurement.bind(this);
		this.getDeviationsStatus = this.getDeviationsStatus.bind(this);
		this.handleNewSignalStrength = this.handleNewSignalStrength.bind(this);
		this.updateShownSensors = this.updateShownSensors.bind(this);
	}

	componentDidMount() {
		this.getSensorGroups();
		this.saveDevicesViewSettings();
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.ALL_DEVIATION_ACKNOWLEDGED, this.getDeviationsStatus));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.DEVIATION_NO_LONGER_ACTIVE, this.getDeviationsStatus));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.DEVIATION_OCCURRED, this.getDeviationsStatus));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.MEASUREMENT_NEW, this.handleNewMeasurement));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.SENSOR_NEW, this.handleNewSensor));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.RUN_STATUS_CHANGED, this.handleRunStatusChanged));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.UPDATE_DASHBOARD_LIBERO_G, this.handleUpdateDashboardLiberoG));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.SIGNAL_STRENGTH_NEW, this.handleNewSignalStrength));

		this.subscriptions.push(
			pubsub.subscribe(
				UI_EVENT.SENSOR_ALARMING_ENABLED_CHANGED,
				async (_msg, data: SensorAlarmingEnabledChangedData) => await this.updateSingleSensor(data.SensorId)
			)
		);

		this.subscriptions.push(
			pubsub.subscribe(
				UI_EVENT.CALIBRATION_STATUS_CHANGED,
				async (_msg, data: CalibrationStatusChanged) => await this.updateSingleSensor(data.sensorId)
			)
		);
	}

	componentWillUnmount() {
		this.subscriptions.forEach(token => {
			pubsub.unsubscribe(token);
		});
	}
	saveDevicesViewSettings() {
		const path = '/dashboard';
		Shared.saveDevicesViewSetting(this.context, path);
	}

	async updateSingleSensor(sensorId: number) {
		try {
			const sensors = this.state.sensors;
			const updatedSensor = (await SensorService.GetSensorsFromDashboardInfo(sensorId)).at(0);
			updatedSensor.CalibrationState = (await this.calibrationService.getCurrentCalibrationStatus(sensorId))[0].State;
			sensors.set(sensorId, updatedSensor);
			this.setState({sensors: sensors}, () => {
				this.updateShownSensors();
				this.getDeviationsStatus();
			});
		} catch (e) {
			console.error(e);
		}
	}

	handleNewMeasurement(_: string, data: MeasurementNew) {
		const sensorId = data.SensorId;
		let sensors = this.state.sensors;
		let sensor = sensors.get(sensorId);
		if (sensor === undefined) {
			return;
		}
		sensor.AddNewMeasurement(data);

		this.setState({sensors: sensors});
	}

	handleNewSignalStrength(_: string, data: SignalStrengthNew) {
		const sensorId = data.sensors_id;
		let sensors = this.state.sensors;
		let sensor = sensors.get(sensorId);
		if (sensor === undefined) {
			return;
		}
		sensor.SignalStrength = data.signal_strength;
		this.setState({sensors: sensors}, () => {
			this.updateShownSensors();
			this.getDeviationsStatus();
		});
	}

	async handleNewSensor(_1, _2) {
		await this.getSensors();
	}

	async handleRunStatusChanged(_: string, _data) {
		// Unfortunately, the libero g status is not available in the all information table, therefore we have to update all sensors
		await this.getSensors();
	}

	async handleUpdateDashboardLiberoG(_: string, _data) {
		if (_data.sensor_id) {
			const sensor = this.state.sensors.get(_data.sensor_id);
			sensor.CalibrationState = (await this.calibrationService.getCurrentCalibrationStatus(_data.sensor_id))[0].State;
			this.updateShownSensors();
			this.sensorAlarmSettingChanged(sensor);
		}
	}

	addSensor = e => {
		e.preventDefault();
		this.props.history.push('/addSensor');
	};

	getSensorGroups() {
		SensorGroupsService.sensorGroups(RequestLogger.createLogData('dashboard', 'load-sensor-groups', 'onLoad'))
			.then(response => {
				const nestedGroups = SensorGroupsUtils.getNestedGroups(response.data);
				const {groups: selectedGroups} = secureToken.getDashboardGroups();

				this.setState(
					{
						sensorGroups: nestedGroups,
						selectedGroups,
					},
					async () => {
						const sensorsLoaded = await this.filterSensorsByGroups();
						if (!sensorsLoaded) {
							await this.getSensors();
						}
					}
				);
			})
			.catch(err => {
				console.log(err);
			});
	}

	async getSensors(params = '') {
		const sensors = new Map<number, Sensor>();

		try {
			const response = await LegacySensorService.dashboardInfo(params);
			for (const dbSensor of response.data) {
				const sensor = new Sensor(dbSensor);
				sensors.set(sensor.Id, sensor);
			}

			const sensorsSorted = this.sortSensors(sensors);

			this.setState({sensors: sensorsSorted}, () => {
				this.updateShownSensors();
				this.getDeviationsStatus();
			});

			MeasurementService.PublishLastMeasurementValues(RequestLogger.createLogData('dashboard', 'getSensors', 'componentDidMount'));

			const calibrationStates = await this.calibrationService.getCurrentCalibrationStatus(...sensorsSorted.keys());
			for (const calibState of calibrationStates) {
				const sensor = sensorsSorted.get(calibState.SensorId);
				sensor.CalibrationState = calibState.State;
				sensor.CanStartCalibration = calibState.Options.StartCalibrationPossible;
				sensor.CanStopCalibration = calibState.Options.StopCalibrationPossible;
			}

			this.updateShownSensors();
		} catch (error) {
			console.log(error);
		}
	}

	getDeviationsStatus() {
		let sensors = this.state.sensors;
		if (sensors.size === 0) {
			return;
		}
		LegacySensorService.deviationsStatus(RequestLogger.createLogData('dashboard', 'load-deviation-status', 'onLoad'))
			.then(response => {
				response.data.forEach(deviation => {
					let sensor = sensors.get(deviation.sensors_id);
					if (sensor) {
						sensor.Deviations.push(new DeviationStatus(deviation));
					}
				});
				this.setState({sensors: sensors});
			})
			.catch(error => console.error(error));
	}

	analyseSensor(sensor: Sensor) {
		this.props.history.push({
			pathname: '/sensorAnalysis/' + sensor.Id,
			state: {sensor: sensor, checkLastRunOnly: this.state.checkLastRunOnly},
		});
	}

	activateAlarming(sensor: Sensor) {
		LegacySensorService.activateAlarming(
			{sensors_id: sensor.Id, activate: true},
			RequestLogger.createLogData('dashboard', 'activate-alarming', 'onClick')
		);
		setTimeout(() => {
			this.sensorAlarmSettingChanged(sensor);
		}, 1000);
	}

	deactivateAlarming(sensor: Sensor) {
		LegacySensorService.activateAlarming(
			{sensors_id: sensor.Id, activate: false},
			RequestLogger.createLogData('dashboard', 'activate-alarming', 'onClick')
		);
		setTimeout(() => {
			this.sensorAlarmSettingChanged(sensor);
		}, 1000);
	}

	sensorAlarmSettingChanged(sensor: Sensor) {
		let sensors = this.state.sensors;
		if (sensors.size === 0) {
			return;
		}
		LegacySensorService.sensor(sensor.Id, RequestLogger.createLogData('dashboard', 'sensorAlarmSettingChanged', 'callback')).then(
			response => {
				sensors.get(sensor.Id).IsMuted = response.data.muted;
				this.setState({sensors: sensors});
			}
		);
	}

	sensorConfiguration(sensor: Sensor) {
		if (
			sensor.ModulePrefix !== null &&
			(sensor.ModulePrefix.includes('X') || sensor.ModulePrefix.includes('Y') || sensor.ModulePrefix.includes('W'))
		) {
			LegacySensorService.discoverRun({s_id: sensor.Id}, RequestLogger.createLogData('dashboard', 'discover-run', 'onClick'))
				.then(response => {
					if (response.status === 200) {
						this.ecologProGxConfiguration(sensor, response.data.affected_sensors, response.data.configuration);
					} else {
						Message.error('Error', response.data.status.text);
					}
				})
				.catch(error => Message.error('Error', 'New run could not be started', error));
		} else {
			this.props.history.push({
				pathname: '/editSensor/' + sensor.Id,
				state: {serial_number: sensor.SerialNumber},
			});
		}
	}

	sortSensors(sensors: Map<number, Sensor>): Map<number, Sensor> {
		let sortedSensors = [...sensors].sort((left, right) => {
			const sensorLeft = left[1];
			const sensorRight = right[1];

			if (sensorLeft.IsRetired && !sensorRight.IsRetired) {
				return 1;
			} else if (!sensorLeft.IsRetired == sensorRight.IsRetired) {
				return -1;
			} else {
				return right[0] - left[0];
			}
		});
		return new Map<number, Sensor>(sortedSensors);
	}

	liberoGxConfiguration(sensor: Sensor, sensors: Map<number, Sensor>, configuration: any) {
		this.props.history.push({
			pathname: '/startNewRun/' + sensor.Id,
			state: {serial_number: sensor.SerialNumber, additional_configuration: configuration, sensors: sensors},
		});
	}

	ecologProGxConfiguration(sensor: Sensor, sensors: Map<number, Sensor>, configuration: any) {
		this.props.history.push({
			pathname: '/editEcologProGx/' + sensor.Id,
			state: {serial_number: sensor.SerialNumber, additional_configuration: configuration, sensors: sensors},
		});
	}

	sensorShowSummary(sensor: Sensor) {
		this.props.history.push({
			pathname: '/sensorShowSummary/' + sensor.Id,
			state: {serial_number: sensor.SerialNumber},
		});
	}

	sensorConfigurationLastStep(sensor: Sensor) {
		LegacySensorService.repairSensor(
			{_serial_nr: sensor.SerialNumber},
			RequestLogger.createLogData('dashboard', 'repair-sensor', 'onClick')
		)
			.then(() => {
				const prefixLength = SerialNumberInfo.isValidLiberoG(sensor.SerialNumber) ? 2 : 1;
				this.props.history.push({
					pathname:
						'/connectModule/' + sensor.Id + '/' + sensor.SerialNumber + '/' + sensor.SerialNumber.substring(0, prefixLength),
				});
			})
			.catch(error => Message.error('Error', 'Sensor repair failed', error));
	}

	replaceSensor(sensor: Sensor) {
		this.props.history.push({
			pathname: '/replaceSensor/' + sensor.Id,
			state: {serial_number: sensor.SerialNumber},
		});
	}

	manageLicense(_) {
		this.props.history.push('/licenses');
	}

	retireSensor() {
		console.log('retire sensor');
	}

	deleteSensor(_: Sensor) {
		this.getSensors();
	}

	async handleSensorGroupChange(selectedGroups) {
		if (selectedGroups === undefined || selectedGroups === null) {
			return;
		}

		selectedGroups.forEach(v => {
			const groupChildes = SensorGroupsUtils.getGroupChilds(this.state.sensorGroups, v.value);
			groupChildes.forEach(group => {
				if (selectedGroups.findIndex(g => g.value === group.id) < 0) {
					selectedGroups.push({value: group.id, label: group.name});
				}
			});
		});

		this.setState(
			{
				selectedGroups,
			},
			await this.filterSensorsByGroups
		);
	}

	filterSensorsByGroups = async () => {
		const filteredSensorGroupsRef = {ref: []};
		const isFilteredGroupsFilled = !!this.state.selectedGroups && this.state.selectedGroups.length > 0;

		const groupsIds = isFilteredGroupsFilled ? this.state.selectedGroups.map(g => g.value) : [];
		SensorGroupsUtils.getOnlyGroups(this.state.sensorGroups, groupsIds, filteredSensorGroupsRef);

		if (JSON.stringify(this.state.filteredSensorGroups) === JSON.stringify(filteredSensorGroupsRef.ref)) return false;

		const {openGroups} = secureToken.getDashboardGroups();
		const params = isFilteredGroupsFilled
			? groupsIds.reduce(
					(acc, curr, i) =>
						i < groupsIds.length - 1 ? `${acc}sensors_groups_ids.cs.[${curr}],` : `${acc}sensors_groups_ids.cs.[${curr}])`,
					'&or=('
			  )
			: '';

		await this.getSensors(params);
		this.setState({filteredSensorGroups: filteredSensorGroupsRef.ref});

		secureToken.setDashboardGroups({
			groups: this.state.selectedGroups,
			openGroups:
				!!openGroups && openGroups.length > 0 && openGroups.filter(id => groupsIds.indexOf(id) >= 0).length > 0
					? openGroups
					: [groupsIds[0]],
		});

		return true;
	};

	getTreeSelectGroups(groups) {
		return groups.map(group =>
			!!group.sub_groups
				? {
						title: group.name,
						value: group.id,
						key: group.id,
						children: this.getTreeSelectGroups(group.sub_groups),
				  }
				: {
						title: group.name,
						value: group.id,
						key: group.id,
				  }
		);
	}

	startNewRun(sensor: Sensor) {
		LegacySensorService.discoverRun({s_id: sensor.Id}, RequestLogger.createLogData('dashboard', 'discover-run', 'onClick'))
			.then(response => {
				if (response.status === 200) {
					this.liberoGxConfiguration(sensor, response.data.affected_sensors, response.data.configuration);
				} else {
					Message.error('Error', response.data.status.text);
				}
			})
			.catch(error => Message.error('Error', 'New run could not be started', error));
	}

	getInnerColProps(count: number): ColProps {
		if (count == 5 || count > 6) {
			count = 6;
		}
		const computedSpan = 24 / count;
		return {
			xs: Math.max(24, computedSpan),
			sm: Math.max(12, computedSpan),
			md: Math.max(8, computedSpan),
			lg: Math.max(8, computedSpan),
			xl: Math.max(4, computedSpan),
			xxl: Math.max(4, computedSpan),
		};
	}

	getOuterColProps(count: number): ColProps {
		if (count == 5 || count > 6) {
			count = 6;
		}
		return {
			xs: 24,
			sm: count * 12,
			md: count * 12,
			lg: count * 6,
			xl: count * 4,
			xxl: count * 4,
		};
	}

	renderSenorsList = (sensorMap: Map<number, Sensor>, group = {id: null}) => {
		let sensors: Sensor[] = [];
		sensorMap.forEach(v => {
			sensors.push(v);
		});

		let sensorsGrouped = groupChannels(sensors);

		const getSensorProps = sensor => {
			return {
				sensorConfigurationLastStep: () => this.sensorConfigurationLastStep(sensor),
				analyseSensor: () => this.analyseSensor(sensor),
				activateAlarming: () => this.activateAlarming(sensor),
				deactivateAlarming: () => this.deactivateAlarming(sensor),
				sensorConfiguration: () => this.sensorConfiguration(sensor),
				sensorShowSummary: () => this.sensorShowSummary(sensor),
				startNewRun: () => this.startNewRun(sensor),
				replaceSensor: () => this.replaceSensor(sensor),
				manageLicense: () => this.manageLicense(sensor),
				retireSensor: this.retireSensor,
				onSensorDeleted: () => this.deleteSensor(sensor),
				onCalibrationStarted: () => this.onCalibrationStarted(sensor),
				onCalibrationStopped: () => this.onCalibrationStopped(sensor),
				sensor: sensor,
			};
		};

		const optimizeFor = sensors.length > OptimizeForSpeedThreshold ? OptimizeFor.Speed : OptimizeFor.Quality;

		let sensorComponents: React.JSX.Element[] = [];

		sensorsGrouped.forEach((v, k) => {
			sensorComponents.push(
				v.length > 1 ? (
					<Col
						key={`${k}-${group.id}`}
						style={colGroupParent}
						className={group.id !== null ? 'g-col-4' : ''}
						{...this.getOuterColProps(v.length)}
					>
						<Row style={rowGroupWrapper}>
							{v.map((sensor, y) => {
								return (
									<Col
										key={`${sensor.Id}-${group.id}`}
										style={y === 1 ? colGroupChildLast : colGroupChild}
										{...this.getInnerColProps(v.length)}
									>
										<SensorComponent optimizeFor={optimizeFor} {...getSensorProps(sensor)} />
									</Col>
								);
							})}
						</Row>
					</Col>
				) : (
					<Col
						key={`${v[0].Id}-${group.id}`}
						style={colSingleParent}
						className={group.id !== null ? 'g-col-2' : ''}
						{...this.getOuterColProps(v.length)}
					>
						<Row style={rowSingleWrapper}>
							<Col key={k} style={colSingleChild} {...this.getInnerColProps(1)}>
								<SensorComponent optimizeFor={optimizeFor} {...getSensorProps(v[0])} />
							</Col>
						</Row>
					</Col>
				)
			);
		});

		return sensorComponents;
	};

	onCalibrationStarted = sensor => {
		const affectedSensor = this.state.sensors.get(sensor.Id);

		affectedSensor.CalibrationState = CalibrationState.Pending;
		affectedSensor.CanStartCalibration = false;
		affectedSensor.CanStopCalibration = false;

		getAdditionallyAffectedSensors(affectedSensor, [...this.state.sensors.values()]).forEach(sensor => {
			sensor.CalibrationState = CalibrationState.Pending;
			sensor.CanStartCalibration = false;
			sensor.CanStopCalibration = false;
		});

		this.updateShownSensors();
	};

	onCalibrationStopped = sensor => {
		const affectedSensor = this.state.sensors.get(sensor.Id);

		affectedSensor.CalibrationState = CalibrationState.Stopping;
		affectedSensor.CanStartCalibration = false;
		affectedSensor.CanStopCalibration = false;

		getAdditionallyAffectedSensors(sensor, [...this.state.sensors.values()]).forEach(sensor => {
			sensor.CalibrationState = CalibrationState.Stopping;
			sensor.CanStartCalibration = false;
			sensor.CanStopCalibration = false;
		});

		this.updateShownSensors();
	};

	/**
	 * Copies a slice of the sensors into another variable, to be used by the infinite scroller
	 * @param pageNr the current ?page? the infinite scroller is on
	 */
	updateShownSensors(pageNr: number = ComputePageNumber(this.state.shownSensors.size, this.ItemsPerInfiniteScrollPage)) {
		let shownSensors = new Map<number, Sensor>([...this.state.sensors].slice(0, pageNr * this.ItemsPerInfiniteScrollPage));
		this.setState({shownSensors: shownSensors});
	}

	render() {
		return (
			<ViewWrapper>
				<ViewHeader knowledgeHelpId={'010'} heading={'Dashboard'}>
					<Access access={accessPermissions.devicesview.child.dashboard.child.addSensor} roles={userRoles.default}>
						<Col>
							<AddSensorButton />
						</Col>
					</Access>
				</ViewHeader>
				<ContentWrapper>
					{this.state.loading ? null : (
						<Space direction={'vertical'}>
							<Access
								access={accessPermissions.devicesview.child.dashboard.child.selectSensorGroups}
								roles={userRoles.default}
							>
								<Row justify={'end'}>
									<Col xs={24} sm={12} md={12} lg={6}>
										<ConfigProvider
											renderEmpty={() => (
												<div style={{textAlign: 'center'}}>
													<p>No sensor groups</p>
												</div>
											)}
										>
											<TreeSelect
												allowClear={true}
												treeData={this.getTreeSelectGroups(this.state.sensorGroups)}
												style={{width: '100%'}}
												value={this.state.selectedGroups}
												placeholder="Select sensor groups"
												showSearch={false}
												treeCheckable={true}
												treeCheckStrictly={true}
												showCheckedStrategy={TreeSelect.SHOW_ALL}
												onChange={this.handleSensorGroupChange}
											/>
										</ConfigProvider>
									</Col>
								</Row>
							</Access>
							<Row style={rowWrapper}>
								{this.state.filteredSensorGroups.length > 0 ? (
									<Groups
										groups={this.state.filteredSensorGroups}
										sensors={this.state.sensors}
										getGroups={this.getSensorGroups.bind(this)}
									>
										{({sensors, group}) => this.renderSenorsList(sensors, group)}
									</Groups>
								) : (
									<InfiniteScroll
										style={rowChild}
										pageStart={0}
										loadMore={this.updateShownSensors}
										hasMore={this.state.shownSensors.size < this.state.sensors.size}
										loader={
											<div className="loader" key={0}>
												Loading ...
											</div>
										}
									>
										<Row style={rowWrapper}>{this.renderSenorsList(this.state.shownSensors)}</Row>
									</InfiniteScroll>
								)}
							</Row>
						</Space>
					)}
				</ContentWrapper>
			</ViewWrapper>
		);
	}
}

export default Dashboard;
