import {CountriesRow} from './DbModel/CountriesRow';

export class Country {
	constructor(row: CountriesRow) {
		this.Id = row.id;
		this.DisplayName = row.display_name;
		this.NativeName = row.native_name;
	}
	public Id: string;
	public DisplayName: string;
	public NativeName: string;
}
