import React, {Suspense, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {HotKeys} from 'react-hotkeys';
import {LoggerSettingsNavigation} from './components/Infrastructure/Requests/Logger/Components';

import SystemSettings from './components/Infrastructure/Settings/SystemSettings';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import './Vendor';

// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';

// Toastify notification
import {cssTransition, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Context
import {AccessContext, AccessDispatchContext, AccessProvider} from './components/Infrastructure/Authorization/Context/AccessContext';
import {
	FeatureFlagsContext,
	FeatureFlagsDispatchContext,
	FeatureFlagsProvider,
} from './components/Infrastructure/Authorization/Context/FeatureFlagsContext';
import {MultiContextProvider} from './components/Infrastructure/Authorization/Context/MultiContext';
import pubsub from 'pubsub-js';
import {CompatRouter} from 'react-router-dom-v5-compat';
import {AuthContext, AuthContextProvider} from './common/context';

// Toast config
toast.configure({
	bodyClassName: 'toast-content',
	autoClose: 3000,
	hideProgressBar: true,
	position: 'top-center',
	transition: cssTransition({
		enter: 'zoomIn',
		exit: 'zoomOut',
	}),
});

// Logger navigation
const keys = {openLogWindow: 'alt+l'};
const handlers = {openLogWindow: _ => pubsub.publish('openLogWindow')};

const multiContextMap = {
	AccessContext,
	AccessDispatchContext,
	FeatureFlagsContext,
	FeatureFlagsDispatchContext,
	AuthContext,
};

export function App() {
	const [loading, setLoading] = useState(true);
	const [logWindowOpen, setLogWindowOpen] = useState(false);
	const [logWindowOpenToken, setLogWindowOpenToken] = useState<PubSubJS.Token>(null);
	const loggingEnabled = window.location.host === 'elcl.xyz' || window.location.port !== '';

	const basename = '/';

	useEffect(() => {
		setLogWindowOpenToken(pubsub.subscribe('openLogWindow', () => setLogWindowOpen(!logWindowOpen)));
		return () => {
			pubsub.unsubscribe(logWindowOpenToken);
		};
	}, []);

	useEffect(() => {
		SystemSettings.loadConfig()
			.then(r => {
				SystemSettings.setConfig(r.data);
			})
			.catch(error => {
				console.error(error);
			});
		setLoading(false);
	}, []);

	return (
		<HotKeys keyMap={keys} handlers={handlers}>
			{logWindowOpen && loggingEnabled ? <LoggerSettingsNavigation /> : null}
			<FeatureFlagsProvider>
				<AccessProvider>
					<AuthContextProvider>
						<MultiContextProvider map={multiContextMap}>
							<Suspense fallback="">
								<BrowserRouter basename={basename}>
									<CompatRouter>{loading ? null : <Routes />}</CompatRouter>
								</BrowserRouter>
							</Suspense>
						</MultiContextProvider>
					</AuthContextProvider>
				</AccessProvider>
			</FeatureFlagsProvider>
		</HotKeys>
	);
}

export default App;
