import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Header as ElproHeader} from './Header';
import {Sidebar} from './Sidebar';
import UserIdle from '../Infrastructure/UserIdle/UserIdle';
import pubsub from 'pubsub-js';
import {AccessContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {layoutPermissions} from '../Infrastructure/Authorization/Access';
import {Layout} from 'antd';

import styles from './styles/styles.module.scss';
import WebSockets from '../Infrastructure/WebSockets/WebSocket';
import UserSettings from '../Infrastructure/Settings/UserSettings';

const {Header, Content, Sider} = Layout;

export function Base(props: {children: React.JSX.Element | React.JSX.Element[]}): React.JSX.Element[] | React.JSX.Element {
	const accessContext = useContext(AccessContext);
	const history = useHistory();
	const showSidebar = userMayNavigate();
	const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
	const sessionExpiredSubscription = pubsub.subscribe('sessionExpired', () => history.push('/login'));

	WebSockets.init();
	UserSettings.decodeJwt();

	useEffect(() => {
		return () => {
			pubsub.unsubscribe(sessionExpiredSubscription);
		};
	});

	function userMayNavigate(): boolean {
		if (accessContext.user && accessContext.user.access_settings.layout) {
			const {navigation} = accessContext.user.access_settings.layout;
			return navigation[layoutPermissions.navigation.navigation.key];
		}

		return true;
	}

	function getContentMargin(): string {
		if (!showSidebar) {
			return '0';
		}
		if (sidebarCollapsed) {
			return '80px';
		}
		return '200px';
	}

	return (
		<Layout className={styles.layout}>
			<Header className={styles.header}>
				<ElproHeader />
			</Header>
			<Layout>
				{showSidebar && (
					<Sider
						breakpoint={'md'}
						collapsible={true}
						collapsed={sidebarCollapsed}
						onCollapse={value => setSidebarCollapsed(value)}
						className={styles.sider}
					>
						<Sidebar collapsed={sidebarCollapsed} />
					</Sider>
				)}
				<Content style={{marginLeft: getContentMargin()}} className={styles.content}>
					<UserIdle />
					{props.children}
				</Content>
			</Layout>
		</Layout>
	);
}

export default Base;
