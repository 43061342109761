import axios, {AxiosRequestConfig} from 'axios';
import appSettings from '../Settings/AppSettings';
import pubsub from 'pubsub-js';
import {secureToken} from '../Authorization/Utils';
import {InterceptorHook} from './Logger';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

const client = axios.create({
	baseURL: appSettings.getBaseUrl(),
});

client.interceptors.request.use(config => {
	InterceptorHook.hookRequestData(config);
	return config;
});

client.interceptors.response.use(response => {
	InterceptorHook.hookResponseData(response);
	return response;
});

export const request = function (options: AxiosRequestConfig & {token?: string; eventData?: any}) {
	const onSuccess = function (response) {
		return response;
	};

	const onError = function (error) {
		if (error.response) {
			if (error.response.status === 401) {
				Authentication.logout_tokens();// 
				secureToken.removeRolesAccessToken();
				secureToken.removeFeatureFlagsToken();
				secureToken.removeDashboardGroups();
				pubsub.publish('sessionExpired');
				console.debug('Session expired');
			} else {
				console.error('Status:', error.response.status);
				console.error('Data:', error.response.data);
				console.error('Headers:', error.response.headers);
			}
		} else {
			console.error('Error Message:', error.message);
		}

		return Promise.reject(error.response || error.message);
	};

	if (options.token !== undefined) {
		if (options.headers === undefined) {
			options.headers = {};
		}
		options.headers['Authorization'] = 'Bearer ' + options.token;
		options.headers['Cache-Control'] = 'no-cache'; // without this internet explorer gets everything from cache
	}

	return client(options).then(onSuccess).catch(onError);
};

export default request;
