import React, {useState} from 'react';
import {Col, Row, Spin} from 'antd';
import Close from '../../../Shared/Components/Close';
import TermsOfUseAndOk from '../../../Shared/Components/TermsOfUseAndOk';
import OfferingTable from '../../../Dashboard/Sensor/Utils/OfferingTable';
import InputVoucher from '../../../Dashboard/Sensor/Utils/InputVoucher';
import LicensingService from '../../Licenses/LicensingService';
import Message from '../../../Shared/Components/Message';
import RequestLogger from '../../../Infrastructure/Requests/Logger/RequestLogger';
import {useNavigate} from 'react-router-dom-v5-compat';
import {ContentWrapper} from '../../../Layout';
import {ViewHeader} from '../../../Common';

const RechargePrepaid = props => {
	const [vouchers, setVouchers] = useState([]);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const close = () => navigate('/billing');

	const rechargeCredit = () => {
		const payload = {
			vouchers: vouchers,
			credid_card: '',
		};

		setLoading(true);
		LicensingService.rechargeCredit(payload, RequestLogger.createLogData('billing', 'recharge-credit', 'onClick'))
			.then(() => {
				Message.success('Prepaid credid successfully recharged', '');
				setVouchers([]);
				setLoading(false);
			})
			.catch(error => {
				setLoading(false);
				Message.error('Error', 'Prepaid credid recharge failed', error);
			});
	};

	return (
		<>
			<ViewHeader heading="Recharge Prepaid Credit">
				<Col>
					<Close onClick={close} />
				</Col>
			</ViewHeader>
			<ContentWrapper>
				<Spin spinning={loading}>
					<Row className="d-block">
						<Col sm={12} md={12} lg={12} xl={12}>
							<h3 style={{fontWeight: 'normal'}}>Vouchers</h3>
							<OfferingTable
								loading={false}
								vat_amount={0}
								lines={vouchers.map(({code, amount}) => ({description: code, actual_price: -amount}))}
								actual_price={vouchers.reduce((sum, x) => sum + x['amount'], 0)}
								show_voucher_price_as_positive_amount={true}
								onRemoveElementByDescription={desc => setVouchers(vouchers.filter(v => v.code !== desc))}
							/>
						</Col>
					</Row>
					<Row className="d-block">
						<Col sm={12} md={12} lg={12} xl={12}>
							<h3 style={{fontWeight: 'normal'}}>Voucher</h3>
							<InputVoucher
								id="inputVoucher"
								className="form-control"
								mask="####-####-####"
								vouchers={vouchers}
								afterVoucherChecked={v => {
									setVouchers([...vouchers, v]);
								}}
								readOnly={props.readOnly}
							/>
						</Col>
					</Row>
					<br />
					<Row>
						<Col>
							<TermsOfUseAndOk okText="Recharge" onOk={rechargeCredit} isLoading={!vouchers.length || loading} />
						</Col>
					</Row>
				</Spin>
			</ContentWrapper>
		</>
	);
};

export default RechargePrepaid;
