import moment from 'moment';
import Shared from '../../../Shared/Shared';
import DateTimeUtils from '../../../../components/Infrastructure/DateTime/DateTimeUtils';

const calibrationAnalysisChartConfiguration = props => {
	return {
		credits: {enabled: false},
		chart: {
			zoomType: 'x',
			height: 400,
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
			borderRadius: 0,
			spacing: [5, 20, 20, 0],
			animation: false,
			accessibility: {
				keyboardNavigation: {
					enabled: true,
				},
			},
		},
		title: {
			text: null,
		},
		xAxis: {
			type: 'datetime',
			labels: {
				formatter: function () {
					const usertime = DateTimeUtils.addUserUTCOffsetToDateTime_date_dep(this.value);
					return '<div>' + moment.utc(usertime).format('DD.MMM.YYYY HH:mm') + '</div>';
				},
				staggerLines: 2,
				y: 20,
				x: 4,
			},
			events: {
				afterSetExtremes: function (event: {trigger: string}) {
					if (event.trigger === 'zoom' && typeof props.handleChartZoom === 'function') {
						props.handleChartZoom(event);
					}
				}.bind(this),
			},
		},
		yAxis: {
			title: {
				text: null,
			},
			labels: {
				formatter: function () {
					return `${this.value} ${Shared.getSensorUnit(props.calibration.Sensor.UnitID)}`;
				},
			},
		},
		tooltip: {
			enabled: true,
			useHTML: true,
			animation: false,
			formatter: function () {
				if (this.series.index === 0) {
					const usertime = DateTimeUtils.addUserUTCOffsetToDateTime_date_dep(this.x);
					return (
						'' +
						'<table>' +
						'  <tr><td>Date</td><td>' +
						moment.utc(usertime).format('DD.MMM.YYYY HH:mm') +
						'</td></tr>' +
						'  <tr><td>Value</td><td>' +
						this.y +
						' ' +
						Shared.getSensorUnit(props.outUnitsId) +
						'</td></tr>' +
						'</table>'
					);
				} else {
					return false;
				}
			},
		},
		legend: {
			enabled: false,
		},
		plotOptions: {
			series: {
				turboThreshold: 0,
				animation: false,
				marker: {
					enabled: false,
					symbol: 'circle',
					states: {
						hover: {
							enabled: true,
							lineWidth: 1,
							radius: 5,
						},
					},
				},
				states: {
					hover: {
						enabled: true,
					},
				},
			},
		},
		series: [],
	};
};

export default calibrationAnalysisChartConfiguration;
