import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type IssueAlarmsUseIssueAlarmProps = {
	sensorIssuesForm: any;
	hasError: any;
	show: boolean;
};

export default function IssueAlarmsIssueNameComponent(props: IssueAlarmsUseIssueAlarmProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.sensorIssueName'} />
				</td>
				{props.hasError('sensor_issue_alarm_name') ? (
					<td style={{color: 'red'}}>
						{props.sensorIssuesForm.limit_issue_name} <Trans i18nKey={'sensorWizard.summary.notUnique'} />
					</td>
				) : (
					<td>{props.sensorIssuesForm.use_issue_alarms ? props.sensorIssuesForm.limit_issue_name : CONSTANTS.NOT_AVAILABLE}</td>
				)}
			</tr>
		)
	);
}
