import React, {Component} from 'react';
import {Select} from 'antd';
import {Label, Row, Col, FormGroup} from 'reactstrap';
const {Option} = Select;

type ModuleChannelSelectProps = {
	value: string | undefined;
	onChange: any;
	disabled: boolean;
	sensorBasicsForm: any;
};

class ModuleChannelSelectState {
	onChange: any;
}

export class ModuleChannelSelectComponent extends Component<ModuleChannelSelectProps, ModuleChannelSelectState> {
	constructor(props: ModuleChannelSelectProps | Readonly<ModuleChannelSelectProps>) {
		super(props);
		this.state = {
			onChange: props.onChange,
		};
	}

	render() {
		return (
			<Row>
				<Col xl={3}>
					<FormGroup>
						<Label>Sensor Channel</Label>
						<div>
							<Select
								style={{width: '100%'}}
								defaultValue={this.props.value}
								id="selectModuleChannel"
								value={this.props.value}
								onChange={this.state.onChange}
								disabled={this.props.disabled}
							>
								{this.props.sensorBasicsForm.currentModuleChannels.channelsFree.map(channel => {
									return channel.channel_types.map((type, index) => {
										let value = channel.channel_number + '-' + type.value_index;
										return (
											<Option id={value} key={index} value={value}>
												Channel {channel.channel_number.toString()}, {type.symbolValue}
											</Option>
										);
									});
								})}
							</Select>
						</div>
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
