import {Sensor} from '../../components/Common/Types/Sensor';
import SerialNumberInfo from '../../components/Shared/SerialNumberInfo';
import FindMaxChannelCount from './FindMaxChannelCount';
import {DeviceUtils} from './DeviceUtils';
import {EcologProRadioType} from '../constants/DeviceType';
import {SensorService} from '../services';

export const getGroupingKey = (sensor: Sensor, channelGroups: Map<string, Sensor[]>): string => {
	let groupingKey = sensor.SerialNumber;

	if (sensor.IsRetired) {
		groupingKey += '_retired';
		let idx = 0;
		let currentKey = '';
		do {
			currentKey = groupingKey + '_' + idx;
			idx++;
		} while (channelGroups.has(currentKey) && channelGroups.get(currentKey).length >= FindMaxChannelCount(sensor.SerialNumber));
		groupingKey = currentKey;
	}
	return groupingKey;
};

export const groupChannels = (sensors: Sensor[]): Map<string, Sensor[]> => {
	let initialValue = new Map<string, Sensor[]>();
	if (!sensors || typeof sensors !== typeof ([] as Sensor[])) {
		return initialValue;
	}
	return sensors.reduce((channelGroups, sensor) => {
		const groupingKey = getGroupingKey(sensor, channelGroups);
		if (!channelGroups.has(groupingKey)) {
			channelGroups.set(groupingKey, []);
		}

		const group = channelGroups.get(groupingKey);
		group.push(sensor);
		group.sort((left, right) => {
			if (left.ChannelNumber == right.ChannelNumber) {
				return left.ValueIndex - right.ValueIndex;
			}
			return left.ChannelNumber - right.ChannelNumber;
		});

		return channelGroups;
	}, initialValue);
};

export const getAdditionallyAffectedSensors = (sensor: Sensor, sensors: Sensor[]) => {
	let affected_sensors = [];
	if (!SerialNumberInfo.isValidEcolog2PTR(sensor.SerialNumber)) {
		const groupedChannels = groupChannels(sensors);
		affected_sensors = groupedChannels.get(sensor.SerialNumber).filter(s => s.Id !== sensor.Id);
	}

	return affected_sensors;
};

export async function getAdditionallyAffectedSensorsAsync(sensor: Sensor): Promise<Sensor[]> {
	if (DeviceUtils.GetDeviceType(sensor) === EcologProRadioType.TwoPTR) {
		return [];
	}

	let sensors: Sensor[] = [];
	try {
		sensors = await SensorService.GetSensorsFromDashboardInfo();
	} catch (e) {
		return await Promise.reject(e);
	}

	return getAdditionallyAffectedSensors(sensor, sensors);
}
