import {DbUser} from './DbModel/DbUser';
import i18next from 'i18next';

type RecipientType = keyof Pick<User, 'Email' | 'Mobile'>;

export class User {
	public Id: number;
	public Email: string;
	public FirstName: string;
	public LastName: string;
	public Mobile: string;

	constructor(init: Partial<DbUser>) {
		this.Id = init.id;
		this.Email = init.email;
		this.FirstName = init.first_name;
		this.LastName = init.last_name;
		this.Mobile = init.mobile;
	}

	private formatUserValue(key: RecipientType, t: i18next.TFunction): string {
		if (this[key] && this[key] != '') {
			return this[key].toString();
		}
		let transKey: string;
		switch (key) {
			case 'Email':
				transKey = 'noEmail';
				break;
			case 'Mobile':
				transKey = 'noPhoneNumber';
				break;
			default:
				transKey = 'notSet';
		}
		return t(`sensorWizard.recipients.${transKey}`).toString();
	}

	public GetLabel(type: RecipientType, t?: i18next.TFunction): string {
		if (!t) {
			t = i18next.t;
		}

		return `${this.FirstName} ${this.LastName} (${this.formatUserValue(type, t)})`;
	}
}

export interface CreateUserRequest {
	email: string;
	firstName: string;
	lastName: string;
	mobile: string;
	roles: string[];
}

export interface UpdateUserAccessSettingsRequest {
	userId: bigint;
	accessSettings: any;
}
