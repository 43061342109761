import React, {Component} from 'react';
import {Trans} from 'react-i18next';

class LoadingOverlayComponent extends Component {
    render() {
        return (
            <>
            Loading
                {/* <Trans i18nKey={'datagrid.loadingOverlay'} />  */}
            </>
        );
    }
}

export default LoadingOverlayComponent;
