import React from 'react';
import {CarryOutOutlined} from '@ant-design/icons';

const AccessSettingsTree = [
	{
		title: 'Devices View',
		key: '0-0',
		path: ['devicesview'],
		type: 'page',
		icon: <CarryOutOutlined />,
		children: [
			{
				title: 'Dashboard',
				key: '0-0-0',
				path: ['devicesview', 'dashboard'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'List View',
				key: '0-0-1',
				path: ['devicesview', 'datagrid'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
		],
	},
	{
		title: 'Deviations',
		key: '0-1',
		path: ['deviations'],
		type: 'page',
		icon: <CarryOutOutlined />,
	},
	{
		title: 'Audit Trail',
		key: '0-3',
		path: ['auditTrail'],
		type: 'page',
		icon: <CarryOutOutlined />,
	},
	{
		title: 'Calibrations',
		key: '0-4',
		path: ['calibrations'],
		type: 'page',
		icon: <CarryOutOutlined />,
	},
	{
		title: 'Settings',
		key: '0-5',
		path: ['settings'],
		type: 'node',
		icon: <CarryOutOutlined />,
		children: [
			{
				title: 'Sensor Groups',
				key: '0-5-0',
				path: ['settings', 'sensorGroups'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'Limit Profiles',
				key: '0-5-1',
				path: ['settings', 'limitProfiles'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'Issue Profiles',
				key: '0-5-2',
				path: ['settings', 'issueProfiles'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'Licences',
				key: '0-5-3',
				path: ['settings', 'licenses'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'Invoices & Cards',
				key: '0-5-4',
				path: ['settings', 'billing'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'Organization',
				key: '0-5-5',
				path: ['settings', 'organization'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'User Profile',
				key: '0-5-6',
				path: ['settings', 'userProfile'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
			{
				title: 'User Management',
				key: '0-5-7',
				path: ['settings', 'userManagement'],
				type: 'page',
				icon: <CarryOutOutlined />,
			},
		],
	},
];

export default AccessSettingsTree;
