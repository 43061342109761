import React, {Component} from 'react';

class LoadingOverlay extends Component {
    render() {
        return (
            <>
            Loading               
            </>
        );
    }
}

export default LoadingOverlay;
