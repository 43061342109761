import React from 'react';
import {Link} from 'react-router-dom';
import SensorDropdown from '../SensorDropdown';
import {CaretRightFilled, LoadingOutlined} from '@ant-design/icons';
import {DeviceState} from '../../../../common/constants';
import {Sensor} from '../../../Common/Types/Sensor';
import {RADIO_STRENGTH_STRINGS} from './SignalStrength/Constants/RadioStrengthEnums';
import {SignalStrengthComponent} from './SignalStrength/SignalStrengthComponent';
import {Col, Row} from 'antd';
import {WarningIcon, WarningIconSettings} from '../../../Common/WarningIcon';

interface SensorHeaderProps {
	onSensorDeleted: any;
	onCalibrationStopped: any;
	onCalibrationStarted: any;
	fillingColor: string;
	sensor: Sensor;
	sensorConfigurationLastStep: any;
}

const SensorHeader = (props: SensorHeaderProps) => {
	const sensor = props.sensor;
	const iconLink = `deviations?moduleId=${sensor.SerialNumber}`;
	const iconLinkTitle = 'View Deviations';

	const getTitle = () => {
		if (sensor.IsRetired) {
			return 'RETIRED';
		}

		if (sensor.CalibrationState) {
			return DeviceState[`CALIBRATION_${sensor.CalibrationState}`].toUpperCase();
		}

		if (sensor.ModuleFamiliesId === 3) {
			return sensor.LiberoGStateDescription;
		}

		if (sensor.State === 'prepared') {
			return 'Sensor not yet started';
		}

		return '';
	};

	const getIconSettings = (type: 'warning' | 'alarm'): WarningIconSettings | undefined => {
		const isWarning = type === 'warning';
		if (!sensor.Deviations) {
			return undefined;
		}

		const alarms = sensor.Deviations.filter(d => d.Warning === isWarning);
		if (alarms.length === 0) {
			return undefined;
		}

		const allAlarmsAcknowledged = alarms.filter(d => !d.Acknowledged).length === 0;
		return {
			isAlarm: !isWarning,
			isInactive: false,
			isAcknowledged: allAlarmsAcknowledged,
		};
	};

	let alarmIconSettings = getIconSettings('alarm');
	let warningIconSettings = getIconSettings('warning');

	const signalStrength = RADIO_STRENGTH_STRINGS[sensor.SignalStrength];

	let headerLeft: React.JSX.Element[] = [];
	let headerRight: React.JSX.Element[] = [];

	if (!sensor.IsRetired) {
		if (sensor.IsMuted) {
			headerLeft.push(<WarningIcon event={{isAlarm: true, isInactive: true}} key={'mutedIcon'} />);
		}

		if (sensor.State === 'prepared') {
			if (sensor.IsPairing) {
				headerLeft.push(<LoadingOutlined id={'btn' + sensor.Name + 'Pairing'} style={{color: '#3d9bfe'}} key={'pairingIcon'} />);
			} else {
				headerLeft.push(
					<CaretRightFilled
						style={{color: '#50af47'}}
						id={'btn' + sensor.Name + 'Start'}
						onClick={props.sensorConfigurationLastStep}
						key={'startButton'}
					/>
				);
			}
		}

		if (alarmIconSettings) {
			headerLeft.push(
				<Link to={iconLink} title={iconLinkTitle} key={'alarmIconLink'}>
					<WarningIcon event={alarmIconSettings} key={'alarmIcon'} />
				</Link>
			);
		}
	}

	if (warningIconSettings) {
		headerLeft.push(
			<Link to={iconLink} title={iconLinkTitle} key={'warningIconLink'}>
				<WarningIcon event={warningIconSettings} key={'warningIcon'} />
			</Link>
		);
	}

	if (sensor.SignalStrength) {
		headerRight.push(<SignalStrengthComponent signalStrength={signalStrength} key={'signalStrengthComponent'} />);
	}
	headerRight.push(
		<SensorDropdown
			sensor={sensor}
			onDeleteSensor={props.onSensorDeleted}
			onCalibrationStarted={props.onCalibrationStarted}
			onCalibrationStopped={props.onCalibrationStopped}
			key={'sensorDropdown'}
			buttonSize={'small'}
		/>
	);

	const gutter: number = 8;

	const leftRightColSpan: number = Math.max(headerLeft.length, headerRight.length) * 3;
	const titleColSpan: number = 24 - 2 * leftRightColSpan;

	return (
		<div style={{margin: '.5em'}}>
			<Row justify={'space-between'} wrap={false}>
				<Col span={leftRightColSpan}>
					<Row gutter={gutter} wrap={false} justify={'start'}>
						{headerLeft.map((child, idx) => (
							<Col key={`headerLeft${idx}`}>{child}</Col>
						))}
					</Row>
				</Col>
				<Col
					style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center'}}
					title={getTitle()}
					span={titleColSpan}
				>
					{getTitle()}
				</Col>
				<Col span={leftRightColSpan}>
					<Row gutter={gutter} wrap={false} justify={'end'}>
						{headerRight.map((child, idx) => (
							<Col key={`headerRight${idx}`}>{child}</Col>
						))}
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default SensorHeader;
