import {LockRenderer} from './LockRenderer';
import {COLUMNS} from '../../Constants/Columns';

export const validateParamsToRenderLockComponent = params => {
	const lock = {
		component: LockRenderer,
	};
	if (params.colDef && params.data) {
		if (params.colDef.field === COLUMNS.LOCKED) {
			return lock;
		}
	}
	return undefined;
};
