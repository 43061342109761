import {SweetAlertResult} from 'sweetalert2';
import {DialogResult} from './DialogResult';

export class SweetAlertDialogResult implements DialogResult {
	private readonly result: SweetAlertResult;
	constructor(result: SweetAlertResult) {
		this.result = result;
	}

	IsConfirmed(): boolean {
		return this.result.isConfirmed;
	}
}
