import React from "react";
import pubsub from "pubsub-js";

const WithGeoDataSubscription = (MapComponent) => {
    return class extends React.Component 
    {
        constructor(props) {
            super(props);

            this.state = {
                newGeoPoint: null
            };
        }

        componentDidMount() 
        {
            const newGeoDataTopic = 'geo_data_new/' + this.props.sensorId;
            this.handleNewGeoDataToken = pubsub.subscribe(newGeoDataTopic, (topic, data) => this.setState({newGeoPoint: data}));
        }

        componentWillUnmount() 
        {
            pubsub.unsubscribe(this.handleNewGeoDataToken);
        }

        render()
        {
            const {forwardedRef, ...rest} = this.props;

            return <MapComponent 
                        ref={forwardedRef} 
                        newGeoPoint={this.state.newGeoPoint} 
                        {...rest} 
                    />
        }

    }
}

export default WithGeoDataSubscription;
