import React from 'react';
import {useDrag, useDrop} from 'react-dnd';
import SensorGroupsUtils from '../../Settings/SensorGroups/SensorGroupsUtils';

const Group = React.memo(({group, index, sortable, moveGroup, findGroup, children}) => {

    const [{isDragging}, drag] = useDrag({
        item: {type: `groupItem-${group.sensors_group_parent}`, id: group.id, index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const [, groupDrop] = useDrop({
        accept: `groupItem-${group.sensors_group_parent}`,
        hover({id: draggedId}) {
            if (draggedId !== group.id) {
                const {index: draggedIndex, group} = findGroup(draggedId);
                moveGroup(draggedIndex, index, group.sensors_group_parent);
            }
        },
    });

    const opacity = (isDragging && !SensorGroupsUtils.isTouchDevice()) ? 0.125 : 1;
    return (
        <div className="dashboard__drag--item" style={{opacity}}>
            {
                sortable &&
                <button ref={node => drag(groupDrop(node))} className="dashboard__drag--ref">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            }
            {children({isDragging})}
        </div>
    );
});

export default Group;