import OlOverlay from 'ol/Overlay';

const getFeatureCluster = (feature) => 
{
    if (feature.values_ === undefined || feature.values_ === null) 
        return null;

    let markerFeatures = [];
    if (feature.values_.features !== undefined && feature.values_.features !== null) 
    {
        if (feature.values_.features.length > 0 ) 
        {
            markerFeatures = feature.values_.features.filter(f => f.values_.isMarker === true);
            markerFeatures = markerFeatures.sort((a, b) => new Date(a.values_.occurrenceDate) > new Date(b.values_.occurrenceDate) ? 1 : -1)
        }
    }

    return markerFeatures.length > 0 ? markerFeatures : null;
}

// const getFeatureMarkers = (feature) => 
// {
//     if (feature.values_ === undefined || feature.values_ === null) 
//         return null;

//     if (!feature.values_.isMarker)
//         return null;
    
//     const markerFeatures = feature.values_.datas.map(d => ({values_: d}));

//     return markerFeatures.length > 0 ? markerFeatures : null;
// }

const GeoTooltip = (map, tooltipEl) => {

    let tooltip = new OlOverlay({
        element: tooltipEl,
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -50]
    });

    map.on('pointermove', function (evt) {
        let feature = map.forEachFeatureAtPixel(evt.pixel, feature => feature);

        if (feature) {
            const markersFeature = getFeatureCluster(feature);
            if (markersFeature === null) return;

            let coordinates = feature.getGeometry().getCoordinates();
            tooltip.setPosition(coordinates);

            const firstOccurrenceDateDate = !!markersFeature[0].values_.occurrenceDate ? markersFeature[0].values_.occurrenceDate : '';
            tooltipEl.innerHTML = `<span>${firstOccurrenceDateDate}</span>`;

            if (markersFeature.length > 1) 
            {
                const lastIndex = markersFeature.length - 1;
                const lastOccurrenceDateDate = !!markersFeature[lastIndex].values_.occurrenceDate ? markersFeature[lastIndex].values_.occurrenceDate : '';
                tooltipEl.innerHTML += `<br/><span>${lastOccurrenceDateDate}</span>`;   
            }
            const isCurrent = markersFeature.some(f => f.values_.isCurrent);
            if (isCurrent)
                tooltipEl.classList.add("current_point");
            else
                tooltipEl.classList.remove("current_point");
                
            tooltipEl.classList.add("show");
        }
        else 
        {
            tooltipEl.classList.remove("show");
        }
    });

    map.on('pointermove', function (e) {
        if (e.dragging) {
            // tooltipEl.classList.remove("show")
            return;
        }

        let pixel = map.getEventPixel(e.originalEvent);
        let hit = map.hasFeatureAtPixel(pixel);
        map.getTarget().style.cursor = hit ? 'pointer' : '';
    });

    return tooltip;
}

export default GeoTooltip;
