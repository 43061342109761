import React from "react";
import {Button} from "reactstrap";
import {useTranslation} from 'react-i18next';

const Save = (props) => {
    const [t] = useTranslation();
    return (
        <Button id="btnSave" color="primary" size="lg" type="submit" disabled={props.disabled}>
            <span>{t('buttons.save')}</span>
        </Button>
    );
};

export default Save;