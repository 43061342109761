import React from 'react';
import {Trans} from 'react-i18next';

type SensorBasicsTitleProps = {
	show: boolean;
};

export default function SensorBasicsTitleComponent(props: SensorBasicsTitleProps) {
	return (
		props.show && (
			<tr style={{backgroundColor: '#b1b3b3'}}>
				<td width="30%">
					<Trans i18nKey={'sensorWizard.summary.sensorBasics'} />
				</td>
				<td />
			</tr>
		)
	);
}
