import {MODULE_FAMILIES} from '../../../Shared/Constants/Module';
import findFirmwareVersionFromSerialNumber from '../../../Shared/Extensions/DeviceFirmwareVersionFinder';
import SerialNumberInfo from '../../../Shared/SerialNumberInfo';
import {LicensesSettings} from './LicensesSettings';

export const getLicensesPaymentMethodSettings = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = LicensesSettings.ecologProRadioSettings.sensorPaymentMethod;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = LicensesSettings.liberoGFirmware0Settings.sensorPaymentMethod;
			}
			if (firmwareVersion >= 1) {
				settings = LicensesSettings.liberoGFirmware1Settings.sensorPaymentMethod;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = LicensesSettings.ecologProGSettings.sensorPaymentMethod;
		default:
			break;
	}
	return settings;
};
