import React, {Component, ReactNode} from 'react';
import {Table} from 'reactstrap';
import {CalibrationAnalysisStatisticsProps, CalibrationAnalysisStatisticsState} from './CalibrationAnalysisStatisticsProps';
import {Trans, Translation} from 'react-i18next';
import Statistics from '../../../Services/Extensions/Statistics';
import SensorUtils from '../../../Dashboard/Sensor/SensorUtils';
import Shared from '../../../Shared/Shared';
import DateTimeUtils from '../../../Infrastructure/DateTime/DateTimeUtils';

class CalibrationAnalysisStatistics extends Component<CalibrationAnalysisStatisticsProps, CalibrationAnalysisStatisticsState> {
	constructor(props: CalibrationAnalysisStatisticsProps | Readonly<CalibrationAnalysisStatisticsProps>) {
		super(props);

		this.state = {
			measuresTemparature: true,
			statistics: {
				mkt: 0,
				countValidMeasValues: 0,
				statisticResult: {
					maxValue: 0,
					maxValueDateTime: undefined,
					minValue: 0,
					minValueDateTime: undefined,
					average: 0,
					duration: '',
				},
			},
		};
	}

	async componentDidMount(): Promise<void> {
		const response = await Statistics.getCalibrationStatistics(this.props.calibration.CalibrationID);
		this.setState({statistics: response.data, measuresTemparature: response.data.outUnitsId !== 4});
	}

	async componentDidUpdate(prevProps: CalibrationAnalysisStatisticsProps) {
		if (prevProps.displayStartDate !== this.props.displayStartDate || prevProps.displayEndDate !== this.props.displayEndDate) {
			const response = await Statistics.getCalibrationStatistics(
				this.props.calibration.CalibrationID,
				this.props.displayStartDate.toDate(),
				this.props.displayEndDate.toDate()
			);

			this.setState({statistics: response.data, measuresTemparature: response.data.outUnitsId !== 4});
		}
	}

	render(): ReactNode {
		const calibration = this.props.calibration;
		const sensor = calibration.Sensor;

		const loggingIntervalInMinutes = Math.round((10 / 60 + Number.EPSILON) * 100) / 100; //per definition during calibrations

		return (
			<Table striped responsive className="col-md-12">
				<tbody>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.calibration_id'} />
						</td>
						<td width="35%">{calibration.CalibrationID}</td>
						<td width="15%">
							<Trans i18nKey={'sensorWizard.summary.loggingInterval'} />
						</td>
						<td width="35%">
							{loggingIntervalInMinutes} <Trans i18nKey={'sensorWizard.summary.minutes'} />
						</td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.sensor_id'} />
						</td>
						<td width="35%">{sensor.ID}</td>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.measurements_count'} />
						</td>
						<td width="35%">{this.state.statistics.countValidMeasValues}</td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.customer_id'} />
						</td>
						<td width="35%">{sensor.CustomerID}</td>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.duration'} />
						</td>
						<td width="35%">{DateTimeUtils.formatDurationTime(this.state.statistics.statisticResult.duration)}</td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.highest_value'} />
						</td>
						<td width="35%">
							{this.state.statistics ? (
								<Translation>
									{t => (
										<>
											{SensorUtils.convertTemperature(this.state.statistics.statisticResult.maxValue, sensor.UnitID)}
											&nbsp;
											{Shared.getSensorUnit(sensor.UnitID)}&nbsp;
											{DateTimeUtils.setDateTimeWithOffset_date_dep(
												this.state.statistics.statisticResult.maxValueDateTime
											)}
										</>
									)}
								</Translation>
							) : (
								<Trans i18nKey={'global.n_a'} />
							)}
						</td>
						<td width="15%"></td>
						<td width="35%"></td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.lowest_value'} />
						</td>
						<td width="35%">
							{this.state.statistics && sensor.UnitID ? (
								<Translation>
									{t => (
										<>
											{SensorUtils.convertTemperature(this.state.statistics.statisticResult.minValue, sensor.UnitID)}
											&nbsp;
											{Shared.getSensorUnit(sensor.UnitID)}&nbsp;
											{DateTimeUtils.setDateTimeWithOffset_date_dep(
												this.state.statistics.statisticResult.minValueDateTime
											)}
										</>
									)}
								</Translation>
							) : (
								<Trans i18nKey={'global.n_a'} />
							)}
						</td>
						<td width="15%"></td>
						<td width="35%"></td>
					</tr>
					<tr style={{borderBottom: '1px solid #eee'}}>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.average'} />
						</td>
						<td width="35%">
							{this.state.statistics ? (
								<Translation>
									{t => (
										<>
											{SensorUtils.convertTemperature(this.state.statistics.statisticResult.average, sensor.UnitID)}
											&nbsp;
											{Shared.getSensorUnit(sensor.UnitID)}
										</>
									)}
								</Translation>
							) : (
								<Trans i18nKey={'global.n_a'} />
							)}
						</td>
						<td width="15%">{this.state.measuresTemparature && <Trans i18nKey={'sensor.statistics.mkt'} />}</td>
						<td width="35%">
							{this.state.measuresTemparature &&
								(this.state.statistics ? (
									<Translation>
										{t => (
											<>
												{SensorUtils.convertTemperature(this.state.statistics.mkt, sensor.UnitID)}{' '}
												{Shared.getSensorUnit(sensor.UnitID)}, {t('global.mkt_mol')}
											</>
										)}
									</Translation>
								) : (
									<Trans i18nKey={'global.n_a'} />
								))}
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default CalibrationAnalysisStatistics;
