import React from 'react';
import {Access} from '../../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../../Infrastructure/Authorization/Access';
import {ActionButton, ActionButtonAction} from '../../../Shared/Components/ActionButton';

class ButtonsComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			locked: props.locked,
			id: props.id,
		};
	}

	render() {
		return (
			<>
				<Access
					access={accessPermissions.settings.child.issueProfiles.child.limitProfile.child.addIssueProfile}
					roles={userRoles.default}
				>
					<ActionButton
						id={'btnCopy-' + this.state.id}
						actionType={ActionButtonAction.Copy}
						onClick={this.props.copyIssueProfile}
						locked={false}
					/>
				</Access>
				<Access
					access={accessPermissions.settings.child.issueProfiles.child.limitProfile.child.editIssueProfile}
					roles={userRoles.default}
				>
					<ActionButton
						id={'btnEdit-' + this.state.id}
						actionType={ActionButtonAction.Edit}
						onClick={this.props.editIssueProfile}
						locked={this.state.locked}
					/>
				</Access>
				<Access
					access={accessPermissions.settings.child.issueProfiles.child.limitProfile.child.deleteIssueProfile}
					roles={userRoles.default}
				>
					<ActionButton
						id={'btnDelete-' + this.state.id}
						actionType={ActionButtonAction.Delete}
						onClick={this.props.deleteIssueProfile}
						locked={this.state.locked}
					/>
				</Access>
			</>
		);
	}
}

export default ButtonsComponent;
