import React, {useContext, useEffect, useState} from 'react';
import DeviceStatusService from '../DeviceStatus/DeviceStatusService';
import {authUtils, secureToken} from '../Infrastructure/Authorization/Utils';
import Menu from '../../Menu';
import Authentication from '../Infrastructure/Authentication/Authentication';
import {AccessDispatchContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {useTranslation} from 'react-i18next';
import {FeatureFlagsDispatchContext} from '../Infrastructure/Authorization/Context/FeatureFlagsContext';
import {FeatureFlagHelper} from '../../common/helpers';
import {StatusAlertProps} from './StatusAlert';
import * as PublicViewCommon from './Common';
import {PublicViewValidationComponent} from './PublicViewValidationComponent';

const DeviceStatus = ({...props}) => {
	const [serialForm, setSerialForm] = useState(() => {
		const {serialNumber} = props.match.params;
		const serial = serialNumber ? serialNumber.split(';')[0] : undefined;
		return {serial};
	});
	const [t] = useTranslation();
	const setFeatureFlags = useContext(FeatureFlagsDispatchContext);

	// alert msg text
	const ALERT_TXT = {
		MESSAGE_OK: t('device_status.alert.message.ok'),
		MESSAGE_ERROR: t('device_status.alert.message.nok'),
		DESC_OK: t('device_status.alert.description.ok'),
		DESC_ERROR: <div dangerouslySetInnerHTML={{__html: t('device_status.alert.description.nok')}} />,
		MESSAGE_ERROR_REDIRECT: t('device_status.alert.message.nok_redirect'),
		DESC_ERROR_REDIRECT: t('device_status.alert.description.nok_redirect'),
	};

	// step description text
	const STEP_DESCRIPTION = {
		0: {
			START: t('device_status.steps.description.0.start', {serial: serialForm.serial}),
			PROCESS: t('device_status.steps.description.0.process'),
			FINISH: t('device_status.steps.description.0.finish'),
			ERROR: t('device_status.steps.description.0.error'),
		},
		1: {
			START: t('device_status.steps.description.1.start'),
			PROCESS: t('device_status.steps.description.1.process'),
			FINISH: t('device_status.steps.description.1.finish'),
		},
		2: {
			START: t('device_status.steps.description.2.start'),
			PROCESS: t('device_status.steps.description.2.process'),
			FINISH: t('device_status.steps.description.2.finish'),
			ERROR: t('device_status.steps.description.2.error'),
		},
	};

	const setUserAccess = useContext(AccessDispatchContext);
	const [currentStep, setCurrentStep] = useState(0);
	const [currentStatus, setCurrentStatus] = useState(PublicViewCommon.PROGRESS_STATUS.WAIT);
	const [disableFormElement, setDisableFormElement] = useState(true);
	const [alertProps, setAlertProps] = useState<Omit<StatusAlertProps, 'onErrorRedirect'>>({
		show: false,
		type: 'success',
		message: '',
		description: '',
	});
	const [stepDescription, setStepDescription] = useState({
		0: STEP_DESCRIPTION[0].START,
		1: STEP_DESCRIPTION[1].START,
		2: STEP_DESCRIPTION[2].START,
	});

	const delay = ms => new Promise(res => setTimeout(res, ms));

	const validateSerialNumber = async () => {
		if (!serialForm.serial) {
			setDisableFormElement(false);
			return;
		}
		setCurrentStatus(PublicViewCommon.PROGRESS_STATUS.PROCESS);
		let token: string;
		try {
			token = await DeviceStatusService.validateDeviceId(serialForm.serial);
		} catch (error) {
			console.error(error);
			token = null;
		}
		await delay(PublicViewCommon.STEP_DELAY);
		if (token) {
			// console.log(token);
			Authentication.setToken_f(token, true);
			await getAccess();
		} else {
			setCurrentStatus(PublicViewCommon.PROGRESS_STATUS.ERROR);
			setStepDescription({...stepDescription, 0: STEP_DESCRIPTION[0].ERROR});
			setDisableFormElement(false);
			setAlertProps({
				...alertProps,
				show: true,
				type: 'error',
				description: ALERT_TXT.DESC_ERROR,
				message: ALERT_TXT.MESSAGE_ERROR,
			});
		}
	};

	const getAccess = async () => {
		setStepDescription({...stepDescription, 0: STEP_DESCRIPTION[0].FINISH});
		setCurrentStep(2);
		try {
			const access = await DeviceStatusService.getUserAccess('liberog-sensor-status');
			const decodedAccessToken = authUtils.decodeToken(access.data.userAccessToken);
			const {settings} = decodedAccessToken.access_settings;
			const allowedNavigationItems = authUtils.allowedNavigationItems(settings.roles, Menu);
			const userData = {
				user: decodedAccessToken,
				authenticated: true,
				accessToken: access.data.userAccessToken,
				allowedNavigationItems: allowedNavigationItems,
			};
			secureToken.setRolesAccessToken(userData);
			setUserAccess(userData);
			setFeatureFlags(await FeatureFlagHelper.GetFeatureFlags());
			await redirectToDashboard();
		} catch (error) {
			console.error(error);
			redirectToLogin();
		}
	};

	useEffect(() => {
		const initDelay = async () => {
			await delay(PublicViewCommon.INITIAL_DELAY);
			await validateSerialNumber();
		};
		initDelay();
	}, []);

	const checkStatusManually = async () => {
		setDisableFormElement(true);
		setStepDescription({...stepDescription, 0: STEP_DESCRIPTION[0].START});
		setAlertProps({...alertProps, show: false});

		await validateSerialNumber();
	};

	const redirectToDashboard = async () => {
		setAlertProps({
			...alertProps,
			show: true,
			type: 'success',
			description: ALERT_TXT.DESC_OK,
			message: ALERT_TXT.MESSAGE_OK,
		});
		await delay(PublicViewCommon.REDIRECT_DELAY);
		props.history.push({
			pathname: '/dashboard',
			state: {checkLastRunOnly: true},
		});
	};

	const redirectToLogin = () => {
		props.history.push('/login');
	};

	const onChangeSerialNumber = e => {
		const serialValue = e.target.value.split(';')[0];
		setSerialForm({...serialForm, serial: serialValue});
	};

	return (
		<>
			<PublicViewValidationComponent
				alertProps={alertProps}
				checkStatusManually={checkStatusManually}
				currentStatus={currentStatus}
				currentStep={currentStep}
				description={t('device_status.description')}
				disableFormElement={disableFormElement}
				identifier={serialForm.serial}
				onIdentifierChanged={onChangeSerialNumber}
				stepDescription={stepDescription}
				title={t('device_status.title')}
			/>
		</>
	);
};

export default DeviceStatus;
