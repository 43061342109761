import {SensorStep} from '../../Dashboard/Sensor/AddSensor/GetSensorSteps';
import {AdditionalConfiguration} from '../../Dashboard/Sensor/AddSensor/WizardState';

interface NavState {
	current_step: number;
	steps: SensorStep[];
	additionalConfiguration: AdditionalConfiguration;
}

export class WizardNavigationUtil {
	static GetNextStep(state: NavState, direction: 'forward' | 'backward' = 'forward'): number {
		const increment: number = direction == 'forward' ? +1 : -1;

		let nextStep = state.current_step;
		do {
			nextStep += increment;
		} while (WizardNavigationUtil.ShouldSkipStep(state, nextStep));

		return nextStep;
	}

	static ShouldSkipStep(state: NavState, step: number): boolean {
		const isConfigurationTemplateStep = (): boolean =>
			step == 1 &&
			state.additionalConfiguration.has_additional_configuration &&
			state.additionalConfiguration.configuration_templates?.length === 1;

		return state.steps[step]?.disabled == true || isConfigurationTemplateStep();
	}
}
