import React from 'react';
import LoadingTie from './LoadingTie';

const DeviationTypeTie = props => {
    return (
        <div className="card card-default" style={{height: '100%'}} id="deviationType">
            <div className="card-body text-center">
                {props.deviation_type.title ? (
                    <>
                        <h4>{props.deviation_type.title}</h4>
                        <br />
                        <h3>
                            {props.deviation_type.temperature} {props.deviation_type.unit}
                        </h3>
                        <h5>{props.deviation_type.date}</h5>
                    </>
                ) : (
                    <LoadingTie />
                )}
            </div>
        </div>
    );
};

export default DeviationTypeTie;
