import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import ErrorDisplay from "./../ErrorDisplay";

const TextControl = React.forwardRef(({
	id,
	name,
	label,
	alias,
	value,
	disabled,
	errors,
	showErrors,
	handleChange,
	handleBlur,
	handleFocus,
	dataToValidate,
	...rest
}, ref) =>
	(
		<>
			<Label for={id}>{label}</Label>
			<Input
				innerRef={ref}
				type="text"
				id={id}
				name={name}
				placeholder={`Enter ${rest.description || alias}`}
				data-param={rest.max_length || null}
				invalid={showErrors && !disabled && errors.length > 0}
				value={value}
				disabled={disabled}
				data-validate={dataToValidate}
				onChange={handleChange}
				onBlur={handleBlur}
				onFocus={handleFocus}
			/>
			{showErrors && !disabled && errors.map((error, i) => (
				<ErrorDisplay key={i} error={error} params={[rest.max_length || 0]} />
			))}
		</>
	)
);

TextControl.displayName = 'TextControl';

TextControl.propTypes = {
	errors: PropTypes.array,
	handleChange: PropTypes.func
}

TextControl.defaultProps = {
	errors: [],
	handleChange: () => { }
};

export default TextControl;