import {useEffect, useState} from 'react';

const ExternalRedirect = ({...props}) => {
    const [param] = useState(props.location.pathname.substring(1).split('='));

    useEffect(() => {
        const redirectPath = param.length >= 2 && param[0] === 'ID' ? '/deviceStatus/' + param[1] : '/deviceStatus';
        props.history.push(redirectPath);
    }, []);

    return null;
};

export default ExternalRedirect;
