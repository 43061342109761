import React, {Component} from "react";
import IdleTimer from 'react-idle-timer';
import Authentication from '../Authentication/Authentication';

class UserIdle extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.callRefreshToken = this.callRefreshToken.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(this.callRefreshToken, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    callRefreshToken() {
        if (this.idleTimer.isIdle()) {
            Authentication.alive()
                .then(function(response) {
                }).catch(function(error) {
                }
            )
        } else {
            Authentication.refreshToken();
        }
    }

    _onAction(e) {
        //console.debug('user did something', e);
    }

    _onActive(e) {
        //console.debug('user is active', e);
        //console.debug('time remaining', this.idleTimer.getRemainingTime());
    }

    _onIdle(e) {
        //console.debug('user is idle', e);
        //console.debug('last active', this.idleTimer.getLastActiveTime());
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 10} />
            </div>
        );
    }
}

export default UserIdle;