import React, {createContext, useEffect, useState} from 'react';
import {ContentWrapper, ViewWrapper} from '../../Layout';
import {ViewHeader} from '../../Common';
import {Button, List, Modal, ModalFuncProps} from 'antd';
import {FileSearchOutlined} from '@ant-design/icons';

interface LicenceSummaryType {
	[key: string]: LicenseSummaryItem;
}

interface LicenseSummaryItem {
	licenses: string;
	repository: string;
	publisher: string;
	email: string;
	path: string;
	licenseFile: string;
}
const LicenseContext = createContext<string | null>(null);
const modalConfig: ModalFuncProps = {
	title: 'License Text',
	content: (
		<pre>
			<LicenseContext.Consumer>{licenseText => licenseText}</LicenseContext.Consumer>
		</pre>
	),
	maskClosable: true,
	width: '50em', // empirically the best width
};

export function OpenSourceLicensesView(): React.JSX.Element {
	const licensePath = process.env.PUBLIC_URL + '/licenses';
	const [summaryLoading, setSummaryLoading] = useState(true);
	const [licenseTextLoading, setLicenseTextLoading] = useState(false);
	const [licenseSummary, setLicenseSummary] = useState<LicenceSummaryType>({});
	const [modal, contextHolder] = Modal.useModal();
	const [selectedLicenseText, setSelectedLicenseText] = useState<string>('');

	function removeVersion(key: string): string {
		const re = /(@?.*)@.*$/;
		const match = re.exec(key);
		return match[1];
	}

	useEffect(() => {
		fetch(`${licensePath}/_summary.json`)
			.then(r => r.json())
			.then((r: LicenceSummaryType) => {
				setLicenseSummary(r);
				setSummaryLoading(false);
			})
			.catch();
	}, []);

	async function showLicenseText(packageName: string): Promise<void> {
		setLicenseTextLoading(true);
		try {
			const filePath = `${licensePath}/${packageName}-LICENSE.txt`;
			const response = await fetch(filePath);
			const licenseText = await response.text();
			setSelectedLicenseText(licenseText);
			modal.info(modalConfig);
		} catch (e) {
			console.error(e);
		}
		setLicenseTextLoading(false);
	}

	return (
		<ViewWrapper>
			<ViewHeader heading={'Open Source Licenses'} />
			<ContentWrapper>
				<LicenseContext.Provider value={selectedLicenseText}>
					{contextHolder}
					<List
						itemLayout="vertical"
						loading={summaryLoading}
						size="large"
						dataSource={Object.entries(licenseSummary).map(([key, item]) => ({packageName: key, ...item}))}
						renderItem={item => (
							<List.Item
								key={item.packageName}
								extra={
									<Button
										key={item.packageName}
										loading={licenseTextLoading}
										onClick={() => showLicenseText(item.packageName)}
										icon={<FileSearchOutlined />}
									/>
								}
							>
								<List.Item.Meta title={removeVersion(item.packageName)} description={item.licenses} />
							</List.Item>
						)}
					/>
				</LicenseContext.Provider>
			</ContentWrapper>
		</ViewWrapper>
	);
}
