import {MODULE_FAMILIES} from '../../../Shared/Constants/Module';
import findFirmwareVersionFromSerialNumber from '../../../Shared/Extensions/DeviceFirmwareVersionFinder';
import SerialNumberInfo from '../../.././Shared/SerialNumberInfo';
import {WizardSettings} from './WizardSettings';

export const getSensorBasicsFormSettingsForConfiguration = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = WizardSettings.ConfigurationEcologProRadioSettings.sensorBasicsForm;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = WizardSettings.ConfigurationLiberoGFirmware0Settings.sensorBasicsForm;
			}
			if (firmwareVersion >= 1) {
				settings = WizardSettings.ConfigurationLiberoGFirmware1Settings.sensorBasicsForm;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = WizardSettings.ConfigurationEcologProGSettings.sensorBasicsForm;
		default:
			break;
	}
	return settings;
};

export const getSensorSummarySettingsForConfiguration = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = WizardSettings.ConfigurationEcologProRadioSettings.sensorSummary;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = WizardSettings.ConfigurationLiberoGFirmware0Settings.sensorSummary;
			}
			if (firmwareVersion >= 1) {
				settings = WizardSettings.ConfigurationLiberoGFirmware1Settings.sensorSummary;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = WizardSettings.ConfigurationEcologProGSettings.sensorSummary;
		default:
			break;
	}
	return settings;
};

export const getSensorPaymentMethodSettingsForConfiguration = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = WizardSettings.ConfigurationEcologProRadioSettings.sensorPaymentMethod;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = WizardSettings.ConfigurationLiberoGFirmware0Settings.sensorPaymentMethod;
			}
			if (firmwareVersion >= 1) {
				settings = WizardSettings.ConfigurationLiberoGFirmware1Settings.sensorPaymentMethod;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = WizardSettings.ConfigurationEcologProGSettings.sensorPaymentMethod;
		default:
			break;
	}
	return settings;
};

export const getSensorBasicsFormSettingsForReConfiguration = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = WizardSettings.ReConfigurationEcologProRadioSettings.sensorBasicsForm;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = WizardSettings.ReConfigurationLiberoGFirmware0Settings.sensorBasicsForm;
			}
			if (firmwareVersion >= 1) {
				settings = WizardSettings.ReConfigurationLiberoGFirmware1Settings.sensorBasicsForm;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = WizardSettings.ReConfigurationEcologProGSettings.sensorBasicsForm;
		default:
			break;
	}
	return settings;
};

export const getSensorSummarySettingsForReConfiguration = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = WizardSettings.ReConfigurationEcologProRadioSettings.sensorSummary;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = WizardSettings.ReConfigurationLiberoGFirmware0Settings.sensorSummary;
			}
			if (firmwareVersion >= 1) {
				settings = WizardSettings.ReConfigurationLiberoGFirmware1Settings.sensorSummary;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = WizardSettings.ReConfigurationEcologProGSettings.sensorSummary;
		default:
			break;
	}
	return settings;
};

export const getSensorPaymentMethodSettingsForReConfiguration = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = WizardSettings.ReConfigurationEcologProRadioSettings.sensorPaymentMethod;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = WizardSettings.ReConfigurationLiberoGFirmware0Settings.sensorPaymentMethod;
			}
			if (firmwareVersion >= 1) {
				settings = WizardSettings.ReConfigurationLiberoGFirmware1Settings.sensorPaymentMethod;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = WizardSettings.ReConfigurationEcologProGSettings.sensorPaymentMethod;
		default:
			break;
	}
	return settings;
};
