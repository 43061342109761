// Core React
import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Services
import SensorService from '../SensorService';

// Modules
import {Input, Row, Col, FormGroup} from 'reactstrap';
import {Trans} from 'react-i18next';

// Custom
import ModuleCard from '../AddSensor/ModuleCard';
import FormValidator from '../../../Forms/FormValidator';
import Message from '../../../Shared/Components/Message';

// Constants
import {MODULE_TYPE} from '../../../Shared/Constants/Module';
import {RequestLogger} from '../../../Infrastructure/Requests/Logger';
import {FormNavigation} from '../../../Wizard/Elements';

class ModuleForm extends Component {
	constructor(props) {
		super(props);
		this.state = {loading: false};
	}

	hasError = (formName, inputName, method) => {
		return (
			this.state[formName] &&
			this.state[formName].errors &&
			this.state[formName].errors[inputName] &&
			this.state[formName].errors[inputName][method]
		);
	};

	onSubmit = e => {
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);

		this.setState({
			[form.name]: {
				...this.state[form.name],
				errors,
			},
			loading: true,
		});

		if (!hasError) {
			const serialNumber = inputs[0].value;

			if (typeof this.props.onNext === 'function') {
				SensorService.discoverModule(
					{serial_number: serialNumber},
					RequestLogger.createLogData('module-form', 'discover-module', 'onClick')
				)
					.then(module => {
						// not valid
						if (module.data.status.code === 0) {
							this.props.handleModuleChannels(module.data);
							return {data: {valid: true, message: null}};
						} else {
							this.setState({
								loading: false,
							});
							return {data: {valid: false, message: module.data.status.text}};
						}
					})
					.then(channel => {
						if (!channel.data.valid) {
							this.setState({
								loading: false,
							});
							Message.error(
								'Error',
								<>
									<Trans i18nKey={'sensorWizard.moduleForm.' + channel.data.message} />
									<br />
									<Trans i18nKey={'sensorWizard.moduleForm.check_input_contact_support'} />
								</>
							);
						}
					});
			}
		} else {
			this.setState({
				loading: false,
			});
		}

		e.preventDefault();
	};

	render() {
		return (
			<Col>
				<form name="formModule" onSubmit={this.onSubmit}>
					<FormNavigation loading={this.state.loading} />
					<Row>
						<Col xl={3}>
							<FormGroup>
								<Input
									type="text"
									id="serialNumber"
									name="serial_number"
									placeholder="Enter the new module's serial number"
									invalid={
										this.hasError('formModule', 'serial_number', 'required') ||
										this.hasError('formModule', 'serial_number', 'serialNumber')
									}
									data-validate='["required", "serialNumber"]'
									value={this.props.moduleForm.serial_number}
									onChange={this.props.handleSerialNumberChange}
								/>
								{this.hasError('formModule', 'serial_number', 'required') && (
									<span className="invalid-feedback">Field is required</span>
								)}
								{this.hasError('formModule', 'serial_number', 'serialNumber') && (
									<span className="invalid-feedback">Field is invalid</span>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<div style={{display: 'flex', padding: '20px 0'}}>
								<ModuleCard type={MODULE_TYPE.NTR} serial={this.props.moduleForm.serial_number} />
								<ModuleCard type={MODULE_TYPE.PTR} serial={this.props.moduleForm.serial_number} />
								<ModuleCard type={MODULE_TYPE.THR} serial={this.props.moduleForm.serial_number} />
								<ModuleCard type={MODULE_TYPE.LIBEROG} serial={this.props.moduleForm.serial_number} />
								{/* // ECOLOG_PRO_G_DEACTIVATED */}
								<ModuleCard type={MODULE_TYPE.ECOLOG_PRO_G} serial={this.props.moduleForm.serial_number} />
							</div>
						</Col>
					</Row>
					<FormNavigation loading={this.state.loading} />
				</form>
			</Col>
		);
	}
}

ModuleForm.propTypes = {
	onNext: PropTypes.func,
};

export default ModuleForm;
