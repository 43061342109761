import axios from 'axios';
import ReCaptchaService from '../ReCaptcha/ReCaptchaService';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
const isDebugMode = false;

const localhostValidateCaptchaToken = payload => {
    return axios({
        url: 'http://localhost:80/srv/v1/recaptcha/validate-recaptcha-token',
        method: 'POST',
        data: payload,
        headers: {
            Authorization: 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
    });
};

const ValidateReCaptchaToken = payload => {
    if (isDebugMode) {
        return localhostValidateCaptchaToken(payload);
    } else {
        return ReCaptchaService.validateReCaptchaToken(payload);
    }
};

const ReCaptcha = {
    ValidateReCaptchaToken,
};

export default ReCaptcha;
