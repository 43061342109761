function sensorsGroupsReducer(state, action) {
    switch (action.type) {
        case 'fetchInit':
            return {...state, loading: true};
        case 'fetchGroupsDone':
            return {
                ...state, 
                loading: false, 
                groups: action.payload['groups'], 
                nestedGroups: getNestedGroups(action.payload['groups'])
            };
        case 'fetchSensorsDone':
            return {
                ...state,
                loading: false,
                sensors: action.payload['sensors'],
                groupId: action.payload['groupId'],
                group: action.payload['group'],
            };
        case 'fetchFailed':
            return {...state, loading: false, error: action.payload};
        default:
            return state;
    }
}

function isTouchDevice() {
    try {
        document.createEvent('TouchEvent');
        return true;
    } catch (e) {
        return false;
    }
}

const fillSubGroups = (groups, parentGroups, depth = 1) => {
    return parentGroups.map(group => {
        const subGroups = groups.filter(g => g.sensors_group_parent === group.id);
        if (subGroups.length > 0) {
            return {...group, group_depth: depth, sub_groups: fillSubGroups(groups, subGroups, depth + 1)};
        }
        return {...group, group_depth: depth};
    });
};

function getNestedGroups(groups) {
    const parentGroups = groups.filter(g => g.sensors_group_parent === null);
    return fillSubGroups(groups, parentGroups);
}

function getFlatGroups(groups) {
    return groups
        .map(g => {
            const {sub_groups, ...rest} = g;
            if (!!sub_groups) {
                return [rest, ...getFlatGroups(sub_groups)];
            }
            return rest;
        })
        .flat()
        .filter(g => g !== null);
}

const filterGroups = (groups, id) => groups.filter(group => group.id !== id);

function getFilteredGroups(groups, id) {
    return filterGroups(
        groups.map(group => {
            const {sub_groups} = group;
            if (sub_groups !== undefined && sub_groups !== null) {
                return {...group, sub_groups: getFilteredGroups(group.sub_groups, id)};
            }
            return group;
        }),
        id
    );
}

/**
 * @param {*} object : {ref: []}
 * The result of the filtered groups will
 * be returned in ref property
 */
function getOnlyGroups(groups, ids, object, refKey = 'ref') {
    const clonedGroups = JSON.parse(JSON.stringify(groups));
    object[refKey] = [];

    clonedGroups.forEach(group => {
        if (!ids.includes(group.id)) {
            object[refKey] = object[refKey].filter(i => i.id !== group.id);
            if (!!group.sub_groups) {
                const returnedGroup = getOnlyGroups(group.sub_groups, ids, group, 'sub_groups');
                if(!!returnedGroup.sub_groups && returnedGroup.sub_groups.length > 0)
                {
                    object[refKey] = [...object[refKey], {...returnedGroup, showSensors: false}];
                }
            }
        } else {
            if (object[refKey].findIndex(i => i.id === group.id) < 0) {
                if (!!group.sub_groups) {
                    const returnedGroup = getOnlyGroups(group.sub_groups, ids, group, 'sub_groups');
                    object[refKey] = [...object[refKey], {...returnedGroup, showSensors: true}];
                } else {
                    object[refKey] = [...object[refKey], {...group, showSensors: true}];
                }
            }
        }
    });

    return object;
}

const sortGroups = (groups, asc) =>
    groups.sort((prevGroup, nextGroup) => {
        if (asc === true) {
            return prevGroup.name.toLowerCase() > nextGroup.name.toLowerCase() ? 1 : -1;
        } else {
            return prevGroup.name.toLowerCase() < nextGroup.name.toLowerCase() ? 1 : -1;
        }
    });

function getSortedGroups(groups, asc = true) {
    return sortGroups(
        groups.map(group => {
            const {sub_groups} = group;
            if (sub_groups !== undefined && sub_groups !== null) {
                return {...group, sub_groups: getSortedGroups(group.sub_groups, asc)};
            }
            return group;
        }),
        asc
    ).map((g, i) => ({...g, group_order: i + 1}));
}

function getGroupChilds(groups, id) {
    const flatGroups = getFlatGroups(groups);

    return flatGroups.filter(g => g.sensors_group_parent === id);
}

const SensorGroupsUtils = {
    isTouchDevice,
    sensorsGroupsReducer,
    getNestedGroups,
    getFlatGroups,
    getFilteredGroups,
    getOnlyGroups,
    getSortedGroups,
    getGroupChilds,
};

export default SensorGroupsUtils;
