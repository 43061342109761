import React, {Component} from 'react';
import ContentWrapper from '../../../../Layout/ContentWrapper';
import {Trans} from 'react-i18next';
import LimitProfileService from '../../../../Services/LimitProfiles/LimitProfilesService';
import MultizoneTable from '../../../../Shared/Components/MultizoneTable';
import FormValidator from '../../../../Forms/FormValidator';
import Alert from '../../../../Shared/Components/Alert';
import Cancel from '../../../../Shared/Components/Cancel';
import Save from '../../../../Shared/Components/Save';
import SerialNumberInfo from '../../../../Shared/SerialNumberInfo';
import RequestLogger from '../../../../Infrastructure/Requests/Logger/RequestLogger';
import {MODES} from '../../Constants/Modes';
import {getInitialStateData} from '../Extensions/StateCreator';
import {getLimitProfiles} from '../../Queries/LimitProfilesQuery';
import {saveLimitProfile, updateLimitProfile} from '../Commands/LimitProfileCommands';
import AccordionComponent from '../Components/AccordionComponent';
import SensorMappingsTable from '../Components/SensorMappingsTable';

class LimitProfile extends Component {
	constructor(props) {
		super(props);

		if (props.location.state) {
			let id = props.location.state.id;
			switch (props.location.state.mode) {
				case MODES.DISPLAY:
					this.state = getInitialStateData(MODES.DISPLAY, id);
					getLimitProfiles(this, true);
					this.getLimitProfile(id);
					break;
				case MODES.ADD:
					this.state = getInitialStateData(MODES.ADD);
					getLimitProfiles(this, true);
					break;
				case MODES.EDIT:
					this.state = getInitialStateData(MODES.EDIT, id);
					getLimitProfiles(this, true);
					this.getLimitProfile(id);
					break;
				default:
					break;
			}
		}
	}

	getLimitProfile = limitProfileId => {
		LimitProfileService.getLimitProfile(limitProfileId, RequestLogger.createLogData('limit-profiles', 'load-limit-profile', 'onLoad'))
			.then(response => {
				this.setState({
					limitAlarmsForm: this.getLimitAlarmsForm(response.data),
					sensors: response.data.sensors,
					initialFormValues: response.data,
					loaded: true,
				});
			})
			.catch(error => console.log('error ', error));
	};

	getLimitAlarmsForm = profile => {
		return {
			id: profile.id,
			is_new_profile: false,
			is_libero_g: profile.sensors === null ? false : SerialNumberInfo.isValidLiberoG(profile.sensors[0].serial_number),
			limit_alarm_creation_type: profile.id,
			limit_alarms: this.loadAlarming(profile),
			limit_warnings: this.loadWarning(profile),
			name: profile.name,
		};
	};

	createZone = (key, limit, delay) => {
		return {
			key: key,
			limit: limit,
			delay: delay,
			event: 'single',
			excursions: 'unlim.',
		};
	};

	loadWarning = profile => {
		let zone = [];

		if (profile.upper_limit_warning != null) {
			zone = [
				this.createZone(
					'H1',
					profile.upper_limit_warning,
					profile.upper_limit_warning_delay === null ? 0 : profile.upper_limit_warning_delay
				),
				...zone,
			];
		}

		if (profile.lower_limit_warning != null) {
			zone = [
				...zone,
				this.createZone(
					'L1',
					profile.lower_limit_warning,
					profile.lower_limit_warning_delay === null ? 0 : profile.lower_limit_warning_delay
				),
			];
		}

		return zone;
	};

	loadAlarming = profile => {
		let zone = [];

		if (profile.upper_limit != null) {
			zone = [
				this.createZone('H1', profile.upper_limit, profile.upper_limit_delay === null ? 0 : profile.upper_limit_delay),
				...zone,
			];
		} else {
			zone = [this.createZone('H1', 0, 0)];
		}
		if (profile.upper_limit_2 != null) {
			zone = [
				this.createZone('H2', profile.upper_limit_2, profile.upper_limit_delay_2 === null ? 0 : profile.upper_limit_delay_2),
				...zone,
			];
		}
		if (profile.upper_limit_3 != null) {
			zone = [
				this.createZone('H3', profile.upper_limit_3, profile.upper_limit_delay_3 === null ? 0 : profile.upper_limit_delay_3),
				...zone,
			];
		}
		if (profile.upper_limit_4 != null) {
			zone = [
				this.createZone('H4', profile.upper_limit_4, profile.upper_limit_delay_4 === null ? 0 : profile.upper_limit_delay_4),
				...zone,
			];
		}
		if (profile.lower_limit != null) {
			zone = [
				...zone,
				this.createZone('L1', profile.lower_limit, profile.lower_limit_delay === null ? 0 : profile.lower_limit_delay),
			];
		} else {
			zone = [...zone, this.createZone('L1', 0, 0)];
		}
		if (profile.lower_limit_2 != null) {
			zone = [
				...zone,
				this.createZone('L2', profile.lower_limit_2, profile.lower_limit_delay_2 === null ? 0 : profile.lower_limit_delay_2),
			];
		}
		if (profile.upper_limit_3 != null) {
			zone = [
				...zone,
				this.createZone('L3', profile.lower_limit_3, profile.lower_limit_delay_3 === null ? 0 : profile.lower_limit_delay_3),
			];
		}

		return zone;
	};

	onSubmit = e => {
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT'].includes(i.nodeName));
		const inputNoSelect = this.removeNoName(inputs);
		const {errors, hasError} = FormValidator.bulkValidate(inputNoSelect);

		this.setErrorMessages(errors, inputNoSelect);

		if (!hasError) {
			switch (this.state.mode) {
				case MODES.DISPLAY:
				//This case is not possible since save button is disabled, when the mode is DISPLAY.
				case MODES.ADD:
					saveLimitProfile(this.parseLimitProfile(this.state.limitAlarmsForm), this);
					break;
				case MODES.EDIT:
					updateLimitProfile(this.parseLimitProfile(this.state.limitAlarmsForm), this);
					break;
				default:
					break;
			}
		}

		e.preventDefault();
	};

	removeNoName = inputs => {
		return inputs.filter(input => {
			return input.name !== '';
		});
	};

	setErrorMessages = (errorList, inputs) => {
		let errorMessage;
		let errorMessages = [];
		inputs.map(input => {
			errorMessage = this.getErrorMessage(input.name, errorList[input.name]);
			if (errorMessage.length > 0) {
				errorMessages.push(errorMessage);
			}
		});

		this.setState({errors: errorMessages});
	};

	getErrorMessage = (inputName, error) => {
		let message = [];

		if (error['required'] === true) {
			if (inputName.includes('Profile Name')) {
				message.push(<p key={inputName + 'Error'}>Profile name is required!</p>);
			} else {
				let tableName = this.getTable(inputName);
				message.push(
					<p key={inputName + 'Error'}>
						{tableName} {this.getZone(inputName)} {this.getCellType(inputName)} is required! {this.getWording(tableName)} zone
						or enter value.
					</p>
				);
			}
		} else {
			if (error['zone'] === true) {
				message.push(
					<p key={inputName + 'Error'}>
						{this.getTable(inputName)} {this.getZone(inputName)} {this.getCellType(inputName)} contains an error!
					</p>
				);
			} else if (error['number'] === true) {
				message.push(
					<p key={inputName + 'Error'}>
						{this.getTable(inputName)} {this.getZone(inputName)} {this.getCellType(inputName)} contains no number!
					</p>
				);
			} else if (error['zonesRequiredAlarming'] === true) {
				message.push(<p key={inputName + 'Error'}>Alarming is enabled. Disable alarming or add at least one zone</p>);
			} else if (error['zonesRequiredWarning'] === true) {
				message.push(<p key={inputName + 'Error'}>Warning is enabled. Disable warning or enable at least one zone</p>);
			}
		}
		return message;
	};

	getWording = tableName => {
		return tableName.includes('Alarming') ? 'remove' : 'disable';
	};

	getCellType = inputName => {
		if (inputName.includes('Delay')) {
			return 'delay';
		} else {
			return 'limit';
		}
	};

	getTable = inputName => {
		if (inputName.includes('Alarming')) {
			return 'Alarming Table:';
		} else {
			return 'Warning Table:';
		}
	};

	getZone = inputName => {
		if (inputName.includes('H4')) {
			return 'H4';
		} else if (inputName.includes('H3')) {
			return 'H3';
		} else if (inputName.includes('H2')) {
			return 'H2';
		} else if (inputName.includes('H1')) {
			return 'H1';
		} else if (inputName.includes('L1')) {
			return 'L1';
		} else if (inputName.includes('L2')) {
			return 'L2';
		} else if (inputName.includes('L3')) {
			return 'L3';
		}
	};

	cancel = e => {
		e.preventDefault();
		this.props.history.push('/limitProfiles');
	};

	handleLimitAlarmsChange = newLimitAlarmsForm => {
		let newState = JSON.parse(JSON.stringify(this.state));
		newState.limitAlarmsForm = newLimitAlarmsForm;
		newState.saveDisabled = false;
		newState.errors = [];

		if (this.state.limitAlarmsForm.limit_alarm_creation_type !== 0 && this.state.limitAlarmsForm.name !== newLimitAlarmsForm.name) {
			let limitProfile = newState.limitProfiles.find(limitProfile => limitProfile.name === this.state.limitAlarmsForm.name);

			limitProfile.name = newLimitAlarmsForm.name;
			newState.limitProfiles[newState.limitProfiles.indexOf(limitProfile)] = limitProfile;
		}
		this.setState(newState);
	};

	parseLimitProfile = newProfile => {
		return {
			name: newProfile.name,
			id: newProfile.id,
			upper_limit: this.getLimitByKey('H1', newProfile),
			upper_limit_2: this.getLimitByKey('H2', newProfile),
			upper_limit_3: this.getLimitByKey('H3', newProfile),
			upper_limit_4: this.getLimitByKey('H4', newProfile),
			lower_limit: this.getLimitByKey('L1', newProfile),
			lower_limit_2: this.getLimitByKey('L2', newProfile),
			lower_limit_3: this.getLimitByKey('L3', newProfile),
			upper_limit_delay: this.getDelayByKey('H1', newProfile),
			upper_limit_delay_2: this.getDelayByKey('H2', newProfile),
			upper_limit_delay_3: this.getDelayByKey('H3', newProfile),
			upper_limit_delay_4: this.getDelayByKey('H4', newProfile),
			lower_limit_delay: this.getDelayByKey('L1', newProfile),
			lower_limit_delay_2: this.getDelayByKey('L2', newProfile),
			lower_limit_delay_3: this.getDelayByKey('L3', newProfile),
			upper_limit_warning: this.getLimitByKeyWarning('H1', newProfile),
			upper_limit_warning_delay: this.getDelayByKeyWarning('H1', newProfile),
			lower_limit_warning: this.getLimitByKeyWarning('L1', newProfile),
			lower_limit_warning_delay: this.getDelayByKeyWarning('L1', newProfile),
			limit_alarm_creation_type: newProfile.limit_alarm_creation_type,
		};
	};

	getLimitByKey = (key, profile) => {
		let value = profile.limit_alarms.find(element => {
			return key === element.key;
		});

		return value === undefined ? null : value.limit;
	};

	getDelayByKey = (key, profile) => {
		let value = profile.limit_alarms.find(element => {
			return key === element.key;
		});

		return value === undefined ? null : value.delay;
	};

	getLimitByKeyWarning = (key, profile) => {
		let value = profile.limit_warnings.find(element => {
			return key === element.key;
		});

		return value === undefined ? null : value.limit;
	};

	getDelayByKeyWarning = (key, profile) => {
		let value = profile.limit_warnings.find(element => {
			return key === element.key;
		});

		return value === undefined ? null : value.delay;
	};

	getAlerts() {
		return this.state.errors.length > 0 ? (
			<div>
				<Alert key="LimitProfileErrors" message="Error" description={this.state.errors} type="error" />
				<br />
			</div>
		) : null;
	}

	render() {
		return (
			<ContentWrapper>
				<form id="formLimitAlarms" name="formLimitAlarms" onSubmit={this.onSubmit}>
					<div className="content-heading">
						<Trans i18nKey={'settings.limitProfiles.title'} />
						<div className="ml-auto">
							<Save disabled={this.state.saveDisabled} />
							{'\u00A0'}
							<Cancel onClick={this.cancel} />
						</div>
					</div>
					{this.state.errors !== [] ? this.getAlerts() : null}

					{this.state.loaded === true && (
						<MultizoneTable
							limitProfiles={this.state.limitProfiles}
							profile={this.state.limitAlarmsForm}
							selectionChanged={false}
							handleLimitAlarmsChange={this.handleLimitAlarmsChange}
							profileSelected={true}
							isProfileEditable={this.state.formSettings.profileInputDisabled}
							alarmingEnabled={!this.state.formSettings.profileInputDisabled}
							warningEnabled={!this.state.formSettings.profileInputDisabled}
						/>
					)}
					{this.state.mode !== MODES.ADD && (
						<AccordionComponent
							accordionItemid="accordinMappings"
							accordionTitleId="accordionMappingsTitle"
							transkey="settings.limitProfiles.mappings"
							itemBody={<SensorMappingsTable sensors={this.state.sensors} />}
						/>
					)}
				</form>
			</ContentWrapper>
		);
	}
}

export default LimitProfile;
