import React from 'react';

export default function GetSensorBodyTemperaturePath({
	alarmDeactivatedColor,
	colors,
	fillingColor,
	hasLimits,
	height,
	lastMeasurementOutsideLimits,
	isRetired,
	muted,
	strokeColor,
	y,
}) {
	return (
		<g transform="translate(500, 0)">
			<path
				d="M 5793,6520 V 1206 C 5793,648 5337,193 4779,193 4221,193 3766,648 3766,1206 v 5314 l -66,58 c -358,312 -561,760 -561,1235 0,906 734,1641 1640,1641 906,0 1641,-735 1641,-1641 0,-475 -204,-923 -562,-1235 z"
				fill="white"
				id="InnerFill"
			/>
			{muted || isRetired ? (
				<rect height={height} width="2702" y={y} x="3428" fill={alarmDeactivatedColor} id="sensorMuted" />
			) : (
				<rect
					height={height}
					width="2702"
					y={y}
					x="3428"
					fill={!hasLimits ? colors.noValue : lastMeasurementOutsideLimits ? colors.alarm : colors.noAlarm}
					id={!hasLimits ? 'sensorNoValue' : lastMeasurementOutsideLimits ? 'sensorAlarm' : 'sensorNoAlarm'}
				/>
			)}
			<path
				d="m 4634,8961 c 80,0 145,-65 145,-144 0,-73 -53,-133 -123,-144 -223,-31 -424,-150 -561,-324 -30,-39 -81,-62 -134,-54 -79,11 -134,84 -123,164 4,26 14,49 29,69 182,231 449,389 747,432 6,1 13,1 20,1 z"
				fill="white"
				id="Shine"
			/>
			<path
				d="M 5793,6520 V 1206 C 5793,648 5337,193 4779,193 4221,193 3766,648 3766,1206 v 5314 l -66,58 c -358,312 -561,760 -561,1235 0,906 734,1641 1640,1641 906,0 1641,-735 1641,-1641 0,-475 -204,-923 -562,-1235 z m -290,132 V 1206 c 0,-398 -326,-723 -724,-723 -398,0 -724,325 -724,723 v 5446 l -164,144 c -295,258 -463,626 -463,1017 0,747 605,1351 1351,1351 747,0 1351,-604 1351,-1351 0,-391 -167,-759 -462,-1017 z"
				fill="white"
				id="InnerContour"
			/>
			<path
				id="OuterContour"
				fill="000"
				d="M5986 6433c384,336 627,830 627,1380 0,1013 -821,1834 -1834,1834 -1012,0 -1833,-821 -1833,-1834 0,-550 243,-1044 627,-1380l0 -5227c0,-664 542,-1206 1206,-1206l0 0c665,0 1207,542 1207,1206l0 5227zm-193 87l0 -5314c0,-558 -456,-1013 -1014,-1013 -558,0 -1013,455 -1013,1013l0 5314 -66 58c-358,312 -561,760 -561,1235 0,906 734,1641 1640,1641 906,0 1641,-735 1641,-1641 0,-475 -204,-923 -562,-1235l-65 -58z"
			/>
			<path
				d="m 5986,6433 c 384,336 627,830 627,1380 0,1013 -821,1834 -1834,1834 -1012,0 -1833,-821 -1833,-1834 0,-550 243,-1044 627,-1380 V 1206 C 3573,542 4115,0 4779,0 v 0 c 665,0 1207,542 1207,1206 z M 2946,0 H 6613 V 9647 H 2946 Z"
				fill={fillingColor}
				id="ColorAccordingToBackground"
			/>
			<path
				d="m 5986,6433 c 384,336 627,830 627,1380 0,1013 -821,1834 -1834,1834 -1012,0 -1833,-821 -1833,-1834 0,-550 243,-1044 627,-1380 V 1206 C 3573,542 4115,0 4779,0 v 0 c 665,0 1207,542 1207,1206 z m -193,87 V 1206 C 5793,648 5337,193 4779,193 4221,193 3766,648 3766,1206 v 5314 l -66,58 c -358,312 -561,760 -561,1235 0,906 734,1641 1640,1641 906,0 1641,-735 1641,-1641 0,-475 -204,-923 -562,-1235 z"
				fill={strokeColor}
				id="OuterContour"
			/>
		</g>
	);
}
