import React, {Component} from 'react';
import { Input, Row, Col } from 'reactstrap';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';

class CreditCardElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name_on_card: '',
            card_number_empty: true,
            card_expiry_empty: true,
            card_cvc_empty: true
        };
        this.handleNameChange = this.handleNameChange.bind(this);
    }

    _elementNumber = {};
    _elementExpiry = {};
    _elementCVC = {};

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }
    handleChange = (event) => {
        switch (event.elementType) {
            case 'cardNumber':
                this.setState({card_number_empty: event.empty});
                break;
            case 'cardExpiry':
                this.setState({card_expiry_empty: event.empty});
                break;
            case 'cardCvc':
                this.setState({card_cvc_empty: event.empty});
                break;
            default:
                break;
        }

        let error = event.error;
        if (error) {
            this.setState({errorMessage: error.message});
        }
    };

    handleNameChange(event) {
        const input = event.target;
        const value = input.value;
        this.setState({name_on_card: value});
    }

    createToken = () => {
        return this.props.stripe.createToken({name: this.state.name_on_card});
    };

    isStripeModelEmpty = () => {
        return (this.state.card_number_empty === this.state.card_expiry_empty === this.state.card_cvc_empty);
    };

    isNameOnCardEmpty = () => {
        return this.state.name_on_card === undefined || this.state.name_on_card.trim() === "";
    };

/*    isStripeModelIncomplete = () => {
        return false;
    };*/

    render() {

        const createOptions = () => {
            return {
                style: {
                    base: {
                        fontSize: '14px',
                        color: '#424770',
                        fontFamily: 'Open Sans, sans-serif',
                        letterSpacing: '0.025em',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#c23d4b',
                    }
                }
            }
        };

        return (

            <div>
                { this.props.formType === 'settings' ?
                    <fieldset>
                        <div className="form-group row align-items-center">
                            <label className="col-md-2 col-form-label">Credit Card</label>
                            <div className="col-sm-4 col-md-4 col-lg-3">
                                <CardNumberElement
                                    className="form-control"
                                    placeholder="Card number"
                                    {...createOptions()}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <br/>
                        <div className="form-group row align-items-center">
                            <label className="col-md-2 col-form-label">Name on Card</label>
                            <div className="col-sm-4 col-md-4 col-lg-3">
                                <Input type="text"
                                       id="inputName"
                                       name="name_on_card"
                                       placeholder="Name on the Card"
                                       value={this.state.name_on_card}
                                       onChange={this.handleNameChange}
                                />
                            </div>
                        </div>
                        <br/>
                        <div className="form-group row align-items-center">
                            <label className="col-md-2 col-form-label">Expiry Date</label>
                            <div className="col-sm-4 col-md-4 col-lg-3">
                                <CardExpiryElement
                                    className="form-control"
                                    placeholder="MM / YY"
                                    {...createOptions()}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <br/>
                        <div className="form-group row align-items-center">
                            <label className="col-md-2 col-form-label">CVC</label>
                            <div className="col-sm-4 col-md-4 col-lg-3">
                                <CardCVCElement
                                    className="form-control"
                                    placeholder="CVC"
                                    {...createOptions()}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </fieldset>
                    :
                    null
                }
                { this.props.formType === 'registration' ?
                    <Row>
                        <Col xl="12">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <CardNumberElement
                                        id="idCardNumber"
                                        name="card_number"
                                        className="form-control"
                                        ref="_elementNumber"
                                        placeholder="Card number"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl="12">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="text"
                                           id="idNameOnCard"
                                           name="name_on_card"
                                           placeholder="Name on card"
                                           value={this.state.name_on_card}
                                           onChange={this.handleNameChange}
                                           data-validate='["required"]'/>
                                </div>
                            </div>
                        </Col>
                        <Col xl="6">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <CardExpiryElement
                                        id="idCardExpiry"
                                        name="card_expiry"
                                        ref="_elementExpiry"
                                        className="form-control"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl="6">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <CardCVCElement
                                        id="idCardCVC"
                                        name="card_cvc"
                                        ref="_elementCVC"
                                        className="form-control"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    :
                    null
                }
                { this.props.formType === 'summary' ?
                    <Row>
                        <Col xl="12">
                            <br/>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <CardNumberElement
                                        id="idCardNumber"
                                        name="card_number"
                                        className="form-control"
                                        ref="_elementNumber"
                                        placeholder="Card number"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl="12">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="text"
                                           id="idNameOnCard"
                                           name="name_on_card"
                                           placeholder="Name on card"
                                           value={this.state.name_on_card}
                                           onChange={this.handleNameChange}
                                           data-validate='["required"]'/>
                                </div>
                            </div>
                        </Col>
                        <Col xl="6">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <CardExpiryElement
                                        id="idCardExpiry"
                                        name="card_expiry"
                                        ref="_elementExpiry"
                                        className="form-control"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl="6">
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <CardCVCElement
                                        id="idCardCVC"
                                        name="card_cvc"
                                        ref="_elementCVC"
                                        className="form-control"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    :
                    null
                }
            </div>
        );
    }
}

export default injectStripe(CreditCardElement);