import React from 'react';
import GroupActionButtons from './GroupActionButtons';
import GroupItem from './GroupItem';
import {Collapse} from 'antd';

const {Panel} = Collapse;

const GroupItemList = ({groups, groupsChange, fetchSensors, addGroup, editGroup, deleteGroup}) => {

    const findGroup = id => {

        const group = groups.filter(group => group.id === id)[0];
        return group !== undefined ?
            {
                group,
                index: groups.indexOf(group)
            } :
            null;
    }

    const moveGroup = (currentIndex, targetIndex, parentGroup) => {

        const newGroups = groups.map((group, i) => {
            if (targetIndex < currentIndex) {
                if (i === targetIndex)
                    return {...groups[currentIndex], group_order: i + 1};

                if (i > targetIndex && i <= currentIndex)
                    return {...groups[i - 1], group_order: i + 1};
            } else if (targetIndex > currentIndex) {
                if (i === targetIndex)
                    return {...groups[currentIndex], group_order: i + 1};

                if (i < targetIndex && i >= currentIndex)
                    return {...groups[i + 1], group_order: i + 1};
            }

            return group;
        });

        groupsChange(newGroups, parentGroup);
    };

    return groups.map((group, index) => (
        !!group.sub_groups ?
            (
                <GroupItem key={group.id} index={index} group={group} findGroup={findGroup} moveGroup={moveGroup}>
                    {(({dropRef, isActive, canDrop, isDragging}) =>
                    <div>
                            
                            <Collapse bordered={false} accordion={false}>
                                <Panel header={
                                    <div>
                                     <h5 className={`accordion__clickable-title u-position-relative draggable-list__item ${isActive ? 'droppable' : ''} ${canDrop ? 'can_drop' : ''} ${isDragging ? 'dragged' : ''}`}
                                        ref={dropRef}
                                            onClick={() => fetchSensors(group)}
                                        >
                                            {group.name}
                                           
                                        </h5>
                                        <GroupActionButtons 
                                                        group={group}
                                                        addAction={group.group_depth < 3 ? addGroup : undefined}
                                                        editAction={editGroup}
                                                        deleteAction={deleteGroup}
                                                    />
                                    </div>
                                     }>
                                    <GroupItemList
                                        groups={group.sub_groups}
                                        groupsChange={groupsChange}
                                        fetchSensors={fetchSensors}
                                        addGroup={addGroup}
                                        editGroup={editGroup}
                                        deleteGroup={deleteGroup}
                                    />
                                </Panel>
                            </Collapse>
                            </div>  )}
                    
                </GroupItem>
            ) : (
                <GroupItem key={group.id} index={index} group={group} findGroup={findGroup} moveGroup={moveGroup}>
                    {(({dropRef, isActive, canDrop, isDragging}) =>
                            <h5 ref={dropRef}
                                className={`accordion__clickable-title u-position-relative draggable-list__item ${isActive ? 'droppable' : ''} ${canDrop ? 'can_drop' : ''} ${isDragging ? 'dragged' : ''}`}
                                onClick={() => fetchSensors(group)}
                            >
                                {group.name}
                                <GroupActionButtons
                                    group={group}
                                    addAction={group.group_depth < 3 ? addGroup : undefined}
                                    editAction={editGroup}
                                    deleteAction={deleteGroup}/>
                            </h5>
                    )}
                </GroupItem>
            )
    ));
}

export default GroupItemList;