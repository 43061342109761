import React from 'react';
import {Trans} from 'react-i18next';
import sensorUtils from '../../../../SensorUtils';

type SensorBasicsUnitProps = {
	show: boolean;
	unit: string;
};

export default function SensorBasicsUnitComponent(props: SensorBasicsUnitProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.unit'} />
				</td>
				<td>{sensorUtils.getSensorUnitType(props.unit)}</td>
			</tr>
		)
	);
}
