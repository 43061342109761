import React, {Component, ReactNode} from 'react';
import {Table} from 'reactstrap';
import {Trans} from 'react-i18next';
import DateTimeUtils from '../../../Infrastructure/DateTime/DateTimeUtils';
import {CalibrationAnalysisInformationProps} from './CalibrationAnalysisInformationProps';

class CalibrationAnalysisInformation extends Component<CalibrationAnalysisInformationProps, {}> {
	constructor(props: CalibrationAnalysisInformationProps | Readonly<CalibrationAnalysisInformationProps>) {
		super(props);
	}

	render(): ReactNode {
		const calibration = this.props.calibration;

		return (
			<Table striped responsive className="col-md-12">
				<tbody>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.calibration_status'} />
						</td>
						<td width="35%">{calibration.State}</td>
						<td width="15%"></td>
						<td width="35%"></td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.calibration_start'} />
						</td>
						<td width="35%">{DateTimeUtils.setDateTimeWithOffset_date_dep(calibration.Start)}</td>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.calibration_stop'} />
						</td>
						<td width="35%">{calibration.Stop ? DateTimeUtils.setDateTimeWithOffset_date_dep(calibration.Stop) : ''}</td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.calibration_description'} />
						</td>
						<td colSpan={3} width="85%">
							{calibration.Description}
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default CalibrationAnalysisInformation;
