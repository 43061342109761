import React from 'react';
import LoadingTie from './LoadingTie';

const DeviationLowBatteryTie = props => {
    return (
        <div className="card card-default" style={{height: '100%'}} id="deviationLowBattery">
            <div className="card-body text-center">
                {props.low_battery.batteryLevel ? (
                    <>
                        <h4>Low Battery</h4>
                        <br />
                        <h3>{props.low_battery.batteryLevel}</h3>
                    </>
                ) : (
                    <LoadingTie />
                )}
            </div>
        </div>
    );
};

export default DeviationLowBatteryTie;
