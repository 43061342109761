import {MODES} from '../../Constants/Modes';
import {configurationLimitProfileFormSettings} from './LimitProfileFormSettings';

export const getInitialStateData = (mode, limitProfileId = null) => {
	const stateObject = {
		mode: mode,
		initialFormValues: undefined,
		formSettings: configurationLimitProfileFormSettings(mode),
		saveDisabled: true,
		sensors: undefined,
		limitProfiles: [],
		limitAlarmsForm: {
			limit_alarm_creation_type: 0,
			limit_alarms: [
				{
					key: 'H1',
					limit: 0,
					delay: 0,
					event: 'single',
					excursions: 'unlim',
				},
				{
					key: 'L1',
					limit: 0,
					delay: 0,
					event: 'single',
					excursions: 'unlim',
				},
			],
			limit_warnings: [],
			name: '',
		},
		errors: [],
		loaded: limitProfileId === null ? true : false,
	};

	if (mode === MODES.EDIT || mode === MODES.DISPLAY) {
		stateObject.limitAlarmsForm.id = limitProfileId;
		stateObject.limitAlarmsForm.limit_alarm_creation_type = limitProfileId;
	}

	return stateObject;
};
