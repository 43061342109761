const getPostfixIndex = (index: number): string => '_' + index;
const postfixIdxRegex = getPostfixIndex(0).replace(/\d+/g, '\\d+');
const postfixExt = '(external)';
const postfixExtRegex = postfixExt.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/**
 * EnrichSensorName adds the index of the sensor on the channel and (external) if an external sensor. In case this
 * information is already encoded in the name, it tries to extract the base name and recreates the new name.
 * @param inputName The current name of the sensor, e.g. X122233333_2
 * @param isExternal If the sensor is external or not
 * @param index The index of the sensor on the channel
 * @constructor
 */
export function EnrichSensorName(inputName: string, isExternal: boolean, index: number): string {
	let name: string;
	const postfixIdx = getPostfixIndex(index);

	name = `${inputName}${postfixIdx}${isExternal ? postfixExt : ''}`;

	return name;
}

/**
 * ExtractBaseName undoes what EnrichSensorName does to a sensors name
 * @param inputName
 * @constructor
 */
export function ExtractBaseName(inputName: string): string {
	const matchString = `^(.+)(${postfixIdxRegex})(${postfixExtRegex})?$`;
	const regex = new RegExp(matchString);

	const matches: RegExpExecArray | null = regex.exec(inputName);
	if (Array.isArray(matches) && matches.length >= 1) {
		return matches[1];
	} else {
		return inputName;
	}
}

export const SensorNameUtils = {
	EnrichSensorName,
	ExtractBaseName,
};
