import styled from 'styled-components';

export const AlertWrapper = styled.div`
	text-align: left;
	padding: 15px;
`;

export const StatusStepsWrapper = styled.div`
	padding: 15px 100px;
	min-width: 800px;
`;

export const PROGRESS_STATUS = {WAIT: 'wait', PROCESS: 'process', FINISH: 'finish', ERROR: 'error'};
export const STEP_DELAY = 2000;
export const INITIAL_DELAY = 2000;
export const REDIRECT_DELAY = 5000;
