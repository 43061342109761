import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import {Button} from 'reactstrap';
import Report from '../../Shared/Components/Report';
import {Trans} from 'react-i18next';
import React from 'react';
import {ExportType} from '../../Common/Export/ExportType';
import {ExportButton} from '../../Common/Export/ExportButton';

interface ReportComponentProps {
	debugReport: any;
	reportLocalhost: any;
	loadingReport: any;
	report: any;
	exportFunction: any;
	exportShowGeo: any;
	liveLink: any;
}

export const ReportComponent = (props: ReportComponentProps) => {
	return (
		<div style={{display: 'flex', justifyContent: 'right'}}>
			{props.debugReport ? (
				<div style={{padding: '0 10px'}}>
					<Button id="btnReport" className="btn btn-primary" color="danger" size="lg" onClick={props.reportLocalhost}>
						<span>
							<Trans i18nKey={'buttons.report'} />
						</span>
					</Button>
				</div>
			) : null}
			{props.liveLink ? (
				<Access
					access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.selectPreviousSensorRuns}
					roles={userRoles.default}
				>
					<div style={{padding: '0 5px'}}>
						<Button id="btnLiveLink" color="primary" onClick={props.liveLink} size="lg">
							<Trans i18nKey={'buttons.liveLink'} />
						</Button>
					</div>
				</Access>
			) : null}
			<div style={{padding: '0 5px'}}>
				<Access
					access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.createReport}
					roles={userRoles.default}
				>
					<Report onClick={props.report} loadingReport={props.loadingReport} />
				</Access>
			</div>
			<Access
				access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.createExcelReport}
				roles={userRoles.default}
			>
				<div style={{display: 'flex'}}>
					<ExportButton
						ExportType={ExportType.Excel}
						WithGeoData={props.exportShowGeo}
						ExportFunction={props.exportFunction}
						Disabled={false}
					></ExportButton>
					<ExportButton
						ExportType={ExportType.Csv}
						WithGeoData={props.exportShowGeo}
						ExportFunction={props.exportFunction}
						Disabled={false}
					></ExportButton>
				</div>
			</Access>
		</div>
	);
};
