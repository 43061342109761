import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';
import {isForeign} from '../../Authentication/Authentication';

const USER_ACCESS_LOCAL_STORAGE_KEY = process.env['USER_ACCESS_LOCAL_STORAGE_KEY'] || Math.random().toString();

const secureTokenStorage = new SecureStorage(localStorage, {
	hash: function hash(key) {
		key = CryptoJS.SHA256(key, USER_ACCESS_LOCAL_STORAGE_KEY);
		return key.toString();
	},
	encrypt: function encrypt(data) {
		data = CryptoJS.AES.encrypt(data, USER_ACCESS_LOCAL_STORAGE_KEY);
		data = data.toString();
		return data;
	},
	decrypt: function decrypt(data) {
		data = CryptoJS.AES.decrypt(data, USER_ACCESS_LOCAL_STORAGE_KEY);
		data = data.toString(CryptoJS.enc.Utf8);
		return data;
	},
});

const secureTokenStorage_f = new SecureStorage(sessionStorage, {
	hash: function hash(key) {
		key = CryptoJS.SHA256(key, USER_ACCESS_LOCAL_STORAGE_KEY);
		return key.toString();
	},
	encrypt: function encrypt(data) {
		data = CryptoJS.AES.encrypt(data, USER_ACCESS_LOCAL_STORAGE_KEY);
		data = data.toString();
		return data;
	},
	decrypt: function decrypt(data) {
		data = CryptoJS.AES.decrypt(data, USER_ACCESS_LOCAL_STORAGE_KEY);
		data = data.toString(CryptoJS.enc.Utf8);
		return data;
	},
});

const getTokenStorage = () => (isForeign() ? secureTokenStorage_f : secureTokenStorage);

const setRolesAccessToken = data => getTokenStorage().setItem('elproCLOUD-User', data);
const getRolesAccessToken = () => getTokenStorage().getItem('elproCLOUD-User');

const removeRolesAccessToken = () => getTokenStorage().removeItem('elproCLOUD-User');

const setFeatureFlagsToken = (data: string[]) => getTokenStorage().setItem('elproCLOUD-Feature-Flags', data);
const getFeatureFlagsToken = (): string[] => getTokenStorage().getItem('elproCLOUD-Feature-Flags') || [];
const removeFeatureFlagsToken = () => getTokenStorage().removeItem('elproCLOUD-Feature-Flags');

const setDashboardGroups = data => getTokenStorage().setItem('elproCLOUD-Groups', data);
const getDashboardGroups = () => {
	const dashboardGroups = getTokenStorage().getItem('elproCLOUD-Groups');

	return !!dashboardGroups
		? dashboardGroups
		: {
				groups: [],
				openGroups: [],
		  };
};
const removeDashboardGroups = () => getTokenStorage().removeItem('elproCLOUD-Groups');

const secureToken = {
	removeRolesAccessToken,
	setRolesAccessToken,
	getRolesAccessToken,
	setFeatureFlagsToken,
	getFeatureFlagsToken,
	removeFeatureFlagsToken,
	setDashboardGroups,
	getDashboardGroups,
	removeDashboardGroups,
};

export default secureToken;
