import {DateTimeUtils, TimeRange} from '../../Infrastructure/DateTime/DateTimeUtils';
import {Button, Col, DatePicker, Row} from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import styles from '../styles.module.css';
import {useSearchParams} from 'react-router-dom-v5-compat';

const {RangePicker} = DatePicker;

export interface TimeRangePickerProps {
	onTimeRangeChanged: (value: TimeRange) => void;
	value?: TimeRange;
}

export function TimeRangePicker(props: TimeRangePickerProps): React.JSX.Element {
	const searchParamStart = 'timeRangeStart';
	const searchParamEnd = 'timeRangeEnd';
	const [searchParams, setSearchParams] = useSearchParams();

	let value = props.value ?? DateTimeUtils.getXDaysBeforeDay(7);
	if (searchParams.has(searchParamStart)) {
		value.start = dayjs.unix(parseInt(searchParams.get(searchParamStart)));
	}
	if (searchParams.has(searchParamEnd)) {
		value.end = dayjs.unix(parseInt(searchParams.get(searchParamEnd)));
	}

	function onRangePickerChange(dates: [dayjs.Dayjs, dayjs.Dayjs], _: [string, string]) {
		if (!dates || dates.length !== 2) {
			return;
		}
		const timeRange: TimeRange = {start: dates[0], end: DateTimeUtils.getOpenInterval(dates[1], 'day')};
		onTimeRangeSelected(timeRange);
	}

	function onTimeRangeSelected(timeRange: TimeRange) {
		setSearchParams({
			...searchParams,
			[searchParamStart]: timeRange.start.unix().toString(),
			[searchParamEnd]: timeRange.end.unix().toString(),
		});
		props.onTimeRangeChanged(timeRange);
	}

	return (
		<Row className={styles.ComponentRow}>
			<Col>
				{/*Use this construct to center the buttons below the RangePicker*/}
				<Row align={'middle'} justify={'start'} className="mb-2">
					<Button type={'link'} onClick={() => onTimeRangeSelected(DateTimeUtils.getXDaysBeforeDay(1))}>
						Today
					</Button>
					|
					<Button type={'link'} onClick={() => onTimeRangeSelected(DateTimeUtils.getXDaysBeforeDay(7))}>
						Last 7 Days
					</Button>
					|
					<Button type={'link'} onClick={() => onTimeRangeSelected(DateTimeUtils.getXDaysBeforeDay(30))}>
						Last 30 Days
					</Button>
					|
					<Button type={'link'} onClick={() => onTimeRangeSelected(DateTimeUtils.getAllTimeRange())}>
						All
					</Button>
				</Row>
				<Row justify={'center'}>
					<RangePicker
						value={[value.start, value.end.subtract(1, 'ms')]}
						format={'DD.MM.YYYY HH:mm'}
						onChange={onRangePickerChange}
					/>
				</Row>
			</Col>
		</Row>
	);
}
