import {Sensor} from '../../components/Common/Types/Sensor';
import {DeviationService, MeasurementService, SensorGroupService, SensorService} from '../services';
import {SensorGroup} from '../types';
import CalibrationService from '../../components/Services/Calibrations/CalibrationService';
import {groupChannels} from '../util/SensorChannelGrouping';
import {CurrentCalibrationState} from '../../components/Calibration/Model/CalibrationState';

export class SensorServiceHelper {
	public static async GetSensorsWithData(sensor_id?: number): Promise<Sensor[]> {
		let sensors: Sensor[] = [];
		let groups: SensorGroup[] = [];
		let calibrationStates: CurrentCalibrationState[] = [];

		const calibrationService = new CalibrationService();

		try {
			sensors = await SensorService.GetSensorsFromDashboardInfo(sensor_id);
			groups = await SensorGroupService.GetSensorGroups();
			const measurements = await MeasurementService.GetLastMeasurementValues();
			const deviationStatus = await DeviationService.GetDeviationStatus();
			const runInfoSummaries = await SensorService.GetRunInfoSummary();

			for (const sensor of sensors) {
				sensor.AddNewMeasurement(measurements.find(m => m.SensorId === sensor.Id));
				sensor.Deviations = deviationStatus.filter(d => d.SensorId === sensor.Id);
				sensor.SetGroups(groups.filter(g => sensor.GroupIds.find(gId => gId === g.Id)));
				sensor.RunInfoSummary = runInfoSummaries.find(r => r.SensorId == sensor.Id);
			}

			calibrationStates = await calibrationService.getCurrentCalibrationStatus(...sensors.map(s => s.Id));
		} catch (e) {
			console.error(e);
			return [];
		}

		const groupedSensors = groupChannels(sensors);
		groupedSensors.forEach((group, _groupingKey) => {
			group.forEach((sensor, idx) => {
				sensors.find(s => s.Id == sensor.Id).AdditionalData['ChannelId'] = idx + 1;
			});
		});

		calibrationStates.forEach(c => {
			const sensor = sensors.find(s => s.Id === c.SensorId);
			sensor.CalibrationState = c.State;
			sensor.CanStartCalibration = c.Options.StartCalibrationPossible;
			sensor.CanStopCalibration = c.Options.StopCalibrationPossible;
		});

		return sensors;
	}
}
