import React, {Component} from 'react';
import ContentWrapper from '../../../Layout/ContentWrapper';
import pubsub from 'pubsub-js';
import SensorService from '../SensorService';
import ConnectModuleHowTo from './ConnectModuleHowTo';
import ConnectModuleState from './ConnectModuleState';
import moment from 'moment';
import Message from '../../../Shared/Components/Message';
import RequestLogger from '../../../Infrastructure/Requests/Logger/RequestLogger';
import {MultiContext} from '../../../Infrastructure/Authorization/Context/MultiContext';

class ConnectModule extends Component {
	static contextType = MultiContext;

	// interval subscription
	intervalID = 0;

	constructor(props) {
		super(props);

		this.state = {
			id: props.match.params.id,
			serialNumber: props.match.params.serialNumber,
			sensorType: this.getSensorType(props.match.params),
			state: 'prepared',
			pairingDuration: 0,
			remainingTimeToPair: 0,
			pairingEndTime: null,
		};

		this.handleSensorStatusChanged = this.handleSensorStatusChanged.bind(this);
	}

	componentDidMount() {
		this.handleSensorStatusChangedToken = pubsub.subscribe('sensor_status_changed/' + this.state.id, this.handleSensorStatusChanged);
		this.getSensor(this.state.id);
	}

	componentWillUnmount() {
		pubsub.unsubscribe(this.handleSensorStatusChangedToken);
		this.deleteLocalStorage();
		clearInterval(this.intervalID);
	}

	getSensorType = params => {
		const serialNumber = params.serialNumber ? params.serialNumber.charAt(0) : null;
		const prefix = params.prefix ? params.prefix : null;
		return serialNumber === prefix.charAt(0) ? prefix : null;
	};

	getSensor(id) {
		SensorService.sensor(id, RequestLogger.createLogData('connect-module', 'load-sensor-data', 'onClick'))
			.then(response => {
				const durationMinutes = response.data.pairing_duration_seconds / 60;
				this.setState({state: response.data.state, pairingDuration: durationMinutes}, () => {
					this.prepareLocalStorage();
				});
			})
			.catch(error => {
				console.log('error ', error);
			});
	}

	handleSensorStatusChanged(message, data) {
		this.setState({state: data.state});
	}

	close = e => {
		this.redirect();
		e.preventDefault();
	};

	redirect = () => {
		const DevicesViewSettings = this.context.AccessContext.user.user_settings.devicesViewSettings;
		this.props.history.push(DevicesViewSettings.view);
	};

	prepareLocalStorage = () => {
		const localStorageData = this.getLocalStorage();

		let end = moment().add(this.state.pairingDuration + 1, 'minutes');

		if (!localStorageData) {
			this.setLocalStorage({endTime: end});
		} else {
			end = localStorageData.endTime;
		}

		this.setEndTime(end);
	};

	setEndTime = end => {
		this.setState({pairingEndTime: end}, () => {
			this.startTimer();
		});
	};

	getLocalStorage = () => {
		const storageData = localStorage.getItem('sensorPairing');
		if (!storageData) {
			return undefined;
		} else {
			return JSON.parse(storageData);
		}
	};

	setLocalStorage = storageData => {
		localStorage.setItem('sensorPairing', JSON.stringify(storageData));
	};

	deleteLocalStorage = () => {
		localStorage.removeItem('sensorPairing');
	};

	startTimer = () => {
		this.intervalID = setInterval(() => {
			this.getRemainingTime();
		}, 60000);
	};

	getRemainingTime = () => {
		const start = moment();
		const remainingTimeInMinutes = moment.duration(moment(this.state.pairingEndTime).diff(start));
		if (remainingTimeInMinutes.minutes() <= 0) {
			Message.error('Pairing time expired', 'The sensor could not be paired in the given time');
			this.redirect();
		}
	};

	render() {
		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>
						{['91', '92', '93', '94', '95', '96', '32', '33', '34', '35', '36'].includes(this.state.sensorType)
							? 'Configuration complete'
							: 'Connect your module now'}
					</div>
				</div>
				{this.state.pairingDuration && (
					<>
						<ConnectModuleHowTo
							sensorType={this.state.sensorType}
							pairingDuration={this.state.pairingDuration}
							serialNumber={this.state.serialNumber}
						/>
						<ConnectModuleState sensorState={this.state.state} sensorType={this.state.sensorType} close={this.close} />
					</>
				)}
			</ContentWrapper>
		);
	}
}

export default ConnectModule;
