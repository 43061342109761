import React from 'react';
import {Row, Col} from 'reactstrap';
import {MODES} from '../Modes';
import {
	MISSING_COMMUNICATION_WARNING_ENUM,
	MISSING_COMMUNICATION_WARNING_STRINGS,
} from '../../../../Shared/Constants/MissingCommunicationWarning/MissingCommunicationWarning';

export default function MissingCommunicationWarningEnum(props) {
	return (
		<Row>
			<Col xl={3}>
				<select
					className="form-control"
					id="missingCommunicationWarningEnum"
					name="missing_communication_warning_enum"
					value={props.issueProfile.missing_communication_warning_enum}
					onChange={props.handleIssueAlarmInputChange}
					disabled={props.mode === MODES.DISPLAY ? true : !props.issueProfile.missing_communication_warning}
				>
					{Object.values(MISSING_COMMUNICATION_WARNING_ENUM)
						.filter(v => !isNaN(Number(v)))
						.map(v => (
							<option value={v}>{MISSING_COMMUNICATION_WARNING_STRINGS[v]}</option>
						))}
				</select>
			</Col>
		</Row>
	);
}
