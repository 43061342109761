import React, {Component} from 'react';
import {Card, CardBody, Col} from 'reactstrap';

class NewModuleCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.type,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClicked(this.state.type);
    }

    getImage() {
        return '/img/AddModuleType' + this.state.type + '.png';
    }

    getText() {
        switch (this.state.type) {
            case 1:
                return (
                    <h4 align="center">
                        Add new radio module and <br /> configure sensor{' '}
                    </h4>
                );

            case 2:
                return <h4 align="center">Configure an additional sensor connected to an already registered module</h4>;
            default:
                return null;
        }
    }

    render() {
        return (
            <Col md={6} lg={3}>
                <Card onClick={this.handleClick} className={'card card-default'}>
                    <CardBody>
                        <img className="mx-auto d-block" src={this.getImage()} />
                        {this.getText()}
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
export default NewModuleCard;
