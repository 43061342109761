import React, {Component} from 'react';
import ReactHighcharts from 'react-highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import highchartsExporting from 'highcharts/modules/exporting';
import moment from 'moment';
import SensorUtils from '../../Dashboard/Sensor/SensorUtils';
import sensorUtils from '../../Dashboard/Sensor/SensorUtils';
import {Button} from 'antd';
import {Trans} from 'react-i18next';
import ChartLegend from './ChartLegend';
import {MODULE_FAMILIES} from '../../Shared/Constants/Module';
import {
	ALARM_EVENTS,
	FLIGHT_MODE_EVENTS,
	LOGGER_STATUS_EVENTS,
	MENU_SET_MARK_EVENTS,
	OCCURRENCES,
	OTHER_OCCURRENCES,
	PLOT_LINE_COLORS,
	REPLACE_SENSOR_EVENTS,
	RUN_EVENTS,
	SENSOR_ISSUE_ALARMS,
	SENSOR_LIMIT_ALARMS,
} from '../../Shared/Constants/Chart';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import GroupAlarmStatusesAccordingToDate from './ExtensionMethods/GroupAlarmStatusesAccordingToDate';
import PickColorOfPlotLine from './ExtensionMethods/PickColorOfPlotLine';
import ChartConfiguration from './ChartConfiguration';
import PrepareDescText from './ExtensionMethods/PrepareDescText';
import {highchartCustomPlotbands} from './ExtensionMethods/HighChartPlotbandExtension';

class ChartComponent extends Component {
	constructor(props) {
		super(props);
		addNoDataModule(ReactHighcharts.Highcharts);
		highchartsExporting(ReactHighcharts.Highcharts);
		highchartCustomPlotbands(ReactHighcharts.Highcharts);

		this.state = {
			chart: undefined,
			showReloadedValues: false,
			showSensorCalibrationValues: false,
			showBypassedValues: true, //Alarming inactive
			showInTransit: false,
			showMeasurementUploads: false, //Communication times
			startRunStopRunSettings: {
				showStartRunStopRunCheckBox: false,
				showStartRunStopRunValues: false,
				startRunStopRunLegends: [],
				showStartRunLegend: false,
				showStopRunLegend: false,
			},
			alarmingOnAlarmingOffSettings: {
				showAlarmingOnAlarmingOffCheckBox: false,
				showAlarmingOnAlarmingOffValues: false,
				alarmingOnAlarmingOffLegends: [],
				showAlarmingOnLegend: false,
				showAlarmingOffLegend: false,
			},
			logStatusesSettings: {
				showLogStatusesCheckBox: false,
				showLogStatusesValues: false,
				logStatusLegends: [],
				showStartLegend: false,
				showLogDelayedLegend: false,
				showLogTransitLegend: false,
				showLogPausedLegend: false,
				showLogArrivedLegend: false,
				showLogStoppedLegend: false,
				showCalibLegend: false,
			},
			sensorLimitAlarmsSettings: {
				showSensorLimitAlarmsCheckBox: false,
				showSensorLimitAlarmsValues: false,
				limitAlarmLegends: [],
				showSensorUpperLimitAlarmLegend: false,
				showSensorLowerLimitAlarmLegend: false,
			},
			sensorIssueAlarmsSettings: {
				showSensorIssueAlarmsCheckBox: false,
				showSensorIssueAlarmsValues: false,
				issueLegends: [],
				showRadioConnectionWarningLegend: false,
				showLostMeasurementAlarmLegend: false,
				showLowBatteryWarningLegend: false,
				showMissingCommunicationWarningLegend: false,
				showMissingValueAlarmLegend: false,
				showSensorFailureAlarmLegend: false,
			},
			flightModeOnOffSettings: {
				showFlightModeOnOffCheckBox: false,
				showFlightModeOnOffValues: false,
				flightModeOnOffLegends: [],
				showFlightModeOnLegend: false,
				showFlightModeOffLegend: false,
			},
			menuSetMarkSettings: {
				showMenuSetMarkCheckBox: false,
				showMenuSetMarkValues: false,
				MenuSetMarkLegends: [],
				showMenuSetMarkLegend: false,
			},
			sensorReplacesSettings: {
				showSensorReplacesCheckBox: false,
				showSensorReplacesValues: false,
				sensorReplacesLegends: [],
				showSensorReplacesLegend: false,
			},
		};
	}

	componentDidMount() {
		this.reloadChart();
	}

	componentDidUpdate(prevProps) {
		if (this.props.measurements !== prevProps.measurements || this.props.limitAlarms !== prevProps.limitAlarms) {
			this.reloadChart();
		}

		if (this.props.sensorLimitAlarms !== prevProps.sensorLimitAlarms) {
			this.DrawSensorLimitAlarms();
		}

		if (this.props.sensorIssueAlarms !== prevProps.sensorIssueAlarms) {
			this.DrawSensorIssueAlarms();
		}

		if (this.props.sensorReplaces !== prevProps.sensorReplaces) {
			this.DrawSensorReplaces();
		}
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.redrawChart;
	}

	getChart = () => {
		return this.chart ? this.chart.getChartHTML() : null;
	};

	reloadChart = () => {
		this.loading();
		this.series = [];
		this.alarmZones = [];
		this.sensorReplaces = [];
		this.series.push({
			id: 'main',
			name: this.props.sensor ? this.props.sensor.name : 'sensor not found!',
			data: [],
		});
		this.series.push({
			id: 'events',
			data: [],
			showInLegend: false,
			visible: false,
		});

		if (this.props.measurements && this.props.measurements.length > 0) {
			this.drawGraphic();
			this.drawAlarmLimits();

			if (this.props.moduleFamilyType !== undefined && this.props.moduleFamilyType !== null) {
				switch (this.props.moduleFamilyType) {
					case MODULE_FAMILIES.LIBERO_G: {
						this.DrawStartRunStopRun();
						this.DrawAlarmingOnAlarmingOff();
						this.DrawFlightModeOnOff();
						this.DrawLoggerStatuses();
						this.DrawMenuSetMark();
						this.DrawSensorLimitAlarms();
						break;
					}
					case MODULE_FAMILIES.ECOLOG_PRO_G:
						this.DrawFlightModeOnOff();
						this.DrawSensorIssueAlarms();
						this.DrawSensorReplaces();
						this.DrawMenuSetMark();
						break;
					case MODULE_FAMILIES.ECOLOG_PRO_RADIO: {
						this.DrawSensorIssueAlarms();
						this.DrawSensorReplaces();
						break;
					}
					default:
					//do nothing.
				}
			}
		}

		this.stopLoading();
	};

	setLineLegendItemVisible(name: string, setVisible: boolean, color: string, chart: any = null) {
		if (setVisible) {
			this.addLineLegendItem(name, color, chart);
		} else {
			this.removedLineLegendItem(name);
		}
	}

	addLineLegendItem = (name, color, chart = null) => {
		if (chart === null) {
			if (!this.chart.series.some(item => item.name === name)) {
				this.chart.addSeries(this.makeLineLegendItemFrom0Series(name, color));
			}
		} else {
			chart.addSeries(this.makeLineLegendItemFrom0Series(name, color));
		}
	};

	removedLineLegendItem = name => {
		const index = this.chart.series.findIndex(item => item.name === name);
		if (index >= 0) {
			this.chart.series[index].remove(true);
		}
	};

	makeLineLegendItemFrom0Series = (name, color) => {
		return {
			// Series that mimics the plot line
			name: name,
			color: color,
			dashStyle: 'line',
			marker: {
				enabled: false,
			},
			events: {
				legendItemClick: function () {
					return false;
				},
			},
		};
	};

	addSensorErrorLegend = (error, chart) => {
		const errorColor = PLOT_LINE_COLORS[error.replaceAll(' ', '_').toUpperCase()];
		chart.addSeries({
			name: 'Sensor Error: ' + error,
			color: errorColor ? errorColor : PLOT_LINE_COLORS.SENSOR_ERROR,
			events: {
				legendItemClick: function () {
					return false;
				},
			},
		});
	};

	DrawStartRunStopRun = () => {
		let showCheckBox = false;
		this.startStopRuns = [];
		this.state.startRunStopRunSettings.startRunStopRunLegends = [];
		this.state.startRunStopRunSettings.showStartRunLegend = false;
		this.state.startRunStopRunSettings.showStopRunLegend = false;
		this.state.startRunStopRunSettings.showStartRunStopRunValues = false;

		if (this.props.startRun) {
			this.startStopRuns.push({
				desc: RUN_EVENTS.START_RUN[0],
				tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(this.props.startRun),
			});
			this.state.startRunStopRunSettings.startRunStopRunLegends.push({
				legendDesc: RUN_EVENTS.START_RUN[1],
			});
			if (this.state.startRunStopRunSettings.showStartRunStopRunValues) {
				this.state.startRunStopRunSettings.showStartRunLegend = true;
			}
			showCheckBox = true;
		}

		if (this.props.stopRun) {
			this.startStopRuns.push({
				desc: RUN_EVENTS.STOP_RUN[0],
				tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(this.props.stopRun),
			});
			this.state.startRunStopRunSettings.startRunStopRunLegends.push({
				legendDesc: RUN_EVENTS.STOP_RUN[1],
			});
			if (this.state.showStartRunStopRunValues) {
				this.state.startRunStopRunSettings.showStopRunLegend = true;
			}
			showCheckBox = true;
		}

		this.state.startRunStopRunSettings.showStartRunStopRunCheckBox = showCheckBox;
	};

	DrawAlarmingOnAlarmingOff = () => {
		let showCheckBox = false;
		this.alarmingOnOff = [];
		this.state.alarmingOnAlarmingOffSettings.alarmingOnAlarmingOffLegends = [];
		this.state.alarmingOnAlarmingOffSettings.showAlarmingOffLegend = false;
		this.state.alarmingOnAlarmingOffSettings.showAlarmingOnLegend = false;

		if (this.menuAlarmingOnOffOccurrences && this.menuAlarmingOnOffOccurrences.length > 0) {
			this.menuAlarmingOnOffOccurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (s.metadata['alarming_on'] === ALARM_EVENTS.ALARMING_OFF[2]) {
					this.alarmingOnOff.push({
						desc: ALARM_EVENTS.ALARMING_OFF[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.alarmingOnAlarmingOffSettings.alarmingOnAlarmingOffLegends.push({
						legendDesc: ALARM_EVENTS.ALARMING_OFF[1],
					});
					if (this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues) {
						this.state.alarmingOnAlarmingOffSettings.showAlarmingOffLegend = true;
					}
					showCheckBox = true;
				} else if (s.metadata['alarming_on'] === ALARM_EVENTS.ALARMING_ON[2]) {
					this.alarmingOnOff.push({
						desc: ALARM_EVENTS.ALARMING_ON[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.alarmingOnAlarmingOffSettings.alarmingOnAlarmingOffLegends.push({
						legendDesc: ALARM_EVENTS.ALARMING_ON[1],
					});
					if (this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues) {
						this.state.alarmingOnAlarmingOffSettings.showAlarmingOnLegend = true;
					}
					showCheckBox = true;
				}
			});
		}

		this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffCheckBox = showCheckBox;
	};

	DrawFlightModeOnOff = () => {
		let CountOfFlightModeOn = 0;
		let flightDetected = false;
		let flightStartDate = undefined;
		let showCheckBox = false;
		this.flightModeOnOff = [];
		this.state.flightModeOnOffSettings.flightModeOnOffLegends = [];
		this.state.flightModeOnOffSettings.showFlightModeOnLegend = false;
		this.state.flightModeOnOffSettings.showFlightModeOffLegend = false;

		if (this.flightModeOnOffOccurrences && this.flightModeOnOffOccurrences.length > 0) {
			this.flightModeOnOffOccurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (CountOfFlightModeOn > 0 && s.metadata['flight_detected'] === FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[2]) {
					CountOfFlightModeOn--;
					flightDetected = false;
					let diffMs = +tstamp - +flightStartDate;
					const diffMins = Math.floor(diffMs / 1000 / 60);
					if (diffMins > 30) {
						this.flightModeOnOff.push(
							{
								desc: FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[0],
								tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(flightStartDate),
							},
							{
								desc: FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[0],
								tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
							}
						);
						this.state.flightModeOnOffSettings.flightModeOnOffLegends.push(
							{
								legendDesc: FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[1],
							},
							{
								legendDesc: FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[1],
							}
						);
						if (this.state.flightModeOnOffSettings.showFlightModeOnOffValues) {
							this.state.flightModeOnOffSettings.showFlightModeOnLegend = true;
							this.state.flightModeOnOffSettings.showFlightModeOffLegend = true;
						}
						showCheckBox = true;
					}
				} else if (s.metadata['flight_detected'] === FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[2]) {
					if (!flightDetected) {
						CountOfFlightModeOn++;
						flightDetected = true;
						flightStartDate = tstamp;
					}
				}
			});
		}

		this.state.flightModeOnOffSettings.showFlightModeOnOffCheckBox = showCheckBox;
	};

	DrawLoggerStatuses = () => {
		let showCheckBox = false;
		this.logStatuses = [];
		this.state.logStatusesSettings.logStatusesLegends = [];
		this.state.logStatusesSettings.showStartLegend = false; // START
		this.state.logStatusesSettings.showLogDelayedLegend = false; //LOG DELAYED
		this.state.logStatusesSettings.showLogTransitLegend = false; //LOG TRANSIT
		this.state.logStatusesSettings.showLogPausedLegend = false; //LOG PAUSED
		this.state.logStatusesSettings.showLogArrivedLegend = false; //LOG ARRIVED
		this.state.logStatusesSettings.showLogStoppedLegend = false; //LOG STOPPED
		this.state.logStatusesSettings.showCalibLegend = false; //CALIB

		if (this.occurrences && this.occurrences.length > 0) {
			this.occurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (s.state === LOGGER_STATUS_EVENTS.START[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.START[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.logStatusesSettings.logStatusLegends.push({
						legendDesc: LOGGER_STATUS_EVENTS.START[1],
					});
					if (this.state.logStatusesSettings.showLogStatusesValues) {
						this.state.logStatusesSettings.showStartLegend = true;
					}
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_DELAY[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_DELAY[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.logStatusesSettings.logStatusLegends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_DELAY[1],
					});
					if (this.state.logStatusesSettings.showLogStatusesValues) {
						this.state.logStatusesSettings.showLogDelayedLegend = true;
					}
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_TRANSIT[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_TRANSIT[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.logStatusesSettings.logStatusLegends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_TRANSIT[1],
					});
					if (this.state.logStatusesSettings.showLogStatusesValues) {
						this.state.logStatusesSettings.showLogTransitLegend = true;
					}
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_PAUSED[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_PAUSED[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.logStatusesSettings.logStatusLegends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_PAUSED[1],
					});
					if (this.state.logStatusesSettings.showLogStatusesValues) {
						this.state.logStatusesSettings.showLogPausedLegend = true;
					}
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_ARRIVED[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_ARRIVED[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.logStatusesSettings.logStatusLegends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_ARRIVED[1],
					});
					if (this.state.logStatusesSettings.showLogStatusesValues) {
						this.state.logStatusesSettings.showLogArrivedLegend = true;
					}
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_STOPPED[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_STOPPED[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.logStatusesSettings.logStatusLegends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_STOPPED[1],
					});
					if (this.state.logStatusesSettings.showLogStatusesValues) {
						this.state.logStatusesSettings.showLogStoppedLegend = true;
					}
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.CALIB[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.CALIB[0],
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.logStatusesSettings.logStatusLegends.push({
						legendDesc: LOGGER_STATUS_EVENTS.CALIB[1],
					});
					if (this.state.logStatusesSettings.showLogStatusesValues) {
						this.state.logStatusesSettings.showCalibLegend = true;
					}
					showCheckBox = true;
				}
			});
		}

		this.state.logStatusesSettings.showLogStatusesCheckBox = showCheckBox;
	};

	DrawSensorLimitAlarms = () => {
		let showCheckBox = false;
		this.sensorLimitAlarms = [];
		this.state.sensorLimitAlarmsSettings.limitAlarmLegends = [];
		this.state.sensorLimitAlarmsSettings.showSensorUpperLimitAlarmLegend = false;
		this.state.sensorLimitAlarmsSettings.showSensorLowerLimitAlarmLegend = false;

		if (this.props.sensorLimitAlarms) {
			for (let i = 0; i < this.props.sensorLimitAlarms.length; i++) {
				const desc = this.props.sensorLimitAlarms[i].desc;
				const tstamp =
					DateTimeUtils.utcOffset_date_dep(this.props.sensorLimitAlarms[i].tstamp).format('YYYY-MM-DDTHH:mm') + '+00:00';
				if (desc === SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[1]) {
					this.sensorLimitAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[0],
					});
					this.state.sensorLimitAlarmsSettings.limitAlarmLegends.push({
						legendDesc: SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[1],
					});
					if (this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues) {
						this.state.sensorLimitAlarmsSettings.showSensorUpperLimitAlarmLegend = true;
					}
					showCheckBox = true;
				} else if (desc === SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[1]) {
					this.sensorLimitAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[0],
					});
					this.state.sensorLimitAlarmsSettings.limitAlarmLegends.push({
						legendDesc: SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[1],
					});
					if (this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues) {
						this.state.sensorLimitAlarmsSettings.showSensorLowerLimitAlarmLegend = true;
					}
					showCheckBox = true;
				}
			}
		}

		this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsCheckBox = showCheckBox;

		if (this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues) {
			this.setAllVisible(this.chart);
		}
	};

	DrawSensorIssueAlarms = () => {
		let showCheckBox = false;
		this.sensorErrorValues = [];
		this.sensorIssueAlarms = [];
		this.state.sensorIssueAlarmsSettings.issueLegends = [];
		this.state.sensorIssueAlarmsSettings.showRadioConnectionWarningLegend = false;
		this.state.sensorIssueAlarmsSettings.showLostMeasurementAlarmLegend = false;
		this.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend = false;
		this.state.sensorIssueAlarmsSettings.showMissingCommunicationWarningLegend = false;
		this.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend = false;
		this.state.sensorIssueAlarmsSettings.showSensorFailureAlarmLegend = false;

		if (this.props.sensorErrors && this.props.sensorErrors.length > 0) {
			this.sensorErrorValues = this.props.sensorErrors.reduce((group, error) => {
				const {type} = error;
				const errorColor = PLOT_LINE_COLORS[type.replaceAll(' ', '_').toUpperCase()];
				group[type] = group[type] ? group[type] : {tstamps: [], color: errorColor ? errorColor : PLOT_LINE_COLORS.SENSOR_ERROR};
				group[type].tstamps.push({tstamp: error.start});
				group[type].tstamps.push({tstamp: error.end});
				return group;
			}, {});

			showCheckBox = true;
		}

		if (this.props.sensorIssueAlarms) {
			for (let i = 0; i < this.props.sensorIssueAlarms.length; i++) {
				const desc = this.props.sensorIssueAlarms[i].desc;
				const tstamp =
					DateTimeUtils.utcOffset_date_dep(this.props.sensorIssueAlarms[i].tstamp).format('YYYY-MM-DDTHH:mm') + '+00:00';
				if (desc === SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[1]) {
					this.sensorIssueAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[0],
					});
					this.state.sensorIssueAlarmsSettings.issueLegends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[1],
					});
					if (this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues) {
						this.state.sensorIssueAlarmsSettings.showRadioConnectionWarningLegend = true;
					}
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[1]) {
					this.sensorIssueAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[0],
					});
					this.state.sensorIssueAlarmsSettings.issueLegends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[1],
					});
					if (this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues) {
						this.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend = true;
					}
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[1]) {
					this.sensorIssueAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[0],
					});
					this.state.sensorIssueAlarmsSettings.issueLegends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[1],
					});
					if (this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues) {
						this.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend = true;
					}
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[1]) {
					this.sensorIssueAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[0],
					});
					this.state.sensorIssueAlarmsSettings.issueLegends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[1],
					});
					if (this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues) {
						this.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend = true;
					}
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[1]) {
					this.sensorIssueAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[0],
					});
					this.state.sensorIssueAlarmsSettings.issueLegends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[1],
					});
					if (this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues) {
						this.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend = true;
					}
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[1]) {
					this.sensorIssueAlarms.push({
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[0],
					});
					this.state.sensorIssueAlarmsSettings.issueLegends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[1],
					});
					if (this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues) {
						this.state.sensorIssueAlarmsSettings.showSensorFailureAlarmLegend = true;
					}
					showCheckBox = true;
				}
			}
		}
		this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsCheckBox = showCheckBox;
	};

	DrawMenuSetMark = () => {
		let showCheckBox = false;
		this.MenuSetMark = [];
		this.state.menuSetMarkSettings.MenuSetMarkLegends = [];
		this.state.menuSetMarkSettings.showMenuSetMarkLegend = false;

		if (this.menuSetMarkOccurrences && this.menuSetMarkOccurrences.length > 0) {
			this.menuSetMarkOccurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (s.metadata['mark_counter']) {
					const desc = MENU_SET_MARK_EVENTS.MARK_COUNTER[0] + ' : ' + s.metadata['mark_counter'];
					this.MenuSetMark.push({
						desc: desc,
						tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					this.state.menuSetMarkSettings.MenuSetMarkLegends.push({
						legendDesc: MENU_SET_MARK_EVENTS.MARK_COUNTER[1],
					});
					if (this.state.menuSetMarkSettings.showMenuSetMarkValues) {
						this.state.menuSetMarkSettings.showMenuSetMarkLegend = true;
					}
					showCheckBox = true;
				}
			});
		}

		this.state.menuSetMarkSettings.showMenuSetMarkCheckBox = showCheckBox;
	};

	DrawSensorReplaces = () => {
		let showCheckBox = false;
		this.state.sensorReplacesSettings.sensorReplacesLegends = [];
		this.state.sensorReplacesSettings.showSensorReplacesLegend = false;

		if (this.props.sensorReplaces) {
			for (let i = 0; i < this.props.sensorReplaces.length; i++) {
				const desc = this.props.sensorReplaces[i].desc;
				const tstamp = DateTimeUtils.utcOffset_date_dep(this.props.sensorReplaces[i].tstamp).format('YYYY-MM-DDTHH:mm') + '+00:00';

				this.sensorReplaces.push({
					tstamp: sensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					desc: desc,
				});
				this.state.sensorReplacesSettings.sensorReplacesLegends.push({
					legendDesc: REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[1],
				});
				if (this.state.sensorReplacesSettings.showSensorReplacesValues) {
					this.state.sensorReplacesSettings.showSensorReplacesLegend = true;
				}
				showCheckBox = true;
			}
		}

		this.state.sensorReplacesSettings.showSensorReplacesCheckBox = showCheckBox;
	};

	drawGraphic = () => {
		let that = this;
		let deferred = false;
		let bypassed = false;
		this.deferredDataPoints = [];
		this.occurrences = [];
		this.menuAlarmingOnOffOccurrences = [];
		this.flightModeOnOffOccurrences = [];
		this.menuSetMarkOccurrences = [];
		this.all = [];
		this.startStopRuns = [];
		this.alarmingOnOff = [];
		this.flightModeOnOff = [];
		this.logStatuses = [];
		this.sensorLimitAlarms = [];
		this.sensorIssueAlarms = [];
		this.MenuSetMark = [];
		this.sensorReplaces = [];
		this.communication_times = [];
		this.bypassedDataPoints = [];
		this.sensorErrorValues = [];
		this.sensorCalibrations = [];

		for (let i = 0; i < this.props.measurements.length; i++) {
			const dataPoint = this.props.measurements[i];

			if (dataPoint.bypassed !== bypassed) {
				this.bypassedDataPoints.push({
					isBypassed: dataPoint.bypassed,
					tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(dataPoint.tstamp),
					value: SensorUtils.convertTemperature(dataPoint.value, that.props.sensor.out_units_id),
				});

				bypassed = dataPoint.bypassed;
			}

			if (dataPoint.deferred !== deferred) {
				this.deferredDataPoints.push({
					isDeferred: dataPoint.deferred,
					tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(dataPoint.tstamp),
				});

				deferred = dataPoint.deferred;
			}

			that.series[0].data.push({
				x: SensorUtils.convertPostgresqlTimestampToHighcharts(dataPoint.tstamp),
				y: SensorUtils.convertTemperature(dataPoint.value, that.props.sensor.out_units_id),
			});
		}

		if (this.props.sensorCalibrations) {
			for (const sensorCalibration of this.props.sensorCalibrations) {
				this.sensorCalibrations.push({tstamp: sensorCalibration.start});
				this.sensorCalibrations.push({tstamp: sensorCalibration.end});
			}
		}

		if (this.props.occurrences) {
			for (let i = 0; i < this.props.occurrences.length; i++) {
				const type_name = this.props.occurrences[i].type_name;
				const tstamp = SensorUtils.convertPostgresqlTimestampToHighcharts(this.props.occurrences[i].tstamp);
				if (type_name === OCCURRENCES.REQUIRED_TYPES[0]) {
					const newState = this.props.occurrences[i].new_state;
					this.occurrences.push({
						tstamp: tstamp,
						state: newState,
					});
				} else if (type_name === OCCURRENCES.REQUIRED_TYPES[1]) {
					this.communication_times.push(tstamp);
				}
			}
		}

		if (this.props.otherOccurrences) {
			for (let i = 0; i < this.props.otherOccurrences.length; i++) {
				const typeName = this.props.otherOccurrences[i].type_name;
				const timestamp = SensorUtils.convertPostgresqlTimestampToHighcharts(this.props.otherOccurrences[i].tstamp);

				if (typeName === OTHER_OCCURRENCES.REQUIRED_TYPES[0]) {
					const metadata = this.props.otherOccurrences[i].metadata;
					this.flightModeOnOffOccurrences.push({
						tstamp: timestamp,
						metadata: metadata,
					});
				} else if (typeName === OTHER_OCCURRENCES.REQUIRED_TYPES[1]) {
					const metadata = this.props.otherOccurrences[i].metadata;
					this.menuAlarmingOnOffOccurrences.push({
						tstamp: timestamp,
						metadata: metadata,
					});
				} else if (typeName === OTHER_OCCURRENCES.REQUIRED_TYPES[2]) {
					const metadata = this.props.otherOccurrences[i].metadata;
					this.menuSetMarkOccurrences.push({
						tstamp: timestamp,
						metadata: metadata,
					});
				}
			}
		}

		const addLineLegendItem = (name, color) => {
			that.series.push(this.makeLineLegendItemFrom0Series(name, color));
		};

		if (this.props.deviationStart) {
			addLineLegendItem('Deviation start', 'red');
		}

		if (this.props.deviationEnd) {
			addLineLegendItem('Deviation end', 'grey');
		}

		var getLastDataElmOfSeries = series => {
			return series.data ? series.data[series.data.length - 1] : null;
		};

		if (SensorUtils.isNumberOdd(this.occurrences.length)) {
			const seriesDataLast = getLastDataElmOfSeries(this.series[0]);
			if (seriesDataLast) {
				this.occurrences.push({
					tstamp: seriesDataLast.x,
				});
			}
		}

		if (SensorUtils.isNumberOdd(this.bypassedDataPoints.length)) {
			if (this.bypassedDataPoints[this.bypassedDataPoints.length - 1].isBypassed) {
				const seriesDataLast = getLastDataElmOfSeries(this.series[0]);
				if (seriesDataLast) {
					this.bypassedDataPoints.push({
						isBypassed: false,
						tstamp: seriesDataLast.x,
					});
				}
			}
		}

		if (SensorUtils.isNumberOdd(this.deferredDataPoints.length)) {
			if (this.deferredDataPoints[this.deferredDataPoints.length - 1].isDeferred) {
				const seriesDataLast = getLastDataElmOfSeries(this.series[0]);
				if (seriesDataLast) {
					this.deferredDataPoints.push({
						isDeferred: false,
						tstamp: seriesDataLast.x,
					});
				}
			}
		}

		this.setState({redrawChart: true});
	};

	drawAlarmLimits = () => {
		if (this.props.limitAlarms) {
			let that = this;
			for (let i = 0; i < this.props.limitAlarms.length; i++) {
				const alarm = this.props.limitAlarms[i];
				let details = alarm.details;

				if (!Array.isArray(details)) continue;
				for (let n = 0; n < details.length; n++) {
					that.drawAlarmBand(details[n], alarm.valid_from, alarm.valid_until);
				}
			}
		}
	};

	drawAlarmBand = (detail, valid_from, valid_until) => {
		if (detail.is_muted) {
			return;
		}

		if (this.props.measurements?.length) {
			if (new Date(valid_from).getTime() < new Date(this.props.measurements[0].tstamp).getTime()) {
				valid_from = new Date(this.props.measurements[0].tstamp);
			}
			if (new Date(valid_until).getTime() > new Date(this.props.measurements[this.props.measurements.length - 1].tstamp).getTime()) {
				valid_until = new Date(this.props.measurements[this.props.measurements.length - 1].tstamp);
			}
		}

		const givenUpperLimits = [];
		detail.upper_limit != null && givenUpperLimits.push(detail.upper_limit);
		detail.upper_limit_2 != null && givenUpperLimits.push(detail.upper_limit_2);
		detail.upper_limit_3 != null && givenUpperLimits.push(detail.upper_limit_3);
		detail.upper_limit_4 != null && givenUpperLimits.push(detail.upper_limit_4);
		detail.upper_limit != null && givenUpperLimits.push(Number.MAX_VALUE);

		const givenLowerLimits = [];
		detail.lower_limit != null && givenLowerLimits.push(detail.lower_limit);
		detail.lower_limit_2 != null && givenLowerLimits.push(detail.lower_limit_2);
		detail.lower_limit_3 != null && givenLowerLimits.push(detail.lower_limit_3);
		detail.lower_limit != null && givenLowerLimits.push(-Number.MAX_VALUE);

		for (var i = 0; i < givenUpperLimits.length - 1; i++) {
			this.alarmZones.push({
				from: givenUpperLimits[i + 1],
				to: givenUpperLimits[i],
				color: PLOT_LINE_COLORS[`H${i + 1}LIMIT`],
				start: new Date(valid_from),
				end: new Date(valid_until),
				id: `H${i + 1}LIMIT`,
			});
		}

		for (var i = 0; i < givenLowerLimits.length - 1; i++) {
			this.alarmZones.push({
				from: givenLowerLimits[i + 1],
				to: givenLowerLimits[i],
				color: PLOT_LINE_COLORS[`L${i + 1}LIMIT`],
				start: new Date(valid_from),
				end: new Date(valid_until),
				id: `L${i + 1}LIMIT`,
			});
		}
	};

	loading = () => {
		let chart = this.getChartObj();
		if (chart !== undefined) {
			chart.showLoading();
		}
	};

	stopLoading = () => {
		let chart = this.getChartObj();
		if (chart !== undefined) {
			chart.hideLoading();
		}
	};

	getChartObj = () => {
		return this.chart;
	};

	setPlotLinesVisible = (container, xAxis, plotLineColorAndId) => {
		for (let i = 0; i < container.length; i++) {
			let text = '';
			let m = moment(container[i].tstamp);
			let desc = '';
			if (container[i].desc) {
				if (Array.isArray(container[i].desc) && container[i].desc.length > 0) {
					desc = container[i].desc.join('<br >');
				} else {
					desc = container[i].desc;
				}
				text = PrepareDescText(moment.utc(container[i].tstamp).format('DD.MMM.YYYY HH:mm'), desc);

				if (container[i].desc.length === 1) {
					plotLineColorAndId.color = PickColorOfPlotLine(container[i].desc[0]);
				} else if (!Array.isArray(container[i].desc)) {
					plotLineColorAndId.color = PickColorOfPlotLine(container[i].desc);
				}
			}
			xAxis.addPlotLine({
				width: 2,
				value: m,
				...plotLineColorAndId,
				zIndex: 5,
				label: {
					text: '',
					verticalAlign: 'top',
					rotation: 0,
					align: 'center',
					useHTML: true,
					style: {
						color: 'black',
						fontWeight: 'bold',
						backgroundColor: 'rgba(232, 252, 251, .6)',
					},
				},
				events: {
					mouseenter: function (_) {
						this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') + 2);
						this.label.attr('text', text);
						return true;
					},
					mouseout: function (_) {
						this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') - 2);
						this.label.attr('text', '');
						return true;
					},
				},
			});
		}
		if (this.legendUpdateFun) this.legendUpdateFun();
	};

	setPlotBandsVisible = (container, xAxis, visible, additionalPlotBandProperties) => {
		if (container && visible) {
			for (let i = 0; i < container.length; i += 2) {
				xAxis.addPlotBand({
					from: container[i].tstamp,
					to: container[i + 1].tstamp,
					...additionalPlotBandProperties,
				});
			}
		} else {
			xAxis.removePlotBand(additionalPlotBandProperties.id);
		}
		if (this.legendUpdateFun) this.legendUpdateFun();
	};

	setOccurrencesVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.occurrences, chart.xAxis[0], visible, {
			color: 'rgba(0, 183, 255, 0.1)',
			id: 'plot-band-occurrences',
		});
	};

	setAllVisible = chart => {
		if (!chart?.options) {
			return;
		}

		try {
			this.all = [];

			if (this.state.startRunStopRunSettings.showStartRunStopRunValues) {
				this.all = [...this.startStopRuns, ...this.all];
			}

			if (this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues) {
				this.all = [...this.alarmingOnOff, ...this.all];
			}

			if (this.state.flightModeOnOffSettings.showFlightModeOnOffValues) {
				this.all = [...this.flightModeOnOff, ...this.all];
			}

			if (this.state.logStatusesSettings.showLogStatusesValues) {
				this.all = [...this.logStatuses, ...this.all];
			}

			if (this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues) {
				this.all = [...this.sensorLimitAlarms, ...this.all];
			}

			if (this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues) {
				this.all = [...this.sensorIssueAlarms, ...this.all];
			}

			if (this.state.menuSetMarkSettings.showMenuSetMarkValues) {
				this.all = [...this.MenuSetMark, ...this.all];
			}

			if (this.state.sensorReplacesSettings.showSensorReplacesValues) {
				this.all = [...this.sensorReplaces, ...this.all];
			}

			chart.showLoading();

			let groupedAlarmStatuses = GroupAlarmStatusesAccordingToDate(this.all);
			const sortedAlarms = groupedAlarmStatuses.sort((a, b) => a.tstamp - b.tstamp);
			const events = chart.series.find(s => s.userOptions.id === 'events');
			if (sortedAlarms?.length && events) {
				events.data = [];
				events.addPoint({
					x: sortedAlarms[0].tstamp,
					y: null,
				});

				events.addPoint({
					x: sortedAlarms[sortedAlarms.length - 1].tstamp,
					y: null,
				});

				events.visible = true;
			}
			let alarmStatusesWithMoreThanOneDesc = [];
			let alarmStatusesWithOneDesc = [];
			for (let i = 0; i < groupedAlarmStatuses.length; i++) {
				if (groupedAlarmStatuses[i].desc.length > 1) {
					alarmStatusesWithMoreThanOneDesc.push(groupedAlarmStatuses[i]);
				} else {
					alarmStatusesWithOneDesc.push(groupedAlarmStatuses[i]);
				}
			}

			chart.xAxis[0].removePlotLine('alarm-statuses-with-more-than-one-desc');
			chart.xAxis[0].removePlotLine('alarm-statuses-with-one-desc');
			chart.xAxis[0].removePlotLine('plot-line-all');

			this.setPlotLinesVisible(alarmStatusesWithMoreThanOneDesc, chart.xAxis[0], {
				color: '#02000d',
				id: 'alarm-statuses-with-more-than-one-desc',
			});

			this.setPlotLinesVisible(alarmStatusesWithOneDesc, chart.xAxis[0], {
				color: '#05fc2e',
				id: 'alarm-statuses-with-one-desc',
			});

			this.setSensorErrorValuesVisible(chart, this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues);

			chart.hideLoading();
		} catch (ex) {
			console.log(ex);
		}
	};

	setReloadedValuesVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.deferredDataPoints, chart.xAxis[0], visible, {
			color: PLOT_LINE_COLORS.RELOADED_VALUES,
			id: 'plot-band-deferred',
		});
	};

	setSensorCalibrationsVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.sensorCalibrations, chart.xAxis[0], visible, {
			color: PLOT_LINE_COLORS.CALIBRATION,
			id: 'plot-band-calibration',
		});
	};

	setBypassedValuesVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.bypassedDataPoints, chart.xAxis[0], visible, {
			color: PLOT_LINE_COLORS.ALARMING_INACTIVE,
			id: 'plot-band-bypassed',
		});
	};

	setSensorErrorValuesVisible = (chart, visible) => {
		Object.keys(this.sensorErrorValues).forEach(errorType => {
			if (visible && chart.series.findIndex(s => s.name.includes(errorType)) === -1) {
				this.addSensorErrorLegend(errorType, chart);
			}
		});

		for (const errorType in this.sensorErrorValues) {
			const error = this.sensorErrorValues[errorType];
			this.setPlotBandsVisible(error.tstamps, chart.xAxis[0], visible, {
				color: error.color,
				id: 'plot-band-' + errorType,
				events: {
					mousemove(e) {
						let x = e.offsetX + 4,
							y = e.offsetY + 8;

						if (!chart.annotationTooltip) {
							chart.annotationTooltip = chart.renderer
								.label(errorType, x, y, 'callout')
								.css({
									color: '#ffffff',
								})
								.attr({
									fill: 'rgba(0, 0, 0, 0.75)',
									zIndex: 10,
									padding: 5,
									r: 5,
								})
								.add();
						} else {
							chart.annotationTooltip.attr({
								x,
								y,
							});
						}
					},
					mouseout() {
						if (chart.annotationTooltip) {
							chart.annotationTooltip.destroy();
							chart.annotationTooltip = undefined;
						}
					},
				},
			});
		}
	};

	setMeasurementUploadsVisible = (cnt, chart, visible) => {
		cnt.communication_times.map(tstamp => {
			const m = moment(tstamp);
			const axis = chart.xAxis[0];
			if (visible) {
				axis.addPlotLine({
					color: PLOT_LINE_COLORS.COMMUNICATION_TIMES,
					width: 1.5,
					value: m,
					className: 'sensor-communication',
					id: m.valueOf(),
					label: {
						text: '',
						verticalAlign: 'top',
						align: 'center',
						rotation: 0,
					},
					events: {
						mouseenter: function (_) {
							this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') + 2);
							this.label.attr('text', moment.utc(m).format('DD.MMM.YYYY HH:mm'));
							return true; // <== returning false will cancel the default action
						},
						mouseout: function (_) {
							this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') - 2);
							this.label.attr('text', '');
							return true; // <== returning false will cancel the default action
						},
					},
				});
			} else {
				axis.removePlotLine(m.valueOf());
			}
		});

		if (this.legendUpdateFun) this.legendUpdateFun();
	};

	onShowInTransitClick = _ => {
		this.setState({showInTransit: !this.state.showInTransit}, () => this.setOccurrencesVisible(this.chart, this.state.showInTransit));
	};

	onShowReloadedValuesClick = _ => {
		this.setState({showReloadedValues: !this.state.showReloadedValues}, () =>
			this.setReloadedValuesVisible(this.chart, this.state.showReloadedValues)
		);
	};

	onShowMeasurementUploadsClick = _ => {
		this.setState({showMeasurementUploads: !this.state.showMeasurementUploads}, () =>
			this.setMeasurementUploadsVisible(this, this.chart, this.state.showMeasurementUploads)
		);
	};

	onShowSensorCalibrationsClick = _ => {
		this.setState({showSensorCalibrationValues: !this.state.showSensorCalibrationValues}, () =>
			this.setSensorCalibrationsVisible(this.chart, this.state.showSensorCalibrationValues)
		);
	};

	onShowStartRunStopRunClick = _ => {
		this.setState(
			{
				startRunStopRunSettings: {
					...this.state.startRunStopRunSettings,
					showStartRunStopRunValues: !this.state.startRunStopRunSettings.showStartRunStopRunValues,
					showStartRunLegend: this.state.startRunStopRunSettings.startRunStopRunLegends.some(
						item => item.legendDesc === RUN_EVENTS.START_RUN[1]
					)
						? !this.state.startRunStopRunSettings.showStartRunStopRunValues
						: this.state.startRunStopRunSettings.showStartRunLegend,
					showStopRunLegend: this.state.startRunStopRunSettings.startRunStopRunLegends.some(
						item => item.legendDesc === RUN_EVENTS.STOP_RUN[1]
					)
						? !this.state.startRunStopRunSettings.showStartRunStopRunValues
						: this.state.startRunStopRunSettings.showStopRunLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);

				const color = PLOT_LINE_COLORS.START_STOP_RUN;

				this.setLineLegendItemVisible(RUN_EVENTS.START_RUN[0], this.state.startRunStopRunSettings.showStartRunLegend, color);
				this.setLineLegendItemVisible(RUN_EVENTS.STOP_RUN[0], this.state.startRunStopRunSettings.showStopRunLegend, color);
			}
		);
	};

	onShowAlarmingOnAlarmingOffRunClick = _ => {
		this.setState(
			{
				alarmingOnAlarmingOffSettings: {
					...this.state.alarmingOnAlarmingOffSettings,
					showAlarmingOnAlarmingOffValues: !this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues,
					showAlarmingOnLegend: this.state.alarmingOnAlarmingOffSettings.alarmingOnAlarmingOffLegends.some(
						item => item.legendDesc === ALARM_EVENTS.ALARMING_ON[1]
					)
						? !this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues
						: this.state.alarmingOnAlarmingOffSettings.showAlarmingOnLegend,
					showAlarmingOffLegend: this.state.alarmingOnAlarmingOffSettings.alarmingOnAlarmingOffLegends.some(
						item => item.legendDesc === ALARM_EVENTS.ALARMING_OFF[1]
					)
						? !this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues
						: this.state.alarmingOnAlarmingOffSettings.showAlarmingOffLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);

				const color = PLOT_LINE_COLORS.ALARMING_ON_OFF;

				this.setLineLegendItemVisible(
					ALARM_EVENTS.ALARMING_ON[0],
					this.state.alarmingOnAlarmingOffSettings.showAlarmingOnLegend,
					color
				);
				this.setLineLegendItemVisible(
					ALARM_EVENTS.ALARMING_OFF[0],
					this.state.alarmingOnAlarmingOffSettings.showAlarmingOnLegend,
					color
				);
			}
		);
	};

	onShowFlightModeOnOffClick = _ => {
		this.setState(
			{
				flightModeOnOffSettings: {
					...this.state.flightModeOnOffSettings,
					showFlightModeOnOffValues: !this.state.flightModeOnOffSettings.showFlightModeOnOffValues,
					showFlightModeOnLegend: this.state.flightModeOnOffSettings.flightModeOnOffLegends.some(
						item => item.legendDesc === FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[1]
					)
						? !this.state.flightModeOnOffSettings.showFlightModeOnOffValues
						: this.state.flightModeOnOffSettings.showFlightModeOnLegend,
					showFlightModeOffLegend: this.state.flightModeOnOffSettings.flightModeOnOffLegends.some(
						item => item.legendDesc === FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[1]
					)
						? !this.state.flightModeOnOffSettings.showFlightModeOnOffValues
						: this.state.flightModeOnOffSettings.showFlightModeOffLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);

				const color = PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF;

				this.setLineLegendItemVisible(
					FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[0],
					this.state.flightModeOnOffSettings.showFlightModeOnLegend,
					color
				);
				this.setLineLegendItemVisible(
					FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[0],
					this.state.flightModeOnOffSettings.showFlightModeOnLegend,
					color
				);
			}
		);
	};

	onShowLogStatusesClick = _ => {
		this.setState(
			{
				logStatusesSettings: {
					...this.state.logStatusesSettings,
					showLogStatusesValues: !this.state.logStatusesSettings.showLogStatusesValues,
					showStartLegend: this.state.logStatusesSettings.logStatusLegends.some(
						item => item.legendDesc === LOGGER_STATUS_EVENTS.START[1]
					)
						? !this.state.logStatusesSettings.showLogStatusesValues
						: this.state.logStatusesSettings.showStartLegend,
					showLogDelayedLegend: this.state.logStatusesSettings.logStatusLegends.some(
						item => item.legendDesc === LOGGER_STATUS_EVENTS.LOG_DELAY[1]
					)
						? !this.state.logStatusesSettings.showLogStatusesValues
						: this.state.logStatusesSettings.showLogDelayedLegend,
					showLogTransitLegend: this.state.logStatusesSettings.logStatusLegends.some(
						item => item.legendDesc === LOGGER_STATUS_EVENTS.LOG_TRANSIT[1]
					)
						? !this.state.logStatusesSettings.showLogStatusesValues
						: this.state.logStatusesSettings.showLogTransitLegend,
					showLogPausedLegend: this.state.logStatusesSettings.logStatusLegends.some(
						item => item.legendDesc === LOGGER_STATUS_EVENTS.LOG_PAUSED[1]
					)
						? !this.state.logStatusesSettings.showLogStatusesValues
						: this.state.logStatusesSettings.showLogPausedLegend,
					showLogArrivedLegend: this.state.logStatusesSettings.logStatusLegends.some(
						item => item.legendDesc === LOGGER_STATUS_EVENTS.LOG_ARRIVED[1]
					)
						? !this.state.logStatusesSettings.showLogStatusesValues
						: this.state.logStatusesSettings.showLogArrivedLegend,
					showLogStoppedLegend: this.state.logStatusesSettings.logStatusLegends.some(
						item => item.legendDesc === LOGGER_STATUS_EVENTS.LOG_STOPPED[1]
					)
						? !this.state.logStatusesSettings.showLogStatusesValues
						: this.state.logStatusesSettings.showLogStoppedLegend,
					showCalibLegend: this.state.logStatusesSettings.logStatusLegends.some(
						item => item.legendDesc === LOGGER_STATUS_EVENTS.CALIB[1]
					)
						? !this.state.logStatusesSettings.showLogStatusesValues
						: this.state.logStatusesSettings.showCalibLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);

				const color = PLOT_LINE_COLORS.LOGGERS_STATUSES;

				this.setLineLegendItemVisible(LOGGER_STATUS_EVENTS.START[0], this.state.logStatusesSettings.showStartLegend, color);
				this.setLineLegendItemVisible(
					LOGGER_STATUS_EVENTS.LOG_DELAY[0],
					this.state.logStatusesSettings.showLogDelayedLegend,
					color
				);
				this.setLineLegendItemVisible(
					LOGGER_STATUS_EVENTS.LOG_TRANSIT[0],
					this.state.logStatusesSettings.showLogTransitLegend,
					color
				);
				this.setLineLegendItemVisible(
					LOGGER_STATUS_EVENTS.LOG_PAUSED[0],
					this.state.logStatusesSettings.showLogPausedLegend,
					color
				);
				this.setLineLegendItemVisible(
					LOGGER_STATUS_EVENTS.LOG_ARRIVED[0],
					this.state.logStatusesSettings.showLogArrivedLegend,
					color
				);
				this.setLineLegendItemVisible(
					LOGGER_STATUS_EVENTS.LOG_STOPPED[0],
					this.state.logStatusesSettings.showLogStoppedLegend,
					color
				);
				this.setLineLegendItemVisible(LOGGER_STATUS_EVENTS.CALIB[0], this.state.logStatusesSettings.showCalibLegend, color);
			}
		);
	};

	onShowSensorLimitAlarmsClick = _ => {
		this.setState(
			{
				sensorLimitAlarmsSettings: {
					...this.state.sensorLimitAlarmsSettings,
					showSensorLimitAlarmsValues: !this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues,
					showSensorUpperLimitAlarmLegend: this.state.sensorLimitAlarmsSettings.limitAlarmLegends.some(
						item => item.legendDesc === SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[1]
					)
						? !this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues
						: this.state.sensorLimitAlarmsSettings.showSensorUpperLimitAlarmLegend,
					showSensorLowerLimitAlarmLegend: this.state.sensorLimitAlarmsSettings.limitAlarmLegends.some(
						item => item.legendDesc === SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[1]
					)
						? !this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues
						: this.state.sensorLimitAlarmsSettings.showSensorLowerLimitAlarmLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);

				const color = PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS;
				this.setLineLegendItemVisible(
					SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[0],
					this.state.sensorLimitAlarmsSettings.showSensorUpperLimitAlarmLegend,
					color
				);
				this.setLineLegendItemVisible(
					SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[0],
					this.state.sensorLimitAlarmsSettings.showSensorLowerLimitAlarmLegend,
					color
				);
			}
		);
	};

	onShowSensorIssueAlarmsClick = _ => {
		this.setState(
			{
				sensorIssueAlarmsSettings: {
					...this.state.sensorIssueAlarmsSettings,
					showSensorIssueAlarmsValues: !this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues,

					showRadioConnectionWarningLegend: this.state.sensorIssueAlarmsSettings.issueLegends.some(
						item => item.legendDesc === SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[1]
					)
						? !this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues
						: this.state.sensorIssueAlarmsSettings.showRadioConnectionWarningLegend,

					showLostMeasurementAlarmLegend: this.state.sensorIssueAlarmsSettings.issueLegends.some(
						item => item.legendDesc === SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[1]
					)
						? !this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues
						: this.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend,

					showLowBatteryWarningLegend: this.state.sensorIssueAlarmsSettings.issueLegends.some(
						item => item.legendDesc === SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[1]
					)
						? !this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues
						: this.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend,

					showMissingCommunicationWarningLegend: this.state.sensorIssueAlarmsSettings.issueLegends.some(
						item => item.legendDesc === SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[1]
					)
						? !this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues
						: this.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend,

					showMissingValueAlarmLegend: this.state.sensorIssueAlarmsSettings.issueLegends.some(
						item => item.legendDesc === SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[1]
					)
						? !this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues
						: this.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend,

					showSensorFailureAlarmLegend: this.state.sensorIssueAlarmsSettings.issueLegends.some(
						item => item.legendDesc === SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[1]
					)
						? !this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues
						: this.state.sensorIssueAlarmsSettings.showSensorFailureAlarmLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);

				const color = PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS;
				this.setLineLegendItemVisible(
					SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[0],
					this.state.sensorIssueAlarmsSettings.showRadioConnectionWarningLegend,
					color
				);
				this.setLineLegendItemVisible(
					SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[0],
					this.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend,
					color
				);
				this.setLineLegendItemVisible(
					SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[0],
					this.state.sensorIssueAlarmsSettings.showLostMeasurementAlarmLegend,
					color
				);
				this.setLineLegendItemVisible(
					SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[0],
					this.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend,
					color
				);
				this.setLineLegendItemVisible(
					SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[0],
					this.state.sensorIssueAlarmsSettings.showMissingCommunicationWarningLegend,
					color
				);
				this.setLineLegendItemVisible(
					SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[0],
					this.state.sensorIssueAlarmsSettings.showSensorFailureAlarmLegend,
					color
				);
			}
		);
	};

	onShowMenuSetMarkClick = _ => {
		this.setState(
			{
				menuSetMarkSettings: {
					...this.state.menuSetMarkSettings,
					showMenuSetMarkValues: !this.state.menuSetMarkSettings.showMenuSetMarkValues,
					showMenuSetMarkLegend: this.state.menuSetMarkSettings.MenuSetMarkLegends.some(
						item => item.legendDesc === MENU_SET_MARK_EVENTS.MARK_COUNTER[1]
					)
						? !this.state.menuSetMarkSettings.showMenuSetMarkValues
						: this.state.menuSetMarkSettings.showMenuSetMarkLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);
				const color = PLOT_LINE_COLORS.MENU_SET_MARK;
				this.setLineLegendItemVisible(
					MENU_SET_MARK_EVENTS.MARK_COUNTER[0],
					this.state.menuSetMarkSettings.showMenuSetMarkValues,
					color
				);
			}
		);
	};

	onShowReplaceSensorClick = _ => {
		this.setState(
			{
				sensorReplacesSettings: {
					...this.state.sensorReplacesSettings,
					showSensorReplacesValues: !this.state.sensorReplacesSettings.showSensorReplacesValues,
					showSensorReplacesLegend: this.state.sensorReplacesSettings.sensorReplacesLegends.some(
						item => item.legendDesc === REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[1]
					)
						? !this.state.sensorReplacesSettings.showSensorReplacesValues
						: this.state.sensorReplacesSettings.showSensorReplacesLegend,
				},
			},
			() => {
				this.setAllVisible(this.chart);

				const color = PLOT_LINE_COLORS.REPLACE_SENSOR;
				this.setLineLegendItemVisible(
					REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[0],
					this.state.sensorReplacesSettings.showSensorReplacesValues,
					color
				);
			}
		);
	};

	updateAlarms = showBypassed => {
		if (this.chart.axes[0].displayBtn) {
			// if in zoom
			this.chart.series.forEach(s => {
				if (s.options.is_muted) {
					if (showBypassed) {
						s.update({color: '#FF0000'}, true);
					} else {
						s.update({color: '#B1B3B3'}, true);
					}
				}
			});
		}
	};

	onShowBypassedValuesClick = _ => {
		this.setState(
			{showBypassedValues: !this.state.showBypassedValues},
			() => this.setBypassedValuesVisible(this.chart, this.state.showBypassedValues),
			this.updateAlarms(this.state.showBypassedValues)
		);
	};

	addSeriesToChart = (chart, name, color) => {
		chart.addSeries({
			name: name,
			color: color,
			lineWidth: 14,
			marker: {
				enabled: false,
			},
			events: {
				legendItemClick: function () {
					return false;
				},
			},
		});
	};

	render() {
		const chartOptions = ChartConfiguration(this.props);
		chartOptions.series = this.series;

		//cnt ist the chart-component, or this
		const afterRender = (cnt, chart) => {
			if (cnt?.alarmZones?.length > 0) {
				for (const zone of cnt.alarmZones) {
					chart.yAxis[0].addPlotBand(zone);
					const zoneName = Trans({i18nKey: `chart.${zone.id}`})[0];
					if (!chart.series.find(s => s.name.includes(zoneName))) {
						this.addSeriesToChart(chart, zoneName, zone.color);
					}
				}
			}

			if (chart.series.length > 0) {
				if (!chart.xAxis[0].isDirty) {
					this.setReloadedValuesVisible(chart, cnt.state.showReloadedValues);
					this.setBypassedValuesVisible(chart, cnt.state.showBypassedValues);
					this.setOccurrencesVisible(chart, cnt.state.showInTransit);
					this.setSensorCalibrationsVisible(chart, cnt.state.showSensorCalibrationValues);

					if (
						this.state.startRunStopRunSettings.showStartRunStopRunValues ||
						this.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues ||
						this.state.flightModeOnOffSettings.showFlightModeOnOffValues ||
						this.state.logStatusesSettings.showLogStatusesValues ||
						this.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues ||
						this.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues ||
						this.state.menuSetMarkSettings.showMenuSetMarkValues ||
						this.state.sensorReplacesSettings.showSensorReplacesValues
					) {
						this.setAllVisible(chart);
					}

					if (cnt.deferredDataPoints && cnt.deferredDataPoints.length > 0 && cnt.state.showReloadedValues) {
						this.addSeriesToChart(chart, 'Reloaded Values', PLOT_LINE_COLORS.RELOADED_VALUES);
					}

					if (cnt.sensorCalibrations && cnt.sensorCalibrations.length > 0 && cnt.state.showSensorCalibrationValues) {
						this.addSeriesToChart(chart, 'Calibration', PLOT_LINE_COLORS.CALIBRATION);
					}

					if (cnt.communication_times && cnt.communication_times.length > 0 && cnt.state.showMeasurementUploads) {
						this.addLineLegendItem('Communication times', PLOT_LINE_COLORS.COMMUNICATION_TIMES, chart);
					}

					if (cnt.state.startRunStopRunSettings.showStartRunLegend) {
						this.addLineLegendItem(RUN_EVENTS.START_RUN[0], PLOT_LINE_COLORS.START_STOP_RUN, chart);
					}
					if (cnt.state.startRunStopRunSettings.showStopRunLegend) {
						this.addLineLegendItem(RUN_EVENTS.STOP_RUN[0], PLOT_LINE_COLORS.START_STOP_RUN, chart);
					}

					if (cnt.state.alarmingOnAlarmingOffSettings.showAlarmingOnLegend) {
						this.addLineLegendItem(ALARM_EVENTS.ALARMING_ON[0], PLOT_LINE_COLORS.ALARMING_ON_OFF, chart);
					}
					if (cnt.state.alarmingOnAlarmingOffSettings.showAlarmingOffLegend) {
						this.addLineLegendItem(ALARM_EVENTS.ALARMING_OFF[0], PLOT_LINE_COLORS.ALARMING_ON_OFF, chart);
					}

					if (cnt.state.flightModeOnOffSettings.showFlightModeOnLegend) {
						this.addLineLegendItem(FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[0], PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF, chart);
					}
					if (cnt.state.flightModeOnOffSettings.showFlightModeOffLegend) {
						this.addLineLegendItem(FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[0], PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF, chart);
					}

					if (cnt.state.logStatusesSettings.showStartLegend) {
						this.addLineLegendItem(LOGGER_STATUS_EVENTS.START[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
					}
					if (cnt.state.logStatusesSettings.showLogDelayedLegend) {
						this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_DELAY[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
					}
					if (cnt.state.logStatusesSettings.showLogTransitLegend) {
						this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_TRANSIT[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
					}
					if (cnt.state.logStatusesSettings.showLogPausedLegend) {
						this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_PAUSED[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
					}
					if (cnt.state.logStatusesSettings.showLogArrivedLegend) {
						this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_ARRIVED[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
					}
					if (cnt.state.logStatusesSettings.showLogStoppedLegend) {
						this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_STOPPED[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
					}
					if (cnt.state.logStatusesSettings.showCalibLegend) {
						this.addLineLegendItem(LOGGER_STATUS_EVENTS.CALIB[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
					}

					if (cnt.state.sensorLimitAlarmsSettings.showSensorUpperLimitAlarmLegend) {
						this.addLineLegendItem(SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[0], PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS, chart);
					}
					if (cnt.state.sensorLimitAlarmsSettings.showSensorLowerLimitAlarmLegend) {
						this.addLineLegendItem(SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[0], PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS, chart);
					}

					if (cnt.state.sensorIssueAlarmsSettings.showRadioConnectionWarningLegend) {
						this.addLineLegendItem(
							SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[0],
							PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
							chart
						);
					}
					if (cnt.state.sensorIssueAlarmsSettings.showLostMeasurementAlarmLegend) {
						this.addLineLegendItem(SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
					}
					if (cnt.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend) {
						this.addLineLegendItem(SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
					}
					if (cnt.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend) {
						this.addLineLegendItem(SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
					}
					if (cnt.state.sensorIssueAlarmsSettings.showMissingCommunicationWarningLegend) {
						this.addLineLegendItem(
							SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[0],
							PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
							chart
						);
					}
					if (cnt.state.sensorIssueAlarmsSettings.showSensorFailureAlarmLegend) {
						this.addLineLegendItem(SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
					}

					if (cnt.state.menuSetMarkSettings.showMenuSetMarkLegend) {
						this.addLineLegendItem(MENU_SET_MARK_EVENTS.MARK_COUNTER[0], PLOT_LINE_COLORS.MENU_SET_MARK, chart);
					}

					if (cnt.state.sensorReplacesSettings.showSensorReplacesLegend) {
						this.addLineLegendItem(REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[0], PLOT_LINE_COLORS.REPLACE_SENSOR, chart);
					}

					if (cnt.bypassedDataPoints && cnt.bypassedDataPoints.length > 0 && cnt.state.showBypassedValues) {
						this.addSeriesToChart(chart, 'Alarming inactive', PLOT_LINE_COLORS.ALARMING_INACTIVE);
					}

					if (cnt.occurrences && cnt.occurrences.length > 0 && cnt.state.showInTransit) {
						this.addLineLegendItem('In Transit', '#ebeeff', chart);
					}

					//deviation
					chart.xAxis[0].addPlotLine({
						color: '#f61214',
						width: 4,
						value: moment(cnt.props.deviationStart),
						className: 'deviation-start',
					});

					chart.xAxis[0].addPlotLine({
						color: '#b1b3b3',
						width: 4,
						value: moment(cnt.props.deviationEnd),
						className: 'deviation-end',
					});
					//

					if (cnt.state.showMeasurementUploads && cnt.communication_times) {
						this.setMeasurementUploadsVisible(cnt, chart, true);
					}
				}

				cnt.chart = chart;

				cnt.stopLoading(chart);
			}
		};

		return (
			<>
				{this.props.measurements && this.props.measurements.length > 0 ? (
					<ChartLegend
						chartComponent={this}
						setUpdateFun={updateFun => {
							this.legendUpdateFun = updateFun;
						}}
					/>
				) : null}

				<div style={{marginBottom: '50px'}}>
					{this.props.filterActive && (
						<Button style={{float: 'right', marginRight: '20px'}} onClick={this.props.resetZoom}>
							<Trans i18nKey="global.resetZoom" />
						</Button>
					)}
				</div>
				<ReactHighcharts config={chartOptions} callback={chart => afterRender(this, chart)} isPureConfig={true} />
			</>
		);
	}
}

export default ChartComponent;
