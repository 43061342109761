import {ColumnApi, GetRowIdFunc, GetRowIdParams, GridApi} from 'ag-grid-enterprise';
import {Sensor} from '../Common/Types/Sensor';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {SensorServiceHelper} from '../../common/helpers';
import {AgGridWrapper} from '../Shared/Components';
import {AgGridReact} from 'ag-grid-react';
import {GridSideBar} from './SideBarDefinitions';
import pubsub from 'pubsub-js';
import {
	CalibrationStatusChanged,
	DEVICE_EVENT,
	RunStatusChanged,
	SensorAlarmingEnabledChangedData,
	UI_EVENT,
} from '../Shared/Constants/Events';
import {MeasurementNew} from '../Common/Types/WebSocket/MeasurementNew';
import {DefaultCellFormatter} from './ValueFormatters';
import {ColumnDefinitions} from './ColumnDefinitions';
import {SettingsSaveComponent} from './SettingsSaveComponent';
import {DefaultValueGetter} from './ValueGetters';
import {
	onNewMeasurement,
	onRunStatusChanged,
	onSensorAlarmingEnabledChanged,
	onSensorStatusChanged,
	onUpdateDashboardLiberoG,
	onCalibrationStatusChanged,
} from './EventHandler';
import {CellDoubleClickedEvent} from 'ag-grid-community';
import {SensorActions} from '../../common/helpers/SensorActions';
import {useHistory} from 'react-router-dom';
import Shared from '../Shared/Shared';
import {MultiContext} from '../Infrastructure/Authorization/Context/MultiContext';
import {Spin} from 'antd';
import {GetDataGridSetting} from '../../common/services/WebserverServicePro/UserSettingsService';

export interface GridApiRef {
	api: GridApi<Sensor>;
	columnApi: ColumnApi;
}

export function SensorDataGrid() {
	const gridRef = useRef();
	const history = useHistory();

	const accessContext = useContext(MultiContext);
	const [sensors, setSensors] = useState<Sensor[]>([]);
	const [subscriptions, setSubscriptions] = useState<PubSubJS.Token[]>([]);

	useEffect(() => {
		Shared.saveDevicesViewSetting(accessContext, '/datagrid');
		getSensors();
		return () => subscriptions.forEach(token => pubsub.unsubscribe(token));
	}, []);

	function getSensors() {
		SensorServiceHelper.GetSensorsWithData().then(s => {
			setSensors(s);
			GetDataGridSetting()
				.then(d => {
					const {api, columnApi} = getGridApi();
					if (!api || !columnApi) {
						return;
					}

					api.setFilterModel(d.filterSettings);
					columnApi.applyColumnState({state: d.columnSettings, applyOrder: true});
				})
				.catch(() => {});
		});
	}

	function getGridApi(): GridApiRef {
		if (!gridRef?.current) {
			return {api: undefined, columnApi: undefined};
		}
		const {api, columnApi} = gridRef.current as GridApiRef;
		if (api == null || columnApi == null) {
			return {api: undefined, columnApi: undefined};
		}
		return {api: api, columnApi: columnApi};
	}

	const getRowId = useMemo((): GetRowIdFunc<Sensor> => (params: GetRowIdParams<Sensor>) => params.data.Id.toString(), []);

	function onGridReady() {
		const {api} = getGridApi();
		!sensors?.length && api.showLoadingOverlay();

		const subscriptions_: PubSubJS.Token[] = [];
		subscriptions_.push(
			pubsub.subscribe(DEVICE_EVENT.MEASUREMENT_NEW, (_msg: string, data: MeasurementNew) => onNewMeasurement(getGridApi(), data))
		);
		subscriptions_.push(
			pubsub.subscribe(UI_EVENT.SENSOR_ALARMING_ENABLED_CHANGED, (_msg: string, data: SensorAlarmingEnabledChangedData) =>
				onSensorAlarmingEnabledChanged(getGridApi(), data)
			)
		);
		subscriptions_.push(
			pubsub.subscribe(DEVICE_EVENT.RUN_STATUS_CHANGED, (_msg: string, data: RunStatusChanged) =>
				onRunStatusChanged(getGridApi(), data)
			)
		);
		subscriptions_.push(
			pubsub.subscribe(DEVICE_EVENT.UPDATE_DASHBOARD_LIBERO_G, (_msg: string, data: {sensor_id: number}) =>
				onUpdateDashboardLiberoG(getGridApi(), data)
			)
		);
		subscriptions_.push(
			pubsub.subscribe(UI_EVENT.CALIBRATION_STATUS_CHANGED, (_msg: string, data: CalibrationStatusChanged) =>
				onCalibrationStatusChanged(getGridApi(), data)
			)
		);
		subscriptions_.push(
			pubsub.subscribe(DEVICE_EVENT.SENSOR_STATUS_CHANGED, (_msg: string, data) => onSensorStatusChanged(getGridApi(), data))
		);
		subscriptions_.push(pubsub.subscribe(DEVICE_EVENT.SENSOR_NEW, getSensors));

		setSubscriptions(subscriptions_);
	}

	function onCellDoubleClicked(event: CellDoubleClickedEvent<Sensor>) {
		const sensor = event?.data;
		if (sensor && event.colDef?.colId !== 'Menu') {
			SensorActions.Analyse(sensor, history);
		}
	}

	return (
		<>
			<AgGridWrapper>
				<AgGridReact
					animateRows={true}
					columnDefs={ColumnDefinitions}
					columnHoverHighlight={true}
					defaultColDef={{
						valueGetter: DefaultValueGetter,
						valueFormatter: DefaultCellFormatter,
						sortable: true,
						resizable: true,
						enableRowGroup: true,
						filter: 'agSetColumnFilter',
					}}
					enableCellChangeFlash={true}
					getRowId={getRowId}
					groupDisplayType={'multipleColumns'}
					groupHideOpenParents={false}
					loadingOverlayComponent={Spin}
					onCellDoubleClicked={onCellDoubleClicked}
					onGridReady={onGridReady}
					ref={gridRef}
					rowClassRules={{blurred: params => params.data?.IsBlurred}}
					rowData={sensors}
					rowGroupPanelShow={'always'}
					rowSelection={'multiple'}
					showOpenedGroup={true}
					sideBar={GridSideBar}
					statusBar={{
						statusPanels: [
							{statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
							{statusPanel: 'agFilteredRowCountComponent', align: 'left'},
							{statusPanel: 'agSelectedRowCountComponent', align: 'left'},
							{statusPanel: SettingsSaveComponent, align: 'right'},
						],
					}}
				/>
			</AgGridWrapper>
		</>
	);
}
