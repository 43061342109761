import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import ErrorDisplay from "./../ErrorDisplay";

const CheckboxControl = React.forwardRef(({
    id,
    name,
    label,
    alias,
    value,
    disabled,
    errors,
    showErrors,
    handleChange,
    handleBlur,
    dataToValidate
}, ref) => {

    return (
        <>
            <Label className="checkbox c-checkbox">
                <Input
                    innerRef={ref}
                    type="checkbox"
                    id={id}
                    name={name}
                    placeholder={alias}
                    data-validate={dataToValidate}
                    invalid={showErrors && !disabled && errors.length > 0}
                    checked={value}
                    disabled={disabled}
                    onChange={e => {
                        handleChange(e);
                        handleBlur()
                    }}
                />
                <span className="fa fa-check" /> {label}
            </Label>
            {showErrors && !disabled && errors.map((error, i) => (
                <ErrorDisplay key={i} error={error} />
            ))}
        </>
    );
});

CheckboxControl.displayName = 'CheckboxControl';

CheckboxControl.propTypes = {
    errors: PropTypes.array,
    handleChange: PropTypes.func
}

CheckboxControl.defaultProps = {
    errors: [],
    handleChange: () => { }
};
export default CheckboxControl;