import {copyIssueProfile} from '../../IssueProfile/Commands/IssueProfileCommands';

export const executeIssueProfileCopyAction = (parent, issueProfile) => {
	if (parent == undefined || parent == null || issueProfile == undefined || issueProfile == null) {
		throw new TypeError('Please pass required objects!!! (parent, issueProfile)');
	}
	const preparedIssueProfile = prepareIssueProfileForCopyAction(issueProfile);
	copyIssueProfile(preparedIssueProfile, parent);
};

const prepareIssueProfileForCopyAction = issueProfile => {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	var issueProfileObj = {};

	issueProfileObj.id = 0;
	issueProfileObj.org_id = issueProfile.org_id;
	issueProfileObj.missing_value_alarm = issueProfile.missing_value_alarm; //Not used anymore
	issueProfileObj.missing_value_alarm_delay = issueProfile.missing_value_alarm_delay; //Not used anymore
	issueProfileObj.sensor_failure_alarm = issueProfile.sensor_failure_alarm;
	issueProfileObj.sensor_failure_alarm_delay = issueProfile.sensor_failure_alarm_delay;
	issueProfileObj.radio_connection_warning = issueProfile.radio_connection_warning; // Not used for anymore. https://elproag.atlassian.net/browse/TH-460
	issueProfileObj.radio_connection_warning_delay = issueProfile.radio_connection_warning_delay; //Not used for anymore. https://elproag.atlassian.net/browse/TH-460
	issueProfileObj.radio_connection_warning_threshold = issueProfile.radio_connection_warning_threshold; // Not used for anymore. https://elproag.atlassian.net/browse/TH-460
	issueProfileObj.low_battery_warning = issueProfile.low_battery_warning;
	issueProfileObj.elpro_cloud_default = issueProfile.elpro_cloud_default;
	issueProfileObj.name = issueProfile.name + '_copy';
	issueProfileObj.lost_measurement_alarm = issueProfile.lost_measurement_alarm;
	issueProfileObj.missing_communication_warning = issueProfile.missing_communication_warning;
	issueProfileObj.missing_communication_warning_enum = issueProfile.missing_communication_warning_enum;
	issueProfileObj.light_warning = issueProfile.light_warning;
	issueProfileObj.tilt_warning = issueProfile.tilt_warning;
	issueProfileObj.locked = false;
	issueProfileObj.sensors = null;

	return issueProfileObj;
};

if (process.env['BABEL_ENV'] === 'test' || process.env['NODE_ENV'] === 'test') {
	//Functions are only visible for testing purpose.
	module.exports.prepareIssueProfileForCopyAction = prepareIssueProfileForCopyAction;
}
