import axios from 'axios';
import Message from '../../Shared/Components/Message';
import pubsub from "pubsub-js";
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

const client = axios.create({baseURL: 'http://localhost:80/srv/v1/'});
//const client = axios.create({baseURL: 'https://api.elcl.xyz/srv/v1/'});
const authToken = Authentication.getToken() || null;

export const asyncRequest = (options) => {
    options.headers = {
        Authorization: `Bearer ${authToken}`
    };
    return client(options);
}

export const asyncRequestState = {
    success: (title, msg) => {
        Message.success(title, msg);
    },
    error: (error) => {
        if (error.response) {
            // the request was made and the server responded with
            // status code that falls out of the range of: 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);

            if (error.response.status === 401) {
                Authentication.logout_tokens();
                pubsub.publish('sessionExpired');
            }

        } else if (error.request) {
            // the request was made but no response was received, `error.request`
            // is an instance of XMLHttpRequest in the browser
            console.log('Request error', error.request);
        } else {
            // something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        console.log("-> Fail!", error);
    }
}