import React, {useContext, useState} from 'react';
import {Button} from 'reactstrap';
import LoadingTie from './LoadingTie';
import {authUtils} from '../../Infrastructure/Authorization/Utils';
import {accessPermissions} from '../../Infrastructure/Authorization/Access';
import {AccessContext} from '../../Infrastructure/Authorization/Context/AccessContext';
import {Event} from '../../../common/types';

const DeviationAcknowledgedTie = (props: {show: boolean; acknowledgeEvent: Event; acknowledgeCallback: (e) => void}) => {
	const userAccess = useContext(AccessContext);
	const [hasAcknowledgeAccess] = useState(() => {
		const acknowledge = authUtils.findAccessElement(
			accessPermissions.deviations.child.deviationsDetail.child.deviationAcknowledge.path,
			userAccess.user.access_settings.access
		);
		return acknowledge.allow;
	});

	return (
		<div className="card card-default" style={{height: '100%'}} id="deviationAcknowledge">
			<div className="card-body text-center">
				{props.show ? (
					<>
						<h4>Acknowledged</h4>
						<br />
						<div>
							<div>
								{props.acknowledgeEvent ? (
									<div>
										<h5>
											{props.acknowledgeEvent.DateOccurred.toString()}
											<br />
											<span style={{lineHeight: '30px'}}>{props.acknowledgeEvent.GetEventAcknowledgedBy()}</span>
										</h5>
									</div>
								) : (
									<div>
										{hasAcknowledgeAccess ? (
											<Button color="primary" onClick={props.acknowledgeCallback}>
												Acknowledge
											</Button>
										) : (
											<h5>Not Acknowledged</h5>
										)}
									</div>
								)}
							</div>
						</div>
					</>
				) : (
					<LoadingTie />
				)}
			</div>
		</div>
	);
};

export default DeviationAcknowledgedTie;
