import dayjs from 'dayjs';
import {RunInfoResult} from './DbModel/RunInfoResult';

export class RunInfo {
	constructor(init: RunInfoResult) {
		this.SensorId = init.sensors_id;
		this.StartDate = dayjs(init.ts_start);
		this.EndDate = dayjs(init.ts_end);
	}

	public SensorId: number;
	public StartDate: dayjs.Dayjs;
	public EndDate: dayjs.Dayjs;
}
