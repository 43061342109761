import request from '../Infrastructure/Requests/Request';
import {asyncServerRequest} from '../Infrastructure/Requests/ServerRequest';

function register(payload, eventData) {
    return request({
        url: '/rpc/registration',
        method: 'POST',
        data: payload,
        eventData,
    });
}

function resend(payload, eventData) {
    return request({
        url: '/rpc/resend',
        method: 'POST',
        data: payload,
        eventData,
    });
}

const getOrganizationSettingTemplateDefault = async payload => {
    const payloadData = {
        url: 'settings/organization-configuration/config-template/' + payload,
        method: 'GET',
    };
    try {
        return await asyncServerRequest(payloadData);
    } catch (error) {
        console.error(error);
    }
};

const RegisterService = {
    register,
    resend,
    getOrganizationSettingTemplateDefault,
};

export default RegisterService;
