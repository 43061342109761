import React from 'react';
import styles from './styles.module.scss';
import {Row} from 'antd';

export function AgGridWrapper(props: {children: React.JSX.Element}): React.JSX.Element {
	const theme = 'ag-theme-alpine';
	return (
		<Row className={styles.container}>
			<div className={`${styles.grid} ${theme}`}>{props.children}</div>
		</Row>
	);
}
