import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type SensorBasicsMemoProps = {
	show: boolean;
	memo: string;
};

export default function SensorBasicsMemoComponent(props: SensorBasicsMemoProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.memo'} />
				</td>
				<td>{props.memo.length > 0 ? props.memo : CONSTANTS.NOT_AVAILABLE}</td>
			</tr>
		)
	);
}
