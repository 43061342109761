import React, {Component} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import CalibrationService from '../Services/Calibrations/CalibrationService';
import {CalibrationsGridProps, CalibrationsGridState as CalibrationsGridState} from './Components/Grid/CalibrationsGridState';
import {DEVICE_EVENT} from '../Shared/Constants/Events';
import pubsub from 'pubsub-js';
import Message from '../Shared/Components/Message';

//Ag Grid
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../styles/app/datagrid/styles.css';
import {ColumnDefinitions} from './Components/Grid/ColumnDefinitions';
import ContentWrapper from '../Layout/ContentWrapper';
import {GridOptions} from './Components/Grid/CalibrationGridOptions';
import {GridApi, GridReadyEvent, RowDoubleClickedEvent, RowNode} from 'ag-grid-community';
import {Calibration} from './Model/Calibration';
import {MeasurementNew} from '../Common/Types/WebSocket/MeasurementNew';
import {CalibrationMeasurement} from './Model/CalibrationMeasurement';
import {AgGridWrapper} from '../Shared/Components';
import {ViewWrapper} from '../Layout';
import {ViewHeader} from '../Common';
import {Spin} from 'antd';

class CalibrationsGrid extends Component<CalibrationsGridProps, CalibrationsGridState> {
	private subscriptions: PubSubJS.Token[] = [];
	private calibrationService: CalibrationService;

	private gridApi: GridApi;

	constructor(props: CalibrationsGridProps | Readonly<CalibrationsGridProps>) {
		super(props);
		this.calibrationService = new CalibrationService();

		this.state = {
			calibrations: [],
			columnDefs: ColumnDefinitions,
			agGridOptions: GridOptions,
		};

		this.handleNewMeasurement = this.handleNewMeasurement.bind(this);
		this.handleUpdateLiberoG = this.handleUpdateLiberoG.bind(this);
	}

	async getCalibrations() {
		try {
			this.gridApi.showLoadingOverlay();
			const calibrations = await this.calibrationService.getCalibrations();
			this.setState({
				calibrations: calibrations,
			});
		} catch (error) {
			console.error(error.message);
		}
	}

	componentDidMount() {
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.MEASUREMENT_NEW, this.handleNewMeasurement));
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.UPDATE_DASHBOARD_LIBERO_G, this.handleUpdateLiberoG));
	}

	componentWillUnmount() {
		this.subscriptions.forEach(token => {
			pubsub.unsubscribe(token);
		});
	}

	async onGridReady(params: GridReadyEvent<Calibration>) {
		this.gridApi = params.api;
		await this.getCalibrations();
	}

	async onRowDoubleClicked(row: RowDoubleClickedEvent<Calibration>) {
		if (!row.data.LastMeasurement) {
			Message.warning(<Trans i18nKey={'calibrations.noDataTitle'} />, <Trans i18nKey={'calibrations.noDataMsg'} />, {
				autoClose: 20000,
			});
			return;
		}

		this.props.history.push(`calibrations/${row.data.CalibrationID}`, {calibration: row.data});
	}

	isRowSelectable(row: RowNode<Calibration>): boolean {
		return !!row.data && row.data.State === 'Stopped';
	}

	async handleNewMeasurement(message: any, newMeasurement: MeasurementNew) {
		const calibration = this?.state?.calibrations?.find(
			calibration => calibration.Sensor.ID === newMeasurement.SensorId && !calibration.Stop
		);
		if (!calibration || isNaN(newMeasurement.Value)) {
			return;
		}

		if (!newMeasurement.Calibration || !calibration.Start) {
			const calib = await this.calibrationService.getCalibration(calibration.CalibrationID);
			calibration.State = calib.State;
			calibration.Start = calib.Start;
			calibration.Stop = calib.Stop;
			calibration.LastMeasurement = calib.LastMeasurement;
		} else {
			calibration.LastMeasurement = new CalibrationMeasurement(
				newMeasurement.Timestamp,
				newMeasurement.Value,
				calibration.Sensor.UnitID,
				newMeasurement.Status
			);
		}

		this.gridApi.refreshCells();
		const affectedRow = this.gridApi.getRowNode(calibration.CalibrationID.toString());
		affectedRow.setRowSelectable(this.isRowSelectable(affectedRow));
	}

	async handleUpdateLiberoG(_: string, _data) {
		if (!_data.sensor_id) {
			return;
		}

		const calibration = this?.state?.calibrations?.find(calibration => calibration.Sensor.ID === _data.sensor_id && !calibration.Stop);
		if (!calibration) {
			return;
		}

		const calib = await this.calibrationService.getCalibration(calibration.CalibrationID);
		calibration.State = calib.State;
		calibration.Start = calib.Start;
		calibration.Stop = calib.Stop;

		this.gridApi.refreshCells();
		const affectedRow = this.gridApi.getRowNode(calibration.CalibrationID.toString());
		affectedRow.setRowSelectable(this.isRowSelectable(affectedRow));
	}

	render() {
		return (
			<ViewWrapper>
				<ViewHeader heading="calibrations.grid"></ViewHeader>
				<ContentWrapper>
					<AgGridWrapper>
						<div style={this.state.agGridOptions.containerStyle}>
							<div style={this.state.agGridOptions.gridStyle} className={this.state.agGridOptions.theme}>
								<AgGridReact
									getRowId={row => row.data.CalibrationID.toString()}
									statusBar={this.state.agGridOptions.statusBar}
									rowGroupPanelShow={this.state.agGridOptions.rowGroupPanelShow}
									rowSelection={this.state.agGridOptions.rowSelection}
									suppressRowClickSelection={this.state.agGridOptions.suppressRowClickSelection}
									enableCellChangeFlash={this.state.agGridOptions.enableCellChangeFlash}
									defaultColDef={this.state.agGridOptions.columnSettings}
									enableRangeSelection={this.state.agGridOptions.enableRangeSelection}
									rowData={this.state.calibrations}
									columnDefs={this.state.columnDefs}
									animateRows={this.state.agGridOptions.animateRows}
									onGridReady={params => this.onGridReady(params)}
									onRowDoubleClicked={row => this.onRowDoubleClicked(row)}
									isRowSelectable={row => this.isRowSelectable(row)}
									columnHoverHighlight={this.state.agGridOptions.columnHoverHighlight}
									showOpenedGroup={this.state.agGridOptions.showOpenedGroup}
									groupDisplayType={this.state.agGridOptions.groupDisplayType}
									groupHideOpenParents={this.state.agGridOptions.groupHideOpenParents}
									loadingOverlayComponent={Spin}
								/>
							</div>
						</div>
					</AgGridWrapper>
				</ContentWrapper>
			</ViewWrapper>
		);
	}
}

export default withTranslation()(CalibrationsGrid);
