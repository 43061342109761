import React, {useEffect, useState} from 'react';
import Modal from '../../../Shared/Components/Modal';
import {Trans, useTranslation} from 'react-i18next';
import {Alert} from 'antd';

const ReplaceSensorConditionsModal = ({isVisible, serial_number, onConditionsAccepted, onConditionsDeclined}) => {
	const [t] = useTranslation();

	const [visible, setVisible] = useState(isVisible);

	// Visibility
	useEffect(() => {
		setVisible(isVisible);
	}, [isVisible]);

	return (
		<Modal
			title={`${t('sensor.replaceSensor')} ${serial_number}`}
			visible={visible}
			confirmLoading={false}
			handleCancel={onConditionsDeclined}
			afterClose={() => {}}
			handleOk={onConditionsAccepted}
		>
			<div>
				<Alert
					message={<Trans i18nKey="replaceSensor.replaceConditionsTitle" />}
					description={<Trans i18nKey="replaceSensor.replaceConditionsDescription" values={{serial_number: serial_number}} />}
					type="error"
				/>
			</div>
		</Modal>
	);
};

export default ReplaceSensorConditionsModal;
