import React, {Component} from 'react';
import {Row, Col, Input, FormGroup, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import FormValidator from '../../../Forms/FormValidator';
import {FormNavigation} from '../../../Wizard/Elements';

interface MetadataFormProps {
	onBack: any;
	onNext: any;
	handleMetadatasInputChange: any;
	hasConfigurationTemplate: boolean;
	metadatasForm: {
		sensor_name: string;
		customer_id: string;
		memo: string;
	};
}

interface MetadataFromState {
	loading: boolean;
	errors: any;
}

class MetadatasForm extends Component<MetadataFormProps, MetadataFromState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errors: undefined,
		};
	}

	hasError = (inputName, method) => {
		return this.state && this.state.errors && this.state.errors[inputName] && this.state.errors[inputName][method];
	};

	back = () => {
		this.props.onBack();
	};

	onSubmit = e => {
		e.preventDefault();

		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);

		this.setState({
			loading: true,
		});

		if (!hasError) {
			this.props.onNext();
		} else {
			this.setState({loading: false, errors: errors});
		}
	};

	render() {
		return (
			<Col>
				<form name="formMetadatas" onSubmit={this.onSubmit}>
					<FormNavigation loading={this.state.loading} nextDisabled={!this.props.hasConfigurationTemplate} />
					{this.props.hasConfigurationTemplate ? (
						<>
							<Row>
								<Col xl={3}>
									<FormGroup>
										<Label for="sensor_name">Sensor name</Label>
										<Input
											type="text"
											id="sensor_name"
											name="sensor_name"
											placeholder="Enter the sensor name"
											invalid={this.hasError('sensor_name', 'required') || this.hasError('sensor_name', 'maxchar')}
											data-validate='["required","maxchar"]'
											data-param="50"
											maxLength="51"
											value={this.props.metadatasForm.sensor_name}
											onChange={this.props.handleMetadatasInputChange}
										/>
										{this.hasError('sensor_name', 'required') && (
											<span className="invalid-feedback">Field is required</span>
										)}
										{this.hasError('sensor_name', 'maxchar') && (
											<span id="sensorNameMax" className="invalid-feedback">
												Field must be be less than 50 characters
											</span>
										)}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xl={3}>
									<FormGroup>
										<Label for="inputCustomerId">Customer's ID (optional)</Label>
										<Input
											type="text"
											id="inputCustomerId"
											name="customer_id"
											value={this.props.metadatasForm.customer_id}
											onChange={this.props.handleMetadatasInputChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xl={3}>
									<FormGroup>
										<Label for="memo">Memo (optional)</Label>
										<Input
											type="textarea"
											name="memo"
											className="form-control"
											id="memo"
											rows="5"
											value={this.props.metadatasForm.memo}
											onChange={this.props.handleMetadatasInputChange}
											invalid={this.hasError('memo', 'maxchar')}
											data-validate='["maxchar"]'
											data-param="512"
											maxLength="513"
										/>
										{this.hasError('memo', 'maxchar') && (
											<span id="memoNameMax" className="invalid-feedback">
												Field must be be less than 512 characters
											</span>
										)}
									</FormGroup>
								</Col>
							</Row>
						</>
					) : null}
					<FormNavigation onBack={this.back} loading={this.state.loading} nextDisabled={!this.props.hasConfigurationTemplate} />
				</form>
			</Col>
		);
	}
}

export default MetadatasForm;
