import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Vector as OlSourceVector, Cluster } from "ol/source";
import { fromLonLat } from "ol/proj";
import { Style as OlStyle, Icon, Text, Fill} from "ol/style";
import AnimatedCluster from "ol-ext/layer/AnimatedCluster";

export const InitGeoCluster = (map) => 
{
    // Style for the clusters
    const getStyle = (feature, resolution) => 
    {
        const features = feature.get('features');
        const size = features.length;
        const isCurrent = features.some(f => f.values_.isCurrent);

        const markerSize = isCurrent ? 0.45 : 0.35;
        const markerPosition = isCurrent ?  [0.5, 1] : [0.5, 0.75];
        const imgSrc = !isCurrent ?
            size === 1 ? "/img/map/map-marker.png" : "/img/map/map-marker-multiple.png" :
            "/img/map/map-marker-current.png";

        return new OlStyle({
            image: new Icon({
                anchor: markerPosition,
                scale:  markerSize,
                src: imgSrc
            }),
            text: size === 1 ? null : new Text({
                text: size.toString(),
                font: 'bold 14px comic sans ms',
                textBaseline: 'top',
                offsetY: 5,
                padding: [2, 6, 2, 6],
                fill: new Fill({
                    color: '#111'
                }),
                backgroundFill: new Fill({
                    color: 'rgba(255,128,0, 0.5)', 
                })
            })
        });
    }

    const clusterSource = new Cluster({
        distance: 35,
        source: new OlSourceVector()
    });

    const clusterLayer = new AnimatedCluster({
        name: 'Cluster',
        source: clusterSource,
        animationDuration: 500,
        style: getStyle
    });

    return [clusterLayer, clusterSource];
}

export const AddClusterFeatures = (points, clusterLayer) => 
{
    let routePoints = [];
    var features=[];
    points.forEach((point, i) => 
    {
        routePoints.push([point.long, point.lat]);
      
        features[i] = new Feature({
            geometry: new Point(fromLonLat([point.long, point.lat])),
            isMarker: true,
            long: point.long,
            lat: point.lat,
            occurrenceDate: point.tstamp,
            temperature: !!point.mesurement ? point.mesurement.value : null,
            measurementDate: !!point.mesurement ? point.mesurement.tstamp : null,
            isCurrent: i === points.length - 1
        });
        features[i].set('id', i);
    });

    clusterLayer.getSource().clear();
    clusterLayer.getSource().addFeatures(features);

    return routePoints;
}
