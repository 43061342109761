import React from 'react';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'antd';
import styles from './styles.module.css';
import {LoadingOutlined} from '@ant-design/icons';

export const FormNavigation = (props: {onBack?: () => void; loading?: boolean; nextDisabled?: boolean}) => {
	const loading: boolean = !!props.loading;
	const nextDisabled: boolean = !!props.nextDisabled;

	const {t} = useTranslation();

	const onBackClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		props.onBack();
		e.preventDefault();
	};

	return (
		<Row justify="space-between" className={styles.FormNavigation}>
			<Col>
				{typeof props.onBack === 'function' && (
					<Button className="btn btn-primary float-left" color="primary" onClick={onBackClick}>
						<em className="fsInButton elpro-Back fa-fw" />
						{t('buttons.back').toString()}
					</Button>
				)}
			</Col>
			<Col>
				<Button className="btn btn-primary" color="primary" type="submit" disabled={nextDisabled || loading}>
					{loading ? (
						<>
							<LoadingOutlined /> Loading
						</>
					) : (
						<>
							<em className="fsInButton elpro-Next fa-fw" />
							{t('buttons.next').toString()}
						</>
					)}
				</Button>
			</Col>
		</Row>
	);
};
