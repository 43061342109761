import {webserverServiceProUrl} from './index';
import {IGetTotpUriRequest, ITotpUriResponse, ITotpValidationRequest, ITotpValidationResponse} from '@elproag/webserver-service-pro';

const authBaseUrl = `${webserverServiceProUrl}/api/web/authentication`;

export async function LoadTOTPUri(userId: bigint, payload: IGetTotpUriRequest): Promise<ITotpUriResponse> {
	const headers = new Headers();
	headers.append('accept', 'application/json');
	headers.append('content-type', 'application/json');

	const response = await fetch(`${authBaseUrl}/totp/uri/${userId}`, {
		headers: headers,
		body: JSON.stringify(payload),
		method: 'PUT',
	});

	if (response.ok) {
		const result = await response.json();
		return Promise.resolve(result);
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function ValidateTOTPCode(userId: bigint, payload: ITotpValidationRequest): Promise<ITotpValidationResponse> {
	const headers = new Headers();
	headers.append('accept', 'application/json');
	headers.append('content-type', 'application/json');

	const response = await fetch(`${authBaseUrl}/totp/validate/${userId}`, {
		headers: headers,
		body: JSON.stringify(payload),
		method: 'PUT',
	});

	if (response.ok) {
		const result = await response.json();
		return Promise.resolve(result);
	}

	console.error(await response.text());
	return Promise.reject();
}
