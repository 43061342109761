import request from '../../Infrastructure/Requests/Request';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

function getRunStatistics(payload: any, eventData: any) {
	return request({
		url: '/statistics',
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

function getCalibrationStatistics(calibrationId: number, start: Date = undefined, end: Date = undefined) {
	return request({
		url: '/statistics/calibrations/' + calibrationId,
		params: start && end ? {from: start, to: end} : null,
		method: 'GET',
		token: Authentication.getToken(),
	});
}

const StatisticsService = {
	getRunStatistics,
	getCalibrationStatistics,
};

export default StatisticsService;
