import ReactHighcharts from 'react-highcharts';
import histogram from 'highcharts/modules/histogram-bellcurve';

const calibrationAnalysisHistogramConfiguration = props => {
	histogram(ReactHighcharts.Highcharts);

	(function (H) {
		H.wrap(H.seriesTypes.histogram.prototype, 'setDerivedData', function (proceed) {
			if (this.baseSeries.yData.length !== 0) {
				proceed.apply(this);
			}
		});
	})(ReactHighcharts.Highcharts);

	return {
		credits: {enabled: false},
		chart: {
			height: 400,
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
			borderRadius: 0,
			spacing: [5, 20, 20, 0],
			animation: false,
			accessibility: {
				keyboardNavigation: {
					enabled: true,
				},
			},
		},
		legend: {
			enabled: false,
		},
		title: {
			text: null,
		},
		xAxis: [
			{
				title: {text: 'Data'},
			},
			{
				title: null,
			},
		],

		yAxis: [
			{
				title: {text: 'Count'},
			},
			{
				title: null,
			},
		],
		series: [],
	};
};

export default calibrationAnalysisHistogramConfiguration;
