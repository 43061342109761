import React from 'react';
import {Trans} from 'react-i18next';

type AdditionalConfigurationSummaryProps = {
	additionalConfigurationSummary: any;
	show: boolean;
};

export function AdditionalConfigurationSummaryComponent(props: AdditionalConfigurationSummaryProps): React.JSX.Element {
	return (
		<>
			{props.show &&
				!!props.additionalConfigurationSummary &&
				Object.keys(props.additionalConfigurationSummary).map(
					(sectionKey, i) =>
						typeof props.additionalConfigurationSummary[sectionKey] === 'object' &&
						props.additionalConfigurationSummary[sectionKey] !== null && (
							<React.Fragment key={i}>
								<tr style={{backgroundColor: '#b1b3b3'}} key={i}>
									<td>
										{' '}
										<Trans
											i18nKey={props.additionalConfigurationSummary[sectionKey].text}
											defaults={props.additionalConfigurationSummary[sectionKey].text}
										/>
									</td>
									<td />
								</tr>
								{Object.keys(props.additionalConfigurationSummary[sectionKey].values).map((itemKey, j) =>
									!!props.additionalConfigurationSummary[sectionKey].values[itemKey] ? (
										Array.isArray(props.additionalConfigurationSummary[sectionKey].values[itemKey]) ? (
											<tr key={j}>
												<td>{props.additionalConfigurationSummary[sectionKey].values[itemKey][0].text}</td>
												<td>
													{props.additionalConfigurationSummary[sectionKey].values[itemKey]
														.map(v => v.value)
														.join(', ')}
												</td>
											</tr>
										) : (
											<tr key={j}>
												<td>{props.additionalConfigurationSummary[sectionKey].values[itemKey].text}</td>
												<td>{props.additionalConfigurationSummary[sectionKey].values[itemKey].value}</td>
											</tr>
										)
									) : null
								)}
							</React.Fragment>
						)
				)}
		</>
	);
}
