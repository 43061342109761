import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import ErrorDisplay from "./../ErrorDisplay";

const EditableDropdownControl = React.forwardRef(({
    id,
    name,
    label,
    value,
    disabled,
    showErrors,
    errors,
    handleChange,
    handleFocus,
    handleBlur,
    dataToValidate,
    ...rest
}, ref) => {

    const [displayedValue, setDisplayedValue] = React.useState("");

    React.useEffect(() => {
        if (!displayedValue) setDisplayedValue(value);
    }, [value])

    const handleSelectChange = e => {
        const selectedIndex = e.target.selectedIndex;
        const labelValue = e.target.options[selectedIndex].text;

        setDisplayedValue(labelValue);
        handleChange(e);
    }

    const handleTextChange = e => {
        setDisplayedValue(e.target.value);
        handleChange(e);
    }

    return (
        <>
            <Label for={id}>{label}</Label>
            <div style={{ position: 'relative' }}>
                <Input
                    innerRef={ref}
                    id={id}
                    type="hidden"
                    name={name}
                    value={value}
                    data-param={rest.max_length || null}
                    data-validate={dataToValidate}
                />
                <Input
                    type="select"
                    invalid={showErrors && !disabled && errors.length > 0}
                    value={value}
                    disabled={disabled}
                    onChange={e => {
                        handleSelectChange(e);
                        handleBlur();
                    }}
                >
                    <option />
                    {rest.defaults.map((option, i) =>
                        option.alias !== undefined ? (
                            <option key={i} value={option.value}>
                                {option.alias}
                            </option>
                        ) : 
                        (
                            <option key={i}>{option}</option>
                        )
                    )}
                </Input>
                <Input
                    className="displayed-control"
                    type="text"
                    value={displayedValue}
                    invalid={showErrors && !disabled && errors.length > 0}
                    disabled={disabled}
                    onChange={handleTextChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                />

                {showErrors && !disabled && errors.map((error, i) => (
                    <ErrorDisplay key={i} error={error} params={[rest.max_length || 0]} />
                ))}
            </div>

        </>
    );
});

EditableDropdownControl.displayName = 'EditableDropdownControl';

EditableDropdownControl.propTypes = {
    errors: PropTypes.array,
    handleChange: PropTypes.func
}

EditableDropdownControl.defaultProps = {
    errors: [],
    handleChange: () => { }
};

export default EditableDropdownControl;
