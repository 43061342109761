import {FormSummary, Row} from '../Summary/FormSummary';
import React from 'react';
import {FeatureFlag} from '../../../../common/helpers';

export function EcologProGFlightModeComponent(props: any): React.JSX.Element {
	if (!props.featureFlags.includes(FeatureFlag.EcologProGxInTransit)) return null;

	const rows: Row[] = [{key: 'sensorWizard.ecologProGx.FlightDetectorEnabled', value: 'True'}];
	return <FormSummary title={'sensorWizard.ecologProGx.InTransit'} rows={rows} />;
}
