import {DeviceUtils} from '../../../common/util';
import {GetLoggingIntervalOptions} from './LoggingIntervalSelectComponentOptions';

type OptionProps = {
	id: string;
	key: number;
	value: number;
	name: string;
}[];

export const getLoggingIntervalSelectComponentOptions = (SerialNumber: string): OptionProps | null => {
	if (!SerialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const deviceType = DeviceUtils.GetDeviceType(SerialNumber);

	if (deviceType) {
		return GetLoggingIntervalOptions(deviceType);
	}

	return null;
};
