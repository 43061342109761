import React from 'react';
import { useDrag } from 'react-dnd';
import SensorGroupsUtils from '../SensorGroupsUtils';

const SensorItem = React.memo(({ sensor, index, groupId, addSensorToGroup, removeSensorFromGroup }) => {

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'sensorItem', id: sensor.id, index, groups_ids: sensor.sensors_groups_ids },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            const dropGroup = monitor.getDropResult();
            if (item !== null && dropGroup !== null) 
            {
                addSensorToGroup(sensor, dropGroup);
            }
        },
    });

    const opacity = (isDragging && !SensorGroupsUtils.isTouchDevice()) ? 0 : 1;
    return (
        <li className={`draggable-list__item ${isDragging ? 'dragged' : ''}`}
            style={{ opacity }}
            ref={drag}
        >
            <span className="list_item">{ sensor.name }</span>
            {
                !!groupId && 
                (
                    <span className="action__btns">
                        <em className={"fs1 elpro-Delete"} id={"remove-sensor-from-group-" + sensor.id}
                            onClick={(e) => removeSensorFromGroup(sensor, groupId)}
                            style={{ cursor: 'pointer' }} 
                        />
                    </span>
                )
            }
        </li>
    )
});

export default SensorItem;
