import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type MetadatasCustomersIdProps = {
	customerId: string;
	show: boolean;
};

export default function MetadatasCustomersIdComponent(props: MetadatasCustomersIdProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.customersID'} />
				</td>
				<td>{props.customerId ? props.customerId : CONSTANTS.NOT_AVAILABLE}</td>
			</tr>
		)
	);
}
