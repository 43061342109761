export const mapCommunicationModeTemplateToAdditionalConfiguration = (
	communicationModeIndex: number,
	communicationModes: any,
	additionalConfiguration: any
) => {
	if (!communicationModes || !additionalConfiguration) {
		throw new TypeError('Please pass required parameters!!!');
	}
	if (!!communicationModeIndex) {
		const foundCommunicationMode = communicationModes.find((obj: {index: number}) => obj.index === communicationModeIndex);

		//Object assignment helps to append or overwrite the object. It does not crash, if the parameter does not exist!!!
		Object.assign(additionalConfiguration, {
			communication_mode: communicationModeIndex,
			communication_intervals: {
				...additionalConfiguration.communication_intervals,
				interval_1: foundCommunicationMode.template.communication_intervals.interval_1,
				interval_ps_1: foundCommunicationMode.template.communication_intervals.interval_ps_1,
			},
			fast_communication: {
				enable: foundCommunicationMode.template.fast_communication.enabled,
				interval: foundCommunicationMode.template.fast_communication.interval,
				min_rsrp: foundCommunicationMode.template.fast_communication.min_rsrp,
			},
			edrx_setting: foundCommunicationMode.template.edrx_setting,
			neighbor_cell_measurement: {
				enable: foundCommunicationMode.template.neighbor_cell_measurement.enable,
				interval: foundCommunicationMode.template.neighbor_cell_measurement.interval,
				search_type: foundCommunicationMode.template.neighbor_cell_measurement.search_type,
				min_neighbor_cells: foundCommunicationMode.template.neighbor_cell_measurement.min_neighbor_cells,
			},
		});
	}
	return additionalConfiguration;
};
