import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import ErrorDisplay from "./../ErrorDisplay";

const MultiselectControl = React.forwardRef(({
    id,
    name,
    label,
    alias,
    value,
    disabled,
    errors,
    showErrors,
    handleChange,
    handleBlur,
    dataToValidate,
    ...rest
}, ref) => {

    const [dropdownValue, setDropdownValue] = React.useState("");
    const [displayedValues, setDisplayedValues] = React.useState([]);

    React.useEffect(() => {
        if (displayedValues.length <= 0 && !!value ) {
            setDisplayedValues(rest.defaults.filter(option => value.indexOf(option.value) >= 0).map(v => v.alias));
        }
    }, [value]);

    const handleSelectChange = e => {
        const selectedIndex = e.target.selectedIndex;
        const labelValue = e.target.options[selectedIndex].text;

        if (value.indexOf(e.target.value) < 0) {
            setDisplayedValues(prevValues => {
                const nextValues = [...value, e.target.value];
                const fackEvent = { target: { value: nextValues } };

                handleChange(fackEvent);
                return  [...prevValues, labelValue];
            });
        }

        setDropdownValue("");
    }

    const handleRemoveItem = index => {
        setDisplayedValues(prevValues => {
            const nextValues = value.filter((value, i) => i !== index);
            const fackEvent = { target: { value: nextValues } };
            
            handleChange(fackEvent);
            return prevValues.filter((value, i) => i !== index);
        });

    }

    return (
        <>
            <Label for={id}>{label}</Label>
            <div className={`list-control-container multiselect ${!disabled ? '' : 'disabled'}`}>
                <Input
                    type="select"
                    invalid={showErrors && !disabled && errors.length > 0}
                    disabled={disabled}
                    value={dropdownValue}
                    onChange={e => {
                        handleSelectChange(e);
                        handleBlur();
                    }}
                >
                    <option value="" />
                    {rest.defaults.map((option, i) =>
                        option.alias !== undefined ? (
                            <option key={i} value={option.value}>
                                {option.alias}
                            </option>
                        ) :
                            (
                                <option key={i}>{option}</option>
                            )
                    )}
                </Input>
                <div
                    className={`form-control displayed-control ${showErrors && !disabled && errors.length > 0 && "is-invalid"}`}
                >
                    {!!displayedValues && displayedValues.map((value, i) => (
                        <div key={i} className="list-control-items">
                            <span>{value} </span>
                            <span className="remove" onClick={() => handleRemoveItem(i)}> x </span>
                        </div>
                    ))}
                </div>
            </div>

            <Input
                innerRef={ref}
                invalid={showErrors && !disabled && errors.length > 0}
                id={id}
                type="hidden"
                name={name}
                value={value}
                data-validate={dataToValidate}
            />

            {showErrors && !disabled && errors.map((error, i) => (
                <ErrorDisplay key={i} error={error} params={[rest.max_length || 0]} />
            ))}

        </>
    );
});

MultiselectControl.displayName = 'MultiselectControl';

MultiselectControl.propTypes = {
    errors: PropTypes.array,
    handleChange: PropTypes.func
}

MultiselectControl.defaultProps = {
    errors: [],
    handleChange: () => { }
};

export default MultiselectControl;
