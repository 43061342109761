import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import ErrorDisplay from "./../ErrorDisplay";

const NumberControl = React.forwardRef(({
	id,
	name,
	label,
	alias,
	value,
	disabled,
	errors,
	showErrors,
	handleBlur,
	handleChange,
	dataToValidate,
	...rest
}, ref) => (
		<>
			<Label for={id}>{label}</Label>
			<Input
				innerRef={ref}
				type="number"
				id={id}
				name={name}
				placeholder={`Enter ${alias}`}
				step={rest.range ? rest.range.step || 1 : 1}
				data-param={rest.range ? JSON.stringify([rest.range.min, rest.range.max]) : null}
				invalid={showErrors && !disabled && errors.length > 0}
				value={value}
				disabled={disabled}
				data-validate={dataToValidate}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			{showErrors && !disabled && errors.map((error, i) => (
				<ErrorDisplay key={i} error={error} params={rest.range ? [rest.range.min, rest.range.max] : null} />
			))}
		</>
	)
);

NumberControl.displayName = 'NumberControl';

NumberControl.propTypes = {
	errors: PropTypes.array,
	handleChange: PropTypes.func
}

NumberControl.defaultProps = {
	errors: [],
	handleChange: () => { }
};

export default NumberControl;