import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import FormControls from "./FormControls";
import FormControl from "./FormControl";
import FormUtils from "./FormUtils";
import LoggerPositionCard from "./Shared/LoggerPositionCard";
import LCDValueDisplay from "./Shared/LCDValueDisplay";


class FormBuilder extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formValue: {},
			formErrors: {}
		};
	}

	componentDidUpdate(prevProps)
	{
		if (JSON.stringify(this.props.schema) !== JSON.stringify(prevProps.schema)) 
		{
			// load the init form value
			let initValue = FormUtils.getTemplateSectionValue(this.props.schema, this.props.value);

			this.setState({
				formValue: initValue,
			});

			this.props.onChange(initValue);
		}
	}

	handleChange = (controlValue, parentKey = null) => {

		if (controlValue === null || controlValue === undefined)
			return;

		this.setState(prevState => {
			const nextFormValue = parentKey === null ?
					{ ...prevState.formValue, ...controlValue} :
					{ ...prevState.formValue, [parentKey]: { ...prevState.formValue[parentKey], ...controlValue }};

			this.props.onChange(nextFormValue);
			return {formValue: nextFormValue};
		})
	};

	handleError = controlError => {
		this.setState(prevState => {
			const nextErrors = { ...prevState.formErrors, ...controlError };
			this.props.onError(nextErrors);

			return { formErrors: nextErrors };
		})
	};

	renderFormControl = (field, itemKey, parentKey = null, hasLabel = true) => {

		if (field.id === undefined || field.id === null)
			return null;

		const RenderedControl = FormControls[field.type] || FormControls["text"];

		// Control properties
		const label = field.alias;
		let controlValue = !!parentKey ? this.state.formValue[parentKey] && this.state.formValue[parentKey][field.id] : this.state.formValue[field.id];
		let disabled = field.editable === false;
		let visible = field.is_visible !== false;

		if (!!field.active_only) {
			let [fieldKey, fieldValue] = JSON.parse(field.active_only);
			let fieldFormValue = this.state.formValue[fieldKey];

			if (!!fieldFormValue && (this.props.schema[fieldKey].type === "list" || this.props.schema[fieldKey].type === "multiselect")) 
			{
				disabled = disabled || fieldFormValue.indexOf(fieldValue) < 0;
			} else {
				// eslint-disable-next-line
				disabled = disabled || fieldFormValue != fieldValue;
			}

			if (disabled)
				controlValue = field.default;

			if (disabled && controlValue === true)
				controlValue = false;
		}

		if (!!field.visible_only) {
			let [fieldKey, fieldValue] = JSON.parse(field.visible_only);
			let fieldFormValue = this.state.formValue[fieldKey];

			if (!!fieldFormValue && (this.props.schema[fieldKey].type === "list" || this.props.schema[fieldKey].type === "multiselect")) 
			{
				visible = visible && fieldFormValue.indexOf(fieldValue) >= 0;
			} else {
				// eslint-disable-next-line
				visible = visible && fieldFormValue == fieldValue;
			}
			
		}

		if (!visible && this.state.formErrors[itemKey] === true) {
			this.handleError({ [itemKey]: false });
		}

		return (
			visible && (
				<React.Fragment key={itemKey}>
					<Row style={{marginLeft:0, marginRight:0}}>
					<Col lg={3}>
						<FormControl
							name={itemKey}
							type={field.type}
							value={controlValue === false ? controlValue : controlValue || ""}
							inValid={this.props.invalidFields[itemKey]}
							mandatory={field.mandatory}
							disabled={disabled}
							onChange={v => this.handleChange(v, parentKey)}
							onError={this.handleError}
							{...field}
						>
							{({ value, errors, dataToValidate, handleChange, handleFocus, handleBlur, showErrors }, ref) => (
								<RenderedControl
									name={itemKey}
									label={hasLabel ? label : null}
									mandatory={field.mandatory}
									disabled={disabled}
									value={value}
									ref={ref}
									errors={errors}
									dataToValidate={dataToValidate}
									handleChange={handleChange}
									handleFocus={handleFocus}
									handleBlur={handleBlur}
									showErrors={showErrors || this.props.formState[`${this.props.name}_is_submitted`]}
									{...field}
								/>
							)}
						</FormControl>
					</Col>

					{
						['after_stop', 'before_start', 'in_transit'].includes(itemKey) &&
						<Col lg={9}>
							<LCDValueDisplay
								value={controlValue}
							/>
						</Col>
					}

					</Row>
					{
						itemKey === 'initial_logger_position' &&
						<LoggerPositionCard
							position={controlValue}
						/>
					}
				</React.Fragment>
			)
		);
	};

	render() {
		return (
			<React.Fragment>
				{
					Object.keys(this.props.schema).map((itemKey, i) =>
						this.props.schema[itemKey].id !== undefined ?
							this.renderFormControl(this.props.schema[itemKey], itemKey) :
							<Row key={i} className="mx-0 text-center">
								<Col sm={12} className="text-left">{this.props.schema[itemKey].alias}</Col>
								{Object.keys(this.props.schema[itemKey]).map((fieldKey, i) => this.renderFormControl(this.props.schema[itemKey][fieldKey], fieldKey, itemKey, false))}
							</Row>
					)
				}
			</React.Fragment>
		);
	}
}

FormBuilder.propTypes = {
	onChange: PropTypes.func,
	onError: PropTypes.func
}

FormBuilder.defaultProps = {
	onChange: () => { },
	onError: () => { }
};

export default FormBuilder;