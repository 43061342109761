import axios from 'axios';
import StatisticsService from '../Statistics/StatisticsService';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
const isDebugMode = false;

const localGetRunStatistics = (payload: any) => {
	return axios({
		url: 'https://localhost:7257/statistics',
		method: 'POST',
		data: payload,
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
			'Content-Type': 'application/json',
			Accept: 'text/plain',
		},
	});
};

const localGetCalibrationStatistics = (calibrationId: number, start: Date = undefined, end: Date = undefined) => {
	return axios({
		url: 'https://localhost:7257/statistics/calibrations/' + calibrationId,
		method: 'GET',
		params: start && end ? {from: start, to: end} : null,
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
		},
	});
};

const getRunStatistics = (payload: any, eventData: any) => {
	if (isDebugMode) {
		return localGetRunStatistics(payload);
	} else {
		return StatisticsService.getRunStatistics(payload, eventData);
	}
};

const getCalibrationStatistics = (calibrationId: number, start: Date = undefined, end: Date = undefined) => {
	if (isDebugMode) {
		return localGetCalibrationStatistics(calibrationId, start, end);
	} else {
		return StatisticsService.getCalibrationStatistics(calibrationId, start, end);
	}
};

const Statistics = {
	getRunStatistics,
	getCalibrationStatistics,
};

export default Statistics;
