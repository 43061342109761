import React from 'react';
import {useDrag, useDrop} from 'react-dnd';
import SensorGroupsUtils from '../SensorGroupsUtils';

const GroupItem = React.memo(({group, index, moveGroup, findGroup, children}) => {

    const [{isDragging}, drag] = useDrag({
        item: {type: `groupItem-${group.sensors_group_parent}`, id: group.id, index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const [, groupDrop] = useDrop({
        accept: `groupItem-${group.sensors_group_parent}`,
        hover({id: draggedId}) {
            if (draggedId !== group.id) {
                const {index: draggedIndex, group} = findGroup(draggedId);
                moveGroup(draggedIndex, index, group.sensors_group_parent);
            }
        },
    });

    const [{canDrop, isOver}, sensorDrop] = useDrop({
        accept: 'sensorItem',
        canDrop: ({groups_ids}) => !groups_ids || groups_ids.indexOf(group.id) < 0, // !isSensorOnSameLevelGroup(sensor.id, groups)
        drop: () => ({id: group.id, name: group.name}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const opacity = (isDragging && !SensorGroupsUtils.isTouchDevice()) ? 0 : 1;
    return (
        <div ref={node => drag(groupDrop(node))} style={{opacity}}>
            {children({dropRef: sensorDrop, isActive: canDrop && isOver, canDrop, isDragging})}
        </div>
    );
});

export default GroupItem;