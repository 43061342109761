import React from 'react';
import {useTranslation} from 'react-i18next';
import {Table} from 'reactstrap';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';
import {LimitAlarmFormState} from '../../../LimitAlarmForm/LimitAlarmFormState';
import {WithUnitString} from '../../../../../../../common/util';
import {SummarySectionHeading} from './SummarySectionHeading';
import {RecipientSelectRows} from './RecipientSelectRows';
import DateTimeUtils from '../../../../../../Infrastructure/DateTime/DateTimeUtils';

type LimitAlarmsSummaryProps = {
	limitAlarmForms: LimitAlarmFormState[];
	loggingInterval: number;
	show: boolean;
};

export default function LimitAlarmsSummary(props: LimitAlarmsSummaryProps) {
	const show = props.show != false;

	const [t] = useTranslation();

	return (
		show && (
			<>
				<SummarySectionHeading title={t('sensorWizard.summary.limitAlarms')} />
				{props.limitAlarmForms?.map((form, idx) => {
					const useLimitAlarm = !!form.alarmLimit;
					return (
						<React.Fragment key={'TODO' + idx + 'summaryGeneral'}>
							{props.limitAlarmForms.length > 1 && (
								<SummarySectionHeading title={`Sensor ${(idx + 1).toString()}`} type={'h2'} />
							)}

							<tr>
								<td>{t('sensorWizard.summary.useLimitAlarm').toString()}</td>
								<td>{useLimitAlarm ? CONSTANTS.YES : CONSTANTS.NO}</td>
							</tr>

							{useLimitAlarm && (
								<>
									<tr>
										<td>{t('sensorWizard.summary.profileName').toString()}</td>
										<td>{form.alarmLimit.Name}</td>
									</tr>
									<tr key={form.alarmLimit.Name + idx + 'summaryLimits'}>
										<td>Limit alarms</td>
										<td>
											<Table>
												<tbody>
													<tr key={form.alarmLimit.Name + idx + 'header'}>
														<td>Zone</td>
														<td>Limit</td>
														<td>Delay</td>
													</tr>
													{form.alarmLimit.GetAllLimitsWithDelay().map(limit => {
														const delay = props.loggingInterval
															? DateTimeUtils.formatSecondsAsHumanDuration(
																	limit.delay * props.loggingInterval
															  )
															: `${limit.delay}`;

														return (
															<tr key={form.alarmLimit.Name + idx + limit.type}>
																<td>{limit.type}</td>
																<td>{WithUnitString(limit.limit, form.unit)}</td>
																<td>{delay}</td>
															</tr>
														);
													})}
												</tbody>
											</Table>
										</td>
									</tr>
									<RecipientSelectRows recipientSelectState={form.recipients} />
								</>
							)}
						</React.Fragment>
					);
				})}
			</>
		)
	);
}
