import React from 'react';
import {Trans} from 'react-i18next';

type MetadatasTitleProps = {
	show: boolean;
};

export default function MetadatasTitleComponent(props: MetadatasTitleProps) {
	return (
		props.show && (
			<tr style={{backgroundColor: '#b1b3b3'}}>
				<td width="30%">
					<Trans i18nKey={'sensorWizard.steps.metadatas'} />
				</td>
				<td />
			</tr>
		)
	);
}
