import 'ag-grid-enterprise';
import {issueProfileNameCellRules} from './CssRules/issueProfileNameCellRules';
import {menuCellRules} from './CssRules/menuCellRules';
import {COLUMNS} from './Constants/Columns';
import ButtonsCellComponent from './ButtonsCell/ButtonsCellComponent';
import {memo} from 'react';
import {validateParamsToRenderCheckboxComponent} from './CellRenderers/CheckboxRenderer/CheckboxRendererValidator';
import {validateParamsToRenderLockComponent} from './CellRenderers/LockRenderer/LockRendererValidator';

export const ColumnDefinitions = [
	{
		headerName: 'Row ID',
		valueGetter: 'node.id',
		hide: 'true',
		lockVisible: 'true',
		filter: false,
		sortable: false,
		suppressMovable: true,
	},
	{
		field: COLUMNS.ISSUE_PROFILE_NAME,
		headerTooltip: COLUMNS.ISSUE_PROFILE_NAME,
		width: 300,
		valueFormatter: p => {
			if (p.value) {
				return p.value;
			}
		},
		cellClassRules: issueProfileNameCellRules,
		tooltipValueGetter: p => {
			if (p.value) {
				return p.value;
			}
		},
	},
	{
		field: COLUMNS.MISSING_COMMUNICATION_WARNING,
		headerTooltip: COLUMNS.MISSING_COMMUNICATION_WARNING,
		width: 240,
		cellRendererSelector: params => validateParamsToRenderCheckboxComponent(params),
		cellStyle: {textAlign: 'center'},
	},
	{
		field: COLUMNS.SENSOR_FAILURE_ALARM,
		headerTooltip: COLUMNS.SENSOR_FAILURE_ALARM,
		width: 170,
		cellRendererSelector: params => validateParamsToRenderCheckboxComponent(params),
		cellStyle: {textAlign: 'center'},
	},
	{
		field: COLUMNS.LOST_MEASUREMENT_ALARM,
		headerTooltip: COLUMNS.LOST_MEASUREMENT_ALARM,
		cellRendererSelector: params => validateParamsToRenderCheckboxComponent(params),
		cellStyle: {textAlign: 'center'},
	},
	{
		field: COLUMNS.LOW_BATTERY_WARNING,
		headerTooltip: COLUMNS.LOW_BATTERY_WARNING,
		width: 150,
		cellRendererSelector: params => validateParamsToRenderCheckboxComponent(params),
		cellStyle: {textAlign: 'center'},
	},
	//{field: COLUMNS.LIGHT_WARNING, cellRenderer: CheckboxRenderer, enableRowGroup: false}, //this feature will be added in the future
	//{field: COLUMNS.TILT_WARNING, cellRenderer: CheckboxRenderer, enableRowGroup: false}, //this feature will be added in the future
	{field: COLUMNS.IN_USE_BY, headerTooltip: COLUMNS.IN_USE_BY, width: 130, cellStyle: {textAlign: 'center'}, enableRowGroup: false},
	{
		field: COLUMNS.LOCKED,
		headerTooltip: COLUMNS.LOCKED,
		width: 120,
		cellStyle: {textAlign: 'center'},
		cellRendererSelector: params => validateParamsToRenderLockComponent(params),
	},
	{
		headerName: '',
		field: COLUMNS.MENU,
		cellRenderer: memo(ButtonsCellComponent),
		cellClassRules: menuCellRules,
		filter: false,
		resizable: false,
		//pinned: true,
		sortable: false,
		enableRowGroup: false,
		minWidth: 80,
		width: 110,
		suppressMenu: true,
		overflow: 'visible',
		lockVisible: 'true',
		suppressMovable: true,
		lockPosition: 'right',
	},
];
