import React, {MouseEvent, useContext, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import SerialNumberInfo from '../../Shared/SerialNumberInfo';
import {Sensor} from '../../Common/Types/Sensor';
import {SensorActions} from '../../../common/helpers/SensorActions';
import {useHistory} from 'react-router-dom';
import {Button, Dropdown, MenuProps} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import {MenuItemType} from 'antd/es/menu/hooks/useItems';
import {ShowDeleteSensorDialog} from '../../Shared/Dialogs/ShowDeleteSensorDialog';
import {SizeType} from 'antd/es/config-provider/SizeContext';
import StartCalibrationModal from '../../Calibration/StartCalibrationModal';
import {DeviceUtils} from '../../../common/util';
import Message from '../../Shared/Components/Message';
import {FeatureFlag} from '../../../common/helpers';
import {getAdditionallyAffectedSensorsAsync} from '../../../common/util/SensorChannelGrouping';
import {authUtils} from '../../Infrastructure/Authorization/Utils';
import {UI_EVENT} from '../../Shared/Constants/Events';
import pubsub from 'pubsub-js';
import { MultiContext } from '../../../components/Infrastructure/Authorization/Context/MultiContext';

interface SensorDropdownProps {
	sensor: Sensor;
	onDeleteSensor?: (sensor: Sensor) => void;
	onCalibrationStopped?: (sensor: Sensor) => void;
	onCalibrationStarted?: (sensor: Sensor) => void;
	updateSensor?: (sensor: Sensor) => void;
	buttonSize?: SizeType;
}

export const SensorDropdown = (props: SensorDropdownProps) => {
	const context = useContext(MultiContext);
	const history = useHistory();
	const [t] = useTranslation();

	const [startCalibrationModalVisible, setStartCalibrationModalVisible] = useState(false);
	const [sensorRetirementVisible, setSensorRetirementVisible] = useState(false);
	const [calibrationAffectedSensors, setCalibrationAffectedSensors] = useState<Sensor[]>([]);

	useEffect(() => {
		if (startCalibrationModalVisible) {
			getAdditionallyAffectedSensorsAsync(props.sensor).then(sensors => setCalibrationAffectedSensors(sensors));
		}
	}, [startCalibrationModalVisible]);

	useEffect(() => {
		const enabled = context.FeatureFlagsContext?.includes(FeatureFlag.Licensing);
		setSensorRetirementVisible(enabled);
	}, []);

	function onCalibrationStarted() {
		setStartCalibrationModalVisible(false);
		if (DeviceUtils.IsLiberoGx(props.sensor) || DeviceUtils.IsEcologProXG(props.sensor)) {
			Message.warning(<Trans i18nKey={'calibrations.startSent'} />, <Trans i18nKey={'calibrations.start'} />, {autoClose: 20000});
		}

		pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${props.sensor.Id}`, {
			sensorId: props.sensor.Id,
		});

		calibrationAffectedSensors.forEach(affectedSensor =>
			pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${affectedSensor.Id}`, {
				sensorId: affectedSensor.Id,
			})
		);

		props.onCalibrationStarted && props.onCalibrationStarted(props.sensor);
	}

	function deleteSensor() {
		ShowDeleteSensorDialog(props.sensor).then(result => {
			if (result.IsConfirmed()) {
				props.onDeleteSensor && props.onDeleteSensor(props.sensor);
			}
		});
	}

	const menuItems: any[] = [
		{
			id: 'btnAnalyse',
			title: t('sensor.analyseSensor'),
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.viewAnalysis,
			roles: userRoles.default,
			showOnRetired: true,
			onClick: () => SensorActions.Analyse(props.sensor, history),
		},
		{
			id: 'btnSensorShowSummary',
			title: t('sensor.sensorShowSummary'),
			onClick: () => SensorActions.ShowSummary(props.sensor, history),
		},
		{type: 'divider'},
		{
			id: 'btnActivateAlarming',
			title: t('sensor.activateAlarming'),
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.deactivateAlarming,
			roles: userRoles.default,
			onClick: () => SensorActions.SetAlarmingEnabled(props.sensor, true),
			disabled: !!props.sensor.CalibrationState,
			hide: SerialNumberInfo.isValidLiberoG(props.sensor.SerialNumber) || !props.sensor.MutingEnabled || !props.sensor.IsMuted,
		},
		{
			id: 'btnDeactivateAlarming',
			title: t('sensor.deactivateAlarming'),
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.deactivateAlarming,
			roles: userRoles.default,
			onClick: () => SensorActions.SetAlarmingEnabled(props.sensor, false),
			hide: SerialNumberInfo.isValidLiberoG(props.sensor.SerialNumber) || !props.sensor.MutingEnabled || props.sensor.IsMuted,
		},
		{
			id: 'btnConfiguration',
			title: t('sensor.configuration'),
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.editSensor,
			roles: userRoles.default,
			onClick: () => SensorActions.ConfigureSensor(props.sensor, history),
			disabled: !!props.sensor.CalibrationState,
			hide: DeviceUtils.IsLiberoGx(props.sensor.SerialNumber),
		},
		{
			id: 'btnStartNewRun',
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.startNewRun,
			roles: userRoles.default,
			onClick: () => SensorActions.ConfigureSensor(props.sensor, history),
			disabled: !props.sensor.CanStartNewRun(),
			hide: !props.sensor.IsMultiRun,
			title: t('sensor.newRun'),
		},
		{type: 'divider'},
		{
			id: 'btnStartCalibration',
			roles: [...userRoles.calibrator],
			disabled: !props.sensor.CanStartCalibration,
			hide: props.sensor.CanStopCalibration || DeviceUtils.IsLiberoGx(props.sensor),
			onClick: () => setStartCalibrationModalVisible(true),
			title: t('sensor.startCalibration'),
		},
		{
			id: 'btnStopCalibration',
			roles: [...userRoles.calibrator],
			disabled: !props.sensor.CanStopCalibration,
			hide: !props.sensor.CanStopCalibration || DeviceUtils.IsLiberoGx(props.sensor),
			onClick: () => {
				SensorActions.StopCalibration(props.sensor);
				props.onCalibrationStopped && props.onCalibrationStopped(props.sensor);
			},
			title: t('sensor.stopCalibration'),
		},
		{
			id: 'btnReplaceSensor',
			title: t('sensor.replaceSensor'),
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.replaceSensor,
			roles: userRoles.default,
			hide: DeviceUtils.IsLiberoGx(props.sensor),
			disabled: props.sensor.MultiRunLockedConfiguration || !!props.sensor.CalibrationState,
			onClick: () => SensorActions.ReplaceSensor(props.sensor, history),
		},
		{
			id: 'btnManageLicense',
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.manageLicence,
			roles: userRoles.default,
			showOnRetired: true,
			onClick: () => SensorActions.ManageLicense(props.sensor, history),
			title: t('sensor.manageLicense'),
		},
		{
			id: 'btnRetireSensor',
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.manageLicence,
			hide: !sensorRetirementVisible,
			roles: userRoles.default,
			onClick: () => SensorActions.RetireSensor(props.sensor, history, context.AccessContext.user.user_settings.devicesViewSettings),
			title: t('sensor.retireSensor'),
		},
		{type: 'divider'},
		{
			id: 'btnDelete',
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.deleteSensor,
			roles: userRoles.default,
			showOnRetired: true,
			onClick: () => deleteSensor(),
			title: t('sensor.deleteSensor'),
		},
		{
			id: 'btnCertificate',
			access: accessPermissions.devicesview.child.dashboard.child.sensor.child.downloadFactoryCalibrationCertificate,
			roles: userRoles.default,
			onClick: () => SensorActions.DownloadCertificate(props.sensor),
			title: t('sensor.calibrationCertificate'),
		},
	];

	function hideMenuItemsForRetiredSensor(menuItem: any): boolean {
		if (menuItem.type === 'divider') {
			return true;
		}
		return !props.sensor.IsRetired || menuItem.showOnRetired;
	}

	function removeDuplicateDividers(menuItem: any, idx: number, array: any[]): boolean {
		if (menuItem.type !== 'divider') {
			return true;
		}

		if (idx === 0) {
			return false;
		}

		return array[idx - 1].type !== 'divider';
	}

	function hideWithMissingAccessRights(menuItem: any): boolean {
		if (menuItem.type === 'divider') {
			return true;
		}
		let hasRoleAccess: boolean = true;
		if (menuItem.roles) {
			hasRoleAccess = menuItem.roles.some(role => context.AccessContext.user.access_settings.settings.roles.includes(role));
		}

		let hasAccess: boolean = true;
		if (menuItem.access) {
			const accessElement = authUtils.findAccessElement(menuItem.access.path, context.AccessContext.user.access_settings.access);
			hasAccess = accessElement?.allow ?? hasAccess;
		}
		return hasRoleAccess && hasAccess;
	}

	const items: MenuProps['items'] = menuItems
		.filter(hideWithMissingAccessRights)
		.filter(m => !m.hide)
		.filter(hideMenuItemsForRetiredSensor)
		.filter(removeDuplicateDividers)
		.map(m => {
			if (m.type) {
				return {type: m.type};
			}
			return {
				disabled: m.disabled,
				label: m.title,
				key: `${props.sensor.Id}-${m.title}`,
				onClick: m.onClick,
				id: m.id,
			} as MenuItemType;
		});
	return (
		<>
			<StartCalibrationModal
				isVisible={startCalibrationModalVisible}
				sensor={props.sensor}
				addidionallyAffectedSensor={calibrationAffectedSensors}
				isLoading={false}
				onChangeModalVisibility={() => setStartCalibrationModalVisible(!startCalibrationModalVisible)}
				startCallback={onCalibrationStarted}
			/>
			<Dropdown menu={{items}} trigger={['click']} arrow={{pointAtCenter: true}}>
				<Button
					icon={<MenuOutlined />}
					onClick={(e: MouseEvent) => e.preventDefault()}
					size={props.buttonSize}
					id={`SensorDropdown${props.sensor.Name}`}
				/>
			</Dropdown>
		</>
	);
};

export default SensorDropdown;
