import React, {useState} from 'react';
import {Input} from 'reactstrap';
import FormValidator from '../Forms/FormValidator';
import Shared from '../Shared/Shared';
import {asyncServerRequest} from '../Infrastructure/Requests/ServerRequest';

const pwError = {
	fontSize: '80%',
	color: '#f61214',
};

const ConfirmationChangePassword = props => {
	const [formResetPassword, setFormResetPassword] = useState({password: '', password_confirm: ''});
	const [errorMessage, setErrorMessage] = useState(undefined);
	const [token] = useState(props.match.params.token);

	const handlePWChange = e => {
		setErrorMessage(Shared.validatePassword(e.target.value));
		validateOnChange(e);
	};

	const validateOnChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;
		const result = FormValidator.validate(input);
		setFormResetPassword({
			...formResetPassword,
			[input.name]: value,
			errors: {
				...formResetPassword.errors,
				[input.name]: result,
			},
		});
	};

	const hasError = (formName, inputName, method) => {
		return (
			formResetPassword &&
			formResetPassword.errors &&
			formResetPassword.errors[inputName] &&
			formResetPassword.errors[inputName][method]
		);
	};

	const setPassword = e => {
		setErrorMessage(undefined);
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
		const {errors, hasError} = FormValidator.bulkValidate(inputs);
		setFormResetPassword({
			...formResetPassword,
			errors,
		});

		if (!hasError) {
			const payload = {
				old_password: null,
				new_password: formResetPassword.password,
				token,
			};

			setNewUserPassword(payload)
				.then(response => {
					props.history.push({
						pathname: '/login',
						state: {userId: response.data.userId},
					});
				})
				.catch(error => {
					console.log(error);
				});
		}

		e.preventDefault();
	};

	const setNewUserPassword = async payload => {
		const payloadData = {
			url: 'settings/user-management/set-new-user-password',
			method: 'POST',
			data: payload,
		};
		try {
			return await asyncServerRequest(payloadData);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="block-center mt-4 wd-xl">
			<div className="card card-flat">
				<div className="card-header text-center bg-white">
					<img width={200} src={process.env.PUBLIC_URL + '/img/logo.png'} alt="elproCLOUD" />
				</div>
				<div className="card-body">
					<p className="text-center py-2">Set Password</p>
					<form name="formResetPassword" onSubmit={setPassword}>
						<div className="form-group">
							<div className="input-group with-focus">
								<Input
									type="password"
									id="inputPassword"
									name="password"
									className="border-right-0"
									placeholder="Password"
									invalid={hasError('formResetPassword', 'password', 'required')}
									data-validate='["required"]'
									onChange={handlePWChange}
									value={formResetPassword.password}
								/>
								<div className="input-group-append">
									<span className="input-group-text text-muted bg-transparent border-left-0">
										<em className="fa fa-lock" />
									</span>
								</div>
								{hasError('formResetPassword', 'password', 'required') && (
									<span id="errorPasswordRequired" className="invalid-feedback">
										Field is required
									</span>
								)}
							</div>
							{errorMessage && (
								<span id="errorPasswordRequired" style={pwError}>
									{errorMessage}
								</span>
							)}
						</div>
						<div className="form-group">
							<div className="input-group with-focus">
								<Input
									type="password"
									id="inputPasswordConfirm"
									name="password_confirm"
									className="border-right-0"
									placeholder="Retype Password"
									invalid={hasError('formResetPassword', 'password_confirm', 'equalto')}
									data-param="inputPassword"
									data-validate='["equalto"]'
									onChange={validateOnChange}
									value={formResetPassword.password_confirm}
								/>
								<div className="input-group-append">
									<span className="input-group-text text-muted bg-transparent border-left-0">
										<em className="fa fa-lock" />
									</span>
								</div>
								{hasError('formResetPassword', 'password_confirm', 'required') && (
									<span id="errorPasswordConfirmRequired" className="invalid-feedback">
										Field is required
									</span>
								)}
								{hasError('formResetPassword', 'password_confirm', 'equalto') && (
									<span id="errorPasswordsNotEqual" className="invalid-feedback">
										Passwords must be equal
									</span>
								)}
							</div>
						</div>

						<button className="btn btn-block btn-primary mt-3" type="submit" disabled={!!errorMessage}>
							Set Password
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ConfirmationChangePassword;
