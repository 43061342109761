import {webserverServiceProUrl} from './index';
import {getDefaultOpts} from '../index';

import {IOrganizationResponse, IOrganizationUpdateRequest} from '@elproag/webserver-service-pro';
import {Organization} from '../../types';

const baseUrl = `${webserverServiceProUrl}/api/web/organizations`;

export async function GetOrganization(orgId: number): Promise<Organization> {
	const response = await fetch(`${baseUrl}/${orgId}`, {
		...getDefaultOpts(),
		method: 'GET',
	});

	if (!response.ok) {
		console.error(await response.text());
		return Promise.reject();
	}

	const result = (await response.json()) as IOrganizationResponse;

	return {
		Id: result.organization.id,
		SessionTimeout: result.organization.sessionTimeout,
		DefaultTemperature: result.organization.defaultTemperature,
		Name: result.organization.name,
		Address: result.organization.address,
		Country: result.organization.country,
		ZipCode: result.organization.zipCode,
		City: result.organization.city,
		Vat: result.organization.vat,
		SupportCode: result.organization.supportCode,
		ResellerName: result.organization.reseller?.resellerName,
		DisplayName: result.organization.reseller?.country.displayName,
		UsePrepaidCredit: result.organization.usePrepaidCredit,
		QuickViewAllowed: result.organization.quickViewAllowed,
		MfaRequired: result.organization.mfaRequired,
	};
}

export async function UpdateOrganization(organization: Organization) {
	const request: IOrganizationUpdateRequest = {
		organization: {
			address: organization.Address,
			city: organization.City,
			country: organization.Country,
			defaultTemperature: organization.DefaultTemperature,
			mfaRequired: organization.MfaRequired,
			name: organization.Name,
			quickViewAllowed: organization.QuickViewAllowed,
			sessionTimeout: organization.SessionTimeout,
			usePrepaidCredit: organization.UsePrepaidCredit,
			vat: organization.Vat,
			zipCode: organization.ZipCode,
		},
	};

	const response = await fetch(`${baseUrl}/${organization.Id}`, {
		...getDefaultOpts(),
		body: JSON.stringify(request),
		method: 'PUT',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}
