import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';
import {findCommunicationModeNameFromIndex} from '../Helpers/CommunicationModeNameFinder';

type SensorBasicsCommunicationModeProps = {
	communicationMode: number;
	communicationModeTemplates: any;
	show: boolean;
};

export default function SensorBasicsCommunicationModeComponent(props: SensorBasicsCommunicationModeProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.communicationMode'} />
				</td>
				<td>
					{props.communicationMode
						? findCommunicationModeNameFromIndex(props.communicationMode, props.communicationModeTemplates)
						: CONSTANTS.NOT_AVAILABLE}
				</td>
			</tr>
		)
	);
}
