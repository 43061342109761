import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import ErrorDisplay from "./../ErrorDisplay";

const DropdownControl = React.forwardRef(({
	id,
	name,
	label,
	value,
	disabled,
	errors,
	showErrors,
	handleChange,
	handleBlur,
	dataToValidate,
	...rest
}, ref) => (
		<>
			<Label for={id}>{label}</Label>
			<Input
				innerRef={ref}
				type="select"
				id={id}
				name={name}
				data-validate={dataToValidate}
				invalid={showErrors && !disabled && errors.length > 0}
				value={value}
				disabled={disabled}
				onChange={e => {
					handleChange(e);
					handleBlur();
				}}
			>
				{!value && <option />}
				{rest.defaults.map((option, i) =>
					option.alias !== undefined ? (
						<option key={i} value={option.value}>
							{option.alias}
						</option>
					) : 
					(
						<option key={i}>{option}</option>
					)
				)}
			</Input>

			{showErrors && !disabled && errors.map((error, i) => (
				<ErrorDisplay key={i} error={error} />
			))}
		</>
	)
);

DropdownControl.displayName = 'DropdownControl';

DropdownControl.propTypes = {
	errors: PropTypes.array,
	handleChange: PropTypes.func
}

DropdownControl.defaultProps = {
	errors: [],
	handleChange: () => { }
};

export default DropdownControl;