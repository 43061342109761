export const SENSOR_FILTER_ALARMS = {
	LOST_MEASUREMENT_ALARM: ['Lost measurement alarm', 'lost_measurement_alarm'],
	LOW_BATTERY_WARNING: ['Low battery warning', 'low_battery_warning'],
	RADIO_CONNECTION_WARNING: ['Radio connection warning', 'radio_connection_warning'],
	UPPER_LIMIT_ALARM: ['Upper limit alarm', 'upper_limit_alarm'],
	LOWER_LIMIT_ALARM: ['Lower limit alarm', 'lower_limit_alarm'],
	MISSING_COMMUNICATION_WARNING: ['Missing communication warning', 'missing_communication_warning'],
	MISSING_VALUE_ALARM: ['Missing value alarm', 'missing_value_alarm'],
	PREDICTIVE_ALARM: ['Predictive Alarm', 'predictive_alarm'],
	PREDICTIVE_WARNING: ['Predictive Warning', 'predictive_warning'],
	SENSOR_FAILURE_ALARM: ['Sensor failure alarm', 'sensor_failure_alarm'],
};
