import React, {Component} from 'react';
import ActionButtons from './ActionButtons';

class ActionCellButtons extends Component {
	constructor(props) {
		super(props);
		if (!props.value) {
			return;
		}
		this.state = {
			limitProfile: props.value.limitProfile,
			rowIndex: props.rowIndex,
			name: props.value.limitProfile.name,
		};
	}

	prepareId() {
		return this.state.name.replace(/\s/g, '').trim() + '-' + this.state.rowIndex;
	}

	render() {
		return (
			<>
				{this.props.value ? (
					<ActionButtons
					    id ={this.prepareId()}
						locked={this.state.limitProfile.locked}
						copyLimitProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.copyLimitProfile(
								this.props.value.parent,
								this.state.limitProfile
							)
						}
						displayLimitProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.displayLimitProfile(
								this.props.value.parent,
								this.state.limitProfile.id
							)
						}
						editLimitProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.editLimitProfile(
								this.props.value.parent,
								this.state.limitProfile
							)
						}
						deleteLimitProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.deleteLimitProfile(
								this.props.value.parent,
								this.state.limitProfile
							)
						}
					/>
				) : null}
			</>
		);
	}
}

export default ActionCellButtons;
