import {webserverServiceProUrl} from './index';
import {getDefaultOpts} from '../index';
import {IUserCreationRequest, IUserUpdateRequest} from '@elproag/webserver-service-pro';
import {CreateUserRequest, UpdateUserAccessSettingsRequest} from 'common/types';

const baseUrl = `${webserverServiceProUrl}/api/web/users`;

export async function CreateUser(user: CreateUserRequest): Promise<void> {
	const request: IUserCreationRequest = {
		email: user.email,
		firstName: user.firstName,
		lastName: user.lastName,
		mobile: user.mobile,
		roles: user.roles,
	};

	const response = await fetch(baseUrl, {
		...getDefaultOpts(),
		body: JSON.stringify(request),
		method: 'POST',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function ActivateUser(userId: bigint): Promise<void> {
	const response = await fetch(`${baseUrl}/${userId}/activate`, {
		...getDefaultOpts(),
		method: 'PATCH',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function UpdateUserAccessSettings(updateData: UpdateUserAccessSettingsRequest): Promise<void> {
	const request: IUserUpdateRequest = {
		accessSettings: updateData.accessSettings,
	};

	const response = await fetch(`${baseUrl}/${updateData.userId}`, {
		...getDefaultOpts(),
		body: JSON.stringify(request),
		method: 'PATCH',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}
