import React, {useEffect, useState} from 'react';
import DeviationsService from '../DeviationsService';
import {Form, FormGroup, Input, Label} from 'reactstrap';
import Alert from '../../Shared/Components/Alert';
import Modal from '../../Shared/Components/Modal';
import RequestLogger from '../../Infrastructure/Requests/Logger/RequestLogger';

const DeviationsAcknowledge = ({title, isLoading, isVisible, onChangeModalVisibility, deviations, acknowledgeSuccess}) => {
	const [modalTitle, setModalTitle] = useState(title);
	const [visible, setVisible] = useState(isVisible);
	const [loading, setLoading] = useState(isLoading);
	const [comment, setComment] = useState('');
	const [password, setPassword] = useState('');
	const [formError, setFormError] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [acknowledgeDeviations, setAcknowledgeDeviations] = useState(false);

	// Visibility
	useEffect(() => {
		setVisible(isVisible);
	}, [isVisible]);

	// OkButton Loading
	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading]);

	// Title
	useEffect(() => {
		setModalTitle(title);
	}, [title]);

	// Acknowledge
	useEffect(() => {
		if (!acknowledgeDeviations) {
			return;
		}

		if (password.length === 0) {
			setFormError(true);
			return;
		}

		const acknowledge = () => {
			DeviationsService.acknowledge_deviation(
				{
					deviations_id: deviations,
					comment: comment,
					pass: password,
				},
				RequestLogger.createLogData('deviations-acknowledge', 'acknowledge-deviations', 'onClick')
			)
				.then(() => {
					acknowledgeSuccess();
				})
				.catch(e => {
					setSubmitError(true);
					setPassword('');
					setFormError(false);
					setAcknowledgeDeviations(false);
				});
		};

		acknowledge();
	}, [acknowledgeDeviations]);

	const handleModalCancel = () => {
		onChangeModalVisibility(false);
	};

	const handleAfterClose = () => {
		setSubmitError(false);
		setComment('');
		setPassword('');
		setFormError(false);
		setAcknowledgeDeviations(false);
	};

	return (
		<Modal
			title={modalTitle}
			visible={visible}
			confirmLoading={loading}
			handleCancel={handleModalCancel}
			afterClose={handleAfterClose}
			handleOk={() => {
				setAcknowledgeDeviations(true);
				setFormError(false);
			}}
		>
			<div>
				<Form
					id="acknowledge"
					name="acknowledge"
					onSubmit={event => {
						setAcknowledgeDeviations(true);
						setFormError(false);
						event.preventDefault();
					}}
				>
					<FormGroup>
						<Label for="comment">Comment</Label>
						<Input
							type="textarea"
							name="comment"
							id="comment"
							rows="4"
							value={comment}
							onChange={e => setComment(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label for="password">Password</Label>
						<Input
							className={`${formError ? 'is-invalid form-control' : ''}`}
							type="password"
							id="password"
							name="password"
							placeholder="Password"
							value={password}
							onChange={e => {
								setPassword(e.target.value);
								setFormError(false);
								setAcknowledgeDeviations(false);
								setSubmitError(false);
							}}
						/>
						{formError && <span className="invalid-feedback">Field is required</span>}
					</FormGroup>
					{submitError && (
						<Alert
							message="Warning"
							description={'The user credentials you entered were not valid, please try again.'}
							type="error"
						/>
					)}
				</Form>
			</div>
		</Modal>
	);
};

export default DeviationsAcknowledge;
