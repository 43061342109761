import {
    isTablet,
    isMobile
} from "react-device-detect";

const deviceType = {

    getDeviceType() {

        if (isMobile) {
            if (isTablet) {
                return 3
            } else {
                return 2;
            }
        } else {
            return 1;
        }
    }
};

export default deviceType;