import hash from 'object-hash';
import cloneDeep from 'lodash/cloneDeep';

const GroupAlarmStatusesAccordingToDate = container => {
    if (container === undefined || container === null || !Array.isArray(container)) {
        throw new TypeError('Please pass an array as a parameter of GroupAlarmStatusesAccordingToDate!!!');
    }

    if (container.length === 1) {
        return container;
    }

    let describtions = [];
    let obj = {
        index: undefined,
        tstamp: undefined,
        desc: [],
    };
    for (let i = 0; i < container.length; i++) {
        const date = container[i].tstamp;
        for (let j = i + 1; j < container.length; j++) {
            const date2 = container[j].tstamp;
            if (hash.MD5(date) === hash.MD5(date2)) {
                if (describtions.length > 0 && describtions.some(item => hash.MD5(date2) === item.index)) {
                    const foundRecord1 = describtions.find(item => {
                        return item.index === hash.MD5(date2);
                    });
                    if (!foundRecord1.desc.some(item => item === container[j].desc)) {
                        foundRecord1.desc.push(container[j].desc);
                    }
                } else {
                    let deepCopy = cloneDeep(obj);
                    deepCopy.index = hash.MD5(date2);
                    deepCopy.tstamp = date2;
                    container[i].desc === container[j].desc
                        ? deepCopy.desc.push(container[i].desc)
                        : deepCopy.desc.push(container[i].desc, container[j].desc);
                    describtions.push(deepCopy);
                }
            } else {
                if (describtions.length > 0 && describtions.some(item => item.index === hash.MD5(date))) {
                    const foundRecord3 = describtions.find(item => {
                        return item.index === hash.MD5(date);
                    });
                    if (!foundRecord3.desc.some(item => item === container[i].desc)) {
                        foundRecord3.desc.push(container[i].desc);
                    }
                } else {
                    let deepCopy = cloneDeep(obj);
                    deepCopy.index = hash.MD5(date);
                    deepCopy.tstamp = date;
                    deepCopy.desc.push(container[i].desc);
                    describtions.push(deepCopy);
                }

                if (describtions.length > 0 && describtions.some(item => hash.MD5(date2) === item.index)) {
                    const foundRecord2 = describtions.find(item => {
                        return item.index === hash.MD5(date2);
                    });
                    if (!foundRecord2.desc.some(item => item === container[j].desc)) {
                        foundRecord2.desc.push(container[j].desc);
                    }
                } else {
                    let deepCopy2 = cloneDeep(obj);
                    deepCopy2.index = hash.MD5(date2);
                    deepCopy2.tstamp = date2;
                    deepCopy2.desc.push(container[j].desc);
                    describtions.push(deepCopy2);
                }
            }
        }
    }
    return describtions;
};

export default GroupAlarmStatusesAccordingToDate;