import React from 'react';
import {Table} from 'antd';
import {Trans} from 'react-i18next';
import {EventDetailType} from '../../common/types';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import dayjs from 'dayjs';
import {GetAuditTrailDetailTranslationKey} from '../../common/helpers/GetAuditTrailDetailTranslationKey';

const columns = [
	{
		title: '',
		dataIndex: 'type',
		render: type => (
			<span>
				<Trans i18nKey={getTransKey(type)} />
			</span>
		),
		width: '13%',
	},
	{
		title: '',
		render: record => getValue(record),
	},
];

function getValue(record) {
	switch (record.type) {
		case 32:
			return <>{`${record.value}`}</>;
		case 41:
			return <div>{record.value / 60} min</div>;
		case EventDetailType.BreachExpectedTimestampUnixUtc:
			return <>{DateTimeUtils.toUserString(dayjs(record.value))}</>;
	}
	if (record.is_object) {
		try {
			const valueAsJson = JSON.parse(record.value);

			if (Array.isArray(valueAsJson) && record.type === 87) {
				return (
					<div>
						{valueAsJson.map((sensor, index) => {
							return (
								<span key={index}>
									{' '}
									{sensor.name} {sensor.expiry_date}
									<br />
								</span>
							);
						})}
					</div>
				);
			} else if (valueAsJson.hasOwnProperty('old_value') || valueAsJson.hasOwnProperty('new_value')) {
				return (
					<div>
						<span>Old value: {valueAsJson['old_value']}</span>
						<br />
						<span>New value: {valueAsJson['new_value']}</span>
					</div>
				);
			} else {
				return Array.isArray(valueAsJson) ? <span>{valueAsJson.toString()}</span> : <span>{JSON.stringify(valueAsJson)}</span>;
			}
		} catch {}
	} else {
		return (
			<div>
				<span>
					{record.type === 5 || record.type === 4 ? <Trans i18nKey={'deviations.reason.' + record.value} /> : record.value}
				</span>
			</div>
		);
	}
}

function getTransKey(type) {
	return GetAuditTrailDetailTranslationKey(type);
}

function renderer(record) {
	return (
		<div style={{marginLeft: '35px'}}>
			<Table
				locale={{emptyText: 'No data'}}
				columns={columns}
				rowKey={record => record.id}
				dataSource={record.details}
				pagination={false}
				showHeader={false}
			/>
		</div>
	);
}

const AuditTrailDetailsRenderer = {
	renderer,
	getTransKey,
};

export default AuditTrailDetailsRenderer;
