import pubsub from 'pubsub-js';
import React, {Component, ReactNode} from 'react';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import {CalibrationAnalysisProps, CalibrationAnalysisState} from './Components/Analysis/CalibrationAnalysisState';
import CalibrationService from '../Services/Calibrations/CalibrationService';
import {MeasurementNew} from '../Common/Types/WebSocket/MeasurementNew';
import AccordionFilter from '../Common/Filter/AccordionFilter';
import {Calibration} from './Model/Calibration';
import {DEVICE_EVENT} from '../Shared/Constants/Events';
import Close from '../Shared/Components/Close';
import ContentWrapper from '../Layout/ContentWrapper';
import {Trans, withTranslation} from 'react-i18next';
import CalibrationAnalysisChart from './Components/Analysis/CalibrationAnalysisChart';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import CalibrationAnalysisStatistics from './Components/Analysis/CalibrationAnalysisStatistics';
import {CalibrationAnalysisExportComponent} from './Components/Analysis/CalibrationAnalysisExportComponent';
import CalibrationAnalysisInformation from './Components/Analysis/CalibrationAnalyisisInformations';
import SensorAnalysisService from '../../components/Analysis/SensorAnalysisService';
import {RequestLogger} from '../Infrastructure/Requests/Logger/';
import {CalibrationMeasurement} from './Model/CalibrationMeasurement';
import moment from 'moment';

class CalibrationAnalysis extends Component<CalibrationAnalysisProps, CalibrationAnalysisState> {
	private calibrationService: CalibrationService;
	private subscriptions: PubSubJS.Token[] = [];

	constructor(props) {
		super(props);

		this.calibrationService = new CalibrationService();

		const calibration: Calibration = props.location.state.calibration;

		this.state = {
			calibration: calibration,
			displayStartDate: DateTimeUtils.utcOffset_date_dep(calibration.Start),
			displayStartDateSelector: DateTimeUtils.utcOffset_date_dep(calibration.Start),
			displayEndDate: calibration.Stop
				? DateTimeUtils.utcOffset_date_dep(calibration.Stop)
				: DateTimeUtils.utcOffset_date_dep(new Date()),
			displayEndDateSelector: calibration.Stop
				? DateTimeUtils.utcOffset_date_dep(calibration.Stop)
				: DateTimeUtils.utcOffset_date_dep(new Date()),
			loadingReport: false,
		};

		this.onClose = this.onClose.bind(this);
		this.handleNewMeasurement = this.handleNewMeasurement.bind(this);
	}

	async componentDidMount() {
		this.subscriptions.push(pubsub.subscribe(DEVICE_EVENT.MEASUREMENT_NEW, this.handleNewMeasurement));

		const calibration = await this.calibrationService.getCalibration(this.state.calibration.CalibrationID);
		this.setState({
			calibration: calibration,
		});

		this.showAll();
	}

	componentWillUnmount() {
		this.subscriptions.forEach(token => {
			pubsub.unsubscribe(token);
		});
	}

	async handleNewMeasurement(message: any, newMeasurement: MeasurementNew) {
		if (newMeasurement.SensorId !== this.state.calibration.Sensor.ID) {
			return;
		}

		if (
			newMeasurement.Calibration &&
			!this.state.calibration.Measurements.find(cm => cm.Date.getTime() === newMeasurement.Timestamp.getTime())
		) {
			this.state.calibration.Measurements.push(
				new CalibrationMeasurement(
					newMeasurement.Timestamp,
					newMeasurement.Value,
					this.state.calibration.Sensor.UnitID,
					newMeasurement.Status
				)
			);
		} else {
			const calib = await this.calibrationService.getCalibration(this.state.calibration.CalibrationID);
			this.setState({
				calibration: calib,
			});
		}

		this.showAll();
	}

	async onClose(e) {
		this.props.history.go(-1);
		e.preventDefault();
	}

	showAll() {
		this.setState(() => ({
			displayStartDate: DateTimeUtils.utcOffset_date_dep(this.state.calibration.Start),
			displayStartDateSelector: DateTimeUtils.utcOffset_date_dep(this.state.calibration.Start),
			displayEndDate: this.state.calibration.Stop
				? DateTimeUtils.utcOffset_date_dep(this.state.calibration.Stop)
				: DateTimeUtils.utcOffset_date_dep(new Date()),
			displayEndDateSelector: this.state.calibration.Stop
				? DateTimeUtils.utcOffset_date_dep(this.state.calibration.Stop)
				: DateTimeUtils.utcOffset_date_dep(new Date()),
		}));
	}

	showSelectedDateTimeRange() {
		if (
			isNaN(Date.parse(this.state.displayStartDateSelector.toString())) ||
			isNaN(Date.parse(this.state.displayEndDateSelector.toString())) ||
			this.state.displayEndDateSelector < this.state.displayStartDateSelector
		) {
			return;
		}

		this.setState(state => ({
			displayStartDate: moment(
				DateTimeUtils.getIsoFromUCTWithoutOffset(state.displayStartDateSelector, DateTimeUtils.getCurrentUserTZOffset())
			),
			displayEndDate: moment(
				DateTimeUtils.getIsoFromUCTWithoutOffset(state.displayEndDateSelector, DateTimeUtils.getCurrentUserTZOffset())
			),
		}));
	}

	handleChartZoom(e) {
		this.setState(() => ({
			displayStartDateSelector: DateTimeUtils.utcOffset_date_dep(e.min),
			displayEndDateSelector: DateTimeUtils.utcOffset_date_dep(e.max),
			displayStartDate: DateTimeUtils.utcOffset_date_dep(e.min),
			displayEndDate: DateTimeUtils.utcOffset_date_dep(e.max),
		}));
	}

	async exportCalibrationData() {
		await this.calibrationService.exportCalibrationResult(this.state.calibration.CalibrationID);
	}

	async createReport(exportType: string) {
		this.setState({loadingReport: true});

		let payload = {
			SensorId: this.state.calibration.Sensor.ID,
			FileSuffix: parseInt(exportType),
			TimeFilter: {
				FromUtc: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.displayStartDate, DateTimeUtils.getCurrentUserTZOffset()),
				ToUtc: DateTimeUtils.getIsoFromUCTWithoutOffset(this.state.displayEndDate, DateTimeUtils.getCurrentUserTZOffset()),
			},
		};

		try {
			await SensorAnalysisService.exportData(
				payload,
				RequestLogger.createLogData('sensor-exportdata', 'export-sensordata', 'onClick')
			);
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({loadingReport: false});
		}
	}

	render(): ReactNode {
		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>
						<Trans i18nKey={'calibrations.analysis'} />
						{`: ${this.state.calibration.CalibrationID}`}
					</div>
					<div className="ml-auto">
						<Close onClick={this.onClose} />
					</div>
				</div>

				<CalibrationAnalysisExportComponent
					reportLocalhost={undefined}
					loadingReport={undefined}
					report={undefined}
					exportFunction={this.createReport.bind(this)}
					exportCalibrationData={this.exportCalibrationData.bind(this)}
					disabled={this.state.calibration.State !== 'Stopped'}
				/>

				<AccordionFilter
					title={<Trans i18nKey={'calibrations.analysisPeriod'} />}
					expanded={true}
					dateFrom={this.state.displayStartDateSelector}
					setDateFrom={value => this.setState({displayStartDateSelector: value})}
					dateTo={this.state.displayEndDateSelector}
					setDateTo={value => this.setState({displayEndDateSelector: value})}
					showSelectedDateTime={this.showSelectedDateTimeRange.bind(this)}
					showAll={this.showAll.bind(this)}
					style={{marginTop: '70px'}}
				/>

				<br />

				<Accordion>
					<AccordionItem id="accordionCalibrationInfos" expanded>
						<AccordionItemTitle id="accordionCalibrationInfosTitle">
							<h5 className="u-position-relative">
								<Trans i18nKey={'titles.calibration'} />
								<div className="accordion__arrow" role="presentation" />
							</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							<CalibrationAnalysisInformation calibration={this.state.calibration} />
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>

				<br />

				<Accordion>
					<AccordionItem id="accordionSensorChart" expanded>
						<AccordionItemTitle id="accordionSensorChartTitle">
							<h5 className="u-position-relative">
								<Trans i18nKey={'titles.chart'} />
								<div className="accordion__arrow" role="presentation" />
							</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							{this.state.calibration.Sensor ? (
								<CalibrationAnalysisChart
									calibration={this.state.calibration}
									displayStartDate={this.state.displayStartDate}
									displayEndDate={this.state.displayEndDate}
									handleChartZoom={this.handleChartZoom.bind(this)}
									resetZoom={this.showAll.bind(this)}
								/>
							) : null}
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>

				<br />

				<Accordion>
					<AccordionItem id="accordionSensorStatistics" expanded>
						<AccordionItemTitle id="accordionSensorStatisticsTitle">
							<h5 className="u-position-relative">
								<Trans i18nKey={'titles.statistics'} />
								<div className="accordion__arrow" role="presentation" />
							</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							{this.state.calibration.Sensor ? (
								<CalibrationAnalysisStatistics
									calibration={this.state.calibration}
									displayStartDate={this.state.displayStartDate}
									displayEndDate={this.state.displayEndDate}
								/>
							) : null}
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>
			</ContentWrapper>
		);
	}
}

export default withTranslation()(CalibrationAnalysis);
