/**
 * @deprecated use DEVICE_EVENT or UI_EVENT instead
 * @type {{UPDATE_DASHBOARD_LIBERO_G: string, NEW_MEASUREMENT: string, ALL_DEVIATION_ACKNOWLEDGED: string, SENSOR_STATUS_CHANGED: string, ALTER_TEMPERATURE: string, DEVIATION_OCCURRED: string, SENSOR_ALARM_SETTING_CHANGED: string, NEW_DEVIATION_STATUS: string, NEW_RSSI: string, NEW_RUN: string, SENSOR_NEW: string, BTN_DROPRIGHT_CHANGED: string, DEVIATION_NO_LONGER_ACTIVE: string, RUN_STATUS_CHANGED: string}}
 */
export const EVENTS = {
	ALL_DEVIATION_ACKNOWLEDGED: 'all_deviations_acknowledged',
	BTN_DROPRIGHT_CHANGED: 'btnDroprightChanged',
	DEVIATION_NO_LONGER_ACTIVE: 'deviation_no_longer_active',
	DEVIATION_OCCURRED: 'deviation_occurred',
	NEW_DEVIATION_STATUS: 'newDeviationStatus',
	NEW_MEASUREMENT: 'newMeasurement',
	NEW_RUN: 'newRun',
	RUN_STATUS_CHANGED: 'run_status_changed',
	SENSOR_ALARM_SETTING_CHANGED: 'sensorAlarmSettingChanged',
	SENSOR_NEW: 'sensor_new',
	SENSOR_STATUS_CHANGED: 'sensor_status_changed',
	UPDATE_DASHBOARD_LIBERO_G: 'update_dashboard_libero_g',
};

/**
 * These are the bindings of the notificationservice_workqueue in the internal broker
 */
export enum DEVICE_EVENT {
	ALL_DEVIATION_ACKNOWLEDGED = 'all_deviations_acknowledged',
	AUDIT_TRAIL_NEW = 'audit_trail_new',
	BRIDGE_CONFIGURATION_DONE = 'bridge_configuration_done',
	BRIDGE_CONFIGURATION_SUCCESSFUL = 'bridge_configuration_successful',
	DEVIATION_ACKNOWLEDGED = 'deviation_acknowledged',
	DEVIATION_NO_LONGER_ACTIVE = 'deviation_no_longer_active',
	DEVIATION_OCCURRED = 'deviation_occurred',
	MEASUREMENT_NEW = 'measurement_new',
	RUN_STATUS_CHANGED = 'run_status_changed',
	SENSOR_NEW = 'sensor_new',
	SENSOR_STATUS_CHANGED = 'sensor_status_changed',
	UPDATE_DASHBOARD_LIBERO_G = 'update_dashboard_libero_g',
	SIGNAL_STRENGTH_NEW = 'signal_strength_new',
}

export enum UI_EVENT {
	BTN_DROPRIGHT_CHANGED = 'btnDroprightChanged',
	ORGANISATION_CHANGED = 'organisationChanged',
	SENSOR_ALARMING_ENABLED_CHANGED = 'alarmingEnabledChanged',
	CALIBRATION_STATUS_CHANGED = 'calibration_status_changed',
}

export interface SensorAlarmingEnabledChangedData {
	AlarmingEnabled: boolean;
	SensorId: number;
}

export interface RunStatusChanged {
	status: boolean;
	sensor_id: number;
	org_id: number;
}

export interface CalibrationStatusChanged {
	sensorId: number;
}
