import axios from 'axios';
import appSettings from '../Settings/AppSettings';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
//const client = axios.create({baseURL: 'http://localhost:80/srv/v1/'});
const client = axios.create({baseURL: appSettings.getBaseUrl() + 'srv/v1/'});

const getAuthToken = () => {
	return Authentication.getToken()  || null;
};

client.interceptors.request.use(config => {
	const authToken = getAuthToken();
	if (authToken) {
		config.headers.Authorization = `Bearer ${authToken}`;
	}
	return config;
});

/*client.interceptors.response.use(response => {
    if (response.data.userAccessToken) {
        const base64Url = response.data.userAccessToken.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        response.access = JSON.parse(window.atob(base64));
    }
    return response;
});*/

export const asyncServerRequest = payload => {
	return client({
		url: payload.url,
		method: payload.method,
		data: payload.data,
		params: payload.params,
	});
};
