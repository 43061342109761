import {request} from '../../../components/Infrastructure/Requests/Request';
import {AlarmLimit} from '../../types';
import {DbSensorLimitAlarm} from '../AlarmLimitService/DbSensorLimitAlarm';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

export class LimitProfileService {
	public static async GetLimitProfiles(): Promise<AlarmLimit[]> {
		const url = '/sensor_limit_alarm';
		try {
			const response = (
				await request({
					url: url,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data as DbSensorLimitAlarm[];
			return response.map(alarmLimit => new AlarmLimit(alarmLimit));
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}
}
