import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {CurrentPriceResult} from 'common/util/LicenseUtils/CurrentPriceExtension';

const divStyle = {
	paddingBottom: '40px',
};

const priceStyle = {
	fontWeight: 'normal',
};

type CurrentPriceProps = {
	currentPrice: CurrentPriceResult;
};

function CurrentPrice(props: CurrentPriceProps): JSX.Element {
	return (
		<div style={divStyle}>
			<h4 id="currentPrice" className="float-right" style={priceStyle}>
				{props.currentPrice.totalAmount !== undefined ? (
					<>{props.currentPrice.currency + ' ' + props.currentPrice.totalAmount + '/' + props.currentPrice.interval}</>
				) : (
					<LoadingOutlined />
				)}
			</h4>
		</div>
	);
}

export default CurrentPrice;
