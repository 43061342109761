import request from '../Infrastructure/Requests/Request'
import {asyncRequest} from "../Infrastructure/Requests/AsyncRequest";
import Authentication from '../Infrastructure/Authentication/Authentication';

function allUsers() {
    return request({
        url:    `/users`,
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function allOrgs() {
    return request({
        url:    `/organizations`,
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function allMeasurements(params) {
    return request({
        url:    `/measurements` + params,
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function get(id) {
    return request({
        url:    `/users/${id}`,
        method: 'GET'
    });
}

function rpcTestCall() {
    return request({
        url:    `/rpc/test_call`,
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function rpcWhoami() {
    return request({
        //url:    '/rpc/whoami',
        //method: 'POST',
        url: '/rpc/headers',
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function rpcHasRight(payload) {
    return request({
        url:    '/rpc/has_right',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload
    });
}

function saveModule(payload) {
    return request({
        url: '/registry',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload
    });
}

function deleteModules() {
    return request({
        url: '/registry',
        method: 'DELETE',
        token: Authentication.getToken(),
    });
}

function getAllChannels()  {
    return request({
        url: '/channels',
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function getTemperatures() {
    return request({
        url: '/temperature_units',
        method: 'GET',
        token: Authentication.getToken(),
    });
}

const DevelopmentService = {
    allUsers, allOrgs, allMeasurements, get, rpcTestCall, rpcWhoami, rpcHasRight, saveModule, deleteModules, getAllChannels, getTemperatures
};

export default DevelopmentService;

export const asyncTestGetVersion = async () => {
    return asyncRequest({
        url: '/get_application_version',
        method: 'GET'
    });
};

export const asyncTestGetOrganization = async () => {
    return asyncRequest({
        url: '/organization',
        method: 'GET'
    });
};