import NoRowsOverlayComponent from './GridComponents/NoRowsOverlayComponent';
import LoadingOverlayComponent from './GridComponents/LoadingOverlayComponent';
//import StatusPanelComponent from './GridComponents/StatusPanelComponent'; //After speach with PM, elpro logo is not shown anymore.
//import SideBar from './SideBarDefinitions'; //After speach with PM, side bar is not shown for now!

export const GridOptions = {
    statusBar: {
        statusPanels: [
            //{statusPanel: StatusPanelComponent, align: 'right'},//After speach with PM, elpro logo is not shown anymore.
            {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
            {statusPanel: 'agFilteredRowCountComponent', align: 'left'},
            {statusPanel: 'agSelectedRowCountComponent', align: 'left'},
        ],
    },
    //sideBar: SideBar, //After speach with PM, side bar is not shown for now!
    noRowsOverlayComponent: NoRowsOverlayComponent,
    loadingOverlayComponent: LoadingOverlayComponent,
    containerStyle: {width: '100%', height: '100%'},
    theme: 'ag-theme-alpine',
    gridStyle: {height: 800, width: '100%'},
    //rowGroupPanelShow: 'always', //After speach with PM, side bar is not shown for now!
    rowSelection: 'multiple',
    colDefinationSettings: {minWidth: 100, sortable: true, resizable: true, enableRowGroup: false, suppressSizeToFit: false, filter: 'agSetColumnFilter'},
    enableRangeSelection: false,
    animateRows: true,
    enableCellChangeFlash: false,
    columnHoverHighlight: true,
    showOpenedGroup:true,
    groupDisplayType: 'multipleColumns',
    groupHideOpenParents:false,
    suppressContextMenu:true,
    autoGroupColumnDef: {
        //headerName: 'Test',
        cellRendererParams:{
            //innerRenderer: p=> '<b>'+p.value+'</b>'
        } 
    },
};
