import React from 'react';
import {accessPermissions, userRoles} from '../../../Infrastructure/Authorization/Access';
import {Access} from '../../../Infrastructure/Authorization/Components';

const GroupActionButtons = ({group, addAction, editAction, deleteAction}) => {
    return (
        <span className="action__btns">
            {addAction !== undefined && (
                <>
                    <Access
                        access={accessPermissions.settings.child.sensorGroups.child.sensorGroup.child.addGroup}
                        roles={userRoles.default}
                    >
                        <em
                            className={'fs2 elpro-Add'}
                            id={'add-sensor-group-' + group.name}
                            onClick={e => addAction(e, {sensors_group_parent: group.id})}
                            style={{cursor: 'pointer'}}
                        />
                    </Access>
                    {'\u00A0'}
                    {'\u00A0'}
                </>
            )}
            {editAction !== undefined && (
                <>
                    <Access
                        access={accessPermissions.settings.child.sensorGroups.child.sensorGroup.child.editGroup}
                        roles={userRoles.default}
                    >
                        <em
                            className={'fs2 elpro-Edit'}
                            id={'edit-sensor-group-' + group.name}
                            onClick={e => editAction(e, group)}
                            style={{cursor: 'pointer'}}
                        />
                    </Access>
                    {'\u00A0'}
                    {'\u00A0'}
                </>
            )}
            {deleteAction !== undefined && (
                <Access
                    access={accessPermissions.settings.child.sensorGroups.child.sensorGroup.child.deleteGroup}
                    roles={userRoles.default}
                >
                    <em
                        className={'fs2 elpro-Delete'}
                        id={'delete-sensor-group-' + group.name}
                        onClick={e => deleteAction(e, group)}
                        style={{cursor: 'pointer'}}
                    />
                </Access>
            )}
        </span>
    );
};

export default GroupActionButtons;
