import React from 'react';
import {Input, Row, Col} from 'reactstrap';

export default function ProfileNameComponent(props) {
	return (
        <Row>
        <Col xl={3}>
            <br />
            <p>Profile name</p>
            <Input
                type="text"
                id="issueProfileName"
                name="name"
                placeholder="Enter the Profile Name"
                invalid={props.hasError('issueProfileForm', 'name', 'required')}
                onChange={props.onChange}
                data-validate='["required"]'
                value={props.issueProfile.name}
                disabled={props.disabled}
            />
            {props.hasError('issueProfileForm', 'name', 'required') && (
                <span className="invalid-feedback">Field is required</span>
            )}
        </Col>
    </Row>
	);
}
