import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Steps} from 'antd';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
const {Step} = Steps;

const StyledStep = styled(Step)`
    .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        max-width: none;
        font-size: 12px;
    }

    .ant-steps-finish-icon,
    .ant-steps-error-icon {
        top: -2px;
        position: relative;
    }
`;

const StatusSteps = ({currentStep, currentStatus, stepDescription}) => {
    const [t] = useTranslation();
    const STEP_TITLE = {
        VALIDATE: t('device_status.steps.title.validate'),
        STATUS: t('device_status.steps.title.status'),
        DATA: t('device_status.steps.title.sensor_data'),
    };
    return (
        <Steps size="small" current={currentStep} status={currentStatus}>
            <StyledStep
                title={STEP_TITLE.VALIDATE}
                description={stepDescription[0]}
                icon={currentStep === 0 && currentStatus === 'process' ? <LoadingOutlined /> : null}
            />
            <StyledStep title={STEP_TITLE.DATA} description={stepDescription[2]} />
        </Steps>
    );
};

export default StatusSteps;
