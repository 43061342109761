import request from '../../../components/Infrastructure/Requests/Request';
import {SensorGroup} from '../../types';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

export class SensorGroupService {
	public static async GetSensorGroups(): Promise<SensorGroup[]> {
		try {
			const groups = await request({
				url: '/sensors_groups',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return groups.data.map(g => new SensorGroup(g));
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}
}
