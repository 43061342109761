import React, {MouseEventHandler} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';

export const CloseButton = (props: {onClick: MouseEventHandler}) => {
	const [t] = useTranslation();
	return (
		<Button id="btnClose" type="primary" size="large" onClick={props.onClick}>
			{t('buttons.close').toString()}
		</Button>
	);
};
