import request from '../Infrastructure/Requests/Request';

function post(payload) {
    return request({
        url: '/rpc/confirmation',
        method: 'POST',
        data: payload
    });
}

function changeMail(payload) {
    return request({
        url: '/rpc/change_email',
        method: 'POST',
        data: payload
    });
}

const ConfirmationService = {
    post, changeMail
};

export default ConfirmationService;