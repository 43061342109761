import {DeviceType, EcologProRadioType, EcologProXGType, LiberoGxType} from '../../../common/constants/DeviceType';

const loggingIntervalsMap: {[key in DeviceType]: number[]} = {
	[EcologProXGType.OneTGi]: [300, 600, 900, 1200, 1800, 3600],
	[EcologProXGType.OneTGe]: [300, 600, 900, 1200, 1800, 3600],
	[EcologProXGType.OneTHGi]: [900, 1200, 1800, 3600],
	[LiberoGxType.GS]: [300, 600, 900, 1200, 1800, 3600],
	[LiberoGxType.GF]: [300, 600, 900, 1200, 1800, 3600],
	[LiberoGxType.GL]: [300, 600, 900, 1200, 1800, 3600],
	[LiberoGxType.GE]: [300, 600, 900, 1200, 1800, 3600],
	[LiberoGxType.GH]: [900, 1200, 1800, 3600],
	[LiberoGxType.GFExt]: [300, 600, 900, 1200, 1800, 3600],
	[EcologProRadioType.OneNTR]: [60, 120, 180, 300, 600, 900, 1200, 1800, 3600],
	[EcologProRadioType.TwoPTR]: [60, 120, 180, 300, 600, 900, 1200, 1800, 3600],
	[EcologProRadioType.OneTHR]: [60, 120, 180, 300, 600, 900, 1200, 1800, 3600],
};

export const GetLoggingIntervalOptions = (
	deviceType: DeviceType
): {
	id: string;
	key: number;
	value: number;
	name: string;
}[] => {
	const loggingIntervals = loggingIntervalsMap[deviceType];

	return loggingIntervals.map((l, idx) => ({
		id: `option${l}`,
		key: idx,
		value: l,
		name: `${l / 60} min`,
	}));
};
