import {LimitProfileService, SensorService} from '../../../common/services';
import {LimitAlarmFormState} from '../../Dashboard/Sensor/Forms/LimitAlarmForm/LimitAlarmFormState';
import {SensorsAllInformationsViewRow} from '../../../common/types/DbModel';
import {AlarmLimit, User} from '../../../common/types';
import {UserServiceHelper} from '../../../common/services/UserService/UserServiceHelper';
import {SensorState} from '../../Dashboard/Sensor/AddSensor/WizardState';

/**
 * Function for retrieving the current SensorState for the Wizard. Currently only retrieves LimitAlarmFormState
 *
 * TODO: get remaining data
 * @param sensorId
 * @constructor
 */
export async function getSensorStateAsync(sensorId: number): Promise<Partial<SensorState>> {
	try {
		const [sensorData, limitProfiles, users, {AffectedSensors}] = await Promise.all([
			SensorService.AllInformation(sensorId),
			LimitProfileService.GetLimitProfiles(),
			UserServiceHelper.GetAllUsers(),
			SensorService.DiscoverRun(sensorId),
		]);

		const AllSensorData: SensorsAllInformationsViewRow[] = [sensorData];

		for (const affectedSensor of AffectedSensors.filter(s => s.id != sensorId)) {
			AllSensorData.push(await SensorService.AllInformation(affectedSensor.id));
		}

		const limitAlarmForms = AllSensorData.map(d => getLimitAlarmFormState(d, limitProfiles, users));

		const sensorState: Partial<SensorState> = {
			formStates: {
				limitAlarmForms: limitAlarmForms,
			},
		};

		if (AffectedSensors.filter(s => s.id != sensorId).length > 0) {
		}

		return sensorState;
	} catch (e) {
		return Promise.reject(e);
	}
}

function getLimitAlarmFormState(
	sensorData: SensorsAllInformationsViewRow,
	limitProfiles: AlarmLimit[],
	users: User[]
): LimitAlarmFormState {
	const alarmLimit = sensorData.has_limit_alarm ? limitProfiles.find(l => l.Id == sensorData.sensor_limit_alarm_id) : null;

	const getUsersFromIds = (ids: number[]) => ids?.map(id => users.find(u => u.Id == id)).filter(u => !!u);

	return {
		alarmLimit: alarmLimit,
		predictive: {enabled: false},
		recipients: {
			email: {
				enabled: sensorData.sensor_limit_email_notification,
				recipients: getUsersFromIds(sensorData.recipients_email_list.limit_recipients),
			},
			sms: {
				enabled: sensorData.sensor_limit_sms_notification,
				recipients: getUsersFromIds(sensorData.recipients_sms_list.limit_recipients),
			},
		},
		unit: sensorData.out_units_id,
	};
}
