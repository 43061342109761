import React, {useState} from 'react';
import {Button, Col} from 'reactstrap';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import GroupsList from './GroupsList';
import Message from '../../Shared/Components/Message';
import SensorGroupsUtils from '../../Settings/SensorGroups/SensorGroupsUtils';
import SensorGroupsService from '../../Settings/SensorGroups/SensorGroupsService';
import {Sensor} from '../../Common/Types/Sensor';

interface GroupsProps {
	groups: number[];
	sensors: Map<number, Sensor>;
	children: any;
	getGroups: any;
}

function Groups(props: GroupsProps) {
	const [orderChanged, setOrderChanged] = useState(false);
	const [groups, setGroups] = useState([]);
	const [flatGroups, setFlatGroups] = useState([]);
	const [nestedGroups, setNestedGroups] = useState([]);

	// Make sure, our internal state is updated, when props.groups is updated. We cannot use the groups as initial state
	// because it won't get updated on changes. Another clean way to trigger reconciliation would be to use the “key”
	// attribute on the Groups component in the dashboard, but unfortunately, due to the components' lifecycle, two
	// instances of the DnD backend for the DnD provider would be created, leading to a crash.
	if (groups !== props.groups) {
		const newFlatGroups = SensorGroupsUtils.getFlatGroups(props.groups);
		setGroups(props.groups);
		setFlatGroups(newFlatGroups);
		setNestedGroups(SensorGroupsUtils.getNestedGroups(newFlatGroups));
	}

	const changeGroupChildren = (groups, groupId, childes) => {
		return groups.map(group => {
			if (group.id === groupId) {
				return {...group, sub_groups: childes};
			}

			if (group.sub_groups !== undefined && group.sub_groups !== null) {
				return {...group, sub_groups: changeGroupChildren(group.sub_groups, groupId, childes)};
			}

			return group;
		});
	};

	const groupsChange = (newGroups, parentGroupId) => {
		const nextGroups = parentGroupId === null ? newGroups : changeGroupChildren(nestedGroups, parentGroupId, newGroups);
		setFlatGroups(SensorGroupsUtils.getFlatGroups(nextGroups));
		setNestedGroups(SensorGroupsUtils.getNestedGroups(flatGroups));
		setOrderChanged(true);
	};

	const saveSensorGroupsOrder = e => {
		e.preventDefault();
		const groupsOrders = flatGroups.map(group => ({id: group.id, order: group.group_order}));

		setOrderChanged(false);
		SensorGroupsService.orderSensorGroups({orders: groupsOrders})
			.then(_ => {
				Message.success('Sensor groups order saved successfully');
				props.getGroups();
			})
			.catch(error => {
				setOrderChanged(true);
				Message.error('Error', 'Sensor groups could not be saved', error);
			});
	};

	return (
		// @ts-ignore
		<DndProvider backend={SensorGroupsUtils.isTouchDevice() ? TouchBackend : HTML5Backend}>
			<Col xs={12} className="dashboard_accordian">
				{orderChanged && (
					<Button className="save-order" color="primary" size="sm" onClick={saveSensorGroupsOrder}>
						<span>Save Order</span>
					</Button>
				)}

				<GroupsList
					startIndex={0}
					groups={nestedGroups}
					sensors={props.sensors}
					groupsChange={groupsChange}
					children={props.children}
				/>
			</Col>
		</DndProvider>
	);
}

export default Groups;
