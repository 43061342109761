import React from 'react';
import {Button} from 'reactstrap';
import {Trans} from 'react-i18next';
import {Spin} from 'antd';

const Report = props => {
	return (
		<Spin size="small" spinning={props.loadingReport}>
			<Button color="primary" onClick={props.onClick} size="lg">
				<span className="fa fa-xl fa-solid elpro-Report mr-2" />
				<Trans i18nKey={'buttons.report'} />
			</Button>
		</Spin>
	);
};

export default Report;
