import React, {useState} from 'react';
import SensorBodyUnit from './SensorBodyUnit';

import GetSensorBodyTemperaturePath from './SensorBodyTemperature';
import GetSensorBodyHumidityPath from './SensorBodyHumidity';
import {SensorType} from '../../Common/Types/SensorType';
import {Sensor} from '../../Common/Types/Sensor';

export enum OptimizeFor {
	Speed,
	Quality,
}

function getViewBoxStyle(optimizeFor: OptimizeFor): React.CSSProperties {
	return {
		marginLeft: '12px',
		shapeRendering: optimizeFor === OptimizeFor.Quality ? 'geometricPrecision' : 'optimizeSpeed',
		textRendering: optimizeFor === OptimizeFor.Quality ? 'geometricPrecision' : 'optimizeSpeed',
		imageRendering: optimizeFor === OptimizeFor.Quality ? 'crisp-edges' : 'pixelated',
		fillRule: 'evenodd',
		clipRule: 'evenodd',
		cursor: 'pointer',
	};
}

function computeFill(sensor: Sensor) {
	const y0 = 6800; // Y value at lower limit
	const yBase = 4400; // Px between lower limit and upper limit
	const h0 = 15000;
	const minY = 8120; // the lowest possible
	const yMiddle = 4600; // for sensors without limit alarm
	const hMiddle = 10000; // for sensors without limit alarm

	if (sensor.LastMeasurement?.Value == null) {
		return {
			y: h0,
			h: 0,
			alarm: false,
		};
	}
	if (sensor.LimitLower == null || sensor.LimitUpper == null) {
		return {
			y: yMiddle,
			h: hMiddle,
			alarm: false,
		};
	}

	const max = sensor.LimitUpper;
	const min = sensor.LimitLower;
	const value = sensor.LastMeasurement.Value;

	const alarm = value < min || value > max;
	const percentage = (value - min) * (1 / (max - min));

	let y = y0 - yBase * percentage;

	if (y > minY) {
		y = minY;
	}

	let h = h0 - y;

	return {
		y: y,
		h: h,
		alarm: alarm,
	};
}

interface SensorBodyGraphicProps {
	onClick: any;
	optimizeFor: OptimizeFor;
	sensor: Sensor;
	sensorAlarmDeactivatedColor: string;
	sensorColors: any;
	sensorValueColor: string;
	sensorFillingColor: string;
	sensorStrokeColor: string;
}

const fontFamily = 'Verdana, sans-serif';

export const SensorBodyGraphic = (props: SensorBodyGraphicProps) => {
	const setFontColor = muted => {
		return muted ? props.sensorColors.inactive : props.sensorColors.active;
	};
	const [sensor] = useState(props.sensor);

	const [alarmDeactivatedColor] = useState(props.sensorAlarmDeactivatedColor);
	const [strokeColor] = useState(props.sensorStrokeColor);
	const [valueColor] = useState(props.sensorValueColor);
	const [colors] = useState(props.sensorColors);

	let getPathFunction;
	switch (sensor.SensorType) {
		case SensorType.Temperature: {
			getPathFunction = GetSensorBodyTemperaturePath;
			break;
		}
		case SensorType.Humidity: {
			getPathFunction = GetSensorBodyHumidityPath;
			break;
		}
	}
	let res = computeFill(sensor);
	const height = res.h;
	const y = res.y;

	let lastMeasurementTime = '';
	let lastMeasurementDate = '';

	if (sensor.LastMeasurement) {
		lastMeasurementTime = sensor.LastMeasurement.GetTimeString();
		lastMeasurementDate = sensor.LastMeasurement.GetDateString();
	}

	const fontColor = setFontColor(sensor.IsMuted);

	const path = getPathFunction({
		muted: sensor.IsMuted,
		isRetired: sensor.IsRetired,
		alarmDeactivatedColor,
		hasLimits: sensor.HasLimits,
		lastMeasurementOutsideLimits: sensor.LastMeasurementOutsideLimits(),
		fillingColor: props.sensorFillingColor,
		strokeColor,
		y,
		height,
		colors,
	});

	const hasValue = (value: number) => value || value == 0;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			onClick={props.onClick}
			viewBox="0 0 11680 9647"
			style={getViewBoxStyle(props.optimizeFor)}
		>
			{path}
			{sensor.IsRetired ? null : (
				<g transform="translate(0, 0)">
					{hasValue(sensor.LimitUpper) && (
						<text
							id="UpperLimitText"
							x="0"
							y="2200"
							fontSize="700"
							fontFamily={fontFamily}
							textRendering="geometricPrecision"
							fill={fontColor}
						>
							{sensor.LimitUpper} <SensorBodyUnit unit={sensor.OutUnitsId} />
						</text>
					)}

					{hasValue(sensor.LimitUpper) && (
						<polygon id="UpperLimitLine" fill="#b1b3b3" points="919,2363 7674,2363 7674,2459 919,2459 " />
					)}

					{hasValue(sensor.LimitLower) && (
						<text
							id="LowerLimitText"
							x="0"
							y="6550"
							fontSize="700"
							fontFamily={fontFamily}
							textRendering="geometricPrecision"
							fill={fontColor}
						>
							{sensor.LimitLower} <SensorBodyUnit unit={sensor.OutUnitsId} />
						</text>
					)}

					{hasValue(sensor.LimitLower) && (
						<polygon id="LowerLimitLine" fill="#b1b3b3" points="919,6705 7674,6705 7674,6802 919,6802 " />
					)}

					<text
						id="sensorValue"
						fontSize="800"
						fontWeight="bold"
						fontFamily={fontFamily}
						textRendering="geometricPrecision"
						y="4500"
						x="7800"
						fill={valueColor}
					>
						{sensor.Status !== 0
							? '--'
							: !sensor.LastMeasurement
							? 'no value'
							: hasValue(sensor.LastMeasurement.Value)
							? sensor.LastMeasurement.Value
							: '--'}
						{sensor.LastMeasurement && <SensorBodyUnit unit={sensor.OutUnitsId} />}
					</text>

					<text
						id="sensorValueLastMeasurementDate"
						fontSize="600"
						fontWeight="regular"
						fontFamily={fontFamily}
						textRendering="geometricPrecision"
						y="5400"
						x="7800"
						fill={valueColor}
					>
						{lastMeasurementDate}
					</text>

					<text
						id="sensorValueLastMeasurementTime"
						fontSize="600"
						fontWeight="regular"
						fontFamily={fontFamily}
						textRendering="geometricPrecision"
						y="6100"
						x="7800"
						fill={valueColor}
					>
						{lastMeasurementTime}
					</text>
				</g>
			)}
		</svg>
	);
};
