import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type IssueAlarmLostMeasurementProps = {
	sensorIssuesForm: any;
	show: boolean;
};

export default function IssueAlarmsLostMeasurementComponent(props: IssueAlarmLostMeasurementProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.lostMeasurementAlarm'} />
				</td>
				<td>
					{props.sensorIssuesForm.use_issue_alarms
						? props.sensorIssuesForm.lost_measurement_alarm
							? CONSTANTS.YES
							: CONSTANTS.NO
						: CONSTANTS.NOT_AVAILABLE}
				</td>
			</tr>
		)
	);
}
