import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {PlusSquareOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import React from 'react';
import {Access} from '../Infrastructure/Authorization/Components';
import {AccessPermissions, UserRoles} from '../Infrastructure/Authorization/Access';

type ButtonAccessRightProps = React.ComponentProps<typeof Button> & {
	ignoreAccessRight?: boolean;
};

export function AddSensorButton(props: ButtonAccessRightProps): React.JSX.Element {
	const [t] = useTranslation();
	const history = useHistory();

	const button = (
		<Button {...props} type={'primary'} size={'large'} onClick={() => history.push('/addSensor')} icon={<PlusSquareOutlined />}>
			{props.children ?? t('buttons.addSensor').toString()}
		</Button>
	);

	if (props.ignoreAccessRight) {
		return button;
	}

	return (
		<Access access={AccessPermissions.devicesview.child.datagrid.child.addSensor} roles={UserRoles.default}>
			{button}
		</Access>
	);
}
