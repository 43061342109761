import {MODULE_FAMILIES} from '../Constants/Module';
import SerialNumberInfo from '../SerialNumberInfo';

const findFirmwareVersionFromSerialNumber = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	let firmwareVersion = null;
	let deviceFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	switch (deviceFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			break;
		case MODULE_FAMILIES.LIBERO_G:
			firmwareVersion = parseInt(serialNumber[2]);
			break;
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			firmwareVersion = parseInt(serialNumber[1]);
			break;
		default:
			break;
	}
	return firmwareVersion;
};

export default findFirmwareVersionFromSerialNumber;
