import DeviceType from '../Infrastructure/Device/DeviceType';
import {DevUtils} from '../../common/util';
import {MultiContext} from '../Infrastructure/Authorization/Context/MultiContext';
import {UpdateDevicesViewSettings} from '../../common/services/WebserverServicePro/UserSettingsService';

/**
 * Formats credit card number to display only the last digits
 * @param card Card object
 * @returns {string} Masked credid card number
 */
const formatCreditCard = card => {
	return '**** **** **** ' + card.creditCardNumber + ' (valid until: ' + card.validUntil + ')';
};

/**
 * Used to generate a pdf (report)
 * @param b64Data Stream
 * @param filename File name
 * @param contentType Content type
 * @returns {Blob} Blob data
 */
const base64toFile = (b64Data, filename, contentType) => {
	const sliceSize = 512;
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, {type: contentType});
};

/**
 * Show affected sensors for alerts, etc.
 * @param sensors List of sensors
 * @returns {string} Affected sensors (name + serial)
 */
const handleAffectedSensors = sensors => {
	return sensors
		.map(s => {
			return s.name + ' (' + s.serial_number + ')';
		})
		.join(', ');
};

/**
 * Get affected sensors except the own/current one
 * @param sensors List of sensors
 * @param currentSensorSerialNumber Current sensor serial number
 * @returns {List} Affected sensors except the sensor with given serial number
 */
const getAffectedSensorsExcludeCurrent = (sensors, currentSensorSerialNumber) => {
	if (!sensors) return;
	return sensors.filter(s => s.serial_number !== currentSensorSerialNumber);
};

/**
 * Validate user password
 * @param value Password
 * @returns {string} Error message
 */
const validatePassword = value => {
	let password = value;
	let errorMessage;
	let capsCount, smallCount, numberCount, symbolCount;

	if (password.length === 0) {
		return '';
	}

	if (password.length < 8) {
		errorMessage = 'Password must be min 8 char';
	} else {
		capsCount = (password.match(/[A-Z]/g) || []).length;
		smallCount = (password.match(/[a-z]/g) || []).length;
		numberCount = (password.match(/[0-9]/g) || []).length;
		symbolCount = (password.match(/\W/g) || []).length;
		if (capsCount < 1) {
			errorMessage = 'Password must contain capital letter';
		} else if (smallCount < 1) {
			errorMessage = 'Password must contain small letter';
		} else if (numberCount < 1) {
			errorMessage = 'Password must contain number';
		} else if (symbolCount < 1) {
			errorMessage = 'Password must contain symbol';
		}
	}

	return errorMessage;
};

/**
 * Get user password strengh
 * @param password Password
 * @returns {string} Password strength
 */
const passwordMeasureStrength = password => {
	let score = 0;
	let passwordStrength;
	let regexPositive = ['[A-Z]', '[a-z]', '[0-9]', '\\W'];
	regexPositive.forEach((regex, _) => {
		if (new RegExp(regex).test(password)) {
			score += 1;
		}
	});

	switch (score) {
		case 0:
		case 1:
			passwordStrength = 'Weak';
			break;
		case 2:
		case 3:
			passwordStrength = 'Good';
			break;
		case 4:
		case 5:
			passwordStrength = 'Strong';
			break;
		default:
			passwordStrength = 'Weak';
	}

	return passwordStrength;
};

/**
 * Map values from object to string values
 * @param dataObject Data object
 * @returns {string[]} Array with string based object values
 */
const mapObjectValuesToString = dataObject => {
	return Object.values(dataObject).map(e => '' + e);
};

/**
 * Get string value of sensor unit (out units id)
 * @param outUnitsId Id fo sensor out unit
 * @returns {string} Sensor unit string value
 */
const getSensorUnit = outUnitsId => {
	switch (outUnitsId) {
		case 2:
			return '°C';
		case 3:
			return '°F';
		case 4:
			return '%';
		default:
			return '';
	}
};

/**
 * Set horizontal scrolling for ant table if device is mobile
 * @returns {boolean|{x: string}}
 */
const setTableScroll = () => {
	if (DeviceType.getDeviceType() === 1) {
		return false;
	} else {
		return {x: 'max-content'};
	}
};

/**
 * @deprecated Use DevUtils.IsDevEnv() instead
 * Check if env is dev
 * @returns {boolean}
 */
const isDevEnv = () => {
	return DevUtils.IsDevEnv();
};

const hideHubspotChat = hidden => {
	const elem = document.getElementById('hubspot-messages-iframe-container');
	if (!elem) return;
	const visibility = hidden ? 'hidden' : 'visible';
	const css = {visibility};
	Object.assign(elem.style, css);
};

const saveDevicesViewSetting = (context: React.ContextType<typeof MultiContext>, path: string) => {
	const setAccessContext = context.AccessDispatchContext;
	const AccessContext = context.AccessContext;
	if (!AccessContext || !setAccessContext) {
		return;
	}

	const userId = AccessContext.user.id;

	const updateData = {devicesViewSettings: {view: path}};

	const payload = {
		userId,
		updateData,
	};
	const devicesViewSettings = updateData.devicesViewSettings;
	AccessContext.user.user_settings
		? AccessContext.user.user_settings.devicesViewSettings
			? (AccessContext.user.user_settings.devicesViewSettings = devicesViewSettings)
			: (AccessContext.user.user_settings = {...AccessContext.user.user_settings, devicesViewSettings})
		: (AccessContext.user.user_settings = {devicesViewSettings});

	UpdateDevicesViewSettings(updateData.devicesViewSettings)
		.then(function (_) {
			try {
				setAccessContext(AccessContext);
			} catch (error) {
				console.log(error);
			}
		})
		.catch(function (error) {
			console.error(error);
		});
};

export const Shared = {
	formatCreditCard,
	base64toFile,
	handleAffectedSensors,
	getAffectedSensorsExcludeCurrent,
	validatePassword,
	passwordMeasureStrength,
	mapObjectValuesToString,
	getSensorUnit,
	saveDevicesViewSetting,
	setTableScroll,
	isDevEnv,
	hideHubspotChat,
};

export default Shared;
