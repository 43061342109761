import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type SensorBasicsFirmwareProps = {
	show: boolean;
	firmware: string;
};

export default function SensorBasicsFirmwareComponent(props: SensorBasicsFirmwareProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.firmware'} />
				</td>
				<td>{props.firmware.length > 0 ? props.firmware : CONSTANTS.NOT_AVAILABLE}</td>
			</tr>
		)
	);
}
