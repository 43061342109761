import React from 'react';
import LoadingTie from './LoadingTie';
import {Trans} from 'react-i18next';

const DeviationDurationTile = props => {
	return (
		<div className="card card-default" style={{height: '100%'}} id="deviationLimit">
			<div className="card-body text-center">
				{props.deviation_limit?.affectedLimit ? (
					<>
						<h4>
							<Trans i18nKey={'auditTrail.details.LimitZoneType'} />
						</h4>
						<br />
						<h3>{props.deviation_limit.affectedLimit}</h3>
						<br />
					</>
				) : (
					<LoadingTie />
				)}
			</div>
		</div>
	);
};

export default DeviationDurationTile;
