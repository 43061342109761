import request from '../../Infrastructure/Requests/Request';
import {DEVICE_EVENT} from '../../Shared/Constants/Events';
import pubsub from 'pubsub-js';
import {MeasurementNew} from '../../Common/Types/WebSocket/MeasurementNew';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
async function GetLastMeasurementValues(eventData: any): Promise<MeasurementNew[]> {
	let response = await request({
		url: '/last_measurement_values',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});

	return response.data.map(data => new MeasurementNew(data));
}

export const PublishLastMeasurementValues = (eventData: any): void => {
	GetLastMeasurementValues(eventData).then(measurements => {
		measurements.forEach(measurement => {
			pubsub.publish(DEVICE_EVENT.MEASUREMENT_NEW + '.' + measurement.SensorId, measurement);
		});
	});
};

const MeasurementService = {
	GetLastMeasurementValues,
	PublishLastMeasurementValues,
};

export default MeasurementService;
