import React, {Component} from 'react';
import {Select} from 'antd';
import {Label, Row, Col, FormGroup} from 'reactstrap';
const {Option} = Select;

type UnitSelectProps = {
	value: string | undefined;
	onChange: any;
	hasConfigurationTemplate: boolean;
	channelTypes: any;
	selectedValueIndex: any;
};

class UnitSelectState {
	onChange: any;
}

export class UnitSelectComponent extends Component<UnitSelectProps, UnitSelectState> {
	constructor(props: UnitSelectProps | Readonly<UnitSelectProps>) {
		super(props);
		this.state = {
			onChange: props.onChange,
		};
	}

	render() {
		return (
			<Row>
				<Col xl={3}>
					{this.props.hasConfigurationTemplate ? (
						<FormGroup>
							<Label>Temperature unit</Label>
							<div>
								<Select
									style={{width: '100%'}}
									defaultValue={this.props.value}
									id="selectUnit"
									value={this.props.value}
									onChange={this.state.onChange}
								>
									<Option id={'option0'} key={0} value={2}>
										°C
									</Option>
									<Option id={'option1'} key={1} value={3}>
										°F
									</Option>
								</Select>
							</div>
						</FormGroup>
					) : (
						<FormGroup>
							<Label for="selectUnit">Unit</Label>
							<div>
								<Select
									style={{width: '100%'}}
									defaultValue={this.props.value}
									id="selectUnit"
									value={this.props.value}
									onChange={this.state.onChange}
								>
									{this.props.channelTypes.map(type => {
										if (type.value_index === this.props.selectedValueIndex) {
											return type.units.map((unit, index) => {
												return (
													<Option id={index} key={index} value={unit.id}>
														{unit.token}
													</Option>
												);
											});
										}
										return null;
									})}
								</Select>
							</div>
						</FormGroup>
					)}
				</Col>
			</Row>
		);
	}
}
