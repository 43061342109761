import React from 'react';
import {Trans} from 'react-i18next';

type SensorBasicsLoginIntervalProps = {
	loggingInterval: number;
	show: boolean;
};

export default function SensorBasicsLoginIntervalComponent(props: SensorBasicsLoginIntervalProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.loggingInterval'} />
				</td>
				<td>
					{props.loggingInterval / 60} <Trans i18nKey={'sensorWizard.summary.minutes'} />
				</td>
			</tr>
		)
	);
}
