import 'ag-grid-enterprise';
import {ColDef} from 'ag-grid-enterprise';
import {nameof} from '../../../../common/util/Nameof';
import {Calibration} from '../../Model/Calibration';
import {CalibrationSensor} from '../../Model/CalibrationSensor';
import {CalibrationMeasurement} from '../../Model/CalibrationMeasurement';
import DateTimeUtils from '../../../Infrastructure/DateTime/DateTimeUtils';
import {CONSTANTS} from '../../../Shared/Constants/Constants';
import {ConvertedMeasurementWithUnitString} from '../../../../common/util/WithUnitString';
import {CALIBRATION_STATES} from '../../../Shared/Constants/CalibrationStates';

export const ColumnDefinitions: ColDef[] = [
	checkboxField(nameof<Calibration>('CalibrationID')),
	calibrationStateField(nameof<Calibration>('State')),
	unformattedField(`${nameof<Calibration>('Sensor')}.${nameof<CalibrationSensor>('ID')}`),
	unformattedField(`${nameof<Calibration>('Sensor')}.${nameof<CalibrationSensor>('CustomerID')}`),
	unformattedField(`${nameof<Calibration>('Sensor')}.${nameof<CalibrationSensor>('ModuleID')}`),
	datetimeField(nameof<Calibration>('Start')),
	datetimeField(nameof<Calibration>('Stop')),
	unformattedField(nameof<Calibration>('Description')),
	datetimeField(`${nameof<Calibration>('LastMeasurement')}.${nameof<CalibrationMeasurement>('Date')}`),
	measurementValueField(`${nameof<Calibration>('LastMeasurement')}.${nameof<CalibrationMeasurement>('Value')}`),
];

function checkboxField(field: string): ColDef {
	return {
		field: field,
		headerCheckboxSelection: true,
		checkboxSelection: true,
		showDisabledCheckboxes: true,
	};
}

function unformattedField(field: string): ColDef {
	return {field: field};
}

function calibrationStateField(field: string): ColDef {
	return {
		field: field,
		valueFormatter: data => (data.value ? CALIBRATION_STATES[data.value] : CONSTANTS.EMPTY),
	};
}

function measurementValueField(field: string): ColDef {
	return {
		field: field,
		valueFormatter: data =>
			data.value
				? ConvertedMeasurementWithUnitString(
						data.value,
						data.data[nameof<Calibration>('LastMeasurement')][nameof<CalibrationMeasurement>('UnitID')]
				  )
				: CONSTANTS.EMPTY,
	};
}

function datetimeField(field: string): ColDef {
	return {
		field: field,
		filter: 'agDateColumnFilter',
		valueFormatter: data => (data.value ? DateTimeUtils.setDateTimeWithOffset_date_dep(data.value) : CONSTANTS.EMPTY),
	};
}
