import React, {useState, useEffect} from 'react';
import {Label, Input, Row, Col, FormGroup} from 'reactstrap';
import {Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} from 'react-accessible-accordion';
import '../../../styles/app/extras/multizoneTable.css';
import {useTranslation} from 'react-i18next';
import SensorService from '../../Dashboard/Sensor/SensorService';
import {Alert} from 'antd';
import {MODULE_FAMILIES} from '../Constants/Module';
import {Table as AntTable} from 'antd';

import {Button, Form, Popconfirm, Checkbox, InputNumber, Select, Tooltip} from 'antd';

import {PlusOutlined, MinusOutlined, UndoOutlined} from '@ant-design/icons';

const LimitCell = ({cellenabled, zone, type, unit, limitAlarmsForm, datasource, handleLimitChange, index}) => {
	const FormItem = Form.Item;
	let limitZone = getZone(datasource.limits, zone);
	let defaultValue = getZone(limitAlarmsForm, zone);

	return (
		<div>
			<FormItem
				key={zone + 'limit'}
				className={pickLevelCalss(zone)}
				size="small"
				hasFeedback={true}
				validateStatus={limitZone.validateStatus === '' ? '' : 'error'}
			>
				<InputNumber
					data-validate={cellenabled === true ? JSON.stringify(['required', 'zone']) : null}
					data-param={cellenabled === true ? (limitZone.validateStatus === '' ? '' : 'error') : ''}
					name={type === 'alarm' ? 'AlarmingTable' + zone + 'limit' : 'WarningTable' + zone + 'limit'}
					key={zone + 'limitInput' + index + unit}
					disabled={!cellenabled}
					size="medium"
					onChange={e => changeLimitAlarmForm(e, type, 'limit', zone, limitAlarmsForm, handleLimitChange)}
					formatter={unit !== undefined ? value => `${value + unit}` : null}
					parser={unit !== undefined ? value => value.replace(unit, '') : null}
					step={0.1}
					min={-999.9}
					max={999.9}
					value={defaultValue === undefined ? 0 : defaultValue.limit}
					type="number"
				/>
			</FormItem>
		</div>
	);
};

const DelayCell = ({cellenabled, zone, type, limitAlarmsForm, datasource, handleLimitChange, interval, index}) => {
	if (zone !== 'G') {
		let defaultValue = getZone(limitAlarmsForm, zone);

		return (
			<div>
				<InputNumber
					data-validate={JSON.stringify(['required', 'number'])}
					name={type === 'alarm' ? 'AlarmingTable' + zone + 'Delay' : 'WarningTable' + zone + 'Delay'}
					key={zone + 'delay' + index}
					disabled={!cellenabled || (zone === 'L1' && datasource.coupleEnabled)}
					size="medium"
					onChange={e => changeLimitAlarmForm(e, type, 'delay', zone, limitAlarmsForm, handleLimitChange)}
					step={1}
					min={0}
					max={150}
					value={defaultValue === undefined ? 0 : defaultValue.delay}
					precision={0}
					type="number"
				/>{' '}
				{interval !== undefined ? calcInterval(zone, limitAlarmsForm, interval) + ' min' : null}
			</div>
		);
	} else {
		return type === 'warning' ? <div></div> : <div>No alarm</div>;
	}
};

const EventCell = ({cellenabled, zone, type, limitAlarmsForm, datasource, handleLimitChange, index}) => {
	const {Option} = Select;
	let defaultValue = getZone(limitAlarmsForm, zone);

	return (
		<div>
			<Select
				key={zone + 'event' + index}
				disabled={!cellenabled || (zone === 'L1' && datasource.coupleEnabled)}
				size="medium"
				style={{width: 120}}
				onChange={e => changeLimitAlarmForm(e, type, 'event', zone, limitAlarmsForm, handleLimitChange)}
				value={defaultValue === undefined ? 'single' : defaultValue.event}
			>
				<Option value="cumulative" disabled={true}>
					Cumulative
				</Option>
				<Option value="single">Single</Option>
			</Select>
		</div>
	);
};

const ExcursionCell = ({cellenabled, zone, type, limitAlarmsForm, datasource, handleLimitChange, index}) => {
	let defaultValue = getZone(limitAlarmsForm, zone);

	const {Option} = Select;
	return (
		<div>
			<Select
				key={zone + 'excursion' + index}
				disabled={!cellenabled || (zone === 'L1' && datasource.coupleEnabled)}
				size="medium"
				style={{width: 120}}
				onChange={e => changeLimitAlarmForm(e, type, 'excursions', zone, limitAlarmsForm, handleLimitChange)}
				value={defaultValue === undefined ? 'unlim' : defaultValue.excursions}
			>
				<Option size="medium" value="unlim">
					unlim.
				</Option>
				<Option size="medium" value="0">
					0
				</Option>
				<Option size="medium" value="1">
					1
				</Option>
				<Option size="medium" value="2">
					2
				</Option>
				<Option size="medium" value="3">
					3
				</Option>
				<Option size="medium" value="4">
					4
				</Option>
				<Option size="medium" value="5">
					5
				</Option>
				<Option size="medium" value="6">
					6
				</Option>
				<Option size="medium" value="7">
					7
				</Option>
				<Option size="medium" value="8">
					8
				</Option>
				<Option size="medium" value="9">
					9
				</Option>
				<Option size="medium" value="10">
					10
				</Option>
				<Option size="medium" value="15">
					15
				</Option>
				<Option size="medium" value="20">
					20
				</Option>
				<Option size="medium" value="25">
					25
				</Option>
				<Option size="medium" value="30">
					30
				</Option>
				<Option size="medium" value="40">
					40
				</Option>
				<Option size="medium" value="50">
					50
				</Option>
			</Select>
		</div>
	);
};

const EmptyCell = (zone, col) => {
	return <div key={zone + col.dataindex}></div>;
};

const GRow = ({col, zone}) => {
	if (col.dataSource === 'delays') {
		return <div key={zone + col.dataindex}>No alarm</div>;
	} else if (col.dataindex === 'zone') {
		return <div key={zone + col.dataindex}>{zone}</div>;
	}
	return <EmptyCell zone={zone} col={col} />;
};

const calcInterval = (zone, datasource, interval) => {
	let foundZone = getZone(datasource, zone);

	return foundZone !== undefined ? (foundZone.delay * interval) / 60 : 0;
};

const CheckboxCell = ({cellenabled, zone, limitAlarmsForm, datasource, setDatasource, handleLimitChange}) => {
	let enabled = getZone(datasource.limits, zone).enabled;
	if (enabled) {
		return (
			<div>
				<Popconfirm
					placement="top"
					title={'Disable ' + zone + '?'}
					okText={'Yes'}
					cancelText={'No'}
					onConfirm={() => onCheckboxCellChange(zone, limitAlarmsForm, datasource, cellenabled, setDatasource, handleLimitChange)}
				>
					<Checkbox
						name={zone + 'WarningEnabled'}
						disabled={!cellenabled}
						//onChange={() => onCheckboxCellChange(zone, limitAlarmsForm, datasource, cellenabled, setDatasource, handleLimitChange)}
						checked={enabled}
						size="small"
					/>
				</Popconfirm>
			</div>
		);
	} else {
		return (
			<div>
				<Checkbox
					disabled={!cellenabled}
					onChange={() => onCheckboxCellChange(zone, limitAlarmsForm, datasource, cellenabled, setDatasource, handleLimitChange)}
					checked={enabled}
					size="small"
				/>
			</div>
		);
	}
};

const onCheckboxCellChange = (zone, limitAlarmsForm, datasource, cellenabled, setDatasource, handleLimitChange) => {
	let checkboxZone = getZone(datasource.limits, zone);
	let newDatasource = JSON.parse(JSON.stringify(datasource));
	let newDatasourceLimit = [...datasource.limits];
	let value = !newDatasourceLimit[newDatasourceLimit.indexOf(checkboxZone)].enabled;

	newDatasourceLimit[newDatasourceLimit.indexOf(checkboxZone)].enabled = value;

	newDatasource.limits = newDatasourceLimit;

	if (value) {
		addZone('warning', zone, zone.includes('H') ? 'H' : 'L', limitAlarmsForm, handleLimitChange);
	} else {
		newDatasourceLimit[newDatasourceLimit.indexOf(checkboxZone)].validateStatus = '';
		removeZone('warning', zone, limitAlarmsForm, handleLimitChange);
	}

	setDatasource(newDatasource);
	//validate(datasource, limitAlarmsForm, setDatasource, cellenabled);
};

const UsedCell = ({name}) => {
	return <Label>{name}</Label>;
};

const selectBgColor = (name, type) => {
	return name + type;
};

const alarmingColumns = (
	columns,
	limitAlarmChange,
	alarmdatasource,
	setAlarmDatasource,
	limitAlarmsForm,
	cellenabled,
	unit,
	interval,
	maxUnlockedLower,
	maxUnlockedUpper,
	index
) => {
	return columns.map(col => {
		return {
			...col,
			render: record => {
				if (record.key === 'addH' || record.key === 'addL') {
					return (
						<EnableCell
							index={index}
							cellenabled={cellenabled}
							col={col}
							zone={record.key}
							limitAlarmsForm={limitAlarmsForm}
							datasource={alarmdatasource}
							setDatasource={setAlarmDatasource}
							handleLimitChange={limitAlarmChange}
							maxUnlockedLower={maxUnlockedLower}
							maxUnlockedUpper={maxUnlockedUpper}
						/>
					);
				}
				if (record.key === 'G') {
					return <GRow col={col} zone={record.key} />;
				} else if (col.dataindex === 'zone') {
					return <UsedCell name={record.key} />;
				} else if (col.dataindex === 'limit') {
					return (
						<LimitCell
							index={index}
							cellenabled={cellenabled}
							zone={record.key}
							type={'alarm'}
							limitAlarmsForm={limitAlarmsForm}
							datasource={alarmdatasource}
							setDatasource={setAlarmDatasource}
							handleLimitChange={limitAlarmChange}
							unit={unit}
						/>
					);
				} else if (col.dataindex === 'delays') {
					return (
						<DelayCell
							index={index}
							cellenabled={cellenabled}
							zone={record.key}
							type={'alarm'}
							limitAlarmsForm={limitAlarmsForm}
							datasource={alarmdatasource}
							setDatasource={setAlarmDatasource}
							handleLimitChange={limitAlarmChange}
							interval={interval}
						/>
					);
				} else if (col.dataindex === 'event') {
					return (
						<EventCell
							index={index}
							cellenabled={cellenabled}
							zone={record.key}
							type={'alarm'}
							limitAlarmsForm={limitAlarmsForm}
							datasource={alarmdatasource}
							setDatasource={setAlarmDatasource}
							handleLimitChange={limitAlarmChange}
						/>
					);
				} else if (col.dataindex === 'excursions') {
					return (
						<ExcursionCell
							index={index}
							cellenabled={cellenabled}
							zone={record.key}
							type={'alarm'}
							limitAlarmsForm={limitAlarmsForm}
							datasource={alarmdatasource}
							setDatasource={setAlarmDatasource}
							handleLimitChange={limitAlarmChange}
						/>
					);
				} else {
					return <EmptyCell zone={record.key} col={col} />;
				}
			},
		};
	});
};

const getZone = (datasource, zone) => {
	return datasource.find(element => {
		return element.key === zone;
	});
};

const enableZone = (zone, datasource, setDatasource, limitAlarmsForm, handleLimitChange) => {
	let newZone;

	if (zone === 'addH') {
		let maxZone = getMaxZone(datasource.limits);
		newZone = getZone(datasource.limits, maxZone.key === 'G' ? 'H1' : maxZone.next);
		setMaxZone(newZone, setDatasource, datasource, true);
		addZone('alarm', newZone.key, 'H', limitAlarmsForm, handleLimitChange);
	} else if (zone === 'addL') {
		let minZone = getMinZone(datasource.limits);
		newZone = getZone(datasource.limits, minZone.key === 'G' ? 'L1' : minZone.next);
		setMinZone(newZone, setDatasource, datasource, true);
		addZone('alarm', newZone.key, 'L', limitAlarmsForm, handleLimitChange);
	}
};

const disableZone = (zone, datasource, setDatasource, limitAlarmsForm, handleLimitChange) => {
	let newZone;

	if (zone === 'addH') {
		let maxZone = getMaxZone(datasource.limits);
		newZone = getZone(datasource.limits, maxZone.key === 'G' ? 'H1' : maxZone.previous);
		setMaxZone(newZone, setDatasource, datasource, false);
		removeZone('alarm', maxZone.key, limitAlarmsForm, handleLimitChange);
	} else if (zone === 'addL') {
		let minZone = getMinZone(datasource.limits);
		newZone = getZone(datasource.limits, minZone.previous);

		setMinZone(newZone, setDatasource, datasource, false);
		removeZone('alarm', minZone.key, limitAlarmsForm, handleLimitChange);
	}
};

const checkIfMin = (zone, limitAlarmsForm) => {
	let testt = limitAlarmsForm.find(element => {
		return element.key === zone;
	});

	return limitAlarmsForm.indexOf(testt) === limitAlarmsForm.length - 1 ? true : false;
};

const checkIfMax = (zone, limitAlarmsForm) => {
	return limitAlarmsForm.indexOf(
		limitAlarmsForm.find(element => {
			return element.key === zone;
		})
	) === 0
		? true
		: false;
};

const checkIfGMax = limitAlarmsForm => {
	return limitAlarmsForm.find(element => element.key.includes('H')) === undefined ? true : false;
};
const checkIfGMin = limitAlarmsForm => {
	return limitAlarmsForm.find(element => element.key.includes('L')) === undefined ? true : false;
};

const validate = (datasource, valuesource, setDatasource, cellenabled) => {
	let newDatasource = JSON.parse(JSON.stringify(datasource));
	if (cellenabled) {
		valuesource.forEach(zone => {
			let tableZone = getZone(newDatasource.limits, zone.key);
			let prevZone =
				tableZone.previous === 'G' ? getBorderPreviousZone(zone.key, valuesource) : getZone(valuesource, tableZone.previous);

			if (tableZone.enabled === false || prevZone === undefined) {
				setSuccess(tableZone, newDatasource, setDatasource);
			} else if (zone.limit === null || zone.limit === '') {
				setError(tableZone, newDatasource, 'limitNull');
			} else if (prevZone.limit === null || prevZone.limit === '') {
				setError(tableZone, newDatasource, 'prevZonelimitNull');
			} else if (zone.key.includes('H') ? !checkIfHigher(prevZone, zone) : !checkIfLower(prevZone, zone)) {
				setError(tableZone, newDatasource, 'limit');
			} else {
				setSuccess(tableZone, newDatasource, setDatasource);
			}
		});
	} else {
		newDatasource.limits.forEach(zone => {
			setSuccess(zone, newDatasource, setDatasource);
		});
	}

	setDatasource(newDatasource);
};

const getBorderPreviousZone = (zone, datasource) => {
	if (zone.includes('H')) {
		return getZone(datasource, 'L1');
	} else if (zone.includes('L')) {
		return getZone(datasource, 'H1');
	}
};

const checkIfHigher = (prevZone, zone) => {
	if (prevZone.limit === null || prevZone.limit === '' || zone.limit === null || zone.limit === '') {
		return true;
	}
	return zone.limit > prevZone.limit;
};

const checkIfLower = (prevZone, zone) => {
	if (prevZone.limit === null || prevZone.limit === '' || zone.limit === null || zone.limit === '') {
		return true;
	}
	return zone.limit < prevZone.limit;
};

const setSuccess = (zone, datasource) => {
	datasource.limits[datasource.limits.indexOf(zone)].validateStatus = '';
};

const setError = (zone, datasource, type) => {
	datasource.limits[datasource.limits.indexOf(zone)].validateStatus = type + 'Error';
};

const ZoneErrors = (limits, type) => {
	let errorMessages = [];
	let message = '';
	limits.limits.forEach(zone => {
		message = checkIfError(zone, limits.limits);

		if (message !== undefined) {
			errorMessages.push(message);
		}
	});

	return errorMessages.length === 0 ? null : <Alert key={type} size="small" description={errorMessages} type="error" banner />;
};

const checkIfError = (zone, limits) => {
	let prevZone = zone.previous === 'G' ? getBorderPreviousZone(zone.key, limits) : getZone(limits, zone.previous);

	if (zone.validateStatus === 'limitError') {
		return getlimitErrorMessage(zone, prevZone);
	} else if (zone.validateStatus === 'prevZonelimitNullError') {
		return getPreZoneEmptyMessage(zone, prevZone);
	} else if (zone.validateStatus === 'limitNullError') {
		return getZoneEmptyMessage(zone);
	}
};

const getlimitErrorMessage = (zone, prevZone) => {
	if (zone.key.includes('H')) {
		return (
			<p key={zone.key + 'limitError'}>
				{zone.key} has to be higher than {prevZone.key}
			</p>
		);
	} else if (zone.key.includes('L')) {
		return (
			<p key={zone.key + 'limitError'}>
				{zone.key} has to be lower than {prevZone.key}
			</p>
		);
	}
};

const getPreZoneEmptyMessage = (zone, prevZone) => {
	if (zone.key.includes('H')) {
		return (
			<p key={zone.key + 'EmptyError'}>
				{zone.key} has to be higher than {prevZone.key}
			</p>
		);
	} else if (zone.key.includes('L')) {
		return (
			<p key={zone.key + 'EmptyError'}>
				{zone.key} has to be lower than {prevZone.key}
			</p>
		);
	}
};

const getZoneEmptyMessage = zone => {
	return <p key={zone.key + 'ZoneEmptyError'}>{zone.key} needs a value</p>;
};

const setZonesEnabled = (limitalarms, limits) => {
	limits.map(limit => {
		if (
			limitalarms.find(valueLimit => {
				return valueLimit.key === limit.key;
			}) !== undefined
		) {
			limit.enabled = true;
			limit.key.includes('H')
				? (limit.isMax = checkIfMax(limit.key, limitalarms))
				: (limit.isMin = checkIfMin(limit.key, limitalarms));
		} else if (limit.key !== 'G' && limit.key !== 'addH' && limit.key !== 'addL') {
			limit.enabled = false;
			limit.key.includes('H') ? (limit.isMax = false) : (limit.isMin = false);
			limit.validateStatus = '';
		} else if (limit.key === 'G') {
			limit.isMax = checkIfGMax(limitalarms);
			limit.isMin = checkIfGMin(limitalarms);
		}
		return limit;
	});

	return limits;
	//setDatasource(newDatasource);
};

const AlarmingTable = ({
	limitAlarms,
	limitAlarmChange,
	cellenabled,
	coupleEnabled,
	unit,
	interval,
	maxUnlockedLower,
	maxUnlockedUpper,
	index,
}) => {
	useEffect(() => {
		validate(alarmdatasource, limitAlarmsForm, setAlarmDatasource, cellenabled);
	}, [cellenabled]);

	useEffect(() => {
		setLimitAlarmsForm(limitAlarms);
		let enabledZones = setZonesEnabled(limitAlarms, [...alarmdatasource.limits]);
		let newAlarmdatasource = JSON.parse(JSON.stringify(alarmdatasource));

		newAlarmdatasource.limits = enabledZones;

		setAlarmDatasource(newAlarmdatasource);
		setDatasource(alarmdatasource.limits.filter(source => source.enabled === true));
		validate(alarmdatasource, limitAlarms, setAlarmDatasource, cellenabled);
	}, [limitAlarms]);

	useEffect(() => {
		setSensorUnit(unit);
	}, [unit]);

	const [sensorUnit, setSensorUnit] = useState(unit);
	const [dataSource, setDatasource] = useState();
	const [limitAlarmsForm, setLimitAlarmsForm] = useState(limitAlarms);
	const [alarmdatasource, setAlarmDatasource] = useState({
		coupleEnabled: false,
		limits: [
			{
				key: 'addH',
				enabled: true,
				validateStatus: '',
			},
			{
				key: 'H4',
				enabled: false,
				validateStatus: '',
				previous: 'H3',
				next: 'end',
				isMax: false,
			},
			{
				key: 'H3',
				enabled: false,
				validateStatus: '',
				previous: 'H2',
				next: 'H4',
				isMax: false,
			},
			{
				key: 'H2',
				enabled: false,
				validateStatus: '',
				previous: 'H1',
				next: 'H3',
				isMax: false,
			},
			{
				key: 'H1',
				enabled: false,
				validateStatus: '',
				previous: 'G',
				next: 'H2',
				isMax: false,
			},
			{
				key: 'G',
				enabled: true,
				validateStatus: '',
				isMax: false,
				isMin: false,
			},
			{
				key: 'L1',
				enabled: false,
				validateStatus: '',
				previous: 'G',
				next: 'L2',
				isMin: false,
			},
			{
				key: 'L2',
				enabled: false,
				validateStatus: '',
				previous: 'L1',
				next: 'L3',
				isMin: false,
			},
			{
				key: 'L3',
				enabled: false,
				validateStatus: '',
				previous: 'L2',
				next: 'end',
				isMin: false,
			},
			{
				key: 'addL',
				enabled: true,
				validateStatus: '',
			},
		],
	});

	const alarmcolumns = [
		{
			title: 'Zone',
			dataindex: 'zone',
		},
		{
			title: 'Limit',
			dataindex: 'limit',
		},
		{
			title: 'Delay (consecutive excursions)',
			dataindex: 'delays',
		},
		// {
		//   title: 'Event',
		//   dataindex: 'event'
		// },
		// {
		//   title: 'Excursions',
		//   dataindex: 'excursions'
		// }
	];

	return (
		<div>
			<AntTable
				key="alarmingTable"
				size="small"
				className="multizoneTable table-light"
				rowClassName={record => {
					return selectBgColor(record.key, 'limit');
				}}
				pagination={false}
				columns={alarmingColumns(
					alarmcolumns,
					limitAlarmChange,
					alarmdatasource,
					setAlarmDatasource,
					limitAlarmsForm,
					cellenabled,
					sensorUnit,
					interval,
					maxUnlockedUpper,
					maxUnlockedLower,
					index
				)}
				dataSource={dataSource}
			/>
			{/* <Checkbox onChange={() => onCoupleEnable()}>H1 and L1 coupled</Checkbox> */}
			<br />
			<ZoneErrors key="alarmingErrors" limits={alarmdatasource.limits} type={'alarm'} />
		</div>
	);
};

const getMaxZone = datasource => {
	return datasource.find(element => {
		return element.isMax === true;
	});
};

const getMinZone = datasource => {
	return datasource.find(element => {
		return element.isMin === true;
	});
};

const setMaxZone = (zone, setDatasource, datasource, enabled) => {
	let newDatasource = JSON.parse(JSON.stringify(datasource));
	let limitAlarms = newDatasource.limits;
	let newZone = getZone(limitAlarms, zone.key);

	let oldMax = getMaxZone(limitAlarms);
	oldMax.isMax = false;
	oldMax.enabled = enabled;

	if (oldMax.key !== 'G') {
		oldMax.validateStatus = '';
	}

	newZone.isMax = true;
	newZone.enabled = true;

	limitAlarms[limitAlarms.indexOf(oldMax)] = oldMax;
	limitAlarms[limitAlarms.indexOf(newZone)] = newZone;
	newDatasource.limits = limitAlarms;

	setDatasource(newDatasource);
};

const setMinZone = (zone, setDatasource, datasource, enabled) => {
	let newDatasource = JSON.parse(JSON.stringify(datasource));
	let limitAlarms = newDatasource.limits;
	let newZone = getZone(limitAlarms, zone.key);

	let oldMin = getMinZone(newDatasource.limits);
	oldMin.isMin = false;
	oldMin.enabled = enabled;

	if (oldMin.key !== 'G') {
		oldMin.validateStatus = '';
	}

	newZone.isMin = true;
	newZone.enabled = true;

	limitAlarms[limitAlarms.indexOf(oldMin)] = oldMin;
	limitAlarms[limitAlarms.indexOf(zone)] = newZone;
	newDatasource.limit_alarms = limitAlarms;

	setDatasource(newDatasource);
};

const checkPlusTypeEnabled = (zone, datasource, maxUnlockedUpper, maxUnlockedLower) => {
	let maxZone = getMaxZone(datasource.limits);
	let minZone = getMinZone(datasource.limits);

	if (
		(zone === 'addH' && maxZone.next !== 'end' && !maxZone.key.includes(maxUnlockedUpper.toString())) ||
		(zone === 'addL' && minZone.next !== 'end' && !minZone.key.includes(maxUnlockedLower.toString()))
	) {
		return true;
	}
	return false;
};

const checkMinusTypeEnabled = (zone, datasource) => {
	if (
		(zone === 'addL' && getMinZone(datasource.limits).key !== 'L1') ||
		(zone === 'addH' && getMaxZone(datasource.limits).key !== 'H1')
	) {
		return true;
	}
	return false;
};

const MinusButton = ({cellenabled, zone, datasource, setDatasource, limitAlarmsForm, handleLimitChange}) => {
	const {t} = useTranslation();

	return (
		<Popconfirm
			placement="top"
			title={`Remove ${
				cellenabled ? (zone === 'addH' ? getMaxZone(datasource.limits).key : getMinZone(datasource.limits).key) : ''
			}?`}
			okText={'Yes'}
			cancelText={'No'}
			onConfirm={() => disableZone(zone, datasource, setDatasource, limitAlarmsForm, handleLimitChange)}
			disabled={!cellenabled || !checkMinusTypeEnabled(zone, datasource)}
		>
			<Tooltip title={t('settings.limitProfiles.remove')}>
				<Button
					id={zone == 'addH' ? 'subUpperLimit' : 'subLowerLimit'}
					type="primary"
					size="small"
					disabled={!cellenabled || !checkMinusTypeEnabled(zone, datasource)}
					icon={<MinusOutlined />}
					style={{marginLeft: '1px'}}
				/>
			</Tooltip>
		</Popconfirm>
	);
};

const PlusButton = ({
	cellenabled,
	zone,
	datasource,
	setDatasource,
	limitAlarmsForm,
	handleLimitChange,
	maxUnlockedLower,
	maxUnlockedUpper,
}) => {
	const {t} = useTranslation();

	return (
		<Tooltip title={t('settings.limitProfiles.add')}>
			<Button
				id={zone == 'addH' ? 'addUpperLimit' : 'addLowerLimit'}
				disabled={!cellenabled || !checkPlusTypeEnabled(zone, datasource, maxUnlockedLower, maxUnlockedUpper)}
				type="primary"
				size="small"
				icon={<PlusOutlined />}
				onClick={() => enableZone(zone, datasource, setDatasource, limitAlarmsForm, handleLimitChange)}
				style={{marginRight: '1px'}}
			/>
		</Tooltip>
	);
};

const EnableCell = ({
	cellenabled,
	col,
	zone,
	limitAlarmsForm,
	datasource,
	setDatasource,
	handleLimitChange,
	maxUnlockedUpper,
	maxUnlockedLower,
}) => {
	if (col.dataindex === 'zone') {
		return (
			<div>
				{maxUnlockedUpper > 1 ? (
					<PlusButton
						cellenabled={cellenabled}
						zone={zone}
						limitAlarmsForm={limitAlarmsForm}
						datasource={datasource}
						setDatasource={setDatasource}
						handleLimitChange={handleLimitChange}
						maxUnlockedLower={maxUnlockedLower}
						maxUnlockedUpper={maxUnlockedUpper}
					/>
				) : null}
				{maxUnlockedLower > 1 ? (
					<MinusButton
						cellenabled={cellenabled}
						zone={zone}
						limitAlarmsForm={limitAlarmsForm}
						datasource={datasource}
						setDatasource={setDatasource}
						handleLimitChange={handleLimitChange}
					/>
				) : null}
			</div>
		);
	} else {
		return <EmptyCell zone={zone} col={col} />;
	}
};

const changeLimitAlarmForm = (e, type, cellType, zone, limitAlarmsForm, handleLimitChange) => {
	let newlimitAlarmsForm = [...limitAlarmsForm];

	let changeZone = getZone(newlimitAlarmsForm, zone);

	if (cellType === 'limit') {
		changeZone.limit = parseFloat(e);
	} else if (cellType === 'delay') {
		changeZone.delay = e;
	} else if (cellType === 'excursions') {
		changeZone.excursions = e;
	} else if (cellType === 'event') {
		changeZone.event = e;
	}

	newlimitAlarmsForm[newlimitAlarmsForm.indexOf(changeZone)] = changeZone;

	handleLimitChange(newlimitAlarmsForm, type);
};

const addZone = (type, zone, area, limitAlarmsForm, handleLimitChange) => {
	let newlimitAlarmsForm = [...limitAlarmsForm];

	if (area === 'H') {
		newlimitAlarmsForm = [getDefaultZone(zone), ...newlimitAlarmsForm];
	} else if (area === 'L') {
		newlimitAlarmsForm = [...newlimitAlarmsForm, getDefaultZone(zone)];
	}

	handleLimitChange(newlimitAlarmsForm, type);
};

const removeZone = (type, zone, limitAlarmsForm, handleLimitChange) => {
	let newlimitAlarmsForm = [...limitAlarmsForm];
	newlimitAlarmsForm = newlimitAlarmsForm.filter(obj => obj.key !== zone);
	handleLimitChange(newlimitAlarmsForm, type);
};

const getDefaultZone = name => {
	return {
		key: name,
		limit: 0,
		delay: 0,
		event: 'single',
		excursions: 'unlim',
	};
};

const pickLevelCalss = name => {
	if (name.includes('H')) {
		return 'multiZoneLimitHigh';
	} else if (name.includes('L')) {
		return 'multiZoneLimitLow';
	}
};

const warningColumns = (
	columns,
	limitAlarmChange,
	warningdatasource,
	setWarningDatasource,
	limitAlarmsForm,
	cellenabled,
	unit,
	interval
) => {
	return columns.map(col => {
		return {
			...col,
			render: record => {
				if (record.key === 'G') {
					return <GRow col={col} zone={record.key} />;
				} else if (col.dataindex === 'zone') {
					return <UsedCell name={record.key} />;
				} else if (col.dataindex === 'limit') {
					return (
						<LimitCell
							cellenabled={cellenabled ? getZone(warningdatasource.limits, record.key).enabled : false}
							zone={record.key}
							type={'warning'}
							limitAlarmsForm={limitAlarmsForm}
							datasource={warningdatasource}
							setDatasource={setWarningDatasource}
							handleLimitChange={limitAlarmChange}
							unit={unit}
						/>
					);
				} else if (col.dataindex === 'delays') {
					return (
						<DelayCell
							cellenabled={cellenabled ? getZone(warningdatasource.limits, record.key).enabled : false}
							zone={record.key}
							type={'warning'}
							limitAlarmsForm={limitAlarmsForm}
							datasource={warningdatasource}
							handleLimitChange={limitAlarmChange}
							interval={interval}
						/>
					);
				} else if (col.dataindex === 'checkbox') {
					return (
						<CheckboxCell
							cellenabled={cellenabled}
							zone={record.key}
							limitAlarmsForm={limitAlarmsForm}
							datasource={warningdatasource}
							handleLimitChange={limitAlarmChange}
							setDatasource={setWarningDatasource}
						/>
					);
				} else {
					return <EmptyCell zone={record.key} col={col} />;
				}
			},
		};
	});
};

const WarningTable = ({limitAlarms, limitAlarmChange, cellenabled, unit, interval}) => {
	useEffect(() => {
		validate(warningdatasource, limitAlarmsForm, setWarningDatasource, cellenabled);
	}, [cellenabled]);

	useEffect(() => {
		setLimitAlarmsForm(limitAlarms);
		let enabledZones = setZonesEnabled(limitAlarms, [...warningdatasource.limits]);
		let newWarningDatasource = JSON.parse(JSON.stringify(warningdatasource));

		newWarningDatasource.limits = enabledZones;

		setWarningDatasource(newWarningDatasource);
		validate(warningdatasource, limitAlarms, setWarningDatasource, cellenabled);
	}, [limitAlarms]);

	const [limitAlarmsForm, setLimitAlarmsForm] = useState(limitAlarms);
	const [warningdatasource, setWarningDatasource] = useState({
		limits: [
			{
				key: 'H1',
				enabled: false,
				next: 'end',
				previous: 'G',
				validateStatus: '',
			},
			{
				key: 'G',
				enabled: true,
			},
			{
				key: 'L1',
				enabled: false,
				next: 'end',
				previous: 'G',
				validateStatus: '',
			},
		],
	});

	const warncolumns = [
		{
			title: 'Zone',
			dataindex: 'zone',
		},
		{
			title: 'Warning',
			dataindex: 'checkbox',
		},
		{
			title: 'Limit',
			dataindex: 'limit',
		},
		{
			title: 'Delays',
			dataindex: 'delays',
		},
	];

	return (
		<div>
			<AntTable
				size="small"
				className="multizoneTable"
				rowClassName={record => {
					return selectBgColor(record.key, 'warning');
				}}
				pagination={false}
				columns={warningColumns(
					warncolumns,
					limitAlarmChange,
					warningdatasource,
					setWarningDatasource,
					limitAlarmsForm,
					cellenabled,
					unit,
					interval
				)}
				dataSource={warningdatasource.limits}
			/>
			<br />
			<ZoneErrors key="warningErrors" limits={warningdatasource.limits} type={'warning'} />
		</div>
	);
};

const UndoButton = ({undoButtonEnabled, onUndoButtonClick}) => {
	return undoButtonEnabled ? (
		<Popconfirm placement="top" title={'Undo changes?'} okText={'Yes'} cancelText={'No'} onConfirm={() => onUndoButtonClick()}>
			<Button disabled={!undoButtonEnabled} type="primary" size="medium" style={{lineHeight: '0.5em', margin: '10px'}}>
				<UndoOutlined style={{height: '1.2em', verticalAlign: 'middle', flex: 'auto', lineHeight: '0.5em', fontSize: '20px'}} />
				Undo
			</Button>
		</Popconfirm>
	) : (
		<Button disabled={!undoButtonEnabled} type="primary" size="medium" style={{lineHeight: '0.5em', margin: '10px'}}>
			<UndoOutlined style={{height: '1.2em', verticalAlign: 'middle', flex: 'auto', lineHeight: '0.5em', fontSize: '20px'}} />
			Undo
		</Button>
	);
};

const MultizoneTable = ({
	limitProfiles,
	profile,
	profileSelected,
	isProfileEditable,
	alarmingEnabled,
	handleLimitAlarmsChange,
	selectionChanged,
	setSelectionChanged,
	templateName,
	interval,
	unit,
	setEdited,
}) => {
	useEffect(() => {
		setRequiredError(profile.name === '' ? 'error' : '');
		setLengthError(profile.name.length > 50 ? 'error' : '');
		if (
			(origProfile === undefined && (limitProfiles.length > 0 || profile.limit_alarm_creation_type === 0)) ||
			selectionChanged === true
		) {
			setOrigProfile(JSON.parse(JSON.stringify(profile)));
			setNamingErrors('');
			if (setSelectionChanged !== undefined) {
				setSelectionChanged(false);
			}
		}
	}, [profile]);

	useEffect(() => {
		setRequiredError(profile.name === '' ? 'error' : '');
		setLengthError(profile.name.length > 50 ? 'error' : '');

		setUndoButtonEnabled(false);
	}, [profile.index]);

	useEffect(() => {
		setRequiredError(profile.name === '' ? 'error' : '');
		setLengthError(profile.name.length > 50 ? 'error' : '');

		getOrgSettings();
	}, []);

	const [namingError, setNamingErrors] = useState('');
	const [lenghtError, setLengthError] = useState(profile.name.length > 50 ? 'error' : '');
	const [requiredError, setRequiredError] = useState(profile.name === '' ? 'error' : '');

	const [origProfile, setOrigProfile] = useState(JSON.parse(JSON.stringify(profile)));
	const [undoButtonEnabled, setUndoButtonEnabled] = useState(false);

	const maxUnlockedUpper = 4;
	const maxUnlockedLower = 3;
	const warningUnlocked = false;

	const getOrgSettings = () => {
		loadOrganizationConfig('sensor-limit');
	};

	const loadOrganizationConfig = setting => {
		SensorService.getOrganizationSetting(setting).then(response => {
			// setUnlockUpperMax(Math.floor(response.data.setting['alarming-zones'] / 10));
			// setUnlockLowerMax(response.data.setting['alarming-zones'] % 10);
			// setWarningEnabled(response.data.setting['warning-zones'] > 0);
		});
	};

	const limitAlarmChange = (profileLimits, type) => {
		const newProfile = JSON.parse(JSON.stringify(profile));

		if (type === 'warning') {
			newProfile.limit_warnings = JSON.parse(JSON.stringify(profileLimits));
		} else if (type === 'alarm') {
			newProfile.limit_alarms = JSON.parse(JSON.stringify(profileLimits));
		}

		if (JSON.stringify(newProfile.limit_alarms) !== JSON.stringify(origProfile.limit_alarms)) {
			setUndoButtonEnabled(false);
		}

		handleLimitAlarmsChange(newProfile);
		setUndoButtonEnabled(true);
	};

	const onUndoButtonClick = () => {
		setUndoButtonEnabled(false);

		//origProfile.use_limit_alarms = true;

		const newProfile = JSON.parse(JSON.stringify(profile));

		newProfile.limit_warnings = JSON.parse(JSON.stringify(origProfile.limit_warnings));
		newProfile.limit_alarms = JSON.parse(JSON.stringify(origProfile.limit_alarms));

		setRequiredError(newProfile.name === '' ? 'error' : '');
		setLengthError(newProfile.name.length > 50 ? 'error' : '');

		handleLimitAlarmsChange(JSON.parse(JSON.stringify(newProfile)));

		if (setEdited) {
			setEdited(false);
		}
	};

	const checkIfNamingError = (origName, name) => {
		if (name === '' || origName === name) {
			return '';
		} else if (name === undefined) {
			return namingError;
		} else {
			let count = limitProfiles.filter(limitProfile => limitProfile.name === name);

			if (
				count.length > 0 &&
				profile.created_index === count[0].created_index &&
				profile.limit_alarm_creation_type === 0 &&
				count[0].limit_alarm_creation_type === 0
			) {
				return '';
			}

			return count.length > 0 ? 'error' : '';
		}
	};

	const onProfileNameChange = event => {
		let newProfile = JSON.parse(JSON.stringify(profile));
		newProfile.name = event.target.value;

		// if (JSON.stringify(newProfile) !== JSON.stringify(origProfile)) {
		//     setUndoButtonEnabled(true);
		// } else {
		//     setUndoButtonEnabled(false);
		// }

		setNamingErrors(checkIfNamingError(origProfile ? origProfile.name : undefined, newProfile.name));
		setRequiredError(newProfile.name === '' ? 'error' : '');
		setLengthError(newProfile.name.length > 50 ? 'error' : '');

		handleLimitAlarmsChange(newProfile);
	};

	const selectToken = () => {
		if (profile.type === undefined) {
			return '';
		} else if (profile.type.units.length === 1) {
			return profile.type.units[0].token;
		}

		let foundUnit = profile.type !== undefined ? profile.type.units.find(x => x.id === unit) : undefined;
		return foundUnit ? foundUnit.token : undefined;
	};

	return (
		<div>
			<br />
			<Row>
				<Col xl={3}>
					<FormGroup key={'proNameFI'} size="small">
						<Label for={'profileName'}>Profile name</Label>
						<Input
							id={'profileName'}
							data-param={namingError}
							data-validate={profileSelected === true ? JSON.stringify(['required', 'duplicateName', 'maxlen50']) : null}
							name={'Profile Name'}
							type={'input'}
							onChange={event => onProfileNameChange(event)}
							value={profile.name}
							placeholder="Profile Name"
							invalid={
								!profileSelected || !alarmingEnabled
									? false
									: namingError === 'error' || requiredError === 'error' || lenghtError === 'error'
							}
							maxLength="51"
							disabled={isProfileEditable}
						/>
						{requiredError === 'error' && <span className="invalid-feedback">Field is required</span>}
						{namingError === 'error' && <span className="invalid-feedback">Profile name already exists</span>}
						{lenghtError && (
							<span id="sensorNameMax" className="invalid-feedback">
								Field must be be less than 50 characters
							</span>
						)}
					</FormGroup>
				</Col>
			</Row>
			{/* //disabled={!profile.is_libero_g ? (!profileSelected && (!alarmingEnabled || !warningEnabled)) : (profileSelected && !profile.is_new_profile && (!alarmingEnabled || !warningEnabled))} /> */}
			<UndoButton undoButtonEnabled={undoButtonEnabled} onUndoButtonClick={onUndoButtonClick} />
			<div>
				<Accordion>
					<AccordionItem expanded={true}>
						<AccordionItemTitle>
							<h5 className="u-position-relative">
								Alarming
								<div className="accordion__arrow" role="presentation" />
							</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							<AlarmingTable
								limitAlarms={profile.limit_alarms}
								limitAlarmChange={limitAlarmChange}
								cellenabled={!isProfileEditable}
								coupleEnabled={false}
								unit={selectToken()}
								interval={interval}
								maxUnlockedLower={maxUnlockedLower}
								maxUnlockedUpper={maxUnlockedUpper}
								index={profile.index}
							/>
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>
			</div>
			<br />
			<div>
				{warningUnlocked ? (
					<Accordion>
						<AccordionItem>
							<AccordionItemTitle>
								<h5 className="u-position-relative">
									Warning
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								<WarningTable
									limitAlarmChange={limitAlarmChange}
									limitAlarms={profile.limit_warnings}
									cellenabled={
										!(
											templateName &&
											(templateName === MODULE_FAMILIES.LIBERO_G || templateName === MODULE_FAMILIES.ECOLOG_PRO_G)
										)
											? profileSelected && alarmingEnabled
											: profileSelected && alarmingEnabled && profile.is_new_profile
									}
									unit={selectToken()}
									interval={interval}
								/>
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				) : null}
			</div>
		</div>
	);
};

export default MultizoneTable;
