import React, {useState} from 'react';
import {Collapse} from 'antd';
import {Row} from 'reactstrap';
import Group from './Group';
import {secureToken} from '../../Infrastructure/Authorization/Utils';
import {Sensor} from '../../Common/Types/Sensor';

export interface GroupListProps {
	groups: Group[];
	sensors: Map<number, Sensor>;
	groupsChange: any;
	children: any;
	startIndex: number;
}

const GroupsList = (props: GroupListProps) => {
	const getGroupSensors = groupId => new Map([...props.sensors].filter(sensor => sensor[1].GroupIds.some(id => id === groupId)));

	let sensorGroups = new Map<number, Map<number, Sensor>>();
	props.groups.forEach(group => {
		sensorGroups.set(group.id, getGroupSensors(group.id));
	});
	const groupIds = props.groups.map(g => g.id);
	const {openGroups} = secureToken.getDashboardGroups();

	const [openGroupPanel, setOpenGroupPanel] = useState(groupIds.filter(id => openGroups.includes(id))[0]);

	const findGroup = id => {
		const group = props.groups.filter(group => group.id === id)[0];
		return group !== undefined
			? {
					group,
					index: props.groups.indexOf(group),
			  }
			: null;
	};

	const moveGroup = (currentIndex, targetIndex, parentGroup) => {
		const newGroups = props.groups.map((group, i) => {
			if (targetIndex < currentIndex) {
				if (i === targetIndex) return {...props.groups[currentIndex], group_order: i + 1};

				if (i > targetIndex && i <= currentIndex) return {...props.groups[i - 1], group_order: i + 1};
			} else if (targetIndex > currentIndex) {
				if (i === targetIndex) return {...props.groups[currentIndex], group_order: i + 1};

				if (i < targetIndex && i >= currentIndex) return {...props.groups[i + 1], group_order: i + 1};
			}

			return group;
		});

		props.groupsChange(newGroups, parentGroup);
	};

	const toggleGroupPanel = id => {
		const {groups, openGroups} = secureToken.getDashboardGroups();

		setOpenGroupPanel(prev => {
			const newId = prev === id ? null : id;

			secureToken.setDashboardGroups({
				groups,
				openGroups: [...openGroups.filter(id => id !== prev), newId],
			});

			return newId;
		});
	};

	return (
		<>
			{props.groups.map((group, i) => (
				<Group
					key={group.id}
					index={i}
					group={group}
					sortable={props.groups.length > 1}
					findGroup={findGroup}
					moveGroup={moveGroup}
				>
					{({_isDragging}) => (
						<Collapse accordion={false} activeKey={openGroupPanel} destroyInactivePanel={true}>
							<Collapse.Panel
								key={`${group.id}`}
								forceRender={true}
								showArrow={false}
								header={
									<h5 className="accordion__clickable-title">
										<span onClick={() => toggleGroupPanel(group.id)} className="accordion__title-text">
											{group.name}
										</span>
									</h5>
								}
							>
								{!!group.showSensors && (
									<Row className="accordion__sensors-wrapper" id={group.name}>
										{sensorGroups.get(group.id) !== undefined && sensorGroups.get(group.id).size > 0 ? (
											props.children({sensors: sensorGroups.get(group.id), group: group})
										) : (
											<p className="nodata_text">No sensors</p>
										)}
									</Row>
								)}
								{!!group.sub_groups && (
									<GroupsList
										groups={group.sub_groups}
										sensors={props.sensors}
										groupsChange={props.groupsChange}
										children={props.children}
										startIndex={i + 1}
									/>
								)}
							</Collapse.Panel>
						</Collapse>
					)}
				</Group>
			))}
		</>
	);
};

export default GroupsList;
