import {Access} from '../../types/DbModel/DbUser';

export class AuthUtils {
	public static DecodeToken(token: string): string {
		const base64Url = token?.split('.')[1];
		const base64 = base64Url?.replace('-', '+').replace('_', '/');
		if (!base64) {
			return undefined;
		}

		return Buffer.from(base64, 'base64').toString();
	}

	public static DecodeTokenToObject(token: string): any {
		if (!token) {
			return undefined;
		}
		return JSON.parse(AuthUtils.DecodeToken(token));
	}

	public static FindAccessElement(path: string[], accesses: Access[]): Access {
		if (!Array.isArray(path) || !Array.isArray(accesses)) {
			return undefined;
		}
		let element: Access = {allow: false, index: -1, key: 'root', path: [], child: accesses};
		path.forEach(subPath => {
			element = element?.child?.find(c => c.key === subPath);
		});
		return element;
	}
}
