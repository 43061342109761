import {UserProfileService} from '../services';

export class UserProfileHelper {
	public static async GetUserId(): Promise<number> {
		try {
			return (await UserProfileService.GetUser()).Id;
		} catch (e) {
			return await Promise.reject(e);
		}
	}
}
