import { LineItemTypeResponse } from 'common/services/LicensingService/Response/LineItemTypeResponse';
import {
	CheckoutSessionCreationLineItemRequest,
	CheckoutSessionCreationRequest,
	LicensingService,
	OfferLineItemRequest,
	OfferRequest,
	SubscriptionUpdateLineItemRequest,
	SubscriptionUpdateRequest,
} from '../../services/LicensingService';
import {LineItem} from './LineItem';
import { ConfigurationOption, ConfigurationOptions, PositionedSensors } from './LineItemConstants';
import { DeviceType } from 'common/constants';

export type OfferRequestProps = {
	moduleId: string;
	actionType: string;
	lineItemProps: LineItemProps;
};

export type CheckoutSessionCreationRequestProps = {
	moduleId: string;
	successUrl: string;
	cancelUrl: string;
	lineItemProps: LineItemProps;
};

export type SubscriptionUpdateRequestProps = {
	moduleId: string;
	lineItemProps: LineItemProps;
};

export type LineItemProps = {
	baseLicenses: LineItem[];
	loggingIntervals: LineItem[];
	limitEmail1: LineItem;
	limitEmail2: LineItem;
	limitSms1: LineItem;
	limitSms2: LineItem;
	issueEmail: LineItem;
	issueSms: LineItem;
	retirement1: LineItem;
	retirement2: LineItem;
};

export class LicensingExtension {
	public static prepareOfferRequest = (offerRequestProps: OfferRequestProps): OfferRequest => {
		let lineItems: OfferLineItemRequest[] = [];

		offerRequestProps?.lineItemProps?.baseLicenses?.forEach(baseLicense => {
			const offerLineItemRequest: OfferLineItemRequest = {
				internalPriceId: baseLicense?.internalPriceId,
				quantity: baseLicense?.quantity,
			};
			lineItems.push(offerLineItemRequest);
		});

		offerRequestProps?.lineItemProps?.loggingIntervals?.forEach(loggingInterval => {
			const offerLineItemRequest: OfferLineItemRequest = {
				internalPriceId: loggingInterval?.internalPriceId,
				quantity: loggingInterval?.quantity,
			};
			lineItems.push(offerLineItemRequest);
		});

		if (offerRequestProps?.lineItemProps?.limitEmail1?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.limitEmail1.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.limitEmail1.quantity,
			});
		}

		if (offerRequestProps?.lineItemProps?.limitEmail2?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.limitEmail2.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.limitEmail2.quantity,
			});
		}

		if (offerRequestProps?.lineItemProps?.limitSms1?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.limitSms1.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.limitSms1.quantity,
			});
		}

		if (offerRequestProps?.lineItemProps?.limitSms2?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.limitSms2.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.limitSms2.quantity,
			});
		}

		if (offerRequestProps?.lineItemProps?.issueEmail?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.issueEmail.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.issueEmail.quantity,
			});
		}

		if (offerRequestProps?.lineItemProps?.issueSms?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.issueSms.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.issueSms.quantity,
			});
		}

		if (offerRequestProps?.lineItemProps?.retirement1?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.retirement1.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.retirement1.quantity,
			});
		}

		if (offerRequestProps?.lineItemProps?.retirement2?.internalPriceId) {
			lineItems.push({
				internalPriceId: offerRequestProps.lineItemProps?.retirement2.internalPriceId,
				quantity: offerRequestProps.lineItemProps?.retirement2.quantity,
			});
		}

		return {
			internalProductId: offerRequestProps.moduleId,
			actionType: offerRequestProps.actionType,
			lineItems: lineItems,
		};
	};

	public static prepareCheckoutSessionCreationRequest = (
		checkoutSessionCreationRequestProps: CheckoutSessionCreationRequestProps
	): CheckoutSessionCreationRequest => {
		let lineItems: CheckoutSessionCreationLineItemRequest[] = [];

		checkoutSessionCreationRequestProps?.lineItemProps?.baseLicenses?.forEach(baseLicense => {
			const checkoutSessionCreationLineItemRequest: CheckoutSessionCreationLineItemRequest = {
				internalPriceId: baseLicense?.internalPriceId,
				quantity: baseLicense?.quantity,
				linkedTo: baseLicense?.linkedTo,
			};
			lineItems.push(checkoutSessionCreationLineItemRequest);
		});

		checkoutSessionCreationRequestProps?.lineItemProps?.loggingIntervals?.forEach(loggingInterval => {
			const checkoutSessionCreationLineItemRequest: CheckoutSessionCreationLineItemRequest = {
				internalPriceId: loggingInterval?.internalPriceId,
				quantity: loggingInterval?.quantity,
				linkedTo: loggingInterval?.linkedTo,
			};
			lineItems.push(checkoutSessionCreationLineItemRequest);
		});

		if (checkoutSessionCreationRequestProps?.lineItemProps?.limitEmail1?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.limitEmail1.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.limitEmail1.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.limitEmail1.linkedTo,
			});
		}

		if (checkoutSessionCreationRequestProps?.lineItemProps?.limitEmail2?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.limitEmail2.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.limitEmail2.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.limitEmail2.linkedTo,
			});
		}

		if (checkoutSessionCreationRequestProps?.lineItemProps?.limitSms1?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.limitSms1.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.limitSms1.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.limitSms1.linkedTo,
			});
		}

		if (checkoutSessionCreationRequestProps?.lineItemProps?.limitSms2?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.limitSms2.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.limitSms2.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.limitSms2.linkedTo,
			});
		}

		if (checkoutSessionCreationRequestProps?.lineItemProps?.issueEmail?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.issueEmail.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.issueEmail.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.issueEmail.linkedTo,
			});
		}

		if (checkoutSessionCreationRequestProps?.lineItemProps?.issueSms?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.issueSms.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.issueSms.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.issueSms.linkedTo,
			});
		}

		if (checkoutSessionCreationRequestProps?.lineItemProps?.retirement1?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.retirement1.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.retirement1.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.retirement1.linkedTo,
			});
		}

		if (checkoutSessionCreationRequestProps?.lineItemProps?.retirement2?.internalPriceId) {
			lineItems.push({
				internalPriceId: checkoutSessionCreationRequestProps.lineItemProps?.retirement2.internalPriceId,
				quantity: checkoutSessionCreationRequestProps.lineItemProps?.retirement2.quantity,
				linkedTo: checkoutSessionCreationRequestProps.lineItemProps?.retirement2.linkedTo,
			});
		}

		return {
			internalProductId: checkoutSessionCreationRequestProps.moduleId,
			successUrl: checkoutSessionCreationRequestProps.successUrl,
			cancelUrl: checkoutSessionCreationRequestProps.cancelUrl,
			lineItems: lineItems,
		};
	};

	public static prepareSubscriptionUpdateRequest = (
		subscriptionUpdateRequestProps: SubscriptionUpdateRequestProps
	): SubscriptionUpdateRequest => {
		let lineItems: SubscriptionUpdateLineItemRequest[] = [];

		subscriptionUpdateRequestProps?.lineItemProps?.baseLicenses?.forEach(baseLicense => {
			const subscriptionUpdateLineItemRequest: SubscriptionUpdateLineItemRequest = {
				internalPriceId: baseLicense?.internalPriceId,
				quantity: baseLicense?.quantity,
				linkedTo: baseLicense?.linkedTo,
			};
			lineItems.push(subscriptionUpdateLineItemRequest);
		});

		subscriptionUpdateRequestProps?.lineItemProps?.loggingIntervals?.forEach(loggingInterval => {
			const subscriptionUpdateLineItemRequest: SubscriptionUpdateLineItemRequest = {
				internalPriceId: loggingInterval?.internalPriceId,
				quantity: loggingInterval?.quantity,
				linkedTo: loggingInterval?.linkedTo,
			};
			lineItems.push(subscriptionUpdateLineItemRequest);
		});

		if (subscriptionUpdateRequestProps?.lineItemProps?.limitEmail1?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.limitEmail1.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.limitEmail1.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.limitEmail1.linkedTo,
			});
		}

		if (subscriptionUpdateRequestProps?.lineItemProps?.limitEmail2?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.limitEmail2.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.limitEmail2.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.limitEmail2.linkedTo,
			});
		}

		if (subscriptionUpdateRequestProps?.lineItemProps?.limitSms1?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.limitSms1.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.limitSms1.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.limitSms1.linkedTo,
			});
		}

		if (subscriptionUpdateRequestProps?.lineItemProps?.limitSms2?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.limitSms2.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.limitSms2.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.limitSms2.linkedTo,
			});
		}

		if (subscriptionUpdateRequestProps?.lineItemProps?.issueEmail?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.issueEmail.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.issueEmail.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.issueEmail.linkedTo,
			});
		}

		if (subscriptionUpdateRequestProps?.lineItemProps?.issueSms?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.issueSms.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.issueSms.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.issueSms.linkedTo,
			});
		}

		if (subscriptionUpdateRequestProps?.lineItemProps?.retirement1?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.retirement1.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.retirement1.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.retirement1.linkedTo,
			});
		}

		if (subscriptionUpdateRequestProps?.lineItemProps?.retirement2?.internalPriceId) {
			lineItems.push({
				internalPriceId: subscriptionUpdateRequestProps.lineItemProps?.retirement2.internalPriceId,
				quantity: subscriptionUpdateRequestProps.lineItemProps?.retirement2.quantity,
				linkedTo: subscriptionUpdateRequestProps.lineItemProps?.retirement2.linkedTo,
			});
		}

		return {
			internalProductId: subscriptionUpdateRequestProps.moduleId,
			lineItems: lineItems,
		};
	};

	public static PrepareLineItemsFromLineItemTypes(
		lineItemTypes: LineItemTypeResponse[],
		deviceType: DeviceType,
		selectedChannel: number,
		selectedValueIndex: number
	): LineItem[] {
		let lineItems: LineItem[] = [];

		const configurationOption = ConfigurationOptions[deviceType];

		if (configurationOption == ConfigurationOption.Single) {
			lineItemTypes = lineItemTypes.filter(lit => lit.internalPrice.endsWith(PositionedSensors[selectedChannel][selectedValueIndex]));
		}

		lineItemTypes.forEach((blt: LineItemTypeResponse) => {
			let lineItem: LineItem = {
				internalPriceId: blt.internalPrice,
				quantity: 1,
				linkedTo: blt.linkedTo,
			};
			lineItems.push(lineItem);
		});
		return lineItems;
	}

	static async HasSubscription(moduleId: string): Promise<boolean> {
		let hasSubscription = false;
		try {
			await LicensingService.GetSubscription(moduleId);
			hasSubscription = true;
		} catch (e) {}
		return hasSubscription;
	}
}
