import React, {useContext, useEffect, useState} from 'react';
import {AntStyledTable} from '../../Shared/Styles/AntStyledTable';
import {AntButton} from '../../Shared/UI/Ant';
import {UserColumns} from './DataHandling';
import {AddUser, ShowAccessSettings, UserLimitCount} from './Components';
import {deleteUser, getOrganizationSetting, sendActivationMail} from '../../Services/UserManagement/UserMangementService';
import UserManagementHelper from '../../Services/Extensions/UserManagementHelper';
import Message from '../../Shared/Components/Message';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import {Access} from '../../Infrastructure/Authorization/Components';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {AccessContext} from '../../Infrastructure/Authorization/Context/AccessContext';
import {useTranslation} from 'react-i18next';
import {ErrorAlert, Success} from '../../Common/Alerts';
import {ContentWrapper, ViewWrapper} from '../../Layout';
import {Card, ViewHeader} from '../../Common';
import {CreateUser, UpdateUserAccessSettings} from '../../../common/services/WebserverServicePro/UserService';

const UserManagement = () => {
	const [t] = useTranslation();
	const [dataSource, setDataSource] = useState([]);
	const [accessSettingsVisible, setAccessSettingsVisible] = useState(false);
	const [accessSettingsContent, setAccessSettingsContent] = useState([]);
	const [addUserVisible, setAddUserVisible] = useState(false);
	const [loadingUserList, setLoadingUserList] = useState(false);
	const [limitCount] = useState({basic: 0, operator: 0, operatorplus: 0});
	const [roleLimitCount, setRoleLimitCount] = useState(limitCount);
	const [orgSettingMaxUser, setOrgSettingMaxUser] = useState(limitCount);

	const userAccess = useContext(AccessContext);

	const loadUsers = () => {
		setLoadingUserList(true);
		UserManagementHelper.loadUsersDataSource().then(users => {
			const tempFilter = users.filter(user => {
				const userAccess = !Array.isArray(user.access_settings) && typeof user.access_settings === 'object' && user.access_settings;

				if (userAccess?.settings && Object.keys(userAccess.settings).length !== 0) {
					return userAccess;
				}
			});

			setDataSource(tempFilter);
			setLoadingUserList(false);
			countRoles(tempFilter);
		});
	};

	const loadOrganizationConfig = setting => {
		getOrganizationSetting(setting).then(response => {
			setOrgSettingMaxUser(response.data.setting);
		});
	};

	useEffect(() => {
		loadUsers();
	}, []);

	useEffect(() => {
		loadOrganizationConfig('user-management');
	}, []);

	const countRoles = users => {
		const roleCounter = {...limitCount};
		users.forEach(user => {
			roleCounter[user.access_settings.settings.roles[0]]++;
		});
		setRoleLimitCount(roleCounter);
	};

	const disableAddNewUser = (roleCount, maxCount) => {
		const maxRoles = [];
		for (const key of Object.keys(roleCount)) {
			if (roleCount[key] >= maxCount[key.toUpperCase()]) {
				maxRoles.push(key);
			}
		}
		return maxRoles.length === Object.keys(maxCount).length;
	};

	const setAvailableRolesToSelect = (roleCount, maxCount) => {
		const availableRoles = {};
		for (const key of Object.keys(roleCount)) {
			availableRoles[key] = roleCount[key] < maxCount[key.toUpperCase()];
		}
		return availableRoles;
	};

	const addNewUser = () => {
		setAddUserVisible(true);
	};

	const sendActivationEMail = record => {
		sendActivationMail(record.id).then(response => {
			if (response.status === 200) {
				Message.success('Activation Mail', response.data.message);
			} else {
				Message.error('Error', response.message);
			}
		});
	};

	const changeUserSetting = (userRecord, updateData) => {
		const userAccess = {
			...userRecord.access_settings,
			[updateData.key]: {...userRecord.access_settings[updateData.key], [updateData.setting]: updateData.value},
		};
		saveUserUpdates(userRecord.id, userAccess);
	};

	const deleteMFAUserSetting = userRecord => {
		const userAccess = {
			...userRecord.access_settings,
			['mfaEnabled']: false,
			['mfaSecret']: '',
		};

		saveUserUpdates(userRecord.id, userAccess);
	};

	const saveUserUpdates = (userId, accessSettings) => {
		const payload = {
			userId,
			accessSettings,
		};

		UpdateUserAccessSettings(payload).then(() => {
			loadUsers();
		});
	};

	const removeUser = record => {
		const MySwal = withReactContent(Swal);
		MySwal.fire({
			title: "<span id='delete_user'>Are you sure?</span>",
			text: "You won't be able to revert this!",
			input: 'password',
			inputAttributes: {
				autocapitalize: 'off',
				placeholder: 'Enter your password',
			},
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete User!',
			preConfirm: password => {
				return deleteUser({
					admin_pass: password,
					delete_user_id: record.id,
					admin_email: userAccess.user.email,
				}).then(response => {
					if (response.status === 200) {
						return 'isConfirmed';
					} else {
						MySwal.showValidationMessage('Invalid Password');
					}
				});
			},
		}).then(result => {
			if (result.value === 'isConfirmed') {
				loadUsers();
			}
		});
	};

	const showAccessSettings = userRecord => {
		setAccessSettingsVisible(true);
		setAccessSettingsContent(userRecord);
	};

	const handleShowAccessSettingsOK = (userId, userAccessSettings) => {
		setAccessSettingsVisible(false);
		saveUserUpdates(userId, userAccessSettings);
	};

	const handleShowAccessSettingsCancel = () => {
		setAccessSettingsVisible(false);
	};

	const handleShowAccessSettingsAfterClose = () => {};

	const handleAddUserOK = user => {
		setAddUserVisible(false);
		const {firstName, lastName, email, mobile, roles} = user;
		const newUser = {
			firstName,
			lastName,
			email,
			mobile,
			roles,
		};

		CreateUser(newUser)
			.then(_response => {
				Success.fire({
					titleText: t('settings.userManagement.message.ok_user_created_title'),
					html: t('settings.userManagement.message.ok_user_created_description'),
				}).then(_ => loadUsers());
			})
			.catch(_e => {
				ErrorAlert.fire({
					titleText: t('error.title'),
					text: t('settings.userManagement.message.fail_user_created_title') + '\n\n',
				}).then(_ => loadUsers());
			});
	};

	const handleAddUserAfterClose = () => {};

	const handleAddUserCancel = () => {
		setAddUserVisible(false);
	};

	return (
		<ViewWrapper>
			<ShowAccessSettings
				title={'Edit User Access Settings'}
				visible={accessSettingsVisible}
				handleCancel={handleShowAccessSettingsCancel}
				handleOk={handleShowAccessSettingsOK}
				handleAfterClose={handleShowAccessSettingsAfterClose}
				accessSettingsContent={accessSettingsContent}
			/>

			<AddUser
				title={'Add User'}
				visible={addUserVisible}
				handleCancel={handleAddUserCancel}
				handleOk={handleAddUserOK}
				handleAfterClose={handleAddUserAfterClose}
				roles={setAvailableRolesToSelect(roleLimitCount, {
					BASIC: orgSettingMaxUser.basic,
					OPERATOR: orgSettingMaxUser.operator,
					OPERATORPLUS: orgSettingMaxUser.operatorplus,
				})}
			/>
			<ViewHeader heading={'User Management'} knowledgeHelpId={'047'}></ViewHeader>

			<ContentWrapper>
				<Card title={'Users'}>
					<div style={{padding: '0 0 20px 0'}}>
						<Access access={accessPermissions.settings.child.userManagement.child.createUser} roles={userRoles.default}>
							<div style={{display: 'flex', alignItems: 'baseline'}}>
								<AntButton
									id={'addNewUser'}
									onClick={addNewUser}
									disabled={disableAddNewUser(roleLimitCount, {
										BASIC: orgSettingMaxUser.basic,
										OPERATOR: orgSettingMaxUser.operator,
										OPERATORPLUS: orgSettingMaxUser.operatorplus,
									})}
								>
									{t('settings.userManagement.createUser').toString()}
								</AntButton>
								<UserLimitCount
									operatorMaxRoles={orgSettingMaxUser.operator}
									basicMaxRoles={orgSettingMaxUser.basic}
									operatorplusMaxRoles={orgSettingMaxUser.operatorplus}
									operatorRoleCount={roleLimitCount.operator}
									basicRoleCount={roleLimitCount.basic}
									operatorplusRoleCount={roleLimitCount.operatorplus}
								/>
							</div>
						</Access>
					</div>
					<AntStyledTable
						loading={loadingUserList}
						scroll={{x: 100}}
						size="small"
						rowKey="id"
						showSorterTooltip={false}
						locale={{emptyText: 'No data'}}
						columns={UserColumns(sendActivationEMail, removeUser, changeUserSetting, showAccessSettings, deleteMFAUserSetting)}
						dataSource={dataSource}
						pagination={false}
					/>
				</Card>
			</ContentWrapper>
		</ViewWrapper>
	);
};

export default UserManagement;
