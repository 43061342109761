import React, {Component} from 'react';
import ButtonsComponent from './ButtonsComponent';

class ButtonsCellComponent extends Component {
	constructor(props) {
		super(props);
		if (!props.value) {
			return;
		}
		this.state = {
			issueProfile: props.value.issueProfile,
			rowIndex: props.rowIndex,
			name: props.value.issueProfile.name,
		};
	}

	prepareId() {
		return this.state.name.replace(/\s/g, '').trim() + '-' + this.state.rowIndex;
	}

	render() {
		return (
			<>
				{this.props.value ? (
					<ButtonsComponent
					    id ={this.prepareId()}
						locked={this.state.issueProfile.locked}
						copyIssueProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.copyIssueProfile(
								this.props.value.parent,
								this.state.issueProfile
							)
						}
						displayIssueProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.displayIssueProfile(
								this.props.value.parent,
								this.state.issueProfile.id
							)
						}
						editIssueProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.editIssueProfile(
								this.props.value.parent,
								this.state.issueProfile
							)
						}
						deleteIssueProfile={() =>
							this.props.value.callbackMethodsForHamburgerMenu.deleteIssueProfile(
								this.props.value.parent,
								this.state.issueProfile
							)
						}
					/>
				) : null}
			</>
		);
	}
}

export default ButtonsCellComponent;
