import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';
import styles from './styles/styles.module.scss';

export function ContentWrapper(props: {children: React.JSX.Element | React.JSX.Element[]}): React.JSX.Element {
	return <div className={styles.contentWrapper}>{props.children}</div>;
}

/**
 * Wrapper element for template content
 */
const ContentWrapperDeprecated = (props: {unwrap: boolean; children: ReactNode}) => (
	<div className="content-wrapper">{props.unwrap ? <div className="unwrap">{props.children}</div> : props.children}</div>
);

ContentWrapperDeprecated.propTypes = {
	/** add element with 'unwrap' class to expand content area */
	unwrap: PropTypes.bool,
};
ContentWrapperDeprecated.defaultProps = {
	unwrap: false,
};

export default ContentWrapperDeprecated;
