import React, {Component} from 'react';
import {Alert, Input} from 'reactstrap';
import WebSocket from '../Infrastructure/WebSockets/WebSocket';
import BridgeConfigurationService from './BridgeConfigurationService';
import bridgeConfigurationUtils from './BridgeConfigurationUtils';
import FormValidator from '../Forms/FormValidator';
import pubsub from 'pubsub-js';
import SystemSettings from '../Infrastructure/Settings/SystemSettings';
import RequestLogger from '../Infrastructure/Requests/Logger/RequestLogger';

class BridgeConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            formBridge: {
                bridge_id: '',
                mobile: '',
                terms_of_use: false,
            },
            formCode: {
                code: '',
            },
            formConfiguration: {
                use_dhcp: true,
                auto_dns: true,
                ip_address: '',
                subnet_mask: '',
                default_gateway: '',
                preferred_dns: '',
                alternate_dns: '',
                hostname: '',
                private_wireless_name: '',
            },
            showValidation: false,
            timer: '',
            success_text: undefined,
            error_text: undefined,
        };
    }

    componentDidMount() {
        this.handleBridgeConfigurationToken = pubsub.subscribe('bridge_configuration', this.handleBridgeConfiguration);

        // handle a refresh
        const obj = bridgeConfigurationUtils.getLocalStorage();

        if (obj !== undefined) {
            this.setState({
                formBridge: {
                    ...this.state.formBridge,
                    bridge_id: obj.bridge_id,
                    mobile: obj.mobile,
                },
                formCode: {
                    ...this.state.formCode,
                    code: obj.code,
                },
            });
            WebSocket.connectToBridgeConfigurationChannel(obj.bridge_id + ':' + obj.code);
            if (obj.endDate !== undefined) {
                this.setState({timer: ''});
                this.setState({step: 4});
                this.startTimer(obj.endDate);
            } else {
                this.setState({step: 3});
            }
        }
    }

    componentWillUnmount() {
        pubsub.unsubscribe(this.handleBridgeConfigurationToken);
    }

    handleBridgeConfiguration = (message, data) => {
        if (data.succeeded) {
            this.setState({success_text: 'Bridge configuration successful!'});
        } else {
            this.setState({error_text: 'Bridge configuration not successful!'});
        }

        bridgeConfigurationUtils.deleteLocalStorage();
    };

    backToLogin = e => {
        this.props.history.push('/login');
        e.preventDefault();
    };

    use_dhcp_changed = event => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        this.setState(prevState => ({
            formConfiguration: {
                ...prevState.formConfiguration,
                use_dhcp: JSON.parse(value),
                auto_dns: JSON.parse(value),
            },
        }));
    };

    auto_dns_changed = event => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        this.setState(prevState => ({
            formConfiguration: {
                ...prevState.formConfiguration,
                auto_dns: JSON.parse(value),
            },
        }));
    };

    validateOnChange = event => {
        const input = event.target;
        const form = input.form;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        const formValue = input.name === 'bridge_id' ? value.toUpperCase() : value;

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: formValue,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result,
                },
            },
        });
    };

    onSubmit = e => {
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors,
            },
        });

        if (!hasError) {
            let that = this;

            if (this.state.step === 1) {
                let payload = {
                    bridge_id: this.state.formBridge.bridge_id,
                    mobile: this.state.formBridge.mobile,
                    terms_of_use: this.state.formBridge.terms_of_use,
                };

                BridgeConfigurationService.request_bridge_configuration(
                    payload,
                    RequestLogger.createLogData('bridge-configuration', 'send-bridge-configuration', 'onClick')
                )
                    .then(function (response) {
                        that.setState({step: 2});
                    })
                    .catch(function (error) {
                        console.log('error');
                    });
            } else if (this.state.step === 2) {
                let payload = {
                    bridge_id: this.state.formBridge.bridge_id,
                    mobile: this.state.formBridge.mobile,
                    code: this.state.formCode.code,
                };

                that.setState({errorMessage: undefined});
                BridgeConfigurationService.confirm_sms_code(payload)
                    .then(function (response) {
                        console.log('response ', response);
                        bridgeConfigurationUtils.setLocalStorage({
                            bridge_id: that.state.formBridge.bridge_id,
                            mobile: that.state.formBridge.mobile,
                            code: that.state.formCode.code,
                        });
                        WebSocket.connectToBridgeConfigurationChannel(that.state.formBridge.bridge_id + ':' + that.state.formCode.code);
                        that.setState({step: 3});
                    })
                    .catch(function (error) {
                        console.log('error ', error);
                        that.setState({errorMessage: error.data.message});
                    });
            } else {
                let configuration = {
                    eth_dhcp: this.state.formConfiguration.use_dhcp ? '1' : '0',
                    eth_configured: '1',
                    server_gateway_address: 'gateway.elpro.cloud',
                    server_gateway_port: '22100',
                    no_tls: '0',
                };

                if (!this.state.formConfiguration.use_dhcp) {
                    configuration.eth_address = this.state.formConfiguration.ip_address;
                    configuration.eth_netmask = this.state.formConfiguration.subnet_mask;
                    configuration.eth_gateway = this.state.formConfiguration.default_gateway;
                }

                if (!this.state.formConfiguration.auto_dns) {
                    let dns_count = 0;
                    if (this.state.formConfiguration.preferred_dns.length > 0) {
                        dns_count++;
                        configuration.dns_server_0 = this.state.formConfiguration.preferred_dns;

                        if (this.state.formConfiguration.alternate_dns.length > 0) {
                            dns_count++;
                            configuration.dns_server_1 = this.state.formConfiguration.alternate_dns;
                        }
                    }
                    configuration.dns_servers = dns_count.toString();
                }

                if (this.state.formConfiguration.hostname.length > 0) {
                    configuration.hostname = this.state.formConfiguration.hostname;
                }

                if (this.state.formConfiguration.private_wireless_name.length > 0) {
                    configuration.organisation_id = this.state.formConfiguration.private_wireless_name;
                }

                let payload = {
                    bridge_id: this.state.formBridge.bridge_id,
                    mobile: this.state.formBridge.mobile,
                    code: this.state.formCode.code,
                    configuration: JSON.stringify(configuration),
                };

                BridgeConfigurationService.save_configuration(payload)
                    .then(function (response) {
                        let obj = bridgeConfigurationUtils.getLocalStorage();
                        obj.endDate = bridgeConfigurationUtils.getEndDate();
                        bridgeConfigurationUtils.setLocalStorage(obj);
                        that.setState({step: 4});
                        that.startTimer(obj.endDate);
                    })
                    .catch(function (error) {
                        console.log('error ', error);
                    });
            }
        } else {
            console.log('has error');
        }

        e.preventDefault();
    };

    setTimer(endDate) {
        const timer = bridgeConfigurationUtils.countdown(endDate);
        timer ? this.setState({timer: timer}) : this.timeout();
    }

    startTimer = endDate => {
        let that = this;
        this.setTimer(endDate);

        setInterval(function () {
            that.setTimer(endDate);
        }, 1000);
    };

    timeout() {
        bridgeConfigurationUtils.deleteLocalStorage();
        this.setState({error_text: 'Time out!'});
    }

    hasError = (formName, inputName, method) => {
        return (
            this.state[formName] &&
            this.state[formName].errors &&
            this.state[formName].errors[inputName] &&
            this.state[formName].errors[inputName][method]
        );
    };

    render() {
        return (
            <div>
                <div className={'block-center mt-4 ' + (this.state.step < 3 ? 'wd-xl' : 'wd-xxl')}>
                    <div className="card card-flat">
                        <div className="card-header text-center bg-white">
                            <a>
                                <img width={200} src="img/logo.png" alt="ELPRO" />
                            </a>
                        </div>
                        <div className="card-body">
                            {this.state.step === 1 ? (
                                <form className="mb-3" name="formBridge" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label className="text-muted">Bridge ID</label>
                                        <div className="input-group with-focus">
                                            <Input
                                                type="text"
                                                id="inputBridgeId"
                                                name="bridge_id"
                                                className="border-right-0"
                                                placeholder="Enter your bridge ID"
                                                invalid={
                                                    this.hasError('formBridge', 'bridge_id', 'required') ||
                                                    this.hasError('formBridge', 'bridge_id', 'bridgeId') ||
                                                    this.hasError('formBridge', 'bridge_id', 'maxchar')
                                                }
                                                onChange={this.validateOnChange}
                                                data-validate='["required","bridgeId","maxchar"]'
                                                maxLength="7"
                                                value={this.state.formBridge.bridge_id}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                                    <em className="fa fa-building" />
                                                </span>
                                            </div>
                                            {this.hasError('formBridge', 'bridge_id', 'required') && (
                                                <span className="invalid-feedback">Field is required</span>
                                            )}
                                            {this.hasError('formBridge', 'bridge_id', 'bridgeId') && (
                                                <span className="invalid-feedback">Bridge ID is not valid</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="text-muted">Mobile Phone for authentication</label>
                                        <div className="input-group with-focus">
                                            <Input
                                                type="text"
                                                id="inputMobile"
                                                name="mobile"
                                                className="border-right-0"
                                                placeholder="Enter your mobile phone"
                                                invalid={
                                                    this.hasError('formBridge', 'mobile', 'required') ||
                                                    this.hasError('formBridge', 'mobile', 'mobilePhone')
                                                }
                                                onChange={this.validateOnChange}
                                                data-validate='["required", "mobilePhone"]'
                                                value={this.state.formBridge.mobile}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                                    <em className="fa fa-mobile-alt" />
                                                </span>
                                            </div>
                                            {this.hasError('formBridge', 'mobile', 'required') && (
                                                <span className="invalid-feedback">Field is required</span>
                                            )}
                                            {this.hasError('formBridge', 'mobile', 'mobilePhone') && (
                                                <span id="errorMobilePhoneNotValid" className="invalid-feedback">
                                                    Field must be valid mobile phone
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="clearfix">
                                        <div className="checkbox c-checkbox float-left mt-0">
                                            <label>
                                                <Input
                                                    type="checkbox"
                                                    onChange={this.validateOnChange}
                                                    value={this.state.formBridge.terms_of_use}
                                                    name="terms_of_use"
                                                />
                                                <span className="fa fa-check" />I have read and agree with the{' '}
                                                <a href={SystemSettings.getConfig().privacy_policy} target="_blank">
                                                    privacy policy
                                                </a>{' '}
                                                and{' '}
                                                <a href={SystemSettings.getConfig().terms_of_use} target="_blank">
                                                    terms of use
                                                </a>
                                            </label>
                                        </div>
                                    </div>

                                    <button
                                        id="btnNext"
                                        disabled={!this.state.formBridge.terms_of_use}
                                        className="btn btn-block btn-primary mt-3"
                                        type="submit"
                                    >
                                        Next
                                    </button>

                                    <br />
                                    <div className="float-right">
                                        <a
                                            id="btnBackToLogin"
                                            className="text-muted"
                                            style={{cursor: 'pointer'}}
                                            onClick={this.backToLogin}
                                        >
                                            Back to login?
                                        </a>
                                    </div>
                                </form>
                            ) : null}

                            {this.state.step === 2 ? (
                                <form className="mb-3" name="formCode" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label className="text-muted">SMS Code</label>
                                        <div className="input-group with-focus">
                                            <Input
                                                type="text"
                                                id="inputCode"
                                                name="code"
                                                className="border-right-0"
                                                placeholder="Enter your SMS code"
                                                invalid={this.hasError('formCode', 'code', 'required')}
                                                onChange={this.validateOnChange}
                                                data-validate='["required"]'
                                                value={this.state.formCode.code}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                                    <em className="fa fa-building" />
                                                </span>
                                            </div>
                                            {this.hasError('formCode', 'code', 'required') && (
                                                <span className="invalid-feedback">Field is required</span>
                                            )}
                                        </div>
                                    </div>

                                    <button id="btnNext" className="btn btn-block btn-primary mt-3" type="submit">
                                        Next
                                    </button>
                                    <div>
                                        <p />
                                        {this.state.errorMessage ? (
                                            <Alert color="danger text-center">{this.state.errorMessage}</Alert>
                                        ) : null}
                                    </div>
                                </form>
                            ) : null}

                            {this.state.step === 3 ? (
                                <form className="mb-3" name="formConfiguration" onSubmit={this.onSubmit}>
                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <input
                                                type="radio"
                                                name="use_dhcp"
                                                value={true}
                                                checked={this.state.formConfiguration.use_dhcp === true}
                                                onChange={this.use_dhcp_changed}
                                            />{' '}
                                            Obtain an IP address automatically (DHCP)
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="radio"
                                                name="use_fixed_ip"
                                                value={false}
                                                checked={this.state.formConfiguration.use_dhcp === false}
                                                onChange={this.use_dhcp_changed}
                                            />{' '}
                                            Use the following IP address:
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">IP address</label>
                                            <div className="input-group with-focus">
                                                <Input
                                                    type="text"
                                                    name="ip_address"
                                                    data-validate={
                                                        !this.state.formConfiguration.use_dhcp ? '["required", "ipAddress"]' : null
                                                    }
                                                    invalid={
                                                        !this.state.formConfiguration.use_dhcp
                                                            ? this.hasError('formConfiguration', 'ip_address', 'required') ||
                                                              this.hasError('formConfiguration', 'ip_address', 'ipAddress')
                                                            : null
                                                    }
                                                    value={this.state.formConfiguration.ip_address}
                                                    disabled={this.state.formConfiguration.use_dhcp}
                                                    onChange={this.validateOnChange}
                                                />
                                                {this.hasError('formConfiguration', 'ip_address', 'required') && (
                                                    <span className="invalid-feedback">Field is required</span>
                                                )}
                                                {this.hasError('formConfiguration', 'ip_address', 'ipAddress') && (
                                                    <span id="errorIpAddressNotValid" className="invalid-feedback">
                                                        Field must be valid IP Address
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-muted">Subnet mask</label>
                                            <div className="input-group with-focus">
                                                <Input
                                                    type="text"
                                                    name="subnet_mask"
                                                    data-validate={
                                                        !this.state.formConfiguration.use_dhcp ? '["required", "ipAddress"]' : null
                                                    }
                                                    invalid={
                                                        !this.state.formConfiguration.use_dhcp
                                                            ? this.hasError('formConfiguration', 'subnet_mask', 'required') ||
                                                              this.hasError('formConfiguration', 'subnet_mask', 'ipAddress')
                                                            : null
                                                    }
                                                    value={this.state.formConfiguration.subnet_mask}
                                                    disabled={this.state.formConfiguration.use_dhcp}
                                                    onChange={this.validateOnChange}
                                                />
                                                {this.hasError('formConfiguration', 'subnet_mask', 'required') && (
                                                    <span className="invalid-feedback">Field is required</span>
                                                )}
                                                {this.hasError('formConfiguration', 'subnet_mask', 'ipAddress') && (
                                                    <span id="errorIpAddressNotValid" className="invalid-feedback">
                                                        Field must be valid IP Address
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="text-muted">Default gateway</label>
                                            <div className="input-group with-focus">
                                                <Input
                                                    type="text"
                                                    name="default_gateway"
                                                    data-validate={
                                                        !this.state.formConfiguration.use_dhcp ? '["required", "ipAddress"]' : null
                                                    }
                                                    invalid={
                                                        !this.state.formConfiguration.use_dhcp
                                                            ? this.hasError('formConfiguration', 'default_gateway', 'required') ||
                                                              this.hasError('formConfiguration', 'default_gateway', 'ipAddress')
                                                            : null
                                                    }
                                                    value={this.state.formConfiguration.default_gateway}
                                                    disabled={this.state.formConfiguration.use_dhcp}
                                                    onChange={this.validateOnChange}
                                                />
                                                {this.hasError('formConfiguration', 'default_gateway', 'required') && (
                                                    <span className="invalid-feedback">Field is required</span>
                                                )}
                                                {this.hasError('formConfiguration', 'default_gateway', 'ipAddress') && (
                                                    <span id="errorIpAddressNotValid" className="invalid-feedback">
                                                        Field must be valid IP Address
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <input
                                                type="radio"
                                                name="auto_dns"
                                                value={true}
                                                checked={this.state.formConfiguration.auto_dns === true}
                                                disabled={!this.state.formConfiguration.use_dhcp}
                                                onChange={this.auto_dns_changed}
                                            />{' '}
                                            Obtain DNS address automatically
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="radio"
                                                name="set_dns"
                                                value={false}
                                                checked={this.state.formConfiguration.auto_dns === false}
                                                onChange={this.auto_dns_changed}
                                            />{' '}
                                            Use the following DNS server addresses:
                                        </div>

                                        <div className="form-group">
                                            <label className="text-muted">Preferred DNS server</label>
                                            <div className="input-group with-focus">
                                                <Input
                                                    type="text"
                                                    name="preferred_dns"
                                                    disabled={this.state.formConfiguration.auto_dns}
                                                    onChange={this.validateOnChange}
                                                />
                                            </div>

                                            <label className="text-muted">Alternate DNS server</label>
                                            <div className="input-group with-focus">
                                                <Input
                                                    type="text"
                                                    name="alternate_dns"
                                                    disabled={this.state.formConfiguration.auto_dns}
                                                    onChange={this.validateOnChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-10">
                                        <label className="text-muted">Hostname</label>
                                        <div className="input-group with-focus">
                                            <Input
                                                type="text"
                                                name="hostname"
                                                value={this.state.formConfiguration.hostname}
                                                onChange={this.validateOnChange}
                                            />
                                        </div>

                                        <label className="text-muted">Private wireless name</label>
                                        <div className="input-group with-focus">
                                            <Input
                                                type="text"
                                                name="private_wireless_name"
                                                value={this.state.formConfiguration.private_wireless_name}
                                                onChange={this.validateOnChange}
                                            />
                                        </div>
                                    </div>

                                    <button id="btnNext" className="btn btn-block btn-primary mt-3" type="submit">
                                        Send configuration to module
                                    </button>
                                </form>
                            ) : null}

                            {this.state.step === 4 ? (
                                <div>
                                    <table>
                                        <tr>
                                            <td>
                                                <img src="img/BridgeConfiguration.png" alt="" />
                                            </td>
                                            <td>
                                                {this.state.success_text === undefined && this.state.error_text === undefined ? (
                                                    <h3>Time to reset {this.state.timer}</h3>
                                                ) : this.state.success_text !== undefined ? (
                                                    <h3 style={{color: 'green'}}>{this.state.success_text}</h3>
                                                ) : (
                                                    <h3 style={{color: 'red'}}>{this.state.error_text}</h3>
                                                )}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BridgeConfiguration;
