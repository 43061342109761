// Core React
import React from 'react';
import PropTypes from 'prop-types';

// Modules
import {Input, Row, Col, FormGroup, Label} from 'reactstrap';
import FormValidator from '../../../Forms/FormValidator';
import {FormNavigation} from '../../../Wizard/Elements';

function ConfigurationTemplatesForm({configurationTemplate, configurationTemplates, handleConfigurationTemplateForm, onNext, onBack}) {
	const [loading, setLoading] = React.useState(false);
	const [errors, setErrors] = React.useState({});
	const [configurationTemplateId, setConfigurationTemplateId] = React.useState(() => {
		if (!!configurationTemplates && configurationTemplates.length === 1) {
			return configurationTemplates[0].id;
		}
		return configurationTemplate || '';
	});

	const hasError = (formName, inputName, method) => {
		return (
			errors[formName] && errors[formName].errors && errors[formName].errors[inputName] && errors[formName].errors[inputName][method]
		);
	};

	const back = e => {
		if (typeof onBack === 'function') {
			onBack();
		}
	};

	const handleSubmit = e => {
		setLoading(true);
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);

		setErrors(prevErrors => ({
			...prevErrors,
			[form.name]: {
				...prevErrors[form.name],
				errors,
			},
		}));

		if (!hasError) {
			handleConfigurationTemplateForm(configurationTemplateId);
		} else {
			setLoading(false);
		}
	};

	return (
		<Col>
			<form name="configurationTemplates" onSubmit={handleSubmit}>
				<FormNavigation loading={loading} />
				<Row>
					<Col xl={3}>
						<FormGroup>
							<Label for="configurationTemplate">Configuration templates</Label>
							<Input
								id="configurationTemplate"
								name="configuration_template"
								type="select"
								placeholder="Select the configuration templates"
								data-validate='["required"]'
								value={configurationTemplateId}
								onChange={event => setConfigurationTemplateId(event.target.value)}
								invalid={hasError('configurationTemplates', 'configuration_template', 'required')}
							>
								{!configurationTemplateId && <option />}
								{configurationTemplates.map((option, i) => (
									<option key={i} value={option.id}>
										{option.name}
									</option>
								))}
							</Input>
							{hasError('configurationTemplates', 'configuration_template', 'required') && (
								<span className="invalid-feedback">Field is required</span>
							)}
						</FormGroup>
					</Col>
				</Row>
				<FormNavigation onBack={back} loading={loading} />
			</form>
		</Col>
	);
}

ConfigurationTemplatesForm.propTypes = {
	onBack: PropTypes.func,
	onNext: PropTypes.func,
};

export default ConfigurationTemplatesForm;
