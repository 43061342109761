export class NumberFormatter {
	public static FormatCurrency(value: number, currency: string): string {
		try {
			const formatter = Intl.NumberFormat(undefined, {style: 'currency', currency: currency});
			return formatter.format(value);
		} catch (e) {}
		return `${value} ${currency}`;
	}

	public static FormatCurrencyValueOnly(value: number): string {
		const formatter = Intl.NumberFormat(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2});
		return formatter.format(value);
	}
}
