
import Swal from 'sweetalert2';
import pubsub from 'pubsub-js';
import withReactContent from 'sweetalert2-react-content';
import Message from '../../../Shared/Components/Message';
import Shared from '../../../Shared/Shared';
import LimitProfileExtension from '../../../Services/Extensions/LimitProfiles';
import {RequestLogger} from '../../../Infrastructure/Requests/Logger';
import {getLimitProfiles} from '../Queries/LimitProfilesQuery';

export const executeLimitProfileDeleteAction = (parent, limitProfile) => {
    const MySwal = withReactContent(Swal);

    const message = limitProfile.sensors
		?	'<br />' +
			'<br />' +
			'<div class="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon">' +
			'A change affects ' +
			limitProfile.sensors.length +
			' sensor(s). ' +
			Shared.handleAffectedSensors(limitProfile.sensors) +
			' </div>'
			: '';
    MySwal.fire({
        title: 'Are you sure?',
        html: "You won't be able to revert this!" + message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
    }).then(result => {
        if (result.value) {
            LimitProfileExtension.deleteProfile(
                limitProfile.id,
                RequestLogger.createLogData('limit-profiles', 'delete-limit-profile', 'onClick')
            )
                .then(result => {
                    if (result.status === 200) {
                        parent.setState({limitProfiles: []}, () => {
                            getLimitProfiles(parent);
                            Message.success('Limit profile deleted', 'Limit profile successfully deleted');
                        });
                    }
                })
                .catch(error => pubsub.publish('applicationError', error.data.message));
        }
    });
};
