import {LimitAlarmFormState} from '../Forms/LimitAlarmForm/LimitAlarmFormState';

export function AlarmLimitToLimitProfile(limitAlarmFormState: LimitAlarmFormState): any {
	return {
		use_limit_alarms: !!limitAlarmFormState?.alarmLimit,
		limit_alarm_name: limitAlarmFormState?.alarmLimit?.Name ?? null,
		id: limitAlarmFormState?.alarmLimit?.Id ?? null,
		upper_limit: limitAlarmFormState?.alarmLimit?.UpperLimit ?? null,
		upper_limit_2: limitAlarmFormState?.alarmLimit?.UpperLimit2 ?? null,
		upper_limit_3: limitAlarmFormState?.alarmLimit?.UpperLimit3 ?? null,
		upper_limit_4: limitAlarmFormState?.alarmLimit?.UpperLimit4 ?? null,
		lower_limit: limitAlarmFormState?.alarmLimit?.LowerLimit ?? null,
		lower_limit_2: limitAlarmFormState?.alarmLimit?.LowerLimit2 ?? null,
		lower_limit_3: limitAlarmFormState?.alarmLimit?.LowerLimit3 ?? null,
		upper_limit_delay: limitAlarmFormState?.alarmLimit?.UpperLimitDelay ?? null,
		upper_limit_delay_2: limitAlarmFormState?.alarmLimit?.UpperLimitDelay2 ?? null,
		upper_limit_delay_3: limitAlarmFormState?.alarmLimit?.UpperLimitDelay3 ?? null,
		upper_limit_delay_4: limitAlarmFormState?.alarmLimit?.UpperLimitDelay4 ?? null,
		lower_limit_delay: limitAlarmFormState?.alarmLimit?.LowerLimitDelay ?? null,
		lower_limit_delay_2: limitAlarmFormState?.alarmLimit?.LowerLimitDelay2 ?? null,
		lower_limit_delay_3: limitAlarmFormState?.alarmLimit?.LowerLimitDelay3 ?? null,
		upper_limit_warning: limitAlarmFormState?.alarmLimit?.UpperLimitWarning ?? null,
		upper_limit_warning_delay: limitAlarmFormState?.alarmLimit?.UpperLimitWarningDelay ?? null,
		lower_limit_warning: limitAlarmFormState?.alarmLimit?.LowerLimitWarning ?? null,
		lower_limit_warning_delay: limitAlarmFormState?.alarmLimit?.LowerLimitWarningDelay ?? null,
		limit_alarm_creation_type: limitAlarmFormState?.alarmLimit?.Id ?? null,
		email_notification: limitAlarmFormState?.recipients.email.enabled ?? false,
		email_users: limitAlarmFormState?.recipients.email.recipients.map(u => u.Id) ?? [],
		sms_notification: limitAlarmFormState?.recipients.sms.enabled ?? false,
		sms_users: limitAlarmFormState?.recipients.sms.recipients.map(u => u.Id) ?? [],
	};
}
