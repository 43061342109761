function isPlaceHolder(value) {
    if (value === undefined || value === null)
        return false;

    if (typeof value !== "string")
        return false

    return value.charCodeAt(0) === 37 && value.charCodeAt(value.length - 1) === 37;
}

function getPlaceHolderValue(object, placeholder) {
    if (object === undefined || object === null)
        return false;

    const keys = placeholder.replace(/^\%+|\%+$/g, '').split('.');
    const value = keys.reduce((acc, key) => acc[key] || {}, object);

    return typeof value === "object" ? "" : value;
}

const getDefaultValue = (value, initValue) => isPlaceHolder(value) ? getPlaceHolderValue(initValue, value) : value;
const getDefaultsValues = (values, initValue) => values.map(option => {
    if (isPlaceHolder(option.value)) {
        const placeHolderValue = getPlaceHolderValue(initValue, option.value);
        option.alias = placeHolderValue;
        option.value = placeHolderValue;
    }

    return option;
})
.filter(option => !!option.alias);

/**
 * Returns the default value of a template section
 */
const getTemplateSectionValue = (sectionTemplate, initValue) => 
{
    const getFieldValue = (field, itemKey, fieldKey = null) => {
        const fieldSchema = fieldKey !== null ? sectionTemplate[itemKey][fieldKey] : sectionTemplate[itemKey];
        const fieldFormValue = fieldKey !== null ? initValue[itemKey][field.id] : initValue[field.id];

        return  fieldFormValue || fieldSchema.default;
    }

    return Object.keys(sectionTemplate).reduce((valueAcc, itemKey) => 
        {
            if (sectionTemplate[itemKey].write_to_device === false)
                return valueAcc;

            let addedKey, addedValue;
            const hasMultipleFields = sectionTemplate[itemKey].id === undefined;
            if (!hasMultipleFields) {
                addedKey = sectionTemplate[itemKey].id;
                addedValue = getFieldValue(sectionTemplate[itemKey], itemKey);
            } else {
                let subfieldsValue = Object.keys(sectionTemplate[itemKey]).reduce(
                    (acc, fieldKey) => {
                        if (sectionTemplate[itemKey][fieldKey].id !== undefined) {
                            return { ...acc, [sectionTemplate[itemKey][fieldKey].id]: getFieldValue(sectionTemplate[itemKey][fieldKey], itemKey, fieldKey) };
                        }
                        return acc;
                    }, {}
                );

                addedKey = itemKey;
                addedValue = subfieldsValue;
            }

            return { ...valueAcc, [addedKey]: addedValue };
        }, initValue
    );
};

const fillSchemePlaceHolder = (sectionTemplate, value) => {
    Object.values(sectionTemplate).forEach(itemValue => {
        if (itemValue.id !== undefined) {
            itemValue.default = getDefaultValue(itemValue.default, value);
            if (!!itemValue.defaults) {
                itemValue.defaults = getDefaultsValues(itemValue.defaults, value);
            }
        } else {
            Object.keys(itemValue).forEach(fieldKey => {
                if (typeof itemValue[fieldKey] === "object") {
                    itemValue[fieldKey].default = getDefaultValue(itemValue[fieldKey].default, value);
                    if (!!itemValue[fieldKey].defaults) {
                        itemValue[fieldKey].defaults = getDefaultsValues(itemValue[fieldKey].defaults, value);
                    }
                }
            });
        }
    });
};

const FormUtils = {
    isPlaceHolder,
    getPlaceHolderValue,
    getTemplateSectionValue,
    fillSchemePlaceHolder
}

export default FormUtils;
