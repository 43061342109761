import React from "react";
import Datetime from 'react-datetime'
import { Label } from "reactstrap";
import PropTypes from 'prop-types';
import ErrorDisplay from "./../ErrorDisplay";
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';

const DatetimeControl = React.forwardRef(({
    id,
    name,
    label,
    alias,
    value,
    disabled,
    showErrors,
    errors,
    handleChange,
    handleBlur,
    dataToValidate,
    ...rest
}, ref) => {

    const [datetime, setDatetime] = React.useState(() =>
        !!value ? 
            rest.subtype === "unix" ?
                new Date(value*1000) :
                new Date(value)
            : null
    );

    const onChange = e => {
        let utcDatetime;
        if (rest.subtype === "unix")
        {
            utcDatetime = Math.round(new Date(e).getTime() / 1000);
        } else {
            utcDatetime = DateTimeUtils.getUTCDateTimeFormat(e);
        }

        const fackEvent = { target: { value: utcDatetime } };

        setDatetime(e)
        handleChange(fackEvent)
    }

    return <>
        <Label for={id}>{label}</Label>
        <Datetime
            inputProps={{ ref, id, name, autocomplete: "off", className: 'form-control', placeholder: `Enter ${alias}` }}
            timeFormat="HH:mm"
            dateFormat="DD.MM.YYYY"
            value={datetime}
            data-validate={dataToValidate}
            className={`${(showErrors && !disabled && errors.length > 0) ? 'is-invalid' : ''}`}
            disabled={disabled}
            onChange={onChange}
            onBlur={handleBlur}
            closeOnSelect={true}
        />

        {showErrors && !disabled && errors.map((error, i) => (
            <ErrorDisplay key={i} error={error} params={[rest.max_length || 0]} />
        ))}
    </>;
});

DatetimeControl.displayName = 'DatetimeControl';

DatetimeControl.propTypes = {
    errors: PropTypes.array,
    handleChange: PropTypes.func
}

DatetimeControl.defaultProps = {
    errors: [],
    handleChange: () => { }
};

export default DatetimeControl;