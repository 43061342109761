const PrepareDescText = (tstamp, desc) => {
	return (
		'' +
		'<div style="overflow-x:auto; border: 1px outset #c7f6ff;">' +
		'<table>' +
		'  <tr><td style="color:blue;">' +
		tstamp +
		'</td></tr>' +
		'  <tr><td>' +
		desc +
		'</td></tr>' +
		'</table>' +
		'</div>'
	);
};

export default PrepareDescText;
