const jsonFile = require('./logger.json');
class RequestLogger {
    constructor() {
        this._logging = false;
        this._disableAliveRefreshRequests = true;
        this._logInBrowserConsole = false;
    }

    get logAliveRefreshRequests() {
        return this._disableAliveRefreshRequests;
    }

    set logAliveRefreshRequests(disable) {
        this._disableAliveRefreshRequests = disable;
    }

    get startLogging() {
        return this._logging;
    }

    set startLogging(trigger) {
        this._logging = trigger;
    }

    get logOutputToBrowserConsole() {
        return this._logInBrowserConsole;
    }

    set logOutputToBrowserConsole(state) {
        this._logInBrowserConsole = state;
    }

    createLogData = (moduleName, eventName, eventTrigger) => {
        return {
            module: moduleName,
            event: eventName,
            trigger: eventTrigger,
            timeStamp: new Date().toISOString(),
        };
    };

    log = log => {
        if (this._logging === false) return;
        this.logToBrowserConsole(log, this.logOutputToBrowserConsole);
        if (this.disableAliveAndRefresh(log, this.logAliveRefreshRequests)) return;
        this.sendOutputToJSON(log);
    };

    sendOutputToJSON = data => {
        jsonFile['log'].push(data);
    };

    getLogDataJSON = () => {
        return jsonFile;
    };

    logToBrowserConsole = (log, enable) => {
        if (!enable) return;
        console.group('id: ' + log.id);
        console.log(log);
        console.groupEnd();
    };

    disableAliveAndRefresh = (log, state) => {
        const url = log.url || log.requestURL;
        return (url.includes('/rpc/alive') || url.includes('/rpc/refresh')) && state;
    };
}

const logger = new RequestLogger();
export default logger;
