import {Sensor} from '../../Common/Types/Sensor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SensorService from '../../Dashboard/Sensor/SensorService';
import {RequestLogger} from '../../Infrastructure/Requests/Logger';
import {SweetAlertDialogResult} from './Common/SweetAlertDialogResult';
import {DialogResult} from './Common/DialogResult';
import { CancelOrDowngradeSubscription } from '../../../components/Wizard/Util/LicensingUtils';

export function ShowDeleteSensorDialog(sensor: Sensor): Promise<DialogResult> {
	const gxAdditionalInfo = sensor.LiberoGStateDescription
		? /* HTML */
		  `<p style="font-size:13px; line-height:25px;">
				<br />Before deleting your device, <span style="font-weight: bold">please read this information</span> carefully:
				<br />
				<a href="https://www.elpro.com/en/support/elpro-cloud/delete" target="_blank" class="important-link">
					Effects of deleting your device</a
				>
		  </p> `
		: '';

	const MySwal = withReactContent(Swal);
	return MySwal.fire({
		title: "<span id='delete_sensor_in_dashboard'>Are you sure?</span>",
		html: "You won't be able to revert this!" + gxAdditionalInfo,
		input: 'password',
		inputAttributes: {
			autocapitalize: 'off',
			placeholder: 'Enter your password',
		},
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#d33',
		cancelButtonColor: '#3085d6',
		confirmButtonText: 'Yes, delete it!',
		preConfirm: password => {
			return SensorService.deleteSensor(
				{
					sensors_id: sensor.Id,
					pass: password,
				},
				RequestLogger.createLogData('dashboard', 'delete-sensor', 'onClick')
			)
				.then(() => CancelOrDowngradeSubscription(sensor))
				.catch(error => {
					if (error.data) {
						const msg = error.data.message;
						if (msg === 'error.invalidPassword') MySwal.showValidationMessage('Invalid Password');
						else MySwal.showValidationMessage(msg);
					} else MySwal.showValidationMessage('Operation failed');
				});
		},
	}).then(result => new SweetAlertDialogResult(result));
}
