import styles from './styles/styles.module.scss';
import React from 'react';
import {Link} from 'react-router-dom';

export const InfoView = ({version, organisationId}) => {
	return (
		<ul className={[styles.infoView, styles.sidebarFooter].join(' ')}>
			<li>
				<a
					href="https://www.elpro.cloud/support/elpro-cloud/software-and-data-information"
					title="FAQ Software, Performance & Data Handling"
					target="_blank"
					rel="noopener noreferrer"
				>
					FAQ Software & Data
				</a>
			</li>
			<li id="organization-id">Organization ID: {organisationId}</li>
			<li id="app-version">Version: {version}</li>
			<li id="oss-licences">
				<Link to={'/openSourceLicenses'}>Open source licenses</Link>
			</li>
		</ul>
	);
};
