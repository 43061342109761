import request from '../../Infrastructure/Requests/Request';
import Authentication from '../../Infrastructure/Authentication/Authentication';

const useLocalhost =
	process.env?.NODE_ENV === 'development' && process.env.REACT_APP_SERVICE_USE_LOCALHOST?.includes('LEGACY_LICENSING_SERVICE');

const licensingBaseUrl: string = useLocalhost ? 'https://localhost:44381' : '';

function licenses(params, eventData) {
	return request({
		url:  '/licenses',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function getSensorLicense(id, eventData) {
	return request({
		url: '/licenses?sensors_id=eq.' + id,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function extendLicenseForOneYear(payload) {
	return request({
		url: `${licensingBaseUrl}/licensing/extendLicenseOneYear`,
		method: 'POST',
		token: Authentication.getToken(),
		data: payload,
	});
}

function retireLicenseForOneYear(payload) {
	return request({
		url: `${licensingBaseUrl}/licensing/retireSensorOneYear`,
		method: 'POST',
		token: Authentication.getToken(),
		data: payload,
	});
}

function changeLicenseFeatures(payload, eventData) {
	return request({
		url: `${licensingBaseUrl}/licensing/changeLicenseFeatures`,
		method: 'POST',
		token: Authentication.getToken(),
		data: payload,
		eventData,
	});
}

function rechargeCredit(payload, eventData) {
	return request({
		url: '/rpc/organization_prepaid_up',
		method: 'POST',
		token: Authentication.getToken(),
		data: {
			payment_info: payload,
		},
		eventData,
	});
}

function setAutomaticLicenseRenewalBySensorId(sensor_id, on, checkIntegrity = false, eventData) {
	const payload = {
		sensors_id: sensor_id,
		renewal_on: on,
		throw_warnings: checkIntegrity,
	};
	return request({
		url: '/rpc/sensors_automatic_license_renewal_set',
		method: 'POST',
		token: Authentication.getToken(),
		data: payload,
		eventData,
	});
}

const LicensingService = {
	licenses,
	getSensorLicense,
	extendLicenseForOneYear,
	retireLicenseForOneYear,
	changeLicenseFeatures,
	setAutomaticLicenseRenewalBySensorId,
	rechargeCredit,
};

export default LicensingService;
