const locale: string = 'en';
const baseUrl: string = `https://www.elpro.cloud/${locale}/support`;

export type KnowledgeBaseItem = {
	visible: boolean;
	url: string;
	linkText: string;
	title: string;
};

export const GetKnowledgeBaseUrl = (url: string): string => `${baseUrl}/${url}`;
export const KnowledgeBase: {[key: string]: KnowledgeBaseItem[]} = {
	'009': [
		{
			visible: true,
			url: 'elpro-cloud',
			linkText: 'Visit Knowledge Base',
			title: 'List view ELPRO Cloud',
		},
	],
	'010': [
		{
			visible: true,
			url: 'elpro-cloud/dashboard',
			linkText: 'Visit Knowledge Base',
			title: 'Dashboard overview ELPRO Cloud',
		},
	],
	'011': [
		{
			visible: true,
			url: 'elpro-cloud/get-started',
			linkText: 'Visit Knowledge Base',
			title: 'How to start and configure your sensor',
		},
	],
	'020': [
		{
			visible: true,
			url: 'elpro-cloud/deviations',
			linkText: 'Visit Knowledge Base',
			title: 'How to deal with deviations',
		},
	],
	'030': [
		{
			visible: true,
			url: 'elpro-cloud/audit-trail',
			linkText: 'Visit Knowledge Base',
			title: 'How to analyse your audit trail ',
		},
	],
	'040': [
		{
			visible: true,
			url: 'manage-limit-profiles',
			linkText: 'Visit Knowledge Base',
			title: 'How to manage upper and lower limit profiles',
		},
	],
	'041': [
		{
			visible: true,
			url: 'manage-issue-profiles',
			linkText: 'Visit Knowledge Base',
			title: 'How to manage alarm issue profiles',
		},
	],
	'042': [
		{
			visible: true,
			url: 'licenses',
			linkText: 'Visit Knowledge Base',
			title: 'License overview and renewal',
		},
	],
	'043': [
		{
			visible: true,
			url: 'invoices-and-cards',
			linkText: 'Visit Knowledge Base',
			title: 'How to update your credit card & payment settings',
		},
	],
	'044': [
		{
			visible: true,
			url: 'organization',
			linkText: 'Visit Knowledge Base',
			title: 'How to manage organization settings',
		},
	],
	'045': [
		{
			visible: true,
			url: 'manage-user-profile-settings',
			linkText: 'Visit Knowledge Base',
			title: 'How to manage user profile settings',
		},
	],
	'046': [
		{
			visible: true,
			url: 'elpro-cloud/sensor-groups',
			linkText: 'Visit Knowledge Base',
			title: 'How to manage sensor groups',
		},
	],
	'047': [
		{
			visible: true,
			url: 'user-management',
			linkText: 'Visit Knowledge Base',
			title: 'How to operate user management',
		},
	],
	'050': [
		{
			visible: true,
			url: 'elpro-cloud/sensor-analysis#reports',
			linkText: 'Visit Knowledge Base',
			title: 'How to create reports',
		},
	],
	'090': [
		{
			visible: true,
			url: 'kb-tickets/new',
			linkText: 'Create Support Ticket',
			title: 'Create Support Ticket',
		},
		{
			visible: true,
			url: 'elpro-cloud/upcoming-features',
			linkText: 'Learn more...',
			title: 'Upcoming Features of ELPRO Cloud',
		},
		{
			visible: true,
			url: 'elpro-cloud/software-and-data-information',
			linkText: 'Read FAQ',
			title: 'FAQ Software, Performance & Data Handling',
		},
		{
			visible: true,
			url: '',
			linkText: '',
			title: 'Support Code: {supportCode}',
		},
		{
			visible: true,
			url: '',
			linkText: '',
			title: 'Version: {version}',
		},
	],
	'091': [
		{
			visible: true,
			url: '',
			linkText: '',
			title: 'Only effective if supported by the device on which the profile is applied.',
		},
	],
	'092': [
		{
			visible: true,
			url: 'select-a-communication-mode',
			linkText: 'Visit Knowledge Base',
			title: 'Description of communication modes',
		},
	],
};
