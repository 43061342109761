import React from 'react';
import {getDisplayedSegments} from './LCDDisplayHelper';

/**
 *  111111111111111
 * 6  7    8    9  2
 * 6    7  8  9    2
 *   10 10   11 11
 * 5   12 13 14    3
 * 5 12   13   14  3
 *  444444444444444
 */
export const DigitsLCDDisplay: {[key: string]: number[]} = {
	'0': [1, 2, 3, 4, 5, 6, 9, 12],
	'1': [2, 3, 9],
	'2': [1, 2, 11, 10, 5, 4],
	'3': [1, 2, 3, 4, 11],
	'4': [6, 10, 11, 2, 3],
	'5': [1, 6, 10, 11, 3, 4],
	'6': [1, 6, 10, 11, 3, 4, 5],
	'7': [1, 2, 3],
	'8': [1, 2, 6, 10, 11, 3, 4, 5],
	'9': [1, 2, 6, 10, 11, 3, 4],
	A: [1, 2, 3, 5, 6, 10, 11],
	B: [1, 2, 3, 4, 8, 13, 11],
	C: [1, 4, 5, 6],
	D: [1, 2, 3, 4, 8, 13],
	E: [1, 4, 5, 6, 10, 11],
	F: [1, 5, 6, 10, 11],
	G: [1, 4, 5, 6, 3, 11],
	H: [2, 3, 5, 6, 10, 11],
	I: [1, 4, 8, 13],
	J: [2, 3, 4, 5],
	K: [6, 5, 10, 9, 14],
	L: [5, 6, 4],
	M: [6, 5, 2, 3, 7, 9],
	N: [5, 6, 7, 14, 2, 3],
	O: [1, 2, 3, 4, 5, 6],
	P: [1, 2, 11, 10, 6, 5],
	Q: [1, 2, 3, 4, 5, 6, 14],
	R: [1, 2, 11, 10, 6, 5, 14],
	S: [1, 7, 11, 3, 4],
	T: [1, 8, 13],
	U: [2, 3, 4, 5, 6],
	V: [6, 5, 12, 9],
	W: [6, 5, 12, 14, 3, 2],
	X: [7, 9, 12, 14],
	Y: [7, 9, 13],
	Z: [1, 9, 12, 4],
	'+': [8, 13, 10, 11],
	'-': [10, 11],
	'*': [7, 8, 9, 10, 11, 12, 13, 14],
	'/': [9, 12],
	'\\': [7, 14],
	'|': [8, 13],
	'{': [9, 11, 14],
	'}': [7, 10, 12],
	'(': [9, 14],
	')': [7, 12],
	'%': [6, 7, 10, 9, 12, 14, 3, 11],
	'°': [1, 2, 11, 10, 6],
	'<': [9, 14],
	'>': [7, 12],
	' ': [],
};

function LCDValueDisplay({value}) {
	const segments = getDisplayedSegments(value);

	const getSeparator = (index: number) => {
		return (
			segments[index] && (
				<span className={`${segments[index].comma ? 'lcd-comma' : ''} ${segments[index].points ? 'lcd-points' : ''}`}></span>
			)
		);
	};

	const isSegmentLineActive = (displayIdx: number, segmentIdx: number) => {
		if (!!segments[displayIdx]) {
			const character = segments[displayIdx].value;
			const activeElements = DigitsLCDDisplay[character] || [];
			return activeElements.includes(segmentIdx + 1);
		}

		return false;
	};

	const getSegmentClassName = (displayIdx: number, segmentIdx: number): string =>
		`lcd-segment__el ${isSegmentLineActive(displayIdx, segmentIdx) ? 'fill' : ''}`;

	return (
		<>
			{[...Array(2)].map((_, displayGroupIdx) => (
				<div className="lcd-display" key={displayGroupIdx}>
					{[...Array(8)]
						.map((_, idx) => displayGroupIdx * 8 + idx)
						.map(displayIdx => (
							<span className="lcd-segment" key={displayIdx}>
								<>
									{[...Array(14)].map((_, segmentIdx) => (
										<i key={segmentIdx} className={getSegmentClassName(displayIdx, segmentIdx)}></i>
									))}
									{getSeparator(displayIdx)}
								</>
							</span>
						))}
				</div>
			))}
		</>
	);
}

export default LCDValueDisplay;
