import React, {Component} from 'react';
import {Row, Col, Input, Label, FormGroup} from 'reactstrap';
import {Trans} from 'react-i18next';
import PropTypes from 'prop-types';
import FormValidator from '../../../../Forms/FormValidator';
import {Select, Alert, Popconfirm, Button} from 'antd';
import Shared from '../../../../Shared/Shared';
import SensorService from '../../SensorService';
import {showNoPhoneNumberWarning} from '../../Utils/RegistryHelper';
import {loadUsersDataSource} from '../../../../Services/UserManagement/UserMangementService';
import {getUser} from '../../../../Settings/UserProfile/UserProfileService';
import {RequestLogger} from '../../../../Infrastructure/Requests/Logger';
import IssueProfileSelectComponent from './IssueProfileSelectComponent';
import MissingCommunicationWarningSelectComponent from './MissingCommunicationWarningSelectComponent';
import {FormNavigation} from '../../../../Wizard/Elements';
import CurrentPrice from '../../../../Common/CurrentPrice';
import {CurrentPriceResult} from '../../../../../common/util/LicenseUtils/CurrentPriceExtension';
import {MODULE_FAMILIES} from '../../../../Shared/Constants/Module';
import {FeatureFlag, FeatureFlagHelper} from '../../../../../common/helpers';
import SmsDowngradeWarningAlert from '../../Utils/SmsDowngradeWarningAlert';

const {Option} = Select;

type SensorIssuesFormComponentProps = {
	sensorIssuesForm: any;
	issueProfiles: any;
	sensorBasicsForm: any;
	sensorSerialNumber: string | undefined;
	isLiberoG: boolean | undefined;
	handleIssueAlarmInputChange: any;
	onNext: any;
	onBack: any;
	makeIssueAlarmsEditable: any;
	handleIssueAlarmCreationTypeChange: any;
	handleUseSensorIssuesChecked: any;
	sms_checkbox_disabled: boolean | undefined;
	currentPrice: CurrentPriceResult;
	templateName: string;
};

class SensorIssuesFormComponentState {
	noPhoneNumberWarning: any;
	setting: any;
	loading: boolean;
	emailList: any;
	smsList: any;
	logginInterval: any;
	suggestedDelay: any;
	transKeyLogginInterval: any;
	users: any;
	formSensorIssues: any;
	[x: number]: any;
	featureFlags: string[];
}

class SensorIssuesFormComponent extends Component<SensorIssuesFormComponentProps, SensorIssuesFormComponentState> {
	static propTypes = {
		onBack: PropTypes.func,
		onNext: PropTypes.func,
	};

	constructor(props: SensorIssuesFormComponentProps | Readonly<SensorIssuesFormComponentProps>) {
		super(props);

		let that = this;
		// handle when the sensor is being edited and we jump to this form for the first time
		if (
			this.props.sensorIssuesForm.use_issue_alarms &&
			this.props.sensorIssuesForm.issue_alarm_creation_type !== 0 &&
			this.props.sensorIssuesForm.editable === false
		) {
			let profile = undefined;

			this.props.issueProfiles.forEach(function (item) {
				if (item.id === that.props.sensorIssuesForm.issue_alarm_creation_type) {
					profile = item;
				}
			});

			if (profile !== undefined) {
				this.props.sensorIssuesForm.limit_issue_name = profile.name;
				this.props.sensorIssuesForm.missing_value_alarm = profile.missing_value_alarm; //Not used anymore
				this.props.sensorIssuesForm.missing_value_alarm_delay = profile.missing_value_alarm_delay; //Not used aynmore
				this.props.sensorIssuesForm.lost_measurement_alarm = profile.lost_measurement_alarm;
				this.props.sensorIssuesForm.missing_communication_warning = profile.missing_communication_warning;
				this.props.sensorIssuesForm.missing_communication_warning_enum = profile.missing_communication_warning_enum;
				this.props.sensorIssuesForm.light_warning = profile.light_warning;
				this.props.sensorIssuesForm.tilt_warning = profile.tilt_warning;
				this.props.sensorIssuesForm.sensor_failure_alarm = profile.sensor_failure_alarm;
				this.props.sensorIssuesForm.sensor_failure_alarm_delay = profile.sensor_failure_alarm_delay;
				this.props.sensorIssuesForm.radio_connection_warning = profile.radio_connection_warning; //Not used anymore https://elproag.atlassian.net/browse/TH-460
				this.props.sensorIssuesForm.radio_connection_warning_delay = profile.radio_connection_warning_delay; //Not used anymore https://elproag.atlassian.net/browse/TH-460
				this.props.sensorIssuesForm.radio_connection_warning_threshold = profile.radio_connection_warning_threshold; //Not used anymore https://elproag.atlassian.net/browse/TH-460
				this.props.sensorIssuesForm.low_battery_warning = profile.low_battery_warning;
				this.props.sensorIssuesForm.sensors = Shared.getAffectedSensorsExcludeCurrent(profile.sensors, props.sensorSerialNumber);
			} else {
				this.props.sensorIssuesForm.use_issue_alarms = false;
			}
		} else {
			this.props.sensorIssuesForm.editable = false;
			this.props.sensorIssuesForm.limit_issue_name = '';
			this.props.sensorIssuesForm.missing_value_alarm = false; //Not used anymore
			this.props.sensorIssuesForm.missing_value_alarm_delay = 1; //Not used anymore
			this.props.sensorIssuesForm.lost_measurement_alarm = false;
			this.props.sensorIssuesForm.missing_communication_warning = false;
			this.props.sensorIssuesForm.missing_communication_warning_enum = 0;
			this.props.sensorIssuesForm.light_warning = false;
			this.props.sensorIssuesForm.tilt_warning = false;
			this.props.sensorIssuesForm.sensor_failure_alarm = false;
			this.props.sensorIssuesForm.sensor_failure_alarm_delay = 0;
			this.props.sensorIssuesForm.radio_connection_warning = false; //Not used anymore https://elproag.atlassian.net/browse/TH-460
			this.props.sensorIssuesForm.radio_connection_warning_delay = 1; //Not used anymore https://elproag.atlassian.net/browse/TH-460
			this.props.sensorIssuesForm.radio_connection_warning_threshold = -90; //Not used anymore https://elproag.atlassian.net/browse/TH-460
			this.props.sensorIssuesForm.low_battery_warning = false;
		}

		const intreval = parseInt(this.props.sensorBasicsForm.logging_interval);
		const suggestedDelay = this.props.sensorBasicsForm.communication_interval / (this.props.sensorBasicsForm.logging_interval / 60);

		this.state = {
			setting: this.getConfig(),
			loading: false,
			emailList: [],
			smsList: [],
			logginInterval: intreval,
			suggestedDelay: suggestedDelay, //This will be taken into consideration for only Ecolog Pro G.
			noPhoneNumberWarning: [],
			users: undefined,
			formSensorIssues: undefined,
			featureFlags: [],
			transKeyLogginInterval:
				intreval === 60
					? 'sensorWizard.sensorIssuesForm.logginInterval1Minute'
					: intreval === 120
					? 'sensorWizard.sensorIssuesForm.logginInterval2Minutes'
					: null,
		};

		this.updateEmailList();
		this.updateSmsList();
	}

	async componentDidMount() {
		this.setState({
			featureFlags: await FeatureFlagHelper.GetFeatureFlags(),
		});
	}

	getConfig = () => {
		let setting = {
			'allow-sms-single': true,
			'allow-sms-multi': false,
		};

		SensorService.getOrganizationSetting('global').then(response => {
			if (this.props.isLiberoG) {
				setting['allow-sms-single'] = response.data.setting['allow-liberog-sms-single'];
				setting['allow-sms-multi'] = response.data.setting['allow-liberog-sms-multi'];
			} else {
				setting['allow-sms-single'] = response.data.setting['allow-ecolog-sms-single'];
				setting['allow-sms-multi'] = response.data.setting['allow-ecolog-sms-multi'];
			}
		});
		return setting;
	};

	updateSmsList = () => {
		let list = [];
		loadUsersDataSource().then(users => {
			getUser(RequestLogger.createLogData('sensor-issue', 'update-sms-list', 'onClick')).then(operatingUser => {
				//push operating user
				let oNumber = operatingUser.data.mobile ? '(' + operatingUser.data.mobile + ')' : '(No phone number available)';

				list.push(
					<Option
						title={operatingUser.data.mobile}
						id={operatingUser.data.id}
						key={operatingUser.data.first_name + ' ' + operatingUser.data.last_name + ' ' + oNumber}
					>
						{operatingUser.data.first_name} {operatingUser.data.last_name} {oNumber}
					</Option>
				);
				if (this.props.sensorIssuesForm.sms_users.length === 0) {
					this.props.handleIssueAlarmInputChange({target: {name: 'sms_users', value: [operatingUser.data.id]}});
				}
				//push rest of users
				users.forEach(user => {
					let number = user.mobile ? '(' + user.mobile + ')' : '(No phone number available)';
					list.push(
						<Option
							// disabled={user.mobile ? false : true}
							title={user.mobile}
							id={user.id}
							key={user.first_name + ' ' + user.last_name + ' ' + number + user.id}
						>
							{user.first_name} {user.last_name} {number}
						</Option>
					);
				});

				this.setState({smsList: list});
			});
		});
	};

	updateEmailList = () => {
		let list = [];
		loadUsersDataSource().then(users => {
			getUser(RequestLogger.createLogData('sensor-issue', 'update-email-list', 'onClick')).then(operatingUser => {
				let origList = JSON.parse(JSON.stringify(users));
				origList.push(JSON.parse(JSON.stringify(operatingUser.data)));

				//push operating user
				list.push(
					<Option
						title={operatingUser.data.email}
						id={operatingUser.data.id}
						key={
							operatingUser.data.first_name +
							' ' +
							operatingUser.data.last_name +
							' ' +
							'(' +
							operatingUser.data.email +
							')' +
							operatingUser.data.id
						}
					>
						{operatingUser.data.first_name} {operatingUser.data.last_name} {'(' + operatingUser.data.email + ')'}
					</Option>
				);
				if (this.props.sensorIssuesForm.email_users.length === 0) {
					this.props.handleIssueAlarmInputChange({target: {name: 'email_users', value: [operatingUser.data.id]}});
				}
				//push rest of users
				users.forEach(user => {
					list.push(
						<Option title={user.email} id={user.id} key={user.first_name + ' ' + user.last_name + ' ' + '(' + user.email + ')'}>
							{user.first_name} {user.last_name} {'(' + user.email + ')'}
						</Option>
					);
				});

				this.setState(prevState => ({...prevState, emailList: list, users: origList}));
			});
		});
	};

	hasError = (formName, inputName, method) => {
		return (
			this.state[formName] &&
			this.state[formName].errors &&
			this.state[formName].errors[inputName] &&
			this.state[formName].errors[inputName][method]
		);
	};

	back = () => {
		this.setState({logginInterval: '0'});
		if (typeof this.props.onBack === 'function') {
			this.props.onBack();
		}
	};

	editable = e => {
		if (typeof this.props.makeIssueAlarmsEditable === 'function') {
			this.props.makeIssueAlarmsEditable();
		}
		e.preventDefault();
	};

	onSubmit = e => {
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		// const {errors, hasError} = FormValidator.bulkValidate(inputs);
		const inputNoSelect = this.removeNoName(inputs);
		let {errors, hasError} = FormValidator.bulkValidate(inputNoSelect);

		// this.setErrorMessages(errors, inputNoSelect);

		this.setState({
			[form.name]: {
				...this.state[form.name],
				errors,
			},
			loading: true,
		});

		if (!hasError) {
			if (typeof this.props.onNext === 'function') {
				if (this.state.noPhoneNumberWarning.length > 0) {
					showNoPhoneNumberWarning(() => {
						this.props.onNext();
					}, this.state.noPhoneNumberWarning);
					this.setState({
						loading: false,
					});
				} else {
					this.props.onNext();
				}
			}
		} else {
			this.setState({
				loading: false,
			});
		}

		e.preventDefault();
	};

	removeNoName = inputs => {
		inputs.map(input => {
			if (input.name === '' && input.id !== '') {
				input.name = input.id;
			}
			if (input.id.includes('selectEmailUsers')) {
				input.dataset.validate =
					this.props.sensorIssuesForm.email_notification === true ? JSON.stringify(['emailUserSelected']) : null;
				input.dataset.param =
					this.props.sensorIssuesForm.email_notification === true
						? this.props.sensorIssuesForm.email_users.length > 0
							? ''
							: 'error'
						: '';
			}
			if (input.id.includes('selectSmsUsers')) {
				input.dataset.validate = this.props.sensorIssuesForm.sms_notification === true ? JSON.stringify(['smsUserSelected']) : null;
				input.dataset.param =
					this.props.sensorIssuesForm.sms_notification === true
						? this.props.sensorIssuesForm.sms_users.length > 0
							? ''
							: 'error'
						: '';
			}
		});
		return inputs;
	};

	getUserKeys = (list, type) => {
		let keyList = [];

		let userI;
		list.forEach(id => {
			userI =
				type === 'email'
					? this.state.emailList.find(user => user.props.id === id)
					: this.state.smsList.find(user => user.props.id === id);
			if (userI !== undefined) {
				keyList.push(userI.key);
			}
		});

		return keyList;
	};

	handleMultiEmailChange = (e, value) => {
		let userIds = [];

		value.forEach(user => {
			userIds.push(user.id);
		});

		this.props.handleIssueAlarmInputChange({target: {name: 'email_users', value: userIds}});

		// setSelectedUserToNotifyForAlarm(value);
	};

	handleMultiSmsChange = (e, value) => {
		let userIds = [];
		let userOrig = this.state.users[0];
		this.setState({noPhoneNumberWarning: []});

		let phoneNrWarning = [];

		if (!Array.isArray(value)) value = [value];

		value.forEach(user => {
			userIds.push(user.id);
			userOrig = this.state.users.find(x => x.id === user.id);

			//check if has phone number, else add warning
			if (userOrig !== null && !userOrig.mobile) {
				phoneNrWarning.push(userOrig.first_name + ' ' + userOrig.last_name);
				this.setState({noPhoneNumberWarning: phoneNrWarning});
			}
		});

		this.props.handleIssueAlarmInputChange({target: {name: 'sms_users', value: userIds}});
	};

	render() {
		return (
			<form id="formSensorIssues" name="formSensorIssues" onSubmit={this.onSubmit}>
				<FormNavigation loading={this.state.loading} />
				<Row>
					<Col>
						<FormGroup>
							<label className="checkbox c-checkbox">
								<Input
									id="checkboxUseSensorIssues"
									name="use_issue_alarms"
									type="checkbox"
									checked={this.props.sensorIssuesForm.use_issue_alarms}
									onChange={this.props.handleUseSensorIssuesChecked}
								/>
								<span className="fa fa-check" /> Activate issue alarms
							</label>
						</FormGroup>
					</Col>
				</Row>
				<IssueProfileSelectComponent
					disabled={!this.props.sensorIssuesForm.use_issue_alarms}
					onChange={this.props.handleIssueAlarmCreationTypeChange}
					issueProfiles={this.props.issueProfiles}
					value={
						this.props.sensorIssuesForm.issue_alarm_creation_type
							? this.props.sensorIssuesForm.issue_alarm_creation_type
							: undefined
					}
				/>
				<br />
				<Row>
					<Col xl={3}>
						<FormGroup key={'proNameFI'} size="small">
							<Label for="inputSensorIssuesName">Profile name</Label>
							<Input
								type="text"
								id="inputSensorIssuesName"
								name="limit_issue_name"
								placeholder="Profile name"
								data-validate={this.props.sensorIssuesForm.use_issue_alarms ? '["required"]' : null}
								invalid={
									this.props.sensorIssuesForm.use_issue_alarms && !this.props.sensorIssuesForm.limit_issue_name
										? this.hasError('formSensorIssues', 'limit_issue_name', 'required')
										: null
								}
								value={this.props.sensorIssuesForm.limit_issue_name}
								onChange={this.props.handleIssueAlarmInputChange}
								disabled={true}
							/>
							{this.hasError('formSensorIssues', 'limit_issue_name', 'required') && (
								<span className="invalid-feedback">Field is required</span>
							)}
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col xl={3}>
						<label className="checkbox c-checkbox">
							<Input
								type="checkbox"
								id="checkboxMissingCommunicationWarning"
								name="missing_communication_warning"
								onChange={this.props.handleIssueAlarmInputChange}
								checked={this.props.sensorIssuesForm.missing_communication_warning}
								disabled
								// disabled={!this.props.sensorIssuesForm.use_issue_alarms || !this.props.sensorIssuesForm.editable} for now commented out https://elproag.atlassian.net/browse/TH-385
							/>
							<span className="fa fa-check" /> Missing communication warning
						</label>
						<br />
						<MissingCommunicationWarningSelectComponent
							value={this.props.sensorIssuesForm.missing_communication_warning_enum}
							onChange={this.props.handleIssueAlarmInputChange}
							disabled={true}
						/>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xl={3}>
						<label className="checkbox c-checkbox">
							<Input
								type="checkbox"
								id="checkboxSensorFailureAlarm"
								name="sensor_failure_alarm"
								checked={this.props.sensorIssuesForm.sensor_failure_alarm}
								onChange={this.props.handleIssueAlarmInputChange}
								disabled
								// disabled={!this.props.sensorIssuesForm.use_issue_alarms || !this.props.sensorIssuesForm.editable} for now commented out https://elproag.atlassian.net/browse/TH-385
							/>
							<span className="fa fa-check" /> Sensor failure alarm
						</label>
					</Col>
				</Row>
				<Row>
					<Col xl={3}>
						<label className="checkbox c-checkbox">
							<Input
								type="checkbox"
								id="checkboxLostMeasurementAlarm"
								name="lost_measurement_alarm"
								onChange={this.props.handleIssueAlarmInputChange}
								checked={this.props.sensorIssuesForm.lost_measurement_alarm}
								disabled
								// disabled={!this.props.sensorIssuesForm.use_issue_alarms || !this.props.sensorIssuesForm.editable}
							/>
							<span className="fa fa-check" /> <Trans i18nKey={'settings.issueProfiles.lostMeasurementAlarm'} />
						</label>
					</Col>
				</Row>
				<Row>
					<Col xl={3}>
						<label className="checkbox c-checkbox">
							<Input
								type="checkbox"
								id="checkboxLowBatteryWarning"
								name="low_battery_warning"
								checked={this.props.sensorIssuesForm.low_battery_warning}
								onChange={this.props.handleIssueAlarmInputChange}
								disabled
							/>
							<span className="fa fa-check" /> Low battery warning
						</label>
					</Col>
				</Row>
				<Row>
					<Col xl={3}>
						{this.props.sensorIssuesForm.editable &&
						this.props.sensorIssuesForm.sensors &&
						this.props.sensorIssuesForm.sensors.length ? (
							<div style={{paddingTop: '8px'}}>
								<Alert
									message="Warning"
									description={
										'A change affects ' +
										this.props.sensorIssuesForm.sensors.length +
										'  more sensors. ' +
										Shared.handleAffectedSensors(this.props.sensorIssuesForm.sensors)
									}
									type="error"
								/>
								<br />
							</div>
						) : null}
					</Col>
				</Row>
				<Row>
					<Col xl={3}>
						<Label for="issueNotification">Alarm notification to:</Label>
						<br />
						<div>
							<label className="checkbox c-checkbox">
								<Input
									id="checkboxEmailNotification"
									name="email_notification"
									type="checkbox"
									checked={this.props.sensorIssuesForm.email_notification}
									onChange={this.props.handleIssueAlarmInputChange}
									disabled={!this.props.sensorIssuesForm.use_issue_alarms}
								/>
								<span className="fa fa-check" /> Email
							</label>
							{this.state.emailList &&
							this.props.sensorIssuesForm.email_users &&
							this.props.sensorIssuesForm.email_notification &&
							this.props.sensorIssuesForm.use_issue_alarms ? (
								<div>
									{this.props.sensorIssuesForm.email_users.length}/{this.state.emailList.length} selected
									<div>
										<Popconfirm
											placement="bottom"
											title={'Clear all recipients?'}
											okText={'Yes'}
											cancelText={'No'}
											onConfirm={e => this.handleMultiEmailChange(e, [])}
											disabled={this.props.sensorIssuesForm.email_users.length === 0}
										>
											<Button disabled={this.props.sensorIssuesForm.email_users.length === 0}>Clear all</Button>
										</Popconfirm>
									</div>
									<Select
										id="selectEmailUsers"
										disabled={
											!this.props.sensorIssuesForm.use_issue_alarms || !this.props.sensorIssuesForm.email_notification
										}
										mode="multiple" //{ (this.state && this.state.setting['allow-sms-multi']) ? "multiple" : "single" }
										//allowClear
										style={{
											display: 'block',
											padding: '10px 0',
										}}
										value={
											this.props.sensorIssuesForm.email_users && this.state.emailList
												? this.getUserKeys(this.props.sensorIssuesForm.email_users, 'email')
												: []
										}
										placeholder="Please select receipient"
										onChange={(e, v) => {
											this.handleMultiEmailChange(e, v);
										}}
									>
										{this.state.emailList}
									</Select>
									{this.props.sensorIssuesForm.email_notification &&
									this.state.formSensorIssues &&
									this.state.formSensorIssues.errors.selectEmailUsers['emailUserSelected'] ? (
										<div>
											<Alert description={'Select email receipient or disable email notifications.'} type="error" />
											<br />
										</div>
									) : null}
								</div>
							) : null}
						</div>
						<div>
							{this.state.setting['allow-sms-single'] ? (
								<div>
									<label className="checkbox c-checkbox">
										<Input
											id="checkboxSmsNotification"
											name="sms_notification"
											type="checkbox"
											checked={this.props.sensorIssuesForm.sms_notification}
											onChange={this.props.handleIssueAlarmInputChange}
											disabled={!this.props.sensorIssuesForm.use_issue_alarms || this.props.sms_checkbox_disabled}
										/>
										<span className="fa fa-check" /> Sms
									</label>
									{
										/*this.state.setting['allow-sms-multi'] && */
										this.state.smsList &&
										this.props.sensorIssuesForm.sms_users &&
										this.props.sensorIssuesForm.sms_notification &&
										this.props.sensorIssuesForm.use_issue_alarms ? (
											<div>
												{this.state.setting['allow-sms-multi']
													? this.props.sensorIssuesForm.sms_users.length +
													  '/' +
													  this.state.smsList.length +
													  ' selected'
													: ''}
												<div>
													<Popconfirm
														placement="bottom"
														title={
															this.state.setting['allow-sms-multi']
																? 'Clear all recipients?'
																: 'Clear recipient?'
														}
														okText={'Yes'}
														cancelText={'No'}
														onConfirm={e => this.handleMultiSmsChange(e, [])}
														disabled={
															this.props.sensorIssuesForm.sms_users.length === 0 ||
															!this.props.sensorIssuesForm.use_issue_alarms ||
															this.props.sms_checkbox_disabled
														}
													>
														<Button
															disabled={
																this.props.sensorIssuesForm.sms_users.length === 0 ||
																!this.props.sensorIssuesForm.use_issue_alarms ||
																this.props.sms_checkbox_disabled
															}
															style={{margin: '10px 0'}}
														>
															{this.state.setting['allow-sms-multi'] ? 'Clear all' : 'Clear'}
														</Button>
													</Popconfirm>
												</div>
												{this.state.setting['allow-sms-multi'] ? (
													<Select
														id="selectSmsUsers"
														disabled={
															!this.props.sensorIssuesForm.use_issue_alarms ||
															!this.props.sensorIssuesForm.sms_notification ||
															!this.props.sensorIssuesForm.use_issue_alarms ||
															this.props.sms_checkbox_disabled
														}
														mode={this.state.setting['allow-sms-multi'] ? 'multiple' : 'tags'}
														//allowClear
														style={{
															display: 'block',
															padding: '10px 0',
														}}
														value={
															this.props.sensorIssuesForm.sms_users && this.state.smsList
																? this.getUserKeys(this.props.sensorIssuesForm.sms_users, 'sms')
																: []
														}
														placeholder="Please select receipient"
														onChange={(e, v) => {
															this.handleMultiSmsChange(e, v);
														}}
													>
														{this.state.smsList}
													</Select>
												) : (
													<Select
														id="selectSmsUsers"
														disabled={
															!this.props.sensorIssuesForm.use_issue_alarms ||
															!this.props.sensorIssuesForm.sms_notification ||
															!this.props.sensorIssuesForm.use_issue_alarms ||
															this.props.sms_checkbox_disabled
														}
														//allowClear
														style={{
															display: 'block',
														}}
														value={
															this.props.sensorIssuesForm.sms_users && this.state.smsList
																? this.getUserKeys(this.props.sensorIssuesForm.sms_users, 'sms')
																: []
														}
														placeholder="Please select receipient"
														onChange={(e, v) => {
															this.handleMultiSmsChange(e, v);
														}}
													>
														{this.state.smsList}
													</Select>
												)}
												{this.props.sensorIssuesForm.sms_notification &&
												this.state.formSensorIssues &&
												this.state.formSensorIssues.errors.selectSmsUsers['smsUserSelected'] ? (
													<div>
														<Alert
															description={'Select sms receipient or disable sms notifications.'}
															type="error"
														/>
														<br />
													</div>
												) : null}
											</div>
										) : null
									}
									{this.props.sms_checkbox_disabled ? (
										<div>
											<Alert
												key="smsNotificationPairing"
												message="SMS notification settings affects pricing"
												description="Pair Sensor to enable SMS notification changes"
												type="info"
												banner
											/>
										</div>
									) : null}
									{this.props.currentPrice.isSmsDowngradeFromLicenseModel &&
									this.props.currentPrice.isSmsDowngradeFromLicenseModel ? (
										<SmsDowngradeWarningAlert />
									) : null}
								</div>
							) : null}
							<br />
						</div>
					</Col>
				</Row>

				{
					<Row>
						<Col>
							{(this.state.featureFlags && this.state.featureFlags.includes(FeatureFlag.Licensing) == true) ||
							!(this.props.templateName && this.props.templateName === MODULE_FAMILIES.LIBERO_G) ? (
								<CurrentPrice currentPrice={this.props.currentPrice} />
							) : null}
						</Col>
					</Row>
				}

				<FormNavigation loading={this.state.loading} onBack={this.back} />
			</form>
		);
	}
}

SensorIssuesFormComponent.propTypes = {
	onBack: PropTypes.func,
	onNext: PropTypes.func,
};

export default SensorIssuesFormComponent;
