import React, {useMemo} from 'react';
import sensorUtils from './SensorUtils';


const SensorBodyUnit = ({unit}) => {
    const sensorUnit = useMemo(() => sensorUtils.getSensorUnitType(unit), [unit]);
    return (<> {sensorUnit}</>);
}

export default SensorBodyUnit;
