export const LicensesSettings = {
	ecologProRadioSettings: {
		sensorPaymentMethod: {
			showVoucher: true,
		},
	},
	liberoGFirmware0Settings: {
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},

	liberoGFirmware1Settings: {
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},
	ecologProGSettings: {
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},
};
