import {MODULE_FAMILIES, SUPPORTED_PREFIX} from './Constants/Module';

function isTemplateVersionSupported(char) {
	return SUPPORTED_PREFIX.SUPPORTED_TEMPLATE_VERSION.indexOf(char) >= 0;
}

function isPrefixSupported(char) {
	return SUPPORTED_PREFIX.SUPPORTED_PREFIX.indexOf(char) >= 0;
}

function isEcologProGPrefixSupported(char) {
	return SUPPORTED_PREFIX.SUPPORTED_PREFIX_ECOLOG_PRO_G.indexOf(char) >= 0;
}

function isLiberoGPrefixSupported(char) {
	return SUPPORTED_PREFIX.SUPPORTED_PREFIX_LIBERO_G.indexOf(char) >= 0;
}

function areOtherCharsSupportedLiberoG(value) {
	const supportedValues = [...SUPPORTED_PREFIX.AFTER_PREFIX, ...SUPPORTED_PREFIX.AFTER_PREFIX_LIBERO_G_HEX];
	return isValueSupported(value, supportedValues);
}

function areOtherCharsSupported(value) {
	return isValueSupported(value, SUPPORTED_PREFIX.AFTER_PREFIX);
}

function isValueSupported(value, supportedList) {
	if (value.length > 1) {
		const sub = value.substring(1, value.length);
		for (let i = 0; i < sub.length; i++) {
			if (supportedList.indexOf(sub[i]) < 0) {
				return false;
			}
		}
		return true;
	} else {
		return true;
	}
}

function findFamilyType(serialNumber) {
	if (serialNumber !== undefined && serialNumber !== null) {
		if (isEcologProGPrefixSupported(serialNumber[0])) {
			return MODULE_FAMILIES.ECOLOG_PRO_G;
		} else if (isPrefixSupported(serialNumber[0])) {
			return MODULE_FAMILIES.ECOLOG_PRO_RADIO;
		} else if (isLiberoGPrefixSupported(serialNumber[0])) {
			return MODULE_FAMILIES.LIBERO_G;
		} else {
			return null;
		}
	} else {
		return null;
	}
}

function isValidEcologProGTemplateVersion(serialNumber) {
	if (isTemplateVersionSupported(serialNumber[1])) {
		return true;
	} else {
		return false;
	}
}

function isValidLiberoGTemplateVersion(serialNumber) {
	if (isTemplateVersionSupported(serialNumber[2])) {
		return true;
	} else {
		return false;
	}
}

function isValidEcologProG(serialNumber) {
	// ECOLOG_PRO_G_DEACTIVATED

	if (isEcologProGPrefixSupported(serialNumber[0])) {
		if (serialNumber.length > 2) {
			return areOtherCharsSupportedLiberoG(serialNumber);
		} else {
			return true;
		}
	} else {
		return false;
	}
}

function isValidLiberoG(serialNumber) {
	if (!serialNumber || typeof serialNumber !== 'string') return false;

	const prefix = serialNumber.substring(0, serialNumber.length === 1 ? 1 : 2);

	if (isLiberoGPrefixSupported(prefix)) {
		if (serialNumber.length > 2) {
			return areOtherCharsSupportedLiberoG(serialNumber);
		} else {
			return true;
		}
	} else {
		return false;
	}
}

function isValidEcolog2PTR(serialNumber) {
	if (!serialNumber || typeof serialNumber !== 'string') return false;

	const prefix = serialNumber.substring(0, 1);
	if (prefix !== 'Q') return false;

	return areOtherCharsSupported(serialNumber);
}

function isValidSerialNumber(serialNumber) {
	if (isPrefixSupported(serialNumber[0])) {
		return areOtherCharsSupported(serialNumber);
	} else {
		return false;
	}
}

function isSupported(value) {
	if (value.length > 0) {
		let isValid = isValidSerialNumber(value);
		if (!isValid) {
			isValid = isValidLiberoG(value.toUpperCase());
		}
		if (!isValid) {
			isValid = isValidEcologProG(value.toUpperCase());
		}

		let moduleFamily = findFamilyType(value);

		if (isValid) {
			switch (moduleFamily) {
				case MODULE_FAMILIES.ECOLOG_PRO_G:
					if (value.length > 1) {
						isValid = isValidEcologProGTemplateVersion(value.toUpperCase());
					}
					break;

				case MODULE_FAMILIES.LIBERO_G:
					if (value.length > 2) {
						isValid = isValidLiberoGTemplateVersion(value.toUpperCase());
					}
					break;
			}
		}

		return isValid;
	} else {
		return true;
	}
}

function isValidLength(value) {
	if (value.length > 0) {
		let moduleFamily = findFamilyType(value);

		switch (moduleFamily) {
			case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
				if (value.length < 8) {
					return true;
				}
				break;

			case MODULE_FAMILIES.ECOLOG_PRO_G:
				if (value.length < 11) {
					return true;
				}
				break;

			case MODULE_FAMILIES.LIBERO_G:
				if (value.length < 14) {
					return true;
				}
				break;
		}
	} else {
		return true;
	}
}

const SerialNumberInfo = {
	isSupported,
	isValidLiberoG,
	isValidEcologProG,
	isValidEcolog2PTR,
	isValidLength,
	findFamilyType,
};

export default SerialNumberInfo;
