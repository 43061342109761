import {prepareSingleRowData} from './LimitProfileGridSingleRowBuilder';

export const mapLimitProfilePropertiesToGrid = (parent, limitProfiles) => {
	let rows = [];
	let _limitProfiles = []; //This array will be used in the future for events. This array a kind of state of limit profiles.
	if (parent && typeof parent === 'object' && limitProfiles && Array.isArray(limitProfiles) && limitProfiles.length > 0) {
		limitProfiles.forEach(limitProfile => {
			let i = 1;
			const limitProfileObj = prepareLimitProfileObj(limitProfile);
			_limitProfiles.push(limitProfileObj);

			const callbackMethodsForHamburgerMenu = prepareCallbackMethodsForHamburgerMenu(parent);
			rows.push(prepareSingleRowData(callbackMethodsForHamburgerMenu, limitProfile, parent, i));
			i++;
		});
	}
	return [rows, _limitProfiles];
};

function prepareCallbackMethodsForHamburgerMenu(parent) {
	if (!parent) {
		throw new TypeError('Please pass parent object!!!');
	}

	return {
		copyLimitProfile: parent.copyLimitProfile,
		displayLimitProfile: parent.displayLimitProfile,
		editLimitProfile: parent.editLimitProfile,
		deleteLimitProfile: parent.deleteLimitProfile,
	};
}

function prepareLimitProfileObj(limitProfile) {
	if (!limitProfile) {
		throw new TypeError('Please pass limitProfile object!!!');
	}

	return {
		limitProfileId: limitProfile.id,		
		locked: limitProfile.locked,		
		sensors: limitProfile.sensors,
	};
}

if (process.env['BABEL_ENV'] === 'test' || process.env['NODE_ENV'] === 'test') {
	//Functions are only visible for testing purpose.
	module.exports.prepareLimitProfileObj = prepareLimitProfileObj;
	module.exports.prepareCallbackMethodsForHamburgerMenu = prepareCallbackMethodsForHamburgerMenu;
}
