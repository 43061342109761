import React from 'react';
import FormUtils from '../../FormUtils';
import {SECTION_KEY, EcologProGxMapperService} from './EcologProGxMapperService';
import EcologProGxValidator from './EcologProGxValidator';

function EcologProGxMapper(FormComponent) {
	return ({name, schema, value, coreData, onChange, onError, isLastStep, ...rest}) => {
		const [formSchema, setFormSchema] = React.useState({});
		const [invalidFields, setInvalidFields] = React.useState({});

		/**
		 * Effect to create local schema with filled placeholders
		 */
		React.useLayoutEffect(() => {
			let clonedSchema = JSON.parse(JSON.stringify(schema[name].fields));
			clonedSchema = _map_to_ecologpro_gx_schema(clonedSchema);

			FormUtils.fillSchemePlaceHolder(clonedSchema, value);
			const objectToPush = {alias: coreData.metadatasForm.sensor_name, value: coreData.metadatasForm.sensor_name};
			clonedSchema.in_transit.defaults.push(objectToPush);
			setFormSchema(clonedSchema);

			/**
			 * Extra configs are the properties that configured from
			 * the template and the user doesnt have access to
			 */
			if (isLastStep) {
				let _extraConfig = {};

				if (!!schema.extra_settings) _extraConfig = FormUtils.getTemplateSectionValue(schema.extra_settings['fields'], {});

				if (!!coreData.sensorBasicsForm) {
					_extraConfig = {..._extraConfig, temperature_display_unit: getTemperatureDisplayUnit()};
					Object.keys(schema.extra_settings['fields']).forEach(key => {
						const field = schema.extra_settings['fields'][key];
						if (field['mapped_in'] !== undefined && field['mapped_in'] !== null) {
							_extraConfig = {
								..._extraConfig,
								[field['mapped_in']]: {..._extraConfig[field['mapped_in']], [field['id']]: _extraConfig[field['id']]},
							};

							delete _extraConfig[field['id']];
						}
					});

					_extraConfig = mapCommunicationInterval(_extraConfig);
					_extraConfig = mapFastCommunicationInterval(_extraConfig);
				}

				onChange(_extraConfig);
			}
		}, []);

		const _map_to_ecologpro_gx_schema = _schema => {
			// add alarm profiles names to display settings options
			if (name === SECTION_KEY.DISPLAY) {
				const selected_alarms_limit = coreData.limitAlarmForms?.map(lp => ({
					alias: lp.alarmLimit?.Name,
					value: lp.alarmLimit?.Name,
				}));
				const alarm_limits_options = [];

				selected_alarms_limit.forEach(profile => {
					if (alarm_limits_options.filter(pro => pro.alias === profile.alias).length === 0 && profile.alias !== '') {
						alarm_limits_options.push(profile);
					}
				});
				return {
					..._schema,
					in_transit: {..._schema.in_transit, defaults: [..._schema.in_transit.defaults, ...alarm_limits_options]},
				};
			}

			// Change time delay step to logging interval unit
			if (name === SECTION_KEY.OPERT) {
				const logging_interval_value = coreData.sensorBasicsForm.logging_interval / 60;

				// Remmove to set time delay to default instaed of 1 logging interval
				if (!!value.start_transit_options) {
					if (
						(!value.start_transit_options['time_delay'] ||
							value.start_transit_options['time_delay'] < logging_interval_value ||
							value.start_transit_options['time_delay'] % logging_interval_value !== 0) &&
						value.start_transit_options['time_delay'] !== 0
					) {
						value.start_transit_options['time_delay'] = logging_interval_value;
					}
				}

				return {
					..._schema,
					start_transit_time_delay: {
						..._schema.start_transit_time_delay,
						default: logging_interval_value,
						range: {
							..._schema.start_transit_time_delay.range,
							step: logging_interval_value,
						},
					},
				};
			}

			return _schema;
		};

		// Communication Interval value
		const mapCommunicationInterval = extraConfig => {
			if (!!extraConfig['communication_intervals']) {
				if (extraConfig['communication_intervals']['interval_1'] !== undefined) {
					extraConfig['communication_intervals']['interval_1'] = coreData.sensorBasicsForm.communication_interval;
				} else {
					delete extraConfig['communication_intervals'];
				}
			}

			return extraConfig;
		};

		// FastCommunication Interval value
		const mapFastCommunicationInterval = extraConfig => {
			if (coreData.sensorBasicsForm.optimized_communication_behavior) {
				if (!!extraConfig['fast_communication']) {
					if (extraConfig['fast_communication']['interval'] !== undefined) {
						extraConfig['fast_communication']['enable'] = true;
						extraConfig['fast_communication']['interval'] = coreData.sensorBasicsForm.fast_communication_interval;
					} else {
						delete extraConfig['fast_communication'];
					}
				}
			} else {
				if (!!extraConfig['fast_communication']) {
					if (extraConfig['fast_communication']['interval'] !== undefined) {
						extraConfig['fast_communication']['enable'] = false;
						extraConfig['fast_communication']['interval'] = 0;
						coreData.sensorBasicsForm.fast_communication_interval = 0;
					} else {
						delete extraConfig['fast_communication'];
					}
				}
			}
			return extraConfig;
		};

		// Temperature Display Unit
		const getTemperatureDisplayUnit = () => {
			let temperature_display_unit = 'C';

			if (coreData.sensorBasicsForm.temperature_display_unit) {
				temperature_display_unit = coreData.sensorBasicsForm.temperature_display_unit;
			} else {
				coreData.sensorBasicsForm.selectedChannel.channel_types.some(type => {
					if (type.value_index === coreData.sensorBasicsForm.selectedChannel.selectedValueIndex) {
						type.units.forEach(unit => {
							if (parseInt(coreData.sensorBasicsForm.unit, 10) === parseInt(unit.id, 10)) {
								temperature_display_unit = unit.token.includes('°') ? unit.token.replace(/^°+/g, '') : 'C';
							}
						});
						return true;
					}
					return false;
				});
			}

			return temperature_display_unit;
		};

		const handleChange = e => {
			if (schema[name].mapped === false) return;

			let data = {...value, [name]: {...value[name], ...e}};

			const [fieldsErrors, formErrors] = EcologProGxValidator.bulkVaildate(e, coreData);

			setInvalidFields(prev => ({...prev, ...fieldsErrors}));
			onError({}, formErrors);

			const outputConfig = EcologProGxMapperService.mapToAdditionalConfiguration(data, coreData);
			onChange(outputConfig);
		};

		const wizardValue = EcologProGxMapperService.mapToTemplateResult(name, value, formSchema);

		//multiuse not visible until further notice
		if (formSchema['multiuse']) {
			let schema = formSchema['multiuse'];
			let def = schema['defaults'];
			let reStart = def.find(x => x.value === 'restart');
			if (reStart) {
				def.splice(def.indexOf(reStart, 1));
			}
		}

		return (
			<FormComponent
				{...rest}
				name={name}
				schema={formSchema}
				value={wizardValue || {}}
				invalidFields={invalidFields}
				onChange={handleChange}
				onError={onError}
			/>
		);
	};
}

export default EcologProGxMapper;
