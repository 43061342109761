import React from 'react';
import {ExportButton} from '../../../Common/Export/ExportButton';
import {ExportType} from '../../../Common/Export/ExportType';
import {Tooltip} from 'antd';
import {Button} from 'reactstrap';
import {Trans} from 'react-i18next';

interface CalibrationAnalysisExportComponentProps {
	reportLocalhost: any;
	loadingReport: any;
	report: any;
	exportFunction: any;
	exportCalibrationData: Function;
	disabled: boolean;
}

export const CalibrationAnalysisExportComponent = (props: CalibrationAnalysisExportComponentProps) => {
	return (
		<div style={{display: 'flex', justifyContent: 'right'}}>
			<div style={{display: 'flex'}}>
				<ExportButton
					ExportType={ExportType.Excel}
					WithGeoData={false}
					ExportFunction={props.exportFunction}
					Disabled={props.disabled}
				></ExportButton>
				<ExportButton
					ExportType={ExportType.Csv}
					WithGeoData={false}
					ExportFunction={props.exportFunction}
					Disabled={props.disabled}
				></ExportButton>
			</div>
			<div style={{padding: '0 5px'}}>
				<Tooltip title={'elproCALIBRATION export'}>
					<Button
						id={'exportButton_elproCALIBRATION'}
						color="primary"
						size="lg"
						disabled={props.disabled}
						onClick={() => props.exportCalibrationData()}
					>
						<span className="fa fa-xl fa-solid elpro-Report mr-2" />
						<Trans i18nKey="calibrations.export" />
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};
