import React, {useEffect, useState} from 'react';
import AntButton from '../../../../Shared/UI/Ant/AntButton';
import {Checkbox, Spin} from 'antd';
import styled from 'styled-components';
import RequestLogger from '../RequestLogger';
import FileSaver from 'file-saver';

const FONT_SIZE = '10px';

const Wrapper = styled.div`
    font-size: ${FONT_SIZE};
    padding: 30px;
    background-color: #75787b17;
    border-bottom: 1px solid #b1b3b3;
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, Courier New, monospace, serif;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    & > div {
        margin-right: 10px;
    }
`;

const LoggerSettingsNavigation = () => {
    const [loggingActive, setLoggingActive] = useState(RequestLogger.startLogging);
    const [disableRefreshAlive, setDisableRefreshAliveActive] = useState(RequestLogger.logAliveRefreshRequests);

    useEffect(() => {
        RequestLogger.startLogging = loggingActive;
    }, [loggingActive]);

    useEffect(() => {
        RequestLogger.logAliveRefreshRequests = disableRefreshAlive;
    }, [disableRefreshAlive]);

    const onChangeRefreshAndAlive = e => {
        setDisableRefreshAliveActive(e.target.checked);
    };

    const onStart = () => {
        setLoggingActive(true);
    };

    const onStop = () => {
        setLoggingActive(false);
    };

    const onExportLog = () => {
        const logData = RequestLogger.getLogDataJSON();
        const strLog = JSON.stringify(logData);
        const file = new File([strLog], 'log.json', {type: 'application/json'});
        FileSaver.saveAs(file);
    };

    return (
        <Wrapper>
            <div>
                <Spin size="small" spinning={loggingActive}>
                    <AntButton disabled={!!loggingActive} type="primary" fontSize={FONT_SIZE} onClick={onStart}>
                        Start Logging
                    </AntButton>
                </Spin>
            </div>
            <div>
                <AntButton type="primary" isDanger="true" fontSize={FONT_SIZE} disabled={!loggingActive} onClick={onStop}>
                    Stop Logging
                </AntButton>
            </div>
            <div>
                <Checkbox
                    disabled={!!loggingActive}
                    checked={disableRefreshAlive}
                    onChange={onChangeRefreshAndAlive}
                    style={{fontSize: FONT_SIZE}}
                >
                    Disable refresh & alive logging
                </Checkbox>
            </div>
            <div style={{flex: 1, textAlign: 'right'}}>
                <AntButton type="primary" fontSize={FONT_SIZE} disabled={!!loggingActive} onClick={onExportLog}>
                    Export Data
                </AntButton>
            </div>
        </Wrapper>
    );
};
export default LoggerSettingsNavigation;
