import moment from 'moment';
const bridgeConnection = 'bridgeConnection';

function pad (str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

const bridgeConfigurationUtils = {

    countdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;
        return timeLeft.min + ":" + pad(timeLeft.sec.toString(), 2);
    },

    getEndDate() {
        const now = new Date();
        return moment(now).add(10, 'm').toDate();
    },

    getLocalStorage() {
        let obj = localStorage.getItem(bridgeConnection);
        if (obj === undefined || obj === null) {
            return undefined;
        } else {
            return JSON.parse(obj);
        }
    },

    setLocalStorage(obj) {
        localStorage.setItem(bridgeConnection, JSON.stringify(obj));
    },

    deleteLocalStorage() {
        localStorage.removeItem(bridgeConnection)
    }

};

export default bridgeConfigurationUtils;