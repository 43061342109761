import React from "react";
import SystemSettings from "../../Infrastructure/Settings/SystemSettings";
import styled from "styled-components";
import {StyledLink} from '../Styles/StyledLink';

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
  
`

const CreateSupportTicket = (props) => {
    return (
        <LinkWrapper>
            <StyledLink href={SystemSettings.getConfig().support_ticket}
               title="Create Support Ticket"
               target="_blank"
               rel="noopener noreferrer"
            > Create Support Ticket
            </StyledLink>
        </LinkWrapper>
    );
};

export default CreateSupportTicket;