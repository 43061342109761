import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type SensorBasicsFastCommunicationIntervalProps = {
	fastCommunicationInterval: number;
	optimizedCommunicationBehavior: boolean;
	show: boolean;
};

export default function SensorBasicsFastCommunicationIntervalComponent(props: SensorBasicsFastCommunicationIntervalProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.fastCommunicationInterval'} />
				</td>
				<td>
					{props.optimizedCommunicationBehavior &&
					(props.fastCommunicationInterval === 30 || props.fastCommunicationInterval === 60)
						? props.fastCommunicationInterval
						: CONSTANTS.NOT_AVAILABLE}{' '}
					{props.optimizedCommunicationBehavior &&
						(props.fastCommunicationInterval === 30 || props.fastCommunicationInterval === 60) && (
							<Trans i18nKey={'sensorWizard.summary.minutes'} />
						)}
				</td>
			</tr>
		)
	);
}
