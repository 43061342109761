export const SensorSettings = {
	ecologProRadioSettings: {
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: false,
				showCommunicationMode: false,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: false,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: false,
		},
	},
	liberoGFirmware0Settings: {
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: false,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: true,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
	},

	liberoGFirmware1Settings: {
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: true,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: true,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
	},
	ecologProGSettings: {
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: false,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: true,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: false,
				showMemo: false,
				showFirmware: true,
			},
			showMetadatasTitle: true,
			metadatas: {
				showSensorName: true,
				showCustomersId: true,
				showMemo: true,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
	},
};
