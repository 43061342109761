import React, {useContext, useEffect, useState} from 'react';
import styles from './styles/styles.module.scss';
import pubsub from 'pubsub-js';
import AppSettings from '../Infrastructure/Settings/AppSettings';
import {getUser} from '../Settings/UserProfile/UserProfileService';
import {AccessContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {layoutPermissions} from '../Infrastructure/Authorization/Access';
import {RequestLogger} from '../Infrastructure/Requests/Logger';
import {NavigationMenu} from './NavigationMenu';
import Authentication from '../Infrastructure/Authentication/Authentication';
import {InfoView} from './InfoView';
import {AuthContext} from '../../common/context';

export function Sidebar(props: {collapsed: boolean}): React.JSX.Element {
	const accessContext = useContext(AccessContext);
	const authContext = useContext(AuthContext);
	const {navigation} = accessContext.user?.access_settings?.layout ?? {navigation: {}};
	const showUserInfo = navigation[layoutPermissions.navigation.user_info.key] ?? true;
	const showLinks = navigation[layoutPermissions.navigation.links.key] ?? true;
	const showSidebarNavigation = navigation[layoutPermissions.navigation.navigation.key] ?? true;

	const [loading, setLoading] = useState(true);
	const [tz, setTz] = useState(parseInt(Authentication.get_tzOffset() /* localStorage.getItem('tzOffset') */));
	const [user, setUser] = useState({firstName: '', lastName: ''});
	const [version, setVersion] = useState('');

	/**
	 * Subscribe event (tzChanged)
	 * @param _ Message identifier
	 * @param data Message data
	 */
	const tzChanged = (_: string, data: string) => setTz(parseInt(data));

	/**
	 * Subscribe event (userChanged)
	 * @param _ Message identifier
	 * @param data Message data
	 */
	const userChanged = (_: string, data: {first_name: string; last_name: string}) =>
		setUser({firstName: data.first_name, lastName: data.last_name});

	const subscriptions: PubSubJS.Token[] = [];
	// settings - user profile - timezone
	subscriptions.push(pubsub.subscribe('tzChanged', tzChanged));

	// settings - user profile name
	subscriptions.push(pubsub.subscribe('userChanged', userChanged));

	useEffect(() => {
		AppSettings.getApplicationVersion()
			.then(r => setVersion(r.data[0].version))
			.catch(e => console.error(e));

		getUser(RequestLogger.createLogData('sidebar', 'load-user', 'onLoad'))
			.then(r => setUser({firstName: r.data.first_name, lastName: r.data.last_name}))
			.catch(e => console.error(e));

		setLoading(false);
		return () => subscriptions.forEach(s => pubsub.unsubscribe(s));
	}, [undefined]);

	function renderTz(offset: number): string {
		if (offset !== undefined && offset !== null && !Number.isNaN(offset)) {
			const prefix = offset.toString().charAt(0) === '-' ? '' : '+';
			const hourSliceLength = offset <= -600 ? -3 : -2;
			return 'UTC' + prefix + `0${(offset / 60) ^ 0}`.slice(hourSliceLength) + ':' + ('0' + (offset % 60)).slice(-2);
		}
		return '';
	}

	if (!showSidebarNavigation || loading || !authContext?.Organization) return null;
	return (
		<div className={styles.sidebar}>
			<NavigationMenu />
			{!props.collapsed && (
				<>
					{showUserInfo && (
						<ul className={styles.infoView}>
							<li id="organization-name">{authContext.Organization.Name}</li>
							<li id="first-last-name">
								{user.firstName} {user.lastName}
							</li>
							<li id="timezone">{renderTz(tz)} </li>
						</ul>
					)}
					<div style={{flex: 1}}></div>
					{showLinks && <InfoView version={version} organisationId={authContext.Organization.Id} />}
				</>
			)}
		</div>
	);
}
