import axios from 'axios';
import IssueProfilesService from '../IssueProfiles/IssueProfilesService';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
const isDebugMode = false;

const localHostSaveProfile = (payload, eventData) => {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: null,
        SensorIssueAlarm: payload,
        PaymentInformation: null,
    };
    wizdata.SensorIssueAlarm.limit_issue_name = wizdata.SensorIssueAlarm.name;
    wizdata.SensorIssueAlarm.use_issue_alarms = true;
    wizdata.SensorIssueAlarm.issue_alarm_creation_type = 0;

    return axios({
        url: 'http://localhost:44353/registry/save',
        method: 'POST',
        data: wizdata,
        eventData,
        headers: {
            Authorization: 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
    });
};

const localHostUpdateProfile = (payload, eventData) => {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: null,
        SensorIssueAlarm: payload,
        PaymentInformation: null,
    };
    wizdata.SensorIssueAlarm.limit_issue_name = wizdata.SensorIssueAlarm.name;
    wizdata.SensorIssueAlarm.use_issue_alarms = true; //update
    wizdata.SensorIssueAlarm.issue_alarm_creation_type = wizdata.SensorIssueAlarm.id;
    wizdata.SensorIssueAlarm.editable = true;

    return axios({
        url: 'http://localhost:44353/registry/update',
        method: 'PUT',
        data: wizdata,
        eventData,
        headers: {
            Authorization: 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
    });
};

const localHostDeleteProfile = (limitProfileId, eventData) => {
    return axios({
        url: 'http://localhost:44353/registry/sensorissueprofile?id=' + limitProfileId,
        method: 'DELETE',
        eventData,
        headers: {
            Authorization: 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
    });
};


const SaveProfile = (payload, eventData) => {
    if (isDebugMode) {
        return localHostSaveProfile(payload, eventData);
    } else {
        return IssueProfilesService.saveProfile(payload, eventData);
    }
};

const UpdateProfile = (payload, eventData) => {
    if (isDebugMode) {
        return localHostUpdateProfile(payload, eventData);
    } else {
        return IssueProfilesService.updateProfile(payload, eventData);
    }
};

const DeleteProfile = (limitProfileId, eventData) => {
    if (isDebugMode) {
        return localHostDeleteProfile(limitProfileId, eventData);
    } else {
        return IssueProfilesService.deleteProfile(limitProfileId, eventData);
    }
};

const IssueProfiles = {
    SaveProfile,
    UpdateProfile,
    DeleteProfile
};

export default IssueProfiles;
