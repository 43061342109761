import React, {Component} from 'react';
import ContentWrapper from '../../Layout/ContentWrapper';
import KnowledgeHelp from '../../Shared/Components/KnowledgeBaseHelp';
import {Button, Container} from 'reactstrap';
import {StyledHeaderHelpIcon} from '../../Shared/Styles/StyledHeaderHelpIcon';
import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';

//Ag Grid
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../../styles/app/datagrid/styles.css';
import {ColumnDefinitions} from './ColumnDefinitions';
import {GridOptions} from './GridOptions';

//Constants
import {COLUMNS} from './Constants/Columns.js';

//Actions
import {executeLimitProfileCopyAction} from './Actions/LimitProfileCopying';
import {executeLimitProfileAddAction} from './Actions/LimitProfileAdding';
import {executeLimitProfileDisplayAction} from './Actions/LimitProfileDisplaying';
import {executeLimitProfileEditAction} from './Actions/LimitProfileEditing';
import {executeLimitProfileDeleteAction} from './Actions/LimitProfileDeleting';

import {MultiContext} from '../../Infrastructure/Authorization/Context/MultiContext';
import {ActionButtonsBindings} from './GridComponents/ActionButtonsBindings';
import {getLimitProfiles} from './Queries/LimitProfilesQuery';

class LimitProfilesGrid extends Component {
	static contextType = MultiContext;

	constructor(props) {
		super(props);

		this.gridRef = React.createRef();
		this.savedFilterState = React.createRef();
		this.savedColumnState = React.createRef();
		this.gridApi = null;

		// menu items
		this.copyLimitProfile = executeLimitProfileCopyAction;
		this.displayLimitProfile = executeLimitProfileDisplayAction;
		this.editLimitProfile = executeLimitProfileEditAction;
		this.deleteLimitProfile = executeLimitProfileDeleteAction;

		this.state = {
			limitProfiles: [], //This array will be used in the future for events. This array a kind of state of limit profiles.
			rowData: [],
			columnDefs: ColumnDefinitions,
			agGridOptions: GridOptions,
		};

		ActionButtonsBindings(this); //The callback functions of menu options are bound, to be able to use properties of this.
	}

	addLimitProfile = e => {
		e.preventDefault();
		executeLimitProfileAddAction(this);
	};

	onGridReady = params => {
		getLimitProfiles(this);
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 50);
	};

	redrawAllRows = () => {
		this.gridRef.current.api.redrawRows();
	};

	deselectAll = e => {
		this.gridRef.current.api.deselectAll();
	};

	cellClickedListener = event => {
		console.log('cellClicked', event); //only for dev experience.
	};

	cellDoubleClickedListener = event => {
		if (event.colDef && event.data != null && event.colDef.field && event.colDef.field !== COLUMNS.MENU) {
			if (event.data.Locked) {
				executeLimitProfileDisplayAction(this, event.data.id);
			} else {
				executeLimitProfileEditAction(this, event.data);
			}
		}
	};

	setColumnDefs = () => {
		const columnDefs = this.state.columnDefs;
		columnDefs.forEach(function (colDef, index) {
			colDef.headerName = 'C' + index;
		});
		this.gridRef.current.api.setColumnDefs(columnDefs);
	};

	getRowId = params => {
		return params.data.id;
	};

	render() {
		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>Limit Profiles</div>
					<StyledHeaderHelpIcon>
						<KnowledgeHelp id="040" />
					</StyledHeaderHelpIcon>
					<div className="ml-auto">
						<Access
							access={accessPermissions.settings.child.limitProfiles.child.limitProfile.child.addLimitProfile}
							roles={userRoles.default}
						>
							<Button id="btnAddLimitProfile" color="primary" size="lg" onClick={this.addLimitProfile}>
								<span>Add Limit Profile</span>
							</Button>
						</Access>
					</div>
				</div>
				<Container fluid>
					<div style={this.state.agGridOptions.containerStyle}>
						<div style={this.state.agGridOptions.gridStyle} className={this.state.agGridOptions.theme}>
							<AgGridReact
								ref={this.gridRef}
								getRowId={this.getRowId}
								noRowsOverlayComponent={this.state.agGridOptions.noRowsOverlayComponent}
								loadingOverlayComponent={this.state.agGridOptions.loadingOverlayComponent}
								statusBar={this.state.agGridOptions.statusBar}
								rowGroupPanelShow={this.state.agGridOptions.rowGroupPanelShow}
								rowSelection={this.state.agGridOptions.rowSelection}
								sideBar={this.state.agGridOptions.sideBar}
								enableCellChangeFlash={this.state.agGridOptions.enableCellChangeFlash}
								defaultColDef={this.state.agGridOptions.colDefinationSettings}
								enableRangeSelection={this.state.agGridOptions.enableRangeSelection}
								rowData={this.state.rowData}
								rowClassRules={this.state.agGridOptions.rowClassRules}
								columnDefs={this.state.columnDefs}
								onCellClicked={this.cellClickedListener}
								animateRows={this.state.agGridOptions.animateRows}
								onGridReady={this.onGridReady}
								columnHoverHighlight={this.state.agGridOptions.columnHoverHighlight}
								autoGroupColumnDef={this.state.agGridOptions.autoGroupColumnDef}
								showOpenedGroup={this.state.agGridOptions.showOpenedGroup}
								groupDisplayType={this.state.agGridOptions.groupDisplayType}
								groupHideOpenParents={this.state.agGridOptions.groupHideOpenParents}
								suppressContextMenu={this.state.agGridOptions.suppressContextMenu}
								onCellDoubleClicked={this.cellDoubleClickedListener}
								suppressRowTransform
							/>
						</div>
					</div>
				</Container>
			</ContentWrapper>
		);
	}
}

export default LimitProfilesGrid;
