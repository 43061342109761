import React from 'react';
import {Container, Row, Col, Button} from 'reactstrap';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ContentWrapper from '../../Layout/ContentWrapper';
import Message from '../../Shared/Components/Message';
import SensorService from '../../Dashboard/Sensor/SensorService';
import SensorGroupsService from './SensorGroupsService';
import SensorGroupsUtils from './SensorGroupsUtils';
import GroupItemList from './Components/GroupItemList';
import SensorItem from './Components/SensorItem';
import {Collapse} from 'antd';
import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import KnowledgeHelp from '../../Shared/Components/KnowledgeBaseHelp';
import {StyledHeaderHelpIcon} from '../../Shared/Styles/StyledHeaderHelpIcon';
import RequestLogger from '../../Infrastructure/Requests/Logger/RequestLogger';

const {Panel} = Collapse;

function SensorGroups(props) {
    const initGroupsState = {loading: false, groups: [], nestedGroups: [], error: null};
    const initSensorsState = {loading: false, groupId: undefined, sensors: [], error: null};

    const [groupsState, groupsDispatch] = React.useReducer(SensorGroupsUtils.sensorsGroupsReducer, initGroupsState);
    const [sensorsState, sensorsDispatch] = React.useReducer(SensorGroupsUtils.sensorsGroupsReducer, initSensorsState);

    const [groupsOrderAsc, setGroupsOrderAsc] = React.useState(true);
    const [saveOrderBtnDisabled, setSaveOrderBtnDisabled] = React.useState(true);

    React.useEffect(() => {
        fetchGroups();
        fetchSensors();
    }, [fetchGroups]);

    const fetchGroups = React.useCallback(() => {
        groupsDispatch({type: 'fetchInit'});

        SensorGroupsService.sensorGroups(RequestLogger.createLogData('sensor-groups', 'load-sensor-groups', 'onLoad'))
            .then(response => groupsDispatch({type: 'fetchGroupsDone', payload: {groups: response.data}}))
            .catch(error => groupsDispatch({type: 'fetchFailed', payload: error}));
    }, []);

    const fetchSensors = (group = {id: null}) => {
        if (sensorsState.groupId === group.id) {
            return;
        }

        sensorsDispatch({type: 'fetchInit'});

        const params = group.id === null ? '' : `&sensors_groups_ids=cs.[${group.id}]`;
        SensorService.dashboardInfo(params, RequestLogger.createLogData('sensor-groups', 'load-dashboard-info', 'onLoad'))
            .then(responce => sensorsDispatch({type: 'fetchSensorsDone', payload: {sensors: responce.data, groupId: group.id, group}}))
            .catch(error => sensorsDispatch({type: 'fetchFailed', payload: error}));
    };

    const addSensorGroup = (e, group) => {
        e.stopPropagation();
        e.preventDefault();

        props.history.push({
            pathname: '/sensorGroup/addGroup',
            state: !!group ? {group} : undefined,
        });
    };

    const editSensorGroup = (e, group) => {
        e.stopPropagation();

        props.history.push({
            pathname: '/sensorGroup/editGroup',
            state: {group},
        });
    };

    const deleteSensorGroup = (e, {id, name, sensors_group_parent}) => {
        e.stopPropagation();

        const confirmText = !!sensors_group_parent
            ? `<span>All sensors in '${name}' will be moved to the parent group. <br/> You won't be able to revert this!</span>`
            : `<span>You want to delete the sensor group '${name}'. <br/> You won't be able to revert this!</span>`;

        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: "<span id='delete_sensor_group'>Are you sure?</span>",
            html: confirmText,
            input: 'password',
            inputAttributes: {
                autocapitalize: 'off',
            },
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            preConfirm: password => {
                return password;
            },
        }).then(result => {
            if (result.value) {
                SensorGroupsService.deleteSensorGroup(
                    {
                        id: id,
                        pass: result.value,
                    },
                    RequestLogger.createLogData('sensor-groups', 'delete-sensor-group', 'onClick')
                )
                    .then(response => {
                        groupsDispatch({
                            type: 'fetchGroupsDone',
                            payload: {groups: SensorGroupsUtils.getFilteredGroups(groupsState.groups, id)},
                        });
                        Message.success('Sensor group deleted successfully');
                    })
                    .catch(error => {
                        Message.error('Error', 'Sensor group could not be deleted', error);
                    });
            }
        });
    };

    const addSensorToGroup = (sensor, group) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: "<span id='add_sensor_to_group'>Are you sure?</span>",
            text: `add sensor '${sensor.name}' to the group '${group.name}'`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then(result => {
            if (result.value) {
                const payload = {items: [{sensor_id: sensor.id, group_id: group.id}]};
                SensorGroupsService.addSensorsToGroups(
                    payload,
                    RequestLogger.createLogData('sensor-groups', 'add-sensor-to-group', 'onClick')
                )
                    .then(response => {
                        Message.success('Sensor added to group successfully');
                    })
                    .catch(error => {
                        Message.error('Error', 'Sensor could not be added to group', error);
                    });
            }
        });
    };

    const removeSensorFromGroup = (sensor, groupId) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: "<span id='delete_sensor_from_group'>Are you sure?</span>",
            text: `remove sensor '${sensor.name}' from this group`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then(result => {
            if (result.value) {
                const payload = {items: [{sensor_id: sensor.id, group_id: groupId}]};
                SensorGroupsService.removeSensorsFromGroups(
                    payload,
                    RequestLogger.createLogData('sensor-groups', 'remove-sensor-from-group', 'onClick')
                )
                    .then(response => {
                        Message.success('Sensor removed from group successfully');
                    })
                    .catch(error => {
                        Message.error('Error', 'Sensor could not be removed from group', error);
                    });
            }
        });
    };

    const saveSensorGroupsOrder = e => {
        e.preventDefault();
        const groupsOrders = groupsState.groups.map(group => ({id: group.id, order: group.group_order}));

        setSaveOrderBtnDisabled(true);
        SensorGroupsService.orderSensorGroups(
            {orders: groupsOrders},
            RequestLogger.createLogData('sensor-groups', 'save-sensor-groups-order', 'onClick')
        )
            .then(response => {
                Message.success('Sensor groups order saved successfully');
            })
            .catch(error => {
                setSaveOrderBtnDisabled(false);
                Message.error('Error', 'Sensor groups could not be saved', error);
            });
    };

    const changeGroupChilds = (groups, groupId, childes) => {
        return groups.map(group => {
            if (group.id === groupId) {
                return {...group, sub_groups: childes};
            }

            if (group.sub_groups !== undefined && group.sub_groups !== null) {
                return {...group, sub_groups: changeGroupChilds(group.sub_groups, groupId, childes)};
            }

            return group;
        });
    };

    const groupsChange = (newGroups, parentGroupId) => {
        const nextGroups = parentGroupId === null ? newGroups : changeGroupChilds(groupsState.nestedGroups, parentGroupId, newGroups);

        groupsDispatch({type: 'fetchGroupsDone', payload: {groups: SensorGroupsUtils.getFlatGroups(nextGroups)}});
        setSaveOrderBtnDisabled(false);
    };

    const sortSensorGroups = e => {
        e.stopPropagation();

        const sortedGroups = SensorGroupsUtils.getSortedGroups(groupsState.groups, groupsOrderAsc);
        groupsDispatch({type: 'fetchGroupsDone', payload: {groups: sortedGroups}});

        setGroupsOrderAsc(prevOrder => !prevOrder);
        setSaveOrderBtnDisabled(false);
    };

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div>Sensor Groups</div>
                <StyledHeaderHelpIcon>
                    <KnowledgeHelp id="046" />
                </StyledHeaderHelpIcon>

                <div className="ml-auto">
                    <Access
                        access={accessPermissions.settings.child.sensorGroups.child.sensorGroup.child.addGroup}
                        roles={userRoles.default}
                    >
                        <Button id="btnAddSensorGroup" color="primary" size="lg" onClick={addSensorGroup}>
                            <span>Add Sensor Group</span>
                        </Button>
                    </Access>
                </div>
            </div>

            <DndProvider backend={SensorGroupsUtils.isTouchDevice() ? TouchBackend : HTML5Backend}>
                <Container fluid={true}>
                    <Row>
                        <Col xl={8} className="mb-3">
                            <div className="accordion">
                                <div className="accordion__title no_pointer">
                                    <div className="d-flex align-items-center">
                                        <div>Sensor Groups</div>
                                        <div className="ml-auto">
                                            <Button
                                                id="btnSaveSensorGroupsOrder"
                                                color="primary"
                                                size="sm"
                                                disabled={saveOrderBtnDisabled}
                                                onClick={saveSensorGroupsOrder}
                                            >
                                                <span>Save Order</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion__body accordion__multiple-items">
                                    <Collapse defaultActiveKey={1}>
                                        <Panel
                                            key={1}
                                            header={
                                                <div>
                                                <h5
                                                    className="accordion__clickable-title u-position-relative"
                                                    onClick={() => fetchSensors()}
                                                >
                                                    All Sensors
                                                   
                                                </h5>
                                                 <span className="action__btns sort_icon">
                                                 <em
                                                     className={`elpro-${groupsOrderAsc ? 'SortAlphaAsc' : 'SortAlphaDesc'}`}
                                                     id={'edit-sensor-group'}
                                                     onClick={sortSensorGroups}
                                                     style={{cursor: 'pointer'}}
                                                 />
                                             </span>
                                             </div>
                                            }
                                        >
                                            <GroupItemList
                                                groups={groupsState.nestedGroups}
                                                groupsChange={groupsChange}
                                                fetchSensors={fetchSensors}
                                                addGroup={addSensorGroup}
                                                editGroup={editSensorGroup}
                                                deleteGroup={deleteSensorGroup}
                                            />
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="accordion">
                                <div className="accordion__title no_pointer">{`Sensors in "${
                                    !!sensorsState.groupId ? sensorsState.group.name : 'All Sensors'
                                }"`}</div>
                                <div className="accordion__body" style={{maxHeight: '500px', overflowY: 'auto'}}>
                                    <ul className="draggable-list">
                                        {sensorsState.loading ? (
                                            <li className={'draggable-list__item'}>
                                                <span className="list_item">Loading ...</span>
                                            </li>
                                        ) : sensorsState.sensors.length > 0 ? (
                                            sensorsState.sensors.map((sensor, i) => (
                                                <SensorItem
                                                    key={sensor.id}
                                                    index={i}
                                                    sensor={sensor}
                                                    groupId={sensorsState.groupId}
                                                    addSensorToGroup={addSensorToGroup}
                                                    removeSensorFromGroup={removeSensorFromGroup}
                                                />
                                            ))
                                        ) : (
                                            <li className={'draggable-list__item'}>
                                                <span className="list_item">No sensors</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </DndProvider>
        </ContentWrapper>
    );
}

export default SensorGroups;
