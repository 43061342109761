import React, {useState} from 'react';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';

const ChartLegend = props => {
    const [count, setCount] = useState(0);

    if (props.setUpdateFun !== undefined && props.setUpdateFun !== null) {
        props.setUpdateFun(() => {
            setCount(count + 1); // make a rerender consequential
        });
    }
    const chco = props.chartComponent;

    return (
        <Accordion>
            <AccordionItem id="chartSettings" expanded>
                <AccordionItemTitle id="chartSettingsTitle">
                    <h5 className="u-position-relative">
                        Chart settings
                        <div className="accordion__arrow" role="presentation" />
                    </h5>
                </AccordionItemTitle>
                <AccordionItemBody>
                    <div>
                        {chco && chco.deferredDataPoints && chco.deferredDataPoints.length > 0 ? (
                            <Checkbox
                                id="reloaded-values-check-box"
                                data-test="reloadedValues"
                                checked={chco.state ? chco.state.showReloadedValues : false}
                                onClick={chco.onShowReloadedValuesClick}
                            >
                                Show Reloaded Values
                            </Checkbox>
                        ) : null}
                        {chco && chco.sensorCalibrations && chco.sensorCalibrations.length > 0 ? (
                            <Checkbox
                                id="sensor-calibrations-check-box"
                                data-test="calibrationValues"
                                checked={chco.state ? chco.state.showSensorCalibrationValues : false}
                                onClick={chco.onShowSensorCalibrationsClick}
                            >
                                Calibration
                            </Checkbox>
                        ) : null}
                        {chco && chco.communication_times && chco.communication_times.length > 0 ? (
                            <Checkbox
                                id="communication-times"
                                data-test="communication_times"
                                checked={chco.state ? chco.state.showMeasurementUploads : false}
                                onClick={chco.onShowMeasurementUploadsClick}
                            >
                                Communication times
                            </Checkbox>
                        ) : null}
                        {chco && chco.bypassedDataPoints && chco.bypassedDataPoints.length > 0 ? (
                            <Checkbox
                                id="alarming-inactive-check-box"
                                data-test="bypassedDataPoints"
                                checked={chco.state ? chco.state.showBypassedValues : false}
                                onClick={chco.onShowBypassedValuesClick}
                            >
                                Alarming inactive
                            </Checkbox>
                        ) : null}
                        {chco &&
                        chco.state &&
                        chco.state.startRunStopRunSettings &&
                        chco.state.startRunStopRunSettings.showStartRunStopRunCheckBox ? (
                            <Checkbox
                                id="start-stop-run-check-box"
                                data-test="showStartRunStopRunValues"
                                checked={chco.state.startRunStopRunSettings.showStartRunStopRunValues}
                                onClick={chco.onShowStartRunStopRunClick}
                            >
                                Start/Stop Run
                            </Checkbox>
                        ) : null}
                        {chco &&
                        chco.state &&
                        chco.state.alarmingOnAlarmingOffSettings &&
                        chco.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffCheckBox ? (
                            <Checkbox
                                id="alarming-on-off-check-box"
                                data-test="showAlarmingOnAlarmingOffValues"
                                checked={chco.state.alarmingOnAlarmingOffSettings.showAlarmingOnAlarmingOffValues}
                                onClick={chco.onShowAlarmingOnAlarmingOffRunClick}
                            >
                                Alarming On/Off
                            </Checkbox>
                        ) : null}
                        {chco &&
                        chco.state &&
                        chco.state.flightModeOnOffSettings &&
                        chco.state.flightModeOnOffSettings.showFlightModeOnOffCheckBox ? (
                            <Checkbox
                                id="flight-mode-on-off-check-box"
                                data-test="showFlightModeOnOffValues"
                                checked={chco.state.flightModeOnOffSettings.showFlightModeOnOffValues}
                                onClick={chco.onShowFlightModeOnOffClick}
                            >
                                Flight Mode On/Off
                            </Checkbox>
                        ) : null}
                        {chco && chco.state && chco.state.logStatusesSettings && chco.state.logStatusesSettings.showLogStatusesCheckBox ? (
                            <Checkbox
                                id="device-state-check-box"
                                data-test="showLogStatusesValues"
                                checked={chco.state.logStatusesSettings.showLogStatusesValues}
                                onClick={chco.onShowLogStatusesClick}
                            >
                                Device State
                            </Checkbox>
                        ) : null}
                        {chco &&
                        chco.state &&
                        chco.state.sensorLimitAlarmsSettings &&
                        chco.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsCheckBox ? (
                            <Checkbox
                                id="limit-alarms-check-box"
                                data-test="showSensorLimitAlarmsValues"
                                checked={chco.state.sensorLimitAlarmsSettings.showSensorLimitAlarmsValues}
                                onClick={chco.onShowSensorLimitAlarmsClick}
                            >
                                Limit Alarms
                            </Checkbox>
                        ) : null}
                        {chco &&
                        chco.state &&
                        chco.state.sensorIssueAlarmsSettings &&
                        chco.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsCheckBox ? (
                            <Checkbox
                                id="issue-alarms-check-box"
                                data-test="showSensorIssueAlarmsValues"
                                checked={chco.state.sensorIssueAlarmsSettings.showSensorIssueAlarmsValues}
                                onClick={chco.onShowSensorIssueAlarmsClick}
                            >
                                Issue Alarms
                            </Checkbox>
                        ) : null}
                        {chco && chco.state && chco.state.menuSetMarkSettings && chco.state.menuSetMarkSettings.showMenuSetMarkCheckBox ? (
                            <Checkbox
                                id="mark-counter-check-box"
                                data-test="showMenuSetMarkValues"
                                checked={chco.state.menuSetMarkSettings.showMenuSetMarkValues}
                                onClick={chco.onShowMenuSetMarkClick}
                            >
                                Mark Counter
                            </Checkbox>
                        ) : null}
                        {chco &&
                        chco.state &&
                        chco.state.sensorReplacesSettings &&
                        chco.state.sensorReplacesSettings.showSensorReplacesCheckBox ? (
                            <Checkbox
                                id="replace-sensor-check-box"
                                data-test="showSensorReplacesValues"
                                checked={chco.state.sensorReplacesSettings.showSensorReplacesValues}
                                onClick={chco.onShowReplaceSensorClick}
                            >
                                Replace Sensor
                            </Checkbox>
                        ) : null}
                        {/* {chco.occurrences && chco.occurrences.length > 0 ? <Checkbox checked={chco.state.showInTransit}
                onClick={chco.onShowInTransitClick}>Show in Transit</Checkbox> : null} */}
                    </div>
                </AccordionItemBody>
            </AccordionItem>
        </Accordion>
    );
};

export default ChartLegend;
