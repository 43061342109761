const roles = {
	superAdminRole: 'superAdmin',
	adminRole: 'admin',
	basicRole: 'basic',
	managerRole: 'manager',
	operatorRole: 'operator',
	operatorplusRole: 'operatorplus',
	superUser: 'superuser',
	liberogSensorStatus: 'liberog-sensor-status',
	liberogLiveLink: 'liberog-livelink',
};

export const UserRoles = {
	admin: [roles.superAdminRole, roles.adminRole, roles.superUser],
	calibrator: [roles.superAdminRole, roles.adminRole, roles.superUser, roles.operatorRole, roles.operatorplusRole],
	basic: [roles.basicRole],
	manager: [roles.managerRole],
	operator: [roles.operatorRole, roles.operatorplusRole],
	default: Object.values(roles),
};

export default UserRoles;
