import React, {Component} from 'react';
import {Alert, Input} from 'reactstrap';
import FormValidator from '../Forms/FormValidator';
import ForgotPasswordService from './ForgotPasswordService';
import {Alert as AntAlert} from 'antd';
import {Trans} from 'react-i18next';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            formForgotPassword: {
                email: '',
            },
        };
    }

    componentDidMount() {}

    validateOnChange = event => {
        const input = event.target;
        const form = input.form;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result,
                },
            },
        });
    };

    resetPassword = e => {
        ForgotPasswordService.requestResetPassword(this.state.formForgotPassword)
            .then(response => {
                this.setState({errorMessage: undefined, success: true});
            })
            .catch(error => {
                this.setState({errorMessage: error.data.message, success: false});
            });

        e.preventDefault();
    };

    render() {
        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center bg-white">
                        <a>
                            <img width={200} className="block-center rounded" src="img/logo.svg" alt="Logo" />
                        </a>
                    </div>
                    <div className="card-body">
                        <form name="formForgotPassword" onSubmit={this.resetPassword}>
                            <span>
                                Please type your e-mail and click reset password. You will receive an e-mail with instructions to reset your
                                password.
                            </span>
                            <br />
                            <br />
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input
                                        type="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="Email"
                                        data-validate='["required", "email"]'
                                        onChange={this.validateOnChange}
                                        value={this.state.formForgotPassword.email}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <button className="btn btn-block btn-primary mt-3" type="submit">
                                Reset Password
                            </button>
                            <div>
                                <p />
                                {this.state.success || this.state.errorMessage ? (
                                    <div className="alert-msg">
                                        <AntAlert description={<Trans i18nKey={'login.forgot_password.success'} />} type="success" />
                                    </div>
                                ) : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
