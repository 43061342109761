import React from 'react';
import {Trans} from 'react-i18next';

type IssueAlarmsTitleProps = {
	show: boolean;
};

export default function IssueAlarmsTitleComponent(props: IssueAlarmsTitleProps) {
	return (
		props.show && (
            <tr style={{backgroundColor: '#b1b3b3'}}>
            <td>
                <Trans i18nKey={'sensorWizard.summary.issueAlarms'} />
            </td>
            <td />
        </tr>
		)
	);
}
