import React, {Component, useState} from 'react';
import {Button, Col, Input, Space} from 'antd';
import {FilterFilled} from '@ant-design/icons';
import {Switch} from 'antd';
import styled from 'styled-components';
import LicensingService from './LicensingService';
import BillingService from '../Billing/BillingService';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import {Trans} from 'react-i18next';
import SensorService from '../../Dashboard/Sensor/SensorService';
import DeviceType from '../../Infrastructure/Device/DeviceType';
import Shared from '../../Shared/Shared';
import LicenseActionForm, {Offer, RetireSensor} from '../../Shared/Components/LicenseActionForm';
import {AntStyledTable} from '../../Shared/Styles/AntStyledTable';
import swal from 'sweetalert2';
import Message from '../../Shared/Components/Message';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import {Access} from '../../Infrastructure/Authorization/Components';
import RequestLogger from '../../Infrastructure/Requests/Logger/RequestLogger';
import {LicenseProps} from './LicenseProps';
import {LicenseState} from './LicenseState';
import {ColumnType} from 'antd/es/table';
import {FilterDropdownProps} from 'antd/es/table/interface';
import {getLicensesPaymentMethodSettings} from './Helpers/LicensesSettingsBuilder';
import {ContentWrapper, ViewWrapper} from '../../Layout';
import {ViewHeader} from '../../Common';
import dayjs from 'dayjs';

const FilterDropDownWrapper = styled.div`
	padding: 8px;
`;

const FilterButton = styled(Button)`
	width: 90px;
`;

const FilterInput = styled(Input)`
	box-shadow: none;
	width: 188px;
	margin-bottom: 8px;
	display: block;
	height: 32px;
`;

const ExtendLicenses = props1 => {
	return (
		props1.location.state?.license && (
			<LicenseActionForm
				saveAndHeadingText="Extend license"
				objectActionVerb="extended"
				actionMethod={LicensingService.extendLicenseForOneYear}
				retireSensor={null}
				getOffering={BillingService.getOfferingCurrentSensor}
				sensorId={props1.location.state.license[0].sensors_id}
				cost={props1.location.state.license[0].cost}
				licensesPaymentMethodSettings={getLicensesPaymentMethodSettings(props1.location.state.license[0].module)}
			/>
		)
	);
};

const RetireLicenses = props1 => {
	if (props1.location.state?.isForSubscription) {
		const retireSensor: RetireSensor = {
			moduleId: props1.location.state?.sensor.ModuleId?.toString(),
			history: props1.history,
			deviceViewSettings: props1.location.state?.devicesViewSettings,
			serialNumber: props1.location.state?.sensor.SerialNumber,
			channelNumber: props1.location.state?.sensor.ChannelNumber,
			valueIndex: props1.location.state?.sensor.ValueIndex,
		};

		return (
			<LicenseActionForm
				saveAndHeadingText="Retire sensor"
				objectActionVerb="retired"
				retireSensor={retireSensor}
				actionMethod={null}
				getOffering={(f = {}) => new Promise<{data: Offer}>(_ => {})}
				sensorId={null}
				cost={{actual_price: undefined, lines: [], vat_amount: undefined, voucher_rest: undefined}}
				licensesPaymentMethodSettings={getLicensesPaymentMethodSettings(props1.location.state?.sensor.SerialNumber)}
			/>
		);
	} else {
		return (
			props1.location.state?.license && (
				<LicenseActionForm
					saveAndHeadingText="Retire sensor"
					objectActionVerb="retired"
					retireSensor={null}
					actionMethod={LicensingService.retireLicenseForOneYear}
					getOffering={(
						f = {
							vouchers: [],
							is_libero_g: props1.location.state.license[0].is_libero_g,
							serial_number: props1.location.state.license[0].module,
						}
					) =>
						BillingService.getOfferingOneYearRetirementForSensor(
							f,
							RequestLogger.createLogData('licenses', 'retire-licenses', 'onClick')
						)
					}
					sensorId={props1.location.state.license[0].sensors_id}
					cost={props1.location.state.license[0].cost}
					licensesPaymentMethodSettings={getLicensesPaymentMethodSettings(props1.location.state.license[0].module)}
				/>
			)
		);
	}
};

const ExtendRetiredLicenses = props1 => {
	return (
		props1.location.state?.license && (
			<LicenseActionForm
				saveAndHeadingText="Extend sensor"
				objectActionVerb="retired"
				actionMethod={LicensingService.extendLicenseForOneYear}
				retireSensor={null}
				getOffering={(
					f = {
						vouchers: [],
						is_libero_g: props1.location.state.license[0].is_libero_g,
						serial_number: props1.location.state.license[0].module,
					}
				) =>
					BillingService.getOfferingOneYearRetirementForSensor(
						f,
						RequestLogger.createLogData('licenses', 'extend-retired-licenses', 'onClick')
					)
				}
				sensorId={props1.location.state.license[0].sensors_id}
				cost={props1.location.state.license[0].cost}
				licensesPaymentMethodSettings={getLicensesPaymentMethodSettings(props1.location.state.license[0].module)}
			/>
		)
	);
};

class Licenses extends Component<LicenseProps, LicenseState> {
	constructor(props: LicenseProps | Readonly<LicenseProps>) {
		super(props);

		this.state = {
			loading: false,
			data: null,
			selectedLicenses: [],
			canExtendLicense: true,
			isRetiredLicense: true,
			selectedRowKeys: [],
			filterSearchText: '',
			searchedColumn: '',
		};
	}

	searchInput: any;

	componentDidMount() {
		this.fetch();
	}

	extendLicenses = e => {
		this.props.history.push({
			pathname: !this.state.isRetiredLicense ? '/extendLicenses' : '/extendRetiredLicenses',
			state: {
				license: this.state.selectedLicenses,
			},
		});

		e.preventDefault();
	};

	retireLicenses = e => {
		BillingService.getOfferingOneYearRetirementForSensor(
			{
				vouchers: [],
				is_libero_g: this.state.selectedLicenses[0].is_libero_g,
				serial_number: this.state.selectedLicenses[0].module,
			},
			RequestLogger.createLogData('licenses', 'load-offering-one-year-retirement-for-sensor', 'onClick')
		).then(response => {
			let lics = this.state.selectedLicenses;
			lics[0].cost = response.data;
			this.props.history.push({
				pathname: '/retireLicenses',
				state: {license: lics, isForSubscription: false},
			});
		});

		e.preventDefault();
	};

	// Automatic extension may have a warning, i.e. there is no default credit card
	// If there is a warning, there is a message shown where the user can proceed
	// If he does not proceed, there switch is turned back to its original state
	// the turn-back is done by reloading the entire page (it can be optimized, reloading just the row, but it is,
	// for now, fast enough)
	handleAutomaticRenewal = (on, sensor_id) => {
		//...Warned is the same function as without the suffix, the difference is, errors are thrown, otherwise they
		// are not thrown (i.e. errors are accepted from the backend)
		let setRenewalFetched = checkIntegrity =>
			LicensingService.setAutomaticLicenseRenewalBySensorId(
				sensor_id,
				on,
				checkIntegrity,
				RequestLogger.createLogData('licenses', 'set-automatic-licences-renewal-by-sensor-id', 'onClick')
			).then(() => {
				this.fetch();
			}); //also rerender

		let setRenewalWarned = () => setRenewalFetched(true);

		setRenewalWarned().catch(response => {
			swal.fire('Warning', response.data.message, 'warning').then(result => {
				if (result.value) {
					setRenewalFetched(false).catch(error => {
						Message.error('Error', 'Automatic license renewal failed', error);
					});
				}
			});
		});
	};

	fetch = (params = {}) => {
		this.setState({loading: true});

		LicensingService.licenses(params, RequestLogger.createLogData('licenses', 'load-licenses', 'onLoad'))
			.then(response => {
				return response.data;
			})
			.then(licenses => {
				const retiredLicenseOfferingRequests = licenses
					.filter(l => l.is_retirement_type)
					.flatMap(l => {
						return {
							logging_interval: 0,
							vouchers: [],
							is_libero_g: l.is_libero_g,
							sensors_id: l.sensors_id, //LUE
							serial_number: l.module, //LUE
						};
					});

				const retiredLicenseOfferings = BillingService.getOfferingsOneYearRetirementForSensors(
					retiredLicenseOfferingRequests,
					RequestLogger.createLogData('licenses', 'load-offering-one-year-retirement-for-sensor', 'onLoad')
				);

				const activeLicenseOfferingRequests = licenses
					.filter(l => !l.is_retirement_type)
					.flatMap(l => {
						return {
							logging_interval: l.logging_interval,
							sms_active: l.sms_notifications ?? false,
							vouchers: [],
							is_libero_g: l.is_libero_g,
							serial_number: l.module, //LUE
							sensors_id: l.sensors_id, //LUE
						};
					});

				const activeBaseLicenseOfferings = BillingService.getOfferings(
					activeLicenseOfferingRequests,
					RequestLogger.createLogData('licenses', 'load-offering', 'onLoad')
				);

				Promise.all([retiredLicenseOfferings, activeBaseLicenseOfferings]).then(r => {
					const offerings = r.flatMap(response => response.data);
					const tableDataSource = licenses.map(row => {
						row.key = row.sensors_id;
						row.cost = offerings.find(o => o.sensorId == row.sensors_id).paymentInfo;
						return row;
					});

					this.setState({
						loading: false,
						data: tableDataSource,
					});
				});
			})
			.catch(_ => this.setState({loading: false}));
	};

	columns = () => {
		let columns: Partial<ColumnType<any>>[] = [
			{
				title: 'Sensor',
				dataIndex: 'sensor_name',
				...this.getColumnSearchProps('sensor_name', 'Search Sensor'),
			},
			{
				title: 'Module',
				dataIndex: 'module',
				...this.getColumnSearchProps('module', 'Search Module'),
			},
			{
				title: 'Valid Until',
				render: record => {
					return DateTimeUtils.setDateTimeWithOffset_date_dep(record.valid_until);
				},
				sorter: (a, b) => {
					return dayjs(a.valid_until).unix() - dayjs(b.valid_until).unix();
				},
			},
			{
				title: 'Options',
				render: record => {
					if (record.is_retirement_type) {
						return <>Retired</>;
					} else {
						return (
							<div>
								<Trans i18nKey={'sensorWizard.summary.loggingInterval'} /> {record.logging_interval / 60}{' '}
								<Trans i18nKey={'sensorWizard.summary.minutes'} />
								{record.sms_notifications ? ' / SMS' : ''}
							</div>
						);
					}
				},
			},
			{
				title: 'Cost / Year',
				align: 'right',
				render: record => {
					return <div>{record.cost.actual_price} CHF</div>;
				},
			},
			{
				title: 'Automatic Renewal',
				align: 'center',
				render: record => {
					return (
						<Switch
							id={record.sensor_name}
							checked={record.automatic_renewal}
							onChange={on => this.handleAutomaticRenewal(on, record.sensors_id)}
							disabled={!record.renewable}
						/>
					);
				},
			},
		];
		return columns;
	};

	getColumnSearchProps = (dataIndex, placeholderText): Partial<ColumnType<any>> => ({
		filterDropdown: (props: FilterDropdownProps) => {
			return (
				<FilterDropDownWrapper>
					<FilterInput
						id={dataIndex + 'SearchValue'}
						ref={node => {
							this.searchInput = node;
						}}
						placeholder={placeholderText}
						value={props.selectedKeys[0]}
						onChange={e => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => this.handleColumnFilterSearch(props.selectedKeys, props.confirm, dataIndex)}
					/>
					<Space>
						<FilterButton
							id={dataIndex + 'SearchButton'}
							onClick={() => this.handleColumnFilterSearch(props.selectedKeys, props.confirm, dataIndex)}
						>
							Search
						</FilterButton>
						<FilterButton id={dataIndex + 'Reset'} onClick={() => this.handleColumnFilterReset(props.clearFilters)}>
							Reset
						</FilterButton>
					</Space>
				</FilterDropDownWrapper>
			);
		},
		filterIcon: filtered => (
			<FilterFilled id={dataIndex + 'SearchIcon'} type="filter" style={{color: filtered ? '#1890ff' : undefined}} />
		),
		onFilter: (value: string, record) => {
			return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false;
		},
	});

	handleColumnFilterSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		const newState = {
			...this.state,
			searchedColumn: dataIndex,
			filterSearchText: selectedKeys[0] || '',
		};

		this.setState(newState);
	};

	handleColumnFilterReset = clearFilters => {
		clearFilters();

		this.searchInput = null;
		this.setState({filterSearchText: ''});
	};

	rowSelection = () => {
		const {selectedRowKeys} = this.state;
		return {
			type: 'radio',
			selectedRowKeys,
			onChange: (selectedRowKeys, selectedRows) => {
				SensorService.sensor(selectedRows[0].sensors_id, RequestLogger.createLogData('licences', 'load-sensor-data', 'onClick'))
					.then(_ => {
						this.setState({
							selectedLicenses: selectedRows,
							canExtendLicense: selectedRows[0].renewable,
							selectedRowKeys: [...selectedRowKeys],
							isRetiredLicense: selectedRows[0].is_retirement_type,
						});
					})
					.catch(e => console.error(e));
			},
		};
	};

	render() {
		return (
			<ViewWrapper>
				<ViewHeader heading={'sidebar.nav.licenses'} knowledgeHelpId={'042'}>
					<Access access={accessPermissions.settings.child.licenses.child.extendLicenses} roles={userRoles.default}>
						<Col>
							<Button
								id="btnExtendLicenses"
								size={'large'}
								type={'primary'}
								onClick={this.extendLicenses}
								disabled={!this.state.selectedLicenses.length || !this.state.canExtendLicense}
							>
								<span>
									{DeviceType.getDeviceType() === 1 ? (
										<Trans i18nKey={'settings.licenses.manually_extend_license'} />
									) : (
										<Trans i18nKey={'settings.licenses.manually_extend_license_for_mobile'} />
									)}
								</span>
							</Button>
						</Col>
					</Access>
					<Access access={accessPermissions.settings.child.licenses.child.extendRetiredLicenses} roles={userRoles.default}>
						<Col>
							<Button
								id="btnRetireLicenses"
								size="large"
								type={'primary'}
								onClick={this.retireLicenses}
								disabled={this.state.isRetiredLicense}
							>
								<span>Retire Sensor</span>
							</Button>
						</Col>
					</Access>
				</ViewHeader>
				<ContentWrapper>
					<AntStyledTable
						scroll={Shared.setTableScroll()}
						locale={{emptyText: 'No data'}}
						columns={this.columns()}
						dataSource={this.state.data}
						loading={this.state.loading}
						pagination={false}
						rowSelection={this.rowSelection()}
					/>
				</ContentWrapper>
			</ViewWrapper>
		);
	}
}

export {ExtendLicenses, RetireLicenses, ExtendRetiredLicenses, Licenses};
