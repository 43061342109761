import React from 'react';
import {Trans} from 'react-i18next';

type MetadatasSensorNameProps = {
	hasError: any;
	sensorName: string;
	show: boolean;
};

export default function MetadatasSensorNameComponent(props: MetadatasSensorNameProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.sensorName'} />
				</td>
				{props.hasError('sensor_name') ? (
					<td style={{color: 'red'}}>
						{props.sensorName} <Trans i18nKey={'sensorWizard.summary.notUnique'} />
					</td>
				) : (
					<td>{props.sensorName}</td>
				)}
			</tr>
		)
	);
}
