import React, {Component} from 'react';
import {Button} from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import BillingService from './BillingService';
import moment from 'moment/moment';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Shared from '../../Shared/Shared';
import {AntStyledTable} from '../../Shared/Styles/AntStyledTable';
import Message from '../../Shared/Components/Message';
import {SyncOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} from 'react-accessible-accordion';
import KnowledgeHelp from '../../Shared/Components/KnowledgeBaseHelp';
import {StyledHeaderHelpIcon} from '../../Shared/Styles/StyledHeaderHelpIcon';
import AntPopover from '../../Shared/UI/Ant/AntPopover';
import LicensingService from '../Licenses/LicensingService';
import {Trans} from 'react-i18next';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import {Access} from '../../Infrastructure/Authorization/Components';
import RequestLogger from '../../Infrastructure/Requests/Logger/RequestLogger';
import {DeleteCreditCard, GetCreditCards} from '../../../common/services/WebserverServicePro/CreditCardService';

class Billing extends Component {
	state = {
		credit_cards: [],
		invoices: [],
		prepaidCredit: [],
		credit_cards_loading: true,
		invoices_loading: true, //also prepaid
		selectedRowKeys: [],
		prepaidAmount: 0,
		prepaid_enabled: false,
		canDeleteDefaultCreditCard: true,
	};

	rechargeIcon = (<SyncOutlined className="btn btn-primary" style={{fontSize: '20px'}} />);

	prepaidCreditColumns = [
		{
			key: 'upload',
			title: 'Uploads by date',
			dataIndex: 'created',
			sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
			defaultSortOrder: 'descend',
			width: 200,
			render: created => <span>{DateTimeUtils.setDateTimeWithOffset_date_dep(moment.utc(created))}</span>,
		},
		{
			key: 'upload amount',
			title: 'Amount',
			dataIndex: 'amount',
			render: amount => <span>CHF {amount}</span>,
		},
		{
			title: 'Details',
			key: 'id',
			align: 'center',
			width: 50,
			render: record => (
				<span>
					{record.invoice_url ? (
						<a
							className="fs1 elpro-Report"
							href={record.invoice_url}
							target="_blank"
							rel="noopener noreferrer"
							style={{cursor: 'pointer', color: 'inherit'}}
						/>
					) : null}
				</span>
			),
		},
	];

	invoicesColumns = [
		{
			key: 'created',
			title: 'Date',
			dataIndex: 'created',
			sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
			defaultSortOrder: 'descend',
			width: 200,
			render: created => <span>{DateTimeUtils.setDateTimeWithOffset_date_dep(moment.utc(created))}</span>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			sorter: false,
			render: amount => <span>CHF {amount}</span>,
		},
		{
			title: 'Invoice',
			key: 'operation',
			align: 'center',
			width: 50,
			render: record => (
				<span>
					{record.invoice_url ? (
						<Access
							access={accessPermissions.settings.child.billing.child.payments.child.viewPayment}
							roles={userRoles.default}
						>
							<a
								className="fs1 elpro-Report"
								href={record.invoice_url}
								target="_blank"
								rel="noopener noreferrer"
								style={{cursor: 'pointer', color: 'inherit'}}
							/>
						</Access>
					) : record.is_prepaid_redeem ? (
						'prepaid credit'
					) : (
						''
					)}
				</span>
			),
		},
	];

	showWarningMessage = translationKey => {
		return (
			<div style={{fontSize: '13px', lineHeight: '1.7em'}}>
				<p>
					<Trans i18nKey={translationKey} />
				</p>
			</div>
		);
	};

	prepaidSummaryColumns = [
		{
			key: 'amount',
			title: 'Total amount',
			dataIndex: 'amount',
			render: amount => `CHF ${amount.toFixed(2)}`,
		},
		{
			title: 'Recharge',
			key: 'operation',
			fixed: 'right',
			width: 50,
			align: 'center',
			render: record => {
				return (
					<Access
						access={accessPermissions.settings.child.billing.child.prepaidCard.child.rechargePrepaid}
						roles={userRoles.default}
					>
						{record.recharge_enabled ? (
							<Link to="/rechargePrepaid">{this.rechargeIcon}</Link>
						) : (
							<AntPopover
								title="Enable Recharge"
								content={this.showWarningMessage('settings.billing.prepaid.recharge_prepaid_enable_warning')}
								trigger="click"
								placement="topRight"
							>
								{this.rechargeIcon}
							</AntPopover>
						)}
					</Access>
				);
			},
		},
	];

	componentDidMount() {
		this.fetch();
	}

	fetch = (params = {}) => {
		LicensingService.licenses(params, RequestLogger.createLogData('billing', 'load-licences', 'onLoad'))
			.then(response => {
				this.setState({canDeleteDefaultCreditCard: !response.data.find(card => card.automatic_renewal)});
			})
			.catch(error => console.log('error', error));

		GetCreditCards()
			.then(response => {
				const creditCards = response.map((card, index) => {
					card['key'] = index;
					return card;
				});

				const defaultCreditCardRowKey = creditCards.find(card => card.defaultCard);

				this.setState(
					{
						credit_cards_loading: false,
						credit_cards: creditCards,
						selectedRowKeys: defaultCreditCardRowKey ? [defaultCreditCardRowKey.key] : [],
					},
					() => {
						if (creditCards.length >= 1 && !defaultCreditCardRowKey) {
							BillingService.updateCreditCard(
								creditCards[0],
								RequestLogger.createLogData('billing', 'update-credit-card', 'onLoad')
							).then(() => {
								this.setState({
									selectedRowKeys: [creditCards[0].key],
								});
							});
						}
					}
				);
			})
			.catch(error => this.setState({credit_cards_loading: false}));

		const gets = [
			BillingService.invoices(RequestLogger.createLogData('billing', 'load-invoices', 'onLoad')),
			BillingService.prepaidAmountAndLastRecharge(
				RequestLogger.createLogData('billing', 'load-prepaid-amount-and-last-recharge', 'onLoad')
			),
		];

		Promise.all(gets)
			.then(responses => {
				this.setState({
					invoices_loading: false,
					invoices: responses[0].data,
					prepaidAmount: responses[1].data.amount,
					prepaid_enabled: responses[1].data.prepaid_enabled,
				});
			})
			.catch(error => this.setState({invoices_loading: false}));
	};

	addCreditCard = e => {
		this.props.history.push('/addCreditCard');
		e.preventDefault();
	};

	deleteCreditCard = (e, row) => {
		const MySwal = withReactContent(Swal);
		MySwal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		}).then(result => {
			if (result.value) {
				DeleteCreditCard(row.id)
					.then(r => this.fetch())
					.catch(error => console.log('error', error));
			}
		});

		e.preventDefault();
	};

	deleteCreditCardColumnRender = record => {
		const className = 'fs1 elpro-Delete';
		const mHandle = {cursor: 'pointer'};
		const canDelete =
			this.state.credit_cards.length > 1 ? true : this.state.credit_cards.length === 1 && this.state.canDeleteDefaultCreditCard;

		if (canDelete) {
			return (
				<span>
					<em className={className} onClick={e => this.deleteCreditCard(e, record)} style={mHandle} />
				</span>
			);
		} else {
			return (
				<AntPopover
					title="Delete Credit card"
					content={this.showWarningMessage('settings.billing.credit_cards.delete_default_card_warning')}
					trigger="click"
					placement="topRight"
				>
					<span>
						<em className={className} style={mHandle} />
					</span>
				</AntPopover>
			);
		}
	};

	creditCardsColumns = [
		{
			title: 'Card Number',
			dataIndex: 'creditCardNumber',
			sorter: (a, b) => a.credit_card_number.localeCompare(b.credit_card_number),
			render: record => <span>**** **** **** {record}</span>,
		},
		{
			title: 'Name on Card',
			dataIndex: 'nameOnCard',
		},
		{
			title: 'Valid Until',
			dataIndex: 'validUntil',
		},
		{
			title: 'Delete',
			key: 'operation',
			align: 'center',
			width: 50,
			render: record => {
				return (
					<Access
						access={accessPermissions.settings.child.billing.child.creditCard.child.deleteCreditCard}
						roles={userRoles.default}
					>
						{this.deleteCreditCardColumnRender(record)}
					</Access>
				);
			},
		},
	];

	rechargePrepaidCredit = () => {
		this.props.history.push('/rechargePrepaid');
	};

	rowSelection = () => {
		const {selectedRowKeys} = this.state;
		return {
			columnTitle: 'Default',
			type: 'radio',
			selectedRowKeys,
			onChange: (selectedRowKeys, selectedRows) => {
				this.setState({selectedRowKeys});
				this.setDefaultCreditCard(selectedRows[0]);
			},
			renderCell: (checked, record, index, originNode) => {
				if (checked) {
					return <em className="fa fa-check fa-2x" />;
				} else {
					return (
						<Access
							access={accessPermissions.settings.child.billing.child.creditCard.child.changeDefaultCreditCard}
							roles={userRoles.default}
						>
							{originNode}
						</Access>
					);
				}
			},
		};
	};

	setDefaultCreditCard = creditCard => {
		BillingService.updateCreditCard(creditCard, RequestLogger.createLogData('billing', 'update-credit-card', 'onClick'))
			.then(() => Message.success('Credit card changed', 'Default credit card successfully changed'))
			.catch(error => Message.error('Error', 'Changes could not be saved', error));
	};

	wrap2Accordeon = WrappedComponent => {
		class Wrapper extends React.PureComponent {
			render() {
				const {accordeon_heading, prepaidEnabled, ...passThroughProps} = this.props;

				return (
					<Accordion>
						<AccordionItem expanded>
							<AccordionItemTitle>
								<h5 className="u-position-relative">
									{accordeon_heading}
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody style={{paddingTop: 20}}>
								{accordeon_heading === 'Prepaid credit' && !prepaidEnabled && (
									<h5>
										<strong>To use prepaid credit, activate this function once in your organization settings.</strong>
									</h5>
								)}

								<WrappedComponent {...passThroughProps} />
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				);
			}
		}

		return Wrapper;
	};

	render() {
		const AccordeonAntStyledTable = this.wrap2Accordeon(AntStyledTable); //having a heading to, please take note
		const AccordeonFragment = this.wrap2Accordeon(React.Fragment); //put in two tables

		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>Invoices & Means of Payment</div>
					<StyledHeaderHelpIcon>
						<KnowledgeHelp id="043" />
					</StyledHeaderHelpIcon>
					<div className="ml-auto">
						<Access
							access={accessPermissions.settings.child.billing.child.creditCard.child.addCreditCard}
							roles={userRoles.default}
						>
							<Button id="btnAddCreditCard" color="primary" size="lg" onClick={this.addCreditCard}>
								<span>Add Credit Card</span>
							</Button>
						</Access>
					</div>
				</div>

				<Access access={accessPermissions.settings.child.billing.child.creditCard} roles={userRoles.default}>
					<AccordeonAntStyledTable
						accordeon_heading="Credit cards"
						showSorterTooltip={false}
						scroll={Shared.setTableScroll()}
						locale={{emptyText: 'No data'}}
						columns={this.creditCardsColumns}
						dataSource={this.state.credit_cards}
						loading={this.state.credit_cards_loading}
						pagination={false}
						rowSelection={this.rowSelection()}
					/>
					<br />
				</Access>
				<Access access={accessPermissions.settings.child.billing.child.prepaidCard} roles={userRoles.default}>
					<AccordeonFragment accordeon_heading="Prepaid credit" prepaidEnabled={this.state.prepaid_enabled}>
						<AntStyledTable
							showSorterTooltip={false}
							scroll={Shared.setTableScroll()}
							locale={{emptyText: 'No data'}}
							columns={this.prepaidSummaryColumns}
							rowKey={record => record.amount}
							dataSource={[{amount: this.state.prepaidAmount, recharge_enabled: this.state.prepaid_enabled}]}
							loading={this.state.invoices_loading}
							pagination={false}
						/>
						<br />
						<AntStyledTable
							showSorterTooltip={false}
							scroll={Shared.setTableScroll()}
							locale={{emptyText: 'No data'}}
							columns={this.prepaidCreditColumns}
							rowKey={record => record.created}
							dataSource={this.state.invoices.filter(
								x =>
									x.invoice_type === 'prepaid_account' &&
									!x.is_prepaid_redeem &&
									x.amount > 0 /*filter out prepaid payments avoided by only enough vouchers, actually a workaround*/
							)}
							loading={this.state.invoices_loading}
							pagination={false}
						/>
					</AccordeonFragment>
					<br />
				</Access>
				<Access access={accessPermissions.settings.child.billing.child.payments} roles={userRoles.default}>
					<AccordeonAntStyledTable
						accordeon_heading="Payments"
						showSorterTooltip={false}
						scroll={Shared.setTableScroll()}
						locale={{emptyText: 'No data'}}
						columns={this.invoicesColumns}
						rowKey={record => record.created}
						dataSource={this.state.invoices.filter(
							x => x.invoice_type === 'payment' || (x.invoice_type === 'prepaid_account' && x.is_prepaid_redeem)
						)}
						loading={this.state.invoices_loading}
						pagination={false}
					/>
				</Access>
			</ContentWrapper>
		);
	}
}

export default Billing;
