export const CONSTANTS = {
	EMPTY: '',
	DEFAULT: '-',
	DEFAULT_EMPTY: 'noValue',
	DEFAULT_TEMPARATURE: '--',
	NO_DATA: 'noData',
	NO_DATE: 'no date',
	NO_VALUE: 'no value',
	NOT_AVAILABLE: 'n.a.',
	YES: 'Yes',
	NO: 'No',
};
