import {CheckboxRenderer} from './CheckboxRenderer';
import {COLUMNS} from '../../Constants/Columns';

export const validateParamsToRenderCheckboxComponent = params => {
    const checkBox = {
        component: CheckboxRenderer,
    };
    if (params.colDef && params.data) {
        if (
            params.colDef.field === COLUMNS.MISSING_COMMUNICATION_WARNING ||
            params.colDef.field === COLUMNS.SENSOR_FAILURE_ALARM ||
            params.colDef.field === COLUMNS.LOST_MEASUREMENT_ALARM ||
            params.colDef.field === COLUMNS.LOW_BATTERY_WARNING
        ) {
            return checkBox;
        }
    }
    return undefined;
};
