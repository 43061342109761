import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Row} from 'antd';
import {AntButton} from '../Shared/UI/Ant';

const SerialNumberForm = ({disableFormElement, identifier, checkStatusManually, changeSerialNumber, placeholder = 'Serial Number'}) => {
	const [checkSensorStateForm] = Form.useForm();
	const [disableBtn, setDisableBtn] = useState(false);

	useEffect(() => {
		const disableBtn = disableSubmitBtn(identifier);
		setDisableBtn(disableBtn);
		checkSensorStateForm.setFieldsValue({serial: identifier});
	}, [identifier]);

	const disableSubmitBtn = value => {
		if (value === '') return true;
	};

	return (
		<Row>
			<Col span={16} offset={4}>
				<Form
					form={checkSensorStateForm}
					preserve={false}
					initialValues={{
						serial: identifier,
					}}
				>
					<Row>
						<Col flex="auto">
							<Form.Item name="serial">
								<Input
									disabled={disableFormElement}
									placeholder={placeholder}
									autoComplete="nope"
									onChange={changeSerialNumber}
								/>
							</Form.Item>
						</Col>
						<Col flex="100px">
							<Form.Item>
								<AntButton
									type="primary"
									htmlType="submit"
									disabled={disableFormElement || disableBtn}
									onClick={checkStatusManually}
								>
									Submit
								</AntButton>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>
		</Row>
	);
};

export default SerialNumberForm;
