import IssueProfilesExtension from '../../../../Services/Extensions/IssueProfiles';
import RequestLogger from '../../../../Infrastructure/Requests/Logger/RequestLogger';
import Message from '../../../../Shared/Components/Message';
import {getIssueProfiles} from '../../Queries/IssueProfilesQueries';

export function copyIssueProfile(issueProfile, parent) {
    IssueProfilesExtension.SaveProfile(issueProfile, RequestLogger.createLogData('issue-profiles', 'copy-issue-profile', 'onClick'))
        .then(() => {
            Message.success('Issue profile copied', 'Issue profile successfully copied');
            getIssueProfiles(parent);
        })
        .catch(error => {
            Message.error('Error', 'Issue profile could not be copied', error.data);
        });
}

export function saveIssueProfile(issueProfile, parent) {
    IssueProfilesExtension.SaveProfile(issueProfile, RequestLogger.createLogData('issue-profiles', 'save-issue-profile', 'onClick'))
        .then(() => {
            Message.success('Issue profile added', 'Issue profile successfully added');
            parent.props.history.push('/issueProfiles');
        })
        .catch(error => {
            Message.error('Error', 'Issue profile could not be added', error.data);
        });
}

export function updateIssueProfile(issueProfile, parent) {
    IssueProfilesExtension.UpdateProfile(issueProfile, RequestLogger.createLogData('issue-profiles', 'update-issue-profile', 'onClick'))
        .then(() => {
            Message.success('Issue profile updated', 'Issue profile successfully updated');
            parent.props.history.push('/issueProfiles');
        })
        .catch(error => {
            Message.error('Error', 'Issue profile could not be updated', error.data);
        });
}
