import React from 'react';
import {Trans} from 'react-i18next';
import {Alert} from 'antd';

export default function SmsDowngradeWarningAlert() {
	return (
		<div>
			<Alert
				key="smsDowngradeWarning"
				description={<Trans i18nKey={'sensorWizard.messages.sms_downgrade_warning'} />}
				type="warning"
			/>
			<br />
		</div>
	);
}
