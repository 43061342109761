import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';

export function ActionButton({onClick, locked, actionType, id}: ButtonProps): ReactElement {
	const className = 'elpro-Button-M elpro-Data-Grid-' + actionType;
	const Button = styled.span`
		margin: 4px;
		cursor: ${locked ? 'not-allowed' : 'pointer'};
		color: ${locked ? 'dimgray' : ''};
		opacity: ${locked ? 0.7 : 1};
	`;
	return (
		<Tooltip title={locked ? 'You cannot ' + actionType.toLowerCase() + ' a locked item' : actionType}>
			<Button className={className} onClick={locked ? undefined : onClick} id={id} alt />
		</Tooltip>
	);
}

type ButtonProps = {
	id: string;
	onClick: string;
	locked: boolean;
	actionType: ActionButtonAction;
};

export enum ActionButtonAction {
	Copy = 'Copy',
	Edit = 'Edit',
	Delete = 'Delete',
}
