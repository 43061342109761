import {DiscoverModuleChannelType, DiscoverModuleResponse} from '../../../../common/services/SensorService/DiscoverModuleResponse';
import {ChannelType} from '../AddSensor/WizardState';

export function DiscoverModuleChannelTypeToChannelType(
	discoverModuleChannel: DiscoverModuleChannelType,
	moduleData: DiscoverModuleResponse
): ChannelType {
	const channelType: ChannelType = {
		symbolValue: symbolValueForView(discoverModuleChannel),
		units: moduleData.units
			.filter(u => u.symbol == discoverModuleChannel.symbol)
			.map(u => ({
				id: u.id,
			})),
		value_index: discoverModuleChannel.value_index,
	};

	// Try to find the previous sensor id
	if (moduleData.module.used_channels) {
		try {
			const channelNumber = moduleData.module.channels.find(c => c.channel_type == discoverModuleChannel.channel_type).channel_number;
			const valueIndex = discoverModuleChannel.value_index;
			const usedChannel = moduleData.module.used_channels.find(c => c.channel_number == channelNumber && c.value_index == valueIndex);
			if (usedChannel) {
				channelType.previousSensorId = usedChannel.sensors_id;
			}
		} catch (_) {}
	}

	return channelType;
}

export const SymbolValueString = {
	temperature: 'Temperature',
	relative_humidity: 'Relative humidity',
	temperature_int: 'Temperature (internal)',
	temperature_ext: 'Temperature (external)',
};

const symbolValueForView = channel_type => {
	switch (channel_type.channel_type) {
		case 'TT':
			return channel_type.value_index === 0 ? SymbolValueString['temperature_int'] : SymbolValueString['temperature_ext'];
		default:
			return SymbolValueString[channel_type.symbol];
	}
};

export const getFreeChannelsFromModule = (moduleChannels, usedModuleChannels, moduleTypes) => {
	let freeChannels = [];
	const usedValueIndexes = usedModuleChannels ? usedModuleChannels.map(used => used.value_index) : [];
	const usedChannels = usedModuleChannels ? usedModuleChannels.map(used => used.channel_number) : [];

	// find channels, that are not used
	if (usedModuleChannels) {
		moduleChannels.forEach(channel => {
			usedModuleChannels.forEach(channelType => {
				if (channel.channel_number !== channelType.channel_number) {
					if (usedValueIndexes.indexOf(channelType.value_index) === -1 || usedChannels.indexOf(channel.channel_number) === -1) {
						freeChannels.push(channel);
					}
				} else {
					moduleTypes
						.filter(types => types.channel_type === channel.channel_type)
						.forEach(type => {
							if (usedValueIndexes.indexOf(type.value_index) === -1) {
								freeChannels.push(channel);
							}
						});
				}
			});
		});
	} else {
		// there are no used channels, all free
		freeChannels = moduleChannels;
	}

	return freeChannels;
};

export const getFreeChannelDataFromModule = (moduleChannelsFree, moduleChannelTypes, moduleUnits, moduleChannelsUsed) => {
	const freeChannels = JSON.parse(JSON.stringify(moduleChannelsFree));
	const channelTypes = JSON.parse(JSON.stringify(moduleChannelTypes));
	const units = JSON.parse(JSON.stringify(moduleUnits));
	const usedChannels = moduleChannelsUsed ? moduleChannelsUsed.map(used => used.value_index) : null;

	let channelData = [];

	freeChannels.forEach(channel => {
		const types = channelTypes.filter(obj => obj.channel_type === channel.channel_type);
		let channelType = {
			channel_number: channel.channel_number,
			channel_types: types.length > 1 && usedChannels ? [types.find(type => usedChannels.indexOf(type.value_index) === -1)] : types,
		};
		channelData.push(channelType);
		channelType = null;
	});

	channelData.forEach(channel => {
		channel.channel_types.map(type => {
			type.symbolValue = symbolValueForView(type);
			type.units = units.filter(unit => unit.symbol === type.symbol);
			return type;
		});
	});

	return channelData;
};

export const getUsedChannelDataFromModule = (moduleChannelsFree, moduleChannelsUsed, moduleChannelTypes, moduleUnits) => {
	const freeChannels = moduleChannelsFree ? moduleChannelsFree.map(used => used.channel_number) : null;
	const usedChannels = JSON.parse(JSON.stringify(moduleChannelsUsed));
	const channelTypes = JSON.parse(JSON.stringify(moduleChannelTypes));
	const units = JSON.parse(JSON.stringify(moduleUnits));

	const channelData = [];

	if (usedChannels) {
		usedChannels.forEach(channel => {
			if (freeChannels.indexOf(channel.channel_number) !== -1) {
				return;
			}

			// what unit is it?
			let unit = undefined;
			units.some(unit => unit.id === channel.units_id)
				? (unit = units.find(unit => unit.id === channel.units_id))
				: (unit = units.find(unit => unit.id === 2)); //In case of not having required unit in the units array, default unit will be set as 2. 2 means °C.

			// find channel type
			const type = channelTypes.find(
				type => type.symbol === unit.symbol && type.value_index === channel.value_index && type.symbol === unit.symbol
			);

			// get all units
			const channelUnits = units.filter(unit => unit.symbol === type.symbol);

			const currentChannel = {
				channel_number: channel.channel_number,
				channel_types: [
					{
						channel_type: type.channel_type,
						symbol: type.symbol,
						symbolValue: symbolValueForView(type),
						units: channelUnits,
						value_index: channel.value_index,
						units_id: channel.units_id,
						sensors_id: channel.sensors_id,
					},
				],
			};

			channelData.push(currentChannel);
		});
	}

	return channelData;
};

export const isFirstSensorForChannelUsed = module => {
	return (module.used_channels && module.used_channels.length) === module.channels.length;
};

export const cleanUpSensorBasicFormData = sensorBasicForm => {
	return (({isConfiguration, defaultSettingUnit, moduleData, currentChannels, currentModuleChannels, selectedChannel, ...s}) => s)(
		sensorBasicForm
	);
};

export function SortChannelTypes(channelTypes: ChannelType[]) {
	for (let i = 0; i < channelTypes.length; i++) {
		if (channelTypes[i].symbolValue.includes('humidity')) {
			let tmp = channelTypes[i];
			channelTypes[i] = channelTypes[channelTypes.length - 1];
			channelTypes[channelTypes.length - 1] = tmp;
		} else if (channelTypes[i].symbolValue.includes('temperature') && !channelTypes[i].symbolValue.includes('external')) {
			let tmp = channelTypes[i];
			channelTypes[i] = channelTypes[0];
			channelTypes[0] = tmp;
		}
	}

	return channelTypes;
}
