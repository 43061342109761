import React, {Component} from 'react';
import RegisterService from './RegisterService';
import {Alert, Input} from 'reactstrap';
import FormValidator from '../Forms/FormValidator';
import {Trans} from 'react-i18next';
import {Alert as AntAlert} from 'antd';
import RequestLogger from '../Infrastructure/Requests/Logger/RequestLogger';

class Resend extends Component {
	constructor(props) {
		super(props);
		this.state = {
			success: false,
			errorMessage: undefined,
			formResend: {
				email: '',
			},
		};
	}

	validateOnChange = event => {
		const input = event.target;
		const form = input.form;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		const result = FormValidator.validate(input);

		this.setState({
			[form.name]: {
				...this.state[form.name],
				[input.name]: value,
				errors: {
					...this.state[form.name].errors,
					[input.name]: result,
				},
			},
		});
	};

	resend = e => {
		e.preventDefault();

		this.setState({errorMessage: undefined, success: false});

		const payload = {
			email: this.state.formResend.email,
		};

		RegisterService.resend(payload, RequestLogger.createLogData('resend-password', 'resend-password', 'onClick'))
			.then(response => {
				this.setState({errorMessage: undefined, success: true});
			})
			.catch(error => {
				this.setState({errorMessage: error.data.message, success: false});
			});
	};

	render() {
		return (
			<div className="block-center mt-4 wd-xl">
				<div className="card card-flat">
					<div className="card-header text-center bg-white">
						<img width={200} className="block-center rounded" src={process.env.PUBLIC_URL + '/img/logo.png'} alt="elproCLOUD" />
					</div>
					<div className="card-body">
						<form name="formResend">
							<p>Please type your e-mail and click resend</p>
							<div className="form-group">
								<div className="input-group with-focus">
									<Input
										type="email"
										name="email"
										className="border-right-0"
										placeholder="Email"
										data-validate='["required", "email"]'
										onChange={this.validateOnChange}
										value={this.state.formResend.email}
									/>
									<div className="input-group-append">
										<span className="input-group-text text-muted bg-transparent border-left-0">
											<em className="fa fa-envelope" />
										</span>
									</div>
								</div>
							</div>
						</form>
						<button className="btn btn-block btn-primary mt-3" onClick={this.resend}>
							Resend
						</button>
						<div>
							<p />
							{this.state.errorMessage ? (
								<Alert color="danger text-center">
									<Trans i18nKey={'register.error.' + this.state.errorMessage} />
								</Alert>
							) : null}

							{this.state.success ? (
								<div className="alert-msg">
									<AntAlert description={<Trans i18nKey={'register.resend.success'} />} type="success" />
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Resend;
