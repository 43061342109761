export enum EventDetailType {
	PropertyChange = 3,
	ErrorDetails = 4,
	EntryReason = 5,
	ModuleId = 7,
	ModuleType = 8,
	SensorName = 9,
	FullName = 10,
	RadioConnectionValue = 23,
	MessageText = 32,
	SensorLoggingInterval = 41,
	SensorId = 68,
	BreachType = 111,
	BreachExpectedTimestampUnixUtc = 112,
	LimitZoneType = 121,
}
