export enum LiberoGxType {
	GS = 'GS',
	GF = 'GF',
	GL = 'GL',
	GE = 'GE',
	GH = 'GH',
	GFExt = 'GF ext',
}

export enum EcologProRadioType {
	OneNTR = '1NTR',
	TwoPTR = '2PTR',
	OneTHR = '1THR',
}

export enum EcologProXGType {
	OneTGi = '1TGi',
	OneTHGi = '1THGi',
	OneTGe = '1TGe',
}

export const DeviceType = {...EcologProXGType, ...EcologProRadioType, ...LiberoGxType};
export type DeviceType = LiberoGxType | EcologProXGType | EcologProRadioType;
