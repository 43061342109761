import {Global, OrganizationsConfig, SensorLimit, UserManagement} from './DbModel/OrganizationsConfig';

export class OrganisationSettings {
	public Global: OrganisationSettingGlobal;
	public SensorLimit: OrganisationSettingSensorLimit;
	public UserManagement: OrganisationSettingUserManagement;

	constructor(init: OrganizationsConfig) {
		this.SensorLimit = new OrganisationSettingSensorLimit(init['sensor-limit']);

		this.UserManagement = new OrganisationSettingUserManagement(init['user-management']);

		this.Global = new OrganisationSettingGlobal(init.global);
	}
}

export class OrganisationSettingGlobal {
	public AllowEcologSmsMulti: boolean;
	public AllowEcologSmsSingle: boolean;
	public AllowLiberogSmsMulti: boolean;
	public AllowLiberogSmsSingle: boolean;

	constructor(init: Global) {
		this.AllowEcologSmsMulti = init['allow-ecolog-sms-multi'];
		this.AllowEcologSmsSingle = init['allow-ecolog-sms-single'];
		this.AllowLiberogSmsMulti = init['allow-liberog-sms-multi'];
		this.AllowLiberogSmsSingle = init['allow-liberog-sms-single'];
	}
}

export class OrganisationSettingSensorLimit {
	public WarningZones: number;
	public AlarmingZones: number;

	constructor(init: SensorLimit) {
		this.WarningZones = init['warning-zones'];
		this.AlarmingZones = init['alarming-zones'];
	}
}

export class OrganisationSettingUserManagement {
	public Basic: number;
	public Operator: number;

	constructor(init: UserManagement) {
		this.Basic = init.basic;
		this.Operator = init.operator;
	}
}
