import React, {Component} from 'react';
import RegisterService from './RegisterService';
import {Link} from 'react-router-dom';
import {Alert, Input, Row, Col} from 'reactstrap';
import FormValidator from '../Forms/FormValidator';
import SystemSettings from '../Infrastructure/Settings/SystemSettings';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CreditCardElement from '../Common/CreditCardElement';
import Shared from '../Shared/Shared';
import {withTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import OrganizationsService from '../Settings/Organization/OrganizationsService';
import {RequestLogger} from '../Infrastructure/Requests/Logger';

const customError = {
	fontSize: '80%',
	color: '#f61214',
	width: '100%',
	marginTop: '0.25rem',
	lineHeight: '10px',
};

const Required = ({hasError}) => <span style={hasError ? {color: '#f61214'} : null}>&nbsp;*</span>;

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			stripe: null,
			errorMessage: undefined,
			formRegister: {
				configuration: '',
				organization: '',
				country: '',
				country_state: '',
				address: '',
				zip_code: '',
				city: '',
				vat: '',
				reseller: '',
				first_name: '',
				last_name: '',
				email: '',
				mobile: '',
				password: '',
				password_confirm: '',
				terms_of_use: false,
			},
			countries: [],
			pwErrorMessage: '',
			passwordStrength: '',
			selectError: false,
			showGlobalErrorMessage: false,
			resellers: [],
		};
	}

	componentDidMount() {
		const gets = [
			SystemSettings.getCountries(),
			SystemSettings.getCountryStates(),
			OrganizationsService.reseller(),
			RegisterService.getOrganizationSettingTemplateDefault('default'),
		];

		Promise.all(gets).then(responses => {
			this.setState({
				stripe: window.Stripe(SystemSettings.getConfig().stripe_pk),
				countries: responses[0].data,
				countryStates: responses[1].data,
				resellers: responses[2].data,
				configuration: responses[3].data.config,
			});
		});
	}

	getCounties() {
		SystemSettings.getCountries()
			.then(response => {
				this.setState({countries: response.data});
			})
			.catch(error => console.error('error ', error));
	}

	handleCountryChange = event => {
		const input = event.target;
		const value = input.value;
		this.setState(prevState => ({
			selectError: value === '',
			formRegister: {
				...prevState.formRegister,
				country: value,
				country_state: value === 'US' ? this.state.countryStates[0].display_name : '',
			},
		}));
	};

	handleResellerChange = event => {
		const value = event.target.value;
		this.setState(prevState => ({
			selectError: value === '',
			formRegister: {
				...prevState.formRegister,
				reseller: value,
			},
		}));
	};

	handleStateChange = event => {
		const input = event.target;
		const value = input.value;

		this.setState(prevState => ({
			formRegister: {
				...prevState.formRegister,
				country_state: value,
			},
		}));
	};

	validateOnChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		const result = FormValidator.validate(input);

		this.setState({
			formRegister: {
				...this.state.formRegister,
				[input.name]: value,
				errors: {
					...this.state.formRegister.errors,
					[input.name]: result,
				},
			},
		});
	};

	onSubmit = e => {
		let that = this;
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'TEXTAREA', 'SELECT'].includes(i.nodeName));

		// validate mobile number and show error only if value is set
		if (inputs[9].value === '') {
			inputs.splice(9, 1);
		}

		let {errors, hasError} = FormValidator.bulkValidate(inputs);

		this.setState({
			[form.name]: {
				...this.state[form.name],
				errors,
			},
			selectError: errors['country'].required,
			showGlobalErrorMessage: true,
		});

		if (!hasError) {
			let isStripeModelEmpty = this.creditCardElement.isStripeModelEmpty();
			const data = that.state.formRegister;

			if (data.country === undefined) {
				this.setState({errorMessage: 'Please select a country'});
				e.preventDefault();
				return;
			} else if (data.country === 'US' && data.country_state === '') {
				this.setState({errorMessage: 'Please select a state'});
				e.preventDefault();
				return;
			} else if (data.country !== 'US') {
				data.country_state = '';
			}

			if (!isStripeModelEmpty) {
				this.creditCardElement
					.createToken()
					.then(function (response) {
						if (response.error) {
							that.setState({errorMessage: response.error.message});
						} else {
							that.setState({errorMessage: undefined});
							that.callRegister(data, response.token);
						}
					})
					.catch(function (error) {
						console.log('error ', error);
						that.setState({errorMessage: error.message});
					});
			} else {
				that.callRegister(data, {
					card: {last4: '', name: '', exp_month: '', exp_year: ''},
					id: '',
					client_ip: '',
				});
			}
		}

		e.preventDefault();
	};

	callRegister(data, stripe_token) {
		let that = this;
		that.setState({showGlobalErrorMessage: false});

		const payload = {
			organization: data.organization,
			country: data.country,
			country_state: data.country_state,
			address: data.address,
			zip_code: data.zip_code,
			city: data.city,
			vat: data.vat,
			reseller: data.reseller,

			email: data.email,
			first_name: data.first_name,
			last_name: data.last_name,
			mobile: data.mobile,
			pass: data.password,

			credit_card_number: stripe_token.card.last4,
			name_on_card: stripe_token.card.name,
			valid_until: stripe_token.card.exp_month + '/' + stripe_token.card.exp_year,
			payment_provider_registration_id: stripe_token.id,
			client_ip: stripe_token.client_ip,
		};

		RegisterService.register(payload, RequestLogger.createLogData('register', 'register-account', 'onClick'))
			.then(function (response) {
				that.setState({page: 2});
			})
			.catch(function (error) {
				let errorMessage = error.data.message;
				const organisationNotUique = error.data.message.indexOf('organizations_name_key');
				const mailNotUique = error.data.message.indexOf('users_email_key');
				if (mailNotUique !== -1) {
					errorMessage = that.props.t('register.error.account_error_101');
				}

				if (organisationNotUique !== -1) {
					errorMessage = that.props.t('register.error.account_error_102');
				}

				that.setState({errorMessage: errorMessage});
			});
	}

	hasError = (formName, inputName, method) => {
		return (
			this.state[formName] &&
			this.state[formName].errors &&
			this.state[formName].errors[inputName] &&
			this.state[formName].errors[inputName][method]
		);
	};

	handlePWChange = e => {
		this.setState({pwErrorMessage: Shared.validatePassword(e.target.value)});
		this.setState({passwordStrength: Shared.passwordMeasureStrength(e.target.value)});
		this.validateOnChange(e);
	};

	render() {
		const {page} = this.state;
		return (
			<div>
				<div className="block-center mt-4 wd-xxl">
					<div className="card card-flat">
						<div className="card-header text-center bg-white">
							<img width={200} src={process.env.PUBLIC_URL + '/img/logo.png'} alt="elproCLOUD" />
						</div>
						<div className="card-body">
							<p className="text-center py-2">Register your elproCLOUD account</p>

							{page === 1 ? (
								<div>
									<form className="mb-3" id="formRegister" name="formRegister" onSubmit={this.onSubmit} noValidate>
										<div className="form-group">
											<label htmlFor="signupInputOrganization">Organization</label>
										</div>
										<Row>
											<Col xl={12}>
												<div className="form-group">
													<div className="input-group with-focus">
														<Input
															type="text"
															id="inputOrganization"
															name="organization"
															className="border-right-0"
															placeholder="Name of your organization/company"
															invalid={
																this.hasError('formRegister', 'organization', 'required') ||
																this.hasError('formRegister', 'organization', 'maxchar')
															}
															onChange={this.validateOnChange}
															data-validate='["required","maxchar"]'
															data-param="100"
															maxLength="101"
															value={this.state.formRegister.organization}
														/>
														<div className="input-group-append">
															<span className="input-group-text text-muted bg-transparent border-left-0">
																<em className="elpro-Company" />
															</span>
														</div>
														<Required
															hasError={
																this.hasError('formRegister', 'organization', 'required') ||
																this.hasError('formRegister', 'organization', 'maxchar')
															}
														/>
														{this.hasError('formRegister', 'organization', 'required') && (
															<span id="errorOrganizationNameRequired" className="invalid-feedback">
																Field is required
															</span>
														)}
														{this.hasError('formRegister', 'organization', 'maxchar') && (
															<span id="errorOrganizationNameMax" className="invalid-feedback">
																Field must be be less than 100 characters
															</span>
														)}
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col xl={12}>
												<div className="form-group">
													<div className="input-group with-focus">
														<Input
															type="textarea"
															id="inputAddress"
															name="address"
															rows="2"
															placeholder="Address"
															invalid={
																this.hasError('formRegister', 'address', 'required') ||
																this.hasError('formRegister', 'address', 'maxchar')
															}
															onChange={this.validateOnChange}
															data-validate='["required","maxchar"]'
															data-param="500"
															maxLength="501"
															value={this.state.formRegister.address}
														/>
														<Required
															hasError={
																this.hasError('formRegister', 'address', 'required') ||
																this.hasError('formRegister', 'address', 'maxchar')
															}
														/>
														{this.hasError('formRegister', 'address', 'required') && (
															<span id="errorAddressRequired" className="invalid-feedback">
																Field is required
															</span>
														)}
														{this.hasError('formRegister', 'address', 'maxchar') && (
															<span id="errorOrganizationNameMax" className="invalid-feedback">
																Field must be be less than 500 characters
															</span>
														)}
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col xl="12">
												<div className="form-group">
													<div className="input-group with-focus">
														<Input
															type="text"
															id="inputZipCode"
															name="zip_code"
															className="border-right-0"
															placeholder="Zip code"
															invalid={this.hasError('formRegister', 'zip_code', 'required')}
															onChange={this.validateOnChange}
															data-validate='["required"]'
															value={this.state.formRegister.zip_code}
														/>

														<div className="input-group-append">
															<span className="input-group-text text-muted bg-transparent border-left-0">
																<em className="elpro-ZipCode" />
															</span>
														</div>
														<Required hasError={this.hasError('formRegister', 'zip_code', 'required')} />
														{this.hasError('formRegister', 'zip_code', 'required') && (
															<span id="errorOrganizationZipRequired" className="invalid-feedback">
																Field is required
															</span>
														)}
													</div>
												</div>
											</Col>
											<Col xl="12">
												<div className="form-group">
													<div className="input-group with-focus">
														<Input
															type="text"
															id="inputCity"
															name="city"
															className="border-right-0"
															placeholder="City"
															invalid={this.hasError('formRegister', 'city', 'required')}
															onChange={this.validateOnChange}
															data-validate='["required"]'
															value={this.state.formRegister.city}
														/>
														<div className="input-group-append">
															<span className="input-group-text text-muted bg-transparent border-left-0">
																<em className="elpro-City" />
															</span>
														</div>
														<Required hasError={this.hasError('formRegister', 'city', 'required')} />
														{this.hasError('formRegister', 'city', 'required') && (
															<span id="errorOrganizationCityRequired" className="invalid-feedback">
																Field is required
															</span>
														)}
													</div>
												</div>
											</Col>
											<Col xl="12">
												<div className="form-group">
													<div className="input-group">
														<select
															className={'form-control ' + (this.state.selectError ? 'is-invalid' : '')}
															id="selectCountry"
															name="country"
															placeholder="Country"
															onChange={this.handleCountryChange}
															data-validate='["required"]'
															value={this.state.formRegister.country || ''}
														>
															<option value="">Country</option>
															{this.state.countries.map(country => (
																<option key={country.id} value={country.id}>
																	{country.display_name}
																</option>
															))}
														</select>
														<Required hasError={this.state.selectError} />
													</div>
													{this.state.selectError && <span style={customError}>Field is required</span>}
												</div>
											</Col>
											{this.state.formRegister.country === 'US' ? (
												<Col xl="12">
													<div className="form-group">
														<div className="input-group">
															<select
																className="form-control"
																id="selectState"
																name="country_state"
																onChange={this.handleStateChange}
																data-validate='["required"]'
																value={this.state.formRegister.country_state}
															>
																{this.state.countryStates.map(state => (
																	<option key={state.id} value={state.id}>
																		{state.display_name}
																	</option>
																))}
															</select>
														</div>
													</div>
												</Col>
											) : null}
										</Row>
										<Row>
											<Col>
												<div className="form-group">
													<div className="input-group with-focus">
														<Input
															type="text"
															id="inputVat"
															name="vat"
															placeholder="VAT / UID"
															invalid={this.hasError('formRegister', 'vat', 'required')}
															onChange={this.validateOnChange}
															data-validate='["required"]'
															value={this.state.formRegister.vat}
														/>
														<Required hasError={this.hasError('formRegister', 'vat', 'required')} />
														{this.hasError('formRegister', 'vat', 'required') && (
															<span id="errorOrganizationVatRequired" className="invalid-feedback">
																Field is required
															</span>
														)}
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col xl="12">
												<div className="form-group">
													<div className="input-group">
														<select
															className={'form-control ' + (this.state.selectError ? 'is-invalid' : '')}
															id="selectReseller"
															name="reseller"
															onChange={this.handleResellerChange}
															data-validate='["required"]'
															value={this.state.formRegister.reseller || ''}
														>
															<option value="">Please select your reseller</option>
															{this.state.resellers.map(reseller => (
																<option key={reseller.id} value={reseller.id}>
																	{reseller.reseller_name}, {reseller.display_name}
																</option>
															))}
														</select>
														<Required hasError={this.state.selectError} />
													</div>
													{this.state.selectError && <span style={customError}>Field is required</span>}
												</div>
											</Col>
										</Row>
										<br />
										<div className="form-group">
											<label htmlFor="signupInputAdminName">Administrator</label>
										</div>
										<Row>
											<Col xl="12">
												<div className="form-group">
													<div className="input-group with-focus">
														<Input
															type="text"
															id="inputFirstName"
															name="first_name"
															className="border-right-0"
															placeholder="First name"
															invalid={this.hasError('formRegister', 'first_name', 'required')}
															onChange={this.validateOnChange}
															data-validate='["required"]'
															value={this.state.formRegister.first_name}
														/>
														<div className="input-group-append">
															<span className="input-group-text text-muted bg-transparent border-left-0">
																<em className="fa fa-user" />
															</span>
														</div>
														<Required hasError={this.hasError('formRegister', 'first_name', 'required')} />
														{this.hasError('formRegister', 'first_name', 'required') && (
															<span id="errorFirstNameRequired" className="invalid-feedback">
																Field is required
															</span>
														)}
													</div>
												</div>
											</Col>

											<Col xl="12">
												<div className="form-group">
													<div className="input-group with-focus">
														<Input
															type="text"
															id="inputLastName"
															name="last_name"
															className="border-right-0"
															placeholder="Last name"
															invalid={this.hasError('formRegister', 'last_name', 'required')}
															onChange={this.validateOnChange}
															data-validate='["required"]'
															value={this.state.formRegister.last_name}
														/>
														<div className="input-group-append">
															<span className="input-group-text text-muted bg-transparent border-left-0">
																<em className="fa fa-user" />
															</span>
														</div>
														<Required hasError={this.hasError('formRegister', 'last_name', 'required')} />
														{this.hasError('formRegister', 'last_name', 'required') && (
															<span id="errorLastNameRequired" className="invalid-feedback">
																Field is required
															</span>
														)}
													</div>
												</div>
											</Col>
										</Row>
										<div className="form-group">
											<div className="input-group with-focus">
												<Input
													type="email"
													id="inputEmail"
													name="email"
													className="border-right-0"
													placeholder="Email"
													invalid={
														this.hasError('formRegister', 'email', 'required') ||
														this.hasError('formRegister', 'email', 'email')
													}
													data-validate='["required", "email"]'
													onChange={this.validateOnChange}
													value={this.state.formRegister.email}
												/>
												<div className="input-group-append">
													<span className="input-group-text text-muted bg-transparent border-left-0">
														<em className="elpro-Username" />
													</span>
												</div>
												<Required
													hasError={
														this.hasError('formRegister', 'email', 'required') ||
														this.hasError('formRegister', 'email', 'email')
													}
												/>
												{this.hasError('formRegister', 'email', 'required') && (
													<span id="errorEmailRequired" className="invalid-feedback">
														Field is required
													</span>
												)}
												{this.hasError('formRegister', 'email', 'email') && (
													<span id="errorEmailNotValid" className="invalid-feedback">
														Field must be valid email
													</span>
												)}
											</div>
										</div>
										<div className="form-group">
											<div className="input-group with-focus">
												<Input
													type="text"
													id="inputMobile"
													name="mobile"
													className="border-right-0"
													placeholder="Mobile phone number (for SMS alarms)"
													invalid={
														this.hasError('formRegister', 'mobile', 'required') ||
														this.hasError('formRegister', 'mobile', 'mobilePhone')
													}
													data-validate='["mobilePhone"]'
													onChange={this.validateOnChange}
													value={this.state.formRegister.mobile}
												/>
												<div className="input-group-append">
													<span className="input-group-text text-muted bg-transparent border-left-0">
														<em className="elpro-MobilePhone" />
													</span>
												</div>
												<Required
													hasError={
														this.hasError('formRegister', 'mobile', 'required') ||
														this.hasError('formRegister', 'mobile', 'mobilePhone')
													}
												/>
												{this.hasError('formRegister', 'mobile', 'mobilePhone') && (
													<span id="errorMobilePhoneNotValid" className="invalid-feedback">
														Field must be valid mobile phone (e.g. +41 79 552 08 08)
													</span>
												)}
											</div>
										</div>
										<div className="form-group">
											<div className="input-group with-focus">
												<Input
													type="password"
													autoComplete="off"
													id="inputPassword"
													name="password"
													className="border-right-0"
													placeholder="Password"
													invalid={this.hasError('formRegister', 'password', 'required')}
													data-validate='["required"]'
													onChange={this.handlePWChange}
													value={this.state.formRegister.password}
												/>
												<div className="input-group-append">
													<span className="input-group-text text-muted bg-transparent border-left-0">
														<em className="elpro-Password" />
													</span>
												</div>
												<Required hasError={this.hasError('formRegister', 'password', 'required')} />
												{this.hasError('formRegister', 'password', 'required') && (
													<span id="errorPasswordRequired" className="invalid-feedback">
														Field is required
													</span>
												)}
												{this.state.pwErrorMessage && (
													<span style={customError}>
														{' '}
														<br />
														{this.state.pwErrorMessage}
													</span>
												)}
											</div>
										</div>
										<div className="form-group">
											<div className="input-group with-focus">
												<Input
													type="password"
													id="inputPasswordConfirm"
													name="password_confirm"
													className="border-right-0"
													placeholder="Retype password"
													invalid={this.hasError('formRegister', 'password_confirm', 'equalto')}
													data-param="inputPassword"
													data-validate='["equalto"]'
													onChange={this.validateOnChange}
													value={this.state.formRegister.password_confirm}
												/>
												<div className="input-group-append">
													<span className="input-group-text text-muted bg-transparent border-left-0">
														<em className="elpro-Password" />
													</span>
												</div>
												{this.hasError('formRegister', 'password_confirm', 'required') && (
													<span id="errorPasswordConfirmRequired" className="invalid-feedback">
														Field is required
													</span>
												)}
												{this.hasError('formRegister', 'password_confirm', 'equalto') && (
													<span id="errorPasswordsNotEqual" className="invalid-feedback">
														Passwords must be equal
													</span>
												)}
											</div>
										</div>
									</form>
									<div>
										<br />
										<div className="form-group">
											<label htmlFor="signupInputCreditCard">Credit card</label>
										</div>

										<StripeProvider stripe={this.state.stripe}>
											<Elements ref="stripe_elements">
												<CreditCardElement formType="registration" onRef={ref => (this.creditCardElement = ref)} />
											</Elements>
										</StripeProvider>

										<label htmlFor="paymentInfoLine1">
											At this point, no payment will be charged. All transactions are secured and encrypted.
										</label>
										<br />
										<br />
										<div className="clearfix">
											<div className="checkbox c-checkbox float-left mt-0">
												<label>
													<Input
														type="checkbox"
														onChange={this.validateOnChange}
														value={this.state.formRegister.terms_of_use}
														name="terms_of_use"
													/>
													<span className="fa fa-check" />I have read and agree with the{' '}
													<a
														href={SystemSettings.getConfig().privacy_policy}
														target="_blank"
														rel="noopener noreferrer"
													>
														privacy policy
													</a>{' '}
													and{' '}
													<a
														href={SystemSettings.getConfig().terms_of_use}
														target="_blank"
														rel="noopener noreferrer"
													>
														terms of use
													</a>
													.
												</label>
											</div>
										</div>

										<button
											id="btnRegister"
											type="submit"
											form="formRegister"
											className="btn btn-block btn-primary mt-3"
											disabled={!this.state.formRegister.terms_of_use}
										>
											Register
										</button>

										<div>
											<p />
											{this.state.showGlobalErrorMessage && (
												<Alert color="danger text-center">
													<Trans i18nKey={'register.error.stillSomeErrors'} />
												</Alert>
											)}
											{this.state.errorMessage ? (
												<Alert color="danger text-center">{this.state.errorMessage}</Alert>
											) : null}
										</div>
									</div>
									<div className="clearfix">
										<br />
										<div className="float-right">
											Already have an account?{' '}
											<Link to="login" className="text-muted">
												Login
											</Link>
										</div>
									</div>
									<div className="clearfix">
										<div className="float-right">
											Didn't get an e-mail?{' '}
											<Link to="resend" className="text-muted">
												Resend
											</Link>
										</div>
									</div>
								</div>
							) : null}

							{page === 2 ? (
								<div className="form-group">
									<p>Almost there</p>
									<p>
										Thank you for registering. Please check your inbox. We have sent an email with a link to confirm
										your registration.{' '}
									</p>

									<div className="clearfix">
										<div className="float-right">
											Didn't get an email?{' '}
											<Link to="resend" className="text-muted">
												Resend
											</Link>
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(Register);
