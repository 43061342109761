import React from 'react';
import {Button, ButtonProps} from 'antd';

interface AntButtonProps extends ButtonProps {
	/** @deprecated use danger instead */
	isDanger?: boolean;
	fontSize?: string;
}

const AntButton = ({
	type = 'default',
	id,
	onClick,
	disabled = false,
	style = {},
	isDanger = false,
	danger = false,
	fontSize = '13px',
	loading = false,
	...props
}: AntButtonProps) => {
	return (
		<Button id={id} type={type} onClick={onClick} disabled={disabled} style={style} danger={danger || isDanger} loading={loading}>
			<span style={{fontSize: fontSize}}>{props.children}</span>
		</Button>
	);
};

export default AntButton;
