import React, {Component} from 'react';
import {COLUMNS} from '../Constants/Columns';
import {LockFilled, UnlockOutlined} from '@ant-design/icons';

export type LockRendererProps = {
	data: any;
};

export class LockRendererState {
	locked: boolean;
}

// Component showing Lock symbol
export class LockRenderer extends Component<LockRendererProps, LockRendererState> {
	constructor(props: LockRendererProps | Readonly<LockRendererProps>) {
		super(props);
		this.state = {
			locked: props.data.Locked,
		};
	}

	render() {
		return this.state.locked ? 
		(
			<LockFilled style={{fontSize: '18px'}} />
		) : (
			<UnlockOutlined style={{fontSize: '18px'}} />
		);		
	}
}

// Locked or Unlocked comp is decided here
export const renderLockSelector = params => {
	const lock = {
		component: LockRenderer,
	};
	if (params.colDef && params.data) {
		if (params.colDef.field === COLUMNS.LOCKED) {
			return lock;
		}
	}
	return undefined;
};
