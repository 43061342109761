import {Static} from 'highcharts';

export function highchartCustomPlotbands(H: Static): Static {
	H.wrap(H.PlotLineOrBand.prototype, 'render', function (proceed: {apply: (arg0: any, arg1: any) => Static}) {
		const ret = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
		const {start, end} = ret.options;

		if (!start && !end) {
			return ret;
		}

		if (typeof ret.svgElem.attr('d') === 'string' || ret.svgElem.attr('d') instanceof String) {
			const path = ret.svgElem.attr('d').split(' ');
			const [x1Index, x2Index] = [1, 4];
			const [x3Index, x4Index] = [6, 8];

			if (start) {
				path[x1Index] = ret.axis.chart.xAxis[0].toPixels(start);
				path[x4Index] = ret.axis.chart.xAxis[0].toPixels(start);
			}
			if (end) {
				path[x2Index] = ret.axis.chart.xAxis[0].toPixels(end);
				path[x3Index] = ret.axis.chart.xAxis[0].toPixels(end);
			}

			ret.svgElem.attr({
				d: path.join(' '),
			});
		}

		return ret;
	});
}
