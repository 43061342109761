import React from 'react';
import {Table} from 'antd';
import {EventDetail, EventDetailType} from '../../common/types';
import {useTranslation} from 'react-i18next';
import {ColumnsType} from 'antd/es/table';
import {GetAuditTrailDetailTranslationKey} from '../../common/helpers/GetAuditTrailDetailTranslationKey';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import dayjs from 'dayjs';

interface DataType {
	key: React.Key;
	eventDetail: EventDetail;
}

export function EventDetailList(props: {eventDetails: EventDetail[]}): React.JSX.Element {
	const [t] = useTranslation();
	const dataSource: DataType[] = props.eventDetails.map(d => ({
		key: d.Id,
		eventDetail: d,
	}));

	const columns: ColumnsType<DataType> = [
		{
			title: '',
			render: record => t(GetAuditTrailDetailTranslationKey(record.eventDetail.EventDetailTypeId)).toString(),
			width: '20em',
		},
		{
			title: '',
			render: record => renderValue(record.eventDetail),
		},
	];

	function renderValue(eventDetail: EventDetail): React.JSX.Element {
		if (typeof eventDetail.Value === 'object') {
			try {
				const valueAsJson = eventDetail.Value;

				if (Array.isArray(valueAsJson) && eventDetail.Value === 87) {
					return (
						<div>
							{valueAsJson.map((sensor, index) => (
								<span key={index}>
									{sensor.name} {sensor.expiry_date}
									<br />
								</span>
							))}
						</div>
					);
				} else {
					return (
						<div>
							<span>Old value: {valueAsJson['old_value']}</span>
							<br />
							<span>New value: {valueAsJson['new_value']}</span>
						</div>
					);
				}
			} catch {}
		}

		switch (eventDetail.EventDetailTypeId) {
			case EventDetailType.EntryReason:
				return t('deviations.reason.' + eventDetail.Value.toString());
			case EventDetailType.MessageText:
				return <>{`${eventDetail.Value}`}</>;
			case EventDetailType.SensorLoggingInterval:
				return <>{parseFloat(eventDetail.Value.toString()) / 60} min</>;
			case EventDetailType.BreachExpectedTimestampUnixUtc:
				return <>{DateTimeUtils.toUserString(dayjs.unix(parseInt(eventDetail.Value.toString())))}</>;
		}
		return <>{eventDetail.Value.toString()}</>;
	}

	return <Table locale={{emptyText: 'No data'}} pagination={false} showHeader={false} dataSource={dataSource} columns={columns} />;
}
