import {FeatureFlag} from '../../helpers';

export type CurrentPriceProps = {
	totalAmount: number;
	actualPrice: number;
	currency: string;
	interval: string;
	isSmsDowngradeFromLicenseModel?: boolean;
};

export type CurrentPriceResult = {
	totalAmount: number;
	currency: string;
	interval: string;
	isSmsDowngradeFromLicenseModel?: boolean;
};

export class CurrentPriceExtension {
	public static getCurrentPrice = (currentPriceProps: CurrentPriceProps, FeatureFlagsContext: any): CurrentPriceResult => {
		let currentPrice: CurrentPriceResult;

		if (FeatureFlagsContext && FeatureFlagsContext.includes(FeatureFlag.Licensing)) {
			currentPrice = {
				totalAmount: currentPriceProps?.totalAmount,
				currency: currentPriceProps?.currency?.toUpperCase(),
				interval: currentPriceProps?.interval?.toLocaleLowerCase(),
			};
		} else {
			currentPrice = {
				totalAmount: currentPriceProps.actualPrice,
				currency: 'CHF',
				interval: 'year',
				isSmsDowngradeFromLicenseModel: currentPriceProps.isSmsDowngradeFromLicenseModel,
			};
		}

		return currentPrice;
	};
}
