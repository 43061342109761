import {Country} from '../../types';
import request from '../../../components/Infrastructure/Requests/Request';
import {CountriesRow} from '../../types/DbModel/CountriesRow';

export class SystemSettingsService {
	public static async GetCountries(): Promise<Country[]> {
		const response = await request({
			url: '/countries?order=display_name',
			method: 'GET',
		});

		const data = response.data as CountriesRow[];
		return data.map(r => new Country(r));
	}
}
