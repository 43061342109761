import {CalibrationOptions} from './CalibrationOptions';

export class CurrentCalibrationState {
	public SensorId: number;
	public State: CalibrationState;
	public Options: CalibrationOptions;

	constructor(sensorId: number, state: string, options: CalibrationOptions) {
		this.SensorId = sensorId;
		this.Options = options;

		const value = parseInt(state);
		if (value < 2) {
			this.State = CalibrationState.Pending;
		} else if (value === 2) {
			this.State = CalibrationState.Calibrating;
		} else if (value === 3) {
			this.State = CalibrationState.Stopping;
		}
	}
}

export enum CalibrationState {
	Inactive = 0,
	Pending = 1,
	Calibrating = 2,
	Stopping = 3,
}
