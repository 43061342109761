import React, {Component} from 'react';
import SensorUtils from '../Dashboard/Sensor/SensorUtils';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import {Table} from 'reactstrap';
import {Trans, Translation} from 'react-i18next';
import Shared from '../Shared/Shared';
import {LimitStatisticTableFragment} from './Statistics/LimitStatisticTableFragment';

class SensorAnalysisStatistics extends Component {
	render() {
		const loggingIntervalInMinutes = this.props.sensor.logging_interval / 60;
		const details = this.props.limitAlarmDetails[0];

		return (
			<Table striped responsive className="col-md-12">
				<tbody>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.module_id'} />
						</td>
						<td width="35%">{this.props.sensor.serial_number}</td>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.customer_id'} />
						</td>
						<td width="35%">
							{this.props.sensor.customer_id ? this.props.sensor.customer_id : <Trans i18nKey={'global.n_a'} />}
						</td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensorWizard.summary.loggingInterval'} />
						</td>
						<td width="35%">
							{loggingIntervalInMinutes} <Trans i18nKey={'sensorWizard.summary.minutes'} />
						</td>
						<td width="15%">
							<Trans i18nKey={'sensorWizard.summary.limitAlarms'} />
						</td>
						<td width="35%">{this.props.limitAlarmCount}</td>
					</tr>

					<LimitStatisticTableFragment
						outUnitsId={this.props.statistics.outUnitsId}
						zone={{
							limit: details?.upper_limit_4,
							delay: details?.upper_limit_delay_4,
							name: 'H4',
						}}
						zoneStatistic={this.props.statistics.resultZones[0]}
					/>

					<LimitStatisticTableFragment
						outUnitsId={this.props.statistics.outUnitsId}
						zone={{
							limit: details?.upper_limit_3,
							delay: details?.upper_limit_delay_3,
							name: 'H3',
						}}
						zoneStatistic={this.props.statistics.resultZones[1]}
					/>

					<LimitStatisticTableFragment
						outUnitsId={this.props.statistics.outUnitsId}
						zone={{
							limit: details?.upper_limit_2,
							delay: details?.upper_limit_delay_2,
							name: 'H2',
						}}
						zoneStatistic={this.props.statistics.resultZones[2]}
					/>

					<LimitStatisticTableFragment
						outUnitsId={this.props.statistics.outUnitsId}
						zone={{
							limit: details?.upper_limit,
							delay: details?.upper_limit_delay,
							name: 'H1',
						}}
						zoneStatistic={this.props.statistics.resultZones[3]}
					/>

					<LimitStatisticTableFragment
						outUnitsId={this.props.statistics.outUnitsId}
						zone={{
							limit: details?.lower_limit,
							delay: details?.lower_limit_delay,
							name: 'L1',
						}}
						zoneStatistic={this.props.statistics.resultZones[4]}
					/>

					<LimitStatisticTableFragment
						outUnitsId={this.props.statistics.outUnitsId}
						zone={{
							limit: details?.lower_limit_2,
							delay: details?.lower_limit_delay_2,
							name: 'L2',
						}}
						zoneStatistic={this.props.statistics.resultZones[5]}
					/>

					<LimitStatisticTableFragment
						outUnitsId={this.props.statistics.outUnitsId}
						zone={{
							limit: details?.lower_limit_3,
							delay: details?.lower_limit_delay_3,
							name: 'L3',
						}}
						zoneStatistic={this.props.statistics.resultZones[6]}
					/>

					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.highest_value'} />
						</td>
						<td width="35%">
							{SensorUtils.convertTemperature(
								this.props.statistics.statisticResult.maxValue,
								this.props.statistics.outUnitsId
							) ? (
								<Translation>
									{(t, {i18n}) => (
										<>
											{SensorUtils.convertTemperature(
												this.props.statistics.statisticResult.maxValue,
												this.props.statistics.outUnitsId
											)}
											&nbsp;
											{Shared.getSensorUnit(this.props.statistics.outUnitsId)}&nbsp;
											{DateTimeUtils.setDateTimeWithOffset_date_dep(
												this.props.statistics.statisticResult.maxValueDateTime
											)}
										</>
									)}
								</Translation>
							) : (
								<Trans i18nKey={'global.n_a'} />
							)}
						</td>
						<td width="15%">
							<Trans i18nKey={'sensorWizard.steps.sensor_issues'} />
						</td>
						<td width="35%">{this.props.sensorIssueCount}</td>
					</tr>
					<tr>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.lowest_value'} />
						</td>
						<td width="35%">
							{SensorUtils.convertTemperature(
								this.props.statistics.statisticResult.minValue,
								this.props.statistics.outUnitsId
							) ? (
								<Translation>
									{(t, {i18n}) => (
										<>
											{SensorUtils.convertTemperature(
												this.props.statistics.statisticResult.minValue,
												this.props.statistics.outUnitsId
											)}
											&nbsp;
											{Shared.getSensorUnit(this.props.statistics.outUnitsId)}&nbsp;
											{DateTimeUtils.setDateTimeWithOffset_date_dep(
												this.props.statistics.statisticResult.minValueDateTime
											)}
										</>
									)}
								</Translation>
							) : (
								<Trans i18nKey={'global.n_a'} />
							)}
						</td>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.system_issues'} />
						</td>
						<td width="35%">0</td>
					</tr>
					<tr style={{borderBottom: '1px solid #eee'}}>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.average'} />
						</td>
						<td width="35%">
							{this.props.statistics.statisticResult.average !== 0 ? (
								<Translation>
									{(t, {i18n}) => (
										<>
											{SensorUtils.convertTemperature(
												this.props.statistics.statisticResult.average,
												this.props.statistics.outUnitsId
											)}
											&nbsp;
											{Shared.getSensorUnit(this.props.statistics.outUnitsId)}
										</>
									)}
								</Translation>
							) : (
								<Trans i18nKey={'global.n_a'} />
							)}
						</td>
						<td width="15%">
							<Trans i18nKey={'sensor.statistics.mkt'} />
						</td>
						<td width="35%">
							{this.props.statistics.mkt ? (
								<Translation>
									{(t, {i18n}) => (
										<>
											{SensorUtils.convertTemperature(this.props.statistics.mkt, this.props.statistics.outUnitsId)}{' '}
											{Shared.getSensorUnit(this.props.statistics.outUnitsId)}, {t('global.mkt_mol')}
										</>
									)}
								</Translation>
							) : (
								<Trans i18nKey={'global.n_a'} />
							)}
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default SensorAnalysisStatistics;
