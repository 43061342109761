
const validateStartLoggingDate = (config) => {
    if (config['start_logging_trigger'] === "time") {
        if (isNaN(config['start_logging_timestamp'])) {
            return 'Please enter a valid date';
        } else {
            const now = new Date();
            const startDate = new Date(config['start_logging_timestamp'] * 1000);

            return startDate - now <= 0 ?
            'Please set a future date': 
            false;
        }
        
    }
    
    return false;
};

const validateStartTransitDelay = (config, coreData) => {
    if (
        config['start_transit_trigger'] === 'time_delay' &&
        config['start_transit_time_delay'] !== undefined &&
        config['start_transit_time_delay'] !== null
    ) {
        const logging_interval = coreData.sensorBasicsForm.logging_interval;
        const time_delay = config['start_transit_time_delay'] * 60;

        if (time_delay < logging_interval) {
            return `Please set at least one logging interval unit '${logging_interval / 60}' as start value.`;
        } else if (time_delay % logging_interval !== 0) {
            return `Please set a multiple of one logging interval unit '${logging_interval / 60}' as a value.`;
        } else {
            return false;
        }
    }
    
    return false;
};


const validateMultiuse = (config) => {
    if (
        config['start_logging_trigger'] !== undefined &&
        config['start_logging_trigger'] !== null &&
        config['multiuse'] !== undefined &&
        config['multiuse'] !== null
    ) {
        if (
            config['multiuse'] === 'restart' &&
            (config['start_logging_trigger'] === 'immediately' || config['start_logging_trigger'] === 'time')
        ) {
            return "'Re-Start' cannot be set for start logging options 'immediately' and 'time based'";
        } else {
            return false;
        }
    }

    return false;
};

const bulkVaildate = (config, coreData) => {

    const startDateError = validateStartLoggingDate(config);
    const startDelayError = validateStartTransitDelay(config, coreData);
    const multiuseError = validateMultiuse(config);

    const fieldsErrors = {
        multiuse: multiuseError, 
        start_logging_timestamp : startDateError, 
        start_transit_time_delay: startDelayError
    };

    const formErrors = {
        multiuse: !!multiuseError, 
        start_logging_timestamp: !!startDateError,
        start_transit_time_delay: !!startDelayError
    };

    return [fieldsErrors, formErrors];
}

const LiberoGxValidator = {
    validateStartLoggingDate,
    validateStartTransitDelay,
    validateMultiuse,
    bulkVaildate
}

export default LiberoGxValidator;
