import dayjs from 'dayjs';
import {AlarmLimitBreachDetails} from './AlarmLimitBreachDetails';
import {SensorLimitsRoutineRow} from './DbModel';

export class AlarmLimitBreach {
	constructor(init: SensorLimitsRoutineRow) {
		this.OrgId = init.org_id;
		this.SensorLimitAlarmId = init.sensor_limit_alarm_id;
		this.SensorId = init.sensors_id;
		this.BreachEnd = dayjs(init.valid_until);
		this.BreachStart = dayjs(init.valid_from);
		this.Details = init.details.map(d => new AlarmLimitBreachDetails(d));
	}

	public OrgId: number;
	public SensorLimitAlarmId: number;
	public SensorId: number;
	public BreachStart: dayjs.Dayjs;
	public BreachEnd: dayjs.Dayjs;
	public Details: AlarmLimitBreachDetails[];
}
