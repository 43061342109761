import {ConfigurationOption, ConfigurationOptions, ProfileIndex} from '../../../../common/util/LicenseUtils/LineItemConstants';
import {SensorIssuesForm} from '../AddSensor/WizardState';
import {DeviceUtils} from '../../../../common/util';
import {LimitAlarmFormState} from '../Forms/LimitAlarmForm/LimitAlarmFormState';

export function SmsAddonRequired(
	serialNumber?: string,
	selectedChannel?: number,
	selectedValueIndex?: number,
	limitAlarmForms?: LimitAlarmFormState[],
	sensorIssuesForm?: SensorIssuesForm
): boolean {
	if (selectedChannel === null || selectedValueIndex === null || serialNumber === null) {
		return false;
	}

	let isSmsActive = false;

	let limitAlarmFormState: LimitAlarmFormState | undefined = limitAlarmForms?.[ProfileIndex.FirstIndex];

	const deviceType = DeviceUtils.GetDeviceType(serialNumber);

	const configurationOption = ConfigurationOptions[deviceType];

	if (configurationOption !== ConfigurationOption.Single) {
		limitAlarmFormState = limitAlarmForms?.find(laf => laf.alarmLimit && laf.recipients.sms.enabled);
	}

	if (
		(sensorIssuesForm?.sms_notification && sensorIssuesForm?.use_issue_alarms) ||
		(limitAlarmFormState && limitAlarmFormState?.alarmLimit && limitAlarmFormState?.recipients?.sms.enabled)
	) {
		isSmsActive = true;
	}

	return isSmsActive;
}
