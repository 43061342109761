import React from 'react';
import {Trans} from 'react-i18next';

type SensorBasicsSerialNumberProps = {
	show: boolean;
	hasError: any;
	serialNumber: string;
};

export default function SensorBasicsSerialNumberComponent(props: SensorBasicsSerialNumberProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.serialNumber'} />
				</td>
				{props.hasError('serial_number') ? (
					<td style={{color: 'red'}}>
						{props.serialNumber} <Trans i18nKey={'sensorWizard.summary.notUnique'} />
					</td>
				) : (
					<td>{props.serialNumber}</td>
				)}
			</tr>
		)
	);
}
