import {OutUnit} from '../constants/OutUnit';
import {GetUnitStringFromOutUnit} from './GetUnitStringFromOutUnit';

export function ToBaseUnit(value: number, outUnitId: number) {
	if (Number.isNaN(Number(value))) {
		return undefined;
	}
	let result: number = null;

	switch (outUnitId) {
		case OutUnit.Kelvin:
			result = value;
			break;
		case OutUnit.DegCelsius:
			result = value + 273.15;
			break;
		case OutUnit.DegFahrenheit:
			result = ((value + 459.67) * 5) / 9;
			break;
		case OutUnit.RelHumidity:
			result = value;
			break;
		default:
			return undefined;
	}

	if (result < 0) {
		return undefined;
	}
	return result;
}

export function ToOutUnit(value: number, outUnitId: OutUnit, precision: number = 1): number {
	if (Number.isNaN(Number(value)) || value < 0) {
		return undefined;
	}

	let result: number = null;
	switch (outUnitId) {
		case OutUnit.Kelvin:
			result = value;
			break;
		case OutUnit.DegCelsius:
			result = value - 273.15;
			break;
		case OutUnit.DegFahrenheit:
			result = (value * 9) / 5 - 459.67;
			break;
		case OutUnit.RelHumidity:
			result = value;
			break;
		default:
			return undefined;
	}

	return Math.round(result * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function FormatAsOutUnit(value: number, outUnitId: OutUnit): string {
	return `${ToOutUnit(value, outUnitId)} ${GetUnitStringFromOutUnit(outUnitId)}`;
}
