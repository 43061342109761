import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// load translation using xhr -> see /public/locales
	.use(Backend)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	.init({
		lng: 'en', // <-- This overwrites the language detector
		fallbackLng: 'en',
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json', // use absolute path
		},
		// have a common namespace used around the full app
		ns: ['translation'],
		defaultNS: 'translation',
		debug: false,
		react: {
			wait: true,
		},
	})
	.catch(e => console.error('Error setting up i18n', e))
	.then();

export default i18n;
