import {User} from '../types';
import request from '../../components/Infrastructure/Requests/Request';
import Authentication from '../../components/Infrastructure/Authentication/Authentication';

export class UserProfileService {
	public static async GetUser(): Promise<User> {
		try {
			const response = await request({
				url: '/user',
				method: 'GET',
				headers: {Accept: 'application/vnd.pgrst.object'},
				token: Authentication.getToken(),
			});

			return new User(response.data);
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}
}
