import request from '../../Infrastructure/Requests/Request';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
function issueProfiles(eventData) {
    return request({
        url: '/sensor_issue_alarm',
        method: 'GET',
        token: Authentication.getToken(),
        eventData,
    });
}

function getIssueProfile(id, eventData) {
    return request({
        url: '/sensor_issue_alarm?id=eq.' + id,
        method: 'GET',
        headers: {Accept: 'application/vnd.pgrst.object'},
        token: Authentication.getToken(),
        eventData,
    });
}

function saveProfile(payload, eventData) {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: null,
        SensorIssueAlarm: payload,
        PaymentInformation: null,
    };
    wizdata.SensorIssueAlarm.limit_issue_name = wizdata.SensorIssueAlarm.name;
    wizdata.SensorIssueAlarm.use_issue_alarms = true;
    wizdata.SensorIssueAlarm.issue_alarm_creation_type = 0;

    return request({
        url: '/registry/save',
        method: 'POST',
        token: Authentication.getToken(),
        data: wizdata,
        eventData,
    });
}

function updateProfile(payload, eventData) {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: null,
        SensorIssueAlarm: payload,
        PaymentInformation: null,
    };
    wizdata.SensorIssueAlarm.limit_issue_name = wizdata.SensorIssueAlarm.name;
    wizdata.SensorIssueAlarm.use_issue_alarms = true; //update
    wizdata.SensorIssueAlarm.issue_alarm_creation_type = wizdata.SensorIssueAlarm.id;
    wizdata.SensorIssueAlarm.editable = true;

    return request({
        url: '/registry/update',
        method: 'PUT',
        token: Authentication.getToken(),
        data: wizdata,
        eventData,
    });
}

function deleteProfile(limitProfileId, eventData) {
    return request({
        url: '/registry/sensorissueprofile?id=' + limitProfileId,
        method: 'DELETE',
        token: Authentication.getToken(),
        eventData,
    });
}

const IssueProfilesService = {
    issueProfiles,
    getIssueProfile,
    saveProfile,
    updateProfile,
    deleteProfile,
};

export default IssueProfilesService;
