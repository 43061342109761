import {MODULE_FAMILIES} from '../../components/Shared/Constants/Module';
import SerialNumberInfo from '../../components/Shared/SerialNumberInfo';

const FindMaxChannelCount = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	let deviceFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	switch (deviceFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			switch (serialNumber[0]) {
				case 'S':
					return 1;
				case 'Q':
				case 'R':
					return 2;
				default:
					return -1;
			}
		case MODULE_FAMILIES.LIBERO_G:
			switch (serialNumber[1]) {
				case '1':
				case '2':
				case '3':
					return 1;
				case '4':
				case '5':
				case '6':
					return 2;
				default:
					return -1;
			}

		case MODULE_FAMILIES.ECOLOG_PRO_G:
			switch (serialNumber[0]) {
				case 'W':
					return 1;
				case 'X':
				case 'Y':
					return 2;
				default:
					return -1;
			}
		default:
			return -1;
	}
};
export default FindMaxChannelCount;
