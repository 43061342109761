import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, List} from 'antd';

interface GridData {
	title: string;
	value: string;
}

const SensorMetaData = ({sensor}) => {
	const [t] = useTranslation();

	const getValueString = (value: unknown): string => (value ? `${value}` : t('global.n_a'));

	const getItemsFromData = (data?: object): GridData[] => {
		if (!data) {
			return [
				{title: t('sensorWizard.summary.sensorName'), value: getValueString(sensor.name)},
				{title: t('sensorWizard.summary.customersID'), value: getValueString(sensor.customer_id)},
				{title: t('sensorWizard.summary.memo'), value: getValueString(sensor.memo)},
			];
		} else {
			return Object.entries(data).map(
				([key, value]): GridData => ({
					title: key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' '),
					value: getValueString(value),
				})
			);
		}
	};

	return (
		<List
			grid={{gutter: 4, column: 4}}
			dataSource={getItemsFromData(sensor.metadata.metadatas)}
			renderItem={item => (
				<List.Item>
					<Card title={item.title} size={'small'}>
						{item.value}
					</Card>
				</List.Item>
			)}
		/>
	);
};

export default React.memo(SensorMetaData);
