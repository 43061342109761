import React from "react";

function ErrorDisplay({ error, params }) {
	switch (error) {
		case "required":
			return <span className="text-left invalid-feedback">Field is required</span>;
		case "email":
			return <span className="text-left invalid-feedback">Field must be an email</span>;
		case "number":
			return <span className="text-left invalid-feedback">Field must be a number</span>;
		case "integer":
			return <span className="text-left invalid-feedback">Field must be an integer</span>;
		case "ipAddress":
			return <span className="text-left invalid-feedback">Field must be an ip address</span>;
		case "serialNumber":
			return <span className="text-left invalid-feedback">Field must be a serial number</span>;
		case "mobilePhone":
			return <span className="text-left invalid-feedback">Field must be a mobile phone</span>;
		case "alphanum":
			return <span className="text-left invalid-feedback">Field must be a alpha numeric</span>;
		case "url":
			return <span className="text-left invalid-feedback">Field must be a URL</span>;
		case "equalto":
			return <span className="text-left invalid-feedback">Field must be equal to {params[0]}</span>;
		case "lessThan":
			return <span className="text-left invalid-feedback">Field must be less than {params[0]}</span>;
		case "minlen":
			return <span className="text-left invalid-feedback">Field must be more than {params[0]} chars</span>;
		case "maxlen":
			return <span className="text-left invalid-feedback">Field must be lessThan {params[0]} chars</span>;
		case "len":
			return <span className="text-left invalid-feedback">Field must have {params[0]} length</span>;
		case "min":
			return <span className="text-left invalid-feedback">Field must be more than {params[0]}</span>;
		case "max":
			return <span className="text-left invalid-feedback">Field must be lessThan {params[0]}</span>;
		case "minmax":
			return <span className="text-left invalid-feedback">Field must be between {params[0]} and {params[1]}</span>;
		case "list":
			return <span className="text-left invalid-feedback">Field must be a list</span>;
		case "maxchar":
			return <span className="text-left invalid-feedback">Field must have less than {params[0]} characters</span>;
		case "lcdCharts":
			return <span className="text-left invalid-feedback">Field contains invalid LCD characters</span>;
		default:
			return <span className="text-left invalid-feedback">{error}</span>;
	}
}

export default ErrorDisplay;