import {MODES} from '../../Constants/Modes';

export const configurationLimitProfileFormSettings = mode => {
    switch (mode) {
        case MODES.DISPLAY:
            return IssueProfilesDisplaySettings;
        case MODES.ADD:
            return LimitProfilesAddSettings;
        case MODES.EDIT:
            return LimitProfilesEditSettings;
        default:
            return null;
    }
};

const IssueProfilesDisplaySettings = {
    profileInputDisabled: true,
};
const LimitProfilesAddSettings = {
    profileInputDisabled: false,    
};

const LimitProfilesEditSettings = {
    profileInputDisabled: false,
};
