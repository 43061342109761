import React, {useEffect, useState} from 'react';
import {Col, Row, Spin} from 'antd';
import Close from '../../Shared/Components/Close';
import Message from '../../Shared/Components/Message';
import OfferingTable from '../../Dashboard/Sensor/Utils/OfferingTable';
import InputPaymentMethod from '../../Dashboard/Sensor/Utils/InputPaymentMethod';
import TermsOfUseAndOk from './TermsOfUseAndOk';
import {useNavigate} from 'react-router-dom-v5-compat';
import {ContentWrapper} from '../../Layout';
import {ViewHeader} from '../../Common';
import {LicenseOfferingTable} from '../../../components/Licensing';
import { GetLicenseOffering, PrepareLicenseOfferingFromLineItemTypes, SetUpSubscriptionUpdate } from '../../../components/Wizard/Util/LicensingUtils';
import { InternalProductCategories } from '../../../common/util/LicenseUtils/LineItemConstants';

export interface Offer {
	actual_price: number;
	lines: unknown[];
	vat_amount: unknown;
	voucher_rest: number;
}

export interface RetireSensor {
	moduleId: string;
	history: any;
	deviceViewSettings: any;
	serialNumber: string;
	channelNumber: number;
	valueIndex: number;
}

interface LicenseActionFormProps {
	actionMethod: (payload: unknown) => Promise<unknown>;
	cost: Offer;
	retireSensor?: RetireSensor;
	getOffering: (payload: unknown) => Promise<{data: Offer}>;
	licensesPaymentMethodSettings: unknown;
	objectActionVerb: string;
	saveAndHeadingText: string;
	sensorId?: number;
}

const LicenseActionForm = (props: LicenseActionFormProps) => {
	const [sensorId] = useState(props.sensorId);
	const [validVouchers, setValidVouchers] = useState([]);
	const [redeemPrepaid, setRedeemPrepaid] = useState(false);
	const [offer, setOffer] = useState(props.cost);
	const [inputPaymentMethod, setInputPaymentMethodCtrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [licenseOfferingResult, setLicenseOfferingResult] = useState(undefined);
	const [licenseOffering, setLicenseOffering]= useState(undefined);

	const navigate = useNavigate();

	const close = e => {
		redirect();
		e.preventDefault();
	};

	const redirect = () => navigate('/licenses');

	const performAction = selectedCreditCard => {
		if (licenseOfferingResult !== undefined) {
			SetUpSubscriptionUpdate(
				props.retireSensor.moduleId,
				licenseOffering,
				props.retireSensor.deviceViewSettings.view,
				props.retireSensor.history,
				props.retireSensor.deviceViewSettings
			);
		} else {
			const payload = {
				sensor_id: sensorId,
				PaymentInformation: {
					vouchers: validVouchers,
					redeem_prepaid: selectedCreditCard.redeem_prepaid,
					credit_card: {
						id: selectedCreditCard.id || 0,
					},
					offer_total: offer.actual_price,
				},
			};

			setLoading(true);
			props
				.actionMethod(payload)
				.then(_license => {
					Message.success(`License ${props.objectActionVerb}`, `License successfully ${props.objectActionVerb}`);
					setLoading(false);
					redirect();
				})
				.catch(error => {
					Message.error('Error', `License could not be ${props.objectActionVerb}`, error);
					setLoading(false);
					redirect();
				});
		}
	};

	useEffect(() => {
		if (props.retireSensor) {
			PrepareLicenseOfferingFromLineItemTypes(
				props.retireSensor?.serialNumber,
				props.retireSensor?.channelNumber,
				props.retireSensor?.valueIndex,
				{
					baseLicenses: [],
					loggingIntervals: [],
					limitEmail1: null,
					limitEmail2: null,
					limitSms1: null,
					limitSms2: null,
					issueEmail: null,
					issueSms: null,
					retirement1: null,
					retirement2: null,
				},
				[InternalProductCategories.RetirementIPC]
			).then(response => {
				setLicenseOffering(response);
			});
		}
	}, []);

	useEffect(() => {
		if (licenseOffering) {
			GetLicenseOffering(props.retireSensor.moduleId, false, licenseOffering).then(response => setLicenseOfferingResult(response));
		}
	}, [licenseOffering]);

	React.useEffect(() => {
		let payload = {
			sensors_id: sensorId,
			vouchers: validVouchers,
			redeem_prepaid: redeemPrepaid,
		};
		
		props
			.getOffering(payload)
			.then(response => {
				setOffer(response.data);
			})
			.catch(error => console.log('error ', error));
	}, [validVouchers, redeemPrepaid]);

	return (
		<>
			<ViewHeader heading={props.saveAndHeadingText}>
				<Col>
					<Close onClick={close} />
				</Col>
			</ViewHeader>
			<ContentWrapper>
				<Spin spinning={loading}>
					<Row className="d-block">
						<Col sm={12} md={12} lg={12} xl={12}>
							<p style={{fontWeight: 'bold'}}>License</p>
							{licenseOfferingResult !== undefined && (
								<LicenseOfferingTable
									loading={licenseOfferingResult === undefined}
									hasError={false}
									offering={licenseOfferingResult}
								/>
							)}
							{offer !== undefined && licenseOfferingResult === undefined ? (
								<OfferingTable
									vat_amount={offer.vat_amount}
									lines={offer.lines}
									actual_price={offer.actual_price}
									loading={false}
									onRemoveElementByDescription={desc => setValidVouchers(validVouchers.filter(v => v.code !== desc))}
								/>
							) : (
								licenseOfferingResult === undefined && <OfferingTable loading={true} />
							)}
						</Col>
					</Row>
					<Row className="d-block">
						<Col sm={12} md={12} lg={12} xl={12}>
							<InputPaymentMethod
								vouchers={validVouchers}
								afterPaymentMethodAltered={(voucher, redeem_prepaid) => {
									if (voucher) {
										setValidVouchers([...validVouchers, voucher]);
									}
									if (redeemPrepaid !== redeem_prepaid) {
										setRedeemPrepaid(redeem_prepaid);
									}
								}}
								onRef={ref => setInputPaymentMethodCtrl(ref)}
								paymentMethodSettings={props.licensesPaymentMethodSettings}
							></InputPaymentMethod>
						</Col>
					</Row>
					<br />
					<Row>
						<Col>
							<TermsOfUseAndOk
								okText={props.saveAndHeadingText}
								onOk={_ => {
									inputPaymentMethod.setCreditCardElementAndInvokeIfOK(performAction, offer ? offer.actual_price : 0);
								}}
								isLoading={loading}
								alert_msg={
									offer.voucher_rest > 0
										? props.saveAndHeadingText +
										  ' is not possible because voucher not fully redeemable (' +
										  offer.voucher_rest +
										  ' CHF remaining)' +
										  '. Please use prepaid credit.'
										: null
								}
							/>
						</Col>
					</Row>
				</Spin>
			</ContentWrapper>
		</>
	);
};

export default LicenseActionForm;
