import {MODULE_FAMILIES} from '../../../Shared/Constants/Module';
import findFirmwareVersionFromSerialNumber from '../../../Shared/Extensions/DeviceFirmwareVersionFinder';
import SerialNumberInfo from '../../../Shared/SerialNumberInfo';
import {SensorSettings} from './SensorSettings';

export const getSensorSummarySettings = serialNumber => {
	if (!serialNumber) {
		throw new TypeError('Please pass a serialNumber!!!');
	}

	const moduleFamilyType = SerialNumberInfo.findFamilyType(serialNumber);

	let settings = null;
	switch (moduleFamilyType) {
		case MODULE_FAMILIES.ECOLOG_PRO_RADIO:
			settings = SensorSettings.ecologProRadioSettings.sensorSummary;
			break;
		case MODULE_FAMILIES.LIBERO_G: {
			const firmwareVersion = findFirmwareVersionFromSerialNumber(serialNumber);
			if (firmwareVersion === 0) {
				settings = SensorSettings.liberoGFirmware0Settings.sensorSummary;
			}
			if (firmwareVersion >= 1) {
				settings = SensorSettings.liberoGFirmware1Settings.sensorSummary;
			}
			break;
		}
		case MODULE_FAMILIES.ECOLOG_PRO_G:
			settings = SensorSettings.ecologProGSettings.sensorSummary;
		default:
			break;
	}
	return settings;
};
