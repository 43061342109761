import React from 'react';
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
});

// Create Document Component
const PermissionsPDF = ({user, accessSettings}) => {
    console.log('.pdf.....', accessSettings);

    if (!accessSettings) return null;

    const data = accessSettings.map((data, index) => {
        console.log(data);
        const block = {
            Page: data.page,
            Allow: data.allow.toString(),
        };
        return block;
    });
    console.log('...data...', data);
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text>User: {user.firstName}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default PermissionsPDF;
