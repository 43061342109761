export class DevUtils {
	public static IsDevEnv(): boolean {
		try {
			return process.env['NODE_ENV'] === 'development';
		} catch (e) {}
		return false;
	}

	public static UseLocal(service: string): boolean {
		if (!this.IsDevEnv()) {
			return false;
		}

		try {
			return process.env.REACT_APP_SERVICE_USE_LOCALHOST.includes(service);
		} catch (e) {}
		return false;
	}
}
