import React from 'react';
import ElproFormLogo from '../Shared/Components/ElproFormLogo';
import {Divider} from 'antd';
import SerialNumberForm from './SerialNumberForm';
import * as PublicViewCommon from './Common';
import StatusSteps from './StatusSteps';
import StatusAlert, {StatusAlertProps} from './StatusAlert';
import {useHistory} from 'react-router-dom';

export interface PublicViewValidationComponentProps {
	alertProps: Omit<StatusAlertProps, 'onErrorRedirect'>;
	checkStatusManually: () => void;
	currentStatus: string;
	currentStep: number;
	description: string;
	disableFormElement: boolean;
	identifier: string;
	onIdentifierChanged: (e: unknown) => void;
	placeholder?: string;
	stepDescription: {[id: number]: string};
	title: string;
}

export const PublicViewValidationComponent = (props: PublicViewValidationComponentProps) => {
	const history = useHistory();

	const redirectToLogin = () => {
		history.push('/login');
	};

	return (
		<div className="center-screen">
			<div className="card card-flat">
				<div className="card-header">
					<ElproFormLogo link={'login'} />
				</div>
				<div className="card-body ">
					<h4>{props.title}</h4>
					<p></p>
					<Divider plain>{props.description}</Divider>

					<SerialNumberForm
						changeSerialNumber={props.onIdentifierChanged}
						identifier={props.identifier}
						disableFormElement={props.disableFormElement}
						checkStatusManually={props.checkStatusManually}
						placeholder={props.placeholder}
					/>
					<PublicViewCommon.StatusStepsWrapper>
						<StatusSteps
							currentStep={props.currentStep}
							currentStatus={props.currentStatus}
							stepDescription={props.stepDescription}
						/>
					</PublicViewCommon.StatusStepsWrapper>
				</div>
				<PublicViewCommon.AlertWrapper>
					<StatusAlert {...props.alertProps} onErrorRedirect={redirectToLogin} />
				</PublicViewCommon.AlertWrapper>
			</div>
		</div>
	);
};
