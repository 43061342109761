import {DbSensorLimitAlarm} from '../services/AlarmLimitService/DbSensorLimitAlarm';

type LimitBaseType = 'H' | 'L';

export type AlarmLimitProperty = {
	/** The type of the limit in the form of <BaseType><Index> (e.g. H1)  */
	type: string;
	limit: number;
	delay: number;
};

export class AlarmLimit {
	constructor(props: Partial<DbSensorLimitAlarm>) {
		this.Id = props.id;
		this.OrgId = props.org_id;
		this.UpperLimit = props.upper_limit;
		this.UpperLimit2 = props.upper_limit_2;
		this.UpperLimit3 = props.upper_limit_3;
		this.UpperLimit4 = props.upper_limit_4;
		this.LowerLimit = props.lower_limit;
		this.LowerLimit2 = props.lower_limit_2;
		this.LowerLimit3 = props.lower_limit_3;
		this.UpperLimitDelay = props.upper_limit_delay;
		this.UpperLimitDelay2 = props.upper_limit_delay_2;
		this.UpperLimitDelay3 = props.upper_limit_delay_3;
		this.UpperLimitDelay4 = props.upper_limit_delay_4;
		this.LowerLimitDelay = props.lower_limit_delay;
		this.LowerLimitDelay2 = props.lower_limit_delay_2;
		this.LowerLimitDelay3 = props.lower_limit_delay_3;
		this.UpperLimitWarning = props.upper_limit_warning;
		this.LowerLimitWarning = props.lower_limit_warning;
		this.UpperLimitWarningDelay = props.upper_limit_warning_delay;
		this.LowerLimitWarningDelay = props.lower_limit_warning_delay;
		this.ElproCloudDefault = props.elpro_cloud_default;
		this.Name = props.name;
		this.Locked = props.locked;
		this.Active = props.active;
	}

	public GetLimitWithDelay(baseType: LimitBaseType, idx: number): Omit<AlarmLimitProperty, 'type'> {
		try {
			const idxString = idx !== 1 ? `${idx}` : '';
			const typeLong = `${baseType == 'H' ? 'Upper' : 'Lower'}Limit`;
			const limitString = `${typeLong}${idxString}`;
			const delayString = `${typeLong}Delay${idxString}`;

			return {limit: this[limitString], delay: this[delayString]};
		} catch (_) {}
		return undefined;
	}

	public GetLimitsWithDelayByType(type: LimitBaseType, order: 'asc' | 'desc' = 'asc'): AlarmLimitProperty[] {
		const limits: AlarmLimitProperty[] = [];
		const limitCount = type == 'L' ? 3 : 4;

		for (let idx = 1; idx <= limitCount; idx++) {
			const limit = this.GetLimitWithDelay(type, idx);
			if (limit.limit == null) {
				break;
			}
			limits.push({type: `${type}${idx}`, ...limit});
		}

		if (order == 'desc') {
			return limits.reverse();
		}
		return limits;
	}

	public GetAllLimitsWithDelay(): AlarmLimitProperty[] {
		return [...this.GetLimitsWithDelayByType('H', 'desc'), ...this.GetLimitsWithDelayByType('L')];
	}

	public Id: number;
	public OrgId: number;
	public Active: boolean;
	public UpperLimit: number;
	public UpperLimit2: number;
	public UpperLimit3: number;
	public UpperLimit4: number;
	public LowerLimit: number;
	public LowerLimit2: number;
	public LowerLimit3: number;
	public UpperLimitDelay: number;
	public UpperLimitDelay2: number;
	public UpperLimitDelay3: number;
	public UpperLimitDelay4: number;
	public LowerLimitDelay: number;
	public LowerLimitDelay2: number;
	public LowerLimitDelay3: number;
	public UpperLimitWarning: number;
	public LowerLimitWarning: number;
	public UpperLimitWarningDelay: number;
	public LowerLimitWarningDelay: number;
	public ElproCloudDefault: boolean;
	public Name: string;
	public Locked: boolean;
}
