import React, {Component} from 'react';
import ConfirmationService from "./ConfirmationService";
import Authentication from '../../components/Infrastructure/Authentication/Authentication';

class MailConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
    }

    componentDidMount() {
        ConfirmationService.changeMail({token: this.props.match.params.token}).then((response) => {
            Authentication.logout_tokens();
            setTimeout(() => {
                this.props.history.push('/login');
            }, 5000);
        }).catch(error => {
            this.setState({error: true})
        })

    }

    render() {
        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center bg-white">
                        <a>
                            <img width={200} className="block-center rounded" src="/img/logo.svg" alt="Logo"/>
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">
                            {

                                this.state.error
                                    ? 'Activation link has expired!'
                                    :
                                    (
                                        <>
                                            Email confirmed.<br/><br/>
                                            The email address associated to this account has been updated.<br/>
                                            You will automatically be redirected to the login page.
                                        </>
                                    )
                            }
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default MailConfirmation;