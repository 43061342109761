import React from 'react';
import {Col, Input, Row} from 'reactstrap';
import FormValidator from '../../Forms/FormValidator';
import ContentWrapper from '../../Layout/ContentWrapper';
import Cancel from '../../Shared/Components/Cancel';
import Save from '../../Shared/Components/Save';
import Message from '../../Shared/Components/Message';
import SensorGroupsService from './SensorGroupsService';
import SensorGroupsUtils from './SensorGroupsUtils';

const SensorGroup = props => {
	const [groups, setGroups] = React.useState([]);
	const [initSensorGroupFrom] = React.useState(() => {
		const group = !!props.location.state ? props.location.state.group : null;
		return {
			id: !group ? null : group.id || null,
			name: !group ? '' : group.name || '',
			memo: !group ? '' : group.memo || '',
			sensor_group_parent: !group ? '' : `${group.sensors_group_parent || ''}`,
			group_order: !group ? null : group.group_order || null,
		};
	});
	const [sensorGroupFrom, setSensorGroupFrom] = React.useState(initSensorGroupFrom);
	const [errors, setErrors] = React.useState(null);
	const [saveDisabled, setSaveDisabled] = React.useState(true);

	const fetchSensorGroups = React.useCallback(() => {
		SensorGroupsService.sensorGroups().then(({data}) => {
			const groupsOptions = SensorGroupsUtils.getFlatGroups(SensorGroupsUtils.getNestedGroups(data)).filter(
				group => group.group_depth < 3 && group.name !== sensorGroupFrom.name
			);
			setGroups(groupsOptions);
		});
	}, []);

	React.useEffect(() => {
		fetchSensorGroups();
	}, [fetchSensorGroups]);

	const validateOnChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		const result = FormValidator.validate(input);

		setSensorGroupFrom(prevState => ({
			...prevState,
			[input.name]: value,
		}));
		setErrors(prevState => ({
			...prevState,
			[input.name]: result,
		}));
	};

	React.useEffect(() => {
		setSaveDisabled(JSON.stringify(initSensorGroupFrom) === JSON.stringify(sensorGroupFrom));
	}, [sensorGroupFrom]);

	React.useEffect(() => {
		const parentId = !!sensorGroupFrom.sensor_group_parent ? parseInt(sensorGroupFrom.sensor_group_parent, 10) : null;
		const sameLevelGroups = groups.filter(group => group.sensors_group_parent === parentId);
		const lastGroupOrder = Math.max(...[...sameLevelGroups.map(option => option.group_order), 0]);

		setSensorGroupFrom(prevState => ({
			...prevState,
			group_order: lastGroupOrder + 1,
		}));
	}, [groups, sensorGroupFrom.sensor_group_parent]);

	const hasError = (formName, inputName, method) => {
		return !!(sensorGroupFrom && errors && errors[inputName] && errors[inputName][method]);
	};

	const handleSubmit = e => {
		e.preventDefault();

		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);
		setErrors(errors);

		if (!hasError) {
			const actionMessage = sensorGroupFrom.id !== null ? 'updated' : 'added';
			const payload = {
				...sensorGroupFrom,
				sensor_group_parent: !!sensorGroupFrom.sensor_group_parent ? sensorGroupFrom.sensor_group_parent : null,
			};
			SensorGroupsService.upsertSensorGroup(payload)
				.then(() => {
					Message.success(`Sensor Group ${actionMessage}`, `Sensor Group successfully ${actionMessage}`);
					props.history.push('/sensorGroups');
				})
				.catch(error => {
					// unique_violation
					if (error.data.code === '23505') {
						setErrors({name: {unique: true}});
					} else {
						Message.error('Error', `Sensor Group could not ${actionMessage}`, error);
					}
				});
		}
	};

	const cancel = e => {
		e.preventDefault();
		props.history.push('/sensorGroups');
	};

	return (
		<ContentWrapper>
			<form id="formSensorGroup" name="formSensorGroup" onSubmit={handleSubmit}>
				<div className="content-heading">
					<div>Sensor Groups</div>
					<div className="ml-auto">
						<Save disabled={saveDisabled} />
						{'\u00A0'}
						<Cancel onClick={cancel} />
					</div>
				</div>

				<Row>
					<Col xl={3}>
						<br />
						<p>Sensor Group name</p>
						<Input
							type="text"
							id="name"
							name="name"
							placeholder="Enter the Group Name"
							data-validate='["required"]'
							invalid={hasError('formSensorGroup', 'name', 'required') || hasError('formSensorGroup', 'name', 'unique')}
							value={sensorGroupFrom.name}
							onChange={validateOnChange}
						/>
						{hasError('formSensorGroup', 'name', 'required') && <span className="invalid-feedback">Field is required</span>}
						{hasError('formSensorGroup', 'name', 'unique') && (
							<span className="invalid-feedback">Group name should be unique</span>
						)}
					</Col>
				</Row>

				<Row>
					<Col xl={3}>
						<br />
						<p>Sensor Group Parent (optional)</p>
						<Input
							value={sensorGroupFrom.sensor_group_parent}
							type="select"
							name="sensor_group_parent"
							id="sensor_group_parent"
							onChange={validateOnChange}
						>
							<option value="" />
							{groups.map(g => {
								return (
									<option key={g.id} value={g.id}>
										{g.name}
									</option>
								);
							})}
						</Input>
					</Col>
				</Row>

				<Row>
					<Col xl={3}>
						<br />
						<p>Memo (optional)</p>
						<Input
							type="textarea"
							className="form-control"
							id="memo"
							name="memo"
							rows="5"
							value={sensorGroupFrom.memo}
							onChange={validateOnChange}
							invalid={hasError('formSensorGroup', 'memo', 'maxchar')}
							data-validate='["maxchar"]'
							data-param="512"
							maxLength="513"
						/>
						{hasError('formSensorGroup', 'memo', 'maxchar') && (
							<span id="memoNameMax" className="invalid-feedback">
								Field must be be less than 512 characters
							</span>
						)}
					</Col>
				</Row>
			</form>
		</ContentWrapper>
	);
};

export default SensorGroup;
