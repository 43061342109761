import React, {createContext, useMemo, useState} from 'react';
import {authUtils, secureToken} from '../Utils/';

const AccessContext = createContext(null);
const AccessDispatchContext = createContext(null);

const AccessProvider = ({children}) => {
	const [userAccess, setUserAccess] = useState(secureToken.getRolesAccessToken() || {});
	useMemo(() => {
		if (userAccess && userAccess.accessToken) {
			const decodedToken = authUtils.decodeToken(userAccess.accessToken);
			const isEqual = JSON.stringify(userAccess.user) === JSON.stringify(decodedToken);
			if (!isEqual) {
				userAccess.authenticated = false;
			}
		}
		return {userAccess, setUserAccess};
	}, [userAccess]);

	return (
		<AccessContext.Provider value={userAccess}>
			<AccessDispatchContext.Provider value={setUserAccess}>{children}</AccessDispatchContext.Provider>
		</AccessContext.Provider>
	);
};

export {AccessProvider, AccessContext, AccessDispatchContext};
