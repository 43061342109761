import {asyncServerRequest} from '../../Infrastructure/Requests/ServerRequest';

const getUserAccess = async payload => {
	const payloadData = {
		url: 'settings/user-management/user-access',
		method: 'GET',
		params: payload,
	};
	try {
		return await asyncServerRequest(payloadData);
	} catch (error) {
		console.error(error);
	}
};

const setOrgConfigTemplateIfEmpty = async payload => {
	const payloadData = {
		url: 'settings/organization-configuration/config-template',
		method: 'POST',
		data: payload,
	};
	try {
		return await asyncServerRequest(payloadData);
	} catch (error) {
		console.error(error);
	}
};

const LoginService = {
	getUserAccess,
	setOrgConfigTemplateIfEmpty,
};

export default LoginService;
