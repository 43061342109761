import {asyncServerRequest} from '../../Infrastructure/Requests/ServerRequest';

const validateReCaptchaToken = async payload => {
    const payloadData = {
        url: 'recaptcha/validate-recaptcha-token',
        method: 'POST',
        data: payload,
    };
    try {
        return await asyncServerRequest(payloadData);
    } catch (error) {
        console.error(error);
    }
};

const ReCaptchaService = {
    validateReCaptchaToken,
};

export default ReCaptchaService;
