import React, {Component} from 'react';

class NoRowsOverlay extends Component {
    render() {
        return (
            <>
            No rows
            </>
        );
    }
}

export default NoRowsOverlay;
