import React, {useContext, useState} from 'react';
import {Input} from 'reactstrap';
import Alert from '../Shared/Components/Alert';
import {useTranslation, withTranslation} from 'react-i18next';
import FormValidator from '../Forms/FormValidator';
import Authentication from '../Infrastructure/Authentication/Authentication';
import {getUserProfile as getUserProfile} from '../Settings/UserProfile/UserProfileService';
import systemSettings from '../Infrastructure/Settings/SystemSettings';
import pubsub from 'pubsub-js';
import Shared from '../Shared/Shared';
import {AccessDispatchContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {FeatureFlagsDispatchContext} from '../Infrastructure/Authorization/Context/FeatureFlagsContext';
import Menu from '../../Menu';
import {authUtils, secureToken} from '../Infrastructure/Authorization/Utils/';
import LoginService from './Services/LoginService';
import styled from 'styled-components';
import {RequestLogger} from '../Infrastructure/Requests/Logger';
import UserAccess from '../Services/Extensions/UserAccess';
import {Link, useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom-v5-compat';
import {FeatureFlagHelper} from '../../common/helpers';
import {LoadTOTPUri, ValidateTOTPCode} from '../../common/services/WebserverServicePro/AuthenticationService';
import {GetDeviceViewSettings} from '../../common/services/WebserverServicePro/UserSettingsService';

const QRCode = require('qrcode.react');

const MandatoryLogin = styled.span`
	display: block;
	padding: 0 0 10px 0;
`;

const Login = () => {
	const [t] = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const setUserAccess = useContext(AccessDispatchContext);
	const setFeatureFlags = useContext(FeatureFlagsDispatchContext);
	const [formLogin, setFormLogin] = useState({email: '', password: '', errors: {}});
	const [mfaState, setMfaState] = useState(-1);

	const [errorMessage, setErrorMessage] = useState('');
	const [activateUser] = useState(location.state && location.state['userId'] ? location.state : null);

	const [verificationCode, setVerificationCode] = useState('');
	const [verificationSetupCode, setVerificationSetupCode] = useState('');
	const invalidCode = false;

	/**
	 * Validate input using onChange event
	 * @param  {String} event Event object
	 * @return {Function} a function used for the event
	 */
	const validateOnChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;
		const result = FormValidator.validate(input);

		setFormLogin({
			...formLogin,
			[input.name]: value,
			errors: {
				...formLogin.errors,
				[input.name]: result,
			},
		});
	};

	/**
	 * Get location path
	 * If location has 'state' and 'from' property use pathname (as history) to redirect
	 * otherwise redirect to dashboard
	 * @param location URL location object
	 * @param pathToNavigateTo
	 * @returns {string} redirect path
	 */
	const getLocationPath = (location, pathToNavigateTo) => {
		return pathToNavigateTo;
	};

	const login1 = payload => {
		Authentication.login(payload)
			.then(response => {
				if (response.status === 200 && response.data.token) {
					Authentication.setToken(response.data.token);
					const decodedToken = authUtils.decodeToken(response.data.token);
					//decodedToken.mfa=0;
					if (decodedToken.mfa >= 1 && mfaState === -1) {
						setMfaState(1);
						//alert(decodedToken.id);
						if (decodedToken.mfa === 1) {
							LoadTOTPUri(decodedToken.id, {
								userName: payload.email,
								password: payload.pass,
							}).then(response => {
								//alert(response.data.secret);
								setVerificationSetupCode(response.uri);
							});
						} else setMfaState(2);
					} else {
						if (mfaState >= 1) {
							//alert(verificationCode);
							ValidateTOTPCode(decodedToken.id, {
								code: verificationCode,
								userName: payload.email,
								password: payload.pass,
							})
								.then(response => {
									//alert(response.data.code);¨
									if (response.code) {
										if (response.backtoken) {
											Authentication.setToken(response.backtoken);
											setUserTimezoneOffset();

											UserAccess.getUserAccess(
												{userId: decodedToken.id, orgId: decodedToken.orgid},
												activateUser
											).then(response => {
												try {
													prepareToken(response.data.userAccessToken);
												} catch (error) {
													setErrorMessage(t('login.connectionFailed'));
												}
												getFeatureFlags().then();
											});
										}
									} else setErrorMessage(t('login.invalidCode'));
								})
								.catch(() => setErrorMessage(t('login.invalidAttempt')));
						} else login2(response);
					}
				} else {
					setErrorMessage(t('login.invalidAttempt'));
				}
			})
			.catch(_ => setErrorMessage(t('login.connectionFailed')));
	};

	const getFeatureFlags = async () => {
		try {
			await FeatureFlagHelper.GetFeatureFlags().then(response => {
				setFeatureFlags(response);
			});
		} catch (e) {
			console.log(e);
		}
	};

	const prepareToken = token => {
		//Authentication.setToken(token);
		const decodedAccessToken = authUtils.decodeToken(token);
		const {settings} = decodedAccessToken.access_settings;
		if (!settings.access) {
			setErrorMessage(t('login.connectionFailed'));
		} else {
			const allowedNavigationItems = authUtils.allowedNavigationItems(settings.roles, Menu);
			const userData = {
				user: decodedAccessToken,
				authenticated: true,
				accessToken: token,
				allowedNavigationItems: allowedNavigationItems,
			};
			secureToken.setRolesAccessToken(userData);
			secureToken.setDashboardGroups([]);
			setUserAccess(userData);

			GetDeviceViewSettings()
				.then(function (response) {
					try {
						navigate(getLocationPath(location, response.view), {replace: true});
					} catch (error) {
						setErrorMessage(t('login.connectionFailed'));
					}
				})
				.catch(function () {
					navigate(getLocationPath(location, '/dashboard'), {replace: true});
				});

			LoginService.setOrgConfigTemplateIfEmpty({type: 'default'});
			// hide the hubspot chat
			Shared.hideHubspotChat(true);
			localStorage.setItem('hideHubspotChat', 'true');
		}
	};

	const login2 = response => {
		Authentication.setToken(response.data.token);
		setUserTimezoneOffset();
		const decodedToken = authUtils.decodeToken(response.data.token);

		UserAccess.getUserAccess(
			{
				userId: decodedToken.id,
				orgId: decodedToken.orgid,
			},
			activateUser
		).then(response => {
			try {
				prepareToken(response.data.userAccessToken);
			} catch (error) {
				setErrorMessage(t('login.connectionFailed'));
			}
			getFeatureFlags();
		});
	};

	const onSubmit = e => {
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
		const {errors, hasError} =
			mfaState === -1
				? FormValidator.bulkValidate(inputs)
				: {
						errors: {},
						hasError: false,
				  };

		const payload = {
			email: formLogin.email,
			pass: formLogin.password,
		};

		if (mfaState === 1) {
			login1(payload);
		} else {
			setFormLogin({
				...formLogin,
				errors,
			});
			if (!hasError) {
				const payload = {
					email: formLogin.email,
					pass: formLogin.password,
				};
				login1(payload);
				// LoginService.Login(payload)
				/*  Authentication.login(payload)
                    .then(response => {
                        if (response.status === 200 && response.data.token) {
                            const decodedToken = authUtils.decodeToken(response.data.token);
                            if(decodedToken.mfa === 1 && mfaState === -1) {
                                setMfaState(1);
                            }else{
                                Authentication.setToken(response.data.token);
                                setUserTimezoneOffset();
                                //const decodedToken = authUtils.decodeToken(response.data.token);
                                getUserAccess({
                                    userId: decodedToken.id,
                                    orgId: decodedToken.orgid,
                                }).then(response => {
                                    try {
                                        const decodedAccessToken = authUtils.decodeToken(response.data.userAccessToken);
                                        const {settings} = decodedAccessToken.access_settings;
                                        if (!settings.access) {
                                            setErrorMessage('User is currently disabled!');
                                        } else {
                                            const allowedNavigationItems = authUtils.allowedNavigationItems(settings.roles, Menu);
                                            const userData = {
                                                user: decodedAccessToken,
                                                authenticated: true,
                                                accessToken: response.data.userAccessToken,
                                                allowedNavigationItems: allowedNavigationItems,
                                            };
    
                                            secureToken.setRolesAccessToken(userData);
                                            secureToken.setDashboardGroups([]);
                                            setUserAccess(userData);
                                            props.history.push(getLocationPath(location));
                                            LoginService.setOrgConfigTemplateIfEmpty({type: 'default'});
    
                                            // hide the hubspot chat
                                            Shared.hideHubspotChat(true);
                                            localStorage.setItem('hideHubspotChat', 'true');
                                        }
                                    
                                    } catch (error) {
                                        setErrorMessage('Connection to the server failed.\nPlease contact the ELPRO-Support');
                                    }
                                
                                });
                            }
                        } else {
                            setErrorMessage('Invalid username or Password');
                        }
                    })
                    .catch(error => setErrorMessage('Connection to the server failed.\nPlease contact the ELPRO-Support'));
     */
			}
		}

		e.preventDefault();
	};

	const setUserTimezoneOffset = () => {
		getUserProfile(RequestLogger.createLogData('login', 'load-user-profile', 'onClick'))
			.then(response => {
				localStorage.setItem('supports_dst', response.data.supports_dst);
				localStorage.setItem('base_utc_offset', response.data.base_utc_offset);
				localStorage.setItem('timezone', response.data.iana_name);

				systemSettings
					.getTimeZoneOffset(response.data.timezone)
					.then(response => {
						localStorage.setItem('tzOffset', response.data);
						pubsub.publish('tzChanged', response.data);
					})
					.catch(error => console.log('error ', error));
			})
			.catch(error => console.log('error ', error));
	};

	const hasError = (formName, inputName, method) => {
		return formLogin && formLogin.errors && formLogin.errors[inputName] && formLogin.errors[inputName][method];
	};

	const pageRegister = e => {
		navigate('/register');
		e.preventDefault();
	};

	return (
		<div className="block-center mt-4 wd-xl">
			<div className="card card-flat">
				<div className="card-header text-center bg-white">
					<img width={200} src={process.env.PUBLIC_URL + '/img/logo.png'} alt="elproCLOUD" />
				</div>
				{mfaState >= 1 ? (
					<div className="card-body">
						<p className="text-center py-2">Two-factor authentication (2FA)</p>
						<div>
							{mfaState === 1 && (
								<div>
									<p>Scan the QR code on your authenticator app</p>
									<div className="block-center card-header text-center">
										<QRCode value={verificationSetupCode}></QRCode>
									</div>
								</div>
							)}
							<form onSubmit={onSubmit}>
								<p>Enter the code from your authenticator app</p>
								<div className="form-group">
									<Input
										id="verificationCode"
										label="Verification code"
										type="text"
										value={verificationCode}
										onChange={e => setVerificationCode(e.target.value)}
									/>

									<button type="submit" className="btn btn-block btn-primary mt-3">
										Confirm
									</button>

									{invalidCode && <p>Invalid verification code</p>}
								</div>
								<div>
									{errorMessage ? (
										<div style={{paddingBottom: '10px'}}>
											<Alert type="error" description={<div className="text-center">{errorMessage}</div>} />
										</div>
									) : null}
								</div>
							</form>
						</div>
						<br />
					</div>
				) : (
					<div className="card-body">
						<p className="text-center py-2">{t('login.sign').toString()}</p>
						<form className="mb-3" name="formLogin" onSubmit={onSubmit}>
							<div className="form-group">
								<div className="input-group with-focus">
									<Input
										type="email"
										id="inputEmail"
										name="email"
										className="border-right-0"
										placeholder="Email"
										invalid={hasError('formLogin', 'email', 'required') || hasError('formLogin', 'email', 'email')}
										onChange={validateOnChange}
										data-validate='["required", "email"]'
										value={formLogin.email}
									/>
									<div className="input-group-append">
										<span className="input-group-text text-muted bg-transparent border-left-0">
											<em className="fs20 elpro-Username" />
										</span>
									</div>
									{hasError('formLogin', 'email', 'required') && (
										<span className="invalid-feedback">Field is required</span>
									)}
									{hasError('formLogin', 'email', 'email') && (
										<span className="invalid-feedback">Field must be valid email</span>
									)}
								</div>
							</div>
							<div className="form-group">
								<div className="input-group with-focus">
									<Input
										type="password"
										id="inputPassword"
										name="password"
										className="border-right-0"
										placeholder="Password"
										invalid={hasError('formLogin', 'password', 'required')}
										onChange={validateOnChange}
										data-validate='["required"]'
										value={formLogin.password}
									/>
									<div className="input-group-append">
										<span className="input-group-text text-muted bg-transparent border-left-0">
											<em className="fs20 elpro-Password" />
										</span>
									</div>
									<span className="invalid-feedback">Field is required</span>
								</div>
							</div>

							<div>
								{activateUser && (
									<MandatoryLogin>
										<Alert
											description={
												<span>
													<h4>
														{t('settings.userManagement.message.login_activation_warning_title').toString()}
													</h4>
													<span
														dangerouslySetInnerHTML={{
															__html: t('settings.userManagement.message.login_activation_warning'),
														}}
													/>
												</span>
											}
											type="warning"
										/>
									</MandatoryLogin>
								)}
								{errorMessage ? (
									<div style={{paddingBottom: '10px'}}>
										<Alert type="error" description={<div className="text-center">{errorMessage}</div>} />
									</div>
								) : null}
							</div>

							<div className="clearfix">
								<div className="text-center">
									<Link to={'/forgotPassword'} className={'text-muted'} id="btnForgotPassword">
										{t('login.resetPassword').toString()}
									</Link>
								</div>
							</div>

							<button id="btnLogin" className="btn btn-block btn-primary mt-3" type="submit">
								{t('login.login').toString()}
							</button>
						</form>

						<button id="btnRegistry" className="btn btn-block btn-primary mt-3" onClick={pageRegister}>
							{t('login.register').toString()}
						</button>
						<br />
					</div>
				)}
			</div>
		</div>
	);
};

export default withTranslation()(Login);
