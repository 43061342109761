import React from 'react';

function LoggerPositionCard(props) {

    const positionImage = {
        '0/1/0' : 'upwards',
        '1/0/0' : 'sideward_left',
        '-1/0/0' : 'sideward_right',
        '0/0/1' : 'horizontal',
        '0/-1/0' : 'downwards'
    }

    return positionImage[props.position] !== undefined?
            <div className="col-xl-3">
                <img style={{maxHeight: '80px', margin: '-5px 0 20px 5px'}} 
                    src={`/img/loggerPosition/${positionImage[props.position]}.png`} 
                />
            </div>:
            null;
}

export default LoggerPositionCard;
