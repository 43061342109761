import React from 'react';
import {Trans} from 'react-i18next';
import {mapMissingCommunicationWarningEnum} from '../Helpers/MissingCommunicationWarningEnumMapper';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type IssueAlarmMissingCommunicationWarningEnumProps = {
	sensorIssuesForm: any;
	show: boolean;
};

export default function IssueAlarmsMissingCommunicationWarningEnumComponent(props: IssueAlarmMissingCommunicationWarningEnumProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.missingCommunicationWarningEnum'} />
				</td>
				<td>
					{props.sensorIssuesForm.use_issue_alarms
						? props.sensorIssuesForm.missing_communication_warning
							? mapMissingCommunicationWarningEnum(props.sensorIssuesForm.missing_communication_warning_enum)
							: CONSTANTS.NO
						: CONSTANTS.NOT_AVAILABLE}
				</td>
			</tr>
		)
	);
}
