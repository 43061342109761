import React from 'react';
import {Select} from 'antd';
import {Label, Row, Col} from 'reactstrap';
const {Option} = Select;

type IssueProfileSelectProps = {
	value: string | undefined;
	disabled: boolean;
	issueProfiles: any;
	onChange: any;
};

export default function IssueProfileSelectComponent(props: IssueProfileSelectProps) {
	return (
		<Row>
			<Col xl={3}>
				<Label>Issue profile</Label>
				<div>
					<Select
						id="selectIssueAlarmCreationType"
						placeholder="Select issue profile"
						style={{width: '100%'}}
						value={props.value}
						onChange={props.onChange}
						disabled={props.disabled}
					>
						{props.issueProfiles
							.sort((i1, i2) => i1.name.localeCompare(i2.name))
							.map(issueProfile => {
								return (
									<Option id={issueProfile.name} key={issueProfile.id} value={issueProfile.id}>
										{issueProfile.name}
									</Option>
								);
							})}
					</Select>
				</div>
			</Col>
		</Row>
	);
}
