import {PredictiveFormState} from './PredictiveForm';
import {FormSummary, Row} from '../Summary/FormSummary';
import React from 'react';
import {WithUnitString} from '../../../../common/util';
import {PredictiveTrans} from './Trans';
import {OutUnit} from '../../../../common/constants/OutUnit';

export function PredictiveFormSummary(props: {predictiveFormState?: PredictiveFormState; outUnit: OutUnit}): React.JSX.Element {
	if (!props.predictiveFormState || !props.predictiveFormState.enabled) {
		return null;
	}

	const rows: Row[] = [
		{key: PredictiveTrans.lane, value: props.predictiveFormState.lane.name},
		{key: PredictiveTrans.container, value: props.predictiveFormState.container.name},
		{
			key: PredictiveTrans.upperLimit,
			value: WithUnitString(props.predictiveFormState.alarmLimit.UpperLimit, props.outUnit),
		},
		{
			key: PredictiveTrans.lowerLimit,
			value: WithUnitString(props.predictiveFormState.alarmLimit.LowerLimit, props.outUnit),
		},
	];

	return <FormSummary title={PredictiveTrans.title} rows={rows} />;
}
