import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Input, Label} from 'reactstrap';
import ContentWrapper from '../../../Layout/ContentWrapper';
import FormValidator from '../../../Forms/FormValidator';
import SensorService from '../SensorService';
import Message from '../../../Shared/Components/Message';
import Close from '../../../Shared/Components/Close';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import Alert from '../../../Shared/Components/Alert';
import {Trans} from 'react-i18next';
import {MultiContext} from '../../../Infrastructure/Authorization/Context/MultiContext';
import {ReplaceSensorState} from './ReplaceSensorState';
import {RouteChildrenProps} from 'react-router-dom';
import ReplaceSensorConditionsModal from './ReplaceSensorConditionsModal';

class ReplaceSensor extends Component<RouteChildrenProps, ReplaceSensorState> {
	declare context: React.ContextType<typeof MultiContext>;
	static contextType = MultiContext;
	constructor(props: RouteChildrenProps) {
		super(props);
		this.state = {
			sensorId: props.match.params['id'],
			serialNumber: props.location.state['serial_number'],
			includingCombinedSensor: '',
			replaceConditionsModalVisible: false,
			replaceSensorForm: {
				newSerialNumber: '',
				errors: {},
				isValid: false,
			},
		};
	}

	componentDidMount() {
		SensorService.replaceSensor({
			_sensors_id: this.state.sensorId,
			new_serial_nr: '',
			dont_replace_get_combined_sensor_name: true,
		})
			.then(response => {
				this.setState({includingCombinedSensor: response.data});
			})
			.catch(error => Message.error('Error', 'Combined sensors not found'));
	}

	handleFormValidationState = input => {
		return !FormValidator.bulkValidate([input]).hasError;
	};

	handleSerialNumberChange = event => {
		const input = event.target;
		const value = event.target.value.toUpperCase();
		const result = FormValidator.validate(input);

		this.setState({
			replaceSensorForm: {
				...this.state.replaceSensorForm,
				[input.name]: value,
				isValid: this.handleFormValidationState(input),
				errors: {
					...this.state.replaceSensorForm.errors,
					[input.name]: result,
				},
			},
		});
	};

	hasError = (formName, inputName, method) => {
		return (
			this.state[formName] &&
			this.state[formName].errors &&
			this.state[formName].errors[inputName] &&
			this.state[formName].errors[inputName][method]
		);
	};

	onSubmit = event => {
		const form = event.target;
		const inputs = [...form.elements].filter(i => ['INPUT'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);

		this.setState({
			replaceSensorForm: {
				...this.state.replaceSensorForm,
				errors: {
					errors,
				},
			},
		});

		if (!hasError) {
			this.setState({
				replaceConditionsModalVisible: true,
			});
		}
		event.preventDefault();
	};

	conditionsAccepted = () => {
		SensorService.replaceSensor({
			_sensors_id: this.state.sensorId,
			new_serial_nr: this.state.replaceSensorForm.newSerialNumber,
		})
			.then(() => {
				this.props.history.push({
					pathname:
						'/connectModule/' +
						this.state.sensorId +
						'/' +
						this.state.replaceSensorForm.newSerialNumber +
						'/' +
						this.state.replaceSensorForm.newSerialNumber[0],
				});
			})
			.catch((error: any) => Message.error('Error', 'Sensor replacement failed', error));
	};

	conditionsDeclined = () => {
		this.setState({replaceConditionsModalVisible: false});
	};

	close = e => {
		const DevicesViewSettings = this.context.AccessContext.user.user_settings.devicesViewSettings;
		this.props.history.push(DevicesViewSettings.view);
		e.preventDefault();
	};

	render() {
		return (
			<>
				<ReplaceSensorConditionsModal
					isVisible={this.state.replaceConditionsModalVisible}
					serial_number={this.state.serialNumber}
					onConditionsAccepted={this.conditionsAccepted.bind(this)}
					onConditionsDeclined={this.conditionsDeclined.bind(this)}
				/>
				<ContentWrapper>
					<div className="content-heading">
						<div>
							<Trans i18nKey={'sensor.replaceSensor'} />
						</div>
						<div className="ml-auto">
							<Close onClick={this.close} />
						</div>
					</div>
					<Accordion>
						<AccordionItem expanded>
							<AccordionItemTitle>
								<h5 className="u-position-relative">
									<Trans i18nKey={'replaceSensor.replaceSelectedSensor'} />: {this.state.serialNumber}
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								<Form id="replaceSensorForm" name="replaceSensorForm" onSubmit={this.onSubmit}>
									<FormGroup row>
										<Label for="inputSerialNumber" sm={2}>
											{this.state.serialNumber}
										</Label>
										<Col sm={4}>
											<Input
												type="text"
												id="inputSerialNumber"
												name="newSerialNumber"
												placeholder="Enter the new module's serial number"
												invalid={
													this.hasError('replaceSensorForm', 'newSerialNumber', 'required') ||
													this.hasError('replaceSensorForm', 'newSerialNumber', 'replaceSerialNumberValid') ||
													this.hasError(
														'replaceSensorForm',
														'newSerialNumber',
														'replaceSerialNumberSameModuleType'
													)
												}
												data-validate='["required", "replaceSerialNumberValid", "replaceSerialNumberSameModuleType"]'
												initial-serial-number={this.state.serialNumber}
												value={this.state.replaceSensorForm.newSerialNumber}
												onChange={this.handleSerialNumberChange}
											/>
											{this.hasError('replaceSensorForm', 'newSerialNumber', 'required') && (
												<span className="invalid-feedback">
													<Trans i18nKey={'validation.fieldRequired'} />
												</span>
											)}
											{this.hasError('replaceSensorForm', 'newSerialNumber', 'replaceSerialNumberValid') && (
												<span className="invalid-feedback">
													<Trans i18nKey={'validation.replaceSensorForm.serialNumberNotValid'} />
												</span>
											)}
											{this.hasError('replaceSensorForm', 'newSerialNumber', 'replaceSerialNumberSameModuleType') && (
												<span className="invalid-feedback">
													<Trans i18nKey={'validation.replaceSensorForm.sensorMustBeSameType'} />
												</span>
											)}
										</Col>
									</FormGroup>
									<FormGroup row>
										{this.state.includingCombinedSensor && (
											<Col
												sm={{size: 4, offset: 2}}
												style={{paddingBottom: 16}}
												id="replaceCombinedSensorWarningMessage"
											>
												<Alert
													message="Warning"
													description={
														<Trans
															i18nKey={'replaceSensor.replaceCombinedSensors'}
															values={{sensors: this.state.includingCombinedSensor}}
														/>
													}
													type="error"
												/>
											</Col>
										)}

										<Col sm={{size: 10, offset: 2}}>
											<Button disabled={!this.state.replaceSensorForm.isValid} color="primary">
												<Trans i18nKey={'sensor.replaceSensor'} />
											</Button>
										</Col>
									</FormGroup>
								</Form>
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				</ContentWrapper>
			</>
		);
	}
}

export default ReplaceSensor;
