import React, {useState, useEffect} from 'react';
import SensorUtils from './SensorUtils';
import styled, {css} from 'styled-components';
import {Badge, Tag} from 'antd';
import AntPopover from '../../Shared/UI/Ant/AntPopover';
import SensorService from './SensorService';
import {Table} from 'reactstrap';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import {Sensor} from '../../Common/Types/Sensor';

const SensorNameWrapper = styled.div`
	display: flex;
	align-items: baseline;
	border-top: 0;
	background-color: ${props => props.filling};
`;

const MultiRunWrapper = styled.div`
	flex-basis: 40px;
	text-align: center;
`;

const ModuleTypeWrapper = styled.div`
	flex-basis: 50px;
	text-align: right;
`;

const NameWrapper = styled.div`
	padding: 0 0 10px 0;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	flex: 1;
`;

const SensorName = styled.span`
	padding-top: 3px;
	width: 100%;
	${props =>
		props.retired &&
		css`
			color: #d9dada;
		`}
`;

const THead = styled.th`
	padding: 4px 0 4px 5px !important;
	font-weight: normal;
	width: ${props => props.colWidth};
`;

const MultiRunCountBadge = styled(Badge)`
	.ant-badge-count {
		pointer-events: none;
		font-size: 9px;
		color: #222;
		background-color: #fadb14;
		box-shadow: 0 0 0 1px #b1b3b3 inset;
	}
`;

const InfoViewWrapper = styled.div`
	font-size: 13px;
	line-height: 1.7em;
	width: 400px;
`;

const PrevRunWrapper = styled.div`
	max-height: 150px;
	overflow-x: auto;
`;

const PreviousRunTable = styled(Table)`
	max-height: 150px;
`;

const TableHead = () => {
	return (
		<thead>
			<tr>
				<THead colWidth={'20%'}>ID</THead>
				<THead colWidth={'40%'}>Start</THead>
				<THead colWidth={'40%'}>End</THead>
			</tr>
		</thead>
	);
};

const MultiRunInfo = ({data}) => {
	return (
		<InfoViewWrapper>
			<p style={{fontWeight: 'bold'}}>Current Sensor Run start: {DateTimeUtils.setDateTimeWithOffset_date_dep(data[0].ts_start)}</p>
			{data.length > 1 ? (
				<>
					<p>Previous Runs</p>
					<PrevRunWrapper>
						<PreviousRunTable size="sm">
							<TableHead />
							<tbody>
								{data.slice(1).map((run, _) => {
									return (
										<tr key={run.sensors_id}>
											<td>{run.sensors_id}</td>
											<td>{DateTimeUtils.setDateTimeWithOffset_date_dep(run.ts_start)}</td>
											<td>{DateTimeUtils.setDateTimeWithOffset_date_dep(run.ts_end)}</td>
										</tr>
									);
								})}
							</tbody>
						</PreviousRunTable>
					</PrevRunWrapper>
				</>
			) : null}
		</InfoViewWrapper>
	);
};

const ModuleType = ({shortName, bgColor}) => <Tag color={bgColor}>{shortName}</Tag>;

export interface SensorFooterProps {
	fillingColor: string;
	moduleShortNameBackgroundColor: string;
	userOffset: number;
	sensor: Sensor;
}

const SensorFooter = (props: SensorFooterProps) => {
	const [isRetired] = useState(props.sensor.IsRetired);
	const [sensorName] = useState(props.sensor.Name);
	const [className] = useState(SensorUtils.setLicenseLevelClass(props.sensor));
	const [multiRun, setMultiRun] = useState({isMultiRun: props.sensor.IsMultiRun, data: []});
	const [tagModuleShortName] = useState(props.sensor.ModuleShortName);
	const [tagModuleShortNameBGColor] = useState(props.moduleShortNameBackgroundColor);

	useEffect(() => {
		if (multiRun.isMultiRun) {
			SensorService.getMultiUseRunInfo({s_id: props.sensor.Id}).then(response => {
				setMultiRun({...multiRun, data: response.data});
			});
		}
	}, []);

	return (
		<SensorNameWrapper className={className} filling={props.fillingColor}>
			<MultiRunWrapper>
				{multiRun.isMultiRun && (
					<AntPopover
						title={`Multiuse Sensor - Total Runs: ${multiRun.data.length}`}
						content={<MultiRunInfo data={multiRun.data} />}
						trigger="hover"
					>
						<MultiRunCountBadge count={multiRun.data.length} />
					</AntPopover>
				)}
			</MultiRunWrapper>
			<NameWrapper>
				<SensorName retired={isRetired}>{sensorName}</SensorName>
			</NameWrapper>
			<ModuleTypeWrapper>
				{tagModuleShortName && <ModuleType shortName={tagModuleShortName} bgColor={tagModuleShortNameBGColor} />}
			</ModuleTypeWrapper>
		</SensorNameWrapper>
	);
};

export default SensorFooter;
