import LoginService from '../../Login/Services/LoginService';
import axios from 'axios';
import Authentication from '../../Infrastructure/Authentication/Authentication';
import {ActivateUser} from '../../../common/services/WebserverServicePro/UserService';
const isDebugMode = false;

const localHostGetUserAccess = payload => {
	return axios({
		url: 'http://localhost:80/srv/v1/settings/user-management/user-access',
		method: 'GET',
		params: payload,
		headers: {
			Authorization: 'Bearer ' + Authentication.getToken(),
			'Content-Type': 'application/json',
			Accept: 'text/plain',
		},
	});
};

const getUserAccess = (payload, activateUser) => {
	if (activateUser && activateUser.userId === payload.userId) {
		return ActivateUser(payload.userId)
			.then(() => {
				return isDebugMode ? localHostGetUserAccess(payload) : LoginService.getUserAccess(payload);
			})
			.catch(error => {
				console.log(error);
			});
	} else {
		return isDebugMode ? localHostGetUserAccess(payload) : LoginService.getUserAccess(payload);
	}
};

const UserAccess = {
	getUserAccess,
};

export default UserAccess;
