import React from "react";
import {Button} from "reactstrap";
import {useTranslation} from 'react-i18next';

const Cancel = (props) => {
    const [t] = useTranslation();
    return (
        <Button id="btnCancel" color="primary" size="lg" onClick={props.onClick}>
            <span>{t('buttons.cancel')}</span>
        </Button>
    );
};

export default Cancel;