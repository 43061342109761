import {EcologProRadioType, EcologProXGType, LiberoGxType} from '../constants/DeviceType';
import {DeviceFamily, DeviceType} from '../constants';
import {Sensor} from '../../components/Common/Types/Sensor';

export class DeviceUtils {
	public static GetDeviceType(param: string | Sensor): DeviceType {
		const serialNumber = param instanceof Sensor ? param.SerialNumber : param;
		switch (this.GetDeviceFamily(serialNumber)) {
			case DeviceFamily.LiberoGx:
				return this.GetLiberoGxDeviceType(serialNumber);
			case DeviceFamily.EcologProXG:
				return this.GetEcologProXGType(serialNumber);
			case DeviceFamily.EcologProRadio:
				return this.GetEcologProRadioType(serialNumber);
			default:
				return undefined;
		}
	}

	public static GetDeviceFamily(param: string | Sensor): DeviceFamily {
		const serialNumber = param instanceof Sensor ? param.SerialNumber : param;
		if (this.IsLiberoGx(serialNumber)) {
			return DeviceFamily.LiberoGx;
		}
		if (this.IsEcologProXG(serialNumber)) {
			return DeviceFamily.EcologProXG;
		}
		if (this.IsEcologProRadio(serialNumber)) {
			return DeviceFamily.EcologProRadio;
		}
	}

	public static IsLiberoGx(param: string | Sensor): boolean {
		const serialNumber = param instanceof Sensor ? param.SerialNumber : param;
		return /^[39][1-6][0-2][0-9a-f]{10}$/i.test(serialNumber);
	}

	public static IsEcologProXG(param: string | Sensor): boolean {
		const serialNumber = param instanceof Sensor ? param.SerialNumber : param;
		return /^[wxy][1-2][0-9a-f]{8}/i.test(serialNumber);
	}

	public static IsEcologProRadio(param: string | Sensor): boolean {
		const serialNumber = param instanceof Sensor ? param.SerialNumber : param;
		return /^[sqr]\d{6}/i.test(serialNumber);
	}

	public static GetLiberoGxDeviceType(param: string | Sensor): LiberoGxType {
		const serialNumber = param instanceof Sensor ? param.SerialNumber : param;
		if (!this.IsLiberoGx(serialNumber)) {
			return undefined;
		}
		switch (serialNumber[1]) {
			case '1':
				return LiberoGxType.GS;
			case '2':
				return LiberoGxType.GF;
			case '3':
				return LiberoGxType.GL;
			case '4':
				return LiberoGxType.GE;
			case '5':
				return LiberoGxType.GH;
			case '6':
				return LiberoGxType.GFExt;
			default:
				return undefined;
		}
	}

	public static GetEcologProRadioType(serialNumber: string): EcologProRadioType {
		if (!this.IsEcologProRadio(serialNumber)) {
			return undefined;
		}
		switch (serialNumber[0].toUpperCase()) {
			case 'S':
				return EcologProRadioType.OneNTR;
			case 'Q':
				return EcologProRadioType.TwoPTR;
			case 'R':
				return EcologProRadioType.OneTHR;
			default:
				return undefined;
		}
	}

	public static GetEcologProXGType(serialnumber: string): EcologProXGType {
		if (!this.IsEcologProXG(serialnumber)) {
			return undefined;
		}

		switch (serialnumber[0].toUpperCase()) {
			case 'W':
				return EcologProXGType.OneTGi;
			case 'X':
				return EcologProXGType.OneTHGi;
			case 'Y':
				return EcologProXGType.OneTGe;
			default:
				return undefined;
		}
	}
}
