import React from "react";
import { Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import FormValidator from "./../FormValidator";
import ErrorDisplay from "./../ErrorDisplay";



const ListControl = React.forwardRef(({
    id,
    name,
    label,
    alias,
    value,
    disabled,
    errors,
    showErrors,
    handleChange,
    handleBlur,
    dataToValidate,
    ...rest
}, ref) => {

    const [values, setValues] = React.useState(value || []);
    const [inputValue, setInputValue] = React.useState("");

    const singleInputRef = React.useRef();

    React.useEffect(() => {
        const fackEvent = { target: { value: JSON.stringify(values) } };
        handleChange(fackEvent)
    }, [values])

    const handleEditItem = index => {
        const item = values.filter((value, i) => i === index);
        setValues(prevValues => prevValues.filter((value, i) => i !== index));
        setInputValue(item);
    }

    const handleRemoveItem = index => {
        setValues(prevValues => prevValues.filter((value, i) => i !== index));
    }

    const handleInputChange = e => {
        setInputValue(e.target.value);
    }

    const handleInputKeyDown = e => {
        /**
         * Move to Next item on the list when typing "Tab", "space" OR comma ","
        **/
        if (e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 188) {
            const { value } = e.target;

            if (value !== "") {
                const validationResult = FormValidator.validate(singleInputRef.current);
                const resultKeys = Object.keys(validationResult).filter(val => validationResult[val]);

                if (resultKeys.length <= 0) {
                    setValues(prevValues => [...prevValues, value]);
                    setInputValue("");
                }
            }
        }

        if (e.keyCode === 8 && values.length > 0 && inputValue.length === 0) {
            handleRemoveItem(values.length - 1);
        }
    }

    const handleInputKeyUp = e => {
        if (e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 188) {
            setInputValue(prevValue => prevValue.slice(0, -1));
        }
    }

    const _handleBlur = e => {
        handleBlur();

        const { value } = e.target;

        if (value !== "") {
            const validationResult = FormValidator.validate(singleInputRef.current);
            const resultKeys = Object.keys(validationResult).filter(val => validationResult[val]);

            if (resultKeys.length <= 0) {
                setValues(prevValues => [...prevValues, value]);
                setInputValue("");
            }
        }
    }

    return (
        <>
            <Label for={id}>{label}</Label>
            <Label
                for={id}
                className="form-control list-control-container"
                invalid={showErrors && !disabled && errors.length > 0}
            >
                {values.map((value, i) => (
                    <div key={i} className="list-control-items">
                        <span onClick={() => handleEditItem(i)}>{value} </span>
                        <span className="remove" onClick={() => handleRemoveItem(i)}> x </span>
                    </div>
                ))}

                <Input
                    innerRef={ref}
                    id={id}
                    name={name}
                    type="text"
                    placeholder={values.length > 0 ? "" : `Enter ${alias}`}
                    className="list-control-input"
                    value={inputValue}
                    disabled={disabled}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onKeyUp={handleInputKeyUp}
                    onBlur={_handleBlur}
                />
                <Input
                    innerRef={singleInputRef}
                    type="hidden"
                    name={name}
                    value={inputValue}
                    data-validate={`["${rest.subtype}"]`}
                />

                {showErrors && !disabled && errors.map((error, i) => (
                    <ErrorDisplay key={i} error={error} />
                ))}
            </Label>
        </>
    );
});

ListControl.displayName = 'ListControl';

ListControl.propTypes = {
    errors: PropTypes.array,
    handleChange: PropTypes.func
}

ListControl.defaultProps = {
    errors: [],
    handleChange: () => { }
};

export default ListControl;