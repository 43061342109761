import {asyncServerRequest} from '../../components/Infrastructure/Requests/ServerRequest';
import {User} from '../types';

const baseUrl = 'settings/user-management';
export class UserManagementService {
	public static async GetUsers(): Promise<User[]> {
		try {
			const payload = {
				url: `${baseUrl}/users`,
				method: 'GET',
			};
			const response = await asyncServerRequest(payload);
			return response.data.users.map(u => new User(u));
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}
}
