import React, {MouseEventHandler} from 'react';
import {Modal as AntModal, ModalProps as AntModalProps} from 'antd';

type ModalProps = {
	/** @deprecated Use onOk instead */
	handleOk?: MouseEventHandler;

	/** @deprecated Use onCancel instead */
	handleCancel?: MouseEventHandler;
};

export const Modal = ({
	centered = true,
	children,
	handleCancel,
	handleOk,
	onCancel,
	onOk,
	open,
	visible,
	...restProps
}: ModalProps & AntModalProps) => {
	return (
		<>
			<AntModal open={open ?? visible} centered={centered} onOk={onOk ?? handleOk} onCancel={onCancel ?? handleCancel} {...restProps}>
				{children}
			</AntModal>
		</>
	);
};

export default Modal;
