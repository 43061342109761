import Feature from "ol/Feature"
import { LineString } from "ol/geom"
import { Style as OlStyle, Stroke as OlStroke } from "ol/style";

export const GeoPolyline = (points) => {
    const route = new LineString(points).transform('EPSG:4326', 'EPSG:3857');
    let routeCoords = route.getCoordinates();

    let routeFeature = new Feature({
        geometry: route
    });
    routeFeature.setStyle(new OlStyle({
        stroke: new OlStroke({
            width: 3,
            color: [53, 23, 172, 1]
        })
    }));

    return [routeFeature, routeCoords];
}
