const AuditTrailEventTypes = [
	{
		type: 0,
		translate: 'Unknown',
	},
	{
		type: 1,
		translate: 'OrganizationCreated',
	},
	{
		type: 2,
		translate: 'OrganizationConfirmed',
	},
	{
		type: 3,
		translate: 'UserCreated',
	},
	{
		type: 4,
		translate: 'MessageSent',
	},
	{
		type: 5,
		translate: 'MessageSendingFailed',
	},
	{
		type: 6,
		translate: 'UserConfirmed',
	},
	{
		type: 7,
		translate: 'LoginSuccessful',
	},
	{
		type: 8,
		translate: 'LoginFailed',
	},
	{
		type: 9,
		translate: 'Logout',
	},
	{
		type: 10,
		translate: 'UserChanged',
	},
	{
		type: 11,
		translate: 'NewEmailAddressConfirmed',
	},
	{
		type: 12,
		translate: 'OrganizationChanged',
	},
	{
		type: 13,
		translate: 'NewMeasurementModuleAdded',
	},
	{
		type: 14,
		translate: 'MeasurementModuleChanged',
	},
	{
		type: 15,
		translate: 'MeasurementModuleDeleted',
	},
	{
		type: 16,
		translate: 'NewSensorAdded',
	},
	{
		type: 17,
		translate: 'SensorChanged',
	},
	{
		type: 18,
		translate: 'SensorAlarmingActivated',
	},
	{
		type: 19,
		translate: 'SensorAlarmingDeactivated',
	},
	{
		type: 20,
		translate: 'SensorPaused',
	},
	{
		type: 21,
		translate: 'SensorRestarted',
	},
	{
		type: 22,
		translate: 'SensorRetired',
	},
	{
		type: 23,
		translate: 'SensorDeleted',
	},
	{
		type: 24,
		translate: 'DeviationOccurred',
	},
	{
		type: 25,
		translate: 'DeviationNoLongerActive',
	},
	{
		type: 26,
		translate: 'DeviationDeactivated',
	},
	{
		type: 27,
		translate: 'DeviationAcknowledged',
	},
	{
		type: 28,
		translate: 'SensorLicenseModelChanged',
	},
	{
		type: 29,
		translate: 'SensorLicenseChargedSuccessfully',
	},
	{
		type: 30,
		translate: 'SensorLicenseChargeFailed',
	},
	{
		type: 31,
		translate: 'SensorLicenseExpiryWarning',
	},
	{
		type: 32,
		translate: 'SensorLimitProfileCreated',
	},
	{
		type: 33,
		translate: 'SensorLimitProfileChanged',
	},
	{
		type: 34,
		translate: 'SensorLimitProfileDeleted',
	},
	{
		type: 35,
		translate: 'SensorIssueProfileCreated',
	},
	{
		type: 36,
		translate: 'SensorIssueProfileChanged',
	},
	{
		type: 37,
		translate: 'SensorIssueProfileDeleted',
	},
	{
		type: 38,
		translate: 'ModuleIssueOccurred',
	},
	{
		type: 39,
		translate: 'ModuleIssueNoLongerActive',
	},
	{
		type: 40,
		translate: 'ModuleIssueDeactivated',
	},
	{
		type: 41,
		translate: 'ServiceStarted',
	},
	{
		type: 42,
		translate: 'ServiceStopped',
	},
	{
		type: 43,
		translate: 'ApplicationErrorOccurred',
	},
	{
		type: 44,
		translate: 'CreditCardCreated',
	},
	{
		type: 45,
		translate: 'CreditCardDeleted',
	},
	{
		type: 46,
		translate: 'OrganisationAddress',
	},
	{
		type: 47,
		translate: 'UserPasswordChanged',
	},
	{
		type: 48,
		translate: 'UserConfirmationResent',
	},
	{
		type: 49,
		translate: 'CreditCardInvalid',
	},
	{
		type: 50,
		translate: 'UserPasswordResetRequested',
	},
	{
		type: 51,
		translate: 'LicenseWarningSanctionBlur',
	},
	{
		type: 52,
		translate: 'LicenseSanctionBlur',
	},
	{
		type: 53,
		translate: 'LicenseWarningSanctionMeasurementStop',
	},
	{
		type: 54,
		translate: 'LicenseSanctionMeasurementStop',
	},
	{
		type: 55,
		translate: 'LicenseWarningSanctionSensorDelete',
	},
	{
		type: 56,
		translate: 'LicenseSanctionSensorDelete',
	},
	{
		type: 57,
		translate: 'UserProfileChanged',
	},
	{
		type: 58,
		translate: 'ModuleRegistered',
	},
	{
		type: 59,
		translate: 'ModuleCRCError',
	},
	{
		type: 60,
		translate: 'SensorLicenseAutomaticExtended',
	},
	{
		type: 61,
		translate: 'NewEmailAddressRequested',
	},
	{
		type: 62,
		translate: 'SensorLicenseAutomaticChargeFailed',
	},
	{
		type: 63,
		translate: 'SensorLicenseAutomaticChargedSuccessfully',
	},
	{
		type: 64,
		translate: 'SensorReplaced',
	},
	{
		type: 65,
		translate: 'CalibrationExpiry',
	},
	{
		type: 66,
		translate: 'RunStarted',
	},
	{
		type: 67,
		translate: 'RunStopped',
	},
	{
		type: 68,
		translate: 'SensorLicenseChargedSuccessfullySilent',
	},
	{
		type: 69,
		translate: 'SensorLicenseChargedFailedSilent',
	},
	{
		type: 70,
		translate: 'EmailTestSent',
	},
	{
		type: 71,
		translate: 'SmsTestSent',
	},
	{
		type: 72,
		translate: 'CustomerSubscriptionCreated',
	},
	{
		type: 73,
		translate: 'CustomerSubscriptionUpdated',
	},
	{
		type: 74,
		translate: 'CustomerSubscriptionCancelled',
	},
	{
		type: 75,
		translate: 'CalibrationStartRequestSent',
	},
	{
		type: 76,
		translate: 'CalibrationStarted',
	},
	{
		type: 77,
		translate: 'CalibrationStopRequestSent',
	},
	{
		type: 78,
		translate: 'CalibrationStopped',
	},
	{
		type: 79,
		translate: 'SensorRunNew',
	},
];

export default AuditTrailEventTypes;
