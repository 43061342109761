import {SensorRunInfoRow} from './DbModel';
import dayjs from 'dayjs';

export class RunInfoSummary {
	constructor(init: SensorRunInfoRow) {
		this.RunCount = init.run_count;
		this.StartDate = dayjs(init.ts_start);
		this.EndDate = dayjs(init.ts_end);
		this.SensorId = init.sensors_id;
	}

	public RunCount: number;
	public StartDate: dayjs.Dayjs;
	public EndDate: dayjs.Dayjs;
	public SensorId: number;
}
