import {Checkbox, Form, FormInstance, Select, SelectProps} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SmsSettings, User} from '../../../common/types';
import {OrganisationSettingService, UserManagementService, UserProfileService} from '../../../common/services';
import {DeviceFamily} from '../../../common/constants';
import {colProps} from '../../../common/props/AntFormProps';
import {RecipientSelectState} from './RecipientSelectState';

type OptionsType = SelectProps['options'];

export function RecipientSelect(props: {
	deviceFamily: DeviceFamily;
	disabled: boolean;
	form: FormInstance;
	onValueChanged: (state: RecipientSelectState) => void;
	initialState?: RecipientSelectState;
}): React.JSX.Element {
	const [t] = useTranslation();
	const [users, setUsers] = useState<User[]>([]);

	const [formState, setFormState] = useState<RecipientSelectState>({
		email: {enabled: false, recipients: []},
		sms: {enabled: false, recipients: []},
	});

	const [smsSettings, setSmsSettings] = useState<SmsSettings>({Allow: false, AllowMulti: false});

	const emailSelectName = `${t('sensorWizard.general.email')} ${t('sensorWizard.recipients.recipients')}`;
	const smsSelectName = `${t('sensorWizard.summary.sms')} ${t('sensorWizard.recipients.recipients')}`;

	useEffect(() => {
		const currentUserPromise = UserProfileService.GetUser();
		const remainingUsersPromise = UserManagementService.GetUsers();
		Promise.all([currentUserPromise, remainingUsersPromise]).then(([user, users]) => setUsers([user, ...users]));

		let smsSettings: SmsSettings = {AllowMulti: false, Allow: true};
		OrganisationSettingService.GetOrganisationSettingGlobal().then(r => {
			if (props.deviceFamily === DeviceFamily.LiberoGx) {
				smsSettings.Allow = r.AllowLiberogSmsSingle;
				smsSettings.AllowMulti = r.AllowLiberogSmsMulti;
			} else if (props.deviceFamily === DeviceFamily.EcologProXG || props.deviceFamily === DeviceFamily.EcologProRadio) {
				smsSettings.Allow = r.AllowEcologSmsSingle;
				smsSettings.AllowMulti = r.AllowEcologSmsMulti;
			}
			setSmsSettings(smsSettings);
		});
	}, []);

	useEffect(() => {
		if (!users || users.length == 0 || !props.initialState) {
			return;
		}

		const state: RecipientSelectState = {
			email: {
				enabled: props.initialState.email?.enabled ?? false,
				recipients: reloadUsers(props.initialState.email?.recipients) ?? [],
			},
			sms: {
				enabled: props.initialState.sms?.enabled ?? false,
				recipients: reloadUsers(props.initialState.sms?.recipients) ?? [],
			},
		};
		setFormState(state);
	}, [users, props.initialState]);

	useEffect(() => {
		if (!formState) {
			return;
		}
		props.onValueChanged(formState);
		// Update select to display the correct label
		props.form.setFieldValue(
			emailSelectName,
			formState.email.recipients.map(user => user.Id)
		);
		props.form.setFieldValue(
			smsSelectName,
			formState.sms.recipients.map(user => user.Id)
		);
	}, [formState]);

	useEffect(() => {
		if (!users || users.length == 0) {
			return;
		}
		if (props.disabled) {
			setFormState({email: {enabled: false, recipients: []}, sms: {enabled: false, recipients: []}});
		} else if (props.initialState == undefined) {
			// Enable E-Mail by default if it has not explicitly been set before
			setFormState({...formState, email: {enabled: true, recipients: [users.at(0)]}});
		}
	}, [props.disabled, users]);

	const emailOptions: OptionsType = users.map(u => ({value: u.Id, label: u.GetLabel('Email', t)}));
	const smsOptions: OptionsType = users.map(u => ({value: u.Id, label: u.GetLabel('Mobile', t)}));

	/**
	 * Reload users in case only the ids are provided
	 * @param partialUsers
	 */
	function reloadUsers(partialUsers: User[]): User[] {
		return partialUsers?.map(partialUser => users.find(u => u.Id == partialUser.Id));
	}

	function asArray(values: number | number[]): number[] {
		if (!values) {
			values = [];
		} else if (!Array.isArray(values)) {
			values = [values];
		}

		return values;
	}

	return (
		<>
			<h5>{t('sensorWizard.general.alarmNotification').toString()}</h5>
			<Form.Item wrapperCol={colProps} name={'recipientSelectEmailEnabled'}>
				<Checkbox
					checked={formState.email.enabled}
					disabled={props.disabled}
					onChange={e => setFormState({...formState, email: {...formState.email, enabled: e.target.checked}})}
				>
					{t('sensorWizard.recipients.email').toString()}
				</Checkbox>
			</Form.Item>
			<Form.Item
				initialValue={formState.email.recipients}
				label={t('sensorWizard.recipients.recipients').toString()}
				rules={[{required: formState.email.enabled}]}
				name={emailSelectName}
			>
				<Select
					disabled={props.disabled || !formState.email.enabled}
					mode={'multiple'}
					allowClear={true}
					options={emailOptions}
					onChange={(values, _opts) =>
						setFormState({
							...formState,
							email: {...formState.email, recipients: asArray(values).map(id => users.find(u => u.Id == id))},
						})
					}
				></Select>
			</Form.Item>
			{smsSettings.Allow && (
				<>
					<Form.Item wrapperCol={colProps} name={'recipientSelectSmsEnabled'}>
						<Checkbox
							checked={formState.sms.enabled}
							disabled={props.disabled}
							onChange={e => setFormState({...formState, sms: {...formState.sms, enabled: e.target.checked}})}
						>
							{t('sensorWizard.recipients.sms').toString()}
						</Checkbox>
					</Form.Item>
					<Form.Item
						initialValue={formState.sms.recipients}
						label={t('sensorWizard.recipients.recipients').toString()}
						rules={[{required: formState.sms.enabled}]}
						name={smsSelectName}
					>
						<Select
							disabled={props.disabled || !formState.sms.enabled}
							mode={smsSettings.AllowMulti ? 'multiple' : undefined}
							allowClear={true}
							options={smsOptions}
							onChange={(values, _opts) =>
								setFormState({
									...formState,
									sms: {...formState.sms, recipients: asArray(values).map(id => users.find(u => u.Id == id))},
								})
							}
						></Select>
					</Form.Item>
				</>
			)}
		</>
	);
}
