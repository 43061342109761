import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Tag} from 'antd';
import {ROLE_TAG_COLOR} from '../Constants';
//const {USER_MGT_MAX_ROLE_OPERATOR, USER_MGT_MAX_ROLE_BASIC} = process.env;

const Wrapper = styled.div`
	margin-left: auto;
`;

const UserLimitCount = ({
	operatorRoleCount,
	basicRoleCount,
	operatorplusRoleCount,
	operatorMaxRoles,
	basicMaxRoles,
	operatorplusMaxRoles,
}) => {
	const [operatorCount, setOperatorCount] = useState(operatorRoleCount);
	const [basicCount, setBasicCount] = useState(basicRoleCount);
	const [operatorplusCount, setOperatorplusCount] = useState(operatorplusRoleCount);

	useEffect(() => {
		setBasicCount(basicRoleCount);
	}, [basicRoleCount]);

	useEffect(() => {
		setOperatorCount(operatorRoleCount);
	}, [operatorRoleCount]);

	useEffect(() => {
		setOperatorplusCount(operatorplusRoleCount);
	}, [operatorplusRoleCount]);

	return (
		<Wrapper>
			{operatorplusMaxRoles > 0 ? (
				<Tag color={ROLE_TAG_COLOR.OPERATORPLUS} style={{padding: '5px'}}>
					OperatorPlus Users: {operatorplusCount}/{operatorplusMaxRoles}
				</Tag>
			) : null}

			<Tag color={ROLE_TAG_COLOR.OPERATOR} style={{padding: '5px'}}>
				Operator Users: {operatorCount}/{operatorMaxRoles}
			</Tag>
			<Tag color={ROLE_TAG_COLOR.BASIC} style={{padding: '5px'}}>
				Basic Users: {basicCount}/{basicMaxRoles}
			</Tag>
		</Wrapper>
	);
};

export default UserLimitCount;
