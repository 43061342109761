import React from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Message from '../../../Shared/Components/Message';
import styled from 'styled-components';
import {renderToStaticMarkup} from 'react-dom/server';
import {FeatureFlag} from '../../../../common/helpers';
import {User} from '../../../../common/types';
import { CurrentPriceResult } from '../../../../common/util/LicenseUtils/CurrentPriceExtension';

const StyledWarning = styled.div`
	font-size: 15px;
	line-height: 30px;
	text-align: center;
	& strong {
		font-weight: bold;
	}
`;

// Martin: move Rubens code from RegistryService here
// https://www.postgresql.org/docs/11/errcodes-appendix.html
// http://postgrest.org/en/v5.2/api.html#http-status-codes
function translatePostgresqlErrorCode(sqlState) {
	let defaultReturnCode = 500;
	switch (sqlState) {
		case '23503': // foreign key violation
		case '23505': // uniqueness violation
			return 409;
		case 'P0001':
			return 400;
		default:
			return defaultReturnCode;
	}
}

function formatRegistryException(exception) {
	if (translatePostgresqlErrorCode !== null) {
		let code = translatePostgresqlErrorCode(exception.code ? exception.code : 0);
		switch (code) {
			case 409: // duplicated
				//returns constraint name - like included in the following text:
				//"duplicate key value violates unique constraint "sensors_org_id_name_key""
				let constraintName = exception.message.match(/"(.*?)"/)[1];
				let columnName = getColumnOfDuplicatedKey(constraintName);
				let detail = exception.details != null ? exception.details : exception.message;
				return formatDuplicatedException(detail, columnName);
			default:
				return;
		}
	}
}

function formatDuplicatedException(detail, column) {
	return {
		detail: detail,
		column: column,
	};
}

function getColumnOfDuplicatedKey(constrainName) {
	if (constrainName.indexOf('modules_org_id_serial_number_key') > -1) {
		return 'serial_number';
	} else if (constrainName.indexOf('sensors_org_id_name_key') > -1) {
		return 'sensor_name';
	} else if (constrainName.indexOf('sensor_limit_alarm_unique_constraint') > -1) {
		return 'sensor_limit_alarm_name';
	} else if (constrainName.indexOf('sensor_issue_alarm_unique_constraint') > -1) {
		return 'sensor_issue_alarm_name';
	}
}

//error from registry service, from pg exception
//registry service formats the error coming from postres
//in such a way:
// new ObjectResult(problemDetails)
//                 {
//                     ContentTypes = { "application/problem+json" },
//                     StatusCode {
//                         Status = TranslatePostgresqlErrorCode(ex.SqlState),
//                         Detail = ex.Detail != null ? ex.Detail : ex.MessageText,
//                     };
//                 };

//and just interested in the exception text actually
function pg_err_detail(pg_error_obj) {
	//check if it is a postgres styled error / from registryservice
	if (pg_error_obj && pg_error_obj.data && pg_error_obj.data.detail) {
		return pg_error_obj.data.detail;
	} else {
		//fall back its a different error not that style
		return pg_error_obj;
	}
}

function logPublishAndToast(error, pubishDataFunc, doneWhat) {
	if (error.data) {
		const exception = formatRegistryException(error.data);
		pubishDataFunc(exception);
	}
	Message.error('Error', 'Error ' + doneWhat + ' Module', pg_err_detail(error));
}

const RegistryHelper = {
	logPublishAndToast,
};

export default RegistryHelper;

export const showPriceWarning = (priceObject: CurrentPriceResult, callback) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		title: 'Attention',
		html: renderToStaticMarkup(
			<StyledWarning>
				Your credit card will be charged with the total amount of
				<br />
				<strong>{priceObject?.totalAmount + ' ' + priceObject?.currency + '/' + priceObject?.interval}</strong>
			</StyledWarning>
		),
		icon: 'info',
		showCancelButton: true,
		confirmButtonColor: '#d33',
		cancelButtonColor: '#3085d6',
		focusConfirm: true,
	}).then(result => {
		if (result.value) {
			callback();
		}
	});
};

export const showNoPhoneNumberWarning = (callback, list: string[] | User[]) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		title: 'Continue?',
		html: renderToStaticMarkup(
			<div>
				<StyledWarning>Following user(s) will not receive notifications, until a phone number is added:</StyledWarning>
				<div>
					{
						<StyledWarning>
							{list.map(x => {
								if (x instanceof User) {
									return (
										<div>
											{x.FirstName} {x.LastName}
										</div>
									);
								}
								return <div>{x}</div>;
							})}
						</StyledWarning>
					}
				</div>
			</div>
		),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#d33',
		cancelButtonColor: '#3085d6',
		focusCancel: true,
	}).then(result => {
		if (result.value) {
			callback();
		}
	});
};

export const showLiberoGWarning = (priceObject, featureFlags, callback) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire(
		featureFlags && featureFlags.get(FeatureFlag.Licensing) == true
			? {
					title: "<span id='show-libero-g-warning'>Are you sure?</span>",
					html: renderToStaticMarkup(
						<StyledWarning>
							The configuration can not be changed, until a new run has started
							<br />
							Your credit card will be charged with the total amount of
							<br />
							<strong>{priceObject?.totalAmount + ' ' + priceObject?.currency + '/' + priceObject?.interval}</strong>
						</StyledWarning>
					),
					icon: 'info',
					showCancelButton: true,
					confirmButtonColor: '#d33',
					cancelButtonColor: '#3085d6',
					focusCancel: true,
			  }
			: {
					title: "<span id='show-libero-g-warning'>Are you sure?</span>",
					html: renderToStaticMarkup(
						<StyledWarning>The configuration can not be changed, until a new run has started</StyledWarning>
					),
					icon: 'info',
					showCancelButton: true,
					confirmButtonColor: '#d33',
					cancelButtonColor: '#3085d6',
					focusCancel: true,
			  }
	).then(result => {
		if (result.value) {
			callback();
		}
	});
};
