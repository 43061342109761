import React from 'react';
import LoadingTie from './LoadingTie';

const DeviationDurationTie = props => {
    return (
        <div className="card card-default" style={{height: '100%'}} id="deviationDuration">
            <div className="card-body text-center">
                {props.deviation_duration.duration ? (
                    <>
                        <h4>Duration of Deviation</h4>
                        <br />
                        <h3>{props.deviation_duration.duration}</h3>
                        <br />
                    </>
                ) : (
                    <LoadingTie />
                )}
            </div>
        </div>
    );
};

export default DeviationDurationTie;
