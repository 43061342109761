import React from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import {Trans} from 'react-i18next';
import styled from 'styled-components';
import {SensorStep} from '../Dashboard/Sensor/AddSensor/GetSensorSteps';

const circleDiameter: number = 80;

const RadialStepsHeader = styled.div`
	height: ${circleDiameter}px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	& svg {
		width: ${circleDiameter}px;
	}
	& div {
		margin-right: 20px;
	}
`;

const RadialSteps = (props: {steps: SensorStep[]; current_step: number; uncounted_steps: number}) => {
	const totalSteps = props.steps.filter(s => !s.disabled).length - props.uncounted_steps;
	const currentStep = props.steps.slice(0, props.current_step).filter(s => !s.disabled).length;
	const getPercentage = () => {
		return ((currentStep - props.uncounted_steps) / (totalSteps - 1)) * 100;
	};

	const getStepsText = () => {
		return currentStep - props.uncounted_steps + 1 + ' of ' + totalSteps;
	};

	return (
		<RadialStepsHeader>
			<div>
				{props.current_step >= props.uncounted_steps && <CircularProgressbar value={getPercentage()} text={getStepsText()} />}
			</div>
			<div>
				<h4>
					<Trans i18nKey={props.steps[props.current_step].text} defaults={props.steps[props.current_step].text} />
				</h4>
			</div>
		</RadialStepsHeader>
	);
};

export default RadialSteps;
