import {SideBarDef} from 'ag-grid-community';

export const GridSideBar: SideBarDef = {
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			minWidth: 225,
			width: 225,
			maxWidth: 225,
		},
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
			minWidth: 180,
			maxWidth: 400,
			width: 250,
		},
	],
	position: 'right',
};
