import IssueProfileService from '../../../../Services/IssueProfiles/IssueProfilesService';
import RequestLogger from '../../../../Infrastructure/Requests/Logger/RequestLogger';

export async function getIssueProfile(issueProfileId, parent) {
    IssueProfileService.getIssueProfile(issueProfileId, RequestLogger.createLogData('issue-profiles', 'load-issue-profile', 'onLoad'))
        .then(response => {
            parent.setState({
                issueProfile: response.data,
                sensors: response.data.sensors,
                initialFormValues: response.data,
            });
        })
        .catch(error => console.log('error ', error));
}
