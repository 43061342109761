import {ColProps, FormProps} from 'antd';

export const colProps: ColProps = {
	lg: 24,
	xl: 16,
	xxl: 8,
};
export const formProps: FormProps = {
	initialValues: {remember: true},
	layout: 'vertical',
	wrapperCol: colProps,
};
