import React, {createContext, useState, useMemo} from 'react';
import {secureToken} from '../Utils/';

const FeatureFlagsContext = createContext(null);
const FeatureFlagsDispatchContext = createContext(null);

const FeatureFlagsProvider = ({children}) => {
	const [featureFlags, setFeatureFlags] = useState(secureToken.getFeatureFlagsToken() || []);
	useMemo(() => {
		return {featureFlags, setFeatureFlags};
	}, [featureFlags]);

	return (
		<FeatureFlagsContext.Provider value={featureFlags}>
			<FeatureFlagsDispatchContext.Provider value={setFeatureFlags}>{children}</FeatureFlagsDispatchContext.Provider>
		</FeatureFlagsContext.Provider>
	);
};

export {FeatureFlagsProvider, FeatureFlagsContext, FeatureFlagsDispatchContext};
