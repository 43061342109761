import request from '../../Infrastructure/Requests/Request';
import Authentication from '../../Infrastructure/Authentication/Authentication';
const useLocalhost = process.env?.NODE_ENV === 'development' && process.env.REACT_APP_SERVICE_USE_LOCALHOST?.includes('BILLING_SERVICE');

const licensingBaseUrl: string = useLocalhost ? 'https://localhost:44381' : '';

function invoices(eventData) {
	return request({
		url: '/invoices',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function prepaidAmountAndLastRecharge(eventData) {
	return request({
		url: '/rpc/organization_prepaid_amount_last_recharge',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}
function checkVoucher(voucher, eventData) {
	return request({
		url: '/rpc/check_voucher',
		method: 'POST',
		data: voucher,
		token: Authentication.getToken(),
		eventData,
	});
}

async function getOffering(payload, eventData) {
	return request({
		url: `${licensingBaseUrl}/paymentInformation`,
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

async function getOfferings(payload, eventData) {
	return request({
		url: `${licensingBaseUrl}/paymentInformation/multi`,
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

function getOfferingUpgrade(payload, eventData) {
	return request({
		url: `${licensingBaseUrl}/paymentInformation/upgrade`,
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

function getOfferingCurrentSensor(payload) {
	return request({
		url: `${licensingBaseUrl}/paymentInformation/sensor`,
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
	});
}

async function getOfferingOneYearRetirementForSensor(payload, eventData) {
	return request({
		url: `${licensingBaseUrl}/paymentInformation/sensorOneYearRetirement`,
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

function getOfferingsOneYearRetirementForSensors(payload, eventData) {
	return request({
		url: `${licensingBaseUrl}/paymentInformation/sensorOneYearRetirement/multi`,
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

function updateCreditCard(payload, eventData) {
	return request({
		url: '/rpc/credit_cards_set_default',
		method: 'POST',
		data: {_credit_cards_id: payload.id},
		token: Authentication.getToken(),
		eventData,
	});
}

const BillingService = {
	updateCreditCard,
	invoices,
	checkVoucher,
	getOffering,
	getOfferings,
	getOfferingUpgrade,
	getOfferingCurrentSensor,
	getOfferingOneYearRetirementForSensor,
	getOfferingsOneYearRetirementForSensors,
	prepaidAmountAndLastRecharge,
};

export default BillingService;
