import React from 'react';
import {FormGroup} from 'reactstrap';
import PropTypes from 'prop-types';
import FormValidator from './FormValidator';
import CharsCounter from './Shared/CharsCounter';

function FormControl({name, type, value, inValid, mandatory, disabled, onChange, onError, children, ...rest}) {
    const inputElement = React.useRef();

    const [errors, setErrors] = React.useState([]);
    const [showErrors, setShowErrors] = React.useState(false);
    const [isFocused, setIsFocused] = React.useState(false);

    /**
     * Side effect to handle control errors
     */
    React.useEffect(() => {
        if (!disabled) {
            if (!value && mandatory === false) {
                onError({[name]: false});
                setErrors([]);
            } else {
                const [hasError, resultError] = getInputError();

                if (hasError[name] || !inValid) setErrors(resultError);
                else if (!!inValid) setErrors([inValid]);

                onError(hasError);
            }
        } else {
            onError({[name]: false});
            setErrors([]);
        }

        // onChange({ [rest.id]: value });
    }, [value, inValid, disabled]);

    /**
     * Returns [hasErrors, errors]
     */
    const getInputError = () => {
        const validationResult = FormValidator.validate(inputElement.current);
        const resultKeys = Object.keys(validationResult).filter(val => validationResult[val]);

        return [{[name]: resultKeys.length > 0}, resultKeys];
    };

    /**
     * Return data-validate atrribue value
     */
    const dataToValidate = React.useCallback(() => {
        let dataToValidate = [];

        if (mandatory) dataToValidate.push('required');
        switch (type) {
            case 'text':
                if (!!rest.subtype && rest.subtype !== 'text') dataToValidate.push(rest.subtype);
                if (!!rest.max_length) dataToValidate.push('maxlen');
                break;
            case 'editableDropdown':
                if (!!rest.max_length) dataToValidate.push('maxlen');
                break;
            case 'number':
                if (!!rest.range && (!!rest.range || !!rest.range)) dataToValidate.push('minmax');
                break;
            case 'dropdown':
                break;
            case 'checkbox':
                break;
            default:
                break;
        }

        return JSON.stringify(dataToValidate);
    }, []);

    const handleChange = e => {
        let nextValue = type === 'checkbox' ? !value : e.target.value;

        if (type === 'number' || rest.subtype === 'number') {
            nextValue = parseInt(nextValue, 10);
        }

        onChange({[rest.id]: nextValue});
    };

    return (
        <>
            <FormGroup>
                {children(
                    {
                        value,
                        errors,
                        dataToValidate: dataToValidate(),
                        handleChange,
                        handleFocus: () => setIsFocused(true), // Mybe hide errors when focus
                        handleBlur: () => {
                            setIsFocused(false);
                            setShowErrors(true);
                        },
                        showErrors,
                    },
                    inputElement
                )}
            </FormGroup>

            {isFocused && !!rest.max_length && ['text', 'editableDropdown'].includes(type) && (
                <CharsCounter value={value} maxLength={rest.max_length} />
            )}
        </>
    );
}

FormControl.propTypes = {
    onChange: PropTypes.func,
    onError: PropTypes.func,
    children: PropTypes.func,
};

FormControl.defaultProps = {
    onChange: () => {},
    onError: () => {},
    children: () => {},
};

export default FormControl;
