import React, {useEffect, useState} from 'react';
import {Button} from 'reactstrap';

const DeviationsListAcknowledge = ({id, onClick, count, identifier}) => {

    const [disable, setDisable] = useState(false);

    useEffect(() => {
        setDisable(count === 0);
    }, [count]);

    return (
        <Button
            id={id}
            className="btn btn-primary"
            color="primary"
            size="sm"
            onClick={(e) => onClick(e, identifier)}
            style={{marginBottom: '10px'}}
            disabled={disable}
        >
            <span>Acknowledge ({count})</span>
        </Button>
    );
};

export default DeviationsListAcknowledge;