import request from '../Requests/Request';
import axios from 'axios';

let config = {};

function getTimeZoneOffset(id) {
    return request({
        url: '/timezoneInformation?timezoneId=' + id,
        method: 'GET',
    });
}

function getTimeZones(eventData) {
    return request({
        url: '/timezones',
        method: 'GET',
        eventData,
    });
}

function getCountries(eventData) {
    return request({
        url: '/countries?order=display_name',
        method: 'GET',
        eventData,
    });
}

function getCountryStates() {
    return request({
        url: '/us_states?order=display_name',
        method: 'GET',
    });
}

function loadConfig() {
    return axios.get('/config.json');
}

function setConfig(data) {
    config = data;
}

function getConfig() {
    return config;
}

const systemSettings = {
    getTimeZones,
    getTimeZoneOffset,
    getCountries,
    getCountryStates,
    loadConfig,
    setConfig,
    getConfig,
};

export default systemSettings;
