import {Button, Col, notification, Row} from 'antd';
import React from 'react';
import {ColumnApi, GridApi} from 'ag-grid-enterprise';
import {Sensor} from '../Common/Types/Sensor';
import styles from './styles.module.scss';
import {UpdateDataGridSettings} from '../../common/services/WebserverServicePro/UserSettingsService';

export const SettingsSaveComponent = (props: {api: GridApi<Sensor>; columnApi: ColumnApi}): React.JSX.Element => {
	if (props.api == null || props.columnApi == null) {
		return null;
	}

	const [notificationApi, notificationContext] = notification.useNotification();
	function onSaveViewClick({api, columnApi}) {
		const columnState = columnApi.getColumnState();
		const filterModel = api.getFilterModel();

		UpdateDataGridSettings({columnSettings: columnState, filterSettings: filterModel})
			.then(_ => notificationApi.success({message: 'Settings saved successfully'}))
			.catch(_ => notificationApi.error({message: 'Settings could not be saved'}));
	}

	function onResetViewClick({api, columnApi}) {
		columnApi.resetColumnState();
		api.setFilterModel(undefined);
	}

	return (
		<Row className={styles.SettingsSaveRow} align={'middle'} gutter={8}>
			{notificationContext}
			<Col>
				<Button id={'SaveButton'} type={'primary'} onClick={() => onSaveViewClick(props)}>
					Save View
				</Button>
			</Col>
			<Col>
				<Button id={'ResetButton'} onClick={() => onResetViewClick(props)}>
					Reset View
				</Button>
			</Col>
		</Row>
	);
};
