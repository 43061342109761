import {MODULE_FAMILIES} from '../Constants/Module';

const findFamilyTypeFromTemplateName = templateName => {
	let moduleFamilyName = null;

	if (!!templateName && templateName.includes('ECOLOG-PRO')) {
		moduleFamilyName = MODULE_FAMILIES.ECOLOG_PRO_G;
	}
	if (!!templateName && templateName.includes('LIBERO')) {
		moduleFamilyName = MODULE_FAMILIES.LIBERO_G;
	}
	return moduleFamilyName;
};
export default findFamilyTypeFromTemplateName;
