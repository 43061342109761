import React from 'react';

import {Alert} from 'reactstrap';
import Shared from '../../../Shared/Shared';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CreditCardElement from '../../../Common/CreditCardElement';
import SystemSettings from '../../../Infrastructure/Settings/SystemSettings';

import InputVoucher from './InputVoucher';
import BillingService from '../../../Settings/Billing/BillingService';
import {withTranslation} from 'react-i18next';
import {GetCreditCards} from '../../../../common/services/WebserverServicePro/CreditCardService';

interface InputPaymentMethodState {
	paymentMethodSettings: any;
	stripe: any;
	credit_card: any;
	creditCards: any[];
	prepaidAmount: any;
	creditCardErrorMessage: any;
	usePrepaidCredit: any;
}

class InputPaymentMethod extends React.Component<any, InputPaymentMethodState> {
	creditCardElement: any;

	constructor(props) {
		super(props);
		this.state = {
			paymentMethodSettings: this.props.paymentMethodSettings,
			stripe: null,
			credit_card: 0,
			creditCards: [],
			prepaidAmount: 0,
			creditCardErrorMessage: undefined,
			usePrepaidCredit: undefined,
		};
		this.creditCardElement = null;
	}

	componentDidMount() {
		this.setState({stripe: window['Stripe'](SystemSettings.getConfig().stripe_pk)});
		let that = this;

		const gets = [GetCreditCards(), BillingService.prepaidAmountAndLastRecharge(undefined)];
		Promise.all(gets)
			.then(responses => {
				let amount = responses[1].data.amount;

				let responseCreditCards = [];
				responses[0].forEach(function (item) {
					responseCreditCards.push({
						id: item.id,
						disabled: false,
						default_card: item.defaultCard,
						display_text: Shared.formatCreditCard(item),
					});
				});

				const prepaid_enabled = responses[1].data.prepaid_enabled;

				const defaultCard = responseCreditCards.find(card => card.default_card === true);
				const default_card_id = defaultCard ? defaultCard.id : prepaid_enabled ? 9999 : null;

				that.setState(
					prevState => ({
						...prevState,
						prepaidAmount: amount,
						usePrepaidCredit: prepaid_enabled,
						credit_card: default_card_id,
						creditCards: responseCreditCards,
					}),
					() => this.props.afterPaymentMethodAltered(null, default_card_id === 9999)
				);
			})
			.catch(function (error) {
				console.log(error);
			});

		this.props.onRef(this);
	}

	setCreditCardError = errorMessage => {
		this.setState({creditCardErrorMessage: errorMessage});
	};

	buildCreditCard = () => {
		let creditCard = this.creditCardElement;
		return {
			id: this.state.credit_card,
			credit_card_number: this.state.credit_card === 0 ? creditCard.token.card.last4 : undefined,
			name_on_card: this.state.credit_card === 0 ? creditCard.token.card.name : undefined,
			valid_until: this.state.credit_card === 0 ? creditCard.token.card.exp_month + '/' + creditCard.token.card.exp_year : undefined,
			client_ip: this.state.credit_card === 0 ? creditCard.token.client_ip : undefined,
			stripe_token: this.state.credit_card === 0 ? creditCard.token.id : undefined,
		};
	};

	setCreditCardElementAndInvokeIfOK = (methodToInvoke, actualPrice) => {
		let creditCard = this.creditCardElement;
		// todo clear errors
		if (this.state.credit_card && this.state.credit_card === 0) {
			// Create credit card
			this.createTokenAndInvokeMethod(creditCard, methodToInvoke);
		} else {
			let redeem_prepaid = this.state.credit_card === 9999;
			if (redeem_prepaid && actualPrice > 0) {
				const tr = this.props.t;
				this.setCreditCardError(tr('error.prepaidCreditInsufficient'));
			} else {
				if (!redeem_prepaid && this.state.credit_card === 0) {
					this.createTokenAndInvokeMethod(creditCard, methodToInvoke);
				} else {
					methodToInvoke({
						id: redeem_prepaid ? 0 : this.state.credit_card,
						credit_card_number: undefined,
						name_on_card: undefined,
						valid_until: undefined,
						client_ip: undefined,
						stripe_token: undefined,
						redeem_prepaid: redeem_prepaid,
					});
				}
			}
		}
	};

	handlePaymentMethodChange = event => {
		const input = event.target;
		let newInputValue = parseInt(input.value);
		this.setState(
			{
				credit_card: newInputValue,
				creditCardErrorMessage: undefined,
			},
			() => this.props.afterPaymentMethodAltered(null, this.state.credit_card === 9999)
		);
	};

	isPrepaidSelected = () => {
		return this.state.credit_card === 9999;
	};

	createTokenAndInvokeMethod = (creditCard, methodToInvoke) => {
		const tr = this.props.t;

		if (creditCard.isStripeModelEmpty()) {
			this.setCreditCardError(tr('error.cardInformationEmpty'));
		} else if (creditCard.isNameOnCardEmpty()) {
			this.setCreditCardError(tr('error.nameOnCardEmpty'));
		} else {
			creditCard
				.createToken()
				.then(response => {
					if (response.error) {
						this.setCreditCardError(response.error.message);
					} else {
						this.setCreditCardError(undefined);
						methodToInvoke({
							id: 0,
							credit_card_number: response.token.card.last4,
							name_on_card: response.token.card.name,
							valid_until: response.token.card.exp_month + '/' + response.token.card.exp_year,
							client_ip: response.token.client_ip,
							stripe_token: response.token.id,
							redeem_prepaid: false,
						});
					}
				})
				.catch(error => {
					this.setCreditCardError(error.message);
				});
		}
	};

	render() {
		return (
			//Fragment
			<React.Fragment>
				<h3 style={{fontWeight: 'normal'}}>Payment method</h3>
				<select
					id="selectCreditCard"
					className="form-control"
					name="selectPaymentMethod"
					value={this.state.credit_card}
					onChange={this.handlePaymentMethodChange}
				>
					{this.state.usePrepaidCredit ? (
						<option key="9999" value="9999">
							{'Prepaid Credit CHF ' + this.state.prepaidAmount.toFixed(2)}
						</option>
					) : null}
					{this.props.withNewCard ? <option value={0}>New credit card</option> : null}
					{this.state.creditCards.map(creditCard => (
						<option key={creditCard.id} value={creditCard.id}>
							{creditCard.display_text}
						</option>
					))}
				</select>

				{this.props.withNewCard && this.state.stripe && this.state.credit_card === 0 ? (
					<StripeProvider stripe={this.state.stripe}>
						<Elements ref="stripe_elements">
							<CreditCardElement formType="summary" onRef={ref => (this.creditCardElement = ref)} />
						</Elements>
					</StripeProvider>
				) : null}
				<div>
					<br />
					{this.state.creditCardErrorMessage ? (
						<Alert color="danger text-center">{this.state.creditCardErrorMessage}</Alert>
					) : null}
				</div>
				{this.state.paymentMethodSettings.showVoucher ? (
					<>
						<h3 style={{fontWeight: 'normal'}}>Voucher</h3>
						<InputVoucher
							id="inputVoucher"
							className="form-control"
							mask="####-####-####"
							vouchers={this.props.vouchers}
							afterVoucherChecked={voucher => {
								let redeem_prepaid = this.isPrepaidSelected();
								this.props.afterPaymentMethodAltered(voucher, redeem_prepaid);
							}}
						/>
					</>
				) : null}
			</React.Fragment>
		);
	}
}

export default withTranslation()(InputPaymentMethod);
