import LimitProfilesExtension from '../../../../Services/Extensions/LimitProfiles';
import RequestLogger from '../../../../Infrastructure/Requests/Logger/RequestLogger';
import Message from '../../../../Shared/Components/Message';
import {getLimitProfiles} from '../../Queries/LimitProfilesQuery';

// Copy
export function copyLimitProfile(limitProfile, parent) {
    LimitProfilesExtension.saveProfile(limitProfile, RequestLogger.createLogData('limit-profiles', 'copy-limit-profile', 'onClick'))
        .then(() => {
            Message.success('Limit profile copied', 'Limit profile successfully copied');
            getLimitProfiles(parent);
        })
        .catch(error => {
            Message.error('Error', 'Limit profile could not be copied', error.data);
        });
}

// Save
export function saveLimitProfile(limitProfile, parent) {
    LimitProfilesExtension.saveProfile(limitProfile, RequestLogger.createLogData('limit-profiles', 'save-limit-profile', 'onClick'))
        .then(() => {
            Message.success('Limit profile added', 'Limit profile successfully added');
            parent.props.history.push('/limitProfiles');
        })
        .catch(error => {
            Message.error('Error', 'Limit profile could not be added', error.data);
        });
}

// Update
export function updateLimitProfile(limitProfile, parent) {
    LimitProfilesExtension.updateProfile(limitProfile, RequestLogger.createLogData('limit-profiles', 'update-limit-profile', 'onClick'))
        .then(() => {
            Message.success('Limit profile updated', 'Limit profile successfully updated');
            parent.props.history.push('/limitProfiles');
        })
        .catch(error => {
            Message.error('Error', 'Limit profile could not be updated', error.data);
        });
}
