import {DeviationStatus} from './DeviationStatus';
import {SensorType} from './SensorType';
import SensorsDashboardInfo from '../../Services/SensorsDashboardInfo/SensorsDashboardInfo';
import {Measurement} from './Measurement';
import {MeasurementNew} from './WebSocket/MeasurementNew';
import {CalibrationState} from '../../Calibration/Model/CalibrationState';
import {AlarmState, DeviceState} from '../../../common/constants';
import {CONSTANTS} from '../../Shared/Constants/Constants';
import {RunInfo, RunInfoSummary, SensorGroup} from '../../../common/types';
import dayjs from 'dayjs';
import {DeviceUtils} from '../../../common/util';
import {ToOutUnit} from '../../../common/util/MeasurementValueUtils';

export class Sensor {
	constructor(props: Partial<SensorsDashboardInfo>) {
		if (props.additional_configuration_metadata) {
			this.AdditionalConfigMetadata = [];
			const m = props.additional_configuration_metadata;
			[m.metadata_1, m.metadata_2, m.metadata_3, m.metadata_4, m.metadata_5, m.metadata_6, m.metadata_7, m.metadata_8].forEach(d =>
				this.AdditionalConfigMetadata.push(d)
			);
		}

		this.BackgroundColor = props.background_color;
		this.ChannelNumber = props.channel_number;
		this.CustomerId = props.customer_id;
		this.GroupIds = props.sensors_groups_ids ? props.sensors_groups_ids : [];
		this.HasLimits = props.has_limits;
		this.Id = props.id;
		this.IsBlurred = props.is_blurred;
		this.IsMultiRun = props.multirun;
		this.IsMuted = props.muted;
		this.IsPairing = undefined;
		this.IsRetired = props.is_retired;
		this.LastMeasurement = undefined;
		this.LatchActive = props.latch_active;
		this.LatchedStatus = props.latched_status;
		this.LiberoGStateDescription = props.libero_g_state_description;
		this.LimitLower = props.lower_limit;
		this.LimitUpper = props.upper_limit;
		this.LoggingInterval = props.logging_interval;
		this.ModuleFamiliesId = props.module_families_id;
		this.ModulePrefix = props.module_prefix;
		this.ModuleShortName = props.module_short_name;
		this.MultiRunLockedConfiguration = props.mr_locked_configuration;
		this.MultiRunStartRunEnabled = props.multirun_start_run_enabled;
		this.MutingEnabled = props.muting_enabled;
		this.Name = props.name;
		this.OutUnitsId = props.out_units_id;
		this.SignalStrength = props.signal_strength;
		this.SerialNumber = props.serial_number;
		this.State = props.state;
		this.ValueIndex = props.value_index;
		this.ModuleId = props.module_id;

		if (props.measures_temperature) {
			this.SensorType = SensorType.Temperature;
		} else {
			this.SensorType = SensorType.Humidity;
		}
	}

	public AddNewMeasurement(data: MeasurementNew) {
		if (!data) {
			return;
		}

		const value = ToOutUnit(data.Value, this.OutUnitsId);
		this.LastMeasurement = new Measurement(data.Timestamp, value);
		this.Status = data.Status;
		this.Error = data.Error;

		if (DeviceUtils.IsEcologProRadio(this)) {
			if (data.Calibration) {
				this.CalibrationState = CalibrationState.Calibrating;
				this.CanStopCalibration = true;
				this.IsMuted = true;
			} else if (this.CalibrationState === CalibrationState.Calibrating || this.CalibrationState === CalibrationState.Stopping) {
				this.CalibrationState = CalibrationState.Inactive;
				this.CanStopCalibration = false;
				this.CanStartCalibration = true;
				this.IsMuted = false;
			}
		}
	}

	public CanStartNewRun(): boolean {
		return this.IsMultiRun && this.MultiRunStartRunEnabled && !this.CalibrationState;
	}

	public GetLimitAlarmState(): AlarmState {
		if (this.IsMuted) {
			return AlarmState.Deactivated;
		}
		if (this.HasAlarm() || this.CalibrationState) {
			return AlarmState.Alarm;
		}
		if (this.HasLimits) {
			return AlarmState.Ok;
		}
		return AlarmState.None;
	}

	public GetDeviceState(): string {
		if (this.IsBlurred) {
			return DeviceState.Blurred;
		}

		if (this.IsRetired) {
			return DeviceState.Retired;
		}

		if (this.CalibrationState) {
			return DeviceState[`CALIBRATION_${this.CalibrationState}`];
		}

		if (this.LiberoGStateDescription) {
			return this.LiberoGStateDescription;
		}

		if ([DeviceState.Prepared, DeviceState.Running].some(v => v.localeCompare(this.State, undefined, {sensitivity: 'accent'}))) {
			return this.State;
		}

		return CONSTANTS.DEFAULT;
	}

	public HasAlarm(): boolean {
		if (this.IsMuted) {
			return false;
		}
		return this.HasLatchAlarm() || this.HasActiveDeviationAlarm();
	}

	public HasActiveDeviationAlarm(): boolean {
		return this.Deviations.filter(d => !d.Done && !d.Warning).length > 0;
	}

	public HasLatchAlarm(): boolean {
		return this.LatchActive && this.LatchedStatus;
	}

	public LastMeasurementOutsideLimits(): boolean {
		return (
			!!this.HasLimits &&
			!!this.LastMeasurement &&
			(this.LastMeasurement.Value < this.LimitLower || this.LastMeasurement.Value > this.LimitUpper)
		);
	}

	public SetGroups(sensorGroups: SensorGroup[]) {
		this.groups = sensorGroups;
	}

	public GetGroups(): SensorGroup[] {
		if (this.groups.length !== this.GroupIds.length) {
			console.error('Groups have not been loaded');
		}
		return this.groups;
	}

	public GetStartDate(): dayjs.Dayjs {
		return this.RunInfoSummary?.StartDate ?? this.Runs.at(-1)?.StartDate;
	}

	public GetRunCount(): number {
		return this.RunInfoSummary?.RunCount ?? this.Runs?.length;
	}

	private groups: SensorGroup[] = [];

	public AdditionalConfigMetadata: string[] | undefined = undefined;
	public AdditionalData: Map<string, string> = new Map<string, string>();
	public BackgroundColor: string;
	public ChannelNumber: number;
	public CustomerId: string;
	public Deviations: DeviationStatus[] = [];
	public Id: number;
	public IsBlurred: boolean;
	public IsMultiRun: boolean;
	public IsMuted: boolean;
	public IsPairing: boolean;
	public IsRetired: boolean;
	public GroupIds: number[];
	public HasLimits: boolean;
	public LastMeasurement: Measurement | undefined;
	public LatchActive: boolean;
	public LatchedStatus: boolean;
	public LiberoGStateDescription: string | null;
	public LimitLower: number;
	public LimitUpper: number;
	public LoggingInterval: number;
	public ModuleId: number;
	public ModuleFamiliesId: number;
	public ModulePrefix: string;
	public ModuleShortName: string;
	public MultiRunLockedConfiguration: boolean;
	public MultiRunStartRunEnabled: boolean;
	public MutingEnabled: boolean;
	public Name: string;
	public OutUnitsId: number;
	public Runs: RunInfo[] = [];
	public RunInfoSummary: RunInfoSummary = undefined;
	public SignalStrength: number | null;
	public SensorType: SensorType;
	public SerialNumber: string;
	public State: string;
	public Status: number = 0;
	public ValueIndex: number;
	public Error: string | null;
	public CalibrationState: CalibrationState;
	public CanStartCalibration: boolean;
	public CanStopCalibration: boolean;
}
