import LimitProfileService from '../../../Services/LimitProfiles/LimitProfilesService';
import {mapLimitProfilePropertiesToGrid} from '../Extensions/LimitProfilePropertiesToGridMapper';
import RequestLogger from '../../../Infrastructure/Requests/Logger/RequestLogger';

export const getLimitProfiles = (parent, skipMapToGrid = false) => {
    LimitProfileService.limitProfiles(RequestLogger.createLogData('limit-profiles', 'load-limit-profiles', 'onLoad'))
        .then(response => {
            const limitProfiles = response.data;

			if(skipMapToGrid) {
				parent.setState({limitProfiles: response.data});
			}
			else {
            	let returnValues = mapLimitProfilePropertiesToGrid(parent, limitProfiles);
            	parent.setState({rowData: [...returnValues[0]], limitProfiles: [...returnValues[1]]});
			}
        })
        .catch(error => console.log(error));
};
