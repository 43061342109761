import {MeasurementNew} from '../Common/Types/WebSocket/MeasurementNew';
import {GridApiRef} from './SensorDataGrid';
import {CalibrationStatusChanged, RunStatusChanged, SensorAlarmingEnabledChangedData} from '../Shared/Constants/Events';
import {Sensor} from '../Common/Types/Sensor';
import {RowNode} from 'ag-grid-community';
import {SensorServiceHelper} from '../../common/helpers';

function getRowNode({api, columnApi}: GridApiRef, sensorId: number): RowNode<Sensor> {
	if (!api || !columnApi) {
		return undefined;
	}
	return api.getRowNode(sensorId.toString());
}

export function onNewMeasurement(gridApiRef: GridApiRef, data: MeasurementNew) {
	const rowNode = getRowNode(gridApiRef, data.SensorId);
	if (!rowNode) {
		return;
	}
	let sensor = rowNode.data;
	sensor.AddNewMeasurement(data);
	rowNode.setData(sensor);
	gridApiRef.api.flashCells({rowNodes: [rowNode]});
}

export function onRunStatusChanged(gridApiRef: GridApiRef, data: RunStatusChanged) {
	const rowNode = getRowNode(gridApiRef, data.sensor_id);
	if (!rowNode) {
		return;
	}
	let sensor = rowNode.data;
	sensor.MultiRunLockedConfiguration = data.status;
	rowNode.setData(sensor);
	gridApiRef.api.flashCells({rowNodes: [rowNode]});
}

export function onSensorAlarmingEnabledChanged(gridApiRef: GridApiRef, data: SensorAlarmingEnabledChangedData) {
	const rowNode = getRowNode(gridApiRef, data.SensorId);
	if (!rowNode) {
		return;
	}
	let sensor = rowNode.data;
	sensor.IsMuted = !data.AlarmingEnabled;
	rowNode.setData(sensor);
	gridApiRef.api.flashCells({rowNodes: [rowNode]});
}

/**
 * NOTE: Not implemented yet, unclear, what the payload looks like and when this is sent. Also, the old data grid did not use it
 * @param gridApiRef
 * @param data
 */
export function onSensorStatusChanged(gridApiRef: GridApiRef, data) {
	console.log(data);
	const rowNode = getRowNode(gridApiRef, data.sensors_id);
	if (!rowNode) {
		return;
	}
}

export function onCalibrationStatusChanged(gridApiRef: GridApiRef, data: CalibrationStatusChanged) {
	if (!data.sensorId) {
		return;
	}

	const rowNode = getRowNode(gridApiRef, data.sensorId);
	if (!rowNode) {
		return;
	}

	SensorServiceHelper.GetSensorsWithData(data.sensorId).then(sensors => rowNode.setData(sensors[0]));
	gridApiRef.api.flashCells({rowNodes: [rowNode]});
}

export function onUpdateDashboardLiberoG(gridApiRef: GridApiRef, data: {sensor_id: number}) {
	if (!data.sensor_id) {
		return;
	}

	const rowNode = getRowNode(gridApiRef, data.sensor_id);
	if (!rowNode) {
		return;
	}

	SensorServiceHelper.GetSensorsWithData(data.sensor_id).then(sensors => rowNode.setData(sensors[0]));
	gridApiRef.api.flashCells({rowNodes: [rowNode]});
}
