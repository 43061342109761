import {SensorsGroupsRow} from './DbModel/SensorsGroupsRow';

export class SensorGroup {
	constructor(init: SensorsGroupsRow) {
		this.Id = init.id;
		this.OrgId = init.org_id;
		this.Memo = init.memo;
		this.Name = init.name;
		this.GroupOrder = init.group_order;
		this.ParentGroupId = init.sensors_group_parent;
	}

	Id: number;
	OrgId: number;
	Memo: string;
	Name: string;
	GroupOrder: number;
	ParentGroupId: number;
}
