import styled from "styled-components";

const color = '#E41919';

export const StyledLink = styled.a`
    color: ${color};
    text-decoration:none;
    transition: all .2s ease-in;
    border-color: transparent;
    border-style:solid;
    border-width: 0 0 1px 0;
      &:hover {
       border-color: ${color};
       color:  ${color};
       border-style:solid;
       border-width: 0 0 1px 0;
       text-decoration:none;
      }
`