import React, {useContext} from 'react';
import {Menu, MenuProps} from 'antd';
import {useTranslation} from 'react-i18next';
import styles from './styles/styles.module.scss';

import {authUtils} from '../Infrastructure/Authorization/Utils/index';
import {default as MenuData, MenuType} from '../../Menu';
import {AccessContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {useHistory} from 'react-router-dom';

export function NavigationMenu(): React.JSX.Element {
	const [t] = useTranslation();
	const history = useHistory();
	const accessContext = useContext(AccessContext);
	type MenuItem = Required<MenuProps>['items'][number];

	const getIcon = (menuItem: MenuType) => menuItem.icon && <span className={menuItem.icon} />;

	function mapMenuTypeToMenuItem(m: MenuType): MenuItem {
		if (
			(m.allowedRoles && !m.allowedRoles.some((role: string) => accessContext.user.access_settings.settings.roles.includes(role))) ||
			(m.accessPath && !authUtils.findAccessElement(m.accessPath, accessContext.user.access_settings.access).allow)
		) {
			return null;
		}

		return {
			key: m.name,
			label: t(m.translate).toString(),
			icon: getIcon(m),
			onClick: () => m.path && history.push(m.path),
			children: m.submenu?.map(s => mapMenuTypeToMenuItem(s)),
		};
	}

	const items: MenuItem[] = MenuData.map(m => mapMenuTypeToMenuItem(m)).filter(m => !!m);

	return <Menu items={items} theme={'dark'} mode={'inline'} className={styles.sidebarMenu} />;
}
