export class CalibrationSensor {
	public ID: number;
	public Name: string;
	public CustomerID: string;
	public ModuleID: string;
	public UnitID: number;

	constructor(id: number, name: string, customerId: string, moduleId: string, unitId: number) {
		this.ID = id;
		this.Name = name;
		this.CustomerID = customerId;
		this.ModuleID = moduleId;
		this.UnitID = unitId;
	}
}
