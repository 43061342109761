import FormUtils from '../../FormUtils';
import {getInfoTextValue} from '../../Shared/LCDDisplayHelper';

/**
 * Sections that needs special mapping
 */
export const SECTION_KEY = {
    ISSUE: 'additional_issues',
    DISPLAY: 'display_settings',
};

const mapToAdditionalConfiguration = (data, coreData = null) => {
    const {[SECTION_KEY.ISSUE]: add_issues, [SECTION_KEY.DISPLAY]: dis_setings, ...rest} = data;

    if ( !!coreData) {
        coreData.sensorBasicsForm.sensor_name = coreData.metadatasForm.sensor_name;
    }

    return {
        ...rest,

        configuration_templates_id: data.configuration_templates_id,
        
        light_detection: !!add_issues
            ? {
                  enable_low: add_issues.activate_lower_light_sensor_alarm,
                  enable_high: add_issues.activate_upper_light_sensor_alarm,
                  limit_low: add_issues.lower_light_sensor_alarm,
                  limit_high: add_issues.upper_light_sensor_alarm,
              }
            : data.light_detection,

        info_texts: !!dis_setings
            ? {
                  ...dis_setings,
                  ...Object.keys(dis_setings).reduce((acc, key) => {
                      if (Array.isArray(dis_setings[key])) {
                          let infoText = [];
                          dis_setings[key].forEach(itemValue => {
                              if (FormUtils.isPlaceHolder(itemValue)) {
                                  const placeHolderValue = FormUtils.getPlaceHolderValue({info_texts: dis_setings}, itemValue);
                                  const infoTextValue = getInfoTextValue(placeHolderValue);
                                  infoText = infoText.concat(infoTextValue);
                              } else {
                                  infoText.push(itemValue);
                              }

                              infoText = infoText.filter(v => !!v);
                          });
                          return {...acc, [key]: infoText};
                      }
                      return acc;
                  }, {}),
              }
            : data.info_texts,
    };
};

const mapToTemplateResult = (section, data, schema = {}) => {
    switch (section) {
        case SECTION_KEY.ISSUE:
            return {
                issue_alarms: !!data.light_detection
                    ? data.light_detection['enable_high'] ||
                      data.light_detection['enable_low'] ||
                      data.tilt_detection['enable'] ||
                      data.tilt_detection['enable']
                    : undefined,
                activate_upper_light_sensor_alarm: !!data.light_detection ? data.light_detection['enable_high'] : undefined,
                upper_light_sensor_alarm: !!data.light_detection ? data.light_detection['limit_high'] : undefined,
                activate_lower_light_sensor_alarm: !!data.light_detection ? data.light_detection['enable_low'] : undefined,
                lower_light_sensor_alarm: !!data.light_detection ? data.light_detection['limit_low'] : undefined,
            };

        case SECTION_KEY.DISPLAY:
            const in_transit_i = !!schema.log_transit ? schema.log_transit.default.indexOf('%info_texts.in_transit%') : undefined;

            return {
                in_transit: !!data.info_texts
                    ? data.info_texts['in_transit'] ||
                      (!!data.info_texts['log_transit']
                          ? (data.info_texts['log_transit'][in_transit_i] || '') + (data.info_texts['log_transit'][in_transit_i + 1] || '')
                          : undefined)
                    : undefined,
            };

        default:
            return data[section];
    }
};

export const EcologProGxMapperService = {
    mapToAdditionalConfiguration,
    mapToTemplateResult,
};