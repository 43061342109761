import request from '../../../components/Infrastructure/Requests/Request';
import {CheckoutSessionCreationRequest} from './Request/CheckoutSessionCreationRequest';
import {CheckoutSessionCreationResponse} from './Response/CheckoutSessionCreationResponse';
import {OfferRequest} from './Request/OfferRequest';
import {OfferResponse} from './Response/OfferResponse';
import {SubscriptionUpdateRequest} from './Request/SubscriptionUpdateRequest';
import {SubscriptionUpdateResponse} from './Response/SubscriptionUpdateResponse';
import {SubscriptionCancellationResponse} from './Response/SubscriptionCancellationResponse';
import {SubscriptionCancellationRequest} from './Request/SubscriptionCancellationRequest';
import {SubscriptionResponse} from './Response/SubscriptionResponse';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
import {SubscriptionItemsDeleteRequest} from './Request/SubscriptionItemsDeleteRequest';
import {SubscriptionItemsDeleteResponse} from './Response/SubscriptionItemsDeleteResponse';
import {LineItemTypeResponse} from './Response/LineItemTypeResponse';
import {getVariableName} from '../../../common/util/NameOfVariable';
import { PrepareQueryString } from '../../../components/Wizard/Util';
const useLocalhost = process.env?.NODE_ENV === 'development' && process.env.REACT_APP_SERVICE_USE_LOCALHOST?.includes('LICENSING_SERVICE');

const baseUrl: string = useLocalhost ? 'http://localhost:5000' : 'https://licensing.elcl.xyz';
const Route: string = `${baseUrl}/api/v1`;

export class LicensingService {
	static async GetSubscription(internalProductId: string): Promise<SubscriptionResponse> {
		try {
			return (
				await request({
					url: `${Route}/Subscription?internalProductId=${internalProductId}`,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async GetOffer(offerRequest: OfferRequest): Promise<OfferResponse> {
		try {
			return (
				await request({
					url: `${Route}/Offer`,
					method: 'POST',
					token: Authentication.getToken(),
					data: offerRequest,
				})
			).data;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async CreateCheckoutSession(
		checkoutSessionCreationRequest: CheckoutSessionCreationRequest
	): Promise<CheckoutSessionCreationResponse> {
		try {
			return (
				await request({
					url: `${Route}/CheckoutSession`,
					method: 'POST',
					token: Authentication.getToken(),
					data: checkoutSessionCreationRequest,
				})
			).data as CheckoutSessionCreationResponse;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async UpdateSubscription(subscriptionUpdateRequest: SubscriptionUpdateRequest): Promise<SubscriptionUpdateResponse> {
		try {
			return (
				await request({
					url: `${Route}/Subscription`,
					method: 'POST',
					token: Authentication.getToken(),
					data: subscriptionUpdateRequest,
				})
			).data as SubscriptionUpdateResponse;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async CancelSubscription(
		subscriptionCancellationRequest: SubscriptionCancellationRequest
	): Promise<SubscriptionCancellationResponse> {
		try {
			return (
				await request({
					url: `${Route}/Subscription`,
					method: 'DELETE',
					token: Authentication.getToken(),
					data: subscriptionCancellationRequest,
				})
			).data as SubscriptionUpdateResponse;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async DeleteSubscriptionItems(
		subscriptionItemsDeleteRequest: SubscriptionItemsDeleteRequest
	): Promise<SubscriptionItemsDeleteResponse> {
		try {
			return (
				await request({
					url: `${Route}/SubscriptionItems`,
					method: 'DELETE',
					token: Authentication.getToken(),
					data: subscriptionItemsDeleteRequest,
				})
			).data as SubscriptionItemsDeleteResponse;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async GetLineItemTypes(internalProductCategories: string[], internalProductTypes: string[]): Promise<LineItemTypeResponse[]> {
		try {
			const queryString = PrepareQueryString([
				{variableName: getVariableName({internalProductCategories}), items: internalProductCategories},
				{variableName: getVariableName({internalProductTypes}), items: internalProductTypes},
			]);

			return (
				await request({
					url: `${Route}/LineItemType?${queryString}`,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data as LineItemTypeResponse[];
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
