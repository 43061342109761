import React from 'react';
import { Trans } from "react-i18next";
import { Table } from "reactstrap";

const OfferingTable = (props) => {

    const isVoucherItem = (item) => {
        var patt = /\w{4}\-\w{4}\-\w{4}/g;
        return item.description && item.description.match(patt);
    }
    const hasVouchers = props.lines.some(isVoucherItem)

    return (
        <Table>
            {!props.loading ?
                <tbody>

                    {props.vat_amount > 0 ?
                        <tr>
                            <td>VAT</td>
                            <td align="right">CHF {props.vat_amount}</td>
                            { hasVouchers ? <td></td> : null }
                        </tr>
                        : null
                    }

                    {props.lines.map((item, index) => {
                        const transKey = item.description ? 'licence.' + item.description.replace(/\./g, '') : "";
                        let text = Trans({ i18nKey: transKey });

                        let amount = item.actual_price;
                        let isVoucher = isVoucherItem(item);
                        if (isVoucher) {
                            text = item.description; //voucher nr
                        }
                        if (isVoucher && props.show_voucher_price_as_positive_amount) {
                            amount = -amount;
                        }

                        return <tr key={index}>
                            <td>{text}</td>
                            <td align="right">CHF {amount}</td>
                            {hasVouchers ? <td>
                                {isVoucher ?
                                    <em
                                        className={'fs2 elpro-Delete'}
                                        onClick={()=>props.onRemoveElementByDescription(item.description)}
                                        style={{ fontSize: '13px', cursor: 'pointer' }}
                                    /> : null}
                            </td> : null}
                        </tr>;
                    })
                    }

                    <tr style={{ borderTop: '2px solid #b1b3b3' }}>
                        <td>Total</td>
                        <td align="right">CHF {props.actual_price}</td>
                    </tr>
                </tbody>
                :
                <tbody>
                    <tr>
                        <td>loading...</td>
                    </tr>
                </tbody>
            }
        </Table>
    );
};

export default OfferingTable;