import React, {Component} from 'react';
import ConfirmationService from './ConfirmationService';

class Confirmation extends Component {
	componentDidMount() {
		try {
			ConfirmationService.post({token: this.props.match.params.token})
				.then(response => {
					setTimeout(() => {
						this.props.history.push({
							pathname: '/login',
							state: {password_set: response.data.password_set},
						});
					}, 3000);
				})
				.catch(error => {
					console.log('error ', error);
				});
		} catch (e) {
			console.error(e);
		}
	}

	render() {
		return (
			<div className="block-center mt-4 wd-xl">
				<div className="card card-flat">
					<div className="card-header text-center bg-white">
						<a>
							<img width={200} className="block-center rounded" src="/img/logo.svg" alt="Logo" />
						</a>
					</div>
					<div className="card-body">
						<p className="text-center py-2">Thanks for registering!</p>
						<form>
							<p className="text-center py-2">
								Your account has been confirmed. You will automatically be redirected to the login page.
							</p>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default Confirmation;
