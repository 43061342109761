import {asyncServerRequest} from '../Infrastructure/Requests/ServerRequest';

const getDeviceStatus = async payload => {
    const payloadData = {
        url: 'device/gx/device-status/' + payload,
        method: 'GET',
    };
    try {
        const response = await asyncServerRequest(payloadData);
        const {data} = response;
        return data;
    } catch (error) {
        const {message, status} = error.response.data.error;
        return {message, status};
    }
};

const validateDeviceId = async payload => {
    const payloadData = {
        url: 'device/gx/device-id-validation/' + payload,
        method: 'GET',
    };
    try {
        const response = await asyncServerRequest(payloadData);
        const {
            data: {token},
        } = response;
        return token;
    } catch (error) {
        //TODO
        console.error(error);
    }
};

const getUserAccess = async payload => {
    const payloadData = {
        url: 'auth/access/' + payload,
        method: 'GET',
    };
    try {
        return await asyncServerRequest(payloadData);
    } catch (error) {
        //TODO
        console.error(error);
    }
};

const DeviceStatusService = {
    getDeviceStatus,
    validateDeviceId,
    getUserAccess,
};

export default DeviceStatusService;
