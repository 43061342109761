import React, {Component} from 'react';
import {Button} from 'reactstrap';
import ContentWrapper from '../Layout/ContentWrapper';
import pubsub from 'pubsub-js';
import systemSettings from '../Infrastructure/Settings/SystemSettings';
import {getUserProfile as getUserProfile} from '../Settings/UserProfile/UserProfileService';
import {isBrowser, isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {asyncServerRequest} from '../Infrastructure/Requests/ServerRequest';

interface DevelopmentProps {
	i18n: any;
}

class Development extends Component<DevelopmentProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			isBrowser: isBrowser,
			isMobile: isMobile,
			isMobileOnly: isMobileOnly,
			isTablet: isTablet,
			tzOffset: undefined,
			showDateObject: undefined,
		};
	}

	componentDidMount() {
		pubsub.subscribe('response', data => this.setState({response: JSON.stringify(data)}));
		this.setBackendDateToTZ('2019-10-10T10:10:00+00:00');
		this.setLocalTimeToUTC();
	}

	// show date
	setBackendDateToTZ = time => {
		//https://github.com/moment/moment-timezone/issues/331
		console.log('---------------');
		console.log(time);
		console.log('---------------');
		console.log(moment.utc(time).utcOffset(180).format('YYYY-MM-DDTHH:mm:ssZ')); // (UTC-01:00) Cape Verde Is.
	};

	// acknowledge, etc.
	setLocalTimeToUTC = () => {
		const mouseClick = moment();
		console.log('mouse clicked at', mouseClick);
		const dateSendToBackend = moment.utc(mouseClick).format('YYYY-MM-DDTHH:mm:ssZ');
		console.log('send to backend', dateSendToBackend);
	};

	// filters' etc
	setLocalDateToTZ = tz => {
		const myPCTime = moment();
		console.log('my current pc time', myPCTime);
		const localToAnotherTZ = moment.utc(myPCTime).tz('Europe/Amsterdam');
		console.log('localToAnotherTZ', localToAnotherTZ);
		this.setState({showDateObject: localToAnotherTZ.date});
	};

	isDateSameOrBefore = (dateToCompare, date) => {
		const c = moment(dateToCompare).tz('Europe/Amsterdam');
		const d = moment(date).tz('Europe/Amsterdam');
		if (d.isSameOrBefore(c)) {
			console.log('is same or before', d);
		}
	};

	isDateSameOrAfter = (dateToCompare, date) => {
		const c = moment(dateToCompare).tz('Europe/Amsterdam');
		const d = moment(date).tz('Europe/Amsterdam');
		if (d.isSameOrAfter(c)) {
			console.log('is same or after', d);
		}
	};

	timezoneOffset = () => {
		getUserProfile({})
			.then(response => {
				systemSettings
					.getTimeZoneOffset(response.data.timezone)
					.then(response => {
						console.log('response ', response.data);
						this.setState({tzOffset: response.data});
					})
					.catch(function (error) {
						console.log('error ', error);
					});
			})
			.catch(function (error) {
				console.log('error ', error);
			});
	};

	killSession() {
		pubsub.publish('sessionExpired');
	}

	changeLang = lng => {
		this.props.i18n.changeLanguage(lng); // how to switch between languages
	};

	getAllDeviations = async () => {
		const payload = {
			url: 'deviation/deviations',
			method: 'GET',
		};

		try {
			const response = await asyncServerRequest(payload);
			console.log('server response: ', response);
		} catch (error) {
			console.error(error);
		}
	};

	getServerStatus = async () => {
		const payload = {
			url: 'status',
			method: 'GET',
		};
		try {
			const response = await asyncServerRequest(payload);
			console.log('server response: ', response.data);
		} catch (error) {
			console.error(error);
		}
	};

	render() {
		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>Development</div>
				</div>
				{isBrowser === true ? <div>Device: I am a computer</div> : <div>Device: I am mobile</div>}
				{isMobileOnly === true ? <div>I am a smartphone</div> : null}
				{isTablet === true ? <div>I am a tablet</div> : null}
				<hr />
				<Button onClick={this.getServerStatus}>get server status</Button>
				<Button onClick={this.getAllDeviations}>Get Deviations (aggregation)</Button>
				<hr />
				<Button onClick={this.timezoneOffset}>timezone offset</Button>
				<Button onClick={this.killSession}>kill session</Button>
				<hr />
				<Button color="primary" onClick={() => this.changeLang('en')}>
					English
				</Button>{' '}
				<Button color="primary" onClick={() => this.changeLang('de')}>
					Deutsch
				</Button>
				<hr />
			</ContentWrapper>
		);
	}
}

export default withTranslation()(Development);
