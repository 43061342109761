import React, {Component} from 'react';
import ContentWrapper from '../../../Layout/ContentWrapper';
import {Button} from 'reactstrap';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} from 'react-accessible-accordion';
import CreditCardElement from '../../../Common/CreditCardElement';
import Close from '../../../Shared/Components/Close';
import Message from '../../../Shared/Components/Message';
import SystemSettings from '../../../Infrastructure/Settings/SystemSettings';
import {AddNewCreditCard} from '../../../../common/services/WebserverServicePro/CreditCardService';
import {RouteComponentProps} from 'react-router-dom';

class AddCreditCard extends Component<RouteComponentProps, {stripe: any}> {
	creditCardElement: any;
	constructor(props) {
		super(props);

		this.state = {
			stripe: null,
		};

		this.save = this.save.bind(this);
	}

	componentDidMount() {
		this.setState({stripe: window['Stripe'](SystemSettings.getConfig().stripe_pk)});
	}

	save() {
		this.creditCardElement
			.createToken()
			.then(response => {
				const token = response.token;
				const cardInformation = {
					client_ip: token.client_ip,
					valid_until: token.card.exp_month + '/' + token.card.exp_year,
					credit_card_number: token.card.last4,
					name_on_card: token.card.name,
					payment_provider_registration_id: token.id,
				};

				AddNewCreditCard(cardInformation)
					.then(() => {
						Message.success('Credit card added', 'Credit card successfully added');
						this.props.history.push('/billing');
					})
					.catch(error => Message.error('Error', 'Credit card could not be added', error));
			})
			.catch(error => Message.error('Error', 'Credit card could not be added', error));
	}

	close = e => {
		this.props.history.push('/billing');
		e.preventDefault();
	};

	render() {
		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>New Card</div>
					<div className="ml-auto">
						<Button id="btnSave" color="primary" size="lg" onClick={this.save}>
							<span>Save</span>
						</Button>
						{'\u00A0'}
						<Close onClick={this.close} />
					</div>
				</div>

				<Accordion>
					<AccordionItem disabled expanded={true}>
						<AccordionItemTitle>
							<h5 className="u-position-relative">Credit Card Information</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							<StripeProvider stripe={this.state.stripe}>
								<Elements>
									<CreditCardElement formType="settings" onRef={ref => (this.creditCardElement = ref)} />
								</Elements>
							</StripeProvider>
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>
			</ContentWrapper>
		);
	}
}

export default AddCreditCard;
