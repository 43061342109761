import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import React, {Component} from 'react';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import {Button} from 'reactstrap';
import {Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';

class MultirunDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLastRunOnly: this.props.showLastRunOnly,
			multiRunMenuOverlay: this.props.multiRunMenuOverlay,
		};
	}
	componentDidMount() {}
	close = () => {
		this.state.closeRequest = true;
	};

	render() {
		let currMRS =
			this.props.multiRunSensor.multiRunData[this.props.multiRunSensor.multiRunData.length - this.props.multiRunSensor.selectedRun];

		return (
			<div style={{display: 'inline-block', padding: '6px'}}>
				{this.state.showLastRunOnly ? null : (
					<div>
						<Access
							access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.selectPreviousSensorRuns}
							roles={userRoles.default}
						>
							{this.state.closeRequest ? (
								<Dropdown
									onMouseEnter={() => {
										this.setState({closeRequest: false});
									}}
									overlay={this.state.multiRunMenuOverlay}
									open={false}
								>
									<Button>
										Current selected Sensor Run: {this.props.multiRunSensor.selectedRun} (of Total:{' '}
										{this.props.multiRunSensor.multiRunData.length}) <DownOutlined />
									</Button>
								</Dropdown>
							) : (
								<Dropdown
									onMouseEnter={() => {
										this.setState({dropdownIsOpen: true});
									}}
									overlay={this.state.multiRunMenuOverlay} /* open={this.state.dropdownIsOpen} */
								>
									<Button>
										Current selected Sensor Run: {this.props.multiRunSensor.selectedRun} (of Total:{' '}
										{this.props.multiRunSensor.multiRunData.length}) <DownOutlined />
									</Button>
								</Dropdown>
							)}
						</Access>
						<span style={{padding: '5px'}}>
							Period:{' '}
							{DateTimeUtils.setDateTimeWithOffset_date_dep(currMRS.ts_start) +
								(currMRS.ts_end ? ' - ' + DateTimeUtils.setDateTimeWithOffset_date_dep(currMRS.ts_end) : ' - ')}
						</span>
					</div>
				)}
			</div>
		);
	}
}

export default MultirunDropdown;
