import React, {useEffect, useState} from 'react';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import DeviationsService from './DeviationsService';
import DateTimeUtils, {TimeRange} from '../Infrastructure/DateTime/DateTimeUtils';
import RequestLogger from '../Infrastructure/Requests/Logger/RequestLogger';
import {ViewHeader} from '../Common';
import {DeviationFilterAccordion} from '../Common/Filter/DeviationFilterAccordion';
import {Event} from '../../common/types';
import {EventList} from './EventList';
import {ContentWrapper, ViewWrapper} from '../Layout';

export function Deviations(): React.JSX.Element {
	const [loading, setLoading] = useState(true);
	const [reload, setReload] = useState(true);
	const [activeOrUnacknowledgedOnly, setActiveOrUnacknowledgedOnly] = useState(false);
	const [timeRange, setTimeRange] = useState<TimeRange>(DateTimeUtils.getXDaysBeforeDay(7));

	const [activeEvents, setActiveEvents] = useState<Event[]>();
	const [inactiveEvents, setInactiveEvents] = useState<Event[]>();

	useEffect(() => {
		if (!reload) {
			return;
		}
		setLoading(true);
		console.debug('Getting active deviations');

		// delay reload to make sure the changes have been saved before
		setTimeout(function () {
			const gets = [
				DeviationsService.deviations_active(RequestLogger.createLogData('deviations', 'load-deviations-active', 'use-effect')),
				DeviationsService.deviations_inactive(RequestLogger.createLogData('deviations', 'load-deviations-inactive', 'use-effect')),
				DeviationsService.deviations_acknowledged(
					RequestLogger.createLogData('deviations', 'load-deviations-acknowledged', 'use-effect')
				),
			];

			Promise.all(gets)
				.then(responses => {
					const activeEvents: Event[] = responses[0].data.map(d => new Event(d));
					const inactiveEvents: Event[] = responses[1].data.map(d => new Event(d));
					const acknowledgedEvents: Event[] = responses[2].data.map(d => new Event(d));

					acknowledgedEvents.forEach(acknowledgedEvent => {
						[activeEvents, inactiveEvents].forEach(eventArray => {
							const foundEvent = eventArray.find(activeEvent => activeEvent.IncidentId === acknowledgedEvent.IncidentId);
							if (foundEvent) {
								foundEvent.RelatedEvents.Array.push(acknowledgedEvent);
							}
						});
					});

					setInactiveEvents(inactiveEvents);
					setActiveEvents(activeEvents);
					setReload(false);
					setLoading(false);
				})
				.catch(e => console.error(e));
		}, 1000);
	}, [reload]);

	return (
		<ViewWrapper>
			<ViewHeader heading={'deviations.view'} knowledgeHelpId={'020'}></ViewHeader>
			<ContentWrapper>
				<DeviationFilterAccordion
					title={'Filter and Sorting'}
					timeRangePickerProps={{onTimeRangeChanged: setTimeRange, value: timeRange}}
					showActiveOrUnacknowledged={{value: activeOrUnacknowledgedOnly, set: setActiveOrUnacknowledgedOnly}}
				/>

				<Accordion>
					<AccordionItem expanded={true}>
						<AccordionItemTitle>
							<h5 className="u-position-relative">
								Active
								<div className="accordion__arrow" role="presentation" />
							</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							<EventList
								events={activeEvents}
								timeRange={timeRange}
								loading={loading}
								onAcknowledge={() => setReload(true)}
							/>
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>

				<Accordion>
					<AccordionItem expanded={true}>
						<AccordionItemTitle>
							<h5 className="u-position-relative">
								Inactive
								<div className="accordion__arrow" role="presentation" />
							</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							<EventList
								events={inactiveEvents?.filter(e => !activeOrUnacknowledgedOnly || !e.IsAcknowledged())}
								timeRange={timeRange}
								loading={loading}
								onAcknowledge={() => setReload(true)}
							/>
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>
			</ContentWrapper>
		</ViewWrapper>
	);
}

export default Deviations;
