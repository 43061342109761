export enum MISSING_COMMUNICATION_WARNING_ENUM {
	TOLERANT = 0,
	RECOMMENDED = 1,
	STRICT = 2,
}

export const MISSING_COMMUNICATION_WARNING_STRINGS: Record<number, string> = {
	[-1]: 'Undefined',
	[MISSING_COMMUNICATION_WARNING_ENUM.RECOMMENDED]: 'Recommended',
	[MISSING_COMMUNICATION_WARNING_ENUM.STRICT]: 'Strict',
	[MISSING_COMMUNICATION_WARNING_ENUM.TOLERANT]: 'Tolerant',
};

