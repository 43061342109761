import React, {Component} from 'react';
import {Select} from 'antd';
import {Label, Row, Col, FormGroup} from 'reactstrap';
import {Trans, Translation} from 'react-i18next';
import {StyledHeaderHelpIcon} from '../../../../Shared/Styles/StyledHeaderHelpIcon';
import KnowledgeHelp from '../../../../Shared/Components/KnowledgeBaseHelp';
const {Option} = Select;

type CommunicationModeSelectProps = {
	value: string | undefined;
	communicationModeTemplates: any;
	onChange: any;
};

class CommunicationModeSelectState {
	onChange: any;
}

export class CommunicationModeSelectComponent extends Component<CommunicationModeSelectProps, CommunicationModeSelectState> {
	constructor(props: CommunicationModeSelectProps | Readonly<CommunicationModeSelectProps>) {
		super(props);
		this.state = {
			onChange: props.onChange,
		};
	}

	render() {
		return (
			<Row>
				<Col xl={3}>
					<FormGroup>
						<div className="d-flex" style={{alignItems: 'baseline'}}>
							<Label>
								<Trans i18nKey={'sensorWizard.sensorBasicsForm.communicationMode'} />
							</Label>
							<StyledHeaderHelpIcon>
								<KnowledgeHelp id={'092'} />
							</StyledHeaderHelpIcon>
						</div>
						<Select
							style={{width: '100%'}}
							defaultValue={this.props.value}
							id="selectCommunicationMode"
							value={this.props.value}
							onChange={this.state.onChange}
						>
							{this.props.communicationModeTemplates.map(communicationModeTemplate => {
								return (
									<Option
										id={communicationModeTemplate.name}
										key={communicationModeTemplate.id}
										value={communicationModeTemplate.index}
									>
										{communicationModeTemplate.name}
									</Option>
								);
							})}
						</Select>
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
