// https://github.com/chriso/validator.js
import validator from 'validator';
import {isValidNumber} from 'libphonenumber-js';
import SerialNumberInfo from '../Shared/SerialNumberInfo';
import {GetValueFromInputElement} from '../../common/util/GetValueFromInputElement';
import {IsBridgeId} from '../../common/validation/IsBridgeId';
import {assignName} from '../Dashboard/Sensor/Forms/SensorBasicsForm/Helpers/InputNameAssigner';

/**
 * Helper methods to validate form inputs
 * using controlled components
 */
const FormValidator = {
	/**
	 * Validate input element
	 * @param element Dome element of the input
	 * Uses the following attributes
	 *     data-validate: array in json format with validation methods
	 *     data-param: used to provide arguments for certain methods.
	 */

	validate(element) {
		const isCheckbox = element.type === 'checkbox';
		const value = isCheckbox ? element.checked : element.value;
		const name = element.name;

		if (!name) {
			throw new Error('Input name must not be empty.');
		}

		// use getAttribute to support IE10+
		const param = element.getAttribute('data-param');
		const validations = JSON.parse(element.getAttribute('data-validate'));

		let result = [];
		if (validations && validations.length) {
			/*  Result of each validation must be true if the input is invalid
                and false if valid. */

			validations.forEach(m => {
				switch (m) {
					case 'required':
						result[m] = isCheckbox ? value === false : validator.isEmpty(value);
						break;
					case 'email':
						result[m] = !validator.isEmail(value);
						break;
					case 'number':
						result[m] = !validator.isNumeric(value);
						break;
					case 'integer':
						result[m] = !validator.isInt(value);
						break;
					case 'ipAddress':
						result[m] = !this.isIPv4(value);
						break;
					case 'serialNumber':
						result[m] = !this.isSerialNumber(value);
						break;
					case 'replaceSerialNumberValid':
						result[m] = !this.isReplaceSerialValid(value);
						break;
					case 'replaceSerialNumberSameModuleType':
						result[m] = !this.isReplaceSerialSameModuleType(value, element.getAttribute('initial-serial-number'));
						break;
					case 'mobilePhone':
						result[m] = !isValidNumber(value);
						break;
					case 'alphanum':
						result[m] = !validator.isAlphanumeric(value);
						break;
					case 'url':
						result[m] = !validator.isURL(value);
						break;
					case 'equalto':
						// here we expect a valid ID as param
						const value2 = GetValueFromInputElement(param);
						result[m] = value2 != null && !validator.equals(value, value2);
						break;
					case 'lessThan':
						const value3 = GetValueFromInputElement(param);
						result[m] = value3 != null && !this.isLessThan(value, value3);
						break;
					case 'minlen':
						result[m] = !validator.isLength(value, {min: param});
						break;
					case 'maxlen':
						result[m] = !validator.isLength(value, {max: param});
						break;
					case 'len':
						const [min, max] = JSON.parse(param);
						result[m] = !validator.isLength(value, {min, max});
						break;
					case 'min':
						result[m] = !validator.isInt(value, {min: validator.toInt(param)});
						break;
					case 'max':
						result[m] = !validator.isInt(value, {max: validator.toInt(param)});
						break;
					case 'minmax':
						const [min2, max2] = JSON.parse(param);
						result[m] = !validator.isInt(value, {min: min2}) || !validator.isInt(value, {max: max2});
						break;
					case 'list':
						const list = JSON.parse(param);
						result[m] = !validator.isIn(value, list);
						break;
					case 'maxchar':
						result[m] = value.length - 1 >= parseInt(param);
						break;
					case 'zone':
						result[m] = param === 'error';
						break;
					case 'zonesRequiredAlarming':
						result[m] = value && param === '0';
						break;
					case 'zonesRequiredWarning':
						result[m] = value && param === '0';
						break;
					case 'profileSelected':
						result[m] = param === 'error';
						break;
					case 'emailUserSelected':
						result[m] = param === 'error';
						break;
					case 'smsUserSelected':
						result[m] = param === 'error';
						break;
					case 'duplicateName':
						result[m] = param === 'error';
						break;
					case 'maxlen50':
						result[m] = !validator.isLength(value, {max: 50});
						break;
					case 'bridgeId':
						result[m] = !IsBridgeId(value);
						break;
					default:
						throw new Error('Unrecognized validator.');
				}
			});
		}

		return result;
	},

	/**
	 * Bulk validation of input elements.
	 * Used with form elements collection.
	 * @param  {Array} inputs Array for DOM element
	 * @return {Object}       Contains array of error and a flag to
	 *                        indicate if there was a validation error
	 */
	bulkValidate(inputs) {
		let errors = {},
			hasError = false;

		inputs.forEach(input => {
			let result = this.validate(input);
			errors = {...errors, [input.name]: result};
			if (!hasError) hasError = Object.keys(result).some(val => result[val]);
		});

		return {
			errors,
			hasError,
		};
	},

	formValidate(form: HTMLFormElement): {errors: {}; isValid: boolean} {
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));
		const namedInputs = assignName(inputs);
		const {errors, hasError} = FormValidator.bulkValidate(namedInputs);

		return {errors, isValid: !hasError};
	},

	isIPv4(value) {
		const ipPattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
		return value.search(ipPattern) === 0;
	},

	isLessThan(value1, value2) {
		try {
			if (typeof value1 !== 'number') {
				value1 = parseInt(value1);
			}
			if (typeof value2 !== 'number') {
				value2 = parseInt(value2);
			}
			return value1 < value2;
		} catch {
			return false;
		}
	},

	isSerialNumber(value) {
		// TODO define how many numbers. At the moment 6 ex. S000000
		return true;
	},

	isReplaceSerialValid(value) {
		return SerialNumberInfo.isSupported(value);
	},

	isReplaceSerialSameModuleType(value, initialSerialNumber) {
		const newSerial = typeof value === 'string' && value[0] ? value[0].toUpperCase() : value[0];
		return newSerial === initialSerialNumber[0];
	},
};

export default FormValidator;
