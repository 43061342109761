export const EditSensorSteps = [
    {
        text: 'sensorWizard.steps.sensor_basics',
    },
    {
        text: 'sensorWizard.steps.alarm_limits',
    },
    {
        text: 'sensorWizard.steps.sensor_issues',
    },
    {
        text: 'sensorWizard.steps.payment',
    },
];

export const EditSensorWithConfigurationTemplatSteps = [
    {
        text: 'sensorWizard.steps.sensor_basics',
    },
    {
        text: 'sensorWizard.steps.configuration_template',
    },
    {
        key: 'metadatas',
        text: 'Metadata',
    },
    {
        key: 'alarm_limits',
        text: 'sensorWizard.steps.alarm_limits',
    },
    // {
    //     key: 'additional_issues',
    //     text: 'Additional Sensor issues'
    // },
    {
        key: 'display_settings',
        text: 'Display settings',
    },
    {
        key: 'device_operation',
        text: 'Device Operation',
    },
    // {
    //     key: 'recipients',
    //     text: 'Recipient settings',
    // },
    {
        text: 'sensorWizard.steps.payment',
    },
];
