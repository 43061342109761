import React, {useState} from 'react';
import {OptimizeFor, SensorBodyGraphic} from './SensorBodyGraphic';
import {Sensor} from '../../Common/Types/Sensor';

const colors = {
	inactive: '#B1B3B3',
	active: '#000000',
	stroke: '#3A3A39',
	noValue: '#d9dada',
	alarm: '#e41919',
	noAlarm: '#50af47',
};

interface SensorBodyComponentProps {
	analyseSensor: any;
	isRetired: boolean;
	fillingColor: string;
	sensor: Sensor;
	optimizeFor: OptimizeFor;
}

export const SensorBodyComponent = (props: SensorBodyComponentProps) => {
	const [sensor] = useState(props.sensor);
	const getLicenseLevelClass = () => {
		if (sensor.IsBlurred) {
			return 'sensor-no-license-level1';
		}
		return '';
	};

	return (
		<div className={'card-body ' + getLicenseLevelClass()}>
			<SensorBodyGraphic
				optimizeFor={props.optimizeFor}
				onClick={props.analyseSensor}
				sensor={sensor}
				sensorAlarmDeactivatedColor={colors.inactive}
				sensorColors={colors}
				sensorValueColor={colors.active}
				sensorFillingColor={props.fillingColor}
				sensorStrokeColor={colors.stroke}
			/>
		</div>
	);
};
export default SensorBodyComponent;
