import React, {useContext, useEffect, useState} from 'react';
import {PredictionService} from '../../common/services';
import {PredictionResult, SensorPredictiveInfo} from '../../common/types/Predictive';
import {Sensor} from '../Common/Types/Sensor';
import {PredictionResultGraph} from './PredictionResultGraph';
import {Select} from 'antd';
import {DevUtils} from '../../common/util';
import dayjs from 'dayjs';
import {DateTimeUtils} from '../Infrastructure/DateTime/DateTimeUtils';
import {PredictionInformationView} from './PredictionInformationView';
import {AuthContext} from '../../common/context';

type PredictionResultProps = {
	sensor: Sensor;
	sensorPredictiveInfo: SensorPredictiveInfo;
};

export function PredictionResultView(props: PredictionResultProps): React.JSX.Element {
	const authContext = useContext(AuthContext);
	const orgId = authContext?.Organization?.Id;
	// Use the first assessment only (it should be the only one at the moment)
	const assessmentResults = props.sensorPredictiveInfo?.assessments?.at(0).assessmentResults ?? [];

	const [calculationId, setCalculationId] = useState<string>(undefined);
	const [predictionResult, setPredictionResult] = useState<PredictionResult>({
		ambientTemperatureMax: [],
		ambientTemperatureMin: [],
		payloadTemperatureMax: [],
		payloadTemperatureMin: [],
	});

	useEffect(() => {
		try {
			setCalculationId(
				assessmentResults.sort((left, right) => dayjs(left.createdAt).unix() - dayjs(right.createdAt).unix()).at(-1).calculationId
			);
		} catch (e) {}
	}, [assessmentResults]);

	useEffect(() => {
		if (!calculationId) {
			return;
		}

		PredictionService.GetPredictionsResult(orgId, calculationId)
			.then(r => setPredictionResult(r))
			.catch();
	}, [calculationId]);

	const assessmentResultSelectOpts = assessmentResults.map(a => ({
		label: DateTimeUtils.toUserString(dayjs(a.createdAt)),
		value: a.calculationId,
	}));

	return (
		<>
			<PredictionInformationView sensorPredictiveInfo={props.sensorPredictiveInfo} sensor={props.sensor} />
			{DevUtils.IsDevEnv() && (
				<>
					<Select
						value={calculationId}
						options={assessmentResultSelectOpts}
						onInputKeyDown={e => {
							const currentIdx = assessmentResults.findIndex(a => a.calculationId == calculationId);
							let newIdx = currentIdx;
							if (e.code == 'ArrowRight') {
								newIdx = Math.min(assessmentResults.length - 1, currentIdx + 1);
							} else if (e.code == 'ArrowLeft') {
								newIdx = Math.max(0, currentIdx - 1);
							} else {
								return;
							}
							setCalculationId(assessmentResults[newIdx].calculationId);
						}}
						onSelect={value => setCalculationId(value)}
					/>
				</>
			)}
			<PredictionResultGraph
				calculationId={calculationId}
				sensor={props.sensor}
				predictionResult={predictionResult}
				sensorPredictiveInfo={props.sensorPredictiveInfo}
			/>
		</>
	);
}
