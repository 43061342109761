export enum DeviceState {
	Retired = 'Retired',
	Start = 'Start',
	Pairing = 'Pairing',
	Prepared = 'Prepared',
	Running = 'Running',
	Transit = 'Transit',
	Stopped = 'Stopped',
	AlarmDeactivated = 'Alarm deactivated',
	ConfigPending = 'Config pending',
	Delayed = 'Delay',
	Blurred = 'Blurred',
	CALIBRATION_1 = 'Calibration pending',
	CALIBRATION_2 = 'Calibrating',
	CALIBRATION_3 = 'Calibration stopping',
}
