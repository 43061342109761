import React from 'react';
import {Input, Row, Col} from 'reactstrap';

export default function CheckBoxComponent(props) {
	return (
		<Row>
			<Col xl={3}>
				<label className="checkbox c-checkbox">
					<Input
						type="checkbox"
						id={props.id}
						name={props.name}
						onChange={props.onChange}
						checked={props.checked}
						disabled={props.disabled}
					/>
					<span className="fa fa-check" /> {props.text}
				</label>
			</Col>
		</Row>
	);
}
