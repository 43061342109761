import React, {Component} from 'react';
import {LockRendererProps} from './LockRendererProps';
import {LockRendererState} from './LockRendererState';
import {LockFilled, UnlockOutlined} from '@ant-design/icons';

export class LockRenderer extends Component<LockRendererProps, LockRendererState> {
	constructor(props: LockRendererProps | Readonly<LockRendererProps>) {
		super(props);
		this.state = {
			locked: props.data.Locked,
		};
	}

	render() {
		return this.state.locked ? <LockFilled style={{fontSize: '18px'}} /> : <UnlockOutlined style={{fontSize: '18px'}} />;
	}
}
