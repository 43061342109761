import dayjs from 'dayjs';
import {EventCollection, EventDetail, EventDetailType} from '.';
import {DeviationsAcknowledgedRow, DeviationsActiveRow, DeviationsInactiveRow, EventRow} from './DbModel';

export class Event {
	Id: number;
	DeviationId: number;
	OrgId: number;
	IncidentId: number;
	Type: number;
	DateOccurred: dayjs.Dayjs;
	Details: EventDetail[];
	RelatedEvents: EventCollection = new EventCollection([]);

	constructor(init: EventRow | DeviationsActiveRow | DeviationsInactiveRow | DeviationsAcknowledgedRow) {
		this.Id = init.id;
		this.DeviationId = init.deviations_id;
		this.OrgId = init.org_id;
		this.IncidentId = init.incidents_id;
		this.Type = init.type;
		this.DateOccurred = dayjs(init.date_occurred);
		this.Details = init.details.map(detail => new EventDetail(detail));
	}

	public GetEntryReason(): string {
		return this.GetEventDetail(EventDetailType.EntryReason)?.Value.toString();
	}

	public GetSensorName(): string {
		return this.GetEventDetail(EventDetailType.SensorName)?.Value.toString();
	}

	public GetModuleId(): string {
		return this.GetEventDetail(EventDetailType.ModuleId)?.Value.toString();
	}

	public GetSensorId(): number {
		return parseInt(this.GetEventDetail(EventDetailType.SensorId)?.Value.toString());
	}

	public GetEventDetail(eventDetailType: EventDetailType): EventDetail {
		return this.Details.find(d => d.EventDetailTypeId == eventDetailType);
	}

	public IsAcknowledged(): boolean {
		return !!this.RelatedEvents.GetDeviationAcknowledgedEvent();
	}

	public GetEventAcknowledgedEvent(): Event {
		return this.RelatedEvents.GetDeviationAcknowledgedEvent();
	}

	public GetEventAcknowledgedTimestamp(): dayjs.Dayjs {
		return this.GetEventAcknowledgedEvent()?.DateOccurred;
	}

	public GetEventAcknowledgedBy(): string {
		return this.GetEventAcknowledgedEvent()?.GetEventDetail(EventDetailType.FullName)?.Value.toString();
	}
}
