// temperature
const y0 = 6800; // Y value at lower limit
const yBase = 4400; // Px between lower limit and upper limit
const h0 = 15000;
const minY = 8120; // the lowest possible
const yMiddle = 4600; // for sensors without limit alarm
const hMiddle = 10000; // for sensors without limit alarm

const sensorUtils = {
    getSensorUnitType(unit) {
        switch (Number(unit)) {
            case 1:
                return 'K';
            case 2:
                return '°C';
            case 3:
                return '°F';
            case 4:
                return '%';
            default:
                return '';
        }
    },

    handleNewMeasurement(sensor, value) {
        let max = sensor.max;
        let min = sensor.min;

        if (max !== undefined) {
            const alarm = value < min || value > max;
            const percentage = (value - min) * (1 / (max - min));

            let y = y0 - yBase * percentage;

            if (y > minY) {
                y = minY;
            }

            let h = h0 - y;

            return {
                y: y,
                h: h,
                alarm: alarm,
            };
        } else {
            // no limit alarm
            return {
                y: yMiddle,
                h: hMiddle,
                alarm: false,
            };
        }
    },

    convertTemperature(value, unit) {
        if (unit === undefined) unit = 2;

        if (value === null || value <= Number.MIN_VALUE || value >= Number.MAX_VALUE) {
            return undefined;
        } else {
            switch (unit) {
                case 2:
                    return Math.round((value - 273.15) * 10) / 10; // K -> °C
                case 3:
                    return Math.round((1.8 * value - 459.67) * 10) / 10; // K -> °F
                case 4:
                    return parseFloat(value.toFixed(1));
                default:
                    return value;
            }
        }
    },

    convertPostgresqlTimestampToHighcharts(timestamptz) {
        if (timestamptz !== null && timestamptz !== undefined) {
            return new Date(timestamptz);
        } else {
            return undefined;
        }
    },

    convertUnixTimestampToHighcharts(unixTimestamp) {
        return new Date(unixTimestamp * 1000);
    },

    isNumberOdd(num) {
        return num % 2;
    },

    /**
     * Set sensor alarming class (blurring) when license is not valid
     * @param sensor Current sensor
     * @returns {string} Blurring class or empty string
     */
    setLicenseLevelClass(sensor) {
        //the app-logic, when a sensor appears blurred has gone into the
        //view api.sensors_dashboard_info now,
        //which is, either a running sensor (alarming=true), having a valid license
        //or a prepared sensor, which is about to become valid by doing the payment
        //or also a retired sensor...
        //the retired sensor has alarming=false, but therefore it is not blurred
        //it is blurred if its license expires
        if (sensor.isBlurred) {
            return 'sensor-no-license-level1';
        } else {
            // everything is fine
            // do nothing
            return '';
        }
    },

    getErrorCodeDisplayValue(errorCode) {
        const errorCodes = {
            0: 'No error',
            1: 'Checksumm error',
            2: 'Timeout',
            3: 'No connection',
            4: 'Short circuit',
            5: 'ADC config error',
            6: 'EMC disturbance',
        };

        return errorCodes[errorCode];
    },
};

export default sensorUtils;
