import React from 'react';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import {Trans} from 'react-i18next';
import {StyledHeaderHelpIcon} from '../../../../Shared/Styles/StyledHeaderHelpIcon';
import KnowledgeHelp from '../../../../Shared/Components/KnowledgeBaseHelp';

export default function AccordionComponent(props) {
	return (
		<Accordion>
			<AccordionItem id={props.accordionItemid} expanded>
				<AccordionItemTitle id={props.accordionTitleId}>
					<h5 className="u-position-relative">
						<div className="d-flex" style={{alignItems: 'baseline'}}>
							<Trans i18nKey={props.transkey} />
							{props.helperIcon ? (
								<StyledHeaderHelpIcon>
									<KnowledgeHelp id={props.helperIcon} />
								</StyledHeaderHelpIcon>
							) : null}
						</div>
						<div className="accordion__arrow" role="presentation" />
					</h5>
				</AccordionItemTitle>
				<AccordionItemBody>{props.itemBody}</AccordionItemBody>
			</AccordionItem>
		</Accordion>
	);
}
