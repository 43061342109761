import styled from 'styled-components';
import {Table} from 'antd';

export const AntStyledTable = styled(Table)`
    .ant-table-thead > tr > th {
    }
    .ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
        background-color: transparent;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: #fafafa;
    }
`;
