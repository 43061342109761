import {DigitsLCDDisplay} from './LCDValueDisplay';

export const getDisplayedSegments = (value: string) => {
	let segments = [];

	if (!value) return segments;

	const init_value = value.toUpperCase();
	for (let i = 0; i < init_value.length && segments.length < 16; i++) {
		const displayed_chart = init_value.charAt(i);
		if (DigitsLCDDisplay[displayed_chart]) {
			const hasNextComma = init_value.charAt(i + 1) === '.' && ![7, 15].includes(segments.length);
			const hasNextPoints = init_value.charAt(i + 1) === ':' && [1, 3, 5, 9, 11, 13].includes(segments.length);
			segments.push({value: displayed_chart, comma: hasNextComma, points: hasNextPoints});
		}
	}

	return segments;
};

export const getInfoTextValue = (value: string) => {
	let info_text_1 = '';
	let info_text_2 = '';

	const segments = getDisplayedSegments(value);
	segments.forEach((segment, i) => {
		if (i < 8) {
			info_text_1 += `${segment.value}${segment.comma ? '.' : ''}${segment.points ? ':' : ''}`;
		} else {
			info_text_2 += `${segment.value}${segment.comma ? '.' : ''}${segment.points ? ':' : ''}`;
		}
	});

	return [info_text_1, info_text_2];
};
