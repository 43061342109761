import request from '../Infrastructure/Requests/Request';

function resetPassword(payload) {
    return request({
        url: '/rpc/user_reset_password',
        method: 'POST',
        data: payload,
    });
}

const ResetPasswordService = {
    resetPassword,
};

export default ResetPasswordService;
