import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
import {Assessment, AssessmentResult, Prediction, PredictionResult, SensorPredictiveInfo} from '../../types/Predictive';
import {Container, Lane, PredictionCalculationResult, PredictionRequest, PredictionRequestResult} from './';
import {DevUtils} from '../../util';
import {request} from '../../../components/Infrastructure/Requests/Request';

const LocalhostPrefix: string = DevUtils.UseLocal('PREDICTION_SERVICE') ? 'http://localhost:5036' : '';
const Route: string = `${LocalhostPrefix}/prediction/api/v1`;

export class PredictionService {
	static async GetContainers(orgId: number): Promise<Container[]> {
		try {
			return (
				await request({
					url: `${Route}/containers?orgId=${orgId}`,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async GetLanes(orgId: number): Promise<Lane[]> {
		try {
			return (
				await request({
					url: `${Route}/lanes?orgId=${orgId}`,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async PostPredictions(orgId: number, predictionRequest: PredictionRequest): Promise<PredictionRequestResult> {
		try {
			return (
				await request({
					url: `${Route}/predictions?orgId=${orgId}`,
					method: 'POST',
					token: Authentication.getToken(),
					data: predictionRequest,
				})
			).data as PredictionRequestResult;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async GetPredictions(orgId: number, sensorRunId: number = null): Promise<Prediction[]> {
		let url = `${Route}/Predictions/?orgId=${orgId}`;
		if (sensorRunId) {
			url += `&sensorRunId=${sensorRunId}`;
		}
		try {
			const response = await request({
				url: url,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return response.data as Prediction[];
		} catch (e) {
			return Promise.reject(e);
		}
	}

	static async GetPredictionsResult(orgId: number, calculationId: string): Promise<PredictionResult> {
		try {
			let response = await request({
				url: `${Route}/PredictionResult/${calculationId}/?orgId=${orgId}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return (response.data as PredictionCalculationResult).result;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public static async GetAssessmentResults(orgId: number, sensorRunId: number): Promise<AssessmentResult[]> {
		try {
			const result = await request({
				url: `${Route}/assessments/?orgId=${orgId}&sensorRunId=${sensorRunId}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
			const assessments = result.data as Assessment[];
			const assessment = assessments[0]; // Currently, we only support one assessment per sensor
			return assessment.assessmentResults;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public static async GetSensorPredictiveInformation(orgId: number, sensorRunId: number): Promise<SensorPredictiveInfo> {
		try {
			const result = await request({
				url: `${Route}/sensors/${sensorRunId}/?orgId=${orgId}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return result.data as SensorPredictiveInfo;
		} catch (e) {
			if (e.status == 404) {
				return null;
			}

			return Promise.reject(e);
		}
	}
}
