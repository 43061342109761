import request from '../Requests/Request';
import pubsub from 'pubsub-js';
import secureToken from '../Authorization/Utils/SecureTokenStorage';
//import Authentication from '../Infrastructure/Authentication/Authentication';

const tokenKey = 'elproCLOUD-Token';
const tokenKey_f = 'elproCLOUD-Token-f';
const tokenKey_ll = 'elproCLOUD-is-ll';

function login(payload) {
	return request({
		url: '/rpc/login',
		method: 'POST',
		headers: {Accept: 'application/vnd.pgrst.object'},
		data: payload,
	});
}

function refreshToken() {
	request({
		url: isForeign() ? '/rpc/refresh_f' : '/rpc/refresh',
		method: 'POST',
		headers: {Accept: 'application/vnd.pgrst.object'},
		token: Authentication.getToken(),
	})
		.then(function (response) {
			if (sessionStorage.getItem(tokenKey_f)) setToken_f(response.data.token);
			else setToken(response.data.token);
		})
		.catch(function (error) {
			console.error(error);
		});
}

function alive() {
	return request({
		url: '/rpc/alive',
		method: 'POST',
		token: Authentication.getToken(),
	});
}

function logout_tokens() {
	localStorage.removeItem(tokenKey);
	sessionStorage.removeItem(tokenKey_f);
	sessionStorage.removeItem(tokenKey_ll);
}

function logout() {
	const token = Authentication.getToken();

	localStorage.removeItem('hideHubspotChat');

	localStorage.removeItem('tzOffset');
	localStorage.removeItem('supports_dst');
	localStorage.removeItem('base_utc_offset');
	localStorage.removeItem('timezone');

	sessionStorage.removeItem('tzOffset');
	sessionStorage.removeItem('supports_dst');
	sessionStorage.removeItem('base_utc_offset');
	sessionStorage.removeItem('timezone');

	secureToken.removeRolesAccessToken();
	secureToken.removeDashboardGroups();
	secureToken.removeFeatureFlagsToken();

	logout_tokens();
	request({
		url: '/rpc/logout',
		method: 'POST',
		token: token,
	})
		.then(function () {
			pubsub.publish('sessionExpired');
		})
		.catch(function () {
			pubsub.publish('sessionExpired');
		});
}

function setToken(token) {
	logout_tokens(); // If there is a f-token in this session clear them!
	localStorage.setItem(tokenKey, token);
}

function setToken_f(token, ll = false) {
	//logout_tokens();
	//localStorage.setItem(tokenKey_f, token);
	sessionStorage.setItem(tokenKey_f, token);

	if (ll == true) {
		sessionStorage.setItem(tokenKey_ll, 'true');
	}
}

function isLiveLink() {
	return sessionStorage.getItem(tokenKey_ll) == 'true';
}

function set_supports_dst_f(val) {
	sessionStorage.setItem('supports_dst', val);
}

function set_base_utc_offset_f(val) {
	sessionStorage.setItem('base_utc_offset', val);
}

function set_timezone_f(val) {
	sessionStorage.setItem('timezone', val);
}

function set_tzOffset_f(val) {
	sessionStorage.setItem('tzOffset', val);
}

function get_supports_dst() {
	return sessionStorage.getItem('supports_dst') || localStorage.getItem('supports_dst');
}

function get_base_utc_offset() {
	return sessionStorage.getItem('base_utc_offset') || localStorage.getItem('base_utc_offset');
}

function get_timezone() {
	return sessionStorage.getItem('timezone') || localStorage.getItem('timezone');
}

function get_tzOffset() {
	return sessionStorage.getItem('tzOffset') || localStorage.getItem('tzOffset');
}

function getToken() {
	//if (! sessionStorage.getItem(tokenKey_f)) alert("no fkey");
	/*
   if(sessionStorage.getItem(tokenKey_f) && localStorage.getItem(tokenKey))
        logout_tokens();
   */
	return sessionStorage.getItem(tokenKey_f) || localStorage.getItem(tokenKey);
}

export function isForeign() {
	return sessionStorage.getItem(tokenKey_f) != null;
}

function isAuthenticated() {
	return this.getToken() !== null;
}

const Authentication = {
	login,
	logout,
	logout_tokens,
	set_tzOffset_f,
	set_timezone_f,
	set_base_utc_offset_f,
	set_supports_dst_f,
	get_tzOffset,
	get_timezone,
	get_base_utc_offset,
	get_supports_dst,
	isForeign,
	//clearToken,
	refreshToken,
	alive,
	setToken,
	setToken_f,
	isLiveLink,
	getToken,
	isAuthenticated,
};

export default Authentication;
