import React from 'react';
import {Table} from 'reactstrap';
import {CONSTANTS} from '../../Constants/Constants';

export default function SensorMappingsTable(props) {
	return (
		<Table striped bordered hover>
			<thead style={{backgroundColor: '#e6e7e8'}}>
				<tr id="header">
					<td id="row-id">#</td>
					<td id="sensor-name-header">Sensor Name</td>
					<td id="serial-number-header">Serial Number</td>
				</tr>
			</thead>
			<tbody>
				{props.sensors ? (
					props.sensors.map((sensor, index) => {
						let rowId = index + 1;
						return (
							<tr key={'header' + rowId}>
								<td id={'index-' + rowId}>{rowId}</td>
								<td id={'sensor-name-' + rowId}>{sensor.name}</td>
								<td id={'serial-number-' + rowId}>{sensor.serial_number}</td>
							</tr>
						);
					})
				) : (
					<tr>
						<td id="index">{CONSTANTS.NO_VALUE}</td>
						<td id="sensor-name">{CONSTANTS.NO_VALUE}</td>
						<td id="serial-number">{CONSTANTS.NO_VALUE}</td>
					</tr>
				)}
			</tbody>
		</Table>
	);
}
