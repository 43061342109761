import React, {Component} from 'react';
import {Input} from 'reactstrap';
import FormValidator from '../Forms/FormValidator';
import ResetPasswordService from './ResetPasswordService';
import Shared from '../Shared/Shared';
import Message from '../Shared/Components/Message';

const pwError = {
	fontSize: '80%',
	color: '#f61214',
};

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formResetPassword: {
				password: '',
				password_confirm: '',
			},
			errorMessage: undefined,
			passwordStrength: '',
		};
	}

	handlePWChange = e => {
		this.setState({errorMessage: Shared.validatePassword(e.target.value)});
		this.setState({passwordStrength: Shared.passwordMeasureStrength(e.target.value)});
		this.validateOnChange(e);
	};

	validateOnChange = event => {
		const input = event.target;
		const form = input.form;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		const result = FormValidator.validate(input);

		this.setState({
			[form.name]: {
				...this.state[form.name],
				[input.name]: value,
				errors: {
					...this.state[form.name].errors,
					[input.name]: result,
				},
			},
		});
	};

	/* Simplify error check */
	hasError = (formName, inputName, method) => {
		return (
			this.state[formName] &&
			this.state[formName].errors &&
			this.state[formName].errors[inputName] &&
			this.state[formName].errors[inputName][method]
		);
	};

	resetPassword = e => {
		this.setState({errorMessage: undefined});

		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);

		this.setState({
			[form.name]: {
				...this.state[form.name],
				errors,
			},
		});

		if (!hasError) {
			let payload = {
				pass: this.state.formResetPassword.password,
				token: this.props.match.params.token,
			};

			ResetPasswordService.resetPassword(payload)
				.then(response => {
					this.props.history.push({
						pathname: '/login',
					});
					Message.success('Password changed', 'Password successfully changed');
				})
				.catch(error => {
					Message.error('Error', 'Password could not be changed. Token not found', error);
				});
		}

		e.preventDefault();
	};

	render() {
		return (
			<div className="block-center mt-4 wd-xl">
				<div className="card card-flat">
					<div className="card-header text-center bg-white">
						<img width={200} className="block-center rounded" src={process.env.PUBLIC_URL + '/img/logo.png'} alt="elproCLOUD" />
					</div>
					<div className="card-body">
						<p className="text-center py-2">Reset Password</p>
						<form name="formResetPassword" onSubmit={this.resetPassword}>
							<div className="form-group">
								<div className="input-group with-focus">
									<Input
										type="password"
										id="inputPassword"
										name="password"
										className="border-right-0"
										placeholder="Password"
										invalid={this.hasError('formResetPassword', 'password', 'required')}
										data-validate='["required"]'
										onChange={this.handlePWChange}
										value={this.state.formResetPassword.password}
									/>
									<div className="input-group-append">
										<span className="input-group-text text-muted bg-transparent border-left-0">
											<em className="fa fa-lock" />
										</span>
									</div>
									{this.hasError('formResetPassword', 'password', 'required') && (
										<span id="errorPasswordRequired" className="invalid-feedback">
											Field is required
										</span>
									)}
								</div>
								{this.state.errorMessage && (
									<span id="errorPasswordRequired" style={pwError}>
										{this.state.errorMessage}
									</span>
								)}
							</div>
							<div className="form-group">
								<div className="input-group with-focus">
									<Input
										type="password"
										id="inputPasswordConfirm"
										name="password_confirm"
										className="border-right-0"
										placeholder="Retype password"
										invalid={this.hasError('formResetPassword', 'password_confirm', 'equalto')}
										data-param="inputPassword"
										data-validate='["equalto"]'
										onChange={this.validateOnChange}
										value={this.state.formResetPassword.password_confirm}
									/>
									<div className="input-group-append">
										<span className="input-group-text text-muted bg-transparent border-left-0">
											<em className="fa fa-lock" />
										</span>
									</div>
									{this.hasError('formResetPassword', 'password_confirm', 'required') && (
										<span id="errorPasswordConfirmRequired" className="invalid-feedback">
											Field is required
										</span>
									)}
									{this.hasError('formResetPassword', 'password_confirm', 'equalto') && (
										<span id="errorPasswordsNotEqual" className="invalid-feedback">
											Passwords must be equal
										</span>
									)}
								</div>
							</div>

							<button className="btn btn-block btn-primary mt-3" type="submit" disabled={!!this.state.errorMessage}>
								Reset Password
							</button>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
