import {webserverServiceProUrl} from './index';
import {getDefaultOpts} from '../index';
import {ICreditCardCreationRequest, ICreditCardResponse} from '@elproag/webserver-service-pro';
import {CreditCard} from '../../types';

const creditCardBaseUrl = `${webserverServiceProUrl}/api/web/creditcards`;

export async function GetCreditCards(): Promise<ICreditCardResponse[]> {
	const response = await fetch(creditCardBaseUrl, {
		...getDefaultOpts(),
		method: 'GET',
	});

	if (response.ok) {
		return Promise.resolve(await response.json());
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function AddNewCreditCard(payload: CreditCard): Promise<void> {
	const request: ICreditCardCreationRequest = {
		clientIp: payload.client_ip,
		creditCardNumber: payload.credit_card_number,
		nameOnCard: payload.name_on_card,
		validUntil: payload.valid_until,
		paymentProviderRegistrationId: payload.payment_provider_registration_id,
		defaultCard: false,
	};

	const response = await fetch(creditCardBaseUrl, {
		...getDefaultOpts(),
		body: JSON.stringify(request),
		method: 'POST',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function DeleteCreditCard(id: bigint): Promise<void> {
	const response = await fetch(`${creditCardBaseUrl}/${id}`, {
		...getDefaultOpts(),
		method: 'DELETE',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}
