import IssueProfileService from '../../../Services/IssueProfiles/IssueProfilesService';
import {mapIssueProfilePropertiesToGrid} from '../Extensions/IssueProfilePropertiesToGridMapper';
import RequestLogger from '../../../Infrastructure/Requests/Logger/RequestLogger';

export const getIssueProfiles = parent => {
    IssueProfileService.issueProfiles(RequestLogger.createLogData('issue-profiles', 'load-issue-profiles', 'onLoad'))
        .then(response => {
            const issueProfiles = response.data;

            let returnValues = mapIssueProfilePropertiesToGrid(parent, issueProfiles);
            parent.setState({rowData: [...returnValues[0]], issueProfiles: [...returnValues[1]]});
        })
        .catch(error => console.log(error));
};
