import {User} from '../../types';
import {UserProfileService} from '../UserProfileService';
import {UserManagementService} from '../UserManagementService';

export class UserServiceHelper {
	public static async GetAllUsers(): Promise<User[]> {
		try {
			const currentUser = await UserProfileService.GetUser();
			const remainingUsers = await UserManagementService.GetUsers();

			return [currentUser, ...remainingUsers];
		} catch (e) {
			Promise.reject(e);
		}
	}
}
