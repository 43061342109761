import {useContext} from 'react';
import {AccessContext} from '../Context/AccessContext';
import {authUtils} from '../Utils/index';

export const Access = props => {
	const userAccess = useContext(AccessContext);
	const hasRoleAccess = props.roles.some(role => userAccess.user.access_settings.settings.roles.includes(role));
	const element = authUtils.findAccessElement(props.access.path, userAccess.user.access_settings.access);
	return hasRoleAccess && element && element.allow ? props.children : null;
};

export default Access;
