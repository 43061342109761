export const SUPPORTED_PREFIX = {
	SUPPORTED_PREFIX: ['S', 's', 'Q', 'q', 'R', 'r'],
	SUPPORTED_PREFIX_LIBERO_G: ['9', '91', '92', '93', '94', '95', '96', '3', '32', '33', '34', '35', '36'],
	SUPPORTED_PREFIX_ECOLOG_PRO_G: ['W', 'w', 'X', 'x', 'Y', 'y'],
	SUPPORTED_TEMPLATE_VERSION: ['0', '1', '2'],
	AFTER_PREFIX: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
	AFTER_PREFIX_LIBERO_G_HEX: ['A', 'B', 'C', 'D', 'E', 'F'],
};

export const MODULE_TYPE = {
	NTR: 'NTR',
	PTR: 'PTR',
	THR: 'THR',
	LIBERO_GS: 'GS',
	LIBERO_GF: 'GF',
	LIBERO_GL: 'GL',
	LIBERO_GE: 'GE',
	LIBERO_GH: 'GH',
	LIBERO_GF_EXT: 'GF ext',
	ECOLOG_PRO_1TGi: 'TGi',
	ECOLOG_PRO_1THGi: 'THGi',
	ECOLOG_PRO_1TGe: 'TGe',
	LIBEROG: 'LIBEROG',
	LIBEROG_PRO: 'LIBEROG_PRO',
	ECOLOG_PRO_G: 'ECOLOG_PRO_G',
};

export const MODULE_FAMILIES = {
	ECOLOG_PRO_MODBUS: 'ECOLOG_PRO_MODBUS',
	ECOLOG_PRO_RADIO: 'ECOLOG_PRO_RADIO',
	LIBERO_G: 'LIBERO_G',
	ECOLOG_PRO_G: 'ECOLOG_PRO_G',
};

export const MODULE_TYPE_IMG = {
	NTR: '../img/module/1NTR.png',
	PTR: '../img/module/2PTR.png',
	THR: '../img/module/1THR.png',
	LIBEROG: '../img/module/LIBEROG.png',
	LIBEROG_PRO: '../img/module/LIBEROG.png',
	ECOLOG_PRO_G: '../img/module/ECOLOGPROG.png',
};

export const MODULE_HOW_TO_IMG = {
	NTR: '/img/connectModule/connect_how_to_1NTR.png',
	PTR: '/img/connectModule/connect_how_to_2PTR.png',
	THR: '/img/connectModule/connect_how_to_1THR.png',
	LIBEROG: '/img/connectModule/connect_how_to_LIBEROG.png',
	LIBEROG_PRO: '/img/connectModule/connect_how_to_LIBEROG.png',
	ECOLOG_PRO_G: '/img/connectModule/connect_how_to_ECOLOGPROG.png',
};
