import {Sensor} from '../Common/Types/Sensor';
import {ValueFormatterParams} from 'ag-grid-enterprise';
import {AlarmState} from '../../common/constants';

import i18n from 'i18next';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import dayjs from 'dayjs';

export function TranslateGlobalString(s: string): string {
	return i18n.t(`global.${s}`);
}

export function FormatAlarmState(alarmState: AlarmState): string {
	return i18n.t(`alarmStates.${alarmState}`).toUpperCase();
}

type FormatterParams<V> = ValueFormatterParams<Sensor, V>;

export function DefaultCellFormatter(v: FormatterParams<any>): string {
	if (v?.node.group && v.node.rowGroupColumn !== v.column) {
		return '';
	}
	if (!v?.value || v.value === '' || (Array.isArray(v.value) && v.value.length === 0)) {
		return i18n.t('global.noValue');
	}
	return v.value;
}

export function DateTimeFormatter(v: FormatterParams<dayjs.Dayjs | string>): string {
	if (!dayjs.isDayjs(v?.value)) {
		return DefaultCellFormatter(v);
	}

	const dateTime = v?.value as dayjs.Dayjs;
	return DateTimeUtils.toUserString(dateTime);
}
