import request from '../Infrastructure/Requests/Request';

function requestResetPassword(payload) {
    return request({
        url:    '/rpc/forgot_password',
        method: 'POST',
        data: payload
    });
}

const ForgotPasswordService = {
    requestResetPassword
};

export default ForgotPasswordService;