import React from 'react';

const CIRCLE_RADIUS = 13;

const CharsCounter = ({value, maxLength}) =>
{
    const circleRef = React.useRef(null);
    const [availableChars, setAvailableChars] = React.useState(maxLength);

    React.useEffect(() => {
        if (!!value && circleRef.current !== null) 
        {
            const roundPercent = 100 * value.length / maxLength;
            const roundCircum = 2 * CIRCLE_RADIUS * Math.PI;
            const roundDraw = roundPercent * roundCircum / 100;
            
            circleRef.current.style.strokeDasharray = `${roundDraw} 999`;
            setAvailableChars((maxLength - value.length));
        }
    }, [value])

    return !!value ?
        <div className="chars__counter">
            <svg ref={circleRef} 
                 className={`chars__counter--circle ${availableChars < 5 ? 'red--circle' : ''}`}
                 width="36" height="36"
                 viewBox="0 0 33 33" 
            >
                <circle cx="16" cy="16" r={CIRCLE_RADIUS} />
            </svg>

            <svg className="chars__counter--empty" 
                 width="36" height="36"
                 viewBox="0 0 33 33" 
            >
                <circle cx="16" cy="16" r={CIRCLE_RADIUS} />
            </svg>

            <span className="chars__counter--number">
                {availableChars}
            </span>
        </div> : 
        null;
}

export default CharsCounter;
