import React from 'react';
import {InputGroupAddon, Button} from 'reactstrap';
import MaskedInput from 'react-maskedinput';
import Message from '../../../Shared/Components/Message';
import BillingService from '../../../Settings/Billing/BillingService';
import RequestLogger from '../../../Infrastructure/Requests/Logger/RequestLogger';

class InputVoucher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invalid_voucher_counter: 0,
            voucher: '',
        };
    }

    render() {
        return (
            <div className="input-group">
                <MaskedInput
                    id="inputVoucher"
                    className="form-control"
                    mask="####-####-####"
                    value={this.state.voucher}
                    onChange={this.handleVoucherChange}
                />{' '}
                {/*https://github.com/insin/inputmask-core#pattern*/}
                <InputGroupAddon addonType="append">
                    <Button
                        color="primary"
                        onClick={this.onVoucherSubmit}
                        disabled={this.state.invalid_voucher_counter > 3 || this.props.readOnly}
                    >
                        Submit
                    </Button>
                </InputGroupAddon>
            </div>
        );
    }

    handleVoucherChange = event => {
        const input = event.target;

        this.setState(prevState => ({
            voucher: input.value.toUpperCase(),
        }));
    };

    onVoucherSubmit = e => {
        let voucher = this.state.voucher;
        const vouchers = this.props.vouchers;

        if (vouchers.length) {
            // check if voucher is not in list already
            for (let i = 0; i < vouchers.length; i++) {
                if (vouchers[i].code === voucher) {
                    Message.toastErr('Voucher already in offer');
                    return;
                }
            }
        }

        // check if voucher is okay
        BillingService.checkVoucher({voucher: voucher}, RequestLogger.createLogData('billing', 'check-voucher', 'onClick'))
            .then(response => {
                Message.success('Voucher redeemed', 'Voucher was successfully redeemed');
                this.setState({voucher: ''});

                // add voucher to voucher list, or let it be added to the list
                this.props.afterVoucherChecked(response.data);
            })
            .catch(error => {
                this.setState({invalid_voucher_counter: this.state.invalid_voucher_counter + 1});
                Message.error('Error', 'Voucher could not be validated', error);
            });

        e.preventDefault();
    };
}

export default InputVoucher;
