import NoRowsOverlay from './GridComponents/NoRowsOverlay';
import LoadingOverlay from './GridComponents/LoadingOverlay';

export const GridOptions = {
    statusBar: {
        statusPanels: [
            {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
            {statusPanel: 'agFilteredRowCountComponent', align: 'left'},
            {statusPanel: 'agSelectedRowCountComponent', align: 'left'},
        ],
    },
    
    noRowsOverlayComponent: NoRowsOverlay,
    loadingOverlayComponent: LoadingOverlay,
    containerStyle: {width: '100%', height: '100%'},
    theme: 'ag-theme-alpine',
    gridStyle: {height: 800, width: '100%'},
    rowSelection: 'single',
    colDefinationSettings: {minWidth: 100, sortable: true, resizable: true, enableRowGroup: false, suppressSizeToFit: false, filter: 'agSetColumnFilter'},
    enableRangeSelection: false,
    animateRows: true,
    enableCellChangeFlash: false,
    columnHoverHighlight: false,
    showOpenedGroup: true,
    groupDisplayType: 'multipleColumns',
    groupHideOpenParents: false,
    suppressContextMenu: true,
    autoGroupColumnDef: {        
        cellRendererParams:{            
        } 
    },
};
