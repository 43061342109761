import React, {Component} from 'react';
import {Select} from 'antd';
import {Row, Col, Label, FormGroup} from 'reactstrap';
const {Option} = Select;
import {getLoggingIntervalSelectComponentOptions} from '../../../../Wizard/Util/LoggingIntervalSelectComponentOptionsBuilder';

type LoggingIntervalSelectProps = {
	value: string | undefined;
	onChange: any;
	hasConfigurationTemplate: boolean;
	serialNumber: string;
	disabled: boolean;
};

class LoggingIntervalSelectState {
	onChange: any;
}

export class LoggingIntervalSelectComponent extends Component<LoggingIntervalSelectProps, LoggingIntervalSelectState> {
	constructor(props: LoggingIntervalSelectProps | Readonly<LoggingIntervalSelectProps>) {
		super(props);
		this.state = {
			onChange: props.onChange,
		};
	}

	getOptions = () => {
		let loggingIntervalSelectoptions = getLoggingIntervalSelectComponentOptions(this.props.serialNumber);
		return loggingIntervalSelectoptions.map(loggingIntervalOption => {
			return (
				<Option id={loggingIntervalOption.id} key={loggingIntervalOption.key} value={loggingIntervalOption.value}>
					{loggingIntervalOption.name}
				</Option>
			);
		});
	};

	render() {
		let loggingIntervalSelectoptions = getLoggingIntervalSelectComponentOptions(this.props.serialNumber);
		let object = loggingIntervalSelectoptions?.find(obj => {
			return obj.value === Number(this.props.value);
		});

		let value = object ? object.name : this.props.value;

		return (
			<Row>
				<Col xl={3}>
					<FormGroup>
						<Label>Logging interval</Label>
						<div>
							<Select
								style={{width: '100%'}}
								defaultValue={value}
								id="selectloggingInterval"
								value={value}
								onChange={this.state.onChange}
								disabled={this.props.disabled}
							>
								<>{this.getOptions()}</>
							</Select>
						</div>
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
