import {EventDetailType} from '../types';
import AuditTrailDetailTypes from '../../components/AuditTrail/AuditTrailDetailTypes';

export function GetAuditTrailDetailTranslationKey(eventDetailType: EventDetailType): string {
	const prefix = 'auditTrail.details';

	let translationKey = AuditTrailDetailTypes.find(t => t.type == eventDetailType)?.translate ?? '';

	if (translationKey.length === 0) {
		translationKey = EventDetailType[eventDetailType] ?? '';
	}

	if (translationKey.length === 0) {
		translationKey = 'Unknown';
	}

	return `${prefix}.${translationKey}`;
}
