import {CellClassRules} from 'ag-grid-enterprise';
import {Sensor} from '../Common/Types/Sensor';
import {AlarmState} from '../../common/constants';

export const LimitAlarmStateCellClassRules: CellClassRules<Sensor> = {
	'state-ok': params => params.data?.GetLimitAlarmState() === AlarmState.Ok,
	'state-alarm': params => params.data?.GetLimitAlarmState() === AlarmState.Alarm,
	'state-alarm-deactivated': params => params.data?.GetLimitAlarmState() === AlarmState.Deactivated,
	blurred: params => params.data?.IsBlurred,
};
