import {EcologProRadioType, EcologProXGType, LiberoGxType} from '../../constants/DeviceType';

export enum LineItemGenericTypes {
	Base = 'base',
	LoggingInterval = 'logging-interval',
	LimitEmail1 = 'limit-email-1',
	LimitSms1 = 'limit-sms-1',
	LimitEmail2 = 'limit-email-2',
	LimitSms2 = 'limit-sms-2',
	IssueEmail = 'issue-email',
	IssueSms = 'issue-sms',
	Retirement1 = 'retirement-1',
	Retirement2 = 'retirement-2',
}

export enum ActionType {
	Get = 'Get',
	Upgrade = 'Upgrade',
}

export enum ConfigurationOption {
	Single = 1,
	Together = 2,
}

export enum InternalProductCategories {
	BaseIPC = 'base-ipc',
	LoggingIntervalIPC = 'logging-interval-ipc',
	IssueProfileEmailIPC = 'issue-profile-email-ipc',
	IssueProfileSmsIPC = 'issue-profile-sms-ipc',
	LimitProfileEmailIPC = 'limit-profile-email-ipc',
	LimitProfileSmsIPC = 'limit-profile-sms-ipc',
	RetirementIPC = 'retirement-ipc',
}

export enum Channel {
	FirstChannel = '1',
	SecondChannel = '2',
}

export enum ValueIndex {
	FirstValueIndex = '0',
	SecondValueIndex = '1',
}

export enum PositionedSensor {
	FirstSensor = '1',
	SecondSensor = '2',
}

export const PositionedSensors = {
	[Channel.FirstChannel]: {
		[ValueIndex.FirstValueIndex]: [PositionedSensor.FirstSensor],
		[ValueIndex.SecondValueIndex]: [PositionedSensor.SecondSensor],
	},
	[Channel.SecondChannel]: {[ValueIndex.FirstValueIndex]: [PositionedSensor.SecondSensor]},
};

export enum ProfileIndex {
	FirstIndex = 0,
	SecondIndex = 1,
}

export const ConfigurationOptions = {
	[EcologProRadioType.OneNTR]: ConfigurationOption.Single,
	[EcologProRadioType.TwoPTR]: ConfigurationOption.Single,
	[EcologProRadioType.OneTHR]: ConfigurationOption.Single,
	[LiberoGxType.GS]: ConfigurationOption.Together,
	[LiberoGxType.GF]: ConfigurationOption.Together,
	[LiberoGxType.GL]: ConfigurationOption.Together,
	[LiberoGxType.GE]: ConfigurationOption.Together,
	[LiberoGxType.GH]: ConfigurationOption.Together,
	[LiberoGxType.GFExt]: ConfigurationOption.Together,
	[EcologProXGType.OneTGi]: ConfigurationOption.Together,
	[EcologProXGType.OneTGe]: ConfigurationOption.Together,
	[EcologProXGType.OneTHGi]: ConfigurationOption.Together,
};
