import FormUtils from '../../FormUtils';
import {getInfoTextValue} from '../../Shared/LCDDisplayHelper';

/**
 * Sections that needs special mapping
 */
export const SECTION_KEY = {
    METAS: 'metadatas',
    ISSUE: 'additional_issues',
    DISPLAY: 'display_settings',
    OPERT: 'device_operation',
};

const mapToAdditionalConfiguration = (data, coreData = null) => {
    const {[SECTION_KEY.ISSUE]: add_issues, [SECTION_KEY.DISPLAY]: dis_setings, [SECTION_KEY.OPERT]: dvc_operations, ...rest} = data;

    if (!!data[SECTION_KEY.METAS] && !!coreData) {
        coreData.sensorBasicsForm.sensor_name = data[SECTION_KEY.METAS][Object.keys(data[SECTION_KEY.METAS])[0]];
    }

    return {
        ...rest,

        configuration_templates_id: data.configuration_templates_id,

        shock_detection: !!add_issues
            ? {
                  enable: add_issues.shock_sensor_alarm,
                  limit: add_issues.shock_sensor_alarm_threshold,
                  samples: 1,
              }
            : data.shock_detection,
        tilt_detection: !!add_issues
            ? {
                  enable: add_issues.tilt_sensor_alarm,
                  selected_reference_position: add_issues.initial_logger_position,
                  reference_x: !!add_issues.initial_logger_position ? add_issues.initial_logger_position.split('/')[0] : undefined,
                  reference_y: !!add_issues.initial_logger_position ? add_issues.initial_logger_position.split('/')[1] : undefined,
                  reference_z: !!add_issues.initial_logger_position ? add_issues.initial_logger_position.split('/')[2] : undefined,
                  limit_angle: add_issues.tilt_sensor_alarm_threshold,
              }
            : data.tilt_detection,
        light_detection: !!add_issues
            ? {
                  enable_low: add_issues.activate_lower_light_sensor_alarm,
                  enable_high: add_issues.activate_upper_light_sensor_alarm,
                  limit_low: add_issues.lower_light_sensor_alarm,
                  limit_high: add_issues.upper_light_sensor_alarm,
              }
            : data.light_detection,

        info_texts: !!dis_setings
            ? {
                  ...dis_setings,
                  ...Object.keys(dis_setings).reduce((acc, key) => {
                      if (Array.isArray(dis_setings[key])) {
                          let infoText = [];
                          dis_setings[key].forEach(itemValue => {
                              if (FormUtils.isPlaceHolder(itemValue)) {
                                  const placeHolderValue = FormUtils.getPlaceHolderValue({info_texts: dis_setings}, itemValue);
                                  const infoTextValue = getInfoTextValue(placeHolderValue);
                                  infoText = infoText.concat(infoTextValue);
                              } else {
                                  infoText.push(itemValue);
                              }

                              infoText = infoText.filter(v => !!v);
                          });
                          return {...acc, [key]: infoText};
                      }
                      return acc;
                  }, {}),
              }
            : data.info_texts,

        start_logging_options: !!dvc_operations
            ? {
                  trigger: dvc_operations.start_logging_trigger === 'immediately' ? 'time' : dvc_operations.start_logging_trigger,
                  timestamp: dvc_operations.start_logging_trigger === 'immediately' ? 0 : dvc_operations.start_logging_timestamp,
              }
            : data.start_logging_options,
        start_transit_options: !!dvc_operations
            ? {
                  trigger: dvc_operations.start_transit_trigger === 'immediately' ? 'time_delay' : dvc_operations.start_transit_trigger,
                  time_delay: dvc_operations.start_transit_trigger === 'immediately' ? 0 : dvc_operations.start_transit_time_delay,
                  light_threshold: dvc_operations.start_transit_light_threshold,
                  movement_threshold: dvc_operations.start_transit_movement_threshold,
              }
            : data.start_transit_options,
        stop_transit_options: !!dvc_operations
            ? {
                  triggers: dvc_operations.stop_transit_triggers,
                  light_threshold: dvc_operations.stop_transit_light_threshold || dvc_operations.stop_transit_light_cloud_threshold,
              }
            : data.stop_transit_options,
        stop_logging_options: !!dvc_operations
            ? {
                  triggers: dvc_operations.stop_logging_triggers,
              }
            : data.stop_logging_options,
        multiuse: !!dvc_operations ? dvc_operations.multiuse : data.multiuse,

        new_run_by_button_enabled: !!dvc_operations ? dvc_operations.new_run_by_button_enabled : data.new_run_by_button_enabled,
        
    };
};

const mapToTemplateResult = (section, data, schema = {}) => {
    switch (section) {
        case SECTION_KEY.ISSUE:
            return {
                issue_alarms: !!data.light_detection
                    ? data.light_detection['enable_high'] ||
                      data.light_detection['enable_low'] ||
                      data.tilt_detection['enable'] ||
                      data.tilt_detection['enable']
                    : undefined,
                activate_upper_light_sensor_alarm: !!data.light_detection ? data.light_detection['enable_high'] : undefined,
                upper_light_sensor_alarm: !!data.light_detection ? data.light_detection['limit_high'] : undefined,
                activate_lower_light_sensor_alarm: !!data.light_detection ? data.light_detection['enable_low'] : undefined,
                lower_light_sensor_alarm: !!data.light_detection ? data.light_detection['limit_low'] : undefined,
                shock_sensor_alarm: !!data.shock_detection ? data.shock_detection['enable'] : undefined,
                shock_sensor_alarm_threshold: !!data.shock_detection ? data.shock_detection['limit'] : undefined,
                tilt_sensor_alarm: !!data.tilt_detection ? data.tilt_detection['enable'] : undefined,
                initial_logger_position: !!data.tilt_detection ? data.tilt_detection.selected_reference_position : undefined,
                tilt_sensor_alarm_threshold: !!data.tilt_detection ? data.tilt_detection['limit_angle'] : undefined,
            };

        case SECTION_KEY.DISPLAY:
            const before_start_i = !!schema.start ? schema.start.default.indexOf('%info_texts.before_start%') : undefined;
            const in_transit_i = !!schema.log_transit ? schema.log_transit.default.indexOf('%info_texts.in_transit%') : undefined;
            const after_stop_i = !!schema.stop ? schema.stop.default.indexOf('%info_texts.after_stop%') : undefined;

            return {
                before_start: !!data.info_texts
                    ? data.info_texts['before_start'] ||
                      (!!data.info_texts['start']
                          ? (data.info_texts['start'][before_start_i] || '') + (data.info_texts['start'][before_start_i + 1] || '')
                          : undefined)
                    : undefined,
                in_transit: !!data.info_texts
                    ? data.info_texts['in_transit'] ||
                      (!!data.info_texts['log_transit']
                          ? (data.info_texts['log_transit'][in_transit_i] || '') + (data.info_texts['log_transit'][in_transit_i + 1] || '')
                          : undefined)
                    : undefined,
                after_stop: !!data.info_texts
                    ? data.info_texts['after_stop'] ||
                      (!!data.info_texts['stop']
                          ? (data.info_texts['stop'][after_stop_i] || '') + (data.info_texts['stop'][after_stop_i + 1] || '')
                          : undefined)
                    : undefined,
            };

        case SECTION_KEY.OPERT:
            return {
                start_logging_timestamp: !!data.start_logging_options ? data.start_logging_options['timestamp'] : undefined,
                start_transit_time_delay: !!data.start_transit_options ? data.start_transit_options['time_delay'] : undefined,
                start_transit_light_threshold: !!data.start_transit_options ? data.start_transit_options['light_threshold'] : undefined,
                start_transit_movement_threshold: !!data.start_transit_options
                    ? data.start_transit_options['movement_threshold']
                    : undefined,
                stop_transit_triggers: !!data.stop_transit_options ? data.stop_transit_options['triggers'] : undefined,
                stop_transit_light_threshold: !!data.stop_transit_options ? data.stop_transit_options['light_threshold'] : undefined,
                stop_transit_light_cloud_threshold: !!data.stop_transit_options ? data.stop_transit_options['light_threshold'] : undefined,
                stop_logging_triggers: !!data.stop_logging_options ? data.stop_logging_options['triggers'] : undefined,

                //multiuse always reconfigure until further notice
                multiuse: !!data.multiuse ? data['multiuse'] : undefined,
                new_run_by_button_enabled: !!data.new_run_by_button_enabled ? data['new_run_by_button_enabled'] : undefined,

                start_logging_trigger: !!data.start_logging_options
                    ? data.start_logging_options['trigger'] === 'time' && data.start_logging_options['timestamp'] === 0
                        ? 'immediately'
                        : data.start_logging_options['trigger']
                    : undefined,

                start_transit_trigger: !!data.start_transit_options
                    ? data.start_transit_options['trigger'] === 'time_delay' && data.start_transit_options['time_delay'] === 0
                        ? 'immediately'
                        : data.start_transit_options['trigger']
                    : undefined,
            };

        default:
            return data[section];
    }
};

export const LiberoGxMapperService = {
    mapToAdditionalConfiguration,
    mapToTemplateResult,
};
