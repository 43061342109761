import {asyncServerRequest} from '../../Infrastructure/Requests/ServerRequest';

export async function loadUsersDataSource() {
	const payload = {
		url: 'settings/user-management/users',
		method: 'GET',
	};
	try {
		const response = await asyncServerRequest(payload);
		return response.data.users;
	} catch (error) {
		console.error(error);
	}
}

export async function deleteUser(deleteData) {
	const payload = {
		url: 'settings/user-management/user/' + deleteData.delete_user_id,
		method: 'DELETE',
		data: deleteData,
	};
	try {
		const response = await asyncServerRequest(payload);
		const {data, status, statusText} = response;
		return {data, status, statusText};
	} catch (error) {
		const {message, status} = error.response.data.error;
		return {message, status};
	}
}

export async function sendActivationMail(id) {
	const payload = {
		url: 'settings/user-management/user/send-activation-mail/' + id,
		method: 'GET',
	};
	try {
		const response = await asyncServerRequest(payload);
		const {data, status, statusText} = response;
		return {data, status, statusText};
	} catch (error) {
		const {message, status} = error.response.data.error;
		return {message, status};
	}
}

export async function getOrganizationSetting(payload) {
	const payloadData = {
		url: 'settings/organization-configuration/setting/' + payload,
		method: 'GET',
	};
	try {
		return await asyncServerRequest(payloadData);
	} catch (error) {
		console.error(error);
	}
}
