import {Sensor} from '../../components/Common/Types/Sensor';
import {History} from 'history';
import {Paths} from '../constants';
import {SensorService} from '../services';
import dayjs from 'dayjs';
import CalibrationService from '../../components/Services/Calibrations/CalibrationService';
import {getAdditionallyAffectedSensorsAsync} from '../util/SensorChannelGrouping';
import {CalibrationState} from '../../components/Calibration/Model/CalibrationState';
import {DeviceUtils} from '../util';
import {UI_EVENT} from '../../components/Shared/Constants/Events';
import pubsub from 'pubsub-js';
import Message from '../../components/Shared/Components/Message';
import { LicensingExtension } from '../../common/util/LicenseUtils/LicensingExtension';

export class SensorActions {
	public static Analyse(sensor: Sensor, history: History) {
		history.push(`${Paths.Sensor.Analysis}/${sensor.Id}`, {sensor: sensor});
	}

	public static async ConfigureSensor(sensor: Sensor, history: History): Promise<void> {
		if (DeviceUtils.IsEcologProXG(sensor) || DeviceUtils.IsLiberoGx(sensor)) {
			const pathBase = DeviceUtils.IsLiberoGx(sensor) ? '/startNewRun/' : '/editEcologProGx/';
			try {
				const {AffectedSensors, Configuration} = await SensorService.DiscoverRun(sensor);
				history.push(pathBase + sensor.Id, {
					serial_number: sensor.SerialNumber,
					additional_configuration: Configuration,
					sensors: AffectedSensors,
				});
				return;
			} catch (e) {
				return await Promise.reject(e);
			}
		}

		history.push('/editSensor/' + sensor.Id, {serial_number: sensor.SerialNumber, sensor_state: sensor.State});
	}

	public static async DownloadCertificate(sensor: Sensor): Promise<void> {
		const url = `https://compliance.elpro.com:50000/PublicWebFacadeService/Rest/GetReport?textInput=${sensor.SerialNumber}`;

		const reqHeaders = new Headers();
		reqHeaders.append('Authorization', 'Basic RWxwcm9MaWJlcm9Qb3J0YWw6VUhWaWJHbGpWMlZpUm1GallXUmxVMlZ5ZG1salpUSXdNakF0TURndE1EYz0=');

		const reqOptions: RequestInit = {
			method: 'GET',
			headers: reqHeaders,
			redirect: 'follow',
		};

		fetch(url, reqOptions)
			.then(response => {
				return response.blob();
			})
			.then(blob => {
				const data = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.download = `${dayjs().format('YYYY-MM-DD')}_ProductionCalibrationCertificate_${sensor.SerialNumber}.pdf`;
				link.href = data;
				link.click();
				link.remove();
			})
			.catch(error => console.error('error', error));
	}

	public static ManageLicense(_: Sensor, history: History) {
		history.push('/licenses');
	}

	public static RetireSensor(sensor: Sensor, history: any, devicesViewSettings: any) {
		LicensingExtension.HasSubscription(sensor.ModuleId?.toString()).then(hasSubscription => {
			if (hasSubscription) {
				history.push({
					pathname: '/retireLicenses',
					state: {sensor: sensor, isForSubscription: true, devicesViewSettings: devicesViewSettings},
				});
			} else {
				Message.error('This sensor has no active subscription.');
			}
		});
	}

	public static ReplaceSensor(sensor: Sensor, history: History) {
		history.push('/replaceSensor/' + sensor.Id, {serial_number: sensor.SerialNumber});
	}

	public static async SetAlarmingEnabled(sensor: Sensor, alarmingEnabled: boolean): Promise<void> {
		return await SensorService.SetAlarmingEnabled(sensor, alarmingEnabled);
	}

	public static ShowSummary(sensor: Sensor, history: History) {
		history.push('/sensorShowSummary/' + sensor.Id, {serial_number: sensor.SerialNumber});
	}

	public static async StopCalibration(sensor: Sensor): Promise<void> {
		const calibrationService = new CalibrationService();
		let affectedSensors: Sensor[] = [];
		try {
			await calibrationService.tryStopCalibration(sensor.Id);
			affectedSensors = await getAdditionallyAffectedSensorsAsync(sensor);
		} catch (e) {
			return await Promise.reject(e);
		}

		sensor.CanStartCalibration = false;
		sensor.CanStopCalibration = false;

		pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${sensor.Id}`, {
			sensorId: sensor.Id,
		});

		affectedSensors.forEach(affectedSensor => {
			affectedSensor.CalibrationState = CalibrationState.Pending;
			affectedSensor.CanStartCalibration = false;
			affectedSensor.CanStopCalibration = false;

			pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${affectedSensor.Id}`, {
				sensorId: affectedSensor.Id,
			});
		});
	}
}
