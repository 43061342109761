import '@babel/polyfill';
import React from 'react';
import {LicenseManager} from 'ag-grid-enterprise';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import {createRoot} from 'react-dom/client';
const {AG_GRID_LICENCE_KEY} = process.env;

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister();
