import request from '../Infrastructure/Requests/Request';

function request_bridge_configuration(payload, eventData) {
    return request({
        url: '/rpc/request_bridge_configuration',
        method: 'POST',
        data: payload,
        eventData,
    });
}

function confirm_sms_code(payload) {
    return request({
        url: '/rpc/confirm_sms_code',
        method: 'POST',
        data: payload,
    });
}

function save_configuration(payload) {
    return request({
        url: '/rpc/save_configuration',
        method: 'POST',
        data: payload,
    });
}

const BridgeConfigurationService = {
    request_bridge_configuration,
    confirm_sms_code,
    save_configuration,
};

export default BridgeConfigurationService;
