import React, {Component} from 'react';
import {Card, CardBody} from 'reactstrap';

class AlarmStateComponent extends Component {
    constructor(props) {
        super(props);
    }

    getImage() {
        let obj = {alarmStatusId: '', alarmStatusImage: ''};
        if (this.props.isAlarmed) {
            obj.alarmStatusId = 'status-alarm';
            obj.alarmStatusImage = '/img/alarmState/Alarm.png';
            return obj;
        } else {
            obj.alarmStatusId = 'status-ok';
            obj.alarmStatusImage = '/img/alarmState/AlarmOK.png';
            return obj;
        }
    }

    render() {
        let obj = this.getImage();
        return (
            <div style={{display: 'flex', justifyContent: 'right', marginRight: '25px', marginTop: '5px', marginBottom: '-25px'}}>
                <Card>
                    <CardBody id={obj.alarmStatusId}>
                        <img src={obj.alarmStatusImage} />
                    </CardBody>
                </Card>
            </div>
        );
    }
}
export default AlarmStateComponent;
