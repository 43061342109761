import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from 'reactstrap';

const wrapper = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
};

const stateMsgStyle = {
    minWidth: '230px',
};

const connectedMsg = {
    color: '#009603',
};

const toDashboardBtn = {
    padding: '0 0 0 12px',
};

const ConnectModuleState = ({sensorState, sensorType, close}) => {
    const waitForNotificationMessage = type => {
        return ['S', 'Q', 'R'].includes(type[0].toUpperCase());
    };

    const [state, setState] = useState(sensorState);
    const [type] = useState(sensorType);
    const [wait] = useState(waitForNotificationMessage(type));

    useEffect(() => {
        setState(sensorState);
    }, [sensorState]);

    if (!type) {
        return null;
    }

    return (
        <Row>
            <Col md="12" xs="12" sm="12">
                <div style={wrapper}>
                    {wait ? (
                        <div style={stateMsgStyle}>
                            {state === 'prepared' ? (
                                <>
                                    <span>Waiting for module</span>
                                    <span className="dot dot1">.</span>
                                    <span className="dot dot2">.</span>
                                    <span className="dot dot3">.</span>
                                </>
                            ) : (
                                <span style={connectedMsg}>Module connected successfully! </span>
                            )}
                        </div>
                    ) : null}

                    {wait && (
                        <div>
                            <Button color="primary" size="m" disabled={state !== 'running'} onClick={close}>
                                OK
                            </Button>
                        </div>
                    )}
                    <div style={toDashboardBtn}>
                        <Button color="primary" size="m" onClick={close}>
                            Return
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default ConnectModuleState;
