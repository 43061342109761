import {asyncServerRequest} from '../Infrastructure/Requests/ServerRequest';
import request from '../Infrastructure/Requests/Request';
import Authentication from '../Infrastructure/Authentication/Authentication';

function getLiveLink(payload, eventData) {
    return request({
        url: '/rpc/live_link_create',
        method: 'POST',
        data: payload,
        token: Authentication.getToken(),
        eventData,
    });
}

const validateLiveLink = async payload => {
    const payloadData = {
        url: 'livelink/livelink-validation/' + payload,
        method: 'GET',
    };
    try {
        const response = await asyncServerRequest(payloadData);
        const {
            data: {token},
        } = response;
        return token;
    } catch (error) {
        //TODO
        console.error(error);
    }
};

const getUserAccess = async payload => {
    const payloadData = {
        url: 'auth/access/' + payload,
        method: 'GET',
    };
    try {
        return await asyncServerRequest(payloadData);
    } catch (error) {
        //TODO
        console.error(error);
    }
};

const DeviceStatusService = {

    validateLiveLink,
    getUserAccess,
    getLiveLink
};

export default DeviceStatusService;
