import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import {MODULE_HOW_TO_IMG} from '../../../Shared/Constants/Module';

const descriptionStyle = {
	lineHeight: '27px',
	padding: '30px 0 30px 0',
	whiteSpace: 'pre-line',
};

const getImage = sensorType => {
	switch (sensorType) {
		case 'S':
			return MODULE_HOW_TO_IMG.NTR;
		case 'Q':
			return MODULE_HOW_TO_IMG.PTR;
		case 'R':
			return MODULE_HOW_TO_IMG.THR;
		case 'W':
		case 'X':
		case 'Y':
			return MODULE_HOW_TO_IMG.ECOLOG_PRO_G;
		case '91':
		case '92':
		case '93':
		case '94':
		case '95':
		case '96':
		case '32':
		case '33':
		case '34':
		case '35':
		case '36':
			return MODULE_HOW_TO_IMG.LIBEROG;
		default:
			return MODULE_HOW_TO_IMG.NTR;
	}
};

const getDescription = (sensorType, serialNumber, pairingDuration) => {
	switch (sensorType) {
		case 'S':
			return `In order to connect the measurement module, open the rear panel and press the “Connect” button for 3 seconds until the red LED starts to flash. (4)
                    During the connection process both LEDs labeled with “Link” will flash. (4a)
                    After a successful connection, the green LED will flash periodically. (4b)
                    The “OK” button will turn active. Click on the button to finish the connection process.
                    You have ${pairingDuration} minutes time for the connection process.`;
		case 'Q':
			return `In order to connect the measurement module, open the rear panel and press the “Connect” button for 3 seconds until the red LED starts to flash. (4)
                    During the connection process both LEDs labeled with “Link” will flash. (4a)
                    After a successful connection, the green LED will flash periodically. (4b)
                    The “OK” button will turn active. Click on the button to finish the connection process.
                     You have ${pairingDuration} minutes time for the connection process.`;
		case 'R':
			return `In order to connect the measurement module, open the rear panel and press the “Connect” button for 3 seconds until the red LED starts to flash. (4)
                    During the connection process both LEDs labeled with “Link” will flash. (4a)
                    After a successful connection, the green LED will flash periodically. (4b)
                    The “OK” button will turn active. Click on the button to finish the connection process.
                     You have ${pairingDuration} minutes time for the connection process.`;
		case 'W':
		case 'X':
		case 'Y':
			return null;
		case '91':
		case '92':
		case '93':
		case '94':
		case '95':
		case '96':
		case '32':
		case '33':
		case '34':
		case '35':
		case '36':
			return null;
		default:
			return `No instructions found...`;
	}
};

const ConnectModuleHowTo = ({sensorType, serialNumber, pairingDuration}) => {
	const [howToImage] = useState(getImage(sensorType));
	const [howToDescription] = useState(getDescription(sensorType, serialNumber, pairingDuration));

	return (
		<Row>
			<Col md="12" xs="12" sm="12">
				{howToImage && <img src={howToImage} alt="" />}
			</Col>
			<Col md="12" xs="12" sm="12">
				<p style={descriptionStyle}> {howToDescription} </p>
			</Col>
		</Row>
	);
};

export default ConnectModuleHowTo;
