function b64toFile(b64Data, filename, contentType) {
    console.log("b64toFile");
    const sliceSize = 512;
    console.log("before atob");
    const byteCharacters = atob(b64Data);
    let byteArrays = [];

    console.log("before for");
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    console.log("before file");
    return new File(byteArrays, filename, {type: contentType});
}


const DevelopmentUtils = {
    b64toFile
};

export default DevelopmentUtils;