import React from 'react';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import {Checkbox, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {TimeRangePicker, TimeRangePickerProps} from './TimeRangePicker';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import styles from '../styles.module.css';

export function DeviationFilterAccordion(props: {
	title: string;
	timeRangePickerProps: TimeRangePickerProps;
	showActiveOrUnacknowledged: {value: boolean; set: React.Dispatch<boolean>};
}): React.JSX.Element {
	const [t] = useTranslation();

	function onShowActiveOrUnacknowledgedChange(e: CheckboxChangeEvent): void {
		props.showActiveOrUnacknowledged.set(e.target.checked);
	}

	return (
		<Accordion>
			<AccordionItem id={'accordionFilter'} expanded={true}>
				<AccordionItemTitle>
					<h5 className="u-position-relative">
						{t(props.title).toString()}
						<div className="accordion__arrow" role="presentation" />
					</h5>
				</AccordionItemTitle>
				<AccordionItemBody>
					<TimeRangePicker
						onTimeRangeChanged={props.timeRangePickerProps.onTimeRangeChanged}
						value={props.timeRangePickerProps.value}
					/>

					<Row className={styles.ComponentRow}>
						<Checkbox checked={props.showActiveOrUnacknowledged.value} onChange={onShowActiveOrUnacknowledgedChange}>
							Active or unacknowledged only
						</Checkbox>
					</Row>
				</AccordionItemBody>
			</AccordionItem>
		</Accordion>
	);
}
