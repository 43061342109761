import {GetUnitStringFromOutUnit} from './GetUnitStringFromOutUnit';

export function WithUnitString(value: number, outUnitId: number): string {
	const unit: string = GetUnitStringFromOutUnit(outUnitId);
	return `${value} ${unit}`.trim();
}

export function ConvertedMeasurementWithUnitString(value: number, unit: number): string {
	const convertedValue = ConvertMeasurementValue(value, unit);
	return WithUnitString(convertedValue, unit);
}

function ConvertMeasurementValue(value: number, unit: number): number {
	if (unit === undefined) unit = 2;

	if (value === null || value <= Number.MIN_VALUE || value >= Number.MAX_VALUE) {
		return undefined;
	} else {
		switch (unit) {
			case 2:
				return Math.round((value - 273.15) * 10) / 10; // K -> °C
			case 3:
				return Math.round((1.8 * value - 459.67) * 10) / 10; // K -> °F
			case 4:
				return parseFloat(value.toFixed(1));
			default:
				return value;
		}
	}
}
