import React from 'react';
import {RecipientSelectState} from '../../../../../../Wizard/FormItems/RecipientSelectState';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';
import {useTranslation} from 'react-i18next';

export function RecipientSelectRows(props: {recipientSelectState: RecipientSelectState}) {
	const [t] = useTranslation();

	return (
		<>
			{props.recipientSelectState.email.enabled ? (
				<tr>
					<td>E-Mail recipients</td>
					<td>{props.recipientSelectState.email.recipients.map(u => u.GetLabel('Email', t)).join(', ')}</td>
				</tr>
			) : (
				<tr>
					<td>E-Mail enabled</td>
					<td>{CONSTANTS.NO}</td>
				</tr>
			)}

			{props.recipientSelectState.sms.enabled ? (
				<tr>
					<td>SMS recipients</td>
					<td>{props.recipientSelectState.sms.recipients.map(u => u.GetLabel('Mobile', t)).join(', ')}</td>
				</tr>
			) : (
				<tr>
					<td>SMS enabled</td>
					<td>{CONSTANTS.NO}</td>
				</tr>
			)}
		</>
	);
}
