import RequestLogger from './RequestLogger';

const hookRequestData = requestData => {
    const request = Object.assign({}, ...['id', 'type', 'data', 'requestURL', 'method', 'eventData'].map(k => ({[k]: requestData[k]})));
    RequestLogger.log(request);
};

const hookResponseData = responseData => {
    const response = {
        id: responseData.config.id,
        url: responseData.config.url,
        data: responseData.data,
        type: responseData.type,
    };
    RequestLogger.log(response);
};

export default {hookRequestData, hookResponseData};
