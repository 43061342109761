import React from 'react';

export default function GetSensorBodyHumidityPath({
	alarmDeactivatedColor,
	colors,
	fillingColor,
	hasLimits,
	height,
	lastMeasurementOutsideLimits,
	isRetired,
	muted,
	strokeColor,
	y,
}) {
	return (
		<g transform="translate(500, 0)">
			<path
				d="M4784 9463c-591,0 -1153,-233 -1571,-651 -418,-418 -650,-980 -650,-1571 0,-5 0,-10 0,-15 2,-506 93,-1018 285,-1488 1,-2 2,-4 3,-7 120,-273 245,-545 379,-812 83,-167 171,-331 258,-496 166,-318 302,-634 433,-968 216,-555 342,-1128 454,-1711 80,-415 119,-838 119,-1261 0,-160 130,-290 290,-290 159,0 289,130 289,290 0,423 39,846 119,1261 112,583 238,1156 455,1711 130,334 266,650 433,968 86,165 174,329 258,496 134,267 258,539 378,812 1,3 2,5 3,7 192,470 283,982 286,1488 0,5 0,10 0,15 0,591 -233,1153 -651,1571 -418,418 -980,651 -1570,651z"
				id="InnerFill"
				fill="white"
			/>
			{muted || isRetired ? (
				<rect height={height} width="4442" y={y} x="2563" fill={alarmDeactivatedColor} id="sensorMuted" />
			) : (
				<rect
					height={height}
					width="4442"
					y={y}
					x="2563"
					fill={!hasLimits ? colors.noValue : lastMeasurementOutsideLimits ? colors.alarm : colors.noAlarm}
					id={!hasLimits ? 'sensorNoValue' : lastMeasurementOutsideLimits ? 'sensorAlarm' : 'sensorNoAlarm'}
				/>
			)}
			<path
				id="Shine"
				fill="white"
				d="M4578 8954c8,1 16,2 25,2 100,0 181,-81 181,-181 0,-92 -69,-168 -159,-180 -421,-49 -803,-291 -1024,-662 -9,-14 -19,-27 -30,-38 -47,-47 -118,-67 -185,-44 -67,22 -112,79 -122,143 -5,28 -3,57 7,85 4,14 10,26 18,38 279,469 760,774 1289,837z"
			/>
			<path
				id="InnerContour"
				fill="white"
				d="M4784 9173c533,0 1016,-216 1365,-566 350,-350 566,-833 566,-1366 0,-4 0,-9 0,-13 -2,-503 -98,-974 -264,-1380 -568,-1297 -651,-1188 -1074,-2288 -262,-656 -389,-1342 -469,-1761 -81,-419 -124,-860 -124,-1316 0,456 -43,897 -124,1316 -81,419 -208,1105 -469,1761 -424,1100 -506,991 -1074,2288 -166,406 -262,877 -265,1380 0,4 0,9 0,13 0,533 217,1016 566,1366 350,350 833,566 1366,566zm0 290c-591,0 -1153,-233 -1571,-651 -418,-418 -650,-980 -650,-1571 0,-5 0,-10 0,-15 2,-506 93,-1018 285,-1488 1,-2 2,-4 3,-7 120,-273 245,-545 379,-812 83,-167 171,-331 258,-496 166,-318 302,-634 433,-968 216,-555 342,-1128 454,-1711 80,-415 119,-838 119,-1261 0,-160 130,-290 290,-290 159,0 289,130 289,290 0,423 39,846 119,1261 112,583 238,1156 455,1711 130,334 266,650 433,968 86,165 174,329 258,496 134,267 258,539 378,812 1,3 2,5 3,7 192,470 283,982 286,1488 0,5 0,10 0,15 0,591 -233,1153 -651,1571 -418,418 -980,651 -1570,651z"
			/>
			<path
				id="ColorAccordingToBackground"
				fill={fillingColor}
				d="M4784 9656c-642,0 -1253,-254 -1707,-707 -454,-454 -708,-1066 -708,-1708 0,-5 0,-10 0,-15 3,-532 99,-1068 301,-1561 1,-4 3,-7 4,-11 122,-277 247,-552 383,-822 84,-168 172,-333 259,-499 162,-308 299,-625 425,-949 211,-541 335,-1108 445,-1676 77,-404 115,-815 115,-1225 0,-266 216,-483 483,-483 266,0 483,217 483,483 0,410 37,821 115,1225 110,568 234,1135 445,1676 126,324 263,641 424,949 87,166 176,331 260,499 135,270 261,545 382,822 2,4 3,7 5,11 201,493 297,1029 300,1561 0,5 0,10 0,15 0,642 -253,1254 -707,1708 -454,453 -1065,707 -1707,707zm-2415 -9656l4829 0 0 9656 -4829 0 0 -9656z"
			/>
			<path
				id="OuterContour"
				fill={strokeColor}
				d="M4784 9463c-591,0 -1153,-233 -1571,-651 -418,-418 -650,-980 -650,-1571 0,-5 0,-10 0,-15 2,-506 93,-1018 285,-1488 1,-2 2,-4 3,-7 120,-273 245,-545 379,-812 83,-167 171,-331 258,-496 166,-318 302,-634 433,-968 216,-555 342,-1128 454,-1711 80,-415 119,-838 119,-1261 0,-160 130,-290 290,-290 159,0 289,130 289,290 0,423 39,846 119,1261 112,583 238,1156 455,1711 130,334 266,650 433,968 86,165 174,329 258,496 134,267 258,539 378,812 1,3 2,5 3,7 192,470 283,982 286,1488 0,5 0,10 0,15 0,591 -233,1153 -651,1571 -418,418 -980,651 -1570,651zm0 193c-642,0 -1253,-254 -1707,-707 -454,-454 -708,-1066 -708,-1708 0,-5 0,-10 0,-15 3,-532 99,-1068 301,-1561 1,-4 3,-7 4,-11 122,-277 247,-552 383,-822 84,-168 172,-333 259,-499 162,-308 299,-625 425,-949 211,-541 335,-1108 445,-1676 77,-404 115,-815 115,-1225 0,-266 216,-483 483,-483 266,0 483,217 483,483 0,410 37,821 115,1225 110,568 234,1135 445,1676 126,324 263,641 424,949 87,166 176,331 260,499 135,270 261,545 382,822 2,4 3,7 5,11 201,493 297,1029 300,1561 0,5 0,10 0,15 0,642 -253,1254 -707,1708 -454,453 -1065,707 -1707,707z"
			/>
		</g>
	);
}
