import React from 'react';
import {useTranslation} from 'react-i18next';
import {ContentWrapper, ViewWrapper} from '../Layout';
import {ViewHeader} from '../Common';
import {Col} from 'antd';
import {AddSensorButton} from '../Buttons';

import {SensorDataGrid} from './SensorDataGrid';

export function SensorListView(): React.JSX.Element {
	const [t] = useTranslation();
	return (
		<ViewWrapper>
			<ViewHeader knowledgeHelpId={'009'} heading={t('datagrid.view')}>
				<Col>
					<AddSensorButton />
				</Col>
			</ViewHeader>
			<ContentWrapper>
				<SensorDataGrid />
			</ContentWrapper>
		</ViewWrapper>
	);
}
