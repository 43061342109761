import React from 'react';
import LoadingTie from './LoadingTie';

const DeviationDurationOutsideLimitTie = props => {
    return (
        <div className="card card-default" style={{height: '100%'}} id="deviationOutsideLimit">
            <div className="card-body text-center">
                {props.deviation_outside_limit.duration ? (
                    <>
                        <h4>Duration Outside Limit</h4>
                        <br />
                        <h3> {props.deviation_outside_limit.duration}</h3>
                        <br />
                    </>
                ) : (
                    <LoadingTie />
                )}
            </div>
        </div>
    );
};

export default DeviationDurationOutsideLimitTie;
