import React, {useContext, useEffect, useState} from 'react';
import pubsub from 'pubsub-js';
import {AccessPermissions, UserRoles} from '../../Infrastructure/Authorization/Access';
import {Access} from '../../Infrastructure/Authorization/Components';
import {Card, ViewHeader} from '../../Common';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, Col, Form, Input, InputNumber, Row, Select} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {SystemSettingsService} from '../../../common/services';
import {Country, Organization} from '../../../common/types';
import {formProps} from '../../../common/props/AntFormProps';
import {MapToSelectOpts} from '../../../common/util';
import {OutUnitMap} from '../../../common/constants';
import {UI_EVENT} from '../../Shared/Constants/Events';
import {ErrorAlert, Success} from '../../Common/Alerts';
import {ContentWrapper, ViewWrapper} from '../../Layout';
import {AuthContext} from '../../../common/context';
import {UpdateOrganization} from '../../../common/services/WebserverServicePro/OrganizationService';

const {TextArea} = Input;

export function OrganizationForm(): React.JSX.Element {
	const [t] = useTranslation();
	const authContext = useContext(AuthContext);
	const [form] = Form.useForm();
	const [editing, setEditing] = useState(false);
	const [countries, setCountries] = useState<Country[]>([]);
	const [valuesHaveChanged, setValuesHaveChanged] = useState(false);
	const [savingInProgress, setSavingInProgress] = useState(false);
	const organisation = authContext.Organization;

	const countrySelectOpts = countries.map(c => ({value: c.Id, label: c.DisplayName}));

	const unitSelectOptions = MapToSelectOpts(OutUnitMap).filter(s => s.value != 4);
	const translate = (key: string): string => t(`settings.organisation.${key}`).toString();
	const smallInputSpan: number = 2;

	useEffect(() => {
		SystemSettingsService.GetCountries()
			.then(countries => setCountries(countries))
			.catch(e => console.error(e));
	}, [undefined]);

	function onFormFinish(organisation: Organization) {
		setSavingInProgress(true);

		UpdateOrganization(organisation)
			.then(() => {
				setEditing(false);
				setValuesHaveChanged(false);
				pubsub.publish(UI_EVENT.ORGANISATION_CHANGED, organisation);
				Success.fire({text: translate('updateSuccess')}).then();
			})
			.catch(e => {
				console.error(e);
				ErrorAlert.fire({text: translate('updateError')}).then();
			});
		setSavingInProgress(false);
	}

	function onCancelClicked() {
		form.setFieldsValue(organisation);
		setValuesHaveChanged(false);
		setEditing(false);
	}

	function getButtons(): React.JSX.Element {
		return (
			<Access access={AccessPermissions.settings.child.organization.child.editOrganization} roles={UserRoles.default}>
				{editing ? (
					<>
						<Col>
							<Button onClick={onCancelClicked} size={'large'}>
								{t('global.cancel').toString()}
							</Button>
						</Col>
					</>
				) : (
					<Col>
						<Button type={'primary'} icon={<EditOutlined />} size={'large'} onClick={() => setEditing(true)}>
							{t('global.edit').toString()}
						</Button>
					</Col>
				)}
			</Access>
		);
	}

	function updateValuesHaveChanged(_changedValues: any, values: Partial<Organization>) {
		const changed = Object.keys(values)
			.map(k => values[k] === organisation[k])
			.some(v => v === false);

		setValuesHaveChanged(changed);
	}

	return (
		<ViewWrapper>
			<ViewHeader heading={t('settings.userManagement.organization')} knowledgeHelpId={'044'}>
				{getButtons()}
			</ViewHeader>
			<ContentWrapper>
				{organisation && (
					<Form
						{...formProps}
						initialValues={organisation}
						disabled={!editing}
						requiredMark={false}
						onFinish={onFormFinish}
						form={form}
						onValuesChange={updateValuesHaveChanged}
					>
						<Form.Item name={'Id'} hidden={true}>
							<InputNumber />
						</Form.Item>

						<Card title={translate('organisationDetails')}>
							<Form.Item<Organization> label={translate('name')} name={'Name'} rules={[{required: true}]}>
								<Input />
							</Form.Item>

							<Form.Item<Organization> label={translate('address')} name={'Address'} rules={[{required: true}]}>
								<TextArea autoSize={true} />
							</Form.Item>

							<Form.Item label={`${translate('zipCode')} / ${translate('city')}`} style={{marginBottom: 0}}>
								<Row gutter={8}>
									<Col flex={1}>
										<Form.Item<Organization> name="ZipCode" rules={[{required: true}]}>
											<Input />
										</Form.Item>
									</Col>
									<Col flex={1}>
										<Form.Item<Organization> name="City" rules={[{required: true}]}>
											<Input />
										</Form.Item>
									</Col>
								</Row>
							</Form.Item>
							<Form.Item<Organization> label={translate('country')} name={'Country'} rules={[{required: true}]}>
								<Select options={countrySelectOpts} />
							</Form.Item>

							<Form.Item<Organization> label={translate('vatNumber')} name={'Vat'} rules={[{required: true}]}>
								<Input />
							</Form.Item>

							<Form.Item label={translate('reseller')}>
								<Row gutter={8}>
									<Col flex={1}>
										<Form.Item<Organization> name={'ResellerName'}>
											<Input disabled={true} />
										</Form.Item>
									</Col>
									<Col flex={1}>
										<Form.Item<Organization> name={'DisplayName'}>
											<Input disabled={true} />
										</Form.Item>
									</Col>
								</Row>
							</Form.Item>

							<Form.Item<Organization> label={translate('supportCode')} name={'SupportCode'}>
								<Input disabled={true} />
							</Form.Item>
						</Card>

						<Card title={translate('organisationWideSettings')}>
							<Form.Item<Organization>
								label={translate('prepaidCreditEnabled')}
								name={'UsePrepaidCredit'}
								valuePropName="checked"
							>
								<Checkbox />
							</Form.Item>

							<Form.Item<Organization>
								label={translate('quickViewEnabled')}
								name={'QuickViewAllowed'}
								valuePropName="checked"
							>
								<Checkbox />
							</Form.Item>

							<Form.Item<Organization> label={translate('2FARequired')} name={'MfaRequired'} valuePropName="checked">
								<Checkbox />
							</Form.Item>

							<Form.Item<Organization>
								rules={[{required: true}]}
								label={translate('sessionTimeout')}
								name={'SessionTimeout'}
								wrapperCol={{span: smallInputSpan}}
							>
								<InputNumber style={{width: '100%'}} min={5} precision={0} />
							</Form.Item>

							<Form.Item<Organization>
								rules={[{required: true}]}
								label={translate('defaultTemperatureUnit')}
								tooltip={translate('defaultTemperatureUnitTooltip')}
								name={'DefaultTemperature'}
								wrapperCol={{span: smallInputSpan}}
							>
								<Select options={unitSelectOptions} />
							</Form.Item>
						</Card>

						<Form.Item wrapperCol={{offset: formProps.labelCol.span}}>
							<Button type={'primary'} htmlType={'submit'} loading={savingInProgress} disabled={!valuesHaveChanged}>
								{t('global.save').toString()}
							</Button>
						</Form.Item>
					</Form>
				)}
			</ContentWrapper>
		</ViewWrapper>
	);
}

export default OrganizationForm;
