import {InternalProductCategories, LineItemGenericTypes} from './LineItemConstants';
import {LineItem} from './LineItem';
import {LicensingExtension} from './LicensingExtension';
import {LicensingService} from '../../../common/services/LicensingService';
import {DeviceType} from 'common/constants';

export type ProfileLineItemProps = {
	serialNumber: string;
	selectedChannelIndex: number;
	selectedValueIndex: number;
	profileIndex: number;
	useAlarms: boolean;
	emailNotification: boolean;
	smsNotification: boolean;
};

export type ProfileLineItemsResult = {
	emailLineItem: LineItem;
	smsLineItem: LineItem;
};

export type LicenseOffering = {
	baseLicenses: LineItem[];
	loggingIntervals: LineItem[];
	limitEmail1: LineItem;
	limitEmail2: LineItem;
	limitSms1: LineItem;
	limitSms2: LineItem;
	issueEmail: LineItem;
	issueSms: LineItem;
	retirement1: LineItem;
	retirement2: LineItem;
};

export class LineItemsExtension {
	public static mapLineItemsToLicenseOffering = (lineItems: LineItem[]): LicenseOffering => {
		if (!lineItems) {
			throw new Error('Please pass lineItems!!!');
		}

		let licenseOffering: LicenseOffering = {
			baseLicenses: [],
			loggingIntervals: [],
			limitEmail1: null,
			limitEmail2: null,
			limitSms1: null,
			limitSms2: null,
			issueEmail: null,
			issueSms: null,
			retirement1: null,
			retirement2: null,
		};

		lineItems.forEach((lineItem: LineItem) => {
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.Base)) {
				licenseOffering.baseLicenses.push(lineItem);
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.LoggingInterval)) {
				licenseOffering.loggingIntervals.push(lineItem);
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.LimitEmail1)) {
				licenseOffering.limitEmail1 = lineItem;
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.LimitSms1)) {
				licenseOffering.limitSms1 = lineItem;
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.LimitEmail2)) {
				licenseOffering.limitEmail2 = lineItem;
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.LimitSms2)) {
				licenseOffering.limitSms2 = lineItem;
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.IssueEmail)) {
				licenseOffering.issueEmail = lineItem;
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.IssueSms)) {
				licenseOffering.issueSms = lineItem;
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.Retirement1)) {
				licenseOffering.retirement1 = lineItem;
			}
			if (lineItem.internalPriceId.includes(LineItemGenericTypes.Retirement2)) {
				licenseOffering.retirement2 = lineItem;
			}
		});

		return licenseOffering;
	};

	public static async getIssueProfileLineItems(
		deviceType: DeviceType,
		selectedChannel: number,
		selectedValueIndex: number,
		profileIndex: number,
		useIssueAlarms: boolean,
		emailNotification: boolean,
		smsNotification: boolean
	): Promise<ProfileLineItemsResult> {
		let lineItemsResult: ProfileLineItemsResult = {
			emailLineItem: null,
			smsLineItem: null,
		};

		if (!useIssueAlarms) {
			return lineItemsResult;
		}

		try {
			if (emailNotification) {
				const lineItems: LineItem[] = LicensingExtension.PrepareLineItemsFromLineItemTypes(
					await LicensingService.GetLineItemTypes([InternalProductCategories.IssueProfileEmailIPC], [deviceType]),
					deviceType,
					selectedChannel,
					selectedValueIndex
				);

				lineItemsResult.emailLineItem = lineItems[profileIndex];
			}

			if (smsNotification) {
				const lineItems: LineItem[] = LicensingExtension.PrepareLineItemsFromLineItemTypes(
					await LicensingService.GetLineItemTypes([InternalProductCategories.IssueProfileSmsIPC], [deviceType]),
					deviceType,
					selectedChannel,
					selectedValueIndex
				);

				lineItemsResult.smsLineItem = lineItems[profileIndex];
			}

			return lineItemsResult;
		} catch (e) {
			console.error('Error while getting line items for issue profile:', e);
		}
	}

	public static async getLimitProfileLineItems(
		deviceType: DeviceType,
		selectedChannel: number,
		selectedValueIndex: number,
		profileIndex: number,
		useLimitAlarms: boolean,
		emailNotification: boolean,
		smsNotification: boolean
	): Promise<ProfileLineItemsResult> {
		let lineItemsResult: ProfileLineItemsResult = {
			emailLineItem: null,
			smsLineItem: null,
		};

		if (!useLimitAlarms) {
			return lineItemsResult;
		}

		try {
			if (emailNotification) {
				const lineItems: LineItem[] = LicensingExtension.PrepareLineItemsFromLineItemTypes(
					await LicensingService.GetLineItemTypes([InternalProductCategories.LimitProfileEmailIPC], [deviceType]),
					deviceType,
					selectedChannel,
					selectedValueIndex
				);

				lineItemsResult.emailLineItem = lineItems[profileIndex];
			}

			if (smsNotification) {
				const lineItems: LineItem[] = LicensingExtension.PrepareLineItemsFromLineItemTypes(
					await LicensingService.GetLineItemTypes([InternalProductCategories.LimitProfileSmsIPC], [deviceType]),
					deviceType,
					selectedChannel,
					selectedValueIndex
				);

				lineItemsResult.smsLineItem = lineItems[profileIndex];
			}

			return lineItemsResult;
		} catch (e) {
			console.error('Error while fetching line items for limit profile:', e);
		}
	}

	public static async getRetirementLineItems(
		deviceType: DeviceType,
		selectedChannel: number,
		selectedValueIndex: number
	): Promise<LineItem[] | null> {
		try {
			return LicensingExtension.PrepareLineItemsFromLineItemTypes(
				await LicensingService.GetLineItemTypes([InternalProductCategories.RetirementIPC], [deviceType]),
				deviceType,
				selectedChannel,
				selectedValueIndex
			);
		} catch (e) {
			console.error('Error while getting retirement line items:', e);
		}
	}
}
