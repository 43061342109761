import React, {useState, useContext, useRef} from 'react';
import {Event} from '../../common/types';
import {Button, CheckboxProps, Input, InputRef, Radio, RadioChangeEvent, Space, Table} from 'antd';
import {useTranslation} from 'react-i18next';
import DateTimeUtils, {TimeRange} from '../Infrastructure/DateTime/DateTimeUtils';
import {accessPermissions, userRoles} from '../Infrastructure/Authorization/Access';
import {Access} from '../Infrastructure/Authorization/Components';
import DeviationsListAcknowledge from './Views/DeviationsListAcknowledge';
import DeviationsAcknowledge from './Views/DeviationsAcknowledge';
import {ColumnsType, ColumnType} from 'antd/es/table';
import {useHistory} from 'react-router-dom';
import {authUtils} from '../Infrastructure/Authorization/Utils';
import {AccessContext} from '../Infrastructure/Authorization/Context/AccessContext';
import styles from './styles.module.css';
import {SENSOR_FILTER_ALARMS} from './DeviationFilterConstants';
import {SearchOutlined} from '@ant-design/icons';
import {FilterConfirmProps, FilterDropdownProps} from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import {WarningIcon} from '../Common/WarningIcon';
import {useSearchParams} from 'react-router-dom-v5-compat';

interface DataType {
	key: React.Key;
	event: Event;
	moduleId: string;
	sensorName: string;
}
type DataIndex = keyof DataType;

const FilterDropDownWrapper = styled.div`
	padding: 8px;
`;

export function EventList(props: {events: Event[]; loading: boolean; onAcknowledge: () => void; timeRange?: TimeRange}): React.JSX.Element {
	const history = useHistory();
	const [t] = useTranslation();
	const accessContext = useContext(AccessContext);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [acknowledgeModalVisible, setAcknowledgeModalVisible] = useState(false);

	let [searchParams] = useSearchParams();
	const defaultFilter = searchParams.getAll('moduleId');

	const hasDeviationDetailViewAccess = authUtils.findAccessElement(
		accessPermissions.deviations.child.deviationsDetail.path,
		accessContext.user.access_settings.access
	);

	const hasAcknowledgeAccess = authUtils.findAccessElement(
		accessPermissions.deviations.child.deviationsDetail.child.deviationAcknowledge.path,
		accessContext.user.access_settings.access
	);

	const datasource: DataType[] =
		props.events
			?.filter(e => DateTimeUtils.isInTimeRange(e.DateOccurred, props.timeRange))
			.map(e => ({
				event: e,
				moduleId: e.GetModuleId(),
				sensorName: e.GetSensorName(),
				key: e.DeviationId, // This is a shortcut for the acknowledgment of the selected items
			})) ?? [];

	const withReasonLocalePrefix = (reason: string) => `deviations.reason.${reason}`;

	function renderIncident(event: Event): React.JSX.Element {
		return (
			event && (
				<div id={event.GetEntryReason()}>
					<h5>{t(withReasonLocalePrefix(event.GetEntryReason())).toString()}</h5>
					<p>
						{event.IncidentId}, {DateTimeUtils.toUserString(event.DateOccurred)}
					</p>
				</div>
			)
		);
	}

	function renderAcknowledgedCell(event: Event): React.JSX.Element {
		return (
			event && <>{event.IsAcknowledged() ? renderAcknowledgedCellAcknowledged(event) : renderAcknowledgedCellUnacknowledged(event)}</>
		);
	}
	function renderAcknowledgedCellAcknowledged(event: Event) {
		return (
			<>
				<h5>Acknowledged</h5>
				<p>
					{event.GetEventAcknowledgedBy()}, {DateTimeUtils.toUserString(event.GetEventAcknowledgedTimestamp())}
				</p>
			</>
		);
	}
	function renderAcknowledgedCellUnacknowledged(event: Event) {
		return (
			<>
				{hasDeviationDetailViewAccess ? (
					<Button type={'primary'} onClick={() => navigateToEventDetail(event)}>
						<em className="fsInButton elpro-Acknowledge" /> {hasAcknowledgeAccess ? 'ACKNOWLEDGE' : 'VIEW DETAIL'}
					</Button>
				) : (
					<h5>Deviation not acknowledged</h5>
				)}
			</>
		);
	}

	const getAcknowledgeFilterProps = (): ColumnType<DataType> => ({
		filterDropdown: (props: FilterDropdownProps) => (
			<FilterDropDownWrapper>
				<Radio.Group
					defaultValue={undefined}
					buttonStyle="solid"
					onChange={(e: RadioChangeEvent) => {
						props.setSelectedKeys([e.target.value]);
						props.confirm({closeDropdown: false});
					}}
				>
					<Radio.Button value={true}>Acknowledged</Radio.Button>
					<Radio.Button value={undefined}>Both</Radio.Button>
					<Radio.Button value={false}>Not Acknowledged</Radio.Button>
				</Radio.Group>
			</FilterDropDownWrapper>
		),
		onFilter: (value, record) => {
			// Do not filter, if value is undefined, else filter against IsAcknowledged
			return !value || value === record.event.IsAcknowledged();
		},
	});

	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef<InputRef>(null);

	const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
		filterDropdown: (props: FilterDropdownProps) => (
			<FilterDropDownWrapper onKeyDown={e => e.key !== 'Escape' && e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={props.selectedKeys[0]}
					onChange={e => {
						const _selectedKeys = e.target.value ? [e.target.value] : [];
						props.setSelectedKeys(_selectedKeys);
						handleSearch(_selectedKeys, () => props.confirm({closeDropdown: false}), dataIndex);
					}}
					onPressEnter={() => handleSearch(props.selectedKeys as string[], props.confirm, dataIndex)}
					onKeyDown={e => e.key === 'Escape' && props.clearFilters && handleReset(props.clearFilters)}
					style={{marginBottom: 8, display: 'block'}}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(props.selectedKeys as string[], props.confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{width: 90}}
					>
						Search
					</Button>
					<Button onClick={() => props.clearFilters && handleReset(props.clearFilters)} size="small" style={{width: 90}}>
						Reset
					</Button>
				</Space>
			</FilterDropDownWrapper>
		),
		filterIcon: (filtered: boolean) => <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}} />,
		onFilter: (value, record) =>
			record[dataIndex] &&
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase()),
		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	const columns: ColumnsType<DataType> = [
		{
			title: '',
			render: row => <WarningIcon size={2} event={row.event} />,
			width: '1%', // shrink tos content
		},
		{
			title: 'Incident',
			filters: Object.entries(SENSOR_FILTER_ALARMS).map(([_1, [_2, incidentTransKey]]) => ({
				text: t(withReasonLocalePrefix(incidentTransKey)).toString(),
				value: incidentTransKey,
			})),
			onFilter: (value: string, record: DataType) => record.event.GetEntryReason() === value,
			render: record => renderIncident(record.event),
		},
		{
			title: 'Sensor Name',
			dataIndex: 'sensorName',
			...getColumnSearchProps('sensorName'),
		},
		{
			title: 'Module Id',
			dataIndex: 'moduleId',
			...getColumnSearchProps('moduleId'),
			defaultFilteredValue: defaultFilter,
		},
		{
			title: 'Acknowledged',
			render: record => renderAcknowledgedCell(record.event),
			...getAcknowledgeFilterProps(),
		},
	];

	function onAcknowledgeList() {
		setAcknowledgeModalVisible(false);
		setSelectedRowKeys([]);
		props.onAcknowledge();
	}

	function navigateToEventDetail(event: Event) {
		history.push(`/deviationsDetail/${event.Id}/${event.GetSensorId()}`);
	}

	return (
		<>
			<DeviationsAcknowledge
				title={`Acknowledge ${selectedRowKeys.length} deviations`}
				isVisible={acknowledgeModalVisible}
				isLoading={false}
				onChangeModalVisibility={setAcknowledgeModalVisible}
				deviations={selectedRowKeys}
				acknowledgeSuccess={onAcknowledgeList}
			/>

			<Access access={accessPermissions.deviations.child.multiAcknowledge} roles={userRoles.default}>
				<DeviationsListAcknowledge
					id="acknowledgeActiveDeviations"
					onClick={() => setAcknowledgeModalVisible(true)}
					count={selectedRowKeys.length}
					identifier="active"
				/>
			</Access>
			<Access access={accessPermissions.deviations.child.viewActiveDeviations} roles={userRoles.default}>
				<Table
					className={styles.EventTable}
					loading={props.loading}
					dataSource={datasource}
					columns={columns}
					onRow={(record: DataType) => ({
						onClick: (_: React.MouseEvent) => hasDeviationDetailViewAccess && navigateToEventDetail(record.event),
					})}
					rowSelection={{
						getCheckboxProps: (record: DataType): CheckboxProps => ({disabled: record.event.IsAcknowledged()}),
						type: 'checkbox',
						onChange: setSelectedRowKeys,
						selections: [Table.SELECTION_ALL],
						selectedRowKeys: selectedRowKeys,
					}}
				></Table>
			</Access>
		</>
	);
}
