import React, {Component} from 'react';
import {Select} from 'antd';
import {Label, Row, Col, FormGroup} from 'reactstrap';
const {Option} = Select;

type CommunicationIntervalSelectProps = {
	value: string | undefined;
	onChange: any;
};

class CommunicationIntervalSelectState {
	onChange: any;
}

export class CommunicationIntervalSelectComponent extends Component<CommunicationIntervalSelectProps, CommunicationIntervalSelectState> {
	constructor(props: CommunicationIntervalSelectProps | Readonly<CommunicationIntervalSelectProps>) {
		super(props);
		this.state = {
			onChange: props.onChange,
		};
	}

	render() {
		return (
			<Row>
				<Col xl={3}>
					<FormGroup>
						<Label>Communication interval</Label>
						<div>
							<Select
								style={{width: '100%'}}
								defaultValue={this.props.value}
								id="selectCommunicationInterval"
								value={this.props.value}
								onChange={this.state.onChange}
							>
								<Option id={'option0'} key={0} value={120}>
									2 hours
								</Option>
								<Option id={'option1'} key={1} value={240}>
									4 hours
								</Option>
								<Option id={'option2'} key={2} value={360}>
									6 hours
								</Option>
							</Select>
						</div>
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
