import {copyLimitProfile} from '../LimitProfile/Commands/LimitProfileCommands';

export const executeLimitProfileCopyAction = (parent, limitProfile) => {
	if (parent == undefined || parent == null || limitProfile == undefined || limitProfile == null) {
		throw new TypeError('Please pass required objects!!! (parent, limitProfile)');
	}
	const preparedLimitProfile = prepareLimitProfileForCopyAction(limitProfile);
	copyLimitProfile(preparedLimitProfile, parent);
};

const prepareLimitProfileForCopyAction = limitProfile => {
	if (!limitProfile) {
		throw new TypeError('Please pass limitProfile object!!!');
	}
	var limitProfileObj = {...limitProfile};

	limitProfileObj.id = 0;
	limitProfileObj.org_id = limitProfile.org_id;
	limitProfileObj.elpro_cloud_default = limitProfile.elpro_cloud_default;
	limitProfileObj.name = limitProfile.name + '_copy';
	limitProfileObj.locked = false;
	limitProfileObj.sensors = null;

	return limitProfileObj;
};

if (process.env['BABEL_ENV'] === 'test' || process.env['NODE_ENV'] === 'test') {
	//Functions are only visible for testing purpose.
	module.exports.prepareLimitProfileForCopyAction = prepareLimitProfileForCopyAction;
}
