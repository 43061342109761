import {CalibrationMeasurement} from './CalibrationMeasurement';
import {CalibrationSensor} from './CalibrationSensor';

export class Calibration {
	public CalibrationID: number;
	public Start: Date;
	public Stop: Date;
	public Description: string;
	public State: string;
	public Sensor: CalibrationSensor;
	public LastMeasurement: CalibrationMeasurement;
	public Measurements: CalibrationMeasurement[] = [];

	private calibrationStates: string[] = ['Created', 'StartSent', 'Calibrating', 'StopSent', 'Stopped'];

	constructor(calibrationId: number, startDate: Date, stopDate: Date, description: string, state: number) {
		this.CalibrationID = calibrationId;
		this.Start = startDate;
		this.Stop = stopDate;
		this.Description = description;
		this.State = this.calibrationStates[state];
	}
}
