import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Message from '../../../../Shared/Components/Message';
import IssueProfileExtension from '../../../../Services/Extensions/IssueProfiles';
import {RequestLogger} from '../../../../Infrastructure/Requests/Logger';
import pubsub from 'pubsub-js';
import {getIssueProfiles} from '../../Queries/IssueProfilesQueries';
import Shared from '../../../../Shared/Shared';

export const executeIssueProfileDeleteAction = (parent, issueProfile) => {
    const MySwal = withReactContent(Swal);

    const message = issueProfile.sensors
		? '<br />' +
		  '<br />' +
		  '<div class="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon">' +
		  'A change affects ' +
		  issueProfile.sensors.length +
		  ' sensor(s). ' +
		  Shared.handleAffectedSensors(issueProfile.sensors) +
		  ' </div>'
		: '';
    MySwal.fire({
        title: 'Are you sure?',
        html: "You won't be able to revert this!" + message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
    }).then(result => {
        if (result.value) {
            IssueProfileExtension.DeleteProfile(
                issueProfile.id,
                RequestLogger.createLogData('issue-profiles', 'delete-issue-profile', 'onClick')
            )
                .then(result => {
                    if (result.status === 200) {
                        parent.setState({issueProfiles: []}, () => {
                            getIssueProfiles(parent);
                            Message.success('Issue profile deleted', 'Issue profile successfully deleted');
                        });
                    }
                })
                .catch(error => pubsub.publish('applicationError', error.data.message));
        }
    });
};
