import {AuthUtils} from '../util';

export class ElproCloudToken {
	constructor(token: string) {
		const parsedToken = AuthUtils.DecodeTokenToObject(token);

		this.userId = parsedToken['id'];
		this.userEmail = parsedToken['email'];
		this.orgId = parsedToken['orgid'] ?? parsedToken['guest_orgid'];
		this.orgName = parsedToken['org'];
		this.sessionId = parsedToken['sessionid'];
		this.userFirstName = parsedToken['first_name'];
		this.userLastName = parsedToken['last_name'];
	}

	public get UserId() {
		return this.userId;
	}

	public get UserEmail() {
		return this.userEmail;
	}

	public get OrgId() {
		return this.orgId;
	}

	public get OrgName() {
		return this.orgName;
	}

	public get SessionId() {
		return this.sessionId;
	}

	public get UserFirstName() {
		return this.userFirstName;
	}

	public get UserLastName() {
		return this.userLastName;
	}

	private userId: number;
	private userEmail: string;
	private orgId: number;
	private orgName: string;
	private sessionId: string;
	private userFirstName: string;
	private userLastName: string;
}
