import request from '../../Infrastructure/Requests/Request';

function reseller() {
	return request({
		url: '/resellers',
		method: 'GET',
	});
}

const OrganizationsService = {
	reseller,
};

export default OrganizationsService;
