import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import Shared from '../../Shared/Shared';
import React from 'react';
import {Trans, Translation} from 'react-i18next';

interface LimitStatisticProps {
	outUnitsId: number;
	zone: {limit: number; delay: number; name: string};
	zoneStatistic: {
		numberOfExcursions: number;
		durationTotal: string;
	};
}

export function LimitStatisticTableFragment(props: LimitStatisticProps): React.JSX.Element {
	if (props.zone.limit == null) {
		return;
	}

	return (
		<>
			<tr>
				<td width="15%">
					<Trans i18nKey={`sensor.statistics.${props.zone.name}_Limit`} />
				</td>
				<td width="35%">
					<Translation>
						{t => (
							<>
								{props.zone.limit} {Shared.getSensorUnit(props.outUnitsId)}
							</>
						)}
					</Translation>
				</td>
				<td width="15%">
					<Trans i18nKey={`sensor.statistics.${props.zone.name}_Delay`} />
				</td>
				<td width="35%">
					<Translation>
						{t => (
							<>
								{props.zone.delay} {t('sensorWizard.summary.logging_interval_s')}
							</>
						)}
					</Translation>
				</td>
			</tr>
			<tr>
				<td width="15%">
					<Trans i18nKey={`sensor.statistics.${props.zone.name}_Excursions`} />
				</td>
				<td width="35%">{props.zoneStatistic.numberOfExcursions}</td>
				<td width="15%">
					<Trans i18nKey={`sensor.statistics.${props.zone.name}_Duration`} />
				</td>
				<td width="35%">{DateTimeUtils.formatDurationTime(props.zoneStatistic.durationTotal)}</td>
			</tr>
		</>
	);
}
