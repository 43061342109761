import React from 'react';
import {Button} from 'reactstrap';
import axios from "axios";
import Shared from '../../Shared/Shared'
import fileSaver from "file-saver";
import Authentication from '../../Infrastructure/Authentication/Authentication';

const btnStyle = {padding: '0 10px'};

const onClick = (data) => {
    axios({
        method: 'post',
        url: data.url,
        data: data.payload,
        headers: {
            'Authorization': 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    }).then(response => {
        const file = Shared.base64toFile(response.data, "Report.pdf", "application/pdf");
        fileSaver.saveAs(file, 'report.pdf');
    }).catch(error => console.error(error));
};

const DebugReport = (props) => {
    return (
        <span style={btnStyle}>
            <Button id="btnReport" className="btn btn-primary" color="danger" onClick={() => onClick(props)}>
                <span><em className="fsInButton elpro-Report fa-fw"/>Report</span>
            </Button>
        </span>
    );
};

export default DebugReport;