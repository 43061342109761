import React from 'react';
import Alert from '../Shared/Components/Alert';
import {AlertProps, Button, Space} from 'antd';

export interface StatusAlertProps extends AlertProps {
	onErrorRedirect: () => void;
	show: boolean;
}

const StatusAlert = ({onErrorRedirect, ...props}: StatusAlertProps) => {
	if (!props.show) return null;

	const action =
		props.type === 'error' ? (
			<>
				<Space direction="vertical">
					<Button danger onClick={onErrorRedirect}>
						Go to the Login
					</Button>
				</Space>
			</>
		) : null;

	return <Alert {...props} action={action} />;
};

export default StatusAlert;
