import React from 'react';
import {Spin} from 'antd';

const LoadingTie = () => {
    const wrapper = {
        paddingTop: '5%',
    };

    return (
        <div style={wrapper}>
            <Spin />
        </div>
    );
};

export default LoadingTie;
