import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type IssueAlarmsRecipientsProps = {
	sensorIssuesForm: any;
	show: boolean;
};

export default function IssueAlarmsRecipientsComponent(props: IssueAlarmsRecipientsProps) {
	const issueAlarmRecipientMail = props.sensorIssuesForm.email_notification;
	const issueAlarmRecipientSMS = props.sensorIssuesForm.sms_notification;
	const issueAlarmAnd = issueAlarmRecipientMail && issueAlarmRecipientSMS;

	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.recipientsForIssueAlarms'} />
				</td>
				<td>
					{issueAlarmRecipientMail ? <Trans i18nKey={'sensorWizard.summary.email'} /> : null}
					{issueAlarmAnd ? (
						<span>
							{' '}
							<Trans i18nKey={'sensorWizard.summary.and'} />{' '}
						</span>
					) : null}
					{issueAlarmRecipientSMS ? <Trans i18nKey={'sensorWizard.summary.sms'} /> : null}
					{!issueAlarmRecipientMail && !issueAlarmRecipientSMS ? CONSTANTS.NOT_AVAILABLE : null}
				</td>
			</tr>
		)
	);
}
