import axios from 'axios';
import LimitProfilesService from '../LimitProfiles/LimitProfilesService';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
const isDebugMode = false;

const localHostSaveProfile = (payload, eventData) => {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: payload,
        SensorIssueAlarm: null,
        PaymentInformation: null,
    };
    wizdata.SensorLimitAlarm.limit_alarm_name = wizdata.SensorLimitAlarm.name;
    wizdata.SensorLimitAlarm.use_limit_alarms = true;
    wizdata.SensorLimitAlarm.limit_alarm_creation_type = 0;

    return axios({
        url: 'http://localhost:44353/registry/save',
        method: 'POST',
        data: wizdata,
        eventData,
        headers: {
            Authorization: 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
    });
};

const localHostUpdateProfile = (payload, eventData) => {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: payload,
        SensorIssueAlarm: null,
        PaymentInformation: null,
    };
    wizdata.SensorLimitAlarm.limit_alarm_name = wizdata.SensorLimitAlarm.name;
    wizdata.SensorLimitAlarm.use_limit_alarms = true; //update
    wizdata.SensorLimitAlarm.limit_alarm_creation_type = payload.limit_alarm_creation_type;
    wizdata.SensorLimitAlarm.editable = true;

    return axios({
        url: 'http://localhost:44353/registry/update',
        method: 'PUT',
        data: wizdata,
        eventData,
        headers: {
            Authorization: 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
    });
};

const localHostDeleteProfile = (limitProfileId, eventData) => {
    return axios({
        url: 'http://localhost:44353/registry/sensorlimitprofile?id=' + limitProfileId,
        method: 'DELETE',
        eventData,
        headers: {
            Authorization: 'Bearer ' + Authentication.getToken(),
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
    });
};


const saveProfile = (payload, eventData) => {
    if (isDebugMode) {
        return localHostSaveProfile(payload, eventData);
    } else {
        return LimitProfilesService.saveProfile(payload, eventData);
    }
};

const updateProfile = (payload, eventData) => {
    if (isDebugMode) {
        return localHostUpdateProfile(payload, eventData);
    } else {
        return LimitProfilesService.updateProfile(payload, eventData);
    }
};

const deleteProfile = (limitProfileId, eventData) => {
    if (isDebugMode) {
        return localHostDeleteProfile(limitProfileId, eventData);
    } else {
        return LimitProfilesService.deleteProfile(limitProfileId, eventData);
    }
};

const LimitProfiles = {
    saveProfile,
    updateProfile,
    deleteProfile
};

export default LimitProfiles;
