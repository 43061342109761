import {ColProps, FormProps} from 'antd';

const labelSpan: number = 8;

export const colProps: ColProps = {
	lg: 16,
	xl: 12,
	xxl: 8,
};
export const formProps: FormProps = {
	initialValues: {remember: true},
	labelWrap: true,
	labelCol: {span: labelSpan},
	layout: 'horizontal',
	wrapperCol: colProps,
};
