import {COLUMNS} from '../Constants/Columns';
import {CONSTANTS} from '../../../Shared/Constants/Constants';

export const prepareSingleRowData = (callbackMethodsForHamburgerMenu, limitProfile, parent, i) => {
    if (!callbackMethodsForHamburgerMenu || !limitProfile || !parent || !i) {
        throw new TypeError('Please pass required parameters!!!');
    }

    const menu = {callbackMethodsForHamburgerMenu: callbackMethodsForHamburgerMenu, limitProfile: limitProfile, parent};
    const id = limitProfile.id;
    const limitProfileName = prepareLimitProfileName(limitProfile);
    const inUsedBy = prepareInUsedBy(limitProfile);
    const locked = prepareLocked(limitProfile);

    var obj = {};
    Object.defineProperty(obj, COLUMNS.MENU, {
        value: menu,
        writable: true,
    });
    Object.defineProperty(obj, 'id', {
        value: id,
        writable: true,
    });
    Object.defineProperty(obj, COLUMNS.LIMIT_PROFILE_NAME, {
        value: limitProfileName,
        writable: true,
    });    
    Object.defineProperty(obj, COLUMNS.IN_USE_BY, {
        value: inUsedBy,
        writable: true,
    });
    Object.defineProperty(obj, COLUMNS.LOCKED, {
        value: locked,
        writable: true,
    });
    return obj;
};

function prepareLimitProfileName(limitProfile) {
    if (!limitProfile) {
        throw new TypeError('Please pass limitProfile object!!!');
    }
    return limitProfile.name ? limitProfile.name : CONSTANTS.DEFAULT;
}

function prepareInUsedBy(limitProfile) {
    if (!limitProfile) {
        throw new TypeError('Please pass limitProfile object!!!');
    }
    return limitProfile.sensors && limitProfile.sensors.length > 0 ? limitProfile.sensors.length : CONSTANTS.DEFAULT;
}

function prepareLocked(limitProfile) {
    if (!limitProfile) {
        throw new TypeError('Please pass limitProfile object!!!');
    }
    return limitProfile.locked !== undefined && limitProfile.locked !== null ? limitProfile.locked : CONSTANTS.DEFAULT;
}

if (process.env['BABEL_ENV'] === 'test' || process.env['NODE_ENV'] === 'test') {
    //Functions are only visible for testing purpose.
    module.exports.prepareLimitProfileName = prepareLimitProfileName;    
    module.exports.prepareInUsedBy = prepareInUsedBy;
    module.exports.prepareLocked = prepareLocked;
}
