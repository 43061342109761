import request from '../../Infrastructure/Requests/Request';
import Authentication from '../../Infrastructure/Authentication/Authentication';

function sensorGroups(eventData) {
    return request({
        url: '/sensors_groups',
        method: 'GET',
        token: Authentication.getToken(),
        eventData,
    });
}

function upsertSensorGroup(payload) {
    return request({
        url: '/rpc/upsert_sensor_group',
        method: 'POST',
        token: Authentication.getToken(),
        data: {groupinfo: payload},
    });
}

function deleteSensorGroup(payload, eventData) {
    return request({
        url: '/rpc/delete_sensor_group',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload,
        eventData,
    });
}

function orderSensorGroups(payload, eventData) {
    return request({
        url: '/rpc/order_sensor_groups',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload,
        eventData,
    });
}

function addSensorsToGroups(payload) {
    return request({
        url: '/rpc/add_sensors_to_groups',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload,
    });
}

function removeSensorsFromGroups(payload, eventData) {
    return request({
        url: '/rpc/remove_sensors_from_groups',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload,
        eventData,
    });
}

const SensorGroupsService = {
    sensorGroups,
    upsertSensorGroup,
    deleteSensorGroup,
    orderSensorGroups,
    addSensorsToGroups,
    removeSensorsFromGroups,
};

export default SensorGroupsService;
