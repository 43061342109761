const parent: string = 'predictive';

export const PredictiveTrans = {
	alarmLimit: parent + '.alarmLimit',
	container: parent + '.container',
	lane: parent + '.lane',
	limitAlarmTooltip: parent + '.limitAlarmTooltip',
	lowerLimit: parent + '.lowerLimit',
	title: 'sensorWizard.steps.predictive',
	upperLimit: parent + '.upperLimit',
};
