import React from 'react';
import SensorHeader from './SensorHeader/SensorHeader';
import SensorBodyComponent from './SensorBodyComponent';
import SensorFooter from './SensorFooter';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import ReactTooltip from 'react-tooltip';
import {SensorComponentProps} from './SensorComponentProps';

const SensorComponent = (props: SensorComponentProps) => {
	const sensor = props.sensor;

	const getCardId = (): string => {
		let defaultCardId = 'noIncident';
		let alarmCardId = 'alarmIncident';
		let warningCardId = 'warningIncident';
		let latchAlarmCardId = 'latchAlarm';

		const activeDeviations = sensor.Deviations.filter(d => !d.Done);

		const activeWarnings = activeDeviations.filter(d => d.Warning);
		const activeAlarms = activeDeviations.filter(a => !a.Warning);

		if (activeAlarms.length) {
			return alarmCardId;
		}

		if (sensor.HasLatchAlarm()) {
			return latchAlarmCardId;
		}

		if (activeWarnings.length) {
			return warningCardId;
		}

		return defaultCardId;
	};

	const getBackgroundColor = (): string => {
		let defaultColor = '#FFFFFF';
		let alarmColor = '#f8baba';
		let warnColor = '#ffea8c';

		if (sensor.IsRetired) {
			return defaultColor;
		}

		if (sensor.HasAlarm()) {
			return alarmColor;
		}

		const activeWarnings = sensor.Deviations.filter(d => !d.Done && d.Warning);
		if (activeWarnings.length) {
			return warnColor;
		}

		return defaultColor;
	};

	const userOffset = DateTimeUtils.getCurrentUserTZOffset();
	const backgroundColor = getBackgroundColor();

	return (
		<>
			{
				<div
					id={'sensorCard' + sensor.Name}
					className={'card card-default dashboard-sensor-card dashboard-sensor-card-' + getCardId()}
					style={{backgroundColor: backgroundColor, margin: '0'}}
				>
					<SensorHeader
						onSensorDeleted={props.onSensorDeleted}
						onCalibrationStopped={props.onCalibrationStopped}
						onCalibrationStarted={props.onCalibrationStarted}
						fillingColor={backgroundColor}
						sensor={sensor}
						sensorConfigurationLastStep={props.sensorConfigurationLastStep}
					/>
					<span data-tip="true" data-for={'sensor_' + sensor.Id}>
						<SensorBodyComponent
							optimizeFor={props.optimizeFor}
							isRetired={sensor.IsRetired}
							fillingColor={backgroundColor}
							analyseSensor={props.analyseSensor}
							sensor={sensor}
						/>
						{sensor.Status !== 0 && (
							<ReactTooltip id={'sensor_' + sensor.Id} className="dashboardSensorErrorCodeToolTip">
								<span>SENSOR ERROR: {sensor.Error}</span>
							</ReactTooltip>
						)}
					</span>
					<SensorFooter
						fillingColor={backgroundColor}
						sensor={sensor}
						moduleShortNameBackgroundColor={sensor.BackgroundColor}
						userOffset={userOffset}
					/>
				</div>
			}
		</>
	);
};

export default SensorComponent;
