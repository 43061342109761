export const LayoutPermissions = {
	header: {
		create_support_ticket: {
			key: 'create_support_ticket',
			path: [],
		},
		deviation_alarm: {
			key: 'deviation_alarm',
			path: [],
		},
		issue_alarm: {
			key: 'issue_alarm',
			path: [],
		},
		collapse_navigation: {
			key: 'collapse_navigation',
			path: [],
		},
		logout: {
			key: 'logout',
			path: [],
		},
	},
	navigation: {
		navigation: {key: 'navigation', path: []},
		user_info: {
			key: 'user_info',
			path: [],
		},
		links: {
			key: 'links',
			path: [],
		},
	},
	analysis: {
		close_btn: {
			key: 'close_btn',
			path: [],
		},
	},
};

export default {
	header: LayoutPermissions.header,
	navigation: LayoutPermissions.navigation,
	analysis: LayoutPermissions.analysis,
};
