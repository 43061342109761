import React from "react";
import {Popover} from 'antd';

const AntPopover = ({placement, title, content, trigger = 'hover', ...props}) => {
    return (
        <Popover placement={placement}
                 title={title}
                 content={content}
                 trigger={trigger}
        > {props.children}
        </Popover>
    );
}

export default AntPopover;