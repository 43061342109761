import DateTimeUtils from '../../../../../../Infrastructure/DateTime/DateTimeUtils';
import {LiberoGxMapperService} from '../../../../../../Forms/Mappers/LiberoGMapper/LiberoGxMapperService';
import {EcologProGxMapperService} from '../../../../../../Forms/Mappers/EcologProGMapper/EcologProGxMapperService';

export const getLiberoGxAdditionalConfigurationSummary = (additionalConfiguration, configurationTemplate) => {
	if (!additionalConfiguration) {
		return null;
	}

	return Object.keys(configurationTemplate).reduce((valueAcc, sectionKey) => {
		if (configurationTemplate[sectionKey]['mapped'] === false || configurationTemplate[sectionKey]['index'] <= 0) return valueAcc;

		const sectionFields = configurationTemplate[sectionKey]['fields'];
		if (typeof sectionFields !== 'object') return valueAcc;

		const wizardValue = LiberoGxMapperService.mapToTemplateResult(sectionKey, additionalConfiguration, sectionFields);

		const values = Object.keys(sectionFields).map(key =>
			sectionFields[key].id === undefined || sectionFields[key].id === null
				? Object.keys(sectionFields[key])
						.map(subKey =>
							getAdditionalConfigurationFieldSummary(
								sectionFields,
								wizardValue,
								sectionFields[key][subKey],
								wizardValue[key][subKey],
								sectionFields[key].alias
							)
						)
						.filter(v => v !== null)
				: getAdditionalConfigurationFieldSummary(sectionFields, wizardValue, sectionFields[key], wizardValue[sectionFields[key].id])
		);

		const sectionStep = configurationTemplate[sectionKey]['title'];
		return !!sectionStep
			? {
					...valueAcc,
					[sectionKey]: {
						text: sectionStep,
						values,
					},
			  }
			: {
					...valueAcc,
			  };
	}, {});
};

export const getEcologProGxAdditionalConfigurationSummary = (additionalConfiguration, configurationTemplate) => {
	if (!additionalConfiguration) {
		return null;
	}

	return Object.keys(configurationTemplate).reduce((valueAcc, sectionKey) => {
		if (
			configurationTemplate[sectionKey]['mapped'] === false ||
			configurationTemplate[sectionKey]['index'] <= 0
		)
			return valueAcc;

		const sectionFields = configurationTemplate[sectionKey]['fields'];
		if (typeof sectionFields !== 'object') return valueAcc;

		const wizardValue = EcologProGxMapperService.mapToTemplateResult(
			sectionKey,
			additionalConfiguration,
			sectionFields
		);

		const values = Object.keys(sectionFields).map(key =>
			sectionFields[key].id === undefined || sectionFields[key].id === null
				? Object.keys(sectionFields[key])
						.map(subKey =>
							getAdditionalConfigurationFieldSummary(
								sectionFields,
								wizardValue,
								sectionFields[key][subKey],
								wizardValue[key][subKey],
								sectionFields[key].alias
							)
						)
						.filter(v => v !== null)
				: getAdditionalConfigurationFieldSummary(
						sectionFields,
						wizardValue,
						sectionFields[key],
						wizardValue[sectionFields[key].id]
				  )
		);

		const sectionStep = configurationTemplate[sectionKey]['title'];
		return !!sectionStep
			? {
					...valueAcc,
					[sectionKey]: {
						text: sectionStep,
						values,
					},
			  }
			: {
					...valueAcc,
			  };
	}, {});
};

const getAdditionalConfigurationFieldSummary = (schema, wizardValue, field, value, alias = null) => {
	if (field === null || field === undefined) return null;

	if (field.id === null || field.id === undefined) return null;

	let visible = field.is_visible !== false;
	if (!!field.visible_only) {
		let [fieldKey, comparedFieldValue, schemaKey] = JSON.parse(field.visible_only);
		let fieldFormValue = wizardValue[fieldKey];

		if (schemaKey !== undefined && schemaKey !== null) {
			if (!!fieldFormValue && (schema[schemaKey].type === 'list' || schema[schemaKey].type === 'multiselect')) {
				visible = visible && fieldFormValue.indexOf(comparedFieldValue) >= 0;
			} else {
				// eslint-disable-next-line
				visible = visible && fieldFormValue == comparedFieldValue;
			}
		} else {
			// eslint-disable-next-line
			visible = visible && fieldFormValue == comparedFieldValue;
		}
	}

	if (!field.alias || !visible) return null;

	let fieldValue = value;
	switch (field.type) {
		case 'text':
			fieldValue = !!fieldValue ? fieldValue : 'n.a.';
			break;
		case 'dropdown':
			const fieldSelectedOption = field.defaults.filter(option => option.value === fieldValue)[0];
			fieldValue = !!fieldValue ? (!!fieldSelectedOption ? fieldSelectedOption.alias : fieldValue) : 'n.a.';
			break;
		case 'number':
			fieldValue = !!fieldValue ? (!!field.range && !!field.range.unit ? `${fieldValue} ${field.range.unit}` : fieldValue) : 'n.a.';
			break;
		case 'list':
			fieldValue = !!fieldValue ? JSON.parse(fieldValue).join(', ') : 'n.a.';
			break;
		case 'checkbox':
			fieldValue = !!fieldValue ? 'Yes' : 'No';
			break;
		case 'datetime':
			fieldValue = !!fieldValue ? DateTimeUtils.getDateTimeInUserTZ(fieldValue * 1000) : 'n.a.';
			break;
		case 'editableDropdown':
			fieldValue = !!fieldValue ? fieldValue : 'n.a.';
			break;
		case 'multiselect':
			const fieldSelectedOptions = field.defaults.filter(option => fieldValue.includes(option.value)).map(option => option.alias);
			fieldValue = !!fieldValue ? (!!fieldSelectedOptions ? fieldSelectedOptions.join(', ') : fieldValue) : 'n.a.';
			break;
		default:
			fieldValue = value;
			break;
	}

	if (!!field.active_only) {
		let [fieldKey, comparedFieldValue] = JSON.parse(field.active_only);
		// eslint-disable-next-line
		if (wizardValue[fieldKey] != comparedFieldValue) {
			fieldValue = 'n.a.';
		}
	}

	return {
		text: alias !== null ? alias : field.alias,
		value: fieldValue,
	};
};
