import {asyncServerRequest} from '../../../components/Infrastructure/Requests/ServerRequest';
import {OrganisationSettingGlobal, OrganisationSettingSensorLimit, OrganisationSettingUserManagement} from '../../types';
import {OrganizationsConfig} from '../../types/DbModel/OrganizationsConfig';
export class OrganisationSettingService {
	public static async GetOrganizationSetting(
		setting: keyof OrganizationsConfig
	): Promise<OrganisationSettingGlobal | OrganisationSettingUserManagement | OrganisationSettingSensorLimit> {
		const request = {
			url: `settings/organization-configuration/setting/${setting}`,
			method: 'GET',
		};
		try {
			const response = (await asyncServerRequest(request)).data;

			switch (setting) {
				case 'sensor-limit':
					return new OrganisationSettingSensorLimit(response.setting);
				case 'user-management':
					return new OrganisationSettingUserManagement(response.setting);
				case 'global':
					return new OrganisationSettingGlobal(response.setting);
			}
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}

	public static async GetOrganisationSettingGlobal(): Promise<OrganisationSettingGlobal> {
		return (await OrganisationSettingService.GetOrganizationSetting('global')) as unknown as OrganisationSettingGlobal;
	}
}
