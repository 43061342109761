import React, {Component} from 'react';
import {MODULE_TYPE_IMG, MODULE_TYPE} from '../../../Shared/Constants/Module';

class ModuleCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: props.type,
			imgId: MODULE_TYPE[props.type],
			imgSrc: MODULE_TYPE_IMG[props.type],
			selected: false,
			initial: true,
			title: null,
			currentInstance: this,
		};
	}

	static getDerivedStateFromProps(props, current_state) {
		let selected = false;
		let initial = true;
		let title = null;

		if (props.serial) {
			selected = current_state.currentInstance.getSelectedModule(props.serial);
			title = current_state.currentInstance.getSelectedModuleTitle(props.serial);
			initial = false;
		}

		return {selected, initial, title};
	}

	getSelectedModule(serial) {
		let selected = false;
		switch (this.state.type) {
			case MODULE_TYPE.NTR:
				if (serial[0].toUpperCase() === 'S') selected = true;
				break;
			case MODULE_TYPE.PTR:
				if (serial[0].toUpperCase() === 'Q') selected = true;
				break;
			case MODULE_TYPE.THR:
				if (serial[0].toUpperCase() === 'R') selected = true;
				break;
			case MODULE_TYPE.ECOLOG_PRO_G:
				if (serial[0].toUpperCase() === 'W' || serial[0].toUpperCase() === 'X' || serial[0].toUpperCase() === 'Y') selected = true;
				break;
			case MODULE_TYPE.LIBEROG:
				if (serial[0] === '9' || serial[0] === '3') selected = true;
				break;
			default:
				selected = false;
		}

		return selected;
	}

	getSelectedModuleTitle(serial) {
		switch (this.state.type) {
			case MODULE_TYPE.LIBEROG:
				switch (serial[1]) {
					case '1':
						return 'LIBERO GS';
					case '2':
						return 'LIBERO GF';
					case '3':
						return 'LIBERO GL';
					case '4':
						return 'LIBERO GE';
					case '5':
						return 'LIBERO GH';
					case '6':
						return 'LIBERO GF ext';
					default:
						return null;
				}
			case MODULE_TYPE.ECOLOG_PRO_G:
				switch (serial[0]) {
					case 'W':
						return 'ECOLOG-PRO 1TGi';
					case 'X':
						return 'ECOLOG-PRO 1THGi';
					case 'Y':
						return 'ECOLOG-PRO 1TGe';
					default:
						return null;
				}
			default:
				return null;
		}
	}

	render() {
		return (
			<div className={'moduleImageWWrapper ' + (this.state.selected ? 'selectedModule' : 'unSelectedModule')}>
				<img
					alt="module"
					id={this.state.imgId + (this.state.selected ? '_selected' : '')}
					src={this.state.imgSrc}
					className={this.state.selected ? null : this.state.initial ? null : 'grayscale'}
				/>
				{this.state.title && <h3 className="moduleImageTitle">{this.state.title}</h3>}
			</div>
		);
	}
}

export default ModuleCard;
