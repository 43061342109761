import React from 'react';
import {Trans} from 'react-i18next';

type SensorBasicsCommunicationIntervalProps = {
	communicationInterval: number;
	show: boolean;
};

export default function SensorBasicsCommunicationIntervalComponent(props: SensorBasicsCommunicationIntervalProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.communicationInterval'} />
				</td>
				<td>
					{props.communicationInterval} <Trans i18nKey={'sensorWizard.summary.minutes'} />
				</td>
			</tr>
		)
	);
}
