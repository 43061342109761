import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

let userData = {org: '', first_name: '', last_name: ''};

const parseJwt = token => {
    try {
        const base64Payload = token.split('.')[1];
        let payload = new Uint8Array();
        try {
            payload = Buffer.from(base64Payload, 'base64');
        } catch (err) {
            return null;
        }
        return JSON.parse(payload);
    } catch (err) {
        return null;
    }
};

function decodeJwt() {
    const token = Authentication.getToken();
    if (token !== null) {
        userData = parseJwt(token);
    }
}

function getUserData() {
    return userData;
}

const userSettings = {
    decodeJwt,
    getUserData,
};

export default userSettings;
