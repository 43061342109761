import React, {useEffect, useState} from 'react';
import Modal from '../../../Shared/Components/Modal';
import {Select, Input, Form} from 'antd';
import FormValidator from '../../../Forms/FormValidator';
import styled, {css} from 'styled-components';
import {useTranslation} from 'react-i18next';

const ErrorMessage = styled.div`
    padding-top: 3px;
    ${props =>
        props.hasError
            ? css`
                  display: block;
              `
            : css`
                  display: none;
              `}
`;

const AddUser = ({title, visible, handleCancel, handleOk, handleAfterClose, roles}) => {
    const {t} = useTranslation();
    const defaultFormValues = {firstName: '', lastName: '', mobile: '', email: '', roles: [], sendActivationOnSave: true};
    const [userForm, setUserForm] = useState(defaultFormValues);
    const [errors, setErrors] = useState();
    const [formLayout] = useState({labelCol: {span: 4}});
    const [addUserForm] = Form.useForm();
    const [userRoles, setUserRoles] = useState([roles]);

    useEffect(() => {
        setUserRoles(setRoles());
        setDefaultSelectedRole(userRoles);
    }, [roles]);

    const setRoles = () => {
        const selectOptions = [];
        for (const key of Object.keys(roles)) {
            const opt = (
                <Select.Option disabled={!roles[key]} value={key} key={key}>
                    {t('settings.userManagement.roles.' + key)}
                </Select.Option>
            );
            selectOptions.push(opt);
        }
        return selectOptions;
    };

    const setDefaultSelectedRole = roles => {
        const defaultSelected = roles.find(role => role.props && role.props.disabled === false);
        if (defaultSelected) {
            setUserForm({...userForm, roles: [defaultSelected.key]});
        }
    };

    const handleRoleChange = value => {
        setUserForm({...userForm, roles: [value]});
    };

    const handleAddUser = () => {
        const {errors, hasError} = validateForm();
        setErrors(errors);
        if (!hasError) {
            handleOk(userForm);
        }
    };

    const validateForm = () => {
        const inputFields = Object.keys(userForm)
            .map(formItem => {
                if (addUserForm.getFieldInstance(formItem) && addUserForm.getFieldInstance(formItem).input) {
                    return addUserForm.getFieldInstance(formItem).input;
                }
                return null;
            })
            .filter(e => e);
        return FormValidator.bulkValidate(inputFields);
    };

    const hasError = (formName, inputName, method) => {
        if (errors) {
            return errors[inputName][method];
        }
    };

    return (
        <Modal
            width="680px"
            title={title}
            visible={visible}
            handleCancel={handleCancel}
            handleOk={handleAddUser}
            okText="Add User"
            afterClose={handleAfterClose}
            destroyOnClose={true}
        >
            <div>
                <Form {...formLayout} form={addUserForm} preserve={false}>
                    <Form.Item label="First Name">
                        <Form.Item name="firstName" style={{marginBottom: '3px'}}>
                            <Input
                                autoComplete="nope"
                                name="firstName"
                                id="firstName"
                                data-validate='["required"]'
                                value={userForm.firstName}
                                onChange={e => setUserForm({...userForm, firstName: e.target.value})}
                            />
                        </Form.Item>
                        <ErrorMessage hasError={hasError('addUserForm', 'firstName', 'required')} className={'invalid-feedback'}>
                            <span>Field is required</span>
                        </ErrorMessage>
                    </Form.Item>

                    <Form.Item label="Last Name">
                        <Form.Item name="lastName" style={{marginBottom: '3px'}}>
                            <Input
                                autoComplete="nope"
                                name="lastName"
                                id="lastName"
                                data-validate='["required"]'
                                value={userForm.lastName}
                                onChange={e => setUserForm({...userForm, lastName: e.target.value})}
                            />
                        </Form.Item>
                        <ErrorMessage hasError={hasError('addUserForm', 'lastName', 'required')} className={'invalid-feedback'}>
                            <span>Field is required</span>
                        </ErrorMessage>
                    </Form.Item>

                    <Form.Item label="Email/Login">
                        <Form.Item name="email" style={{marginBottom: '3px'}}>
                            <Input
                                autoComplete="nope"
                                name="email"
                                id="email"
                                data-validate='["required", "email"]'
                                value={userForm.email}
                                onChange={e => setUserForm({...userForm, email: e.target.value})}
                            />
                        </Form.Item>
                        <ErrorMessage hasError={hasError('addUserForm', 'email', 'required')} className={'invalid-feedback'}>
                            <span>Field is required</span>
                        </ErrorMessage>
                        <ErrorMessage hasError={hasError('addUserForm', 'email', 'email')} className={'invalid-feedback'}>
                            <span>Email is not valid</span>
                        </ErrorMessage>
                    </Form.Item>

                    <Form.Item label="Mobile Phone">
                        <Form.Item name="mobile" style={{marginBottom: '3px'}}>
                            <Input
                                autoComplete="nope"
                                name="mobile"
                                id="mobile"
                                data-validate={userForm.mobile !== '' ? '["mobilePhone"]' : null}
                                value={userForm.mobile}
                                onChange={e => setUserForm({...userForm, mobile: e.target.value})}
                            />
                        </Form.Item>
                        <ErrorMessage hasError={hasError('addUserForm', 'mobile', 'mobilePhone')} className={'invalid-feedback'}>
                            <span>Mobile Phone Number is not valid</span>
                        </ErrorMessage>
                    </Form.Item>

                    <Form.Item label="Role" style={{marginBottom: '12px'}}>
                        <Select mode="single" defaultValue={userForm.roles} onChange={handleRoleChange}>
                            {userRoles.map(role => role)}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default AddUser;
