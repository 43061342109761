export enum RADIO_STRENGTH_ENUM {
	VERYGOOD = 4,
	GOOD = 3,
	WEAK = 2,
	BAD = 1,
	VERYBAD = 0,
	UNDEFINED = -1,
}

export const RADIO_STRENGTH_STRINGS: Record<number, string> = {
	[RADIO_STRENGTH_ENUM.UNDEFINED]: 'undefined',
	[RADIO_STRENGTH_ENUM.VERYGOOD]: 'fs1 elpro-SignalStrength4',
	[RADIO_STRENGTH_ENUM.GOOD]: 'fs1 elpro-SignalStrength3',
	[RADIO_STRENGTH_ENUM.WEAK]: 'fs1 elpro-SignalStrength2',
    [RADIO_STRENGTH_ENUM.BAD]: 'fs1 elpro-SignalStrength1',
    [RADIO_STRENGTH_ENUM.VERYBAD]: 'fs1 elpro-SignalStrength0',
};
