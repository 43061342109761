import React, {Component} from 'react';
import {Button, Input} from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import {
	changePassword,
	getUser,
	getUserProfile,
	reset2FA,
	sendTestEmail,
	sendTestSms,
	updateProfile,
	updateUser,
} from './UserProfileService';
import ReCaptcha from '../../Services/Extensions/ReCaptcha';
import SystemSettings from '../../Infrastructure/Settings/SystemSettings';
import {Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} from 'react-accessible-accordion';
import FormValidator from '../../Forms/FormValidator';
import pubsub from 'pubsub-js';
import Shared from '../../Shared/Shared';
import Cancel from '../../Shared/Components/Cancel';
import Save from '../../Shared/Components/Save';
import Message from '../../Shared/Components/Message';
import KnowledgeHelp from '../../Shared/Components/KnowledgeBaseHelp';
import {StyledHeaderHelpIcon} from '../../Shared/Styles/StyledHeaderHelpIcon';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import {Access} from '../../Infrastructure/Authorization/Components';
import {authUtils} from '../../Infrastructure/Authorization/Utils';
import {AccessContext} from '../../Infrastructure/Authorization/Context/AccessContext';
import RequestLogger from '../../Infrastructure/Requests/Logger/RequestLogger';
import ReCAPTCHA from 'react-google-recaptcha';
import {Trans} from 'react-i18next';
import {UserProfileState} from './UserProfileState';

const {TEST_SITE_KEY} = process.env;

const pwError = {
	fontSize: '80%',
	color: '#f61214',
};

class UserProfile extends Component<any, UserProfileState> {
	reCaptchaEmailRef: React.RefObject<ReCAPTCHA>;
	reCaptchaSmsRef: React.RefObject<ReCAPTCHA>;

	constructor(props) {
		super(props);

		this.reCaptchaEmailRef = React.createRef<ReCAPTCHA>();
		this.reCaptchaSmsRef = React.createRef<ReCAPTCHA>();

		this.state = {
			edit: false,
			saveDisabled: true,
			initialUserFormValues: undefined,
			initialUserProfileFormValues: undefined,
			user: {
				first_name: '',
				last_name: '',
				email: '',
				mobile: '',
				access_settings: undefined,
			},
			userProfile: {
				user_id: undefined,
				timezone: '',
				language: '',
			},
			timezones: [{id: '', display_name: ''}],
			changePassword: {
				old_password: '',
				new_password: '',
				confirm_password: '',
			},
			changePasswordError: undefined,
			languages: [
				{
					id: 'en',
					name: 'English',
				},
			],
			errors: undefined,
			selectedTZ: undefined,
			errorMessage: '',
			passwordStrength: '',
			hasEditUserDataAccess: true,
			hasEditUserSettingsAccess: true,
			hasChangeUserPasswordAccess: true,
			isAdmin: true,
		};
	}

	static contextType = AccessContext;

	componentDidMount() {
		this.getAccessPermissions(this.context);

		let gets = [
			getUser(RequestLogger.createLogData('user-profile', 'load-user', 'onLoad')),
			getUserProfile(RequestLogger.createLogData('user-profile', 'load-profile', 'onLoad')),
			SystemSettings.getTimeZones(RequestLogger.createLogData('user-profile', 'load-time-zones', 'onLoad')),
		];

		Promise.all(gets).then(responses => {
			const [{data: user}, {data: userProfile}, {data: timezones}] = responses;

			if (user.mobile === null) {
				user.mobile = '';
			}

			this.setState({
				user: user,
				initialUserFormValues: user,
				userProfile: userProfile,
				initialUserProfileFormValues: userProfile,
				timezones: timezones,
			});
		});
	}

	getAccessPermissions = accessContext => {
		const editUserDataAccess = authUtils.findAccessElement(
			accessPermissions.settings.child.userProfile.child.editUserProfile.child.editUserData.path,
			accessContext.user.access_settings.access
		);

		const editUserSettingsAccess = authUtils.findAccessElement(
			accessPermissions.settings.child.userProfile.child.editUserProfile.child.editUserSettings.path,
			accessContext.user.access_settings.access
		);

		const changeUserPasswordAccess = authUtils.findAccessElement(
			accessPermissions.settings.child.userProfile.child.editUserProfile.child.changeUserPassword.path,
			accessContext.user.access_settings.access
		);

		this.setState({
			hasEditUserDataAccess: editUserDataAccess.allow,
			hasEditUserSettingsAccess: editUserSettingsAccess.allow,
			hasChangeUserPasswordAccess: changeUserPasswordAccess.allow,
			isAdmin: accessContext.user.access_settings.settings.roles.some(role => userRoles.admin.indexOf(role) >= 0),
		});
	};

	getTimezoneById(timezone) {
		this.state.timezones.forEach(tz => {
			if (tz.id === timezone) {
				return tz;
			}
		});
		return undefined;
	}

	handleUserChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(
			{
				user: {
					...this.state.user,
					[input.name]: value,
				},
			},
			() => {
				const initialValues = Shared.mapObjectValuesToString(this.state.initialUserFormValues);
				const currentValues = Shared.mapObjectValuesToString(this.state.user);
				this.setState({saveDisabled: JSON.stringify(initialValues) === JSON.stringify(currentValues)});
			}
		);
	};

	handleUserProfileChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(
			{
				userProfile: {
					...this.state.userProfile,
					[input.name]: value,
				},
			},
			() => {
				const initialValues = Shared.mapObjectValuesToString(this.state.initialUserProfileFormValues);
				const currentValues = Shared.mapObjectValuesToString(this.state.userProfile);
				this.setState({saveDisabled: JSON.stringify(initialValues) === JSON.stringify(currentValues)});
			}
		);
	};

	handleTimezoneChange = event => {
		const input = event.target;
		const value = input.value;

		this.getTimezoneById(input.value);

		this.setState(
			{
				userProfile: {
					...this.state.userProfile,
					timezone: value,
				},
			},
			() => {
				const initialValues = Shared.mapObjectValuesToString(this.state.initialUserProfileFormValues);
				const currentValues = Shared.mapObjectValuesToString(this.state.userProfile);
				this.setState({saveDisabled: JSON.stringify(initialValues) === JSON.stringify(currentValues)});
			}
		);
	};

	handlePasswordChangeInputs = event => {
		const input = event.target;
		const value = input.value;

		this.setState(prevState => ({
			changePassword: {
				...prevState.changePassword,
				[input.name]: value,
			},
		}));
	};

	hasError = (formName, inputName, method) => {
		return this.state && this.state.errors && this.state.errors[inputName] && this.state.errors[inputName][method];
	};

	reset2FA = e => {
		reset2FA(null, RequestLogger.createLogData('user-profile', 'reset2FA', 'onClick'))
			.then(() => Message.success('2FA', '2FA reseted'))
			.catch(error => Message.error('2FA', 'Reset failed!', error));

		e.preventDefault();
	};

	changePassword = e => {
		this.setState({changePasswordError: undefined});

		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);
		this.setState({errors: errors});

		if (!hasError) {
			const data = {old_password: this.state.changePassword.old_password, new_password: this.state.changePassword.new_password};
			changePassword(data, RequestLogger.createLogData('user-profile', 'change-password', 'onClick'))
				.then(() => Message.success('Password changed', 'Password successfully changed'))
				.catch(error => Message.error('Error', 'Wrong password. Please enter your current password correctly', error));
		}

		e.preventDefault();
	};

	onSubmit = e => {
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		let {errors, hasError} = FormValidator.bulkValidate(inputs);

		if (!this.state.isAdmin) {
			errors['mobile'] = [];
			hasError = false;
		}

		this.setState({errors: errors});

		if (!hasError) {
			let successMessage = 'User profile successfully changed.';

			if (this.state.initialUserFormValues.email !== this.state.user.email) {
				successMessage += ' An activation email has been sent to your requested address. It will be valid for 24 hours.';
			}

			const gets = [
				updateProfile(
					{
						timez: this.state.userProfile.timezone,
						lang: this.state.userProfile.language,
					},
					RequestLogger.createLogData('user-profile', 'update-profile', 'onClick')
				),
				updateUser(this.state.user, RequestLogger.createLogData('user-profile', 'update-user', 'onClick')),
				SystemSettings.getTimeZoneOffset(
					this.state.userProfile.timezone,
					RequestLogger.createLogData('user-profile', 'get-time-zone-offset', 'onClick')
				),
			];

			Promise.all(gets)
				.then(response => {
					localStorage.removeItem('tzOffset');
					localStorage.setItem('tzOffset', response[2].data);

					getUserProfile(RequestLogger.createLogData('profile', 'load-user-profile', 'onClick'))
						.then(response => {
							localStorage.setItem('supports_dst', response.data.supports_dst);
							localStorage.setItem('base_utc_offset', response.data.base_utc_offset);
							localStorage.setItem('timezone', response.data.iana_name);

							pubsub.publish('tzChanged', localStorage.getItem('tzOffset'));

							Message.success('User profile changed', successMessage);

							this.setState(
								{
									user: this.state.user,
									initialUserFormValues: this.state.user,
									userProfile: this.state.userProfile,
									initialUserProfileFormValues: this.state.userProfile,
									saveDisabled: true,
									errorMessage: undefined,
									changePassword: {
										old_password: '',
										new_password: '',
										confirm_password: '',
									},
								},
								() => {
									pubsub.publish('userChanged', this.state.user);
								}
							);
						})
						.catch(error => console.log('error ', error));
				})
				.catch(error => Message.error('Error', 'Changes could not be saved', error));
		}

		e.preventDefault();
	};

	cancel = e => {
		this.setState({
			user: this.state.initialUserFormValues,
			userProfile: this.state.initialUserProfileFormValues,
			changePassword: {
				old_password: '',
				new_password: '',
				confirm_password: '',
			},
			edit: false,
			saveDisabled: true,
			errorMessage: undefined,
		});
		e.preventDefault();
	};

	handlePWChange = e => {
		this.setState({errorMessage: Shared.validatePassword(e.target.value)});
		this.setState({passwordStrength: Shared.passwordMeasureStrength(e.target.value)});
		this.handlePasswordChangeInputs(e);
	};

	editUserProfileData = () => {
		this.setState({edit: true});
	};

	validateReCaptcha = e => {
		let callBackMethod = undefined;
		let token = undefined;

		if (e.currentTarget.id === 'btnSendTestEmail') {
			token = this.reCaptchaEmailRef.current.getValue();
			this.reCaptchaEmailRef.current.reset();

			callBackMethod = this.sendTestEmail;
		} else {
			token = this.reCaptchaSmsRef.current.getValue();
			this.reCaptchaSmsRef.current.reset();
			callBackMethod = this.sendTestSms;
		}

		const payload = {
			token: token,
		};

		ReCaptcha.ValidateReCaptchaToken(payload)
			.then(response => {
				if (response.data.validated) {
					callBackMethod();
				} else {
					Message.error('Error', <Trans i18nKey={'error.notARobot'} />);
				}
			})
			.catch(error => {
				Message.error('Error', <Trans i18nKey={'error.serverError'} />, error);
			});
		e.preventDefault();
	};

	sendTestEmail = () => {
		sendTestEmail(null, RequestLogger.createLogData('user-profile', 'send-test-email', 'onClick'))
			.then(response => {
				if (response.status === 200) {
					Message.success('Success', <Trans i18nKey={'success.testEmailSent'} />);
				} else {
					Message.error('Error', <Trans i18nKey={'error.testEmailFailed'} />);
				}
			})
			.catch(error => Message.error('Error', <Trans i18nKey={'error.testEmailFailed'} />, error));
	};

	sendTestSms = () => {
		sendTestSms(null, RequestLogger.createLogData('user-profile', 'send-test-sms', 'onClick'))
			.then(response => {
				if (response.status === 200) {
					Message.success('Success', <Trans i18nKey={'success.testSmsSent'} />);
				} else {
					Message.error('Error', <Trans i18nKey={'error.testSmsFailed'} />);
				}
			})
			.catch(error => Message.error('Error', <Trans i18nKey={'error.testSmsFailed'} />, error));
	};

	isRequiredFieldForRole = isAdmin => (isAdmin ? 'required' : null);

	render() {
		const mfaOn =
			this.state.user.access_settings && (this.state.user.access_settings.mfaEnabled || this.state.user.access_settings.mfaSecret);
		return (
			<ContentWrapper>
				<form id="userProfile" name="userProfileForm" onSubmit={this.onSubmit}>
					<div className="content-heading">
						<div>User Profile</div>
						<StyledHeaderHelpIcon>
							<KnowledgeHelp id="045" />
						</StyledHeaderHelpIcon>
						<div className="ml-auto">
							{this.state.edit ? (
								<>
									<Save disabled={this.state.saveDisabled} />
									{'\u00A0'}
									<Cancel onClick={this.cancel} />
								</>
							) : (
								<>
									<Access
										access={accessPermissions.settings.child.userProfile.child.editUserProfile}
										roles={userRoles.default}
									>
										<Button id="btnSave" color="primary" size="lg" onClick={this.editUserProfileData}>
											<span>Edit User Profile</span>
										</Button>
									</Access>
								</>
							)}
						</div>
					</div>
					<Accordion>
						<AccordionItem expanded>
							<AccordionItemTitle>
								<h5 className="u-position-relative">
									User
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								<fieldset>
									<div className="form-group row align-items-center">
										<label className="col-md-2 col-form-label">First name</label>
										<div className="col-sm-3">
											<Input
												type="text"
												id="inputFirstName"
												name="first_name"
												placeholder="Enter the First Name"
												invalid={this.hasError('user', 'first_name', 'required')}
												onChange={this.handleUserChange}
												data-validate='["required"]'
												value={this.state.user.first_name}
												disabled={!this.state.edit || !(this.state.edit && this.state.hasEditUserDataAccess)}
											/>
											{this.hasError('user', 'first_name', 'required') && (
												<span className="invalid-feedback">Field is required</span>
											)}
										</div>
									</div>
									<br />
									<div className="form-group row align-items-center">
										<label className="col-md-2 col-form-label">Last name</label>
										<div className="col-sm-3">
											<Input
												type="text"
												id="inputLastName"
												name="last_name"
												placeholder="Enter the Last Name"
												invalid={this.hasError('user', 'last_name', 'required')}
												onChange={this.handleUserChange}
												data-validate='["required"]'
												value={this.state.user.last_name}
												disabled={!this.state.edit || !(this.state.edit && this.state.hasEditUserDataAccess)}
											/>
											{this.hasError('user', 'last_name', 'required') && (
												<span className="invalid-feedback">Field is required</span>
											)}
										</div>
									</div>
									<br />

									<div className="form-group row align-items-center">
										<label className="col-md-2 col-form-label">Email address</label>
										<div className="col-sm-3">
											<Input
												type="text"
												id="inputUserEmail"
												name="email"
												placeholder="Enter the Email"
												invalid={
													this.hasError('user', 'email', 'required') || this.hasError('user', 'email', 'email')
												}
												onChange={this.handleUserChange}
												data-validate='["required", "email"]'
												value={this.state.user.email}
												disabled={!this.state.edit}
											/>
											{this.hasError('user', 'email', 'required') && (
												<span className="invalid-feedback">Field is required</span>
											)}
											{this.hasError('user', 'email', 'email') && (
												<span className="invalid-feedback">Email is not valid</span>
											)}
										</div>
										<Button
											id="btnSendTestEmail"
											color="primary"
											size="lg"
											disabled={this.state.edit}
											onClick={this.validateReCaptcha}
										>
											<span>Send Test Email</span>
										</Button>
										{!this.state.edit && (
											<ReCAPTCHA
												id="reCaptchaEmail"
												ref={this.reCaptchaEmailRef}
												className="col-md-2 col-form-label"
												sitekey={TEST_SITE_KEY}
											/>
										)}
									</div>
									<br />
									<div className="form-group row align-items-center">
										<label className="col-md-2 col-form-label">Mobile phone</label>
										<div className="col-sm-3">
											<Input
												type="text"
												id="inputMobile"
												name="mobile"
												value={this.state.user.mobile}
												onChange={this.handleUserChange}
												disabled={!this.state.edit}
												invalid={
													this.hasError('', 'user', 'mobile') || this.hasError('user', 'mobile', 'mobilePhone')
												}
												data-validate='["mobilePhone"]'
											/>
											{this.hasError('user', 'mobile', 'mobilePhone') && (
												<span id="errorMobilePhoneNotValid" className="invalid-feedback">
													Field must be valid mobile phone
													<br />
													(e.g. +41 79 552 08 08)
												</span>
											)}
										</div>
										<Button
											id="btnSendTestSms"
											color="primary"
											size="lg"
											disabled={this.state.edit}
											onClick={this.validateReCaptcha}
										>
											<span>Send Test SMS</span>
										</Button>
										{!this.state.edit && (
											<ReCAPTCHA
												id="reCaptchaSms"
												ref={this.reCaptchaSmsRef}
												className="col-md-2 col-form-label"
												sitekey={TEST_SITE_KEY}
											/>
										)}
									</div>
								</fieldset>
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
					<br />
					<Accordion>
						<AccordionItem expanded>
							<AccordionItemTitle>
								<h5 className="u-position-relative">
									User settings
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								<fieldset>
									<div className="form-group row align-items-center">
										<label className="col-md-2 col-form-label">Time zone</label>
										<div className="col-sm-3">
											<select
												className="form-control"
												id="selectTimezones"
												name="timezones"
												value={this.state.userProfile.timezone}
												onChange={this.handleTimezoneChange}
												disabled={!this.state.edit || !(this.state.edit && this.state.hasEditUserSettingsAccess)}
											>
												{this.state.timezones.map(timezone => (
													<option key={timezone.id} value={timezone.id}>
														{timezone.display_name}
													</option>
												))}
											</select>
										</div>
									</div>
									<br />
									<div className="form-group row align-items-center">
										<label className="col-md-2 col-form-label">User interface language</label>
										<div className="col-sm-3">
											<select
												name="language"
												id="selectLanguages"
												className="form-control"
												value={this.state.userProfile.language}
												onChange={this.handleUserProfileChange}
												disabled={!this.state.edit || !(this.state.edit && this.state.hasEditUserSettingsAccess)}
											>
												{this.state.languages.map(lang => (
													<option key={lang.id} value={lang.id}>
														{lang.name}
													</option>
												))}
											</select>
										</div>
									</div>
								</fieldset>
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				</form>
				<br />
				<Accordion>
					<AccordionItem expanded>
						<AccordionItemTitle>
							<h5 className="u-position-relative">
								Reset password
								<div className="accordion__arrow" role="presentation" />
							</h5>
						</AccordionItemTitle>
						<AccordionItemBody>
							<form id="changePassword" name="changePassword" onSubmit={this.changePassword}>
								<div className="form-group row align-items-center">
									<label className="col-md-2 col-form-label">Old password</label>
									<div className="col-sm-3">
										<Input
											type="password"
											id="inputOldPassword"
											name="old_password"
											placeholder="Enter the old password"
											invalid={this.hasError('changePassword', 'old_password', 'required')}
											onChange={this.handlePasswordChangeInputs}
											data-validate='["required"]'
											value={this.state.changePassword.old_password}
											disabled={!this.state.edit || !(this.state.edit && this.state.hasChangeUserPasswordAccess)}
										/>
										{this.hasError('changePassword', 'old_password', 'required') && (
											<span className="invalid-feedback">Field is required</span>
										)}
									</div>
								</div>
								<br />
								<div className="form-group row align-items-center">
									<label className="col-md-2 col-form-label">New password</label>
									<div className="col-sm-3">
										<Input
											type="password"
											id="inputNewPassword"
											name="new_password"
											placeholder="Enter the new password"
											value={this.state.changePassword.new_password}
											invalid={this.hasError('changePassword', 'new_password', 'required')}
											data-validate='["required"]'
											onChange={this.handlePWChange}
											disabled={!this.state.edit || !(this.state.edit && this.state.hasChangeUserPasswordAccess)}
										/>
										{this.hasError('changePassword', 'new_password', 'required') && (
											<span className="invalid-feedback">Field is required</span>
										)}
										{this.state.errorMessage && <span style={pwError}>{this.state.errorMessage}</span>}
									</div>
								</div>
								<br />
								<div className="form-group row align-items-center">
									<label className="col-md-2 col-form-label">Confirm password</label>
									<div className="col-sm-3">
										<Input
											type="password"
											id="inputConfirmPassword"
											name="confirm_password"
											placeholder="Confirm the new password"
											invalid={this.hasError('changePassword', 'confirm_password', 'equalto')}
											data-param="inputNewPassword"
											onChange={this.handlePasswordChangeInputs}
											data-validate='["equalto"]'
											value={this.state.changePassword.confirm_password}
											disabled={!this.state.edit || !(this.state.edit && this.state.hasChangeUserPasswordAccess)}
										/>
										{this.hasError('changePassword', 'confirm_password', 'required') && (
											<span className="invalid-feedback">Field is required</span>
										)}
										{this.hasError('formRegister', 'confirm_password', 'equalto') && (
											<span id="errorPasswordsNotEqual" className="invalid-feedback">
												Passwords must be equal
											</span>
										)}
									</div>
								</div>
								<Button
									id="btnChangePassword"
									color="primary"
									size="lg"
									type="submit"
									disabled={!!this.state.errorMessage || !this.state.edit}
								>
									<span>Change password</span>
								</Button>
							</form>
						</AccordionItemBody>
					</AccordionItem>
				</Accordion>
				<br />
				{mfaOn ? (
					<Accordion>
						<AccordionItem expanded>
							<AccordionItemTitle>
								<h5 className="u-position-relative">
									Reset 2FA
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								<form id="reset2FA" name="reset2FA" onSubmit={this.reset2FA}>
									<div className="form-group row align-items-center">
										<div className="col-sm-3">
											<Button
												id="btnReset2FA"
												color="primary"
												size="lg"
												type="submit"
												disabled={!!this.state.errorMessage || !this.state.edit}
											>
												<span>Reset 2FA</span>
											</Button>
										</div>
									</div>
								</form>
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				) : (
					''
				)}
			</ContentWrapper>
		);
	}
}

export default UserProfile;
