import React, {useEffect, useState} from 'react';
import Modal from '../../../Shared/Components/Modal';
import {AntStyledTable} from '../../../Shared/Styles/AntStyledTable';
import {Tree, Alert} from 'antd';
import {AccessSettingsTree, AccessPageColumns, AccessElementColumns} from '../DataHandling';
import {authUtils} from '../../../Infrastructure/Authorization/Utils';
import {userRoles} from '../../../Infrastructure/Authorization/Access';
import styled from 'styled-components';

const RestrictUserRoleWarningWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0 0 20px 0;
	padding-bottom: 12px;
	border-bottom: solid 1px #f0f0f0;
	& > div {
		flex-grow: 1;
	}
`;

const AccessContentWrapper = styled.div`
	display: flex;
`;

const AccessContentWrapperTree = styled.div`
	flex-grow: 0;
	min-width: 230px;
`;

const AccessContentWrapperSettings = styled.div`
	flex-grow: 1;
	height: 300px;
	min-height: 300px;
	overflow-y: auto;
`;

const ALLOWED_ROLES_TO_MODIFY = [...userRoles.admin, ...userRoles.manager];

const RestrictUserRoleWarning = () => {
	return (
		<RestrictUserRoleWarningWrapper>
			<div>
				<Alert description="Access Settings for User with this Role can't be modified" type="warning" />
			</div>
		</RestrictUserRoleWarningWrapper>
	);
};

const ShowAccessSettings = ({title, visible, handleOk, handleCancel, handleAfterClose, accessSettingsContent}) => {
	const [selectedPagePermissionsDataSource, setSelectedPagePermissionsDataSource] = useState([]);
	const [userData, setUserData] = useState({});
	const [expandedRowKeys, setExpandedRowKeys] = useState([0]);
	const [userSettings, setUserSettings] = useState();
	const [rootItemKey, setRootItemKey] = useState('');
	const [canModifyAccess, setCanModifyAccess] = useState(true);

	useEffect(() => {
		if (accessSettingsContent.access_settings) {
			setCanModifyAccess(
				ALLOWED_ROLES_TO_MODIFY.filter(e => accessSettingsContent.access_settings.settings.roles.includes(e)).length !== 0
			);

			setUserSettings(accessSettingsContent.access_settings);
		}
	}, [accessSettingsContent]);

	useEffect(() => {
		if (userSettings && !rootItemKey) {
			setRootItemKey(userSettings.access[0].key);
			setSelectedPagePermissionsDataSource([userSettings.access[0]]);
			selectedUserData(accessSettingsContent);
			setExpandedRowKeys(expandedRowKeys);
		}
	}, [userSettings]);

	const onSelect = (selectedKeys, info) => {
		if (info.node.type === 'node') return;
		const item = authUtils.findAccessElement(info.node.path, userSettings.access);
		setRootItemKey(item.key);
		setSelectedPagePermissionsDataSource([item]);
		setExpandedRowKeys([item.index]);
	};

	const selectedUserData = user => {
		setUserData({
			id: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
		});
	};

	const expandedRowRenderPage = record => {
		return (
			<AntStyledTable
				showHeader={false}
				size="small"
				columns={AccessElementColumns(onChangePageAccess, record, canModifyAccess)}
				dataSource={selectedPagePermissionsDataSource[0].child}
				pagination={false}
				expandable={{
					expandedRowRender: record => expandedRowRenderChild(record),
					rowExpandable: record => record.child,
				}}
			/>
		);
	};

	const expandedRowRenderChild = record => {
		return (
			<div style={{paddingLeft: '20px'}}>
				<AntStyledTable
					showHeader={false}
					size="small"
					columns={AccessElementColumns(onChangePageAccess, record, canModifyAccess)}
					dataSource={record.child}
					pagination={false}
				/>
			</div>
		);
	};

	const onChangePageAccess = (event, record) => {
		const modSettings = userSettings.access.map(item => {
			if (record.path.length === 1 && record.key === item.key) {
				item.allow = event.target.checked;
			} else {
				item.child.map(child => {
					if (child.child && record.path.length === 2) {
						child.child.map(childL1 => {
							if (childL1.key === record.key) {
								childL1.allow = event.target.checked;
							}
							return childL1;
						});
					}

					if (child.child && record.path.length === 3 && child.key === record.path[1]) {
						child.child.map(childL2 => {
							if (childL2.child) {
								childL2.child.map(childL3 => {
									if (childL3.key === record.key) {
										childL3.allow = event.target.checked;
									}
									return childL3;
								});
							} else {
								if (childL2.key === record.key) {
									childL2.allow = event.target.checked;
								}
							}
							return childL2;
						});
					}

					if (child.key === record.key) {
						child.allow = event.target.checked;
					}
					return child;
				});
			}

			return item;
		});

		const settings = {...userSettings};
		settings.access = modSettings;
		setUserSettings(settings);
	};

	const handleUpdateSettings = () => {
		handleOk(userData.id, userSettings);
		setRootItemKey('');
		setExpandedRowKeys([0]);
	};

	const handleCancelSettings = () => {
		handleCancel();
		setRootItemKey('');
		setExpandedRowKeys([0]);
	};

	return (
		<Modal
			width="50%"
			title={title}
			visible={visible}
			handleCancel={handleCancelSettings}
			handleOk={handleUpdateSettings}
			afterClose={handleAfterClose}
			bodyStyle={{paddingTop: '12px'}}
			destroyOnClose={true}
		>
			<div>
				{!canModifyAccess && <RestrictUserRoleWarning />}
				<AccessContentWrapper>
					<AccessContentWrapperTree>
						<Tree
							defaultExpandAll={true}
							onSelect={onSelect}
							showLine={true}
							showIcon={false}
							defaultSelectedKeys={['0-0']}
							treeData={AccessSettingsTree}
						/>
					</AccessContentWrapperTree>
					<AccessContentWrapperSettings>
						<AntStyledTable
							size="small"
							sticky={true}
							columns={AccessPageColumns(onChangePageAccess, canModifyAccess)}
							dataSource={selectedPagePermissionsDataSource}
							pagination={false}
							defaultExpandedRowKeys={[0]}
							expandedRowKeys={expandedRowKeys}
							rowKey={record => record.index}
							expandable={{
								expandedRowRender: record => {
									return record.child?.length ? expandedRowRenderPage(record) : <></>;
								},
							}}
						/>
					</AccessContentWrapperSettings>
				</AccessContentWrapper>
			</div>
		</Modal>
	);
};

export default ShowAccessSettings;
