import {MODES} from '../Modes';
import {configurationIssueProfileFormSettings} from '../IssueProfileFormSettings';

export const getInitialStateData = (mode, issueProfileId = null) => {
    const stateObject = {
        formSettings: configurationIssueProfileFormSettings(mode),
        saveDisabled: true,
        sensors: undefined,
        mode: mode,
        initialFormValues: undefined,
        issueProfile: defaultIssueProfileState,
        errors: undefined,
    };

    if (mode === MODES.EDIT || mode === MODES.DISPLAY) {
        stateObject.issueProfile.id = issueProfileId;
    }

    return stateObject;
};

export const defaultIssueProfileState = {
    name: '',
    missing_value_alarm: false, //Not used anymore
    missing_value_alarm_delay: 1, //Not used anymore
    lost_measurement_alarm: false,
    missing_communication_warning: false,
    missing_communication_warning_enum: 0,
    light_warning: false,
    tilt_warning: false,
    sensor_failure_alarm: false,
    sensor_failure_alarm_delay: 0,
    radio_connection_warning: false, //not used anymore. https://elproag.atlassian.net/browse/TH-460
    radio_connection_warning_delay: 1, //not used anymore. https://elproag.atlassian.net/browse/TH-460
    radio_connection_warning_threshold: -90, //not used anymore. https://elproag.atlassian.net/browse/TH-460
    low_battery_warning: false,
};
