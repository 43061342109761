export const WizardSettings = {
	ConfigurationEcologProRadioSettings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: true,
			showCommunicationMode: false,
			showCommunicationInterval: false,
			showFastCommunicationInterval: false,
			showTemperatureUnit: true,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: false,
				showCommunicationMode: false,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: false,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: false,
		},
		sensorPaymentMethod: {
			showVoucher: true,
		},
	},
	ConfigurationLiberoGFirmware0Settings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: true,
			showCommunicationMode: false,
			showCommunicationInterval: true,
			showFastCommunicationInterval: false,
			showTemperatureUnit: true,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: false,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: true,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},

	ConfigurationLiberoGFirmware1Settings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: true,
			showCommunicationMode: true,
			showCommunicationInterval: false,
			showFastCommunicationInterval: false,
			showTemperatureUnit: true,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: true,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: true,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},
	ConfigurationEcologProGSettings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: true,
			showCommunicationMode: true,
			showCommunicationInterval: false,
			showFastCommunicationInterval: false,
			showTemperatureUnit: true,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: false,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: true,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: false,
				showMemo: false,
				showFirmware: true,
			},
			showMetadatasTitle: true,
			metadatas: {
				showSensorName: true,
				showCustomersId: true,
				showMemo: true,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},

	ReConfigurationEcologProRadioSettings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: false,
			showCommunicationMode: false,
			showCommunicationInterval: false,
			showFastCommunicationInterval: false,
			showTemperatureUnit: false,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: false,
				showCommunicationMode: false,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: false,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: false,
		},
		sensorPaymentMethod: {
			showVoucher: true,
		},
	},

	ReConfigurationLiberoGFirmware0Settings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: true,
			showCommunicationMode: false,
			showCommunicationInterval: true,
			showFastCommunicationInterval: false,
			showTemperatureUnit: true,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: false,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: true,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},

	ReConfigurationLiberoGFirmware1Settings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: true,
			showCommunicationMode: true,
			showCommunicationInterval: false,
			showFastCommunicationInterval: false,
			showTemperatureUnit: true,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: true,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: true,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: true,
				showMemo: true,
				showFirmware: true,
			},
			showMetadatasTitle: false,
			metadatas: {
				showSensorName: false,
				showCustomersId: false,
				showMemo: false,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},

	ReConfigurationEcologProGSettings: {
		sensorBasicsForm: {
			showSensorChannel: false,
			showSensorName: false,
			showLoggingInterval: false,
			showCommunicationMode: true,
			showCommunicationInterval: false,
			showFastCommunicationInterval: false,
			showTemperatureUnit: true,
			showCustomerId: false,
			showMemo: false,
			showIncreasesYearlyLicenseText: false,
			showPrice: true,
			ShowQuickView: false,
		},
		sensorSummary: {
			showSensorBasicsTitle: true,
			sensorBasics: {
				showSerialNumber: true,
				showSensorName: false,
				showLoggingInterval: true,
				showCommunicatonInterval: true,
				showCommunicationMode: true,
				showFastCommunicationInterval: false,
				showUnit: true,
				showCustomersId: false,
				showMemo: false,
				showFirmware: true,
			},
			showMetadatasTitle: true,
			metadatas: {
				showSensorName: true,
				showCustomersId: true,
				showMemo: true,
			},
			showSensorLimitAlarmsTitle: true,
			showLimitAlarmComponent: true,
			showRecipientsForLimitAlarms: true,
			showSensorIssueAlarmsTitle: true,
			sensorIssueAlarms: {
				showUseIssueAlarm: true,
				showSensorIssueName: true,
				showMissingCommunicationWarning: true,
				showMissingCommunicationWarningEnum: true,
				showSensorFailureAlarm: true,
				showLostMeasurementAlarm: true,
				showLowBatteryWarning: true,
				showRecipientsForIssueAlarms: true,
			},
			showAdditionConfigurationSummary: true,
		},
		sensorPaymentMethod: {
			showVoucher: false,
		},
	},
};
