import React from 'react';
import {Table} from 'antd';
import {useTranslation} from 'react-i18next';
import type {ColumnsType} from 'antd/es/table';
import {OfferLineItemResponse, OfferResponse} from 'common/services/LicensingService';
import {NumberFormatter} from '../../common/util';

import styles from '../Common/styles.module.css';

type LicenseOfferingProps = {
	offering: OfferResponse;
	hasError: boolean;
	loading: boolean;
};

export function LicenseOfferingTable(props: LicenseOfferingProps) {
	const [t] = useTranslation();
	const data = props.offering?.lineItems ?? [];

	const translate = (internalPriceId: string) => t(`sensorWizard.summary.offering.${internalPriceId}`).toString();
	const renderVatItem = (): string => translate('vat') + ' (' + props.offering.vatPercentage + '\u202f%' + ')';

	const columns: ColumnsType<OfferLineItemResponse> = [
		{
			title: 'Item',
			key: 'internalPriceId',
			render: (_, record) => <>{record.internalPriceId.startsWith('VAT') ? renderVatItem() : translate(record.internalPriceId)}</>,
		},
		{
			title: 'Interval',
			dataIndex: 'interval',
			key: 'interval',
			render: (text, record) => <>{record.internalPriceId.startsWith('VAT') ? '' : text}</>,
		},
		{
			title: `Price`,
			dataIndex: 'price',
			key: 'price',
			align: 'right',
			render: (_, record) => <>{NumberFormatter.FormatCurrency(record.price, record.currency)}</>,
		},
	];

	return (
		<Table
			rowKey="index"
			className="table-light"
			locale={{emptyText: 'No data'}}
			dataSource={data}
			pagination={false}
			summary={_ => (
				<Table.Summary fixed>
					<Table.Summary.Row className={styles.TableFooter}>
						<Table.Summary.Cell index={0} colSpan={columns.length - 1}>
							{translate('total')}
						</Table.Summary.Cell>
						<Table.Summary.Cell align="right" index={1}>
							{NumberFormatter.FormatCurrency(props.offering?.totalAmount ?? 0, props.offering?.currency ?? 'CHF')}
						</Table.Summary.Cell>
					</Table.Summary.Row>
				</Table.Summary>
			)}
			columns={columns}
		/>
	);
}
