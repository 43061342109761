import React, {Component} from 'react';
import {Select} from 'antd';
import {Label, Row, Col, FormGroup} from 'reactstrap';
const {Option} = Select;

type FastCommunicationIntervalSelectProps = {
	value: string | undefined;
	onChange: any;

};

class FastCommunicationIntervalSelectState {
	onChange: any;
}

export class FastCommunicationIntervalSelectComponent extends Component<
	FastCommunicationIntervalSelectProps,
	FastCommunicationIntervalSelectState
> {
	constructor(props: FastCommunicationIntervalSelectProps | Readonly<FastCommunicationIntervalSelectProps>) {
		super(props);
		this.state = {
			onChange: props.onChange,
		};
	}

	render() {
		return (
			<Row>
				<Col xl={3}>
					<FormGroup>
						<Label>Fast communication interval</Label>
						<div>
							<Select
								style={{width: '100%'}}
								defaultValue={this.props.value}
								id="selectFastCommunicationInterval"
								value={this.props.value}
								onChange={this.state.onChange}
							>
								<Option value={30}>30 minutes (Ultra fast)</Option>
								<Option value={60}>60 minutes (Fast)</Option>
							</Select>
						</div>
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
