import request from '../../Infrastructure/Requests/Request';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
function limitProfiles(params, eventData) {
    let url = '/sensor_limit_alarm';

    if (params.sortField !== undefined) {
        url += '?order=' + params.sortField + '.' + (params.sortOrder === 'ascend' ? 'asc' : 'desc');
    }

    return request({
        url: url,
        method: 'GET',
        token: Authentication.getToken(),
        eventData,
    });
}

function getLimitProfile(id, eventData) {
    return request({
        url: '/sensor_limit_alarm?id=eq.' + id,
        method: 'GET',
        headers: {Accept: 'application/vnd.pgrst.object'},
        token: Authentication.getToken(),
        eventData,
    });
}

function saveProfile(payload, eventData) {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: payload,
        SensorIssueAlarm: null,
        PaymentInformation: null,
    };
    wizdata.SensorLimitAlarm.limit_alarm_name = wizdata.SensorLimitAlarm.name;
    wizdata.SensorLimitAlarm.use_limit_alarms = true;
    wizdata.SensorLimitAlarm.limit_alarm_creation_type = 0;

    return request({
        url: '/registry/save',
        method: 'POST',
        token: Authentication.getToken(),
        data: wizdata,
        eventData,
    });
}

function updateProfile(payload, eventData) {
    const wizdata = {
        Module: null,
        Sensor: null,
        SensorLimitAlarm: payload,
        SensorIssueAlarm: null,
        PaymentInformation: null,
    };
    wizdata.SensorLimitAlarm.limit_alarm_name = wizdata.SensorLimitAlarm.name;
    wizdata.SensorLimitAlarm.use_limit_alarms = true; //update
    wizdata.SensorLimitAlarm.limit_alarm_creation_type = payload.limit_alarm_creation_type;
    wizdata.SensorLimitAlarm.editable = true;

    return request({
        url: '/registry/update',
        method: 'PUT',
        token: Authentication.getToken(),
        data: wizdata,
        eventData,
    });
}

function deleteProfile(limitProfileId, eventData) {
    return request({
        url: '/registry/sensorlimitprofile?id=' + limitProfileId,
        method: 'DELETE',
        token: Authentication.getToken(),
        eventData,
    });
}

const LimitProfilesService = {
    limitProfiles,
    getLimitProfile,
    saveProfile,
    updateProfile,
    deleteProfile,
};

export default LimitProfilesService;
