import React from 'react';
import {Link} from 'react-router-dom';

const ElproFormLogo = ({link}) => {
	return (
		<Link to={'/' + link}>
			<img width={200} src={process.env.PUBLIC_URL + '/img/logo.png'} alt="elproCLOUD" />
		</Link>
	);
};

export default ElproFormLogo;
