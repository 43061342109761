export class DeviationStatus {
	constructor(init) {
		this.SensorId = init.sensors_id;
		this.Acknowledged = init.acknowledged;
		this.Done = init.done;
		this.Warning = init.warning;
	}

	public SensorId: number;
	public Acknowledged: boolean;
	public Done: boolean;
	public Warning: boolean;
}
