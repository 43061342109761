import React, {Component} from 'react';
import {SignalStrengthComponentProps} from './SignalStrengthComponentProps';
import {SignalStrengthComponentState} from './SignalStrengthComponentState';

export class SignalStrengthComponent extends Component<SignalStrengthComponentProps, SignalStrengthComponentState> {
	constructor(props: SignalStrengthComponentProps | Readonly<SignalStrengthComponentProps>) {
		super(props);
		this.state = {
			signalStrength: props.signalStrength,
		};
	}

	render() {
		return this.state.signalStrength === null ? null : (
			<span className={this.state.signalStrength} style={{marginLeft: '1px'}}>
				<span className="path1" />
				<span className="path2" />
				<span className="path3" />
				<span className="path4" />
				<span className="path5" />
			</span>
		);
	}
}
