import {PLOT_LINE_COLORS, LOGGER_STATUS_EVENTS, RUN_EVENTS, ALARM_EVENTS, FLIGHT_MODE_EVENTS, SENSOR_LIMIT_ALARMS, SENSOR_ISSUE_ALARMS, MENU_SET_MARK_EVENTS, REPLACE_SENSOR_EVENTS} from '../../../Shared/Constants/Chart';

const PickColorOfPlotLine = desc => {
    let color = 'purple';
    if (desc === undefined || desc === null || typeof desc !== 'string') {
        return color;
    }
    switch (desc.replace(/ /g, '').replace(/[0-9]/g, '').replace(/:/g, '')) {
        case RUN_EVENTS.START_RUN[0].replace(/ /g, ''):
        case RUN_EVENTS.STOP_RUN[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.START_STOP_RUN;
            break;
        case ALARM_EVENTS.ALARMING_ON[0].replace(/ /g, ''):
        case ALARM_EVENTS.ALARMING_OFF[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.ALARMING_ON_OFF;
            break;
        case FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[0].replace(/ /g, ''):
        case FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF;
            break;
        case SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[0].replace(/ /g, ''):
        case SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS;
            break;
        case SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[0].replace(/ /g, ''):
        case SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[0].replace(/ /g, ''):
        case SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[0].replace(/ /g, ''):
        case SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS;
            break;
        case LOGGER_STATUS_EVENTS.START[0].replace(/ /g, ''):
        case LOGGER_STATUS_EVENTS.LOG_DELAY[0].replace(/ /g, ''):
        case LOGGER_STATUS_EVENTS.LOG_TRANSIT[0].replace(/ /g, ''):
        case LOGGER_STATUS_EVENTS.LOG_PAUSED[0].replace(/ /g, ''):
        case LOGGER_STATUS_EVENTS.LOG_ARRIVED[0].replace(/ /g, ''):
        case LOGGER_STATUS_EVENTS.LOG_STOPPED[0].replace(/ /g, ''):
        case LOGGER_STATUS_EVENTS.CALIB[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.LOGGERS_STATUSES;
            break;
        case MENU_SET_MARK_EVENTS.MARK_COUNTER[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.MENU_SET_MARK;
            break;
        case REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[0].replace(/ /g, ''):
            color = PLOT_LINE_COLORS.REPLACE_SENSOR;
            break;
        default:
            color = PLOT_LINE_COLORS.DEFAULT;
            break;
    }
    return color;
};

export default PickColorOfPlotLine;
