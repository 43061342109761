import React, {useEffect, useState} from 'react';
import ContentWrapper from '../../Layout/ContentWrapper';
import {Button, Row, Col, Input} from 'reactstrap';
import {Select, Badge, Slider, Form} from 'antd';
import {asyncTestGetVersion, asyncTestGetOrganization} from '../DevelopmentService';
import {asyncRequestState} from '../../Infrastructure/Requests/AsyncRequest';

// the hook translation
import {useTranslation} from 'react-i18next';

// the toast
import {toast} from 'react-toastify';

const createRandomLocation = () => {
	const lat = Math.random() * 4 + 6;
	const lng = Math.random() * 2 + 7.5;
	const city = ['Zürich', 'St.Gallen', 'Buchs', 'Genf', 'Luzern', 'Bern', 'Chur', 'Sion', 'Rorschach'];

	return {
		lat: lat,
		lng: lng,
		city: city[Math.round(Math.random() * (city.length - 1))],
		id: Math.floor(Math.random() * 100000000),
	};
};

const ReactDevelopment = () => {
	/*const randomLocation = () => {
        const lat = Math.random() * 2 + 46;
        const lng = Math.random() * 2 + 7.5;

        const location = {
            lat: lat,
            lng: lng
        };
        return location;
    };*/

	const [myLocation, setMyLocation] = useState(createRandomLocation());
	const [upperLimitValue, setUpperLimitValue] = useState([20, 50]);

	useEffect(() => {
		const timer = setInterval(() => {
			setMyLocation(createRandomLocation());
		}, 500000);
		return () => clearTimeout(timer);
	}, []);

	// the translation hook
	const {t, i18n} = useTranslation();

	// ant design stuff
	const {Option} = Select;

	const handleLanguageChange = value => {
		console.log(`selected ${value}`);
		i18n.changeLanguage(value);
	};

	const toastitError = () => {
		toast.error(
			<div className={'toast-content'}>
				<div className="toast-content-pos">
					<h1>Error</h1>
					<p>Settings could not be saved. Please try again</p>
				</div>
			</div>,
			{
				className: 'toast-error-wrapper',
				bodyClassName: 'toast-content',
			}
		);
	};

	const toastitSuccess = () => {
		toast.success(
			<div className={'toast-content'}>
				<div className="toast-content-pos">
					<h1>Profile changed</h1>
					<p>Profile changes successfully saved</p>
				</div>
			</div>,
			{
				className: 'toast-success-wrapper',
				bodyClassName: 'toast-content',
			}
		);
	};

	const onChangeUpperLimit = value => {
		setUpperLimitValue(value);
	};

	const callAsyncRequest = async () => {
		try {
			const version = await asyncTestGetVersion();
			const organization = await asyncTestGetOrganization();
			console.log('Version: ', version);
			console.log('Organization: ', organization);
			asyncRequestState.success('Async Test success', 'Test description message...');
		} catch (error) {
			console.log(Object.keys(error), error.message);
			asyncRequestState.error(error);
		}
	};

	return (
		<ContentWrapper>
			<div className="content-heading">
				<div>React Development</div>
				<div className="ml-auto">
					<Button id="dummy" color="primary" size="lg">
						<span>{t('buttons.show')}</span>
					</Button>
				</div>
			</div>

			<Row>
				<Col>
					<Button id="dummy" color="primary" size="lg" onClick={callAsyncRequest}>
						<span>TEST - ASYNC</span>
					</Button>
					<hr />
				</Col>
			</Row>

			<Row>
				<Col>
					<Form layout={'horizontal'} name="basic">
						<Form.Item label="Profile Name">
							<Input placeholder="Add profile name" />
						</Form.Item>

						<Form.Item label="Upper/Lower">
							<Slider range step={1} onChange={onChangeUpperLimit} value={upperLimitValue} />
							<Row>
								<Col>
									{' '}
									<Row>
										<Col>
											{' '}
											<Input placeholder="Upper" />
										</Col>
										<Col>
											<Input placeholder="Delay" />
										</Col>
									</Row>
								</Col>
								<Col>
									{' '}
									<Row>
										<Col>
											{' '}
											<Input placeholder="Lower" />
										</Col>
										<Col>
											<Input placeholder="Delay" />
										</Col>{' '}
									</Row>
								</Col>
							</Row>
						</Form.Item>
					</Form>
				</Col>
				{/*<Col>
                   <div style={{
                        display: 'inline-block',
                        width: 300,
                        marginLeft: 70,
                    }}>


                    <InputNumber
                            min={1}
                            max={20}
                            style={{marginLeft: 16}}
                            value={upperLimitValue}
                            onChange={onChangeUpperLimit}
                        />


                    </div>
                    <hr/>
                </Col>*/}
			</Row>
			<Row>
				<Col>
					<Button color="primary" className="btn" onClick={toastitError}>
						<em className="fsInButton elpro-Report fa-fw" />
						Error
					</Button>
					{'\u00A0'}
					<Button color="primary" className="btn" onClick={toastitSuccess}>
						<em className="fsInButton elpro-Report fa-fw" />
						Success
					</Button>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<Select defaultValue="en" style={{width: '100%'}} onChange={handleLanguageChange}>
						<Option value="en">EN Lang</Option>
						<Option value="de">DE Lang</Option>
						<Option value="disabled" disabled>
							Disabled
						</Option>
						<Option value="Yiminghe">yiminghe</Option>
					</Select>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<Input type="select" name="select" id="exampleSelect">
						<option>EN Lang</option>
						<option>DE Lang</option>
						<option>Disabled</option>
						<option>yiminghe</option>
					</Input>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					{/*<div style={{backgroundColor:'rgba(110, 110, 110, 0.5)', width:'40px', height:'40px', padding:'12px 3px', borderRadius: '6px'}}>
                        <Badge count={5} style={{fontSize: '10px'}}>
                        <span className='fs2 elpro-WarningActive' style={{fontSize: '20px'}}>
                            <span className="path1"/>
                            <span className="path2"/>
                            <span className="path3"/>
                            <span className="path4"/>
                        </span>
                        </Badge>
                    </div>

                    <div style={{backgroundColor:'rgba(110, 110, 110, 0.5)', width:'40px', height:'40px', padding:'12px 3px', borderRadius: '6px'}}>
                        <Badge count={5} style={{fontSize: '10px'}}>
                        <span className='fs2 elpro-AlarmAcknowledged' style={{fontSize: '25px'}}>
                            <span className="path1"/>
                            <span className="path2"/>
                            <span className="path3"/>
                            <span className="path4"/>
                        </span>
                        </Badge>
                    </div>*/}
					<Badge count={5} style={{fontSize: '10px', height: '20px', width: '10px'}}>
						<span className="fs2 elpro-AlarmAcknowledged">
							<span className="path1" />
							<span className="path2" />
							<span className="path3" />
							<span className="path4" />
						</span>
					</Badge>
					&nbsp;
					<Badge count={5} style={{fontSize: '10px', height: '20px', width: '10px'}}>
						<span className="fs2 elpro-WarningActive">
							<span className="path1" />
							<span className="path2" />
							<span className="path3" />
							<span className="path4" />
						</span>
					</Badge>
				</Col>
			</Row>
		</ContentWrapper>
	);
};

export default ReactDevelopment;
