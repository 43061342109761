import {ExportType} from './ExportType';
import React from 'react';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {Tooltip} from 'antd';

interface ExportButtonProps {
	ExportType: ExportType;
	WithGeoData: boolean;
	ExportFunction: any;
	Disabled: boolean;
}

export const ExportButton = (props: ExportButtonProps) => {
	const {t} = useTranslation();

	let tooltip = t('tooltips.exportAs', {fileFormat: ExportType[props.ExportType]});

	let classIdentifier = 'exportButton' + ExportType[props.ExportType];
	let faIcon: string;
	switch (props.ExportType) {
		case ExportType.Csv:
			faIcon = 'fa-file-csv';
			break;
		case ExportType.Excel:
			faIcon = 'fa-file-excel';
			break;
		case ExportType.Pdf:
			faIcon = 'fa-file-pdf';
			break;
	}

	return (
		<div style={{padding: '0 5px'}}>
			<Tooltip title={tooltip}>
				<Button
					id={classIdentifier}
					aria-label={tooltip}
					aria-required={true}
					color="primary"
					onClick={() => props.ExportFunction(props.ExportType, props.WithGeoData)}
					size="lg"
					key={'exportButton' + props.ExportType}
					disabled={props.Disabled}
				>
					<span className={'fa fa-xl fa-solid ' + faIcon} />
				</Button>
			</Tooltip>
		</div>
	);
};
