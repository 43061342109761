import {IStatusPanelComp, IStatusPanelParams} from 'ag-grid-community';
import CalibrationService from '../../../Services/Calibrations/CalibrationService';

export class CalibrationExportStatusBarComponent implements IStatusPanelComp {
	params: IStatusPanelParams;
	eGui: HTMLButtonElement;
	buttonListener: any;
	calibrationService: CalibrationService;

	init(params: IStatusPanelParams) {
		this.params = params;
		this.calibrationService = new CalibrationService();
		this.buttonListener = this.onButtonClicked.bind(this);

		this.eGui = document.createElement('button');
		this.eGui.disabled = this.params.api.getSelectedRows().length == 0;
		this.eGui.addEventListener('click', this.buttonListener);
		this.eGui.innerHTML = 'Export Calibrations';
		this.eGui.type = 'button';
		this.eGui.style.padding = '12px';
		this.eGui.style.margin = '12px';
		this.eGui.classList.add('btn');
		this.eGui.classList.add('btn-primary');
		this.eGui.classList.add('py-1');

		params.api.addEventListener('selectionChanged', () => {
			this.updateStatusBar();
		});
	}

	updateStatusBar() {
		this.eGui.disabled = this.params.api.getSelectedRows().length == 0;
	}

	getGui() {
		return this.eGui;
	}

	destroy() {
		this.eGui.removeEventListener('click', this.buttonListener);
	}

	onButtonClicked() {
		for (const selectedRow of this.params.api.getSelectedRows()) {
			this.calibrationService.exportCalibrationResult(selectedRow.CalibrationID);
		}

		this.params.api.deselectAll();
	}
}

export const GridOptions = {
	statusBar: {
		statusPanels: [
			{statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
			{statusPanel: 'agFilteredRowCountComponent', align: 'left'},
			{statusPanel: 'agSelectedRowCountComponent', align: 'left'},
			{statusPanel: CalibrationExportStatusBarComponent, align: 'right'},
		],
	},
	containerStyle: {width: '100%', height: '100%'},
	theme: 'ag-theme-alpine',
	gridStyle: {height: '100%', width: '100%'},
	rowGroupPanelShow: 'always',
	rowSelection: 'multiple',
	suppressRowClickSelection: true,
	columnSettings: {minWidth: 100, sortable: true, resizable: true, enableRowGroup: true, filter: 'agSetColumnFilter'},
	animateRows: true,
	enableCellChangeFlash: true,
	showOpenedGroup: true,
	groupDisplayType: 'multipleColumns',
	groupHideOpenParents: false,
};
