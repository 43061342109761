import React, {Component} from 'react';
import {Trans} from 'react-i18next';

class NoRowsOverlayComponent extends Component {
    render() {
        return (
            <>
            No rows
                {/* <Trans i18nKey={'datagrid.noRowsOverLay'} /> */}
            </>
        );
    }
}

export default NoRowsOverlayComponent;
