import {Sensor} from '../../../components/Common/Types/Sensor';
import request from '../../../components/Infrastructure/Requests/Request';
import SensorsDashboardInfo from '../../../components/Services/SensorsDashboardInfo/SensorsDashboardInfo';
import pubsub from 'pubsub-js';
import {UI_EVENT} from '../../../components/Shared/Constants/Events';
import {RunInfo, RunInfoSummary} from '../../types';
import {RunInfoResult, SensorRunInfoRow, SensorsAllInformationsViewRow} from '../../types/DbModel';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
import {DiscoverModuleResponse} from './DiscoverModuleResponse';

export class SensorService {
	public static async DiscoverModule(serialNumber: string): Promise<DiscoverModuleResponse> {
		try {
			const response = await request({
				url: '/rpc/discover_module',
				method: 'POST',
				data: {serial_number: serialNumber},
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}
	public static async DiscoverRun(
		sensor: Sensor | number
	): Promise<{AffectedSensors: {id: number; name: string}[]; Configuration: object}> {
		const sensorId = sensor instanceof Sensor ? sensor.Id : sensor;

		try {
			const response = await request({
				url: '/rpc/discover_run',
				method: 'POST',
				data: {s_id: sensorId},
				token: Authentication.getToken(),
			});
			return {AffectedSensors: response.data.affected_sensors, Configuration: response.data.configuration};
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}
	public static async SetAlarmingEnabled(sensor: Sensor, alarmingEnabled: boolean): Promise<void> {
		try {
			await request({
				url: '/rpc/activate_alarming',
				method: 'POST',
				token: Authentication.getToken(),
				data: {
					sensors_id: sensor.Id,
					activate: alarmingEnabled,
				},
			});
			pubsub.publish(`${UI_EVENT.SENSOR_ALARMING_ENABLED_CHANGED}.${sensor.Id}`, {
				AlarmingEnabled: alarmingEnabled,
				SensorId: sensor.Id,
			});
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}

	public static async GetSingleRunInfo(sensor: Sensor): Promise<RunInfo[]> {
		try {
			const response = await request({
				url: '/rpc/get_run_info',
				method: 'POST',
				data: {s_id: sensor.Id},
				token: Authentication.getToken(),
			});
			const result = response.data as RunInfoResult[];

			return result.map(r => new RunInfo(r));
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}

	public static async GetRunInfoSummary(): Promise<RunInfoSummary[]> {
		try {
			const response = await request({
				url: '/sensor_run_info',
				method: 'GET',
				token: Authentication.getToken(),
			});
			const result = response.data as SensorRunInfoRow[];

			return result.map(r => new RunInfoSummary(r));
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}

	public static async GetSensorsFromDashboardInfo(sensorId?: number): Promise<Sensor[]> {
		const idFilter = sensorId ? `&id=eq.${sensorId}` : '';

		let dashboardInfo: SensorsDashboardInfo[] = [];
		try {
			dashboardInfo = (
				await request({
					url: '/sensors_dashboard_info?order=id' + idFilter,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			console.error(e);
		}

		return dashboardInfo.map(d => new Sensor(d));
	}

	public static async AllInformation(sensorId: number): Promise<SensorsAllInformationsViewRow> {
		try {
			const response = await request({
				url: '/sensors_all_informations?id=eq.' + sensorId,
				method: 'GET',
				headers: {Accept: 'application/vnd.pgrst.object'},
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
