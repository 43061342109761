export class CalibrationMeasurement {
	public Date: Date;
	public Value: number;
	public UnitID: number;
	public Status: number;

	constructor(date: Date, value: number, unitId: number, status: number) {
		this.Date = date;
		this.Value = value;
		this.UnitID = unitId;
		this.Status = status;
	}
}
