import request from '../../Infrastructure/Requests/Request';
import {asyncServerRequest} from '../../Infrastructure/Requests/ServerRequest';
import Authentication from '../../Infrastructure/Authentication/Authentication';

function dashboardInfo(params = '', eventData) {
    return request({
        url: '/sensors_dashboard_info?order=id' + params,
        method: 'GET',
        token: Authentication.getToken(),
        eventData,
    });
}

function measurements() {
    return request({
        url: '/sensors_current_measurements',
        method: 'GET',
        token: Authentication.getToken(),
    });
}

function dashboardSensorMeasurement(payload, eventData) {
    return request({
        url: '/rpc/get_current_measurement',
        method: 'POST',
        data: payload,
        token: Authentication.getToken(),
        eventData,
    });
}

function getMultiUseRunInfo(payload, eventData) {
    return request({
        url: '/rpc/get_run_info',
        method: 'POST',
        data: payload,
        token: Authentication.getToken(),
        eventData,
    });
}

function startRun(payload, eventData) {
    return request({
        url: '/rpc/start_run_multi',
        method: 'POST',
        data: {
            info: payload,
        },
        token: Authentication.getToken(),
        eventData,
    });
}

function discoverRun(payload, eventData) {
    return request({
        url: '/rpc/discover_run',
        method: 'POST',
        data: payload,
        token: Authentication.getToken(),
        eventData,
    });
}

function deviationsStatus(eventData) {
    return request({
        url: '/deviations_status',
        method: 'GET',
        token: Authentication.getToken(),
        eventData,
    });
}

function sensor(id, eventData) {
    return request({
        url: '/sensors_all_informations?id=eq.' + id,
        method: 'GET',
        headers: {Accept: 'application/vnd.pgrst.object'},
        token: Authentication.getToken(),
        eventData,
    });
}

function deleteSensor(payload, eventData) {
    return request({
        url: '/rpc/delete_sensor',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload,
        eventData,
    });
}

function replaceSensor(payload) {
    return request({
        url: '/rpc/replace_sensor',
        method: 'POST',
        token: Authentication.getToken(),
        data: payload,
    });
}

function addModule(payload, eventData) {
    return request({
        url: '/rpc/register_sensor_multi',
        method: 'POST',
        token: Authentication.getToken(),
        data: {
            info: payload,
        },
        eventData,
    });
}

function updateModule(payload, eventData) {
    return request({
        url: '/rpc/sensor_update',
        method: 'POST',
        token: Authentication.getToken(),
        data: {
            info: payload,
        },
    });
}

function repairSensor(data, eventData) {
    return request({
        url: '/rpc/repair_sensor',
        method: 'POST',
        token: Authentication.getToken(),
        data: data,
        eventData,
    });
}

function activateAlarming(data, eventData) {
    return request({
        url: '/rpc/activate_alarming',
        method: 'POST',
        token: Authentication.getToken(),
        data: data,
        eventData,
    });
}

function checkPairingWindow(id, eventData) {
    return request({
        url: 'sensor_pairing?serial_number=eq.' + id,
        method: 'GET',
        token: Authentication.getToken(),
        eventData,
    });
}

function discoverModule(data, eventData) {
    return request({
        url: '/rpc/discover_module',
        method: 'POST',
        token: Authentication.getToken(),
        data: data,
        eventData,
    });
}

function configuration_template(id, eventData) {
    return request({
        url: '/configuration_templates?id=eq.' + id,
        method: 'GET',
        headers: {Accept: 'application/vnd.pgrst.object'},
        token: Authentication.getToken(),
        eventData,
    });
}

const getOrganizationSetting = async payload => {
    const payloadData = {
        url: 'settings/organization-configuration/setting/' + payload,
        method: 'GET',
    };
    try {
        return await asyncServerRequest(payloadData);
    } catch (error) {
        console.error(error);
    }
};

const SensorService = {
    dashboardInfo,
    measurements,
    getMultiUseRunInfo,
    startRun,
    discoverRun,
    deviationsStatus,
    sensor,
    deleteSensor,
    replaceSensor,
    addModule,
    updateModule,
    repairSensor,
    activateAlarming,
    checkPairingWindow,
    discoverModule,
    configuration_template,
    dashboardSensorMeasurement,
    getOrganizationSetting,
};

export default SensorService;
