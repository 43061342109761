import React from 'react';
import {Event} from '../../common/types';

export type WarningIconSettings = {isAlarm: boolean; isAcknowledged?: boolean; isInactive?: boolean};

type WarningIconProps = {
	event: Event | WarningIconSettings;
	size?: number;
};

export function WarningIcon(props: WarningIconProps) {
	const getWarningIconClassName = (): string => {
		let className = 'elpro-';
		let isAlarm: boolean;
		let isAcknowledged: boolean;
		let isInactive: boolean;

		if (props.event instanceof Event) {
			isAlarm = props.event.GetEntryReason().includes('alarm');
			isAcknowledged = props.event.IsAcknowledged();
		} else {
			isAlarm = props.event.isAlarm;
			isAcknowledged = props.event.isAcknowledged;
			isInactive = props.event.isInactive;
		}

		className += isAlarm ? 'Alarm' : 'Warning';
		if (isInactive) {
			className += 'Inactive';
		} else {
			className += isAcknowledged ? 'Acknowledged' : 'Active';
		}
		return className;
	};

	return (
		<>
			<span className={`fs${props.size ?? 1} ${getWarningIconClassName()} float-right`}>
				<span className="path1" />
				<span className="path2" />
				<span className="path3" />
				<span className="path4" />
			</span>
		</>
	);
}
