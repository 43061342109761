import {MeasurementNew} from '../../../components/Common/Types/WebSocket/MeasurementNew';
import request from '../../../components/Infrastructure/Requests/Request';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

export class MeasurementService {
	public static async GetLastMeasurementValues(): Promise<MeasurementNew[]> {
		let measurementData = [];

		try {
			measurementData = (
				await request({
					url: '/last_measurement_values',
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			console.error(e);
		}

		return measurementData.map(m => new MeasurementNew(m));
	}
}
