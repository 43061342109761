import {DeviationStatus} from '../../../components/Common/Types/DeviationStatus';
import request from '../../../components/Infrastructure/Requests/Request';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

export class DeviationService {
	public static async GetDeviationStatus(): Promise<DeviationStatus[]> {
		let deviationStatus = [];
		try {
			deviationStatus = (
				await request({
					url: '/deviations_status',
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			console.error(e);
		}

		return deviationStatus.map(d => new DeviationStatus(d));
	}
}
