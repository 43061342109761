import Authentication from '../../components/Infrastructure/Authentication/Authentication';

export {DeviationService} from './DeviationService/DeviationService';
export {LimitProfileService} from './LimitProfilesService/LimitProfileService';
export {MeasurementService} from './MeasurementService/MeasurementService';
export {OrganisationSettingService} from './OrganisationSettingService/OrganisationSettingService';
export {PredictionService} from './PredictionService';
export {SensorGroupService} from './SensorGroupService/SensorGroupService';
export {SensorService} from './SensorService/SensorService';
export {SystemSettingsService} from './SystemSettingsService/SystemSettingsService';
export {UserManagementService} from './UserManagementService';
export {UserProfileService} from './UserProfileService';

export function getDefaultOpts(): RequestInit {
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${Authentication.getToken()}`);
	headers.append('accept', 'application/json');
	headers.append('content-type', 'application/json');

	return {headers: headers};
}
