import TextControl from "./TextControl";
import DropdownControl from "./DropdownControl";
import CheckboxControl from "./CheckboxControl";
import NumberControl from "./NumberControl";
import DatetimeControl from "./DatetimeControl";
import EditableDropdownControl from "./EditableDropdownControl";
import ListControl from "./ListControl";
import MultiselectControl from "./MultiselectControl";

export default {
    text: TextControl,
    dropdown: DropdownControl,
    checkbox: CheckboxControl,
    number: NumberControl,
    datetime: DatetimeControl,
    editableDropdown: EditableDropdownControl,
    list: ListControl,
    multiselect: MultiselectControl
};
