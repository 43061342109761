import React, {useState} from 'react';
import {RightOutlined} from '@ant-design/icons';
import AntPopover from '../UI/Ant/AntPopover';
import styled from 'styled-components';
import {StyledLink} from '../Styles/StyledLink';
import {GetKnowledgeBaseUrl, KnowledgeBase} from '../../../common/constants/KnowledgeBase';

const StyledContent = styled.div`
	padding-bottom: ${({numberOfContents}) => (numberOfContents > 1 ? '15px' : '0')};
	font-size: 13px;
`;

const HelpContent = ({contents}) => {
	return (
		<>
			{contents.map((content, index) => (
				<StyledContent key={index} numberOfContents={contents.length}>
					<div style={{display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 1fr'}}>
						<RightOutlined style={{fontSize: '12px'}} />
						<div style={{padding: '0 0 0 5px'}}>{content.title}</div>
					</div>
					{content.url && content.linkText && (
						<div style={{margin: '0 15px'}}>
							<StyledLink href={GetKnowledgeBaseUrl(content.url)} rel="noopener noreferrer" target="_blank">
								{content.linkText}
							</StyledLink>
						</div>
					)}
				</StyledContent>
			))}
		</>
	);
};

const ToolTipIcon = () => {
	return <img src={process.env.PUBLIC_URL + '/img/helpIcon.svg'} alt="" />;
};

const parseContent = (content, customValues) => {
	const contentData = JSON.parse(JSON.stringify(content));
	if (customValues) {
		for (let k in contentData) {
			contentData[k].forEach(c => {
				const result = c.title.match(/\{(.*)}/);
				if (result) {
					const replacer = result.pop();
					const customValueKeys = Object.keys(customValues);
					const toReplace = customValueKeys.find(key => key === replacer);
					if (toReplace && customValues[toReplace]) {
						c.title = c.title.replace(/\{(.+?)}/g, customValues[toReplace]);
					}
				}
			});
		}
	}

	return contentData;
};

export const KnowledgeBaseHelp = ({id, placement = 'right', visible = true, text = '', showIcon = true, customValues = undefined}) => {
	if (!visible) return;
	const [helpContent, setHelpContent] = useState<typeof KnowledgeBase>(KnowledgeBase);

	if (customValues) {
		const content = parseContent(helpContent, customValues);
		setHelpContent(content);
	}

	return (
		helpContent &&
		helpContent[id] && (
			<AntPopover placement={placement} content={<HelpContent contents={helpContent[id]} />} visible="true">
				{showIcon && <ToolTipIcon />}
				{text}
			</AntPopover>
		)
	);
};

export const KnowledgeBaseHelpMemo = React.memo(KnowledgeBaseHelp);

export default React.memo(KnowledgeBaseHelp);
