import React, {useState} from 'react';
import {Input} from 'reactstrap';
import SystemSettings from '../../Infrastructure/Settings/SystemSettings';
import AntAlert from './Alert';

const TermsOfUseAndOk = props => {
	const [termsOfUse, setTermsOfUse] = useState(false);

	const setTermsOfUseState = () => {
		setTermsOfUse(!termsOfUse);
	};

	const hasAlert = Boolean(props.alert_msg);
	return (
		<>
			<div className="clearfix">
				<div className="checkbox c-checkbox float-left mt-0">
					<label>
						<Input type="checkbox" onChange={setTermsOfUseState} value={termsOfUse} name="terms_of_use" />
						<span className="fa fa-check" />I have read and agree with the
						<a href={SystemSettings.getConfig().privacy_policy} rel="noopener noreferrer" target="_blank">
							{' '}
							privacy policy
						</a>{' '}
						and
						<a href={SystemSettings.getConfig().terms_of_use} rel="noopener noreferrer" target="_blank">
							{' '}
							terms of use
						</a>
						.
					</label>
					<br />
					<br />
				</div>
			</div>
			<br />

			{hasAlert ? (
				<div style={{float: 'right', padding: '0 20px'}}>
					<AntAlert type="error" message={props.alert_msg} />
				</div>
			) : null}

			<button
				id="btnSave"
				className="btn btn-primary"
				color="primary"
				disabled={!termsOfUse || hasAlert || props.isLoading}
				onClick={props.onOk}
			>
				{props.okText}
			</button>
		</>
	);
};

export default TermsOfUseAndOk;
