import dayjs from 'dayjs';
import {SensorLimitsRoutineDetailsRow} from './DbModel/SensorLimitsRoutineRow';

export class AlarmLimitBreachDetails {
	constructor(init: SensorLimitsRoutineDetailsRow) {
		this.UpperLimit = init.upper_limit;
		this.UpperLimitDelay = init.upper_limit_delay;
		this.LowerLimit = init.lower_limit;
		this.LowerLimitDelay = init.lower_limit_delay;
		this.BreachEnd = dayjs(init.until);
		this.BreachStart = dayjs(init.from);
		this.IsMuted = init.is_muted;
	}

	public UpperLimit: number;
	public UpperLimitDelay: number;
	public LowerLimit: number;
	public LowerLimitDelay: number;
	public IsMuted: boolean;

	public BreachStart: dayjs.Dayjs;
	public BreachEnd: dayjs.Dayjs;
}
