import 'ag-grid-enterprise';
import {memo} from 'react';
import {COLUMNS} from './Constants/Columns';
import {limitProfileNameCellRules} from './CssRules/limitProfileNameCellRules';
import {menuCellRules} from './CssRules/menuCellRules';
import ActionCellButtons from './GridComponents/ActionCellButtons';
import {renderLockSelector} from './GridComponents/LockRenderer';

export const ColumnDefinitions = [
	{
		headerName: 'Row ID',
		valueGetter: 'node.id',
		hide: 'true',
		lockVisible: 'true',
		filter: false,
		sortable: false,
		suppressMovable: true,
	},
	{
		field: COLUMNS.LIMIT_PROFILE_NAME,
		headerTooltip: COLUMNS.LIMIT_PROFILE_NAME,
		sortable:true,
		flex:3,
		cellClassRules: limitProfileNameCellRules,
	},	
	{
		field: COLUMNS.IN_USE_BY, 
		headerTooltip: COLUMNS.IN_USE_BY, 
		width: 130, 
		maxWidth:130,
		flex:1,
		cellStyle: {textAlign: 'center'}, 
		enableRowGroup: false,
		comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            if (valueA == valueB) return 0;            
			return (valueA > valueB) ? 1 : -1;}
	},
	{
		field: COLUMNS.LOCKED,
		headerTooltip: COLUMNS.LOCKED,
		width: 120,
		maxWidth:120,
		flex:1,		
		cellStyle: {textAlign: 'center'},
		cellRendererSelector: params => renderLockSelector(params),
	},
	{
		headerName: '',
		field: COLUMNS.MENU,
		cellRenderer: memo(ActionCellButtons),
		cellClassRules: menuCellRules,
		filter: false,
		resizable: false,		
		sortable: false,
		enableRowGroup: false,
		
		minWidth: 60,
		maxWidth: 110,
		suppressMenu: true,
		lockVisible: 'true',
		suppressMovable: true,
		lockPosition: 'right',
	},
];
