import {prepareSingleRowData} from './IssueProfileGridSingleRowBuilder';

export const mapIssueProfilePropertiesToGrid = (parent, issueProfiles) => {
	let rows = [];
	let _issueProfiles = []; //This array will be used in the future for events. This array a kind of state of issue profiles.
	if (parent && typeof parent === 'object' && issueProfiles && Array.isArray(issueProfiles) && issueProfiles.length > 0) {
		issueProfiles.forEach(issueProfile => {
			let i = 1;
			const issueProfileObj = prepareIssueProfileObj(issueProfile);
			_issueProfiles.push(issueProfileObj);

			const callbackMethodsForHamburgerMenu = prepareCallbackMethodsForHamburgerMenu(parent);
			rows.push(prepareSingleRowData(callbackMethodsForHamburgerMenu, issueProfile, parent, i));
			i++;
		});
	}
	return [rows, _issueProfiles];
};

function prepareCallbackMethodsForHamburgerMenu(parent) {
	if (!parent) {
		throw new TypeError('Please pass parent object!!!');
	}

	return {
		copyIssueProfile: parent.copyIssueProfile,
		displayIssueProfile: parent.displayIssueProfile,
		editIssueProfile: parent.editIssueProfile,
		deleteIssueProfile: parent.deleteIssueProfile,
	};
}

function prepareIssueProfileObj(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}

	return {
		issueProfileId: issueProfile.id,
		missingValueAlarm: issueProfile.missing_value_alarm,
		sensorFailureAlarm: issueProfile.sensor_failure_alarm,
		radioLowBatteryWarning: issueProfile.low_battery_warning,
		lightWarning: issueProfile.light_warning,
		tiltWarning: issueProfile.tilt_warning,
		locked: issueProfile.locked,
		lostMeasurementAlarm: issueProfile.lost_measurement_alarm,
		missingCommunicationWarning: issueProfile.missing_communication_warning,
		sensors: issueProfile.sensors,
	};
}

if (process.env['BABEL_ENV'] === 'test' || process.env['NODE_ENV'] === 'test') {
	//Functions are only visible for testing purpose.
	module.exports.prepareIssueProfileObj = prepareIssueProfileObj;
	module.exports.prepareCallbackMethodsForHamburgerMenu = prepareCallbackMethodsForHamburgerMenu;
}
