import React, {Component, ReactNode} from 'react';
import ReactHighcharts from 'react-highcharts';
import CalibrationAnalysisChartConfiguration from './CalibrationAnalysisChartConfiguration';
import SensorUtils from '../../../Dashboard/Sensor/SensorUtils';
import {CalibrationAnalysisChartProps, CalibrationAnalysisChartState} from './CalibrationAnalysisChartProps';
import CalibrationAnalysisHistogramConfiguration from './CalibrationAnalysisHistogramConfiguration';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import {Button} from 'antd';
import {Trans} from 'react-i18next';
import classnames from 'classnames';

class CalibrationAnalysisChart extends Component<CalibrationAnalysisChartProps, CalibrationAnalysisChartState> {
	constructor(props: CalibrationAnalysisChartProps) {
		super(props);

		this.state = {
			activeTab: '1',
		};
	}

	toggle(tab: string) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	getRelevantMeasurements() {
		return this.props.calibration.Measurements.filter(
			m => m.Date >= this.props.displayStartDate.toDate() && m.Date <= this.props.displayEndDate.toDate()
		)
			.map(cm => {
				return {
					x: SensorUtils.convertPostgresqlTimestampToHighcharts(cm.Date),
					y: cm.Status ? null : SensorUtils.convertTemperature(cm.Value, this.props.calibration.Sensor.UnitID),
				};
			})
			.sort((cm1, cm2) => cm1.x - cm2.x);
	}

	filterActive() {
		return (
			Math.abs(new Date(this.props.calibration.Start).getTime() - this.props.displayStartDate.toDate().getTime()) > 1000 ||
			Math.abs(new Date(this.props.calibration.Stop).getTime() - this.props.displayEndDate.toDate().getTime()) > 1000
		);
	}

	render(): ReactNode {
		const relevantMeasurements = this.getRelevantMeasurements();
		if (relevantMeasurements.length == 0) {
			return;
		}

		const chartOptions = CalibrationAnalysisChartConfiguration(this.props);
		chartOptions.series.push({
			id: 'main',
			name: this.props.calibration.Sensor.Name,
			data: relevantMeasurements,
		});

		const histogramOptions = CalibrationAnalysisHistogramConfiguration(this.props);
		histogramOptions.series.push(
			{
				type: 'histogram',
				binsNumber: 'sturges',
				baseSeries: 's1',
			},
			{
				name: 'Data',
				type: 'scatter',
				data: relevantMeasurements.filter(m => m.y != null).map(m => m.y),
				id: 's1',
				visible: false,
				showInLegend: false,
			}
		);

		return (
			<>
				{this.filterActive() && (
					<Button style={{float: 'right'}} onClick={this.props.resetZoom}>
						<Trans i18nKey="global.resetZoom" />
					</Button>
				)}
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({
								active: this.state.activeTab === '1',
							})}
							onClick={() => {
								this.toggle('1');
							}}
						>
							<Trans i18nKey={'titles.lineChart'} />
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: this.state.activeTab === '2',
							})}
							onClick={() => {
								this.toggle('2');
							}}
						>
							<Trans i18nKey={'titles.histogram'} />
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
					<TabPane tabId="1">
						<ReactHighcharts config={chartOptions} isPureConfig={true} />
					</TabPane>
					<TabPane tabId="2">
						<ReactHighcharts config={histogramOptions} isPureConfig={true} />
					</TabPane>
				</TabContent>
			</>
		);
	}
}

export default CalibrationAnalysisChart;
