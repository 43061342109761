import {DeviationsAcknowledgedRowDetail, DeviationsActiveRowDetail, DeviationsInactiveRowDetail, EventDetailRow} from './DbModel';
import {EventDetailType} from './EventDetailType';

type ValueType = number | string | Object;

export class EventDetail {
	Id: number;
	OrgId: number;
	EventId: number;
	EventDetailTypeId: EventDetailType;
	Value: ValueType;

	constructor(init: EventDetailRow | DeviationsActiveRowDetail | DeviationsInactiveRowDetail | DeviationsAcknowledgedRowDetail) {
		this.Id = init.id;
		this.OrgId = init.org_id;
		this.EventId = init.events_id;
		this.EventDetailTypeId = init.type;
		this.Value = this.parseValue(init.value, init.is_object);
	}

	private parseValue(value: string, isObject: boolean): ValueType {
		if (isObject) {
			return JSON.parse(value);
		}

		const numberValue: number = parseFloat(value);

		if (!isNaN(numberValue) && numberValue.toString() === value) {
			return numberValue;
		}

		return value;
	}
}
