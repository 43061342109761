import request from '../../Infrastructure/Requests/Request';
import Authentication from '../../Infrastructure/Authentication/Authentication';
import {webserverServiceProUrl} from '../../../common/services/WebserverServicePro';
import {IUserUpdateRequest} from '@elproag/webserver-service-pro';
import {getDefaultOpts} from '../../../common/services';

const baseUrl = `${webserverServiceProUrl}/api/web/users`;

export function getUser(eventData) {
	return request({
		url: '/user',
		method: 'GET',
		headers: {Accept: 'application/vnd.pgrst.object'},
		token: Authentication.getToken(),
		eventData,
	});
}

export async function updateUser(payload, eventData) {
	const request: IUserUpdateRequest = {
		email: payload.email,
		firstName: payload.first_name,
		lastName: payload.last_name,
		mobile: payload.mobile,
	};

	const response = await fetch(`${baseUrl}/${payload.id}`, {
		...getDefaultOpts(),
		body: JSON.stringify(request),
		method: 'PATCH',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.json());
	return Promise.reject();
}

export function getUserProfile(eventData) {
	return request({
		url: '/user_profile',
		method: 'GET',
		headers: {Accept: 'application/vnd.pgrst.object'},
		token: Authentication.getToken(),
		eventData,
	});
}

export function updateProfile(payload, eventData) {
	return request({
		url: '/rpc/user_profile_update',
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

export function changePassword(payload, eventData) {
	return request({
		url: '/rpc/user_change_password',
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

export function reset2FA(payload, eventData) {
	return request({
		url: '/rpc/user_reset2fa',
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

export function sendTestEmail(payload, eventData) {
	return request({
		url: '/rpc/send_test_email',
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}

export function sendTestSms(payload, eventData) {
	return request({
		url: '/rpc/send_test_sms',
		method: 'POST',
		data: payload,
		token: Authentication.getToken(),
		eventData,
	});
}
