import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';

type IssueAlarmsUseIssueAlarmProps = {
	show: boolean;
	useIssueAlarm: boolean;
};

export default function IssueAlarmsUseIssueAlarmComponent(props: IssueAlarmsUseIssueAlarmProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.useIssueAlarm'} />
				</td>
				<td>{props.useIssueAlarm ? CONSTANTS.YES : CONSTANTS.NO}</td>
			</tr>
		)
	);
}
