import React, {Component} from 'react';
import {Label, Row, Col, FormGroup, Input} from 'reactstrap';

type SensorBasicsNameInputProps = {
	value: string | undefined;
	onChange: any;
	hasError: any;
};

class SensorBasicsNameInputState {
	onChange: any;
}

export class SensorBasicsNameInputComponent extends Component<SensorBasicsNameInputProps, SensorBasicsNameInputState> {
	constructor(props: SensorBasicsNameInputProps | Readonly<SensorBasicsNameInputProps>) {
		super(props);
		this.state = {
			onChange: props.onChange,
		};
	}

	render() {
		return (
			<Row>
				<Col xl={3}>
					<FormGroup>
						<Label for="sensor_name">Sensor name</Label>
						<Input
							type="text"
							id="sensor_name"
							name="sensor_name"
							placeholder="Enter the sensor name"
							invalid={
								this.props.hasError('formSensorBasics', 'sensor_name', 'required') ||
								this.props.hasError('formSensorBasics', 'sensor_name', 'maxchar')
							}
							data-validate='["required","maxchar"]'
							data-param="50"
							maxLength="51"
							value={this.props.value}
							onChange={this.state.onChange}
						/>
						{this.props.hasError('formSensorBasics', 'sensor_name', 'required') && (
							<span className="invalid-feedback">Field is required</span>
						)}
						{this.props.hasError('formSensorBasics', 'sensor_name', 'maxchar') && (
							<span id="sensorNameMax" className="invalid-feedback">
								Field must be be less than 50 characters
							</span>
						)}
					</FormGroup>
				</Col>
			</Row>
		);
	}
}
